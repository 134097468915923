import React from 'react';
import './DrugList.scss';
import { useTranslation } from 'react-i18next';
type Props = {
  drugs: any[];
  selectedDrugs: any[];
  selectDrug: (payload: any) => void;
  removeDrug: (payload: any) => void;
};
const DrugList = (props: Props, ref: any) => {
  const { t } = useTranslation();

  const { drugs, selectedDrugs, selectDrug, removeDrug } = props;
  return (
    <div className="drugs-container">
      <div className="row">
        <div className="col-md-6">
          <div className="card-container">
            <div className="card-header">
              <button>{t('search.drugs')}</button>
            </div>

            {drugs.map((drug: any) => {
              const exist = selectedDrugs.find((d: any) => d === drug);

              if (!exist) {
                return (
                  <div className="card">
                    <div className="card-body">
                      <div className="drug-name">{drug} </div>{' '}
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => {
                          selectDrug(drug);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-container">
            <div className="card-header">
              <button>{t('search.prescription')}</button>
            </div>

            {selectedDrugs.map((drug: any) => {
              return (
                <div className="card">
                  <div className="card-body">
                    <div className="drug-name">{drug} </div>{' '}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        removeDrug(drug);
                      }}
                    >
                      remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrugList;
