import React from 'react';
import Button from '../../UI/Form/Button/Button';
import './CustomAlertUI.scss';
type Props = {
  title: string;
  body: string;
  onClose: () => void;
  send: () => void;
};
const CustomAlertUI = ({ title, body, onClose, send }: Props) => {
  return (
    <div className="custom-alert-ui">
      <h4>{title}</h4>
      <p>{body}</p>
      <div className="btn-group">
        {title != 'warning' ? (
          <Button
            type="button"
            name={title === 'warning' ? 'OK' : 'Yes'}
            saveData={() => {
              onClose();
              send();
            }}
          />
        ) : (
          ''
        )}
        <Button
          type="button"
          btnType="warning"
          name={title === 'warning' ? 'OK' : 'Cancel'}
          saveData={onClose}
        />
      </div>
    </div>
  );
};
export default CustomAlertUI;
