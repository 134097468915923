import React, { useState, useEffect } from 'react';
import './Login.scss';

import LoginForm from '../../components/Login/LoginForm';
import TokenForm from '../../components/Token/TokenForm';
import RegisterClinicDetails from '../../components/Layout/RegisterClinicDetails/RegisterClinicDetails';
import { useHistory, useLocation } from 'react-router-dom';
import apiService from '../../services/api';
import tokenTypes from '../../assets/constants/tokenTypes';
import { useDispatch } from 'react-redux';

import routes from '../../assets/constants/routes';
// import { Device } from '../../utils/device';
import { storeData, getStoredData, isAuthenticated } from '../../utils/storage';
import { setUserAuthenticated, getClinicianUnits } from '../../store/actions';
// import { UserRoles } from '../../assets/constants/roles';

let loginPayload: any = {};

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const [user, setUser] = useState<any>({});
  const [isLogin, setIsLogin] = useState(true);
  const [shouldResendToken, setShouldResendToken] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // console.log('user from Login', location.state);
    if (location && location.state && location.state.user) {
      setUser({ ...location.state.user });
    } else {
      history.push(routes.CHECK_USER);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated()) history.push(routes.HOME);
  }, [history]);

  const setLoginPayloadHandler = async (values: any) => {
    loginPayload = {
      ...loginPayload,
      ...values,
      trustedDeviceToken: getStoredData(`trustedDeviceToken-${values.mobile}`),
    };
    await LoginHandler();
  };

  const LoginHandler = async () => {
    try {
      const data = await apiService.login(loginPayload);
      if (data.data.tokenRequired) {
        setIsLogin(false);
      } else {
        delete data.data.tokenRequired;
        let storedData = {
          ...data.data,
        };
        if (data.data.trustedDeviceToken) {
          storedData = {
            ...storedData,
            [`trustedDeviceToken-${loginPayload.mobile}`]: data.data
              .trustedDeviceToken,
          };
        }

        delete storedData.trustedDeviceToken;
        storeData({ ...storedData });
        dispatch(setUserAuthenticated());
        dispatch(getClinicianUnits(history));
        // if (
        //   [
        //     UserRoles.Nurse,
        //     UserRoles.Receptionist,
        //     UserRoles.Assistant,
        //   ].includes(data.data.user.role)
        // ) {
        //   history.push(routes.BOOKING);
        // } else if (
        //   [
        //     UserRoles.Physician,
        //     UserRoles.LabTech,
        //     UserRoles.RadioTech,
        //   ].includes(data.data.user.role)
        // ) {
        // }
        // history.push(routes.BOARD);
      }
    } catch (error) {
      console.error('error[login]', error);
    }
  };

  const checkTokenHandler = async (values: any) => {
    if (!values.token || values.token.length !== 4) return;
    try {
      setIsSubmitting(true);
      const tokenPayload = {
        ...values,
        mobile: user.mobile,
        tokenType: tokenTypes.loginToken,
      };
      const data = await apiService.checkToken(tokenPayload);
      setIsSubmitting(false);
      setLoginPayloadHandler({ token: data.data.token });
    } catch (error) {
      console.error('error[checkToken]', error);
      setIsSubmitting(false);
    }
  };

  const resendTokenHandler = async () => {
    try {
      await apiService.requestToken({
        mobile: user.mobile,
        tokenType: tokenTypes.loginToken,
      });
      setShouldResendToken(true);
    } catch (error) {
      console.error('error[resendToken]', error);
      setShouldResendToken(true);
    }
  };

  const goBackHandler = () => {
    setIsLogin(true);
  };

  return (
    <div className="login-container d-flex flex-column-reverse flex-sm-row">
      {isLogin ? (
        <LoginForm Loginhandler={setLoginPayloadHandler} user={user} />
      ) : (
        <TokenForm
          checkTokenhandler={checkTokenHandler}
          resendTokenHandler={resendTokenHandler}
          mobile={user.mobile}
          shouldResend={shouldResendToken}
          isSubmitting={isSubmitting}
          goBack={goBackHandler}
        />
      )}

      <RegisterClinicDetails />
    </div>
  );
};

export default Login;
