import React from 'react';
import './SearchForm.scss';
import Input from '../UI/Form/Input/Input';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

type Props = {
  search: (payload: any) => void;
};
const SearchForm = (props: Props, ref: any) => {
  const { search } = props;
  const { t } = useTranslation();

  return (
    <div className="search-form">
      <Formik
        initialValues={{ name: '', symptoms: '' }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            search(values);
            actions.setSubmitting(false);
          }, 1000);
        }}
      >
        {(propss) => (
          <form onSubmit={propss.handleSubmit} autoComplete="off">
            <div className="search-drug-form-group">
              <div className=" d-flex flex-column flex-sm-row align-items-center justify-content-between mt-5">
                <Input
                  name="text"
                  placeholder={t('search.name')}
                  type="name"
                  className={''}
                />
                <Input
                  name="symptoms"
                  placeholder={t('search.symptoms')}
                  type="text"
                  className={''}
                />
                <input
                  className="btn btn-success btn-sm"
                  type="submit"
                  value="Search"
                ></input>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SearchForm;
