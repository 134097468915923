import * as Yup from 'yup';

const formValidationSchema = (t: any) => {
  return Yup.object().shape({
    name: Yup.string().required(t('booking_page.required')),
    gov: Yup.string().required(t('booking_page.required')),
    area: Yup.string().required(t('booking_page.required')),
  });
};

export default formValidationSchema;
