import React, { useState } from 'react';
import './AddLabServiceModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../UI/Form/Button/Button';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20rem',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  confirmAddHandler: (ser: { name: string; price: number }) => void;
};

const AddLabServiceModal: React.FC<Props> = ({
  confirmAddHandler,
  isOpen,
  onModalClose,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [price, setPrice] = useState<number | ''>('');
  const priceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      return setPrice(value);
    }
    if (+value < 0) {
      setPrice(+value * -1);
    }
    if (+value > 0) {
      setPrice(+value);
    }
    if (+value === 0) {
      setPrice(0);
    }
  };

  const resetModal = () => {
    setName('');
    setPrice('');
  };
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="addServiceModal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div className="lab-service-modal">
        <div className="modal-inputs">
          <input
            autoFocus
            className="service-name"
            type="text"
            name="serviceName"
            placeholder={t('labsDetails_page.service_name')}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="price"
            type="number"
            name="price"
            placeholder={t('labsDetails_page.service_price')}
            onChange={(e) => {
              priceHandler(e);
            }}
          />
        </div>
        <div className="modal-btns">
          <Button
            isDisabled={!name || price === ''}
            type="button"
            name={t('booking_page.confirm')}
            saveData={() => {
              resetModal();
              confirmAddHandler({ name, price: +price });
            }}
          />
          <Button
            type="button"
            name={t('booking_page.close')}
            saveData={() => {
              resetModal();
              onModalClose();
            }}
            btnType="warning"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddLabServiceModal;
