import api from './api';

export const getUnitMonthStatistics = async (payload: any): Promise<any> => {
  return await api.send('getUnitMonthStatistics', payload);
};
export const addExpenses = async (payload: any): Promise<any> => {
  return await api.send('addExpenses', payload);
};

export const getDentalMonthlyReport = async (
  clinicId: string,
  month: string
) => {
  const payload = { clinicId, month };
  return await api.send('getDentalMonthlyReport', payload);
};

export const getDentLabReport = async (payload: any): Promise<any> => {
  return await api.send('getDentLabReport', payload);
};
export const getUnitDailyStatistics = async (payload: any): Promise<any> => {
  return await api.send('getUnitDailyStatistics', payload);
};
