import React, { useState, useEffect } from 'react';
import './AddEmployees.scss';
import Button from '../../../UI/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import AddSingleEmployee from './AddSingleEmployee';
import { errorToast } from '../../../../utils/toast';
import backArrow from '../../../../assets/img/backArrow.svg';
import { mobileRegex } from '../../../../assets/constants/regexValues';
import { UserRoles, paymentRoles } from '../../../../assets/constants/roles';
import EventTracker from '../../../../utils/analytics';

let data: any = {
  emp1: {
    name: { firstName: '', lastName: '' },
    mobile: '',
    role: UserRoles.Assistant,
    payment: paymentRoles.Monthly,
    salary: '',
  },
};

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler?: (payload: { type: string; data: any }) => void;
  saveClinicData: () => Promise<void>;
  currentSegment: string;
  initialState: any;
  isSubmitting: boolean;
  unitType: string;
};

const AddEmployees: React.FC<Props> = ({
  nextStepHandler,
  goBackHandler,
  saveClinicData,
  currentSegment,
  initialState,
  isSubmitting,
  unitType,
}) => {
  const { t } = useTranslation();
  // if (initialState && Object.keys(initialState).length === 0) {
  //   initialState = data;
  // }

  useEffect(() => {
    if (initialState && Object.keys(initialState).length > 0) {
      data = { ...initialState };
    }
  }, [initialState]);

  const setInputHandler = (
    name: string,
    keyName: string,
    value: string | null
  ) => {
    if (keyName === 'firstName' || keyName === 'lastName') {
      const n = data[name]?.name ? data[name].name : {};
      const m = { ...n, [keyName]: value };
      data[name] = {
        ...data[name],
        name: { ...m },
      };
    } else {
      data[name] = {
        ...data[name],
        [keyName]: value,
      };
    }
    nextStepHandler({ type: currentSegment, data });
  };

  const removeEmployeeHandler = (index: number) => {
    EventTracker.sendEvent('REMOVE_EMPLOYEE', { number: index + 1 });
    const newEmps = [...addEmployeesList];
    newEmps.splice(index, 1);
    delete data[`emp${index + 1}`];
    setAddEmployeesList([...newEmps]);
    nextStepHandler({ type: currentSegment, data });
  };

  const goBack = () => {
    if (goBackHandler) goBackHandler({ type: currentSegment, data });
  };

  const saveEmpHandler = async () => {
    // console.log('data', data);
    if (Object.keys(data).length <= 0) {
      errorToast(t('employess_page.fill_choosed'));
      return;
    }
    let isValid: boolean = false;
    let isValidMobile: boolean = false;
    for (const key of Object.keys(data)) {
      const obj = data[key];
      isValid =
        !!obj.mobile &&
        !!obj.role &&
        !!obj.name.firstName &&
        !!obj.name.lastName &&
        !!obj.salary &&
        !!obj.payment;
      isValidMobile = mobileRegex.test(obj.mobile);
    }
    if (!isValid) {
      errorToast(t('employess_page.emp-errMsg'));
      return;
    }
    if (!isValidMobile) {
      errorToast(t('booking_page.valid-mobile'));
      return;
    }
    await saveClinicData();
  };

  const mapInitialEmployees = () => {
    if (initialState && Object.keys(initialState).length > 0) {
      return Object.values(initialState).map((initEmp: any, index) => {
        return (
          <AddSingleEmployee
            key={`emp${index + 1}`}
            name={`emp${index + 1}`}
            index={index}
            setInputHandler={setInputHandler}
            removeEmployeeHandler={removeEmployeeHandler}
            initEmp={initEmp}
            unitType={unitType}
          />
        );
      });
    } else {
      return [
        <AddSingleEmployee
          key={`emp${1}`}
          name={`emp${1}`}
          index={0}
          setInputHandler={setInputHandler}
          removeEmployeeHandler={removeEmployeeHandler}
          initEmp={null}
          unitType={unitType}
        />,
      ];
    }
  };

  const [addEmployeesList, setAddEmployeesList] = useState<any>(() => {
    const initialEmployees = mapInitialEmployees();
    return initialEmployees;
  });

  const addEmployeeHandler = () => {
    EventTracker.sendEvent('ADD_EMPLOYEE_BUTTON_CLICKED', {
      number: addEmployeesList.length + 1,
    });

    data = {
      ...initialState,
      [`emp${addEmployeesList.length + 1}`]: {
        mobile: '',
        role: UserRoles.Assistant,
      },
    };
    setAddEmployeesList([
      ...addEmployeesList,
      <AddSingleEmployee
        key={`emp${addEmployeesList.length + 1}`}
        name={`emp${addEmployeesList.length + 1}`}
        index={addEmployeesList.length}
        setInputHandler={setInputHandler}
        removeEmployeeHandler={removeEmployeeHandler}
        initEmp={null}
        unitType={unitType}
      />,
    ]);
  };

  return (
    <div className="addEmployees-container  d-flex flex-column">
      <div className="addEmployees-container__main">
        {addEmployeesList.map((emp: any) => emp)}
      </div>
      <button className="add-emp link-button" onClick={addEmployeeHandler}>
        {t('employess_page.add')}
      </button>
      <Button
        name={t('employess_page.finish')}
        isSubmitting={isSubmitting}
        saveData={saveEmpHandler}
      />
      <div
        className="back go-back align-self-end"
        onClick={() => {
          goBack();
        }}
      >
        <span>{t('otp_page.back')}</span>
        <img src={backArrow} alt="back" />
      </div>
    </div>
  );
};

export default AddEmployees;
