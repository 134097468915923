export const getFileExtension = (filename: string) => {
  if (!filename) return false;
  const ext = /[.]/.exec(filename) ? /[^.]+$/.exec(filename)![0] : undefined;
  // console.log(filename, ext);
  if (ext && fileExtensions.includes(ext)) {
    return true;
  }
  return false;
};

export const fileExtensions = [
  'jpg',
  'jpeg',
  'png',
  'pdf',
  'doc',
  'txt',
  'docm',
  'docx',
  'xlsx',
  'xlsm',
  'xls',
  'xlt',
];
