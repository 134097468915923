import React, { useEffect, useState, useRef } from 'react';
import ReactModal, { Styles } from 'react-modal';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../../../assets/img/close-icon.svg';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import clinicIcon from '../../../../../assets/img/clinic-icon.svg';
import './ClinicModal.scss';

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  data: any[];
  onChoose: any;
};
const customStyles: Styles = {
  overlay: {
    background: 'none',
  },
  content: {
    position: 'absolute',
    left: '32rem',
    bottom: '0',
    border: '1px solid #ccc',
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '20px',
    top: '23rem',
    width: '26rem',
    height: '26rem',
  },
};

let targetClinic = '';
const CLinicModal = (prop: Props) => {
  const { isOpen, onModalClose, data, onChoose } = prop;
  const [unitkey, setUnitKey] = useState('');

  useEffect(() => {
    targetClinic = '';
  }, []);

  const handleSelectClinic = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUnitKey(value);
    targetClinic = data.find((unit: any) => unit.acc_unit === value);
    onChoose(targetClinic);
    onModalClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onModalClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="row modal-container">
        <div className="d-flex flex-column pb-4">
          <h6>Choose Clinic</h6>
          <div className="d-flex flex-column btn-group-toggle mt-2 ">
            {data.map((unit: any, index: any) => (
              <label
                className={`btn btn-light mt-2 text-left ${
                  unitkey === unit.acc_unit ? 'active' : ''
                }`}
              >
                <input
                  type="radio"
                  name="name"
                  value={unit.acc_unit}
                  onChange={handleSelectClinic}
                />
                <img className="clinicImg" src={clinicIcon} alt="" />
                {unit.unitName}
              </label>
            ))}
          </div>
        </div>

        {/* <div className="col-md-4">
          <img
            src={closeIcon}
            className="close"
            alt=""
            onClick={onModalClose}
          />
            <button className="ok btn btn-primary " onClick={onOk}>
            ok
          </button> 
        </div> 
        */}
      </div>
    </ReactModal>
  );
};
export default CLinicModal;
