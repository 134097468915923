import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../assets/locals/en/translation.json';
import ar from '../assets/locals/ar/translation.json';

const lang = localStorage.getItem('i18nextLng') || 'ar';
// const lang = 'ar';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // show localization data in console
    lng: lang,
    fallbackLng: lang, // use en if detected lng is not available
    nsSeparator: ':',
    keySeparator: '.', // we do not use keys in form messages.welcome
    saveMissing: true,
    whitelist: ['ar', 'en'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },

    resources: {
      en: {
        translations: en,
      },
      ar: {
        translations: ar,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
