import React from 'react';
import './UpCommingBooking.scss';

import { Booking } from '../../../types';
import { useTranslation } from 'react-i18next';

import { formatTimeAmPm, formatDateWithDay } from '../../../utils/moment';
import days from '../../../assets/constants/days';

import calenderIcon from '../../../assets/img/calender.svg';
import clockIcon from '../../../assets/img/clock.svg';
import priceIcon from '../../../assets/img/price.svg';
import bookingIcon from '../../../assets/img/medical-degree.svg';
import editIcon from '../../../assets/img/edit.svg';
import Button from '../../UI/Form/Button/Button';
import bookingStatus from '../../../assets/constants/bookingStatus';

type Props = {
  booking: Booking | null;
  goToEditBookingHandler: () => void;
  updateBookingStatusHandler: () => void;
  getCustomTypeName: (key: string) => string;
};

const UpCommingBooking: React.FC<Props> = ({
  booking,
  goToEditBookingHandler,
  updateBookingStatusHandler,
  getCustomTypeName,
}) => {
  const { t, i18n } = useTranslation();
  // const [openMoxdal, setOpenModal] = useState(false);

  return (
    <div className="upcomming-booking-details-container align-items-center d-flex flex-column justify-content-between">
      <div className="align-self-end">
        <Button
          type="button"
          name={t('addBooking_page.back')}
          btnType="back"
          isDisabled={!booking || booking.status === bookingStatus.canceled}
          saveData={updateBookingStatusHandler}
        />
      </div>
      <div className="booking d-flex flex-column align-self-stretch mt-3 ">
        <div className="patient-headerr d-flex justify-content-between align-items-center">
          <p>{t('booking_page.booking')}</p>
          <div
            className="patient-headerr-edit"
            onClick={goToEditBookingHandler}
          >
            <img src={editIcon} alt="editIcon" />
          </div>
        </div>
        {booking && booking.sk && (
          <div className="booking-details flex-fill">
            <div className="booking-item align-items-center d-flex">
              <img src={calenderIcon} alt="mobile icon" />
              <h6>
                {`${
                  days[
                    formatDateWithDay(new Date(booking.date))
                      .split(' ')[1]
                      .toLocaleLowerCase()
                  ][i18n.language]
                } ${formatDateWithDay(new Date(booking.date)).split(' ')[0]}`}
              </h6>
            </div>
            <div className="booking-item align-items-center d-flex">
              <img src={bookingIcon} alt="mobile icon" />
              <h6>{getCustomTypeName(booking.type)}</h6>
            </div>
            <div className="booking-item align-items-center d-flex">
              <img src={clockIcon} alt="mobile icon" />
              <h6>
                {`${formatTimeAmPm(new Date(booking.date)).split(' ')[0]} ${t(
                  'booking_page.' +
                    formatTimeAmPm(new Date(booking.date)).split(' ')[1]
                )}`}
              </h6>
            </div>
            <div className="booking-item payment d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <img src={priceIcon} alt="mobile icon" />
                <h6>
                  {`${booking.cost} ${t('examinationAndPrices_page.egp')}`}
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpCommingBooking;
