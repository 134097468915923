import React, { useEffect } from 'react';
import './CheckUser.scss';

import CheckUserForm from '../../components/CheckUser/CheckUser';
import RegisterClinicDetails from '../../components/Layout/RegisterClinicDetails/RegisterClinicDetails';
import { useHistory } from 'react-router-dom';
import apiService from '../../services/api';
import routes from '../../assets/constants/routes';
import tokenTypes from '../../assets/constants/tokenTypes';
import { UserStatus, UserRoles } from '../../assets/constants/roles';
import { errorToast } from '../../utils/toast';
import { isAuthenticated } from '../../utils/storage';
import EventTracker from '../../utils/analytics';

const CheckUser: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) history.push(routes.HOME);
  }, [history]);

  const checkUserHandler = async (values: any) => {
    try {
      EventTracker.sendEvent('CHECK_USER', { mobile: values.mobile });
      const payload = { ...values, checkUnits: true };
      const data = await apiService.checkUser(payload);
      // console.log('checkUserHandler', data.data);
      if (data.data.exist) {
        const user = { ...data.data.user };
        // check user status and role
        if (
          user.status === UserStatus.New &&
          [
            UserRoles.Nurse,
            UserRoles.Receptionist,
            UserRoles.Assistant,
            UserRoles.LabTech,
            UserRoles.RadioTech,
          ].includes(user.role)
        ) {
          // request token to complete profile
          await apiService.requestToken({
            mobile: user.mobile,
            tokenType: tokenTypes.updateProfile,
          });
          history.push({
            pathname: routes.COMPLETE_PROFILE,
            state: { user },
          });
        } else {
          // redirect to login page
          history.push({
            pathname: routes.LOGIN,
            state: { user },
          });
        }
      } else {
        errorToast(data.data.msg);
      }
    } catch (error) {
      // console.error('error[login]', error);
    }
  };

  return (
    <div className="login-page d-flex p-5">
      <div className="d-flex flex-column-reverse flex-sm-row ">
        <CheckUserForm checkUserHandler={checkUserHandler} />
        <RegisterClinicDetails />
      </div>
    </div>
  );
};

export default CheckUser;
