import * as Yup from 'yup';
import {
  arabicRegex,
  mobileRegex,
  nationalIdRegex,
} from '../../assets/constants/regexValues';

const bookingFormValidationSchema = (t: any) => {
  return Yup.object().shape(
    {
      firstName: Yup.string()
        .trim('The contact name cannot include leading and trailing spaces')
        .matches(arabicRegex, t('booking_page.name-arabic'))
        .min(2, t('booking_page.name-valid'))
        .required(t('booking_page.required')),
      lastName: Yup.string()
        .trim('The contact name cannot include leading and trailing spaces')
        .matches(arabicRegex, t('booking_page.name-arabic'))
        .min(2, t('booking_page.name-valid'))
        .required(t('booking_page.required')),
      mobile: Yup.string()
        .matches(mobileRegex, t('booking_page.valid-mobile'))
        .required(t('booking_page.required')),
      gov: Yup.string().required(t('booking_page.required')),
      nationalNumber: Yup.string()
        .matches(nationalIdRegex, t('booking_page.valid-nat'))
        .length(14, t('booking_page.valid-14')),
      gender: Yup.string().required(t('booking_page.required')),
      dob: Yup.string().when(['age'], {
        is: (age) => !age,
        then: Yup.string().required(t('booking_page.required')),
      }),
      age: Yup.number().when(['dob'], {
        is: (dob) => !dob,
        then: Yup.number()
          .typeError(t('booking_page.valid-number'))
          .required(t('booking_page.required'))
          .min(0, t('booking_page.valid-number'))
          .max(120, t('booking_page.valid-number')),
      }),
    },
    ['age', 'dob'] as any
  );
};

export default bookingFormValidationSchema;
