import React from 'react';
import './NotAuthorized.scss';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../../assets/constants/routes';

const NotAuthorized = () => {
  // const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="not-found d-flex align-items-center justify-content-center flex-column">
      <h3>
        {/* <span>{t('notMatch.msg')}</span> <code>{location.pathname}</code> */}
      </h3>
      <Link to={routes.HOME}>{t('notMatch.go-home')}</Link>
    </div>
  );
};

export default NotAuthorized;
