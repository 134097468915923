import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import hideIcon from '../../../../assets/img/hide.svg';
import showIcon from '../../../../assets/img/show.svg';
import { endBookingAction, resetReservation } from '../../../../store/actions';
import {
  setCurrentViewAction,
  setHideFlag,
  setPhysAllBookings,
  setPhysCollapsedSlots,
  setSomeSlotsOpen,
  setBoardCurrentBooking,
} from '../../../../store/actions/board.actions';
import { rootState } from '../../../../store/reducers';
import { getBookingGroupedBySlot } from '../../../../utils/booking';
import { getAgeFromBD } from '../../../../utils/moment';
import ChangeBookingConfirmationModal from '../../changeBookingConfirmationModal/changeBookingConfirmationModal';
import './BookingList.scss';
import BookingTimeSlot from './BookingTimeSlot/BookingTimeSlot';
import { setCurrentPatient } from '../../../../store/actions';
type Props = {
  onSelect: () => void;
  onSave: (status: string, partialUpdate: boolean) => void;
};

const BookingList = (props: Props) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [tempBooking, setTempBooking] = useState(null);
  const [checkedToggle, setCheckedToggle] = useState(true);
  const {
    bookings,
    someSlotsOpen,
    physCollapsedSlots,
    hideFlag,
    physAllBookings,
    attachments,
    reservation,
    currentBooking,
  } = useSelector((state: rootState) => state.board);

  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );

  const { currentUnitDetails } = useSelector(
    (state: rootState) => state.booking
  );

  const slots = getBookingGroupedBySlot(bookings, physAllBookings);
  const { t } = useTranslation();

  const openModalHandler = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (bookings && !currentBooking && !reservation.b?.date) {
      const activeBookingIndex = bookings.findIndex(
        (booking: any) => booking.status === 'active'
      );
      dispatch(setBoardCurrentBooking(bookings[activeBookingIndex]));
    }
    // eslint-disable-next-line
  }, [bookings]);

  const calcAgeHandler = (patient: any) => {
    return getAgeFromBD(new Date(patient.dob));
  };

  const showHideCollapsedHandler = (index: number) => {
    if (physCollapsedSlots.includes(index)) {
      dispatch(
        setPhysCollapsedSlots(
          physCollapsedSlots.filter((e: number) => e !== index)
        )
      );
      dispatch(setHideFlag(false));
      dispatch(setSomeSlotsOpen(true));
    } else {
      dispatch(setPhysCollapsedSlots([...physCollapsedSlots, index]));
      dispatch(setHideFlag(true));
      dispatch(setSomeSlotsOpen(false));
    }
  };
  const doChangeBooking = (booking: any) => {
    if (booking) {
      dispatch(resetReservation());
      setTimeout(() => {
        dispatch(setBoardCurrentBooking(booking));
        props.onSelect();
      }, 100);
    } else {
      props.onSelect();
    }
  };

  const setCurrentBookingHandler = (booking: any) => {
    // dispatch(setBoardCurrentBooking(booking));
    if (booking.sk === currentBooking?.sk) {
      if (!currentPatient) {
        dispatch(setCurrentPatient(currentBooking.patient));
      }
      props.onSelect();
    } else {
      const { lastChange } = reservation;
      if (lastChange) {
        openModalHandler();
        setTempBooking(booking);
      } else {
        doChangeBooking(booking);
      }
    }
    dispatch(setCurrentViewAction('clinicalExam'));
  };

  // const onSave = (status: string, partialUpdate: boolean = false) => {
  //   if ((!currentBooking && !reservation.pk) || !currentUnitDetails) return;
  //   let removeLocalUrls: { url: string }[] = [];
  //   if (attachments && attachments.length > 0) {
  //     removeLocalUrls = attachments.map(({ url }: any) => {
  //       const splittedUrl = url.split('?')[0];
  //       return { url: splittedUrl };
  //     });
  //   }
  //   if (currentBooking) {
  //     const payload = {
  //       ...reservation,
  //       attachments: removeLocalUrls,
  //       unit: currentUnitDetails.pk,
  //       id: currentBooking.patient.pk,
  //       patient: {
  //         name: currentBooking.patient.name,
  //         lmobile: currentBooking.patient.lmobile,
  //       },
  //       b: {
  //         type: currentBooking.type,
  //         cost: currentBooking.cost,
  //         sk: currentBooking.sk,
  //         date: currentBooking.date,
  //         linkId: currentBooking.linkId,
  //       },
  //       clinic: currentBooking.clinic,
  //       unitType: currentUnitDetails.type,
  //       dr: currentBooking.dr,
  //       status,
  //       partialUpdate,
  //     };
  //     // console.log('onSave', payload);
  //     dispatch(endBookingAction(payload));
  //   } else if (reservation.pk) {
  //     const payload = {
  //       ...reservation,
  //       attachments: removeLocalUrls,
  //       unit: currentUnitDetails.pk,
  //       unitType: currentUnitDetails.type,
  //       status,
  //       partialUpdate,
  //       id: reservation.pk,
  //     };
  //     // console.log('payload', payload);
  //     dispatch(endBookingAction(payload));
  //   }
  // };

  const onDismissModal = () => {
    setTempBooking(null);
    doChangeBooking(tempBooking);
  };

  const onConfirmModal = (status: string, partialUpdate: boolean = false) => {
    props.onSave(status, partialUpdate);
    setTimeout(() => {
      doChangeBooking(tempBooking);
      openModalHandler();
    }, 50);
  };

  const indices = Array.from(new Array(Object.keys(slots).length)).map(
    (_v: number, i: number) => i
  );

  return (
    <>
      <div className="reservation-list-sub-header justify-content-between w-100">
        <span className="toggle-span">
          <span>{t('booking_page.all-bookings')}</span>
          <Toggle
            className="custom-classname"
            defaultChecked={physAllBookings}
            // defaultChecked={allBookings}
            icons={false}
            checked={checkedToggle}
            onChange={() => {
              dispatch(setPhysAllBookings(!physAllBookings));
              dispatch(setHideFlag(true));
              dispatch(setSomeSlotsOpen(true));
              dispatch(setPhysCollapsedSlots([]));
              if (physCollapsedSlots.length === Object.keys(slots).length) {
                dispatch(setPhysCollapsedSlots([]));
                dispatch(setHideFlag(true));
                dispatch(setSomeSlotsOpen(true));
                setCheckedToggle(true);
              } else {
                dispatch(setPhysCollapsedSlots(indices));
                dispatch(setHideFlag(false));
                dispatch(setSomeSlotsOpen(false));
                setCheckedToggle(false);
              }
            }}
          />
        </span>

        <button
          onClick={() => {
            if (physCollapsedSlots.length === Object.keys(slots).length) {
              dispatch(setPhysCollapsedSlots([]));
              dispatch(setHideFlag(true));
              dispatch(setSomeSlotsOpen(true));
              setCheckedToggle(true);
            } else {
              dispatch(setPhysCollapsedSlots(indices));
              dispatch(setHideFlag(false));
              dispatch(setSomeSlotsOpen(false));
              setCheckedToggle(false);
            }
          }}
        >
          <span>
            {t(
              physCollapsedSlots.length !== Object.keys(slots).length ||
                (hideFlag && someSlotsOpen)
                ? 'booking_page.hide-booking'
                : 'booking_page.show-booking'
            )}
          </span>
          <img
            src={
              physCollapsedSlots.length !== Object.keys(slots).length ||
              (hideFlag && someSlotsOpen)
                ? hideIcon
                : showIcon
            }
            alt="showIcon"
          />
        </button>
      </div>
      {openModal && (
        <ChangeBookingConfirmationModal
          isOpen={openModal}
          bookingStatus={currentBooking ? currentBooking.status : ''}
          onModalClose={onDismissModal}
          onModalConfirm={onConfirmModal}
          closeModelWithoutAction={openModalHandler}
        />
      )}
      {slots && Object.keys(slots).length > 0
        ? Object.keys(slots)
            .sort((a: any, b: any) => {
              a = new Date(a);
              b = new Date(b);
              return a - b;
            })
            .map((slot, index) => (
              <BookingTimeSlot
                key={`timSlot-${index}`}
                index={index}
                slots={slots}
                slot={slot}
                currentUnitDetails={currentUnitDetails}
                showHideCollapsedHandler={showHideCollapsedHandler}
                collapsedSlots={physCollapsedSlots}
                // collapsedSlots={collapsedSlots}
                setCurrentBookingHandler={setCurrentBookingHandler}
                currentBooking={currentBooking}
                calcAgeHandler={calcAgeHandler}
              />
            ))
        : null}
    </>
  );
};

export default BookingList;
