import { useField, useFormikContext } from 'formik';
import React from 'react';
import parseArabicNumber from '../../../../utils/parseArabicNumber';
import './NumberInput.scss';

type InputProps = {
  iconClick?: () => void;
  inputChange?: (value: string) => void;
  name: string;
  placeholder?: string;
  type: string;
  icon?: string;
  isIcon?: boolean;
  className?: string;
  pattern?: string;
  disabled?: boolean;
  autoComplete?: string;
  onChangeValue?: (value: string, fieldName: string) => void;
};

const Input: React.FC<InputProps> = ({
  icon,
  isIcon,
  iconClick,
  disabled,
  inputChange,
  onChangeValue,
  type,
  autoComplete,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const onInputChange = (value: string) => {
    if (inputChange) {
      inputChange(value);
    }
  };

  return (
    <div className="input-container">
      <input
        {...props}
        {...field}
        inputMode="numeric"
        type={type || 'text'}
        onKeyDown={(e: any) => {
          const value = parseArabicNumber(e.target.value);
          setFieldValue(field.name, value);
        }}
        onKeyUp={(e: any) => {
          const value = parseArabicNumber(e.target.value);
          // console.log('value', value);
          setFieldValue(field.name, value);
          if (onChangeValue) onChangeValue(value, field.name);
          onInputChange(value);
        }}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      {isIcon ? (
        <img className="icon" src={icon} alt="icon" onClick={iconClick}></img>
      ) : null}

      <div className="error ">
        {meta.touched && meta.error ? (
          <span className="error">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
