import React, { useState, useEffect } from 'react';
import './WorkingDay.scss';
import Toggle from 'react-toggle';
import { useTranslation } from 'react-i18next';

import ShiftTimes from './ShiftTimes';

type Props = {
  day: string;
  shouldToggle: boolean;
  changeWorkingDays: (type: string, payload: any) => void;
  type: string;
  initialState: any;
};

let data: any = {};

const WorkingDay: React.FC<Props> = ({
  day,
  shouldToggle,
  changeWorkingDays,
  type,
  initialState,
}) => {
  // console.log('initialState', initialState);
  const { t } = useTranslation();
  const [toggle, setSetToggle] = useState(shouldToggle);
  if (initialState && Object.keys(initialState).length === 0) {
    initialState = data;
  }

  useEffect(() => {
    // console.log('initialState[useEffect]', initialState);
    if (initialState && Object.keys(initialState).length > 0) {
      data = { ...initialState };
    }
  }, [initialState]);

  const handleChange = (name: string, keyName: string, value: string) => {
    const ff = {
      [name]: {
        ...data[name],
        [keyName]: value,
      },
    };
    data = { ...data, ...ff };
    // console.log('data', data);
    changeWorkingDays(type, data);
  };

  const mapInitialShifts = () => {
    // if (initialState && Object.keys(initialState).length > 0) {
    return Object.values(initialState).map((initShift: any, index) => {
      return (
        <ShiftTimes
          key={`shift${index + 1}`}
          name={`shift${index + 1}`}
          from={initShift.from}
          to={initShift.to}
          handleChange={handleChange}
        />
      );
    });
  };

  const [shifts, setShifts] = useState<any>(() => {
    const initialShifts = mapInitialShifts();
    return initialShifts;
  });

  const handleTofuChange = (e: any) => {
    // console.log('e.target.checked', e.target.checked);
    setSetToggle(e.target.checked);
    if (!e.target.checked) {
      data = {};
      changeWorkingDays(type, {});
    } else {
      data = {
        shift1: {
          from: '10:00',
          to: '23:00',
        },
      };
      setShifts([
        <ShiftTimes
          key={1}
          name={`shift${1}`}
          handleChange={handleChange}
          from={data && data[`shift${1}`].from}
          to={data && data[`shift${1}`].to}
        />,
      ]);
      changeWorkingDays(type, data);
    }
  };

  const addTimeHandler = () => {
    data = {
      ...data,
      [`shift${shifts.length + 1}`]: {
        from: '10:00',
        to: '23:00',
      },
    };
    setShifts([
      ...shifts,
      <ShiftTimes
        key={`shift${shifts.length + 1}`}
        name={`shift${shifts.length + 1}`}
        handleChange={handleChange}
        from={data[`shift${shifts.length + 1}`].from}
        to={data[`shift${shifts.length + 1}`].to}
      />,
    ]);
    changeWorkingDays(type, data);
  };

  const removeTimeHandler = (index: number) => {
    const newShifts = [...shifts];
    newShifts.splice(index, 1);
    delete data[`shift${index + 1}`];
    changeWorkingDays(type, data);
    setShifts([...newShifts]);
  };

  return (
    <div
      className="workingDays-container__days--day mb-4"
      style={{
        alignSelf: toggle ? 'inherit' : 'baseline',
      }}
    >
      <div className="workingDays-container__days--day-date d-flex justify-content-between ">
        <p>{day}</p>
        <div>
          <Toggle
            className="custom-classname"
            defaultChecked={toggle}
            icons={false}
            onChange={handleTofuChange}
          />
        </div>
      </div>
      {toggle ? (
        <>
          {shifts.length > 0 &&
            shifts.map((ele: any, index: any) => (
              <div
                key={index}
                className="workingDays-container__days--day-shifts d-flex flex-wrap justify-content-between align-items-center mt-2"
              >
                {ele}
                <div className="timing align-self-end">
                  {index !== 0 ? (
                    <button
                      className="remove link-button m-2 ml-sm-3 mr-sm-3"
                      onClick={() => {
                        removeTimeHandler(index);
                      }}
                    >
                      {t('working_days_page.remove')}
                    </button>
                  ) : null}
                  <button className="link-button" onClick={addTimeHandler}>
                    {t('working_days_page.add')}
                  </button>
                </div>
              </div>
            ))}
        </>
      ) : null}
    </div>
  );
};

export default WorkingDay;
