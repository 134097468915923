import React, { useState, useEffect } from 'react';
import './Profile.scss';

import { useHistory } from 'react-router-dom';
import apiService from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImages from '../../components/Profile/ProfileImages/ProfileImages';
import ProfileForm from '../../components/Profile/ProfileForm/ProfileForm';
import Button from '../../components/UI/Form/Button/Button';
import routes from '../../assets/constants/routes';
import { nationalIdRegex } from '../../assets/constants/regexValues';
import { storeData } from '../../utils/storage';
import { getClinicianUnits, setUserProfile } from '../../store/actions';
import { errorToast } from '../../utils/toast';
import { UserRoles } from '../../assets/constants/roles';
import { rootState } from '../../store/reducers';

const inputData = {
  firstName: 'firstName',
  lastName: 'lastName',
  medicalDegree: 'medicalDegree',
  specialty: 'specialty',
  nationalNumber: 'nationalNumber',
  associationScan: 'associationScan',
  nationalScan: 'nationalScan',
  profileImg: 'profileImg',
  licenseNumber: 'licenseNumber',
};

let initialState: any = {
  firstName: '',
  lastName: '',
  specialty: '',
  medicalDegree: '',
  nationalNumber: '',
  nationalScan: '',
  profileImg: '',
  associationScan: '',
};

const Profile: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [personalData, setPersonalData] = useState({
    firstName: '',
    lastName: '',
    medicalDegree: '',
    specialty: '',
    licenseNumber: '',
  });

  const userProfile = useSelector((state: rootState) => state.auth.user);

  useEffect(() => {
    const checkLanguage = async (lang: string) => {
      await i18n.changeLanguage(lang);
    };
    if (i18n.language === 'ar') {
      checkLanguage('en');
    }
  }, [i18n]);

  const [profileImg, setProfileImg] = useState<any>('');
  const [nationalScan, setNationalScan] = useState<any>('');
  const [nationalNumber, setNationalNumber] = useState<any>('');
  const [associationScan, setAssociationScan] = useState<any>('');
  const [uploading, setUploading] = useState<any>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileLocalImg, setProfileLocalImg] = useState<any>('');
  const [nationalScanLocalImg, setNationalScanLocalImg] = useState<any>('');
  const [associationScanImg, setAssociationScanImg] = useState<any>('');

  const getImage = (file: File, type: string) => {
    if (!file || !file.type || !file.type.startsWith('image/')) {
      errorToast(t('register_page.invalid'));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (_e: any) => {
      if (type === 'profile') {
        setProfileLocalImg(reader.result);
      } else if (type === 'national') {
        setNationalScanLocalImg(reader.result);
      } else if (type === 'association') {
        setAssociationScanImg(reader.result);
      }
      uploadFile(file, type);
    };
  };

  const uploadFile = async (file: any, type?: string) => {
    if (!file) return;
    setUploading(true);
    const imgUrl = await apiService.upload(
      file,
      t('register_page.upload-img'),
      t('register_page.upload-img-done'),
      t('register_page.upload-img-err'),
      type === 'profile'
    );
    setUploading(false);
    if (!imgUrl) return;
    if (type === 'profile') {
      setProfileImg(imgUrl);
    } else if (type === 'national') {
      setNationalScan(imgUrl);
    } else if (type === 'association') {
      setAssociationScan(imgUrl);
    }
  };

  const onDataChange = async (payload: { type: string; data: any }) => {
    // console.log('payload', payload);
    if (payload.type === inputData.nationalNumber) {
      setNationalNumber(payload.data.nationalNumber);
    } else if (payload.type === inputData.firstName) {
      setPersonalData({ ...personalData, firstName: payload.data });
    } else if (payload.type === inputData.lastName) {
      setPersonalData({ ...personalData, lastName: payload.data });
    } else if (payload.type === inputData.specialty) {
      setPersonalData({ ...personalData, specialty: payload.data });
    } else if (payload.type === inputData.medicalDegree) {
      setPersonalData({
        ...personalData,
        medicalDegree: payload.data,
      });
    } else if (payload.type === inputData.licenseNumber) {
      setPersonalData({
        ...personalData,
        licenseNumber: payload.data,
      });
    }
  };

  useEffect(() => {
    if (!userProfile) return;
    // console.log('user', userProfile);
    initialState = { ...userProfile };
    setPersonalData({
      firstName: initialState.name.firstName,
      lastName: initialState.name.lastName,
      medicalDegree: initialState.medicalDegree,
      specialty: initialState.specialty,
      licenseNumber: initialState.licenseNumber,
    });

    setProfileLocalImg(initialState.profileImg);
    setProfileImg(initialState.profileImg);

    setAssociationScanImg(initialState.associationScan);
    setAssociationScan(initialState.associationScan);

    setNationalScan(initialState.nationalScan);
    setNationalScanLocalImg(initialState.nationalScan);

    setNationalNumber(initialState.nationalNumber);
  }, [userProfile]);

  const saveEditProfileHandler = async () => {
    try {
      const payload = {
        ...personalData,
        nationalNumber,
        nationalScan,
        profileImg,
        associationScan,
        role: UserRoles.Physician,
      };

      if (
        payload.nationalNumber &&
        payload.nationalNumber.length !== 14 &&
        !RegExp(nationalIdRegex).test(payload.nationalNumber)
      ) {
        errorToast(t('complete_profile.nat-invalid'));
        return;
      }
      // console.log('payload', payload);
      setIsSubmitting(true);
      const {
        data: { user },
      } = await apiService.updateUserProfile(payload);
      storeData({ user });
      dispatch(setUserProfile(user));
      setIsSubmitting(false);
      dispatch(getClinicianUnits(history));
      // history.push(routes.HOME);
    } catch {
      setIsSubmitting(false);
    }
  };

  const cancelEditProfileHandler = () => {
    history.push(routes.HOME);
  };

  return (
    <div className="profile-page d-flex flex-column align-items-center p-5 ">
      <div className="d-flex flex-column">
        <div className="profile-page-header align-items-center">
          <p className="profile-page-header-title">
            {t('complete_profile.edit-profile')}
          </p>
          <div className="profile-page-header-actionButton">
            <Button
              name={t('working_days_page.save')}
              saveData={saveEditProfileHandler}
              isSubmitting={isSubmitting || uploading}
            />
            <Button
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={cancelEditProfileHandler}
            />
          </div>
        </div>
        <div className="profile-page__main d-flex flex-column flex-sm-row">
          <ProfileForm
            personalData={personalData}
            onDataChange={onDataChange}
          />
          <div>
            <ProfileImages
              getImage={getImage}
              onNatNumChange={onDataChange}
              uploading={uploading}
              profileLocalImg={profileLocalImg}
              nationalScanLocalImg={nationalScanLocalImg}
              associationScanImg={associationScanImg}
              nationalNumber={nationalNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
