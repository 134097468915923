import moment from 'moment';
import { TimeSlot, TimeSlotGroupType, Booking, ClinicData } from '../types';
import i18n from './i18n';
import { formatDate, formatTime, getDayName } from '../utils/moment';
// import unitTypes from '../utils/unitTypes';

// new Timeslot group
export const groupTimeSlot = (
  bookings: Booking[],
  currentUnitDetails: ClinicData,
  bookingDate: Date
) => {
  if (!currentUnitDetails) return;
  // get day name
  const dayName = getDayName(new Date(bookingDate)).toLocaleLowerCase();

  // get starting and end periods[slots] for unit in this day and if not found get first in setting

  // const defaultSlots =
  //   (currentUnitDetails.workingDays &&
  //     currentUnitDetails.workingDays[dayName]) ||
  //   (currentUnitDetails.workingDays &&
  //     currentUnitDetails.workingDays[
  //       Object.keys(currentUnitDetails.workingDays)[0]
  //     ]) ||
  //   {};

  const defaultSlots = (currentUnitDetails.workingDays &&
    currentUnitDetails.workingDays[dayName]) || {
    shift1: { from: '00:00', to: '23:59' },
  };

  // console.log('bookings', bookings);

  let sDate = new Date(bookingDate.setHours(0, 0, 0, 0)).getHours();
  const eDate = new Date(bookingDate.setHours(23, 0, 0, 0)).getHours();

  const group: any = {};
  while (sDate <= eDate) {
    for (const slot of Object.values(defaultSlots)) {
      if (slot.to.split(':')[1] === '00') {
        if (
          sDate >= +slot.from.split(':')[0] &&
          sDate < +slot.to.split(':')[0]
        ) {
          const key: string = sDate < 10 ? `0${sDate}:00` : `${sDate}:00`;
          if (!group[key]) group[key] = [];
        }
      } else {
        if (
          sDate >= +slot.from.split(':')[0] &&
          sDate <= +slot.to.split(':')[0]
        ) {
          const key: string = sDate < 10 ? `0${sDate}:00` : `${sDate}:00`;
          if (!group[key]) group[key] = [];
        }
      }
    }
    // const key: string = sDate < 10 ? `0${sDate}:00` : `${sDate}:00`;
    // if (key >= defaultBookingTime && !group[key]) group[key] = [];
    sDate += 1;
  }
  const groupp: any = bookings.reduce((periods: any, book) => {
    const date = new Date(book.date).getHours();
    const date1 = new Date(book.date).getDate();
    // console.log(`data= ${date}`)
    // console.log(`data= ${date1}`)
    const key: string = date < 10 ? `0${date}:00` : `${date}:00`;
    if (!periods[key]) {
      periods[key] = [];
    }
    const timeSlot = {
      booking: book,
      hasReservation: true,
    };
    periods[key].push(timeSlot);
    return periods;
  }, {});
  const slots = { ...group, ...groupp };
  // console.log('slots', slots);
  const currentHour = new Date().getHours();
  const fKey: string =
    currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`;
  const fSlots: any = {};
  Object.keys(slots).forEach((slot) => {
    if (slot >= fKey || slots[slot].length > 0) {
      fSlots[slot] = [...slots[slot]];
    }
  });
  // REVIEW: messam
  return bookingDate.getDate() === new Date().getDate() ? fSlots : slots;
};

// old Timeslot group
export const groupTimeSlotss = (
  bookings: Booking[],
  currentUnitDetails: ClinicData,
  bookingDate: Date
) => {
  const group: TimeSlotGroupType = {};

  const addedMinutes =
    currentUnitDetails &&
    currentUnitDetails.examinationTypes &&
    currentUnitDetails.examinationTypes.normal
      ? +currentUnitDetails.examinationTypes.normal.time
      : 15;

  const dayName = getDayName(new Date(bookingDate)).toLocaleLowerCase();
  const defaultBookingTime =
    currentUnitDetails &&
    currentUnitDetails.workingDays &&
    currentUnitDetails.workingDays[dayName] &&
    currentUnitDetails.workingDays[dayName].shift1
      ? currentUnitDetails.workingDays[dayName].shift1.from
      : '10:00';

  const startDate = moment(bookingDate)
    .hour(+defaultBookingTime.split(':')[0])
    .minute(+defaultBookingTime.split(':')[1]);
  const stopDate = moment(bookingDate).hour(24).minute(0);

  while (startDate.date() !== stopDate.date()) {
    const timeSlot: TimeSlot = {
      startDate: startDate.toDate(),
      endDate: moment(startDate).add(addedMinutes, 'minute').toDate(),
      booking: null,
      hasReservation: false,
    };

    // given it is sorted
    bookings.forEach((booking) => {
      if (moment(new Date(booking.date)).isSame(startDate, 'minute')) {
        timeSlot.booking = booking;
        timeSlot.hasReservation = true;
      }
    });

    // assign it to a group
    const hour: number = startDate.hour();
    const key: string =
      hour < 10 ? `0${hour.toString()}:00` : `${hour.toString()}:00`;

    // if (groupTime !== 60) {
    //   const minutes = startDate.minute();

    // get the minutes to determine which period it belong hour:00 or hour:30
    // if (minutes < 30) key = key.concat('00');
    // else key = key.concat('30');
    // }

    if (group[key]) group[key].push(timeSlot);
    else group[key] = [timeSlot];

    startDate.add(addedMinutes, 'minute');
  }

  return group;
};

export const getBusyTimeSlot = (
  bookings: Booking[],
  bookingDate: Date
): string[] => {
  return bookings
    .filter(
      (booking: Booking) => formatDate(bookingDate) === formatDate(booking.date)
    )
    .map((booking: Booking) => formatTime(booking.date));
};

const convertHour = (startDate: any) => {
  moment.locale(i18n.language);
  return moment(startDate).format('hh:mm');
};

export const getTimeSlots = (
  bookingDate: Date,
  currentUnitDetails: ClinicData,
  bookings: Booking[] = [],
  time?: string
) => {
  const groupTime = currentUnitDetails?.examinationTypes?.normal
    ? +currentUnitDetails.examinationTypes.normal.time
    : 15;

  const dayName = getDayName(new Date(bookingDate)).toLocaleLowerCase();
  const defaultBookingTime =
    currentUnitDetails?.workingDays &&
    currentUnitDetails.workingDays[dayName] &&
    currentUnitDetails.workingDays[dayName].shift1
      ? currentUnitDetails.workingDays[dayName].shift1.from
      : '00:00';

  const defaultEndTime =
    currentUnitDetails?.workingDays &&
    currentUnitDetails.workingDays[dayName] &&
    currentUnitDetails.workingDays[dayName].shift1
      ? currentUnitDetails.workingDays[dayName].shift1.to
      : '23:59';

  const busySlots = getBusyTimeSlot(bookings, bookingDate);

  const group: any = {};

  const startDate = moment(bookingDate)
    .hour(+defaultBookingTime.split(':')[0])
    .minute(+defaultBookingTime.split(':')[1]);
  const stopDate = moment(bookingDate)
    .hour(
      defaultEndTime.split(':')[1] === '00'
        ? +defaultEndTime.split(':')[0] - 1
        : +defaultEndTime.split(':')[0]
    )
    .minute(+defaultEndTime.split(':')[1]);

  while (
    startDate.hours() <= stopDate.hours() &&
    startDate.date() <= stopDate.date()
  ) {
    const hour: number = startDate.hour();
    const minutes: number = startDate.minute();
    const mmm = minutes < 10 ? `0${minutes}` : minutes;
    const key: string = hour < 10 ? `0${hour}:${mmm}` : `${hour}:${mmm}`;
    const formtedhour = convertHour(startDate);
    const timeSlot: any = {
      ar: `${formtedhour} ${hour < 12 ? 'ص' : 'م'}`,
      en: `${formtedhour} ${hour < 12 ? 'AM' : 'PM'}`,
    };
    if (
      !busySlots.includes(key) &&
      new Date(startDate.format()).getTime() > Date.now()
    ) {
      group[key] = timeSlot;
    }

    // if (currentUnitDetails && currentUnitDetails.type !== unitTypes.clinic) {
    //   group[key] = timeSlot;
    // }

    startDate.add(groupTime, 'minute');
  }

  return group;
};

export const getBookingInHour = (currentUnitDetails: ClinicData | null) => {
  if (!currentUnitDetails) return 0;
  const addedMinutes =
    currentUnitDetails &&
    currentUnitDetails.examinationTypes &&
    currentUnitDetails.examinationTypes.normal
      ? +currentUnitDetails.examinationTypes.normal.time
      : 15;

  return Math.floor(60 / addedMinutes);
};

export const getResrvedTime = (currentUnitDetails: ClinicData | null) => {
  if (!currentUnitDetails) return 0;
  const reservedTime =
    currentUnitDetails &&
    currentUnitDetails.examinationTypes &&
    currentUnitDetails.examinationTypes.normal
      ? +currentUnitDetails.examinationTypes.normal.time
      : 15;
  return reservedTime;
};
