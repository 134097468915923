import React, { useState } from 'react';
import './LoginForm.scss';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import routes from '../../assets/constants/routes';
import PasswordInput from '../UI/Form/Password/PasswordInput';
import Button from '../UI/Form/Button/Button';
import formValidationSchema from './FormValidation';

import defaultProfile from '../../assets/img/avatar.png';
import lockIcon from '../../assets/img/lock.svg';
import eyeIcon from '../../assets/img/eye.svg';
import visibilityIcon from '../../assets/img/visibility.svg';

type Props = {
  Loginhandler: (payload: any) => Promise<void>;
  user: any;
};

const LoginForm: React.FC<Props> = ({ Loginhandler, user }) => {
  const { t } = useTranslation();

  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const iconClickHandler = () => {
    setShouldShowPassword((showPassword) => !showPassword);
  };

  return (
    <div className="login-user-page-container d-flex align-items-center justify-content-center flex-column p-5">
      <div className="image-name d-flex align-self-start">
        <img src={user?.profileImg || defaultProfile} alt="profile" />
        <div className="d-flex align-items-center flex-column">
          <h6 className="align-self-start">
            {t('login_user_page.welcome')}{' '}
            {user && user.name ? user.name.firstName : ''}
          </h6>
          <Link to={routes.CHECK_USER}>
            <span>{t('login_user_page.change_account')}</span>
          </Link>
        </div>
      </div>
      <div className="login-user-page-form align-items-center align-self-stretch d-flex mt-5">
        <div className="align-items-center d-flex flex-column p-5">
          <div className="lock-icon d-flex align-self-start">
            <img src={lockIcon} alt="lockIcon"></img>
            <h6>{t('login_user_page.password')}</h6>
          </div>
          <Formik
            initialValues={{
              mobile: user.mobile || '',
              password: '',
            }}
            validationSchema={formValidationSchema(t)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await Loginhandler(values);
            }}
            enableReinitialize={true}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="passwordInput mt-3">
                  <PasswordInput
                    type={shouldShowPassword ? 'text' : 'password'}
                    name="password"
                    placeholder={t('complete_profile.password')}
                    icon={shouldShowPassword ? eyeIcon : visibilityIcon}
                    isIcon={true}
                    iconClick={iconClickHandler}
                  />
                </div>
                <div className="forget">
                  <Link to={routes.FORGET_PASSWORD}>
                    <span>{t('login_user_page.forget')}</span>
                  </Link>
                </div>
                <div className="mt-4">
                  <Button
                    type="submit"
                    name={t('login_user_page.login')}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
