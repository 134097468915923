const medicalDegrees: any = {
  // bachelor: { en: 'Bachelor', ar: 'بكالوريوس' },
  // master: { en: 'Master', ar: 'ماجستير' },
  // fellow: { en: 'Fellow', ar: 'زميل' },
  // diploma: { en: 'Diploma', ar: 'دبلومة' },
  professor: { en: 'Professor', ar: '' },
  assistantProfessor: { en: 'Assistant professor', ar: '' },
  consultant: { en: 'Consultant', ar: '' },
  lecturer: { en: 'Lecturer', ar: '' },
  assistantLecturer: { en: 'Assistant lecturer', ar: '' },
  specialist: { en: 'Specialist', ar: '' },
  generalPractitioner: { en: 'General  Practitioner', ar: '' },
};

export default medicalDegrees;
