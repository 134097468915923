import React from 'react';
import './NoEmployees.scss';
import employeesIcon from '../../../../assets/img/employees.svg';
import Button from '../../../UI/Form/Button/Button';
import { useTranslation } from 'react-i18next';

type Props = {
  showNoEmployeePageHandler: () => void;
};

const NoEmployees: React.FC<Props> = ({ showNoEmployeePageHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="no-employees">
      <div className="no-employees-data d-flex flex-column p-5">
        <img src={employeesIcon} alt="employeesIcon" />
        <p>{t('employess_page.noEmployees-msg')}</p>
      </div>
      <Button
        name={t('employess_page.add_user')}
        saveData={showNoEmployeePageHandler}
      />
    </div>
  );
};

export default NoEmployees;
