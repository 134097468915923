import React, { useState, useEffect } from 'react';
import './LabBookingDetails.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/Form/Button/Button';
import CancelBookingModal from './CancelBookingModal/CancelBookingModal';

import { Booking } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { setGanderByLang } from '../../../assets/constants/gender';
import {
  formatTimeAmPm,
  formatDateWithDay,
  getAgeFromBD,
} from '../../../utils/moment';
import bookingStatus from '../../../assets/constants/bookingStatus';

import calenderIcon from '../../../assets/img/calender.svg';
import clockIcon from '../../../assets/img/clock.svg';
import bookingIcon from '../../../assets/img/medical-degree.svg';
import priceIcon from '../../../assets/img/price.svg';
import editIcon from '../../../assets/img/edit.svg';
import nameIcon from '../../../assets/img/personal.svg';
import mobileIcon from '../../../assets/img/mobile.svg';
import genderIcon from '../../../assets/img/gender.svg';
import days from '../../../assets/constants/days';
import { useHistory } from 'react-router-dom';
import routes from '../../../assets/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPatient } from '../../../store/actions';
import { rootState } from '../../../store/reducers';
import parseArabic from '../../../utils/parseArabic';

type Props = {
  currentBooking: Booking | null;
  updateBookingStatusHandler: (status: string) => void;
  updateBookingCostHandler: (paid: number) => void;
};

const LabBookingDetails: React.FC<Props> = ({
  currentBooking,
  updateBookingStatusHandler,
  updateBookingCostHandler,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [paidPrice, setPaidPrice] = useState<number | string>('');
  const [remainingPrice, setRemainingPrice] = useState<number | string>('');

  const isLoading = useSelector((state: rootState) => state.booking.isLoading);

  useEffect(() => {
    if (currentBooking && currentBooking.cost) {
      setPaidPrice(
        currentBooking && currentBooking.paid ? +currentBooking.paid : ''
      );
      setRemainingPrice(
        currentBooking && currentBooking.paid
          ? +currentBooking.cost - +currentBooking.paid
          : +currentBooking.cost
      );
    }
  }, [currentBooking]);

  const calcAgeHandler = (patient: any) => {
    let age: any;
    if (patient && patient.dob) {
      const patientAge = getAgeFromBD(new Date(patient.dob));
      age = `${patientAge.split(' ')[0]} ${t(
        'booking_page.' + patientAge.split(' ')[1]
      )}`;
    } else {
      age = `${patient.age} ${t('booking_page.age')}`;
    }

    return age;
  };

  const changePriceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseArabic(e.target.value).replace(/\D|-/g, '');
    const remainig = +currentBooking!.cost - value;
    setPaidPrice(value ? value : '');
    setRemainingPrice(remainig);
  };

  const openModalHandler = () => {
    setOpenModal(!openModal);
  };

  const goToEditPatientHandler = () => {
    if (!currentBooking || currentBooking.date < Date.now()) return;
    history.push({
      pathname: routes.ADD_PATIENT,
      state: { patient: currentBooking.patient },
    });
  };

  const goToEditBookingHandler = () => {
    // console.log('edit booking', currentBooking);
    if (!currentBooking || currentBooking.status === bookingStatus.completed)
      return;
    dispatch(setCurrentPatient(currentBooking.patient));
    history.push({
      pathname: routes.ADD_BOOKING,
      state: { booking: currentBooking },
    });
  };

  return (
    <div className="booking-details-container">
      <CancelBookingModal
        isOpen={openModal}
        onModalClose={openModalHandler}
        confirmCancelHandler={() => {
          openModalHandler();
          updateBookingStatusHandler(bookingStatus.canceled);
        }}
      />
      <div className="booking-actions align-items-center d-flex justify-content-between">
        <h6>{t('addBooking_page.booking')}</h6>
        <div className="button-actions d-flex">
          <Button
            type="button"
            name={t('booking_page.cancel')}
            btnType="warning"
            isDisabled={
              !currentBooking ||
              ![bookingStatus.new].includes(currentBooking.status)
            }
            saveData={openModalHandler}
          />
        </div>
      </div>
      <div className="booking-details-container--data align-items-center d-flex flex-column flex-sm-row justify-content-between mt-5">
        <div className="patient align-self-start">
          <div className="patient-header d-flex flex-fill justify-content-between align-items-center">
            <p>{t('booking_page.patient')}</p>
            <div
              className="patient-header-edit"
              onClick={goToEditPatientHandler}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          {currentBooking?.patient && (
            <div className="patient-details d-flex flex-column justify-content-between">
              <div className="booking-item align-items-center d-flex">
                <img src={nameIcon} alt="mobile icon" />
                <h6 className="ar-font">
                  {getFullName(currentBooking.patient.name)}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={genderIcon} alt="mobile icon" />
                <h6>{setGanderByLang(currentBooking.patient.gender)}</h6>
              </div>

              <div className="booking-item mobile-icon align-items-center d-flex">
                <img src={mobileIcon} alt="mobile icon" />
                <h6 className="mobile">
                  {currentBooking.patient.lmobile || ''}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={calenderIcon} alt="calender icon" />
                <h6>{calcAgeHandler(currentBooking.patient)}</h6>
              </div>
              {currentBooking && currentBooking.targetDr && (
                <div className="booking-item align-items-center d-flex">
                  <img src={nameIcon} alt="mobile icon" />
                  <h6 className="ar-font">
                    {currentBooking &&
                    currentBooking.targetDr &&
                    currentBooking.targetDr.name
                      ? `${t('board-header_page.dr')}. ${getFullName(
                          currentBooking.targetDr.name
                        )}`
                      : ''}
                  </h6>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="booking align-self-start">
          <div className="patient-header d-flex flex-fill justify-content-between align-items-center">
            <p>{t('booking_page.booking')}</p>
            <div
              className="patient-header-edit"
              onClick={goToEditBookingHandler}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          {currentBooking && currentBooking.sk && (
            <div className="booking-details">
              <div className="booking-item align-items-center d-flex">
                <div className="d-flex">
                  <img src={calenderIcon} alt="mobile icon" />
                  <h6>
                    {`${
                      days[
                        formatDateWithDay(new Date(currentBooking.date))
                          .split(' ')[1]
                          .toLocaleLowerCase()
                      ][i18n.language]
                    } ${
                      formatDateWithDay(new Date(currentBooking.date)).split(
                        ' '
                      )[0]
                    }`}
                  </h6>
                </div>
                <div className="d-flex">
                  <img src={clockIcon} alt="mobile icon" />
                  <h6>{`${
                    formatTimeAmPm(new Date(currentBooking.date)).split(' ')[0]
                  } ${t(
                    'booking_page.' +
                      formatTimeAmPm(new Date(currentBooking.date)).split(
                        ' '
                      )[1]
                  )}`}</h6>
                </div>
              </div>
              <div className="booking-item test-types mt-5">
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ padding: '1rem 0' }}
                >
                  <div className="d-flex">
                    <img src={bookingIcon} alt="mobile icon" />
                    <h6>{t('booking_page.scan')}</h6>
                  </div>
                  <div className="d-flex">
                    <img src={priceIcon} alt="mobile icon" />
                    <h6>{t('booking_page.price')}</h6>
                  </div>
                </div>
                <div className="table">
                  {currentBooking.tests &&
                    currentBooking.tests.length > 0 &&
                    currentBooking.tests.map((test, ind) => (
                      <div
                        key={`${test.code}-ind`}
                        className="d-flex justify-content-between align-items-center p-3"
                      >
                        <h6>{test.name}</h6>
                        <h6>{test.price}</h6>
                      </div>
                    ))}
                </div>
              </div>
              <div className="price d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center">
                  <h6>{t('booking_page.total')}</h6>
                  <h6 className="data">
                    {currentBooking.cost}{' '}
                    <span>{t('examinationAndPrices_page.egp')}</span>{' '}
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <h6>{t('booking_page.remaining')}</h6>
                  <h6 className="data">
                    {remainingPrice}{' '}
                    <span>{t('examinationAndPrices_page.egp')}</span>{' '}
                  </h6>
                </div>
                <div className="d-flex align-items-center  ">
                  <h6>{t('booking_page.paid')}</h6>
                  <input
                    type="number"
                    className="data"
                    onChange={changePriceHandler}
                    value={paidPrice}
                  />
                </div>
              </div>
              <div className="price d-flex justify-content-between align-items-center ">
                <div className="d-flex ">
                  <Button
                    type="button"
                    name={t('booking_page.pay')}
                    isDisabled={
                      !currentBooking ||
                      currentBooking.status === bookingStatus.canceled ||
                      +currentBooking.cost === remainingPrice ||
                      +paidPrice === +currentBooking.paid
                    }
                    isSubmitting={isLoading}
                    saveData={() => {
                      updateBookingCostHandler(+paidPrice);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabBookingDetails;
