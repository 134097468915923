import React, { useEffect, useState } from 'react';
import './DentalHistoryView.scss';
import { rootState } from '../../../../store/reducers';
import ComplainComponent from '../../../DentalBoard/TreatmentView/TreatmentComponent/ComplainComponent/ComplainComponent';
import OperationComponent from '../../../DentalBoard/TreatmentView/TreatmentComponent/OperationComponent/OperationComponent';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import { updateReservation } from '../../../../store/actions/board.actions';

import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentBooking } from '../../../../store/actions/board.actions';
import AttachmentsComponent from '../AttachmentsComponent/AttachmentsComponent';
import PrescriptionComponent from '../PrescriptionComponent/PrescriptionComponent';
import { formatCAlDate } from '../../../../utils/moment';
import { number } from 'yup';
import { isNewDate } from '../../../../utils/moment';
type Props = { currentEncounter: any };

const DentalHistoryView = (props: Props) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('complain');
  const currentBooking = useSelector(
    (state: rootState) => state.board.currentBooking
  );
  const currentReservation = useSelector(
    (state: rootState) => state.board.reservation
  );
  const setOperations = (operations: any) => {
    dispatch(updateReservation({ operations, lastChange: new Date() }));
  };
  const currentEncounter = useSelector(
    (state: rootState) => state.board.currentEncounter
  );
  const [filteredOperations, setFilteredOPerations] = useState<any[]>([]);

  const changeTabHandler = (tab: string) => {
    setTab(tab);
  };

  const checkOperationStep = (index: number, step: string) => {
    let allChecked: boolean = true;
    let noneChecked: boolean = true;
    for (const currentStep of reservation.operations[index].operationSteps) {
      if (currentStep.step === step)
        currentStep.stepCompleted = !currentStep.stepCompleted;
      if (!currentStep.stepCompleted) allChecked = false;
      if (currentStep.stepCompleted) noneChecked = false;
    }
    allChecked
      ? (reservation.operations[index].operationStatus = 'Completed')
      : noneChecked
      ? (reservation.operations[index].operationStatus = 'Planned')
      : (reservation.operations[index].operationStatus = 'In progress');
    setOperations([...reservation.operations]);
  };
  const reservation = useSelector(
    (state: rootState) => state.board.reservation
  );

  const openInNewTab = (image: { localUrl?: string; url: string }) => {
    // console.log('image', image);
    const url = image.localUrl ? image.localUrl : image.url;
    window.open(url, '_blank');
  };

  const checkOperatiomTimeStamp = (
    bookingTime: number,
    operationTime: number
  ) => {
    // this function return true if booking time is greater than or equal operation time stamp
    return isNewDate(bookingTime, operationTime);
  };

  const filterOperations = (encounter: any) => {
    if (encounter.operations) {
      const filteredOperations = encounter.operations.filter(
        (operation: any) => {
          if (!operation.mlog) return false;
          return (
            operation.mlog.some((log: any) => {
              return checkOperatiomTimeStamp(encounter.b.date, log.md);
            }) &&
            operation.operationSteps.map((step: any) => {
              // console.log(step);
              return step.mlog.some((log: any) => {
                // console.log('loggg', log);
                if (checkOperatiomTimeStamp(encounter.b.date, log.md)) {
                  step.stepCompleted = log.stepCompleted;
                  // console.log('stepCompleteddddd', operation.operationSteps);
                }
              });
            })
          );
        }
      );
      return filteredOperations;
    }
    return [];
  };

  // const filterOperations = (encounter: any) => {
  //   if (encounter.operations) {
  //     const filteredOperations = encounter.operations.filter(
  //       (operation: any) => {
  //         if (!operation.mlog) return false;
  //         return operation.mlog.some((log: any) => {
  //           return checkOperatiomTimeStamp(encounter.b.date, log.md);
  //         });
  //       }
  //     );
  //     return filteredOperations;
  //   }
  //   return [];
  // };

  useEffect(() => {
    if (currentEncounter) {
      setFilteredOPerations(filterOperations(currentEncounter));
    }
  }, [currentEncounter]);

  return (
    <div className="history-view">
      <div className="row">
        <div className="col-6">
          <SectionHeaderComponent
            title={'Allergy & Chronic Disease'}
            column={''}
            hasButton={false}
          />
          <div className="col-content">
            <div className="tags">
              {currentReservation.allergy &&
                currentReservation.allergy.map((element: any) => {
                  return (
                    <span
                      className="badge badge-primary"
                      key={`lab-${element.code}`}
                    >
                      <strong>{element.code} : </strong> {element.name}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="col-6">
          {/* <AttachmentsComponent imgSource={'dentalBoard'} /> */}

          <div className="d-flex overflow-auto">
            {currentEncounter.attachments.length > 0 &&
              currentEncounter.attachments.map((image: any, idx: number) => (
                <div key={image.url + idx} className="photo ml-2">
                  <div className="main-img" onClick={() => openInNewTab(image)}>
                    <img
                      src={image.localUrl ? image.localUrl : image.url}
                      alt=""
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="header w-100 d-flex ">
        <ul className="d-flex">
          <li
            className={tab === 'complain' ? 'selected-li' : ''}
            onClick={() => {
              changeTabHandler('complain');
            }}
          >
            Complaint & Diagnosis
          </li>
          <li
            className={tab === 'operation' ? 'selected-li' : ''}
            onClick={() => {
              changeTabHandler('operation');
            }}
          >
            Operation
          </li>
        </ul>
      </div>

      <div className="main-content">
        {tab === 'complain' ? (
          // <ComplainComponent startTreatmentPlan={startTreatmentPlan} />
          <div className="row">
            <div className="complain-wrapper d-flex flex-column col-8">
              <div className="complain-section section d-flex flex-column">
                Complaint:
                <textarea
                  className=""
                  value={
                    currentEncounter.complaintText
                      ? currentEncounter.complaintText
                      : ''
                  }
                  onChange={(e) => {}}
                  readOnly
                ></textarea>
              </div>
              <div className="Diag-section section d-flex flex-column ">
                Diagnosis:
                <textarea
                  className=""
                  value={
                    currentEncounter.diagnosisText
                      ? currentEncounter.diagnosisText
                      : ''
                  }
                  onChange={(e) => {}}
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className="col-4">
              <div>
                <PrescriptionComponent
                  isHistory={true}
                  data={currentEncounter.pres || []}
                  column={2}
                />
              </div>
              <div>
                <SectionHeaderComponent
                  title={'Investigations (Lab/Rad)'}
                  column={1}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <div className="tags">
                    {currentEncounter.lab &&
                      currentEncounter.lab.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`lab-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                    {/* {rad &&
                      rad.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`rad-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <OperationComponent
            operations={filteredOperations}
            checkOperationStep={checkOperationStep}
            cancelOperation={() => {}}
            activateOperation={() => {}}
            isHistory={true}
            addNote={() => {}}
            handleOperationCanalsMeasurements={undefined}
            currentDate={currentEncounter.b.date}
          />
        )}
      </div>
    </div>
  );
};
export default DentalHistoryView;
