import React, { useEffect, useState } from 'react';
import docIcon from '../../../assets/img/doc.svg';
import pdfIcon from '../../../assets/img/pdf.svg';
import xlsIcon from '../../../assets/img/xls.svg';
import { getFileExtension } from '../../../utils/files';
import './DocumentViewer.scss';

const FileViewer = ({ file }: { file?: any }) => {
  if (!file) return <></>;
  else if (['doc', 'pdf', 'xls', 'xlsx', 'docx'].includes(file.ext))
    return (
      <iframe
        title="FileViewer"
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          file.url
        )}&embedded=true`}
      ></iframe>
    );
  else return <img src={file.url} className={''} alt="" />;
};

type Props = {
  files: string[];
};

const parseFiles = (files: string[]) => {
  return files.map((file: string) => {
    return {
      url: file,
      ext: getFileExtension(file),
      icon:
        getFileExtension(file) === 'pdf'
          ? pdfIcon
          : ['doc', 'docx'].includes(getFileExtension(file))
          ? docIcon
          : ['xls', 'xlsx'].includes(getFileExtension(file))
          ? xlsIcon
          : file,
    };
  });
};
const DocumentViewer: React.FC<Props> = ({ files }) => {
  const [currentFile, setCurrentFile] = useState<any>();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (files.length > 0) {
      const dd = parseFiles(files);
      // console.log(dd);
      setData(dd);
      setCurrentFile(0);
    }
    // eslint-disable-next-line
  }, [files]);

  const openFile = (file: string) => {
    // console.log('openFile', file);
    window.open(file, '_blank');
  };

  return (
    <div className="container-gallery">
      <div
        className="image-container"
        onClick={() => {
          if (data[currentFile]) openFile(data[currentFile].url);
        }}
      >
        <FileViewer file={data[currentFile]} />
      </div>
      <div className="bottom-row">
        {data.map((file: any, index: number) => {
          return (
            <div
              key={`file-${index}`}
              className={`card ${currentFile === index ? 'active' : ''}`}
              onClick={() => {
                if (currentFile === index) return;
                setCurrentFile({});
                setTimeout(() => {
                  setCurrentFile(index);
                }, 100);
              }}
            >
              <img alt="" src={file.icon} className="card-img-top" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DocumentViewer;
