import React from 'react';
import './ForgetPassword.scss';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Form/Button/Button';
import mobileIcon from '../../assets/img/mobile.svg';
import MobileNumberInput from '../UI/Form/MobileNum/MobileNumInput';
import formValidationSchema from './FormValidation';

import backArrow from '../../assets/img/back.svg';
import { useHistory } from 'react-router-dom';

type Props = {
  forgetPasswordhandler: (payload: any) => Promise<void>;
};

const ForgetPasswordForm: React.FC<Props> = ({ forgetPasswordhandler }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="forget-password-page-container d-flex  justify-content-center flex-column p-5">
      <div className="forget-password-page-container-msg">
        <p>{t('pages.forgetPassword')}</p>
        <h6>{t('forgetPasswords_page.msg')}</h6>
      </div>
      <div className="forget-password-page-container-form align-items-center align-self-stretch d-flex mt-3">
        <div className="align-items-center d-flex flex-column mt-3 p-5">
          <div className="mobile-icon d-flex align-self-start mt-4">
            <img src={mobileIcon} alt="mobile icon" />
            <h6>{t('forgetPasswords_page.mobile')}</h6>
          </div>
          <Formik
            initialValues={{
              mobile: '',
            }}
            validationSchema={formValidationSchema(t)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await forgetPasswordhandler(values);
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="mt-3" autoComplete="off">
                <div className="mt-3">
                  <MobileNumberInput />
                </div>
                <div className="mt-3">
                  <Button
                    type="submit"
                    name={t('forgetPasswords_page.next')}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="back align-self-start mt-2"
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={backArrow} alt="back" />
        <span>{t('otp_page.back')}</span>
      </div>
    </div>
  );
};

export default ForgetPasswordForm;
