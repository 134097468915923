export const vitalValidations: any = {
  b1: {
    min: 10,
    max: 200,
  },
  b2: {
    min: 10,
    max: 200,
  },

  temp: {
    min: 30,
    max: 44,
  },
  height: {
    min: 1,
    max: 220,
  },
  weight: {
    min: 10,
    max: 220,
  },
  pulseRate: {
    min: 10,
    max: 220,
  },
  fastBloodGlucose: {
    min: 10,
    max: 500,
  },
  respiratoryRate: {
    min: 1,
    max: 100,
  },
  bmi: {
    min: 1,
    max: 150,
  },
};
