import React from 'react';
import WithUnit from '../../hocs/WithUnit';
import Plans from './Plans';

const PlansPage: React.FC = () => {
  return (
    <WithUnit>
      <Plans />{' '}
    </WithUnit>
  );
};
export default PlansPage;
