import { getClinicBookings } from '../../services/api/booking';
import {
  endPatientBooking,
  findPatientById,
  getPatientBookingHistory,
  addPatientReferedData,
  getPatientOpenFinancailLog,
} from '../../services/api/patient';
import { searchDrByMobile } from '../../services/api/clinicians';
import { warnToast } from '../../utils/toast';
import {
  RESET_BOARD_BOOKING,
  RESET_HISTORY,
  RESET_RESERVATION,
  SET_ACTIVE_SECTION,
  SET_ATTACHMENTS,
  SET_BOARD_BOOKINGS,
  SET_CURRENT_ENCOUNTER,
  SET_CURRENT_PATIENT,
  SET_CURRENT_VIEW,
  SET_HIDE_ALL,
  SET_HISTORY,
  SET_PHYS_ALLBOOKINGS,
  SET_PHYS_COLLAPSED_SLOTS,
  SET_SOMESLOTS_OPEN,
  SET_TESTS,
  TOGGLE_BOARD_MENU,
  UPDATE_RESERVATION,
  START_EXAMINATION,
  SET_BOARD_CURRENT_BOOKING,
  SEARCH_DR_RESULT,
  RESET_BOARD,
  SET_REFERAL,
  SET_ENCOUNTER,
  SET_BOARD_CURRENT_FINANCAIL_LOG,
  UPDATE_BOARD_CURRENT_FINANCAIL_LOG,
} from '../actions/actionTypes';
import { Booking, FinancalLog, fLog } from '../../types';
import { IS_ATTACHMENT_UPLOADING, SET_EXPIRATION_MODAL } from './actionTypes';
import { Dispatch } from './types';

export const updateReservation = (data: any) => async (dispatch: any) => {
  dispatch({ type: UPDATE_RESERVATION, payload: data });
};

export const setCurrentViewAction = (view: string) => async (dispatch: any) => {
  dispatch({ type: SET_CURRENT_VIEW, payload: { view } });
};

export const setTests = ({
  tests,
  patientId,
  encounter,
  testType,
}: any) => async (dispatch: any, getState: any) => {
  const {
    booking: { currentPatient },
  } = getState();
  const {
    board: { currentBooking },
  } = getState();
  if (
    currentBooking?.patientId === patientId ||
    currentPatient?.pk === patientId
  )
    dispatch({ type: SET_TESTS, payload: { encounter, tests, testType } });
};

export const setReferedTests = ({ payload }: any) => async (
  dispatch: any,
  getState: any
) => {
  const {
    booking: { currentPatient },
  } = getState();
  const {
    board: { currentBooking },
  } = getState();
  if (
    currentBooking?.patientId === payload.patientId ||
    currentPatient?.pk === payload.patientId
  )
    dispatch({ type: SET_REFERAL, payload: { referal: payload, send: 'now' } });
};

export const endBookingAction = (encounter: any) => async (dispatch: any) => {
  try {
    const {
      data: { booking },
    } = await endPatientBooking(encounter);
    dispatch(updateCurrentBooking(booking));
    dispatch({
      type: UPDATE_RESERVATION,
      payload: { status: booking.status, lastChange: null },
    });
    // revert Checkpoint
    // dispatch(setBoardCurrentBooking(null));
    // dispatch({ type: RESET_RESERVATION });
    // dispatch({
    //   type: TOGGLE_BOARD_MENU,
    //   payload: {
    //     menuOpen: true,
    //   },
    // });
    dispatch({
      type: START_EXAMINATION,
      payload: {
        startExam: false,
      },
    });
    // console.log('====================All Dispatched====================');
  } catch (error) {
    console.error(error);
  }
};

export const getPatientBookingHistoryAction = (id: any, unit: string) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch({ type: RESET_HISTORY });
    const { data } = await getPatientBookingHistory({ id, unit });
    const { history, labHistory, radHistory, referal } = data;
    const {
      board: { currentBooking },
    } = getState();
    // console.log({ history, labHistory, radHistory });
    const sortedHistory = history.sort((a: any, b: any) => {
      const dateA = new Date(a._ct);
      const dateB = new Date(b._ct);
      return dateB.getTime() - dateA.getTime();
    });
    if (currentBooking && currentBooking.patient.pk === id) {
      let currentEnc = null;
      if (currentBooking.dr.specialty === 'dentistry') {
        currentEnc = sortedHistory.find((enc: any) => enc.isOpen === true);
        if (!currentEnc) {
          // console.log('Found no Open enc');

          currentEnc = sortedHistory.find((enc: any) => {
            if (enc.visits) {
              // console.log('finding by visits ');
              return enc.visits.find(
                (visit: any) => visit.booking?.sk === currentBooking.sk
              );
            } else {
              // console.log('Finding by the old method ');
              return enc.encounter.includes(currentBooking.sk);
            }
          });
        }
      } else {
        currentEnc = sortedHistory.find((enc: any) =>
          enc.encounter.includes(currentBooking.sk)
        );
      }

      // console.log('Result of the seearch  : ', sortedHistory[0].isOpen);
      if (currentEnc) {
        dispatch({
          type: UPDATE_RESERVATION,
          payload: {
            ...currentEnc,
            orders: [...currentEnc.lab, ...currentEnc.rad],
            lastChange: null,
          },
        });
        dispatch({
          type: SET_ATTACHMENTS,
          payload: currentEnc.attachments || [],
        });
      }

      dispatch({
        type: SET_HISTORY,
        payload: { history: sortedHistory },
      });

      if (referal) {
        const sortedReferal = referal.sort((a: any, b: any) => {
          const dateA = new Date(a._ct);
          const dateB = new Date(b._ct);
          return dateB.getTime() - dateA.getTime();
        });
        dispatch({ type: SET_REFERAL, payload: { referal: sortedReferal } });
      }
      for (const enc of labHistory) {
        dispatch(
          setTests({
            encounter: enc.sk,
            patientId: enc.patient.pk,
            tests: enc.tests,
            testType: 'lab',
          })
        );
      }
      for (const enc of radHistory) {
        dispatch(
          setTests({
            encounter: enc.sk,
            patientId: enc.patient.pk,
            tests: enc.tests,
            testType: 'rad',
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPatientHistoryAction = (
  id: any,
  unit: string,
  t: any
) => async (dispatch: any, getState: any) => {
  try {
    dispatch({ type: RESET_HISTORY });

    const { data } = await getPatientBookingHistory({ id, unit });
    const { history, labHistory, radHistory } = data;
    const {
      booking: { currentPatient },
    } = getState();
    // console.log({ history, labHistory, radHistory });

    const sortedHistory = history.sort((a: any, b: any) => {
      const dateA = new Date(a._ct);
      const dateB = new Date(b._ct);
      return dateB.getTime() - dateA.getTime();
    });
    if (sortedHistory.length === 0) warnToast(t('clinicBoard_page.noBooking'));

    const [currentEnc, ...encounters] = sortedHistory;
    if (currentEnc) {
      dispatch({
        type: UPDATE_RESERVATION,
        payload: {
          ...currentEnc,
          orders: [...currentEnc.lab, ...currentEnc.rad],
          allergy: currentPatient.allergy,
          chronic: currentPatient.chronic,
          lastChange: null,
        },
      });
      dispatch({
        type: SET_ATTACHMENTS,
        payload: currentEnc.attachments || [],
      });
    }

    dispatch({
      type: SET_HISTORY,
      payload: { history: encounters },
    });

    for (const enc of labHistory) {
      dispatch(
        setTests({
          encounter: enc.sk,
          patientId: enc.patient.pk,
          tests: enc.tests,
          testType: 'lab',
        })
      );
    }
    for (const enc of radHistory) {
      dispatch(
        setTests({
          encounter: enc.sk,
          patientId: enc.patient.pk,
          tests: enc.tests,
          testType: 'rad',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const resetReservation = () => async (dispatch: any) => {
  dispatch({ type: RESET_RESERVATION });
};

export const isAttachmentUploading = (value: boolean) => async (
  dispatch: any
) => {
  dispatch({ type: IS_ATTACHMENT_UPLOADING, payload: value });
};

export const getCurrentPatientProfileAction = (
  id: string,
  unit: string
) => async (dispatch: any) => {
  try {
    const { data } = await findPatientById({ id, unit });
    const { patient } = data;
    dispatch({ type: SET_CURRENT_PATIENT, payload: patient });
    dispatch({
      type: UPDATE_RESERVATION,
      payload: {
        allergy: patient.allergy || [],
        chronic: patient.chronic || [],
        lastChange: null,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const setBoardCurrentBooking = (booking: Booking | null) => async (
  dispatch: any,
  getState: any
) => {
  const {
    board: { currentBooking },
  } = getState();

  if (currentBooking?.sk !== booking?.sk) {
    dispatch({ type: RESET_HISTORY });
  }
  if (booking === null) {
    dispatch({ type: START_EXAMINATION, payload: { startExam: false } });
  }
  dispatch({ type: SET_BOARD_CURRENT_BOOKING, payload: booking });
};

export const updateCurrentBooking = (booking: Booking) => async (
  dispatch: any,
  getState: any
) => {
  const {
    board: { currentBooking },
  } = getState();
  if (currentBooking && currentBooking.sk === booking.sk) {
    dispatch(setBoardCurrentBooking(booking));
  }
};
export const setFinancalLog = (financalLog: FinancalLog) => async (
  dispatch: any
) => {
  dispatch(updateReservation({ financalLog }));
};

export const setBoardCurrentFinancailLog = (
  clinicId: string,
  patientId: string
) => async (dispatch: Dispatch) => {
  const financalLog = await getPatientOpenFinancailLog({
    clinicId,
    patientId,
  });
  dispatch({
    type: SET_BOARD_CURRENT_FINANCAIL_LOG,
    payload: financalLog.data.financialLog,
  });
};

export const updateBoardCurrentFinancailLog = (financalLog: fLog[]) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_BOARD_CURRENT_FINANCAIL_LOG,
    payload: { fLog: financalLog },
  });
};

export const addBoardCurrentFinancailLog = (financalLog: fLog) => async (
  dispatch: any
) => {
  dispatch({ type: UPDATE_BOARD_CURRENT_FINANCAIL_LOG, payload: financalLog });
};

export const getClinicBookingsAction = (
  clinicId: string,
  startDate: number,
  endDate: number
) => async (dispatch: any) => {
  try {
    const data = await getClinicBookings({
      clinicId,
      startDate,
      endDate,
    });

    dispatch({
      type: SET_BOARD_BOOKINGS,
      payload: { bookings: data.data.bookings },
    });
  } catch (error) {
    console.error('error[getClinicBooking]', error);
  }
};

type Encounter = {
  diag: any[];
  pres: any[];
  allergy: any[];
  symp: any[];
  rad: any[];
  lab: any[];
  vitals: any;
  chronic: [];
  orders: [];
};
export const setCurrentEncounterAction = (encounter: Encounter) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_CURRENT_ENCOUNTER,
    payload: {
      ...encounter,
      orders: [...(encounter.rad || []), ...(encounter.lab || [])],
    },
  });
};

export const toggleMenuAction = (open: boolean) => async (dispatch: any) => {
  dispatch({
    type: TOGGLE_BOARD_MENU,
    payload: {
      menuOpen: open,
    },
  });
};
export const StartExaminationAction = (start: boolean) => async (
  dispatch: any
) => {
  dispatch({
    type: START_EXAMINATION,
    payload: {
      startExam: start,
    },
  });
};

export const setActiveSectionAction = (
  column: number,
  section: string
) => async (dispatch: any) => {
  dispatch({
    type: SET_ACTIVE_SECTION,
    payload: { activeSection: { column, section } },
  });
};

export const resetBoardBooking = () => async (dispatch: any) => {
  dispatch({ type: RESET_BOARD_BOOKING });
};

export const setExpirationModal = (value: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_EXPIRATION_MODAL, payload: value });
};
export const setPhysCollapsedSlots = (slots: any[]) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_PHYS_COLLAPSED_SLOTS,
    payload: slots,
  });
};
export const setPhysAllBookings = (allBookings: boolean) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_PHYS_ALLBOOKINGS,
    payload: allBookings,
  });
};

export const setHideFlag = (hideFlag: boolean) => async (dispatch: any) => {
  dispatch({
    type: SET_HIDE_ALL,
    payload: hideFlag,
  });
};

export const setSomeSlotsOpen = (someSlots: boolean) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_SOMESLOTS_OPEN,
    payload: someSlots,
  });
};

export const findDrByMobileAction = (mobile: string) => async (
  dispatch: any
) => {
  try {
    const data = await searchDrByMobile({ mobile });
    if (data.exist) {
      dispatch({ type: SEARCH_DR_RESULT, payload: data.user });
    }
  } catch (error) {
    console.error('error[searchDRByMobile]', error);
  }
};

export const addPatientReferedDataAction = (
  clinic: any,
  tests: any[],
  notes: string
) => async (dispatch: any, getState: any) => {
  const {
    booking: { currentPatient, currentUnitDetails },
  } = getState();
  const patientId = currentPatient?.pk;
  const dr = currentUnitDetails?.owner;
  try {
    const { data } = await addPatientReferedData({
      clinic,
      tests,
      notes,
      patientId,
      dr,
    });
  } catch (error) {
    console.error('error[addPatientEncountersAction]', error);
  }
};
export const setEncounter = (enc: any) => async (dispatch: any) => {
  dispatch({
    type: SET_ENCOUNTER,
    payload: enc,
  });
};
export const resetBoard = () => async (dispatch: any) => {
  dispatch({
    type: RESET_BOARD,
  });
};
