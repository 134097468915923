import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { searchAllergy, searchChronic } from '../../../../services/api/search';
import { rootState } from '../../../../store/reducers';
import SearchResult from '../SearchResult/SearchResult';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import { SelectedItemTag } from '../SelectedItemTag/SelectedItemTag';
import * as ICD11Service from './../../../../services/icd11';
import './AllergyComponent.scss';

type Props = {
  data: any[];
  onSelect: (data: any) => void;
  onRemove: (data: any) => void;
  column: number;
  dental?: boolean;
};
const AllergyComponent = (props: Props) => {
  const { data, column, dental, onSelect, onRemove } = props;
  const [title] = useState<string>(
    dental ? 'Allergy & Chronic Disease' : 'Allergy'
  );

  const [result, setResult] = useState<any>({});
  const [reset, doReset] = useState(0);

  const [hText, setHText] = useState<any>('');
  const strings = hText.split(' ');
  const activeSection = useSelector(
    (state: rootState) => state.board.activeSection
  );
  const onTextChange = async (value: string) => {
    if (!value) setResult({});
    else {
      const { data: allergySearchData } = await searchAllergy({ text: value });
      let searchData: [];
      if (dental) {
        const { data: chronicSearchData } = await searchChronic({
          text: value,
        });
        searchData = allergySearchData.concat(chronicSearchData);
      } else {
        searchData = allergySearchData;
      }
      setResult(ICD11Service.groupByCode(searchData));
      setHText(value);
    }
  };
  const onDone = (value: string) => {
    setResult({});
    setHText('');
    doReset((prev) => prev + 1);
  };

  const onSelectItem = (d: any) => {
    onSelect(d);
    setResult({});
    doReset((prev) => prev + 1);
  };

  useEffect(() => {
    setResult({});
    setHText('');
    doReset((prev) => prev + 1);
  }, [activeSection.section]);

  return (
    <div className="component allergy-component">
      <SectionHeaderComponent
        title={title}
        column={column}
        hasButton={true}
        onTextChange={onTextChange}
        onDone={onDone}
        reset={reset}
      />
      <div className="col-content">
        <SearchResult data={result} strings={strings} onSelect={onSelectItem} />
        <div className="selected-result">
          <div className="tags">
            {data.map((element: any) => (
              <SelectedItemTag
                key={`diag-${element.code}`}
                element={element}
                onRemove={onRemove}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllergyComponent;
