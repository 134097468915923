import * as Yup from 'yup';
import { arabicRegex } from '../../../assets/constants/regexValues';

const bookingFormValidationSchema = (t: any) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(arabicRegex, t('booking_page.name-arabic'))
      .min(2, t('booking_page.name-valid'))
      .required(t('booking_page.required')),
    lastName: Yup.string()
      .trim()
      .matches(arabicRegex, t('booking_page.name-arabic'))
      .min(2, t('booking_page.name-valid'))
      .required(t('booking_page.required')),
    password: Yup.string()
      .required(t('booking_page.required'))
      .min(8, t('booking_page.valid-8')),
    confirmPassword: Yup.string()
      .required(t('booking_page.required'))
      .oneOf([Yup.ref('password'), null], t('booking_page.password-match')),
  });
};

export default bookingFormValidationSchema;
