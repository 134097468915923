import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import alertIcon from '../../../../assets/img/alert.svg';
import arrowIcon from '../../../../assets/img/arrow-down.svg';
import uploadIcon from '../../../../assets/img/upload.svg';
import { setCurrentLabBookingAction } from '../../../../store/actions/labBoard.actions';
import { rootState } from '../../../../store/reducers';
import './UploadManager.scss';

const getNumberOfFile = (files: any[]) => {
  return files.length;
};
const getNumberOfUploaded = (files: any[]) => {
  return files.filter((f: any) => f.status === 'success').length;
};
const getNumberOfPending = (files: any[]) => {
  return files.filter((f: any) => f.status === 'pending').length / 2;
};
const hasError = (files: any[]) => {
  return !files.every((f: any) => f.status !== 'error');
};

const getTestsGrouped = (files: any[]) => {
  const data: any = {};
  for (const file of files) {
    if (!data[file.booking])
      data[file.booking] = { patient: file.patient, files: [] };
    data[file.booking].files.push(file);
  }
  return data;
};
const UploadManager: React.FC = () => {
  const dispatch = useDispatch();

  const [opened, setMenuOpen] = useState(false);
  const files: any[] = useSelector((state: rootState) => state.uploader.files);
  const labBookings = useSelector(
    (state: rootState) => state.labBoard.bookings
  );

  const setCurrentBooking = (key: string) => {
    const booking = labBookings.find((a: any) => a.sk === key);
    dispatch(setCurrentLabBookingAction(booking));
  };
  const data: any = getTestsGrouped(files);
  return (
    <div
      className={`upload-containerr ${
        opened && files.length > 0 ? 'opened' : ''
      }`}
    >
      <div
        className="upload-item d-flex justify-content-between align-items-center "
        onClick={() => {
          files.length > 0 && setMenuOpen(!opened);
        }}
      >
        <div className="items d-flex align-items-center">
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex align-items-center">
              <img className="upload" src={uploadIcon} alt="avatarIcon" />
              <p>{files.length} file uploading</p>
            </div>
            <div className="progress" style={{ height: '2px' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${
                    getNumberOfFile(files) > 0
                      ? ((getNumberOfUploaded(files) +
                          getNumberOfPending(files)) /
                          getNumberOfFile(files)) *
                        100
                      : 0
                  }%`,
                }}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
          <div className="d-flex align-items-center error">
            {hasError(files) && (
              <img className="upload warning" src={alertIcon} alt="alertIcon" />
            )}
          </div>
        </div>
        <img src={arrowIcon} alt="arrowIcon" />
      </div>

      {opened && (
        <div className="upload-items">
          {Object.keys(data).map((key: string, i: number) => {
            return (
              <div
                className="upload-item"
                key={`${key}-${i}`}
                onClick={() => {
                  setCurrentBooking(key);
                }}
              >
                <div className="upload-item-header">
                  <p>
                    <strong>{data[key].patient} </strong>
                    {hasError(data[key].files) && (
                      <img
                        className="upload warning"
                        src={alertIcon}
                        alt="alertIcon"
                      />
                    )}
                  </p>
                  <p>
                    {data[key].files.length} Files
                    {' ' +
                      Math.floor(
                        ((getNumberOfUploaded(data[key].files) +
                          getNumberOfPending(data[key].files)) /
                          getNumberOfFile(data[key].files)) *
                          100
                      )}
                    %
                  </p>
                </div>
                <div className="progress" style={{ height: '2px' }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${
                        ((getNumberOfUploaded(data[key].files) +
                          getNumberOfPending(data[key].files)) /
                          getNumberOfFile(data[key].files)) *
                        100
                      }%`,
                    }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UploadManager;
