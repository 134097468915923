import React from 'react';
import './TermsModal.scss';
import Modal from 'react-modal';
import closeIcon from '../../assets/img/close2.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '1.5rem',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
    height: '90%',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
};

const TermsModal: React.FC<Props> = ({ isOpen, onModalClose }) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div dir="rtl" className="terms-modal-container d-flex flex-column ">
        <div className="close-img" onClick={onModalClose}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="terms d-flex flex-column align-items-start mt-3 p-2">
          <h1>الشروط والأحكام</h1>
          <h3 className="text-danger mt-3">1: المقدمة</h3>
          <p className="text-justify mx-3">
            شركة كوديلز لتكنولوجيا للمعلومات - هي شركة متخصصة في تطوير وتشغيل
            نظم إدارة العيادات للأطباء ومقدمي الرعاية الصحية لإدارة وتحسين
            ممارساتهم وخدماتهم الطبية.
          </p>
          <h3 className="text-danger mt-3">2: تعريفات</h3>
          <div className="flex-column align-items-start mx-3">
            <p className="text-justify">الشركة: شركة كوديلز لتكنولوجيا</p>
            <p className="text-justify">
              سجل: هو أحد الأنظمة المملوكة لشركة كوديلز لتكنولوجيا المعلومات و
              الخاص بإدارة العيادات الطبية .
            </p>
            <p className="text-justify">
              النظام : يقصد به نظام سجل لادارة العيادات الطبية.
            </p>
            <p className="text-justify">العميل: هو المتعاقد على النظام</p>
            <p className="text-justify">
              المستخدمين: هم كل من يعطيه العميل صلاحية استخدام النظام
            </p>
            <p className="text-justify">
              الحساب: هو الاشتراك الخاص بالعميل على النظام .
            </p>
          </div>
          <h3 className="text-danger mt-3">3: الضمانات والتعهدات والإقرارات</h3>
          <div className="flex-column align-items-start mx-3">
            <p className="text-justify">
              يتطلب الإشتراك وإنشاء حساب – موافقة العميل على شروط الاستخدام
              وجميع السياسات المرتبطة بها.
            </p>
            <p className="text-justify underline">
              أنت تضمن وتتعهد وتقر و على ما يلي:
            </p>
            <p className="text-justify">
              &#9679; أنت مقدم رعاية صحية لديه رخصة أو ممثل معتمد لمؤسسة رعاية
              صحية مرخصة مع تقديم ترخيص المؤسسة والوثائق القانونية لتقديم خدمات
              الرعاية الصحية للمرضى .
            </p>
            <p className="text-justify">
              &#9679; أنت مسئول عن التأكد من أن جميع المستخدمين المصرح لهم
              باستخدام النظام على دراية بشروط الاستخدام هذه مع التزامهم بها.
            </p>
            <p className="text-justify">
              &#9679; مسؤوليتك عن الحفاظ على الخصوصية وتقييد الوصول إلى الحساب
              الخاص بك واستخدامه هو وكلمة المرور، والموافقة على تحمل مسؤولية
              جميع الأنشطة التي تتم باسم الحساب الخاص بك وكلمة المرور الخاصة بك.
            </p>
            <p className="text-justify">
              &#9679; الموافقة على إخطارنا فورا عن أي استخدام غير مصرح به لكلمة
              المرور أو الحساب الخاص بك أو أي خرقٍ آخر لمعايير الاستخدام الآمن
              للنظام.
            </p>
            <p className="text-justify">
              &#9679; تقديم المعلومات الكاملة والحقيقية والدقيقة والحالية عن
              نفسك وعن استخدامك للخدمات كما هو محدد من قبلنا.
            </p>
            <p className="text-justify">
              &#9679; التعاون مع الطلبات الصادرة عنا للحصول على معلومات إضافية
              فيما يتعلق بأهليتكك واستخدامك لخدماتنا.
            </p>
            <p className="text-justify">
              &#9679; الامتثال التام للاستمرار بالعمل وفقاً للقوانين والأنظمة
              واللوائح المطبقة، بما في ذلك على سبيل المثال لا الحصر الالتزام
              بالتشريعات المتعلقة بقوانين الخصوصية وتنظيم المحتوى.
            </p>
            <p className="text-justify">
              &#9679; سيُعلم العميل المستخدمين المعتمدين بجميع المصطلحات
              والممارسات والسياسات ذات الصلة باستخدامهم للنظام وأي إعدادات قد
              تؤثر على معالجتهم للبيانات.
            </p>
            <p className="text-justify">
              &#9679; العميل مسؤول أيضًا عن التأكد من أن معالجة جميع البيانات في
              النظام تتم وفقًا للوائح والقوانين اللازمة. يجب أن يكون جميع
              المستخدمين المعتمدين في سن قانونية وأن يمتلكوا الترخيص والمؤهلات
              اللازمة لإستخدام النظام طبقاّ لمسؤليا
            </p>
            <p className="text-justify">
              &#9679; يحق لـلشركة إلغاء حساب العميل ، أوالوصول إلى أي جزء من
              نظامه ، في أي وقت وفقًا لتقديرها الخاص ، إذا حدث انتهاكًا لشروط
              الأستخدام طبقاّ لهذه الاتفاقية.
            </p>
            <p className="text-justify">
              &#9679; باستخدام النظام ، فإنك توافق على تخزين بياناتك في نظام آمن
              يتم الوصول إلية من خلال نظم الحوسبة السحابية(Cloud Systems).
            </p>
          </div>
          <h3 className="text-danger mt-3">4. الاشتراكات والمدفوعات:</h3>
          <div className="flex-column align-items-start mx-3">
            <p className="text-justify">
              &#9632; تعتمد جميع استخدامات العملاء والمستخدمين المعتمدين
              لـلبرنامج على الاشتراك طبقاّ لسياسة الأستخدام.
            </p>
            <p className="text-justify">
              &#9632; يتم دفع المستحقات المالية مباشرة للشركة وفقًا لوسائل الدفع
              المعلن عنها .
            </p>
            <p className="text-justify">
              &#9632; يتم تجديد جميع الاشتراكات تلقائيًا في نهاية اّخر فاتورة من
              مدة التعاقد.
            </p>
            <p className="text-justify">
              &#9632; سيتم تعليق الخدمة تلقائيًا فى حالة التأخرعن فترة الدفع ،
              حتى يتم سداد جميع المستحقات مع احتساب فترة التأخر ضمن فترة
              الاشتراك.
            </p>
          </div>
          <h3 className="text-danger mt-3">5. المسؤوليات والالتزامات:</h3>
          <div className="flex-column align-items-start mx-3">
            <p className="text-justify">
              &#9632; تعمل الشركة باستمرار على تحديث النظام من أجل تقديم أفضل
              خدمة ممكنة للعملاء. يقر جميع العملاء ويوافقون على أن شكل وطبيعة
              الخدمات التي يقدمها النظام قد تتغير من وقت لآخر ، ويمكن إضافة
              ميزات أو تحسينها أو تغييرها دون إشعار مسبق وفقًا لمتطلبات
              تغيّرالتكنولوجيا بما يخدم العملاء وإهتماماتهم.
            </p>
            <p className="text-justify">
              &#9632; أنت مسؤول مسؤولية كاملة عن استخدامك و فريقك لبيانات المرضى
              في النظام ومسؤول عن الحفاظ على خصوصية وسرية بيانات مرضاك.
            </p>
          </div>
          <h3 className="text-danger mt-3">6. إنهاء العقد ودعم الإنهاء:</h3>
          <div className="flex-column align-items-start mx-3">
            <p className="text-justify">
              &#9632; تسري شروط الاستخدام هذه من الوقت الذي تبدأ فيه استخدام
              النظام ، حتى يتم إنهاؤها من قبلك و / أو من قبل الشركة .
            </p>
            <p className="text-justify">
              &#9632; عند إنهاء الاشتراك ، لن تتمكن من استخدام حسابك. وينطبق
              الشيء نفسه على المستخدمين المصرح لهم في حسابك.
            </p>
          </div>
          <h3 className="text-danger mt-3">7.أحكام عامة:</h3>
          <div className="d-flex flex-column align-items-start mx-3">
            <p className="text-justify mt-2">
              <span className="underline">القانون المطبق: </span>
              <span>
                {' '}
                إن شروط الاستخدام هذه وأياً من الحقوق أو الواجبات غير التعاقدية
                ذات الصلة يجب إخضاعها وتفسيرها وفقاً لقوانين جمهورية مصر العربية
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">حل النزاعات: </span>
              <span>
                {' '}
                إذا كان لديك أي من المشكلات الخاصة بخدماتنا يُرجى الاتصال بنا‎.
                وسنعمل جاهدين على حل المشكلة التي تواجهك في أقرب فرصة ممكنة. يتم
                تسوية أي من النزاعات أو الخلافات المتعلقة بشروط الاستخدام هذه،
                بما في ذلك أي حقوق أو واجبات غير تعاقدية ذات صلة عن طريق المحكمة
                المختصة بالقاهرة.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">حقوق الغير: </span>
              <span>
                {' '}
                الشخص الذي لا يُعد جزءاً من شروط الاستخدام هذه ليس لديه أي حق في
                تنفيذ أي من شروطها.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">علاقة الأطراف: </span>
              <span>
                {' '}
                لا يوجد في شروط الاستخدام هذه ما يمكن للأطراف أو للغير تأويله أو
                تفسيره ليفسر العلاقة بيننا على أنها بين شركاء أو وكلاء أو يوجِد
                مشروعاً مشتركاً بين الأطراف، ولكنه من المفهوم والواضح أن كل
                الأطراف في الاتفاق هي أطراف مستقلة .
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">التأكيدات الإضافية: </span>
              <span>
                {' '}
                تقوم الأطراف بالتصرفات اللازمة أو الترتيب لاتخاذ التصرفات
                اللازمة وتحرير المستندات وغيرها من الأمور التي تقع ضمن سلطتها من
                أجل إنفاذ شروط الاستخدام هذه والتحقق من العمل بها، بما في ذلك
                على سبيل المثال لا الحصر مساعدة كل طرف على الالتزام بالقانون
                المعمول به.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">التنازل: </span>
              <span>
                {' '}
                تلتزم شروط الاستخدام هذه بضمان تحقيق الفائدة للأطراف ولخلفائهم
                المعنيين والمُفوضين رسمياً. توافق على أنك لن تقوم بالتنازل عن أو
                نقل شروط الاستخدام أو أي من الحقوق أو الواجبات الخاصة بك
                والمتعلقة بشروط الاستخدام هذه، سواء كان ذلك بشكلٍ مباشر أو غير
                مباشر، دون الحصول مسبقاً على موافقة خطية من قبلنا.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">مجمل الاتفاق: </span>
              <span>
                {' '}
                إن شروط الاستخدام هذه والوثائق المشار إليها أو المدرجة في شروط
                الاستخدام تمثل مجمل الاتفاق بين الأطراف فيما يتعلق بموضوع
                الاتفاقية وتسمو على وتحجب جميع الاتفاقيات والمفاوضات والإقرارات
                السابقة، الخطية أو الشفهية، ذات الصلة بالموضوع. باستثناء ما هو
                محدد في شروط الاستخدام والوثائق المشار إليها أو المدرجة في شروط
                الاستخدام الماثلة فلا توجد أي شروط أو إقرارات أو ضمانات أو
                تعهدات أو اتفاقيات بين الأطراف سواء كان ذلك مباشراً أو غير مباشر
                أو جماعياً أو صريحاً أو ضمنياً.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">التعديلات: </span>
              <span>
                {' '}
                لا يحق إجراء أي تعديل على شروط الاستخدام هذه أو إدخال أي إضافة
                أو تكملتها. نحن نحتفظ بالحق في إدخال أي تعديل أو تغيير أو إضافة
                أو إكمال شروط الاستخدام هذه في أي وقت أو من وقتٍ لآخر. وسنقوم
                بنشر النسخة الحالية لشروط الاستخدام على الموقع وستكون سارية
                المفعول عند نشرها على الموقع أو بناء على الموعد المحدد من جانبنا
                بصفته "تاريخ السريان" (إن وجد). إن استخدامك المستمر للخدمات في
                حال حدوث أي تغييرات يُعد موافقة منك على الالتزام بشروط الاستخدام
                المعدلة.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">قابلية الفصل بين البنود: </span>
              <span>
                {' '}
                إذا ما تم اعتبار أي من أحكام شروط الاستخدام هذه ملغًى من قبل
                أيٍّ من المحاكم المختصة أو غير قانوني أو غير معمول به فإنه يتم
                إلغاء هذا البند من شروط الاستخدام هذه وتستمر باقي الشروط
                والأحكام قائمة سارية نافذة طالما ظل الجوهر القانوني والاقتصادي
                للصفقات التي تمت تحت شروطها قائماً دون أي تأثير معاكس على
                أطرافها.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">لقوة القاهرة: </span>
              <span>
                {' '}
                لا يتحمل أي طرف مسؤولية وجود الخسارة أو الضرر أو التأخير أو عدم
                الوفاء نتيجة للأعمال الخارجة عن السيطرة لأي من الأطراف سواء كان
                ذلك العمل يمكن توقعه أو لا (مثل القضاء والقدر والإجراءات الصادرة
                عن السلطات التشريعية أو القضائية أو التنظيمية لأي من الحكومة
                المصرية أو السلطات القضائية أو الإجراءات التي يقوم بها أي من
                المقاولين من الباطن التابعين لنا أو أي طرف ثالث مورد البضائع أو
                الخدمات لنا أو الاضطرابات العمالية أو الانقطاع الكامل للتيار
                الكهربائي أو المقاطعة الاقتصادية).
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">عدم التنازل: </span>
              <span>
                {' '}
                إن التنازل عن أي من الأحكام الواردة في شروط الاستخدام لا يُعد
                تنازلاً عن أي من الأحكام الأخرى (المشابهة أو غير المشابهة)، ولا
                يعد أي تنازل آخر تنازلاً مستمراً عن أي من الأحكام المعنية، ما لم
                ننص على ذلك صراحة وخطياً.
              </span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">التواصل: </span>
              <span>يمكنك التواصل معنا عبر البريد الإلكتروني</span>
            </p>
            <p className="text-justify mt-2">
              <span className="underline">استمرار النفاذ: </span>
              <span>
                {' '}
                جميع الأحكام التي يُنص على أنها تظل سارية أو التي تسري بطبيعتها
                بعد إنهاء التعاقد تظل نافذة المفعول بعد إنهاء أو تعليق عضويتك في
                الموقع.
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
