const governments: any = {
  cairo: { ar: 'القاهرة', en: 'Cairo', code: '01' },
  alexandria: { ar: 'الاسكندرية', en: 'Alexandria', code: '02' },
  portsaid: { ar: 'بورسعيد', en: 'Portsaid', code: '03' },
  elsawies: { ar: 'السويس', en: 'Elsawies', code: '04' },
  damietta: { ar: 'دمياط', en: 'Damietta', code: '11' },
  daqahlia: { ar: 'الدقهلية', en: 'Daqahlia', code: '12' },
  sharqia: { ar: 'الشرقية', en: 'Elsharqia', code: '13' },
  qaliobia: { ar: 'القليوبية', en: 'Elqaliobia', code: '14' },
  kafrElshiekh: { ar: 'كفر الشيخ', en: 'Kafr Elshiekh', code: '15' },
  gharbia: { ar: 'الغربية', en: 'Gharbia', code: '16' },
  menofia: { ar: 'المنوفية', en: 'Menofia', code: '17' },
  beheira: { ar: 'البحيرة', en: 'Elbeheira', code: '18' },
  ismailia: { ar: 'الإسماعيلية', en: 'Ismailia', code: '19' },
  giza: { ar: 'الجيزة', en: 'Giza', code: '21' },
  beniSuef: { ar: 'بني سويف', en: 'Beni Suef', code: '22' },
  faiyum: { ar: 'الفيوم', en: 'Faiyum', code: '23' },
  minya: { ar: 'المنيا', en: 'Minya', code: '24' },
  asyut: { ar: 'أسيوط', en: 'Asyut', code: '25' },
  sohag: { ar: 'سوهاج', en: 'Sohag', code: '26' },
  qena: { ar: 'قنا', en: 'Qena', code: '27' },
  aswan: { ar: 'أسوان', en: 'Aswan', code: '28' },
  luxor: { ar: 'الأقصر', en: 'Luxor', code: '29' },
  redSea: { ar: 'البحر الأحمر', en: 'Red Sea', code: '31' },
  newValley: { ar: 'الوادي الجديد', en: 'New Valley', code: '32' },
  marsaMatruh: { ar: 'مطروح ', en: 'Marsa Matruh', code: '33' },
  northSinai: { ar: 'شمال سيناء', en: 'North Sinai', code: '34' },
  southSinai: { ar: 'جنوب سيناء', en: 'South Sinai', code: '35' },
  foreign: { ar: 'اجنبى', en: 'Foreign', code: '88' },
};

export default governments;
