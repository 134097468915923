import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import TimeField from 'react-simple-timefield';
import { getHours, getMinutes } from '../../../../assets/constants/hours';

import arrowIcon from '../../../../assets/img/dark-arrow.svg';

type Props = {
  handleChange: (name: string, keyName: string, value: string) => void;
  name: string;
  from: string;
  to: string;
};

const ShiftTimes: React.FC<Props> = ({ name, handleChange, from, to }) => {
  const { t } = useTranslation();
  const [slotFromHour, setSlotFromHour] = useState(
    (from && from.split(':')[0]) || ''
  );
  const [slotFromMinute, setSlotFromMinute] = useState(
    (from && from.split(':')[1]) || ''
  );
  const [slotToHour, setSlotToHour] = useState((to && to.split(':')[0]) || '');
  const [slotToMinute, setSlotToMinute] = useState(
    (to && to.split(':')[1]) || ''
  );

  const [hours, setHours] = useState<string[]>([]);
  const [minutes, setMinutes] = useState<string[]>([]);

  const [timeErr, setTimeErr] = useState<string>('');

  useEffect(() => {
    const mm = getMinutes();
    const hh = getHours();
    setHours([...hh]);
    setMinutes([...mm]);
  }, []);

  const checkTimeHandler = (fromValue: string, toValue: string) => {
    // console.log(fromValue, toValue);
    const isValidTime =
      (toValue === '00:00' && fromValue !== '00:00') || toValue > fromValue;
    // console.log('isValidTime', isValidTime);
    if (!isValidTime) {
      setTimeErr(t('addClinic_page.time-errMsg'));
    } else {
      setTimeErr('');
    }
  };

  const handleSlot = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value || '00';
    const attrName = fieldName.split('-')[0];
    let arrtValue = '';
    if (fieldName === 'from-hour') {
      setSlotFromHour(fieldValue);
      arrtValue = `${fieldValue}:${slotFromMinute}`;
      handleChange(name, attrName, arrtValue);
      handleChange(name, 'to', `${slotToHour}:${slotToMinute}`);
      checkTimeHandler(arrtValue, `${slotToHour}:${slotToMinute}`);
    } else if (fieldName === 'from-minute') {
      setSlotFromMinute(fieldValue);
      arrtValue = `${slotFromHour}:${fieldValue}`;
      handleChange(name, attrName, arrtValue);
      handleChange(name, 'to', `${slotToHour}:${slotToMinute}`);
      checkTimeHandler(arrtValue, `${slotToHour}:${slotToMinute}`);
    } else if (fieldName === 'to-hour') {
      setSlotToHour(fieldValue);
      arrtValue = `${fieldValue}:${slotToMinute}`;
      handleChange(name, attrName, arrtValue);
      handleChange(name, 'from', `${slotFromHour}:${slotFromMinute}`);
      checkTimeHandler(`${slotFromHour}:${slotFromMinute}`, arrtValue);
    } else if (fieldName === 'to-minute') {
      setSlotToMinute(fieldValue);
      arrtValue = `${slotToHour}:${fieldValue}`;
      handleChange(name, attrName, arrtValue);
      handleChange(name, 'from', `${slotFromHour}:${slotFromMinute}`);
      checkTimeHandler(`${slotFromHour}:${slotFromMinute}`, arrtValue);
    }

    // handleChange(name, attrName, arrtValue);
  };

  return (
    <>
      <div className="workingDays-container__days--day-shifts-shift d-flex flex-column">
        <div className="d-flex">
          <div className="from d-flex align-items-center justify-content-center">
            <p>{t('working_days_page.from')}</p>
            <div className="time">
              <div>
                <select
                  name="from-hour"
                  value={slotFromHour}
                  onChange={handleSlot}
                >
                  <option value=""></option>
                  {hours.map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
                <img src={arrowIcon} alt="" />
              </div>
              <div>
                <select
                  name="from-minute"
                  value={slotFromMinute}
                  onChange={handleSlot}
                >
                  <option value=""></option>
                  {minutes.map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
                <img src={arrowIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="to d-flex align-items-center justify-content-center">
            <p>{t('working_days_page.to')}</p>
            <div className="time">
              <div>
                <select name="to-hour" value={slotToHour} onChange={handleSlot}>
                  <option value=""></option>
                  {hours
                    .filter((hour) => hour != '00')
                    .map((key) => (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    ))}
                  {/* {hours.map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))} */}
                </select>
                <img src={arrowIcon} alt="" />
              </div>
              <div>
                <select
                  name="to-minute"
                  value={slotToMinute}
                  onChange={handleSlot}
                >
                  <option value=""></option>
                  {minutes.map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
                <img src={arrowIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <span>{timeErr ? timeErr : ''}</span>
      </div>
    </>
  );
};

export default ShiftTimes;
