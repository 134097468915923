import React from 'react';
import Input from '../Input/Input';
import { useTranslation } from 'react-i18next';

const MobileNumberInput = () => {
  const { t } = useTranslation();
  return (
    <Input
      type="number"
      name="mobile"
      inputMode="tel"
      className={['num-left', 'placeholder-right'].join(' ')}
      placeholder={t('login_page.mobile-number')}
    />
  );
};
export default MobileNumberInput;
