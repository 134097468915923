import React, { useReducer } from 'react';
import './WorkingDays.scss';

import { useTranslation } from 'react-i18next';

import WorkingDay from './WorkingDay/WorkingDay';
import Button from '../../UI/Form/Button/Button';
import { errorToast } from '../../../utils/toast';
import { ClinicData } from '../../../types';
import { SubscriptionStatus } from '../../Subscription';
import EventTracker from '../../../utils/analytics';

const days = {
  saturday: 'saturday',
  sunday: 'sunday',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
};

type Action = {
  type: string;
  payload: any;
};

// const initialStat: any = {};

const reducer = (state: any, action: Action) => {
  if (Object.keys(action.payload).length <= 0) {
    delete state[action.type];
    return {
      ...state,
    };
  } else {
    return {
      ...state,
      [action.type]: { ...action.payload },
    };
  }
};
type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler?: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit?: ClinicData | null;
};

const WorkingDays: React.FC<Props> = ({
  nextStepHandler,
  currentSegment,
  initialState,
  isSettingPage,
  goBackHandler,
  isSubmitting,
  unit,
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeWorkingDays = (type: string, payload: any) => {
    dispatch({ type, payload });
  };
  const saveWorkingDaysHandler = async () => {
    // console.log('saveWorkingDaysHandler', state);
    EventTracker.sendEvent('SET_WORKING_DAYS', {});
    if (Object.keys(state).length <= 0) {
      errorToast(t('employess_page.choose_one'));
      return;
    }
    let isValid = false;
    let isValidTime = false;
    let isSamePeriod = false;
    let isIntersectPeriod = false;
    const slots = Object.keys(state);
    for (const slot of slots) {
      const period = state[slot];
      for (let i = 0; i < Object.keys(period).length; i++) {
        const current = period[Object.keys(period)[i]];
        isValid = !!current.from && !!current.to;
        if (!isValid) {
          errorToast(t('addClinic_page.fill_choosed'));
          return;
        }
        isValidTime =
          (current.to === '00:00' && current.from !== '00:00') ||
          current.to > current.from;
        if (!isValidTime) {
          errorToast(t('addClinic_page.time-errMsg'));
          return;
        }
        for (let j = i + 1; j < Object.keys(period).length; j++) {
          const next = period[Object.keys(period)[j]];
          isSamePeriod = current.from === next.from;
          if (isSamePeriod) {
            errorToast(t('addClinic_page.period-errMsg'));
            return;
          }
          isIntersectPeriod = next.from < current.to;
          if (isIntersectPeriod) {
            errorToast(t('addClinic_page.period-intersect-errMsg'));
            return;
          }
        }
      }
    }

    nextStepHandler({ type: currentSegment, data: state });
  };

  return (
    <div className="workingDays-container d-flex flex-column ">
      {isSettingPage && (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              saveData={saveWorkingDaysHandler}
              isSubmitting={isSubmitting}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBackHandler}
            />
          </div>
        </div>
      )}
      <div className="workingDays-container__main ">
        <WorkingDay
          day={t('working_days_page.saturday')}
          shouldToggle={state[days.saturday] ? true : false}
          type={days.saturday}
          key={days.saturday}
          changeWorkingDays={changeWorkingDays}
          initialState={
            state && state[days.saturday] ? state[days.saturday] : {}
          }
        />
        <WorkingDay
          day={t('working_days_page.sunday')}
          shouldToggle={state[days.sunday] ? true : false}
          type={days.sunday}
          key={days.sunday}
          changeWorkingDays={changeWorkingDays}
          initialState={state && state[days.sunday] ? state[days.sunday] : {}}
        />
        <WorkingDay
          day={t('working_days_page.monday')}
          shouldToggle={state[days.monday] ? true : false}
          type={days.monday}
          key={days.monday}
          changeWorkingDays={changeWorkingDays}
          initialState={state && state[days.monday] ? state[days.monday] : {}}
        />
        <WorkingDay
          day={t('working_days_page.tuesday')}
          shouldToggle={state[days.tuesday] ? true : false}
          type={days.tuesday}
          key={days.tuesday}
          changeWorkingDays={changeWorkingDays}
          initialState={state && state[days.tuesday] ? state[days.tuesday] : {}}
        />
        <WorkingDay
          day={t('working_days_page.wednesday')}
          shouldToggle={state[days.wednesday] ? true : false}
          type={days.wednesday}
          key={days.wednesday}
          changeWorkingDays={changeWorkingDays}
          initialState={
            state && state[days.wednesday] ? state[days.wednesday] : {}
          }
        />
        <WorkingDay
          day={t('working_days_page.thursday')}
          shouldToggle={state[days.thursday] ? true : false}
          type={days.thursday}
          key={days.thursday}
          changeWorkingDays={changeWorkingDays}
          initialState={
            state && state[days.thursday] ? state[days.thursday] : {}
          }
        />
        <WorkingDay
          day={t('working_days_page.friday')}
          shouldToggle={state[days.friday] ? true : false}
          type={days.friday}
          key={days.friday}
          changeWorkingDays={changeWorkingDays}
          initialState={state && state[days.friday] ? state[days.friday] : {}}
        />
      </div>
      {!isSettingPage && (
        <Button
          name={t('working_days_page.next')}
          saveData={saveWorkingDaysHandler}
        />
      )}
    </div>
  );
};

export default WorkingDays;
