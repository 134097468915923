import { toast, ToastContainerProps } from 'react-toastify';
import i18n from '../services/i18n';

export const toastOptions: ToastContainerProps = {
  position: i18n.language === 'ar' ? 'top-left' : 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: i18n.language === 'ar' ? true : false,
  pauseOnFocusLoss: false,
  draggable: false,
  bodyStyle: {
    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
    fontSize: '1.2rem',
    textAlign: 'start',
  },
};
// toast.configure();

export const successToast = (msg: string) => {
  return toast.success(msg);
};

export const errorToast = (msg: string) => {
  return toast.error(msg);
};

export const infoToast = (msg: string) => {
  return toast.info(msg);
};

export const warnToast = (msg: string) => {
  return toast.warning(msg);
};
