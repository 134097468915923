import './ServiceDetails.scss';

import React, { useState } from 'react';

type props = {
  isActive: boolean;
  price: number;
  discountHandler: any;
  isDiscountPercentage: boolean;
  discount: any;
  toggleDiscountOption: () => void;
};
const ServiceDetails: React.FC<props> = ({
  isActive,
  price,
  discountHandler,
  isDiscountPercentage,
  discount,
  toggleDiscountOption,
}) => {
  /*const [discount, setDiscount] = useState(0);
  const [isDiscountPercentage, setIsDiscountPercentage] = useState(true);
  //const price = 50;
  const discountHandler = (e: any) => {
    if (isDiscountPercentage) {
      e.target.value >= 0 && e.target.value <= 100
        ? setDiscount(e.target.value)
        : setDiscount(0);
    } else {
      e.target.value >= 0 && e.target.value <= price
        ? setDiscount(e.target.value)
        : setDiscount(0);
    }
  };*/
  return (
    <div className="service-details-wrapper">
      <h1 className={isActive ? 'active-header' : undefined}>
        Service Details
      </h1>
      <div className="data-wrapper">
        <h1>Price: {price}</h1>
        <div className="discount-input">
          <input
            type="text"
            name="discount"
            placeholder={'discount'}
            onChange={(e) => discountHandler(e)}
          />
          <span
            onClick={() => toggleDiscountOption()}
            className="pecentage-checkbox"
          >
            <input
              type="checkbox"
              checked={isDiscountPercentage}
              onChange={() => {}}
            />
            <span></span>
            <p>Percentage %</p>
          </span>
        </div>
        <div></div>
        <h1>
          Discount :{' '}
          {isDiscountPercentage ? (price * discount) / 100 : discount || 0}
        </h1>
      </div>
    </div>
  );
};

export default ServiceDetails;
