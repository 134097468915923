import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import routes from '../../assets/constants/routes';
import NotAuthorized from '../../components/NotAuthorized/NotAuthorized';
import { appRoutes } from '../../MainRoutes';
import { rootState } from '../../store/reducers';

const WithUnit = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const [currentRoute, setCurrentRoute] = useState<any>(null);

  const { currentEmpUnit } = useSelector((state: rootState) => state.booking);
  useEffect(() => {
    const p = appRoutes.find((el: any) => el.path === location.pathname);
    if (!p) history.push(routes.HOME);
    else setCurrentRoute(p);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {!currentRoute ? null : currentEmpUnit &&
        currentRoute.roles.includes(currentEmpUnit.role) ? (
        children
      ) : currentEmpUnit &&
        !currentRoute.roles.includes(currentEmpUnit.role) ? (
        <NotAuthorized />
      ) : null}
    </React.Fragment>
  );
};
export default WithUnit;
