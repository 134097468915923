import React from 'react';
import { ClinicData } from '../../types';
import './NotActive.scss';
import { formatDate } from '../../utils/moment';
import { useTranslation } from 'react-i18next';
import { SijilBasicIcon, SijilTrialIcon } from '../../assets/constants/images';
import { NavLink } from 'react-router-dom';
import routes from '../../assets/constants/routes';
import WalletIcon from '../../assets/img/Mask Group 521.svg';

type Props = {
  unit?: ClinicData | null;
};
const SubscriptionStatus = (props: Props) => {
  const { unit } = props;
  const { t } = useTranslation();

  return (
    <div>
      {unit?.subscription?.type === 'trial'
        ? TrialPlan(unit, t)
        : unit?.subscription?.type === 'basic'
        ? BasicPlan(unit, t)
        : ''}
    </div>
  );
};

export default SubscriptionStatus;
const TrialPlan = (unit: ClinicData | null, t: any) => (
  <div className="plan-log">
    <SijilTrialIcon />

    <div>
      {/* eslint-disable-next-line */}
      <React.Fragment>
        {formatDate(unit?.subscription.expirationDate)}
      </React.Fragment>
      {unit?.type === 'clinic' && unit.owner.active ? (
        <NavLink to={routes.PLANS} className="link">
          {t('subscription.subscribe')}
        </NavLink>
      ) : unit?.owner.active ? (
        <NavLink to={routes.PLANS} className="link">
          {t('subscription.recharge')}
        </NavLink>
      ) : null}
    </div>
  </div>
);

const BasicPlan = (unit: ClinicData | null, t: any) => (
  <div className="plan-log">
    <SijilBasicIcon />
    <div>
      {unit?.type === 'clinic' ? (
        <React.Fragment>
          {unit?.subscription.expirationDate &&
            ` ${formatDate(unit?.subscription.expirationDate)}`}

          {unit?.owner.active && (
            <NavLink to={routes.PLANS} className="link">
              {t('subscription.subscribe')}
            </NavLink>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <WalletIcon title="balance" fill='red' /> */}
          <img className="wallet" src={WalletIcon} alt="" />
          {unit?.subscription?.balance} EGP
          {unit?.owner.active && (
            <NavLink to={routes.PLANS} className="link">
              {t('subscription.recharge')}
            </NavLink>
          )}
        </React.Fragment>
      )}
    </div>
  </div>
);
