import {
  // DELETE_LAB_BOOKING,
  SET_CURRENT_LAB_BOOKING,
  SET_LAB_BOOKINGS,
  UPDATE_LAB_BOOKING,
  RESET_LAB_BOOKING,
  UPDATE_EXIST_LAB_BOOKING,
  // CLEAR_BOOKING_FILES,
} from './actionTypes';
import apiService from '../../services/api';
import { markComplete } from '../../services/api/booking';

import { setBookingFilesSend } from './uploader.actions';
import {
  // addPatientRadEncounters,
  addPatientLabEncounters,
} from '../../services/api/patient';
import { Booking } from '../../types';

export const getLabBookingsAction = (id: string, mobile?: string) => async (
  dispatch: any
) => {
  try {
    const q = mobile ? { id, mobile } : { id };
    const data = await apiService.getLabBookings(q);
    const bookings = data.data.bookings;
    dispatch({ type: SET_LAB_BOOKINGS, payload: bookings });
    if (bookings.length > 0) dispatch(setCurrentLabBookingAction(bookings[0]));
    else dispatch(setCurrentLabBookingAction(null));
  } catch (error) {
    dispatch({ type: SET_LAB_BOOKINGS, payload: [] });
  }
};
export const setCurrentLabBookingAction = (booking: any) => async (
  dispatch: any
) => {
  dispatch({ type: SET_CURRENT_LAB_BOOKING, payload: booking });
};

export const markBookingAsReady = (booking: any) => async (dispatch: any) => {
  try {
    const { data } = await markComplete({ id: booking.pk, tstamp: booking.sk });
    const updated = data.updated;
    dispatch({ type: UPDATE_LAB_BOOKING, payload: updated });
  } catch (error) {
    console.error('error[markBookingAsReady]', error);
  }
};

export const addPatientEncountersAction = (
  id: string,
  tstamp: string
) => async (dispatch: any) => {
  try {
    const { data } = await addPatientLabEncounters({ id, tstamp });
    const { booking } = data;

    dispatch(setBookingFilesSend(booking.sk));
  } catch (error) {
    console.error('error[addPatientEncountersAction]', error);
  }
};

export const resetLabBooking = () => async (dispatch: any) => {
  dispatch({ type: RESET_LAB_BOOKING });
};

export const updateExistBookingAction = (booking: Booking) => async (
  dispatch: any
) => {
  try {
    dispatch({ type: UPDATE_EXIST_LAB_BOOKING, payload: booking });
  } catch (error) {
    console.error('error[addPatientEncountersAction]', error);
  }
};
