import React, { useState, useEffect } from 'react';
import ReactModal, { Styles } from 'react-modal';
import printIcon from '../../../assets/img/Mask Group 360.svg';
import closeIcon from '../../../assets/img/close-icon.svg';
import './FinancialReport.scss';
import { printFinancialReport } from '../../../services/print';
import { fLog } from '../../../types';
import { getDayFormatEN } from '../../../assets/constants/currentDay';

type Props = {
  onModalClose: () => void;
  header: any[];
  data: fLog[];
  title: string;
  mobile: string;
};

const customStyles: Styles = {
  overlay: {
    background: 'none',
  },
  content: {
    position: 'absolute',
    top: '116px',
    left: '240px',
    right: '20px',
    maxWidth: '80rem',
    border: '1px solid #ccc',
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '1rem',
    outline: 'none',
    padding: '20px',
    minHeight: '300px',
    boxShadow: '0px 0px 20px #0000004D',
  },
};

const FinancialReport = (props: Props) => {
  const { title, onModalClose, header, data, mobile } = props;

  const handlePrint = () => {
    printFinancialReport(title, mobile, data, header);
  };
  return (
    <ReactModal
      isOpen={true}
      closeTimeoutMS={5000}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onModalClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="report-modal">
        <div className="row report-header d-flex">
          <div className="col-8 title">{title}</div>
          <div className="col-4 d-flex justify-content-end ">
            <div className="print">
              <a
                className="btn btn-primary align-self-center "
                onClick={handlePrint}
              >
                <img src={printIcon} alt="" />
              </a>
            </div>
            <div className="close ">
              <img src={closeIcon} alt="closeIcon" onClick={onModalClose} />
            </div>
          </div>
        </div>
        <div className="report-body">
          <table className="table table-responsive table-striped mt-4">
            <thead>
              <tr>
                {header.map((ele: any, index) => (
                  <th key={index} scope="col">
                    <div>{ele}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((ele: any, ind) => (
                <tr key={ind}>
                  <td>{getDayFormatEN(ele.date)}</td>
                  <td>{ele.record}</td>
                  <td>{ele.value}</td>
                  <td>{ele.balance}</td>
                  <td>{ele.notes}</td>
                  {/* {Object.entries(ele).map(([key]) => (
                    <td key={key}>{ele[key]}</td>
                  ))} */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ReactModal>
  );
};
export default FinancialReport;
