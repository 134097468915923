import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import bookingStatus from '../../../assets/constants/bookingStatus';
import routes from '../../../assets/constants/routes';
import calenderIcon from '../../../assets/img/calender.svg';
import clockIcon from '../../../assets/img/clock.svg';
import bookingIcon from '../../../assets/img/medical-degree.svg';
import { getTimeSlots } from '../../../services/timeSlots';
import { rootState } from '../../../store/reducers';
import { Booking, ClinicData, Patient } from '../../../types';
import { formatTime, getDayName } from '../../../utils/moment';
import { warnToast } from '../../../utils/toast';
import Button from '../../UI/Form/Button/Button';
import DatePicker from '../../UI/Form/DatePicker/DatePicker';
import Input from '../../UI/Form/Input/Input';
import Select from '../../UI/Form/Select/Select';
import './ClinicBookingForm.scss';
import formValidationSchema from './FormValidation';

type Props = {
  addBookingHandler: (payload: any) => void;
  editBookingHanler: (payload: any) => void;
  clinic: ClinicData | null;
  patient: Patient | null;
  booking: Booking | null;
  bookDate: number;
  currentSlot: string;
};

const BookingForm: React.FC<Props> = ({
  addBookingHandler,
  editBookingHanler,
  clinic,
  booking,
  patient,
  bookDate,
  currentSlot,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [bookingDate, setBookingDate] = useState(
    new Date(bookDate).toISOString()
  );

  const [offers, setOffers] = useState<any[]>([]);
  const [appliedOffer, setAppliedOffer] = useState<any>();

  const [filteredExaminationTypes, setFilteredExaminationTypes] = useState<any>(
    {}
  );

  useEffect(() => {
    // console.log('clinic', clinic);
    if (
      !clinic ||
      !clinic.examinationTypes ||
      Object.keys(clinic.examinationTypes).length === 0
    )
      return;

    let data: any = [];
    const patientNetwork = patient?.networks || [];
    const unitNetworks = clinic?.networks || [];
    for (const network of unitNetworks) {
      const exist = patientNetwork.find((n) => n.code === network.code);
      if (exist) {
        data = [
          ...data,
          ...network.offers.map((o: any) => {
            return { ...o, network: network.code };
          }),
        ];
      }
    }

    setOffers(data);

    const types: any = {};
    Object.keys(clinic.examinationTypes).forEach((tt) => {
      if (
        !clinic.examinationTypes[tt].hasOwnProperty('open') ||
        (clinic.examinationTypes[tt].hasOwnProperty('open') &&
          clinic.examinationTypes[tt].open === true)
      ) {
        types[tt] = { ...clinic.examinationTypes[tt] };
      }
    });

    const type = types.normal ? 'normal' : Object.keys(types)[0];
    const offer = data.find((o: any) => o.service === type);

    const cost = offer
      ? (types[type].price * (100 - offer.discountRatio)) / 100
      : types[type].price;
    // console.log(appliedOffer);
    setFilteredExaminationTypes({ ...types });
    updateFormValues({
      exminationType: type,
      exminationPrice:
        types.normal && types.normal.price ? types[type].price : '',
      cost: Math.floor(cost),
    });

    setAppliedOffer(offer);
    // eslint-disable-next-line
  }, [clinic, patient]);

  const [bookingTime, setBookingTime] = useState('');

  const [timeSlotDate, setTimeSlotDate] = useState(new Date());

  const [formValues, setFormValues] = useState({
    exminationType:
      filteredExaminationTypes && filteredExaminationTypes.normal
        ? 'normal'
        : Object.keys(filteredExaminationTypes).length > 0
        ? Object.keys(filteredExaminationTypes)[0]
        : '',
    exminationPrice:
      filteredExaminationTypes && filteredExaminationTypes.normal
        ? filteredExaminationTypes.normal.price
        : Object.keys(filteredExaminationTypes).length > 0
        ? filteredExaminationTypes &&
          filteredExaminationTypes[Object.keys(filteredExaminationTypes)[0]]
            .price
        : '',
    cost: '',
    bookingTime,
    bookingDate,
  });

  const [isEditBooking, setIsEditBooking] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(true);

  const [bookingData, setBookingData] = useState<any>({});

  const [timeSlots, setTimeSlots] = useState<any>({});

  const bookings = useSelector((state: rootState) => state.booking.bookings);

  const isLoading = useSelector((state: rootState) => state.booking.isLoading);

  useEffect(() => {
    if (!clinic) return;
    setTimeSlots({});
    const timeSlot = getTimeSlots(new Date(bookingDate), clinic, bookings);
    setTimeSlots(timeSlot);
    if (currentSlot) {
      const nextSlot =
        Object.keys(timeSlot).find((ele) => ele >= currentSlot) || '';
      updateFormValues({
        bookingTime: nextSlot,
      });
      setBookingTime(nextSlot);
    }
    // eslint-disable-next-line
  }, [timeSlotDate, bookingDate, clinic, bookings]);

  useEffect(() => {
    if (booking) editBookingHandler(booking);
    // eslint-disable-next-line
  }, [booking]);

  const editBookingHandler = (book: Booking) => {
    // console.log('book', book);
    setIsEditBooking(true);
    setAppliedOffer(booking?.appliedOffer);
    setBookingData(booking);
    setFormValues({
      exminationType: book.type,
      exminationPrice: book.price || book.cost,
      cost: '' + book.cost,
      bookingTime: formatTime(new Date(book.date)),
      bookingDate: new Date(book.date).toISOString(),
    });
    setTimeSlotDate(new Date(book.date));
    setBookingTime(formatTime(new Date(book.date)));
    setBookingDate(new Date(book.date).toISOString());
  };

  const setDateHandler = (date: string) => {
    const dayName = getDayName(new Date(date)).toLocaleLowerCase();
    if (
      clinic &&
      clinic.workingDays &&
      !Object.keys(clinic?.workingDays).includes(dayName)
    ) {
      // console.log('dayName', dayName);
      warnToast(t('addBooking_page.clinic-day'));
    }
    setBookingDate(new Date(date).toISOString());
    setBookingTime('');
    setTimeSlotDate(new Date(date));
    updateFormValues({
      bookingDate: date,
      bookingTime: '',
    });
  };

  const setTimeHandler = (value: string) => {
    setBookingTime(value);
    updateFormValues({
      bookingTime: value,
    });
  };

  const onchangeData = (e: string) => {
    const offer = offers.find((o: any) => o.service === e);

    const price =
      filteredExaminationTypes[e] && filteredExaminationTypes[e].price
        ? filteredExaminationTypes[e].price
        : '';

    const cost = offer
      ? (price * (100 - offer.discountRatio)) / 100
      : filteredExaminationTypes[e]
      ? filteredExaminationTypes[e].price
      : '';
    setAppliedOffer(offer);
    updateFormValues({
      exminationType: e,
      exminationPrice: price,
      cost,
    });
  };

  const updateFormValues = (values: any) => {
    setIsFormChanged(false);
    setFormValues({ ...formValues, ...values });
  };

  const getScanTimeSlot = (time: string) => {
    const slots = timeSlots || {};
    if (time) {
      const hour = +time.split(':')[0];
      const minutes = +time.split(':')[1];

      const timeSlot: any = {
        ar: `${
          hour <= 12 ? hour : hour <= 21 ? '0' + (hour - 12) : hour - 12
        }:${minutes < 10 ? '0' + minutes : minutes} ${hour < 12 ? 'ص' : 'م'}`,
        en: `${
          hour <= 12 ? hour : hour <= 21 ? '0' + (hour - 12) : hour - 12
        }:${minutes < 10 ? '0' + minutes : minutes} ${hour < 12 ? 'AM' : 'PM'}`,
      };
      slots[time] = timeSlot;
    }
    return slots;
  };

  return (
    <div className="c-booking-data__form mt-5 mt-sm-0">
      <Formik
        initialValues={formValues}
        validationSchema={formValidationSchema(t)}
        enableReinitialize
        onSubmit={(values) => {
          // console.log(values);
          if (isEditBooking) {
            editBookingHanler({
              ...values,
              appliedOffer,
              pk: bookingData.pk,
              sk: bookingData.sk,
              date: bookingData.date,
            });
          } else {
            addBookingHandler({ ...values, appliedOffer });
          }
        }}
      >
        {({ handleSubmit }: FormikProps<any>) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="c-booking-actions d-flex justify-content-end">
                <Button
                  type="button"
                  name={t('addBooking_page.back')}
                  btnType="back"
                  saveData={() => {
                    history.push(routes.BOOKING);
                  }}
                />
                <Button
                  isSubmitting={isLoading}
                  isDisabled={isEditBooking && isFormChanged}
                  type="submit"
                  name={
                    isEditBooking
                      ? t('addBooking_page.edit')
                      : t('addBooking_page.confirm')
                  }
                />
              </div>
              <div className="c-booking-data__form-container mt-4 p-4">
                <div className="d-flex flex-column">
                  <div className="name-group align-items-center d-flex mb-3">
                    <img src={calenderIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('addBooking_page.booking-date')}
                    </h6>
                  </div>
                  <DatePicker
                    name="bookingDate"
                    setDateHandler={setDateHandler}
                    minDate={new Date()}
                    startDate={new Date(bookingDate)}
                    disabled={
                      booking && ![bookingStatus.new].includes(booking.status)
                    }
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="name-group align-items-center d-flex mb-3">
                    <img src={bookingIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('addBooking_page.booking-type')}
                    </h6>
                  </div>
                  <Select
                    label={t('addBooking_page.booking-type')}
                    onchangeData={onchangeData}
                    name="exminationType"
                    options={Object.keys(filteredExaminationTypes).map(
                      (key) => ({
                        label: filteredExaminationTypes[key].name
                          ? filteredExaminationTypes[key].name
                          : t('examinationAndPrices_page.' + key),
                        value: key,
                      })
                    )}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="name-group align-items-center d-flex mb-3">
                    <img src={clockIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('addBooking_page.booking-time')}
                    </h6>
                  </div>
                  <Select
                    disabled={
                      booking && ![bookingStatus.new].includes(booking.status)
                    }
                    label={t('addBooking_page.booking-time')}
                    name="bookingTime"
                    onchangeData={setTimeHandler}
                    options={Object.keys(
                      getScanTimeSlot(
                        booking ? formatTime(new Date(booking.date)) : ''
                      )
                    )
                      .sort()
                      .map((key) => ({
                        label: timeSlots[key][i18n.language],
                        value: key,
                      }))}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="name-group align-items-center d-flex mb-3">
                    <img src={bookingIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('addBooking_page.booking-price')}
                    </h6>
                  </div>
                  <div className="input-double">
                    <Input type="number" name="cost" />
                    <span>{t('addBooking_page.instead')}</span>
                    <Input
                      type="number"
                      name="exminationPrice"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default BookingForm;
