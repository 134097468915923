import * as Yup from 'yup';
import { emailRegex } from '../../assets/constants/regexValues';

const formValidationSchema = (t: any) => {
  return Yup.object().shape({
    price: Yup.number()
      .required(t('booking_page.required-price'))
      .min(100, t('booking_page.min-price')),
    terms: Yup.boolean()
      .required(t('booking_page.required-terms'))
      .oneOf([true], t('booking_page.required-terms')),
    email: Yup.string()
      .matches(emailRegex, t('booking_page.valid-email'))
      .required(t('booking_page.required')),
  });
};

export default formValidationSchema;
