import React, { useEffect, useState, useRef } from 'react';
import ReactModal, { Styles } from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { rootState } from '../../../../../store/reducers';
import closeIcon from '../../../../../assets/img/close-icon.svg';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomAlertUI from '../../../../LabBoard/CustomAlertUI/CustomAlertUI';
import parseArabic from '../../../../../utils/parseArabic';
import { mobileRegex } from '../../../../../assets/constants/regexValues';
import getFullName from '../../../../../utils/getFullName';
import { searchDrByMobile } from '../../../../../services/api/clinicians';
import { getStoredData } from '../../../../../utils/storage';

import {
  findDrByMobileAction,
  addPatientReferedDataAction,
} from '../../../../../store/actions/board.actions';
import CLinicModal from '../ClinicModal/ClinicModal';
import './ReferralModal.scss';

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  data: any[];
};
const customStyles: Styles = {
  overlay: {
    background: 'none',
  },
  content: {
    position: 'absolute',
    left: '30rem',
    bottom: '0',
    top: '15rem',
    border: '1px solid #ccc',
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '20px',
    width: '44rem',
    height: '35rem',
  },
};
let tests: any[] = [];
let selectedTests: any[] = [];
let referredDr: any = '';
let targetUnit: any = '';
let drClinics: any[] = [];
const flatEncountersList = (encounters: any[]) => {
  for (const element of encounters) {
    if (!tests.includes(element)) {
      tests = [...tests, element];
    }
  }
  const sortedTests = tests.sort((a, b) => {
    const c = new Date(a.date);
    const d = new Date(b.date);
    return c < d ? 1 : -1;
  });
  return sortedTests;
};
const ReferralModal = (prop: Props) => {
  const { isOpen, onModalClose, data } = prop;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState<any[]>([]);
  const [showDrsList, setShowDrsList] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [notes, setNotes] = useState('');
  const [targetClinic, setTargetClinic] = useState('');
  const [openClinicModal, setOpenClinicModal] = useState(false);

  const searchedDr = useSelector((state: rootState) => state.board.searchedDr);

  const [drsList, setDrsList] = useState<any[]>([]);
  const labEncounters = useSelector(
    (state: rootState) => state.board.labEncounters
  );
  const radEncounters = useSelector(
    (state: rootState) => state.board.radEncounters
  );

  const referredEncounters = useSelector(
    (state: rootState) => state.board.referredEncounters
  );
  const divRef = useRef<any>('');
  useEffect(() => {
    const drs = getStoredData('drsList');

    if (drs) {
      setDrsList(drs);
    }
  }, []);
  useEffect(() => {
    if (labEncounters.length <= 0 && radEncounters.length <= 0) return;
    let listData = [];
    if (labEncounters.length > 0) {
      listData = flatEncountersList(labEncounters);
    }
    if (radEncounters.length > 0) {
      listData = flatEncountersList(radEncounters);
    }
    setList(listData);
    setShowDrsList('');
  }, [labEncounters, radEncounters]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowDrsList('');
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  const searchDrHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDrsList('');
    const mobile = parseArabic(e.target.value);
    setSearchValue(mobile);
    if (mobileRegex.test(mobile)) {
      dispatch(findDrByMobileAction(mobile));
      setShowDrsList('searchResult');
    }
  };
  const inputFocusHandler = () => {
    setSearchValue('');
    referredDr = '';
    targetUnit = '';
    setShowDrsList('savedList');
  };

  const clickNameHandler = async (dr: any) => {
    setSearchValue(getFullName(dr.name));
    referredDr = dr;
    try {
      const data = await searchDrByMobile({ mobile: dr.mobile });
      if (data.exist) {
        drClinics = data.units.filter((unit: any) => unit.type === 'clinic');
        if (drClinics.length === 1) {
          targetUnit = drClinics[0];
        } else if (drClinics.length > 1) {
          setOpenClinicModal(true);
        }
      }
    } catch (error) {
      console.error('error[searchDRByMobile]', error);
    }
    setShowDrsList('');
  };

  const selectTestHandler = (
    r: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      if (!selectedTests.includes(r)) {
        selectedTests = [...selectedTests, r];
      }
    } else {
      if (selectedTests.includes(r)) {
        const selectedTestss = selectedTests.filter((ele) => ele != r);
        selectedTests = [...selectedTestss];
      }
    }
  };
  const addNotesHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNotes(value);
  };
  const sendHandler = () => {
    if (
      targetUnit === '' ||
      (selectedTests && selectedTests.length === 0 && notes === '')
    ) {
      let msg = '';
      targetUnit === '' ? (msg = 'doctor clinic') : (msg = 'data');
      confirmAlert({
        customUI: ({ onClose }) => (
          <CustomAlertUI
            title={'warning'}
            body={t('referrToDr_page.msg', {
              msg,
            })}
            onClose={onClose}
            send={onClose}
          />
        ),
      });
    } else {
      send();
    }
  };
  const send = () => {
    dispatch(addPatientReferedDataAction(targetUnit, selectedTests, notes));
    onModalClose();
  };

  const onClinicModalClose = () => {
    setOpenClinicModal(false);
  };

  const onClinicChoose = async (clinic: any) => {
    targetUnit = clinic;
  };
  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onModalClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="row modal-container">
        <div className="col-md-8 d-flex flex-column">
          <div className="title pb-2">To</div>
          <div className="search d-flex flex-column">
            <input
              placeholder={t('referrToDr_page.search')}
              onChange={searchDrHandler}
              maxLength={11}
              value={searchValue}
              onFocus={inputFocusHandler}
              autoComplete="off"
              className="search-input"
            ></input>
            {showDrsList != '' && (
              <div ref={divRef} tabIndex={2} className="dr-list scrollHide">
                {showDrsList === 'savedList'
                  ? drsList.map((dr: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => {
                          clickNameHandler(dr);
                        }}
                        className="dr-item d-flex align-items-center justify-content-between overflow-visible"
                      >
                        <p className="ar-font m-0">
                          {dr && dr.name ? getFullName(dr.name) : ''}
                        </p>
                        <p className="mobile m-0">
                          {dr && dr.mobile ? dr.mobile : ''}
                        </p>
                      </div>
                    ))
                  : showDrsList === 'searchResult' && (
                      <div
                        onClick={() => {
                          clickNameHandler(searchedDr);
                        }}
                        className="dr-item d-flex align-items-center justify-content-between overflow-visible"
                      >
                        <p className="ar-font m-0">
                          {searchedDr && searchedDr.name
                            ? getFullName(searchedDr.name)
                            : ''}
                        </p>
                        <p className="mobile m-0">
                          {searchedDr && searchedDr.mobile
                            ? searchedDr.mobile
                            : ''}
                        </p>
                      </div>
                    )}
              </div>
            )}
            <div className="col-bottom">
              {openClinicModal && (
                <CLinicModal
                  isOpen={openClinicModal}
                  onModalClose={onClinicModalClose}
                  data={drClinics}
                  onChoose={onClinicChoose}
                />
              )}
            </div>
          </div>
          <div className="title pt-2 pb-2">Notes</div>
          <textarea rows={10} onChange={addNotesHandler}></textarea>
        </div>
        <div className="col-md-4">
          <img
            src={closeIcon}
            className="close"
            alt=""
            onClick={onModalClose}
          />

          <div className="tests-container">
            <table className="table">
              <thead>
                <tr>
                  <th className="table-title">
                    <div className="title d-flex align-items-center">
                      Tests/Radiology
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((r: any, i: number) => {
                  return (
                    <tr key={`result-${i}`}>
                      <td className="cell-first d-flex">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            selectTestHandler(r, e);
                          }}
                        />
                        <label className="item ">{r.name}</label>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className=" mt-1 d-flex align-items-center flex-column">
            <button
              className="btn btn-primary btn-lg w-75 "
              onClick={() => {
                sendHandler();
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default ReferralModal;
