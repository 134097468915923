import io from 'socket.io-client';
import { socketUrl } from '../utils/serverUrl';
import {
  setBookingFromSocket,
  setTests,
  setReferedTests,
} from '../store/actions';
import {
  ADD_BOARD_BOOKING,
  ADD_LAB_BOOKING,
} from '../store/actions/actionTypes';
import unitTypes from '../utils/unitTypes';
import { Unit } from '../types';
import bookingStatus from '../assets/constants/bookingStatus';
// import { getStoredData } from '../utils/storage';
import { UserRoles } from '../assets/constants/roles';

let socket: SocketIOClient.Socket;

export const eventsNames = {
  BookingAdded: 'BookingAdded',
  BookingUpdated: 'BookingUpdated',
  BookingDeleted: 'BookingDeleted',
  LabsUpdated: 'LabsUpdated',
  RadsUpdated: 'RadsUpdated',
  referTests: 'referTests',
};

const initSocket = () => {
  socket = io(socketUrl);

  socket.on('connect', () => {
    // tslint:disable-next-line: no-console
    console.log('socket connected to server');
  });
};

export const closeSocket = () => {
  socket.on('disconnect', () => {
    // tslint:disable-next-line: no-console
    console.log('socket disconnect from server');
  });
};

export const emitSocketEvent = (eventName: string, payload: any) => {
  socket.emit(eventName, payload);
};

export const listenToSocketEvent = (
  eventName: string,
  currentEmpUnit: Unit,
  dispatch: any
) => {
  if (!currentEmpUnit) return;
  socket.on(eventName, (data: { action: string; data: any }) => {
    // console.log(`listenToSocketEvent[${eventName}]`, data);

    // listen to labs and rad tests
    if (
      [eventsNames.LabsUpdated, eventsNames.RadsUpdated].includes(
        data.action
      ) &&
      [UserRoles.Physician, UserRoles.LabTech, UserRoles.RadioTech].includes(
        currentEmpUnit.role
      )
    ) {
      // console.log(`tests[${eventName}]`, data);
      dispatch(
        setTests({
          tests: data.data.tests,
          encounter: data.data.sk,
          patientId: data.data.patientId,
          testType: data.action === eventsNames.LabsUpdated ? 'lab' : 'rad',
        })
      );
    }
    // refree tests
    else if (data.action === eventsNames.referTests) {
      dispatch(
        setReferedTests({
          payload: data.data,
          // tests: data.data.tests,
          // patientId: data.data.patientId,
          // notes: data.data.notes,
        })
      );
    } else {
      // listen to booking
      if (
        [UserRoles.Physician, UserRoles.LabTech, UserRoles.RadioTech].includes(
          currentEmpUnit.role
        )
      ) {
        if (currentEmpUnit.type === unitTypes.clinic) {
          dispatch({
            type: ADD_BOARD_BOOKING,
            payload:
              data.action === eventsNames.BookingDeleted
                ? { ...data.data, status: bookingStatus.deleted }
                : data.data,
          });
        } else {
          dispatch({
            type: ADD_LAB_BOOKING,
            payload:
              data.action === eventsNames.BookingDeleted
                ? { ...data.data, status: bookingStatus.deleted }
                : data.data,
          });
        }
        if (currentEmpUnit.role === UserRoles.Physician) {
          dispatch(setBookingFromSocket(data.data, data.action));
        }
      } else {
        dispatch(setBookingFromSocket(data.data, data.action));
      }
    }
  });
};

export const closeSocketEvent = (eventName: string) => {
  // console.log('eventName', eventName);
  socket.off(eventName);
};

export const socketRemoveAllListener = () => {
  socket.removeAllListeners();
  socket.close();
};

export default initSocket;
