import * as Yup from 'yup';

const formValidationSchema = (t: any) => {
  return Yup.object().shape(
    {
      oldPassword: Yup.string()
        .required(t('booking_page.required'))
        .min(8, t('changePassword_page.password-validation-8')),
      newPassword: Yup.string()
        .required(t('booking_page.required'))
        .test(
          'passwords-match',
          t('changePassword_page.new-password-match'),
          function (value) {
            if (this.parent.oldPassword === value) return false;
            return true;
          }
        )
        .min(8, t('changePassword_page.password-validation-8')),
      confirmPassword: Yup.string()
        .required(t('booking_page.required'))
        .oneOf(
          [Yup.ref('newPassword'), null],
          t('changePassword_page.password-match')
        ),
    },
    ['oldPassword', 'newPassword'] as any
  );
};

export default formValidationSchema;
