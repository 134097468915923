import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';

import { useTranslation } from 'react-i18next';
import uploadIcon from '../../../assets/img/upload.svg';

type Props = {
  getImage: (e: any, type: string) => void;
  type: string;
  imgSrc: string;
};

const MyDropzone: React.FC<Props> = ({ getImage, type, imgSrc }) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const image = acceptedFiles[0];
      // console.log('image', image);
      getImage(image, type);
    },
    [getImage, type]
  );

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className="drop-zone" {...getRootProps()}>
          {imgSrc ? (
            <img className="drop-zone-img" src={imgSrc} alt="upload" />
          ) : (
            <div className="profile upload d-flex flex-column align-items-center">
              <img src={uploadIcon} alt="upload" />
              <span>{t('complete_profile.drag-msg')}</span>
            </div>
          )}
          <input {...getInputProps()} accept="image/*" />
        </div>
      )}
    </Dropzone>
  );
};

export default MyDropzone;
