import React, { useState, useEffect } from 'react';
import ReactModal, { Styles } from 'react-modal';
import printIcon from '../../../../assets/img/Mask Group 360.svg';
import closeIcon from '../../../../assets/img/close-icon.svg';
import './Report.scss';
import { printMonthReport } from '../../../../services/print';

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  currentMonth: string;
  reportType: string;
  data: any[];
};

const customStyles: Styles = {
  overlay: {
    background: 'none',
  },
  content: {
    position: 'absolute',
    top: '116px',
    left: '240px',
    right: '20px',
    maxWidth: '80rem',
    border: '1px solid #ccc',
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '1rem',
    outline: 'none',
    padding: '20px',
    minHeight: '300px',
    boxShadow: '0px 0px 20px #0000004D',
  },
};
const labReportHeader = [
  'Patient name',
  'Lab',
  'Service',
  'Units',
  'cost',
  'Doctor name',
  'Impression date',
  'Delivery date',
];

const incomeReportHeader = ['Patient name', 'Payment', 'Doctor name', 'Date'];

const expencesReportHeader = ['Paid by', 'Type', 'Value', 'Date'];

const Report = (props: Props) => {
  const { isOpen, currentMonth, onModalClose, reportType, data } = props;
  const header =
    reportType === 'Lab'
      ? labReportHeader
      : reportType === 'Income'
      ? incomeReportHeader
      : reportType === 'Expences'
      ? expencesReportHeader
      : [];
  const handlePrint = () => {
    printMonthReport(currentMonth, reportType, data, header);
  };
  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onModalClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="report-modal">
        <div className="row report-modal-header d-flex ">
          <div className="col-8 title">
            {currentMonth} {reportType} report
          </div>
          <div className="col-4 d-flex justify-content-end ">
            <div className="print">
              <a
                className="btn btn-primary align-self-center "
                onClick={handlePrint}
              >
                <img src={printIcon} alt="" />
              </a>
            </div>
            <div className="close ">
              <img src={closeIcon} alt="closeIcon" onClick={onModalClose} />
            </div>
          </div>
        </div>
        <div className="report-modal-body">
          <table className="table table-responsive table-striped mt-4">
            <thead>
              <tr>
                {header.map((ele: any, index) => (
                  <th scope="col" key={index}>
                    <div>
                      <h6>{ele}</h6>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((ele: any, index) => (
                <tr key={index}>
                  {Object.entries(ele).map(([key]) => (
                    <td key={key}>{ele[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ReactModal>
  );
};
export default Report;
