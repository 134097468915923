const areas: any = {
  cairo: {
    May_15: { id: '1', ar: '15 مايو', en: '15 May' },
    alAzbakeyah: { id: '2', ar: 'الازبكية', en: 'Al Azbakeyah' },
    alBasatin: { id: '3', ar: 'البساتين', en: 'Al Basatin' },
    tebin: { id: '4', ar: 'التبين', en: 'Tebin' },
    elKhalifa: { id: '5', ar: 'الخليفة', en: 'El-Khalifa' },
    eldarrasa: { id: '6', ar: 'الدراسة', en: 'El darrasa' },
    aldarbAlahmar: { id: '7', ar: 'الدرب الاحمر', en: 'Aldarb Alahmar' },
    zawyaAlHamra: { id: '8', ar: 'الزاوية الحمراء', en: 'Zawya al-Hamra' },
    elZaytoun: { id: '9', ar: 'الزيتون', en: 'El-Zaytoun' },
    Sahel: { id: '10', ar: 'الساحل', en: 'Sahel' },
    elSalam: { id: '11', ar: 'السلام', en: 'El Salam' },
    sayedaZeinab: { id: '12', ar: 'السيدة زينب', en: 'Sayeda Zeinab' },
    elSharabeya: { id: '13', ar: 'الشرابية', en: 'El Sharabeya' },
    shorouk: { id: '14', ar: 'مدينة الشروق', en: 'Shorouk' },
    elDaher: { id: '15', ar: 'الظاهر', en: 'El Daher' },
    ataba: { id: '16', ar: 'العتبة', en: 'Ataba' },
    newCairo: { id: '17', ar: 'القاهرة الجديدة', en: 'New Cairo' },
    elMarg: { id: '18', ar: 'المرج', en: 'El Marg' },
    ezbetElNakhl: { id: '19', ar: 'عزبة النخل', en: 'Ezbet el Nakhl' },
    Matareya: { id: '20', ar: 'المطرية', en: 'Matareya' },
    maadi: { id: '21', ar: 'المعادى', en: 'Maadi' },
    maasara: { id: '22', ar: 'المعصرة', en: 'Maasara' },
    mokattam: { id: '23', ar: 'المقطم', en: 'Mokattam' },
    manyal: { id: '24', ar: 'المنيل', en: 'Manyal' },
    mosky: { id: '25', ar: 'الموسكى', en: 'Mosky' },
    nozha: { id: '26', ar: 'النزهة', en: 'Nozha' },
    waily: { id: '27', ar: 'الوايلى', en: 'Waily' },
    babAlShereia: { id: '28', ar: 'باب الشعرية', en: 'Bab al-Shereia' },
    bolaq: { id: '29', ar: 'بولاق', en: 'Bolaq' },
    gardenCity: { id: '30', ar: 'جاردن سيتى', en: 'Garden City' },
    hadayekElKobba: { id: '31', ar: 'حدائق القبة', en: 'Hadayek El-Kobba' },
    helwan: { id: '32', ar: 'حلوان', en: 'Helwan' },
    darAlSalam: { id: '33', ar: 'دار السلام', en: 'Dar Al Salam' },
    shubra: { id: '34', ar: 'شبرا', en: 'Shubra' },
    Tura: { id: '35', ar: 'طره', en: 'Tura' },
    abdeen: { id: '36', ar: 'عابدين', en: 'Abdeen' },
    abaseya: { id: '37', ar: 'عباسية', en: 'Abaseya' },
    ainShams: { id: '38', ar: 'عين شمس', en: 'Ain Shams' },
    nasrCity: { id: '39', ar: 'مدينة نصر', en: 'Nasr City' },
    newHeliopolis: { id: '40', ar: 'مصر الجديدة', en: 'New Heliopolis' },
    masrAlQadima: { id: '41', ar: 'مصر القديمة', en: 'Masr Al Qadima' },
    mansheyaNasir: { id: '42', ar: 'منشية ناصر', en: 'Mansheya Nasir' },
    badrCity: { id: '43', ar: 'مدينة بدر', en: 'Badr City' },
    obourCity: { id: '44', ar: 'مدينة العبور', en: 'Obour City' },
    cairoDowntown: { id: '45', ar: 'وسط البلد', en: 'Cairo Downtown' },
    zamalek: { id: '46', ar: 'الزمالك', en: 'Zamalek' },
    kasrElNile: { id: '47', ar: 'قصر النيل', en: 'Kasr El Nile' },
    rehab: { id: '48', ar: 'الرحاب', en: 'Rehab' },
    katameya: { id: '49', ar: 'القطامية', en: 'Katameya' },
    madinty: { id: '50', ar: 'مدينتي', en: 'Madinty' },
    rodAlfarag: { id: '51', ar: 'روض الفرج', en: 'Rod Alfarag' },
    sheraton: { id: '52', ar: 'شيراتون', en: 'Sheraton' },
    elGamaleya: { id: '53', ar: 'الجمالية', en: 'El-Gamaleya' },
    ramadanCity: {
      id: '54',
      ar: 'العاشر من رمضان',
      en: '10th of Ramadan City',
    },
    helmeyatAlzaytoun: { id: '55', ar: 'الحلمية', en: 'Helmeyat Alzaytoun' },
    newNozha: { id: '56', ar: 'النزهة الجديدة', en: 'New Nozha' },
    capitalNew: { id: '57', ar: 'العاصمة الإدارية', en: 'Capital New' },
  },
  giza: {
    giza: { id: '58', ar: 'الجيزة', en: 'Giza' },
    sixthOfOctober: {
      id: '59',
      ar: 'السادس من أكتوبر',
      en: 'Sixth of October',
    },
    cheikhZayed: { id: '60', ar: 'الشيخ زايد', en: 'Cheikh Zayed' },
    hawamdiyah: { id: '61', ar: 'الحوامدية', en: 'Hawamdiyah' },
    badrasheen: { id: '62', ar: 'البدرشين', en: 'Al Badrasheen' },
    saf: { id: '63', ar: 'الصف', en: 'Saf' },
    atfih: { id: '64', ar: 'أطفيح', en: 'Atfih' },
    alAyat: { id: '65', ar: 'العياط', en: 'Al Ayat' },
    alBawaiti: { id: '66', ar: 'الباويطي', en: 'Al-Bawaiti' },
    manshiyetAlQanater: {
      id: '67',
      ar: 'منشأة القناطر',
      en: 'ManshiyetAl Qanater',
    },
    oaseem: { id: '68', ar: 'أوسيم', en: 'Oaseem' },
    kerdasa: { id: '69', ar: 'كرداسة', en: 'Kerdasa' },
    abuNomros: { id: '70', ar: 'أبو النمرس', en: 'Abu Nomros' },
    kafrGhati: { id: '71', ar: 'كفر غطاطي', en: 'Kafr Ghati' },
    manshiyetAlBakari: {
      id: '72',
      ar: 'منشأة البكاري',
      en: 'Manshiyet Al Bakari',
    },
    dokki: { id: '73', ar: 'الدقى', en: 'Dokki' },
    agouza: { id: '74', ar: 'العجوزة', en: 'Agouza' },
    haram: { id: '75', ar: 'الهرم', en: 'Haram' },
    warraq: { id: '76', ar: 'الوراق', en: 'Warraq' },
    imbaba: { id: '77', ar: 'امبابة', en: 'Imbaba' },
    boulaqDakrour: { id: '78', ar: 'بولاق الدكرور', en: 'Boulaq Dakrour' },
    alWahatAlBaharia: {
      id: '79',
      ar: 'الواحات البحرية',
      en: 'Al Wahat Al Baharia',
    },
    omraneya: { id: '80', ar: 'العمرانية', en: 'Omraneya' },
    moneeb: { id: '81', ar: 'المنيب', en: 'Moneeb' },
    binAlsarayat: { id: '82', ar: 'بين السرايات', en: 'Bin Alsarayat' },
    kitKat: { id: '83', ar: 'الكيت كات', en: 'Kit Kat' },
    mohandessin: { id: '84', ar: 'المهندسين', en: 'Mohandessin' },
    faisal: { id: '85', ar: 'فيصل', en: 'Faisal' },
    abuRawash: { id: '86', ar: 'أبو رواش', en: 'Abu Rawash' },
    hadayekAlahram: { id: '87', ar: 'حدائق الأهرام', en: 'Hadayek Alahram' },
    haraneya: { id: '88', ar: 'الحرانية', en: 'Haraneya' },
    hadayekOctober: { id: '89', ar: 'حدائق اكتوبر', en: 'Hadayek October' },
    saftAllaban: { id: '90', ar: 'صفط اللبن', en: 'Saft Allaban' },
    smartVillage: { id: '91', ar: 'القرية الذكية', en: 'Smart Village' },
    ardEllwaa: { id: '92', ar: 'ارض اللواء', en: 'Ard Ellwaa' },
  },
  portsaid: {
    porSaid: { id: '287', ar: 'بورسعيد', en: 'PorSaid' },
    portFouad: { id: '288', ar: 'بورفؤاد', en: 'Port Fouad' },
    alarab: { id: '289', ar: 'العرب', en: 'Alarab' },
    zohour: { id: '290', ar: 'حى الزهور', en: 'Zohour' },
    alsharq: { id: '291', ar: 'حى الشرق', en: 'Alsharq' },
    aldawahi: { id: '292', ar: 'حى الضواحى', en: 'Aldawahi' },
    almanakh: { id: '293', ar: 'حى المناخ', en: 'Almanakh' },
    mubarak: { id: '294', ar: 'حى مبارك', en: 'Mubarak' },
  },
  elsawies: {
    suez: { id: '249', ar: 'السويس', en: 'Suez' },
    alganayen: { id: '250', ar: 'الجناين', en: 'Alganayen' },
    ataqah: { id: '251', ar: 'عتاقة', en: 'Ataqah' },
    ainSokhna: { id: '252', ar: 'العين السخنة', en: 'Ain Sokhna' },
    faysal: { id: '253', ar: 'فيصل', en: 'Faysal' },
  },
  damietta: {
    damietta: { id: '295', ar: 'دمياط', en: 'Damietta' },
    newDamietta: { id: '296', ar: 'دمياط الجديدة', en: 'New Damietta' },
    rasElBar: { id: '297', ar: 'رأس البر', en: 'Ras El Bar' },
    faraskour: { id: '298', ar: 'فارسكور', en: 'Faraskour' },
    zarqa: { id: '299', ar: 'الزرقا', en: 'Zarqa' },
    alsaru: { id: '300', ar: 'السرو', en: 'alsaru' },
    alruwda: { id: '301', ar: 'الروضة', en: 'alruwda' },
    kafrElBatikh: { id: '302', ar: 'كفر البطيخ', en: 'Kafr El-Batikh' },
    azbetAlBurg: { id: '303', ar: 'عزبة البرج', en: 'Azbet Al Burg' },
    meetAbouGhalib: { id: '304', ar: 'ميت أبو غالب', en: 'Meet Abou Ghalib' },
    kafrSaad: { id: '305', ar: 'كفر سعد', en: 'Kafr Saad' },
  },
  daqahlia: {
    mansoura: { id: '137', ar: 'المنصورة', en: 'Mansoura' },
    talkha: { id: '138', ar: 'طلخا', en: 'Talkha' },
    mittGhamr: { id: '139', ar: 'ميت غمر', en: 'Mitt Ghamr' },
    dekernes: { id: '140', ar: 'دكرنس', en: 'Dekernes' },
    aga: { id: '141', ar: 'أجا', en: 'Aga' },
    meniaElNasr: { id: '142', ar: 'منية النصر', en: 'Menia El Nasr' },
    Sinbillawin: { id: '143', ar: 'السنبلاوين', en: 'Sinbillawin' },
    elKurdi: { id: '144', ar: 'الكردي', en: 'El Kurdi' },
    baniUbaid: { id: '145', ar: 'بني عبيد', en: 'Bani Ubaid' },
    alManzala: { id: '146', ar: 'المنزلة', en: 'Al Manzala' },
    tamiAlamdid: { id: '147', ar: 'تمي الأمديد', en: "tami al'amdid" },
    aljamalia: { id: '148', ar: 'الجمالية', en: 'aljamalia' },
    sherbin: { id: '149', ar: 'شربين', en: 'Sherbin' },
    mataria: { id: '150', ar: 'المطرية', en: 'Mataria' },
    belqas: { id: '151', ar: 'بلقاس', en: 'Belqas' },
    meetSalsil: { id: '152', ar: 'ميت سلسيل', en: 'Meet Salsil' },
    gamasa: { id: '153', ar: 'جمصة', en: 'Gamasa' },
    mahalatDamana: { id: '154', ar: 'محلة دمنة', en: 'Mahalat Damana' },
    nabroh: { id: '155', ar: 'نبروه', en: 'Nabroh' },
  },
  sharqia: {
    zagazig: { id: '306', ar: 'الزقازيق', en: 'Zagazig' },
    alAshrMenRamadan: {
      id: '307',
      ar: 'العاشر من رمضان',
      en: 'Al Ashr Men Ramadan',
    },
    minyaAlQamh: { id: '308', ar: 'منيا القمح', en: 'Minya Al Qamh' },
    belbeis: { id: '309', ar: 'بلبيس', en: 'Belbeis' },
    mashtoulElSouq: { id: '310', ar: 'مشتول السوق', en: 'Mashtoul El Souq' },
    qenaiat: { id: '311', ar: 'القنايات', en: 'Qenaiat' },
    abuHammad: { id: '312', ar: 'أبو حماد', en: 'Abu Hammad' },
    elQurain: { id: '313', ar: 'القرين', en: 'El Qurain' },
    hehia: { id: '314', ar: 'ههيا', en: 'Hehia' },
    abuKabir: { id: '315', ar: 'أبو كبير', en: 'Abu Kabir' },
    faccus: { id: '316', ar: 'فاقوس', en: 'Faccus' },
    elSalihiaElGedida: {
      id: '317',
      ar: 'الصالحية الجديدة',
      en: 'El Salihia El Gedida',
    },
    alIbrahimiyah: { id: '318', ar: 'الإبراهيمية', en: 'Al Ibrahimiyah' },
    deirbNegm: { id: '319', ar: 'ديرب نجم', en: 'Deirb Negm' },
    kafrSaqr: { id: '320', ar: 'كفر صقر', en: 'Kafr Saqr' },
    awladSaqr: { id: '321', ar: 'أولاد صقر', en: 'Awlad Saqr' },
    husseiniya: { id: '322', ar: 'الحسينية', en: 'Husseiniya' },
    sanAlhajarAlqablia: {
      id: '323',
      ar: 'صان الحجر القبلية',
      en: 'san alhajar alqablia',
    },
    manshayatAbuOmar: {
      id: '324',
      ar: 'منشأة أبو عمر',
      en: 'Manshayat Abu Omar',
    },
  },
  qaliobia: {
    banha: { id: '231', ar: 'بنها', en: 'Banha' },
    qalyub: { id: '232', ar: 'قليوب', en: 'Qalyub' },
    shubraAlKhaimah: { id: '233', ar: 'شبرا الخيمة', en: 'Shubra Al Khaimah' },
    alQanaterCharity: {
      id: '234',
      ar: 'القناطر الخيرية',
      en: 'Al Qanater Charity',
    },
    khanka: { id: '235', ar: 'الخانكة', en: 'Khanka' },
    kafrShukr: { id: '236', ar: 'كفر شكر', en: 'Kafr Shukr' },
    tukh: { id: '237', ar: 'طوخ', en: 'Tukh' },
    qaha: { id: '238', ar: 'قها', en: 'Qaha' },
    obour: { id: '239', ar: 'العبور', en: 'Obour' },
    khosous: { id: '240', ar: 'الخصوص', en: 'Khosous' },
    shibinAlQanater: { id: '241', ar: 'شبين القناطر', en: 'Shibin Al Qanater' },
    mostorod: { id: '242', ar: 'مسطرد', en: 'Mostorod' },
  },
  kafrElshiekh: {
    kafrElSheikh: { id: '334', ar: 'كفر الشيخ', en: 'Kafr El Sheikh' },
    kafrElSheikhDowntown: {
      id: '335',
      ar: 'وسط البلد كفر الشيخ',
      en: 'Kafr El Sheikh Downtown',
    },
    desouq: { id: '336', ar: 'دسوق', en: 'Desouq' },
    fooh: { id: '337', ar: 'فوه', en: 'Fooh' },
    metobas: { id: '338', ar: 'مطوبس', en: 'Metobas' },
    burgAlBurullus: { id: '339', ar: 'برج البرلس', en: 'Burg Al Burullus' },
    baltim: { id: '340', ar: 'بلطيم', en: 'Baltim' },
    masiefBaltim: { id: '341', ar: 'مصيف بلطيم', en: 'Masief Baltim' },
    hamol: { id: '342', ar: 'الحامول', en: 'Hamol' },
    bella: { id: '343', ar: 'بيلا', en: 'Bella' },
    riyadh: { id: '344', ar: 'الرياض', en: 'Riyadh' },
    sidiSalm: { id: '345', ar: 'سيدي سالم', en: 'Sidi Salm' },
    qellen: { id: '346', ar: 'قلين', en: 'Qellen' },
    sidiGhazi: { id: '347', ar: 'سيدي غازي', en: 'Sidi Ghazi' },
  },
  gharbia: {
    tanta: { id: '192', ar: 'طنطا', en: 'Tanta' },
    alMahallaAlKobra: {
      id: '193',
      ar: 'المحلة الكبرى',
      en: 'Al Mahalla Al Kobra',
    },
    kafrElZayat: { id: '194', ar: 'كفر الزيات', en: 'Kafr El Zayat' },
    zefta: { id: '195', ar: 'زفتى', en: 'Zefta' },
    elSanta: { id: '196', ar: 'السنطة', en: 'El Santa' },
    qutour: { id: '197', ar: 'قطور', en: 'Qutour' },
    basion: { id: '198', ar: 'بسيون', en: 'Basion' },
    samannoud: { id: '199', ar: 'سمنود', en: 'Samannoud' },
  },
  menofia: {
    shbeenElKoom: { id: '209', ar: 'شبين الكوم', en: 'Shbeen El Koom' },
    sadatCity: { id: '210', ar: 'مدينة السادات', en: 'Sadat City' },
    menouf: { id: '211', ar: 'منوف', en: 'Menouf' },
    sarsElLayan: { id: '212', ar: 'سرس الليان', en: 'Sars El-Layan' },
    ashmon: { id: '213', ar: 'أشمون', en: 'Ashmon' },
    alBagor: { id: '214', ar: 'الباجور', en: 'Al Bagor' },
    quesna: { id: '215', ar: 'قويسنا', en: 'Quesna' },
    berkatElSaba: { id: '216', ar: 'بركة السبع', en: 'Berkat El Saba' },
    tala: { id: '217', ar: 'تلا', en: 'Tala' },
    alShohada: { id: '218', ar: 'الشهداء', en: 'Al Shohada' },
  },
  beheira: {
    damanhour: { id: '164', ar: 'دمنهور', en: 'Damanhour' },
    kafrElDawar: { id: '165', ar: 'كفر الدوار', en: 'Kafr El Dawar' },
    eashid: { id: '166', ar: 'رشيد', en: 'Rashid' },
    edco: { id: '167', ar: 'إدكو', en: 'Edco' },
    abuAlMatamir: { id: '168', ar: 'أبو المطامير', en: 'Abu al-Matamir' },
    abuHoms: { id: '169', ar: 'أبو حمص', en: 'Abu Homs' },
    delengat: { id: '170', ar: 'الدلنجات', en: 'Delengat' },
    mahmoudiyah: { id: '171', ar: 'المحمودية', en: 'Mahmoudiyah' },
    rahmaniyah: { id: '172', ar: 'الرحمانية', en: 'Rahmaniyah' },
    itaiBaroud: { id: '173', ar: 'إيتاي البارود', en: 'Itai Baroud' },
    houshEissa: { id: '174', ar: 'حوش عيسى', en: 'Housh Eissa' },
    shubrakhit: { id: '175', ar: 'شبراخيت', en: 'Shubrakhit' },
    komHamada: { id: '176', ar: 'كوم حمادة', en: 'Kom Hamada' },
    badr: { id: '177', ar: 'بدر', en: 'Badr' },
    wadiNatrun: { id: '178', ar: 'وادي النطرون', en: 'Wadi Natrun' },
    newNubaria: { id: '179', ar: 'النوبارية الجديدة', en: 'New Nubaria' },
    alnoubareya: { id: '180', ar: 'النوبارية', en: 'Alnoubareya' },
  },
  ismailia: {
    ismailia: { id: '200', ar: 'الإسماعيلية', en: 'Ismailia' },
    fayed: { id: '201', ar: 'فايد', en: 'Fayed' },
    qantaraSharq: { id: '202', ar: 'القنطرة شرق', en: 'Qantara Sharq' },
    qantaraGharb: { id: '203', ar: 'القنطرة غرب', en: 'Qantara Gharb' },
    elTalElKabier: { id: '204', ar: 'التل الكبير', en: 'El Tal El Kabier' },
    abuSawir: { id: '205', ar: 'أبو صوير', en: 'Abu Sawir' },
    kasasienElGedida: {
      id: '206',
      ar: 'القصاصين الجديدة',
      en: 'Kasasien El Gedida',
    },
    nefesha: { id: '207', ar: 'نفيشة', en: 'Nefesha' },
    sheikhZayed: { id: '208', ar: 'الشيخ زايد', en: 'Sheikh Zayed' },
  },
  alexandria: {
    abuQir: { id: '93', ar: 'ابو قير', en: 'Abu Qir' },
    alIbrahimeyah: { id: '94', ar: 'الابراهيمية', en: 'Al Ibrahimeyah' },
    azarita: { id: '95', ar: 'الأزاريطة', en: 'Azarita' },
    anfoushi: { id: '96', ar: 'الانفوشى', en: 'Anfoushi' },
    dekheila: { id: '97', ar: 'الدخيلة', en: 'Dekheila' },
    elSoyof: { id: '98', ar: 'السيوف', en: 'El Soyof' },
    ameria: { id: '99', ar: 'العامرية', en: 'Ameria' },
    elLabban: { id: '100', ar: 'اللبان', en: 'El Labban' },
    alMafrouza: { id: '101', ar: 'المفروزة', en: 'Al Mafrouza' },
    elMontaza: { id: '102', ar: 'المنتزه', en: 'El Montaza' },
    mansheya: { id: '103', ar: 'المنشية', en: 'Mansheya' },
    naseria: { id: '104', ar: 'الناصرية', en: 'Naseria' },
    ambrozo: { id: '105', ar: 'امبروزو', en: 'Ambrozo' },
    babSharq: { id: '106', ar: 'باب شرق', en: 'Bab Sharq' },
    bourjAlarab: { id: '107', ar: 'برج العرب', en: 'Bourj Alarab' },
    stanley: { id: '108', ar: 'ستانلى', en: 'Stanley' },
    smouha: { id: '109', ar: 'سموحة', en: 'Smouha' },
    sidiBishr: { id: '110', ar: 'سيدى بشر', en: 'Sidi Bishr' },
    shads: { id: '111', ar: 'شدس', en: 'Shads' },
    gheetAlenab: { id: '112', ar: 'غيط العنب', en: 'Gheet Alenab' },
    fleming: { id: '113', ar: 'فلمينج', en: 'Fleming' },
    victoria: { id: '114', ar: 'فيكتوريا', en: 'Victoria' },
    campShizar: { id: '115', ar: 'كامب شيزار', en: 'Camp Shizar' },
    karmooz: { id: '116', ar: 'كرموز', en: 'Karmooz' },
    mahtaAlraml: { id: '117', ar: 'محطة الرمل', en: 'Mahta Alraml' },
    minaElBasal: { id: '118', ar: 'مينا البصل', en: 'Mina El-Basal' },
    asafra: { id: '119', ar: 'العصافرة', en: 'Asafra' },
    agamy: { id: '120', ar: 'العجمي', en: 'Agamy' },
    bakos: { id: '121', ar: 'بكوس', en: 'Bakos' },
    boulkly: { id: '122', ar: 'بولكلي', en: 'Boulkly' },
    cleopatra: { id: '123', ar: 'كليوباترا', en: 'Cleopatra' },
    glim: { id: '124', ar: 'جليم', en: 'Glim' },
    alMamurah: { id: '125', ar: 'المعمورة', en: 'Al Mamurah' },
    alMandara: { id: '126', ar: 'المندرة', en: 'Al Mandara' },
    moharamBek: { id: '127', ar: 'محرم بك', en: 'Moharam Bek' },
    elshatby: { id: '128', ar: 'الشاطبي', en: 'Elshatby' },
    sidiGaber: { id: '129', ar: 'سيدي جابر', en: 'Sidi Gaber' },
    northCoast: { id: '130', ar: 'الساحل الشمالي', en: 'North Coast/sahel' },
    alhadra: { id: '131', ar: 'الحضرة', en: 'Alhadra' },
    alattarin: { id: '132', ar: 'العطارين', en: 'Alattarin' },
    sidiKerir: { id: '133', ar: 'سيدي كرير', en: 'Sidi Kerir' },
    elgomrok: { id: '134', ar: 'الجمرك', en: 'Elgomrok' },
    alMax: { id: '135', ar: 'المكس', en: 'Al Max' },
    marina: { id: '136', ar: 'مارينا', en: 'Marina' },
  },
  beniSuef: {
    baniSweif: { id: '277', ar: 'بني سويف', en: 'Bani Sweif' },
    beniSuefElGedida: {
      id: '278',
      ar: 'بني سويف الجديدة',
      en: 'Beni Suef El Gedida',
    },
    alWasta: { id: '279', ar: 'الواسطى', en: 'Al Wasta' },
    naser: { id: '280', ar: 'ناصر', en: 'Naser' },
    ehnasia: { id: '281', ar: 'إهناسيا', en: 'Ehnasia' },
    beba: { id: '282', ar: 'ببا', en: 'beba' },
    fashn: { id: '283', ar: 'الفشن', en: 'Fashn' },
    somasta: { id: '284', ar: 'سمسطا', en: 'Somasta' },
    alabbaseri: { id: '285', ar: 'الاباصيرى', en: 'Alabbaseri' },
    mokbel: { id: '286', ar: 'مقبل', en: 'Mokbel' },
  },
  faiyum: {
    fayoum: { id: '181', ar: 'الفيوم', en: 'Fayoum' },
    fayoumElGedida: { id: '182', ar: 'الفيوم الجديدة', en: 'Fayoum El Gedida' },
    Tamiya: { id: '183', ar: 'طامية', en: 'Tamiya' },
    Snores: { id: '184', ar: 'سنورس', en: 'Snores' },
    Etsa: { id: '185', ar: 'إطسا', en: 'Etsa' },
    epschway: { id: '186', ar: 'إبشواي', en: 'Epschway' },
    yusufElSediaq: { id: '187', ar: 'يوسف الصديق', en: 'Yusuf El Sediaq' },
    hadqa: { id: '188', ar: 'الحادقة', en: 'Hadqa' },
    atsa: { id: '189', ar: 'اطسا', en: 'Atsa' },
    algamaa: { id: '190', ar: 'الجامعة', en: 'Algamaa' },
    sayala: { id: '191', ar: 'السيالة', en: 'Sayala' },
  },
  minya: {
    minya: { id: '219', ar: 'المنيا', en: 'Minya' },
    minyaElGedida: { id: '220', ar: 'المنيا الجديدة', en: 'Minya El Gedida' },
    elAdwa: { id: '221', ar: 'العدوة', en: 'El Adwa' },
    magagha: { id: '222', ar: 'مغاغة', en: 'Magagha' },
    baniMazar: { id: '223', ar: 'بني مزار', en: 'Bani Mazar' },
    mattay: { id: '224', ar: 'مطاي', en: 'Mattay' },
    samalut: { id: '225', ar: 'سمالوط', en: 'Samalut' },
    madinatElFekria: {
      id: '226',
      ar: 'المدينة الفكرية',
      en: 'Madinat El Fekria',
    },
    meloy: { id: '227', ar: 'ملوي', en: 'Meloy' },
    deirMawas: { id: '228', ar: 'دير مواس', en: 'Deir Mawas' },
    abuQurqas: { id: '229', ar: 'ابو قرقاص', en: 'Abu Qurqas' },
    ardSultan: { id: '230', ar: 'ارض سلطان', en: 'Ard Sultan' },
  },
  asyut: {
    assiut: { id: '266', ar: 'أسيوط', en: 'Assiut' },
    assiutElGedida: { id: '267', ar: 'أسيوط الجديدة', en: 'Assiut El Gedida' },
    dayrout: { id: '268', ar: 'ديروط', en: 'Dayrout' },
    manfalut: { id: '269', ar: 'منفلوط', en: 'Manfalut' },
    qusiya: { id: '270', ar: 'القوصية', en: 'Qusiya' },
    abnoub: { id: '271', ar: 'أبنوب', en: 'Abnoub' },
    abuTig: { id: '272', ar: 'أبو تيج', en: 'Abu Tig' },
    elGhanaim: { id: '273', ar: 'الغنايم', en: 'El Ghanaim' },
    sahelSelim: { id: '274', ar: 'ساحل سليم', en: 'Sahel Selim' },
    elBadari: { id: '275', ar: 'البداري', en: 'El Badari' },
    sidfa: { id: '276', ar: 'صدفا', en: 'Sidfa' },
  },
  sohag: {
    sohag: { id: '383', ar: 'سوهاج', en: 'Sohag' },
    sohagElGedida: { id: '384', ar: 'سوهاج الجديدة', en: 'Sohag El Gedida' },
    akhmeem: { id: '385', ar: 'أخميم', en: 'Akhmeem' },
    akhmimElGedida: { id: '386', ar: 'أخميم الجديدة', en: 'Akhmim El Gedida' },
    albalina: { id: '387', ar: 'البلينا', en: 'Albalina' },
    elMaragha: { id: '388', ar: 'المراغة', en: 'El Maragha' },
    almunsha: { id: '389', ar: 'المنشأة', en: "almunsha'a" },
    darAISalaam: { id: '390', ar: 'دار السلام', en: 'Dar AISalaam' },
    gerga: { id: '391', ar: 'جرجا', en: 'Gerga' },
    jahinaAlGharbia: {
      id: '392',
      ar: 'جهينة الغربية',
      en: 'Jahina Al Gharbia',
    },
    saqilatuh: { id: '393', ar: 'ساقلته', en: 'Saqilatuh' },
    tama: { id: '394', ar: 'طما', en: 'Tama' },
    tahta: { id: '395', ar: 'طهطا', en: 'Tahta' },
    alkawthar: { id: '396', ar: 'الكوثر', en: 'Alkawthar' },
  },
  qena: {
    qena: { id: '367', ar: 'قنا', en: 'Qena' },
    newQena: { id: '368', ar: 'قنا الجديدة', en: 'New Qena' },
    abuTesht: { id: '369', ar: 'ابو طشت', en: 'Abu Tesht' },
    nagHammadi: { id: '370', ar: 'نجع حمادي', en: 'Nag Hammadi' },
    deshna: { id: '371', ar: 'دشنا', en: 'Deshna' },
    alwaqf: { id: '372', ar: 'الوقف', en: 'Alwaqf' },
    qaft: { id: '373', ar: 'قفط', en: 'Qaft' },
    naqada: { id: '374', ar: 'نقادة', en: 'Naqada' },
    farshout: { id: '375', ar: 'فرشوط', en: 'Farshout' },
    quos: { id: '376', ar: 'قوص', en: 'Quos' },
  },
  aswan: {
    aswan: { id: '254', ar: 'أسوان', en: 'Aswan' },
    aswanElGedida: { id: '255', ar: 'أسوان الجديدة', en: 'Aswan El Gedida' },
    drau: { id: '256', ar: 'دراو', en: 'Drau' },
    komOmbo: { id: '257', ar: 'كوم أمبو', en: 'Kom Ombo' },
    nasrAlNuba: { id: '258', ar: 'نصر النوبة', en: 'Nasr Al Nuba' },
    kalabsha: { id: '259', ar: 'كلابشة', en: 'Kalabsha' },
    edfu: { id: '260', ar: 'إدفو', en: 'Edfu' },
    alRadisiyah: { id: '261', ar: 'الرديسية', en: 'Al-Radisiyah' },
    alBasilia: { id: '262', ar: 'البصيلية', en: 'Al Basilia' },
    alSibaeia: { id: '263', ar: 'السباعية', en: 'Al Sibaeia' },
    aboSimblAlSiyahia: {
      id: '264',
      ar: 'ابوسمبل السياحية',
      en: 'Abo Simbl Al Siyahia',
    },
    marsaAlam: { id: '265', ar: 'مرسى علم', en: 'Marsa Alam' },
  },
  luxor: {
    luxor: { id: '358', ar: 'الأقصر', en: 'Luxor' },
    newLuxor: { id: '359', ar: 'الأقصر الجديدة', en: 'New Luxor' },
    Esna: { id: '360', ar: 'إسنا', en: 'Esna' },
    newTiba: { id: '361', ar: 'طيبة الجديدة', en: 'New Tiba' },
    alziynia: { id: '362', ar: 'الزينية', en: 'Al ziynia' },
    alBayadieh: { id: '363', ar: 'البياضية', en: 'Al Bayadieh' },
    alQarna: { id: '364', ar: 'القرنة', en: 'Al Qarna' },
    armant: { id: '365', ar: 'أرمنت', en: 'Armant' },
    alTud: { id: '366', ar: 'الطود', en: 'Al Tud' },
  },
  redSea: {
    hurghada: { id: '156', ar: 'الغردقة', en: 'Hurghada' },
    rasGhareb: { id: '157', ar: 'رأس غارب', en: 'Ras Ghareb' },
    safaga: { id: '158', ar: 'سفاجا', en: 'Safaga' },
    elQusiar: { id: '159', ar: 'القصير', en: 'El Qusiar' },
    marsaAlam: { id: '160', ar: 'مرسى علم', en: 'Marsa Alam' },
    shalatin: { id: '161', ar: 'الشلاتين', en: 'Shalatin' },
    halaib: { id: '162', ar: 'حلايب', en: 'Halaib' },
    aldahar: { id: '163', ar: 'الدهار', en: 'Aldahar' },
  },
  newValley: {
    elKharga: { id: '243', ar: 'الخارجة', en: 'El Kharga' },
    paris: { id: '244', ar: 'باريس', en: 'Paris' },
    mout: { id: '245', ar: 'موط', en: 'Mout' },
    farafra: { id: '246', ar: 'الفرافرة', en: 'Farafra' },
    balat: { id: '247', ar: 'بلاط', en: 'Balat' },
    dakhla: { id: '248', ar: 'الداخلة', en: 'Dakhla' },
  },
  marsaMatruh: {
    marsaMatrouh: { id: '348', ar: 'مرسى مطروح', en: 'Marsa Matrouh' },
    elHamam: { id: '349', ar: 'الحمام', en: 'El Hamam' },
    alamein: { id: '350', ar: 'العلمين', en: 'Alamein' },
    dabaa: { id: '351', ar: 'الضبعة', en: 'Dabaa' },
    alNagila: { id: '352', ar: 'النجيلة', en: 'Al-Nagila' },
    sidiBrani: { id: '353', ar: 'سيدي براني', en: 'Sidi Brani' },
    salloum: { id: '354', ar: 'السلوم', en: 'Salloum' },
    siwa: { id: '355', ar: 'سيوة', en: 'Siwa' },
    marina: { id: '356', ar: 'مارينا', en: 'Marina' },
    northCoast: { id: '357', ar: 'الساحل الشمالى', en: 'North Coast' },
  },
  northSinai: {
    arish: { id: '377', ar: 'العريش', en: 'Arish' },
    sheikhZowaid: { id: '378', ar: 'الشيخ زويد', en: 'Sheikh Zowaid' },
    nakhl: { id: '379', ar: 'نخل', en: 'Nakhl' },
    rafah: { id: '380', ar: 'رفح', en: 'Rafah' },
    biralAbed: { id: '381', ar: 'بئر العبد', en: 'Bir al-Abed' },
    hasana: { id: '382', ar: 'الحسنة', en: 'Al Hasana' },
  },
  southSinai: {
    alToor: { id: '325', governorate_id: '21', ar: 'الطور', en: 'Al Toor' },
    sharmElShaikh: {
      id: '326',
      governorate_id: '21',
      ar: 'شرم الشيخ',
      en: 'Sharm El-Shaikh',
    },
    dahab: { id: '327', governorate_id: '21', ar: 'دهب', en: 'Dahab' },
    nuweiba: { id: '328', governorate_id: '21', ar: 'نويبع', en: 'Nuweiba' },
    taba: { id: '329', governorate_id: '21', ar: 'طابا', en: 'Taba' },
    saintCatherine: {
      id: '330',
      governorate_id: '21',
      ar: 'سانت كاترين',
      en: 'Saint Catherine',
    },
    abuRedis: {
      id: '331',
      governorate_id: '21',
      ar: 'أبو رديس',
      en: 'Abu Redis',
    },
    abuZenaima: {
      id: '332',
      governorate_id: '21',
      ar: 'أبو زنيمة',
      en: 'Abu Zenaima',
    },
    rasSidr: { id: '333', governorate_id: '21', ar: 'رأس سدر', en: 'Ras Sidr' },
  },
  foreign: {},
};

export default areas;
