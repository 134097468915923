import React from 'react';
import { useTranslation } from 'react-i18next';
import gender from '../../assets/constants/gender';
import governments from '../../assets/constants/governments';
import calenderIcon from '../../assets/img/calender.svg';
import cardIcon from '../../assets/img/card.svg';
import genderIcon from '../../assets/img/gender.svg';
import locationIcon from '../../assets/img/location.svg';
import mobileIcon from '../../assets/img/mobile.svg';
import nameIcon from '../../assets/img/personal.svg';
import DatePicker from '../UI/Form/DatePicker/DatePicker';
import Input from '../UI/Form/Input/Input';
import NumberInput from '../UI/Form/NumberInput/NumberInput';
import Select from '../UI/Form/Select/Select';
import './PatientForm.scss';

type Props = {
  startDate: any;
  setDateHandler: (value: string) => void;
  setAgeTypeHandler: (value: string) => void;
  mobile: any;
  onChangeValue?: (value: string, name?: string) => void;
  ageType: string;
};

const ageTypes = ['years', 'months', 'days'];

const PatientForm: React.FC<Props> = ({
  startDate,
  setDateHandler,
  mobile,
  onChangeValue,
  ageType,
  setAgeTypeHandler,
}) => {
  const { t, i18n } = useTranslation();

  const setAgeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAgeTypeHandler(e.target.value);
  };

  return (
    <div className="patient-form p-2">
      <div className="ar name d-flex flex-column flex-sm-row align-content-center">
        <div className="d-flex flex-column">
          <div className="name-group align-items-center d-flex mb-2">
            <img src={nameIcon} alt="mobile icon" />
            <h6 className="align-self-end">{t('complete_profile.name')}</h6>
          </div>
          <Input
            type="text"
            name="firstName"
            onChangeValue={onChangeValue}
            placeholder={t('register_page.fName')}
          />
        </div>
        <div className="d-flex flex-column align-self-stretch align-self-sm-end">
          <Input
            type="text"
            name="lastName"
            onChangeValue={onChangeValue}
            placeholder={t('register_page.lName')}
          />
        </div>
      </div>
      <div className="name d-flex flex-column flex-sm-row align-content-center">
        <div className="mobile d-flex flex-column">
          <div className="name-group align-items-center mobile-icon d-flex mb-2">
            <img src={mobileIcon} alt="mobile icon" />
            <h6 className="align-self-end">{t('complete_profile.mobile')}</h6>
          </div>
          <NumberInput
            name="mobile"
            type="text"
            onChangeValue={onChangeValue}
            disabled={!!mobile}
          />
        </div>
        <div className="mobile d-flex flex-column">
          <div className="name-group align-items-center d-flex mb-2">
            <img src={cardIcon} alt="mobile icon" />
            <h6 className="align-self-end">
              {t('complete_profile.nat-num')}
              <span className="m-2">{`(${t(
                'complete_profile.optional'
              )})`}</span>
            </h6>
          </div>
          <NumberInput
            name="nationalNumber"
            type="text"
            onChangeValue={onChangeValue}
          />
        </div>
      </div>
      <div className="name d-flex flex-column flex-sm-row align-content-center">
        <div className="d-flex flex-column align-self-stretch align-self-sm-end">
          <div className="d-flex flex-column">
            <div className="name-group align-items-center d-flex mb-2">
              <img src={locationIcon} alt="mobile icon" />
              <h6 className="align-self-end">
                {t('complete_profile.location')}
              </h6>
            </div>
            <Select
              label={t('register_page.location')}
              name="gov"
              onchangeData={onChangeValue}
              options={Object.keys(governments).map((key) => ({
                label:
                  i18n.language === 'ar'
                    ? governments[key].ar
                    : governments[key].en,
                value: key,
              }))}
            />
          </div>
        </div>
        <div className="d-flex flex-column align-self-stretch align-self-sm-end">
          <div className="d-flex flex-column">
            <div className="name-group align-items-center d-flex mb-2">
              <img src={genderIcon} alt="mobile icon" />
              <h6 className="align-self-end">{t('complete_profile.gender')}</h6>
            </div>
            <Select
              label={t('complete_profile.gender')}
              name="gender"
              onchangeData={onChangeValue}
              options={Object.keys(gender).map((key) => ({
                label: i18n.language === 'ar' ? gender[key].ar : gender[key].en,
                value: key,
              }))}
            />
          </div>
        </div>
      </div>
      <div className="name d-flex flex-column flex-sm-row align-content-center">
        <div className="d-flex flex-column">
          <div className="name-group align-items-center d-flex mb-2">
            <img src={calenderIcon} alt="mobile icon" />
            <h6 className="align-self-end">{t('complete_profile.dob')}</h6>
          </div>
          <DatePicker
            name="dob"
            maxDate={new Date()}
            startDate={startDate ? new Date(startDate) : ''}
            setDateHandler={setDateHandler}
          />
        </div>
        <div className="d-flex flex-column align-self-stretch align-self-sm-end">
          <div className="d-flex flex-column">
            <div className="name-group align-items-center d-flex mb-2">
              <img src={calenderIcon} alt="mobile icon" />
              <h6 className="align-self-end">{t('complete_profile.age')}</h6>
            </div>
            <div className="age-container d-flex">
              <NumberInput
                type="text"
                name="age"
                onChangeValue={onChangeValue}
              />
              <select
                className="align-self-start"
                value={ageType}
                onChange={setAgeType}
              >
                {ageTypes.map((type) => (
                  <option key={type} value={type}>
                    {t('booking_page.' + type)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
