import React, { useEffect, useState } from 'react';
import './AddPatient.scss';

import PatientForm from '../../components/PatientForm/PatientForm';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import Button from '../../components/UI/Form/Button/Button';

import formValidationSchema from './FormValidation';

import { useDispatch, useSelector } from 'react-redux';
import { addNewPatient, editPatient } from '../../store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import routes from '../../assets/constants/routes';
import { rootState } from '../../store/reducers';
import { nationalIdRegex } from '../../assets/constants/regexValues';
import getInfoFromNID from '../../utils/getInfoFromNID';
import { getAgeFromBD, getDateFromAge } from '../../utils/moment';

const AddPatient: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const [isEditPatient, setIsEditPatient] = useState(false);
  const [patientForm, setPatientForm] = useState<any>({
    firstName: '',
    lastName: '',
    mobile: '',
    age: '',
    dob: '',
    gender: '',
    gov: '',
    nationalNumber: '',
  });

  const [mobile, setMobile] = useState<any>('');
  const [patientData, setPatientData] = useState<any>({});
  const [patientDob, setPatientDob] = useState<any>('');
  const [ageType, setAgeType] = useState('years');

  useEffect(() => {
    const locationState = location.state;
    if (locationState && locationState.patient) {
      const patient = locationState.patient;
      const age = patient.dob
        ? getAgeFromBD(new Date(patient.dob)).split(' ')[0]
        : patient.age;
      setIsEditPatient(true);
      setPatientData(patient);
      setPatientForm({
        ...patientForm,
        firstName: patient.name.firstName,
        lastName: patient.name.lastName,
        mobile: patient.lmobile,
        age,
        dob: patient.dob ? new Date(patient.dob).toISOString() : '',
        gender: patient.gender,
        gov: patient.gov ? patient.gov : '',
        nationalNumber: patient.nationalNumber ? patient.nationalNumber : '',
      });
      setAgeType(getAgeFromBD(new Date(patient.dob)).split(' ')[1]);
      setPatientDob(patient.dob ? new Date(patient.dob) : new Date());
    } else if (locationState && locationState.mobile) {
      setMobile(locationState.mobile);
      setPatientForm({
        ...patientForm,
        mobile: locationState.mobile,
      });
    }
    //  eslint-disable-next-line
  }, [location]);

  const setDateHandler = (date: string) => {
    const age = +getAgeFromBD(new Date(date)).split(' ')[0];
    setAgeTypeHandler(getAgeFromBD(new Date(date)).split(' ')[1]);
    setPatientDob(new Date(date));
    setPatientForm({
      ...patientForm,
      age,
      dob: new Date(date).toISOString(),
    });
  };

  const setAgeTypeHandler = (val: string) => {
    setAgeType(val);
    setPatientForm({
      ...patientForm,
      dob: '',
      age: '',
    });
    setPatientDob(null);
  };

  const onChangeInputValue = (value: string, fieldName?: string) => {
    if (!fieldName) return;
    // console.log(fieldName, value);
    if (fieldName === 'age') {
      setPatientDob(null);
      setPatientForm({
        ...patientForm,
        [fieldName]: value,
        dob: '',
      });
    } else if (fieldName !== 'nationalNumber' && fieldName !== 'age') {
      setPatientForm({
        ...patientForm,
        [fieldName]: value,
      });
    } else {
      const nationalNumber = value;
      setPatientForm({
        ...patientForm,
        nationalNumber,
      });
      if (nationalIdRegex.test(nationalNumber)) {
        const { dob, age, gov, gender } = getInfoFromNID(nationalNumber);
        setAgeType(getAgeFromBD(new Date(dob)).split(' ')[1]);
        setPatientForm({
          ...patientForm,
          nationalNumber,
          age,
          gov,
          gender,
          dob: new Date(dob).toISOString(),
        });
        setPatientDob(new Date(dob));
      }
    }
  };

  const pateintHandler = (values: any) => {
    const payload = {
      ...values,
    };
    const { dob, age } = values;
    if (dob) {
      payload.dob = new Date(values.dob).toISOString();
      payload.isExact = true;
    } else if (age && !dob) {
      const calculatedAge = getDateFromAge(+age, ageType);
      payload.dob = new Date(calculatedAge).toISOString();
      payload.age = '';
      payload.isExact = false;
    }
    // console.log('pateintHandler', payload);
    if (isEditPatient) {
      payload.pk = patientData.pk;
      if (currentUnitDetails && currentUnitDetails.pk) {
        dispatch(
          editPatient({ ...payload, clinicId: currentUnitDetails.pk }, history)
        );
      }
    } else {
      dispatch(addNewPatient(payload, history));
    }
  };

  return (
    <div className="add-patient d-flex align-items-center justify-content-center">
      <div className="form-container d-flexflex-column justify-content-center align-items-center">
        <Formik
          initialValues={patientForm}
          enableReinitialize
          validationSchema={formValidationSchema(t)}
          onSubmit={(values) => {
            const vv = formValidationSchema(t).cast(values);
            // console.log('vv', vv);
            pateintHandler(vv);
          }}
        >
          {({ handleSubmit }: FormikProps<any>) => (
            <form
              onSubmit={handleSubmit}
              className="mt-4 mt-sm-0"
              autoComplete="off"
            >
              <div className=" pb-5 d-flex align-items-center justify-content-between">
                <p>
                  {isEditPatient
                    ? t('add_patient_page.edit-patient')
                    : t('add_patient_page.add-patient')}
                </p>
                <div className="btnn d-flex">
                  <Button
                    type="button"
                    name={t('addBooking_page.back')}
                    btnType="back"
                    saveData={() => {
                      history.push(routes.BOOKING);
                    }}
                  />
                  <Button
                    type="submit"
                    name={
                      isEditPatient
                        ? t('add_patient_page.edit-patient')
                        : t('add_patient_page.add-patient')
                    }
                  />
                </div>
              </div>
              <PatientForm
                startDate={patientDob}
                setDateHandler={setDateHandler}
                mobile={mobile}
                onChangeValue={onChangeInputValue}
                ageType={ageType}
                setAgeTypeHandler={setAgeTypeHandler}
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPatient;
