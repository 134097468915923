import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import bookingStatus from '../../../assets/constants/bookingStatus';
import days from '../../../assets/constants/days';
import { setGanderByLang } from '../../../assets/constants/gender';
import { SijilNetworkIcon } from '../../../assets/constants/images';
import paymentStatus from '../../../assets/constants/paymentStatus';
import { UserRoles } from '../../../assets/constants/roles';
import routes from '../../../assets/constants/routes';
import calenderIcon from '../../../assets/img/calender.svg';
import clockIcon from '../../../assets/img/clock.svg';
import editIcon from '../../../assets/img/edit.svg';
import genderIcon from '../../../assets/img/gender.svg';
import bookingIcon from '../../../assets/img/medical-degree.svg';
import mobileIcon from '../../../assets/img/mobile.svg';
import nameIcon from '../../../assets/img/personal.svg';
import { setCurrentPatient } from '../../../store/actions';
import {
  addBoardCurrentFinancailLog,
  StartExaminationAction,
} from '../../../store/actions/board.actions';
import { rootState } from '../../../store/reducers';
import { Booking, ClinicData, FinancalLog, fLog } from '../../../types';
import getFullName from '../../../utils/getFullName';
import vitalSignsActiveIcon from '../../../assets/img/vitalSignsActive.svg';
import {
  formatDateWithDay,
  formatTimeAmPm,
  getAgeFromBD,
} from '../../../utils/moment';
import Button from '../../UI/Form/Button/Button';
import CancelBookingModal from './CancelBookingModal/CancelBookingModal';
import './ClinicBookingDetails.scss';
import FinancialReport from '../../Board/FinancialReport/FinancialReport';
import {
  saveFinancailLog,
  updateCurrentFinancailLog,
} from '../../../store/actions/booking.actions';
import {
  getCurruntDay,
  getDayFormat,
} from '../../../assets/constants/currentDay';
import { updatePatientFinancailLog } from '../../../services/api/patient';
import CostModel from './costModal/CostModal';
import paymentTypes from '../../../assets/constants/paymentTypes';

type Props = {
  currentBooking: Booking | null;
  updateBookingStatusHandler: (status: string) => void;
  payBookingHandler: (status: string) => void;
  clinic: ClinicData;
};
const ReportHeader = ['Date', 'Record', 'Value', 'Balance', 'Notes'];

const ClinicBookingDetails: React.FC<Props> = ({
  currentBooking,
  updateBookingStatusHandler,
  payBookingHandler,
  clinic,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openPaid, setOpenPaid] = useState(false);
  const [currentPaid, setcurrentPaid] = useState('');
  const [isActiveBooking, setIsActiveBooking] = useState(false);
  const currentEmpUnit = useSelector(
    (state: rootState) => state.booking.currentEmpUnit
  );
  const [dataBody, setDataBody] = useState<fLog[]>([]);
  const [costValues, setCostValues] = useState<{
    paid: number;
    due: number;
    planned: number;
  }>({ paid: 0, due: 0, planned: 0 });
  const [openReport, setOnenReport] = useState(false);
  const bookings = useSelector((state: rootState) => state.booking.bookings);
  const bookingDate = useSelector(
    (state: rootState) => state.booking.bookingDate
  );

  const openModel = () => {
    setOnenReport(!openReport);
  };

  const currentFinancailLog = useSelector(
    (state: rootState) => state.booking.currentFinancailLog
  );

  const boardCurrentFinancailLog = useSelector(
    (state: rootState) => state.board.currentFinancailLog
  );

  useEffect(() => {
    if (currentFinancailLog && currentBooking?.dr.specialty === 'dentistry') {
      setDataBody(currentFinancailLog?.fLog);
      const planned =
        currentFinancailLog?.fLog[currentFinancailLog?.fLog.length - 1].balance;
      let paid = 0;
      let due = 0;
      currentFinancailLog?.fLog.forEach((log) => {
        if (log.record === paymentTypes.pay) paid += log.value;
        if (log.record === paymentTypes.retrieve) paid -= log.value;
        if (
          getDayFormat(log.date) === getDayFormat(getCurruntDay()) &&
          log.record !== paymentTypes.pay &&
          log.record !== paymentTypes.retrieve &&
          log.record !== paymentTypes.cancel
        )
          due += log.value;
        if (
          getDayFormat(log.date) === getDayFormat(getCurruntDay()) &&
          log.record === paymentTypes.cancel
        )
          due -= log.value;
      });
      setCostValues({ paid, due, planned });
    }
  }, [currentFinancailLog]);

  useEffect(() => {
    const isActive =
      bookings.length > 0 &&
      bookings.some(
        (ele) =>
          ele.status === bookingStatus.active &&
          new Date(ele.date).getDate() === new Date(bookingDate).getDate()
      );

    // console.log('isActive', isActive);
    setIsActiveBooking(isActive);
  }, [bookingDate, bookings]);

  const calcAgeHandler = (patient: any) => {
    let age: any;
    if (patient && patient.dob) {
      const patientAge = getAgeFromBD(new Date(patient.dob));
      age = `${patientAge.split(' ')[0]} ${t(
        'booking_page.' + patientAge.split(' ')[1]
      )}`;
    } else {
      age = `${patient.age} ${t('booking_page.age')}`;
    }

    return age;
  };

  const getCustomTypeName = (key: string) => {
    return clinic?.examinationTypes[key].name
      ? clinic?.examinationTypes[key].name
      : key;
  };

  const openCurrentPaid = () => {
    setOpenPaid(!openPaid);
  };

  const handlerCurrentPaid = (paid: string) => {
    handlerCost(parseInt(paid));
    openCurrentPaid();
  };

  const handlerCost = (paid: number) => {
    if (currentFinancailLog?.fLog && currentBooking) {
      if (currentPaid === paymentTypes.retrieve && paid > costValues.paid) {
        return;
      }
      const balance: number =
        currentPaid === paymentTypes.retrieve
          ? currentFinancailLog.fLog.length
            ? currentFinancailLog.fLog[currentFinancailLog.fLog.length - 1]
                .balance + paid
            : -paid
          : currentFinancailLog?.fLog.length
          ? currentFinancailLog.fLog[currentFinancailLog.fLog.length - 1]
              .balance - paid
          : paid;
      const financalLog: fLog = {
        date: getCurruntDay(),
        record: currentPaid,
        value: paid,
        balance,
        notes: '',
      };
      dispatch(
        saveFinancailLog({
          clinicId: currentBooking.pk,
          patientId: currentBooking.patientId,
          fLog: {
            ...currentFinancailLog,
            isOpen: balance > 0,
            fLog: [...currentFinancailLog.fLog, financalLog],
          },
          payment: { type: currentPaid, amount: paid },
          hideMsg: true,
        })
      );
    }
    if (boardCurrentFinancailLog?.fLog && currentBooking) {
      if (currentPaid === paymentTypes.retrieve && paid > costValues.paid) {
        return;
      }
      const balance: number =
        currentPaid === paymentTypes.retrieve
          ? boardCurrentFinancailLog.fLog.length
            ? boardCurrentFinancailLog.fLog[
                boardCurrentFinancailLog.fLog.length - 1
              ].balance + paid
            : -paid
          : boardCurrentFinancailLog?.fLog.length
          ? boardCurrentFinancailLog.fLog[
              boardCurrentFinancailLog.fLog.length - 1
            ].balance - paid
          : paid;

      const financalLog: fLog = {
        date: getCurruntDay(),
        record: currentPaid,
        value: paid,
        balance,
        notes: '',
      };
      dispatch(addBoardCurrentFinancailLog(financalLog));
    }
  };
  useEffect(() => {
    paymentTypes.cancel === currentPaid &&
      handlerCost(currentBooking?.price ? currentBooking?.price : 0);
  }, [currentPaid]);

  const openModalHandler = () => {
    setOpenModal(!openModal);
  };

  const goToEditPatientHandler = () => {
    if (!currentBooking || currentBooking.date < Date.now()) return;
    history.push({
      pathname: routes.ADD_PATIENT,
      state: { patient: currentBooking.patient },
    });
  };

  const goToEditBookingHandler = () => {
    if (
      !currentBooking ||
      [bookingStatus.canceled, bookingStatus.completed].includes(
        currentBooking.status
      )
    )
      return;
    dispatch(setCurrentPatient(currentBooking.patient));
    history.push({
      pathname: routes.ADD_BOOKING,
      state: { booking: currentBooking },
    });
  };

  return (
    <div className="booking-details-container">
      {openPaid && (
        <CostModel
          onModalClose={openCurrentPaid}
          handlerCurrentPaid={handlerCurrentPaid}
          isOpen={openPaid}
        />
      )}
      {openReport && (
        <FinancialReport
          header={ReportHeader}
          data={dataBody}
          onModalClose={openModel}
          title={getFullName(currentBooking?.patient.name)}
          mobile={currentBooking?.patient.lmobile || ''}
        />
      )}
      <CancelBookingModal
        isOpen={openModal}
        onModalClose={openModalHandler}
        confirmCancelHandler={() => {
          if (currentBooking?.dr.specialty === 'dentistry') {
            setcurrentPaid(paymentTypes.cancel);
          }
          openModalHandler();
          updateBookingStatusHandler(bookingStatus.canceled);
        }}
      />
      <div className="booking-actions align-items-center d-flex justify-content-between">
        <h6>{t('addBooking_page.booking')}</h6>
        <div className="button-actions d-flex">
          <Button
            type="button"
            name={t('booking_page.cancel')}
            btnType="warning"
            isDisabled={
              currentBooking?.status !== bookingStatus.pending
                ? !currentBooking ||
                  ![bookingStatus.new].includes(currentBooking.status)
                : false
            }
            saveData={openModalHandler}
          />
          {currentEmpUnit?.role !== UserRoles.Physician &&
            (currentBooking?.status !== bookingStatus.pending ? (
              <Button
                type="button"
                name={t('booking_page.dr-enter')}
                isDisabled={
                  !currentBooking ||
                  isActiveBooking ||
                  ![bookingStatus.new, bookingStatus.hold].includes(
                    currentBooking.status
                  )
                }
                saveData={() => {
                  dispatch(StartExaminationAction(true));
                  updateBookingStatusHandler(bookingStatus.active);
                }}
              />
            ) : (
              <Button
                type="button"
                name={t('booking_page.confirm-booking')}
                isDisabled={!currentBooking}
                saveData={() => {
                  updateBookingStatusHandler(bookingStatus.new);
                }}
              />
            ))}
        </div>
      </div>
      <div className="booking-details-container--data align-items-center d-flex flex-column flex-sm-row justify-content-between mt-5">
        <div className="patient align-self-start">
          <div className="patient-header d-flex flex-fill justify-content-between align-items-center">
            <p>{t('booking_page.patient')}</p>
            <div
              className="patient-header-edit"
              onClick={goToEditPatientHandler}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          {currentBooking && currentBooking.patient && (
            <div className="patient-details d-flex flex-column justify-content-between">
              <div className="booking-item align-items-center d-flex">
                <img src={nameIcon} alt="mobile icon" />
                <h6 className="ar-font">
                  {currentBooking.patient.name
                    ? getFullName(currentBooking.patient.name)
                    : ''}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={genderIcon} alt="mobile icon" />
                <h6>
                  {currentBooking.patient.gender
                    ? setGanderByLang(currentBooking.patient.gender)
                    : ''}
                </h6>
              </div>

              <div className="booking-item align-items-center d-flex">
                <img src={mobileIcon} alt="mobile icon" />
                <h6 className="mobile">
                  {currentBooking.patient.lmobile
                    ? currentBooking.patient.lmobile
                    : ''}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={calenderIcon} alt="mobile icon" />
                <h6>{calcAgeHandler(currentBooking.patient)}</h6>
              </div>
              <div
                dir="ltr"
                className="booking-item align-items-center d-flex network"
              >
                {currentBooking.appliedOffer?.network === 'sijil' && (
                  <SijilNetworkIcon />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="booking align-self-start">
          <div className="patient-header d-flex flex-fill justify-content-between align-items-center">
            <p>{t('booking_page.booking')}</p>
            <div
              className="patient-header-edit"
              onClick={goToEditBookingHandler}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          {currentBooking && currentBooking.sk && (
            <div className="booking-details">
              <div className="booking-item align-items-center d-flex">
                <img src={calenderIcon} alt="mobile icon" />
                <h6>
                  {`${
                    days[
                      formatDateWithDay(new Date(currentBooking.date))
                        .split(' ')[1]
                        .toLocaleLowerCase()
                    ][i18n.language]
                  } ${
                    formatDateWithDay(new Date(currentBooking.date)).split(
                      ' '
                    )[0]
                  }`}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={bookingIcon} alt="mobile icon" />
                <h6>
                  {i18n.exists(
                    'examinationAndPrices_page.' + currentBooking.type
                  )
                    ? t('examinationAndPrices_page.' + currentBooking.type)
                    : getCustomTypeName(currentBooking.type)}
                </h6>
              </div>
              <div className="booking-item align-items-center d-flex">
                <img src={clockIcon} alt="mobile icon" />
                <h6>
                  {`${
                    formatTimeAmPm(new Date(currentBooking.date)).split(' ')[0]
                  } ${t(
                    'booking_page.' +
                      formatTimeAmPm(new Date(currentBooking.date)).split(
                        ' '
                      )[1]
                  )}`}
                </h6>
              </div>
              <div className="booking-item payment d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <img src={bookingIcon} alt="mobile icon" />
                  <h6>
                    {currentBooking.cost !== currentBooking.price && (
                      <small className="old-price">{`${currentBooking.price}   `}</small>
                    )}
                    {`${currentBooking.cost} ${t(
                      'examinationAndPrices_page.egp'
                    )}`}
                  </h6>
                </div>
                {[paymentStatus.refunded, paymentStatus.not_paid].includes(
                  currentBooking.paymentStatus
                ) &&
                  currentBooking?.status !== bookingStatus.pending &&
                  currentBooking.dr.specialty !== 'dentistry' && (
                    <Button
                      type="button"
                      name={t('booking_page.pay')}
                      saveData={() => {
                        payBookingHandler(paymentStatus.paid);
                      }}
                    />
                  )}
                {currentBooking.paymentStatus === paymentStatus.paid &&
                  currentBooking.dr.specialty !== 'dentistry' && (
                    <Button
                      type="button"
                      name={t('booking_page.refund')}
                      btnType="warning"
                      saveData={() => {
                        payBookingHandler(paymentStatus.refunded);
                      }}
                    />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      {currentBooking &&
        currentBooking.patient &&
        currentBooking.dr.specialty === 'dentistry' && (
          <div className="booking-details-container--cost">
            <div className="title">
              <h2>{t('booking_page.patient-cost')}</h2>
            </div>
            <div className="costs align-items-center d-flex justify-content-between mt-5">
              <div>
                <span className="name">{t('booking_page.paid')} </span>
                {costValues.paid}
              </div>
              <div>
                <span className="name">{t('booking_page.due')} </span>
                <span className="due"> {costValues.due}</span>
              </div>
              <div>
                <span className="name">{t('booking_page.planned')} </span>
                {costValues.planned}
              </div>
              <div className="bottons d-flex justify-content-between">
                <div
                  className="report justify-content-center"
                  onClick={openModel}
                >
                  <img src={vitalSignsActiveIcon} alt="" />
                </div>
                <Button
                  type="button"
                  name={t('booking_page.pay')}
                  saveData={() => {
                    openCurrentPaid();
                    setcurrentPaid(paymentTypes.pay);
                  }}
                />
                <Button
                  btnType="warning"
                  type="button"
                  name={t('booking_page.retrieval')}
                  saveData={() => {
                    openCurrentPaid();
                    setcurrentPaid(paymentTypes.retrieve);
                  }}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default ClinicBookingDetails;
