import React from 'react';
import './DailyReportCardDetails.scss';
type Props = {
  income: number;
  totalVisits: number;
  newPatients: number;
  docName: string;
};

const DailyReportCardDetails: React.FC<Props> = ({
  income,
  totalVisits,
  newPatients,
  docName,
}) => {
  return (
    <div className="total-income-details-card">
      <div>
        <p>{docName + ' income'}</p>
        <p>{income}</p>
      </div>
      <div>
        <p>Total visits</p>
        <p>{totalVisits}</p>
      </div>
      <div>
        <p>New patients</p>
        <p>{newPatients}</p>
      </div>
    </div>
  );
};
export default DailyReportCardDetails;
