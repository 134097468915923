import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bookingStatus from '../../../../../assets/constants/bookingStatus';
import {
  OutLineCheck,
  PauseIcon,
  SolidCheck,
  RejectIcon,
} from '../../../../../assets/constants/images';
import { getClinicBookingsPending } from '../../../,,/../../../store/actions/booking.actions';
import paymentStatus from '../../../../../assets/constants/paymentStatus';
import avatarIcon from '../../../../../assets/img/avatar.svg';
import collapseIcon from '../../../../../assets/img/collapse.svg';
import GirlAvatarIcon from '../../../../../assets/img/GirlAvatar.svg';
import moreIcon from '../../../../../assets/img/more.svg';
import showIcon from '../../../../../assets/img/show.svg';
import {
  getBookingInHour,
  getResrvedTime,
} from '../../../../../services/timeSlots';
import { rootState } from '../../../../../store/reducers';
import { TimeSlot } from '../../../../../types';
import {
  getBookingColor,
  getBookingStatusColor,
} from '../../../../../utils/booking';
import getFullName from '../../../../../utils/getFullName';
import { formatTimeAmPm, getAgeFromBD } from '../../../../../utils/moment';
import '../UnitPanelBookings.scss';
import CancelBookingModal from '../../../ClinicBookingDetails/CancelBookingModal/CancelBookingModal';
import ConfirmBookingModal from '../../../ClinicBookingDetails/ConfirmBookingModal/ConfirmBookingModal';

const TimeSlots = (props: any) => {
  const {
    timeSlots,
    showHideCollapsedHandler,
    setCurrentBookingHandler,
    addBookingHandler,
    reservation,
    updateBookingStatusHandler,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const collapsedSlots = useSelector(
    (state: rootState) => state.booking.collapsedSlots
  );
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );
  const bookingDate = useSelector(
    (state: rootState) => state.booking.bookingDate
  );
  const allBookings = useSelector((state: rootState) => state.booking.bookings);
  const allBookingsPending = useSelector(
    (state: rootState) => state.booking.bookingsPending
  );

  useEffect(() => {
    if (!currentUnitDetails) return;

    const startOfDay = new Date(
      new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
        .toJSON()
        .slice(0, 10)
    ).getTime();
    // moment.tz.setDefault().startOf('day').toDate().getTime();
    const endOfDay = moment().endOf('day').toDate().getTime();
    dispatch(
      getClinicBookingsPending(currentUnitDetails?.pk, startOfDay, endOfDay)
    );
  }, [allBookings, currentUnitDetails]);

  const openModalHandler = () => {
    setOpenModal(!openModal);
  };
  const openModalConfirmHandler = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  const calcAgeHandler = (patient: any) => {
    let age: any;
    if (patient && patient.dob) {
      const patientAge = getAgeFromBD(new Date(patient.dob));
      age = `${patientAge.split(' ')[0]} ${t(
        'booking_page.' + patientAge.split(' ')[1]
      )}`;
    } else {
      age = `${patient.age} ${t('booking_page.age')}`;
    }

    return age;
  };

  const getNumberOfBookingsInTimeSlot = (timeSlotsData: TimeSlot[]) => {
    return timeSlotsData.filter((slot: TimeSlot) => slot.booking).length;
  };

  const setTimeSlotAmPmHandler = (timeslot: string) => {
    const hour = timeslot.split(':')[0];
    const minute = timeslot.split(':')[1];
    const timeSlotDate = new Date().setHours(+hour, +minute, 0, 0);
    return `${formatTimeAmPm(new Date(timeSlotDate)).split(' ')[0]} ${t(
      'booking_page.' + formatTimeAmPm(new Date(timeSlotDate)).split(' ')[1]
    )}`;
  };

  const getEmptyPlacesInTimeSlot = (
    timeSlotsData: TimeSlot[],
    slot: string
  ) => {
    const bookingInHour = getBookingInHour(currentUnitDetails); // 4
    const reservedTime = getResrvedTime(currentUnitDetails); // 15
    const kk = timeSlotsData.map((slot: any) => {
      return new Date(slot.booking.date).getMinutes();
    });
    const emptyPlaces = [];
    let i = 0;

    while (i < bookingInHour) {
      if (!kk.includes(reservedTime * i)) emptyPlaces.push(reservedTime * i);
      i += 1;
    }
    const now = new Date();
    let availableooking = true;
    if (+slot.split(':')[0] === now.getHours()) {
      emptyPlaces.forEach((min: any) => {
        if (min < now.getMinutes()) {
          availableooking = false;
        } else {
          availableooking = true;
        }
      });
    }
    return availableooking;
  };

  return (
    <>
      <CancelBookingModal
        isOpen={openModal}
        onModalClose={openModalHandler}
        confirmCancelHandler={() => {
          openModalHandler();
          updateBookingStatusHandler(bookingStatus.canceled);
        }}
      />
      <ConfirmBookingModal
        isOpen={openModalConfirm}
        onModalClose={openModalConfirmHandler}
        confirmCancelHandler={() => {
          openModalConfirmHandler();
          updateBookingStatusHandler(bookingStatus.new);
        }}
      />
      {timeSlots && Object.keys(timeSlots).length > 0
        ? Object.keys(timeSlots)
            .sort()
            .map((timSlot) =>
              timeSlots[timSlot].length > 0 || reservation ? (
                <div
                  key={`timSlot-${timSlot}`}
                  className="booking-item-container"
                  style={{
                    border: `2px solid var(--color-primary-dark1)`,
                  }}
                >
                  <div
                    className="date-time d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: 'var(--color-primary-dark1)',
                    }}
                    onClick={() => {
                      showHideCollapsedHandler(timSlot);
                    }}
                  >
                    <div>
                      <img
                        src={
                          collapsedSlots && collapsedSlots[timSlot]
                            ? collapseIcon
                            : moreIcon
                        }
                        alt="bookings"
                        onClick={() => {
                          showHideCollapsedHandler(timSlot);
                        }}
                      />
                    </div>
                    {/* slot time and number of bookings in slot */}
                    <div className="numbers flex-fill d-flex justify-content-between">
                      <p>{setTimeSlotAmPmHandler(timSlot)}</p>
                      <p>
                        {`${getNumberOfBookingsInTimeSlot(timeSlots[timSlot])}${
                          currentUnitDetails &&
                          currentUnitDetails.type === 'clinic'
                            ? '/' + getBookingInHour(currentUnitDetails)
                            : ''
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="booking-item-container-details">
                    <Collapse
                      isOpened={collapsedSlots && collapsedSlots[timSlot]}
                    >
                      {/* bookings in slot */}
                      {timeSlots[timSlot].map((ele: TimeSlot, ind: number) => {
                        return (
                          ele &&
                          ele.booking &&
                          ele.booking.sk && (
                            <div
                              key={`ele-${ind}-${timSlot}-${ele.booking.sk}`}
                              onClick={() => {
                                setCurrentBookingHandler(ele.booking);
                              }}
                              className={`booking-item d-flex align-items-center justify-content-between ${
                                currentBooking &&
                                currentBooking.sk === ele.booking.sk
                                  ? 'current'
                                  : ''
                              }`}
                              style={{
                                backgroundColor: `${getBookingColor(
                                  ele.booking,
                                  currentUnitDetails
                                )}`,
                              }}
                            >
                              <div className="d-flex align-items-center flex-fill">
                                <img
                                  className="avatar"
                                  src={
                                    ele
                                      ? ele.booking.patient.gender === 'male'
                                        ? avatarIcon
                                        : GirlAvatarIcon
                                      : ''
                                  }
                                  alt="avatarIcon"
                                />
                                {/* patient name and age */}
                                <div className="d-flex flex-column justify-content-start align-items-start flex-fill">
                                  <p className="ar-font">
                                    {getFullName(
                                      ele && ele.booking
                                        ? ele.booking.patient.name
                                        : null
                                    )}
                                  </p>
                                  <span>
                                    {ele && ele.booking
                                      ? calcAgeHandler(ele.booking.patient)
                                      : ''}
                                  </span>
                                </div>
                              </div>
                              {/* booking status and paid icons */}
                              {ele.booking.status !== bookingStatus.pending ? (
                                <div
                                  className={`d-flex icons ${ele.booking.status}`}
                                >
                                  {/* paid icon */}
                                  {ele.booking.paymentStatus !==
                                    paymentStatus.paid &&
                                  (currentUnitDetails?.type !== 'clinic' ||
                                    currentBooking?.dr.specialty !==
                                      'dentistry') ? (
                                    <span className="payment d-flex align-items-center justify-content-center">
                                      $
                                    </span>
                                  ) : null}

                                  {/* status icons */}
                                  <span
                                    className={`status  ${ele.booking.status}`}
                                  >
                                    {ele.booking.status ===
                                    bookingStatus.hold ? (
                                      <PauseIcon
                                        fill={getBookingStatusColor(
                                          ele.booking.status
                                        )}
                                      />
                                    ) : ele.booking.status ===
                                      bookingStatus.completed ? (
                                      <SolidCheck
                                        fill={getBookingStatusColor(
                                          ele.booking.status
                                        )}
                                      />
                                    ) : ele.booking.status ===
                                      bookingStatus.active ? (
                                      <OutLineCheck
                                        fill={getBookingStatusColor(
                                          ele.booking.status
                                        )}
                                      />
                                    ) : null}
                                  </span>
                                </div>
                              ) : (
                                <div className={`d-flex icons`}>
                                  <span
                                    className={`span-pending pending-confirm`}
                                    onClick={() => {
                                      openModalConfirmHandler();
                                    }}
                                  >
                                    <SolidCheck fill="#fff" />
                                  </span>
                                  <span
                                    className={`pending-cancel`}
                                    onClick={openModalHandler}
                                  >
                                    <RejectIcon />
                                  </span>
                                </div>
                              )}
                            </div>
                          )
                        );
                      })}
                      {/* if length of slot is not complete show empty div with add booking button */}
                      {getNumberOfBookingsInTimeSlot(timeSlots[timSlot]) <
                        getBookingInHour(currentUnitDetails) &&
                      getEmptyPlacesInTimeSlot(timeSlots[timSlot], timSlot) &&
                      reservation ? (
                        <div className="booking-item d-flex align-items-centerd-flex justify-content-center">
                          {currentUnitDetails &&
                          // currentUnitDetails.type === 'clinic' &&  add booking in slot for all units type
                          (+timSlot.split(':')[0] >= new Date().getHours() ||
                            new Date(bookingDate) > new Date()) ? (
                            <button
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => {
                                addBookingHandler(timSlot);
                              }}
                            >
                              <img src={showIcon} alt="showIcon" />
                              <span>{t('booking_page.add-booking')}</span>
                            </button>
                          ) : null}
                        </div>
                      ) : null}
                    </Collapse>
                  </div>
                </div>
              ) : null
            )
        : null}
    </>
  );
};

export default TimeSlots;
