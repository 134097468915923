import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import closeIcon from '../../../assets/img/close-icon.svg';
import imagingCenterIcon from '../../../assets/img/imagingCenterIcon.svg';
import nameIcon from '../../../assets/img/personal.svg';
import reportsIcon from '../../../assets/img/reports.svg';
import { addPatientEncountersAction } from '../../../store/actions/labBoard.actions';
import {
  removeTestImages,
  uploadMultiFiles,
} from '../../../store/actions/uploader.actions';
import { rootState } from '../../../store/reducers';
import { getBookingFilesByType } from '../../../utils/files';
import getFullName from '../../../utils/getFullName';
import Button from '../../UI/Form/Button/Button';
import Dropzone from '../DropZone';
import './ImageDetails.scss';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { isAllFilesSent } from '../../../utils/booking';
import CustomAlertUI from '../CustomAlertUI/CustomAlertUI';

const hasNoFiles = (booking: any) => {
  if (!booking) return true;
  return (
    booking.tests.every((test: any) => {
      return (
        (test.reports.urls.length === 0 && test.results.urls.length === 0) ||
        (test.reports.status && test.reports.status !== 'success') ||
        (test.results.status && test.results.status !== 'success')
      );
    }) ||
    booking.tests.some((test: any) => {
      return (
        (test.reports.status && test.reports.status === 'error') ||
        (test.results.status && test.results.status === 'error')
      );
    })
  );
};

const ImageDetails: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentBooking = useSelector(
    (state: rootState) => state.labBoard.currentBooking
  );

  const [booking, setBooking] = useState<any>();
  const files = useSelector((state: rootState) => state.uploader.files);
  // const currentUnitDetails = useSelector(
  //   (state: rootState) => state.booking.currentUnitDetails
  // );

  const getImageHandler = (images: any[], test: any, type?: string) => {
    if (type === 'results') {
      dispatch(uploadMultiFiles(images, 'results', currentBooking, test));
    } else if (type === 'reports') {
      dispatch(uploadMultiFiles(images, 'reports', currentBooking, test));
    }
  };

  const removeBookingHandler = (type: string, test: any) => {
    dispatch(removeTestImages(type, currentBooking, test));
  };
  useEffect(() => {
    if (currentBooking) {
      const tests = currentBooking.tests.map((test: any) => {
        return {
          ...test,
          reports: getBookingFilesByType(
            files,
            currentBooking,
            test,
            'reports'
          ),
          results: getBookingFilesByType(
            files,
            currentBooking,
            test,
            'results'
          ),
        };
      });

      setBooking({ ...currentBooking, tests });
    } else {
      setBooking(null);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(files), JSON.stringify(currentBooking)]);

  const saveDataHandler = async () => {
    const hasEmptyTests = booking.tests.some((test: any) => {
      return (
        test.results.urls.length === 0 ||
        test.results.status === 'uploading' ||
        test.reports.urls.length === 0 ||
        test.reports.status === 'uploading'
      );
    });

    if (hasEmptyTests) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <CustomAlertUI
            title={t('labBoard_page.confirmation.title')}
            body={t('labBoard_page.confirmation.message')}
            onClose={onClose}
            send={send}
          />
        ),
      });
    } else {
      send();
    }
  };
  const send = () => {
    dispatch(addPatientEncountersAction(booking.pk, booking.sk));
  };

  return (
    <div className="details-container">
      {booking && (
        <>
          <div className="details-container__header flex-column">
            <div className="d-flex justify-content-between align-items-center ">
              <Button
                isDisabled={
                  hasNoFiles(booking) ||
                  isAllFilesSent(booking, files) ||
                  booking.status === 'completed'
                }
                name={t('labBoard_page.save')}
                saveData={saveDataHandler}
              />
              <div className="name d-flex align-items-center">
                <h6>
                  {booking.patient &&
                    booking.patient.name &&
                    getFullName(booking.patient.name)}
                </h6>
                <img src={nameIcon} alt="nameIcon" />
              </div>
            </div>
            <div className="result-container d-flex align-items-center justify-content-end mt-3">
              <div className="reports d-flex align-items-center">
                <img src={reportsIcon} alt="reportsIcon" />
                <h6>{t('labBoard_page.reports')}</h6>
              </div>
              <div className="results d-flex align-items-center">
                <img src={imagingCenterIcon} alt="imagingCenterIcon" />
                <h6>{t('labBoard_page.results')}</h6>
              </div>
            </div>
          </div>

          <div className="details-container__data">
            {booking.tests &&
              booking.tests.map((test: any, index: number) => {
                return Test(
                  index,
                  test,
                  getImageHandler,
                  removeBookingHandler,
                  booking.status
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default ImageDetails;
function Test(
  index: number,
  test: any,
  getImageHandler: (
    images: any[],
    test: any,
    type?: string | undefined
  ) => void,
  removeBookingHandler: (type: string, test: any) => void,
  bookingStatus: string
) {
  return (
    <div key={index + test.name}>
      <div className="d-flex justify-content-between align-items-center p-3">
        <h6>{test.name}</h6>
        {bookingStatus !== 'completed' && (
          <div className="image-container d-flex">
            <div className="reports d-flex align-items-center">
              <Dropzone
                getImage={(e: any, _index: number, type?: string) =>
                  getImageHandler(e, test, type)
                }
                files={test.reports || {}}
                index={index}
                type="reports"
              />
              {test.reports.urls.length > 0 && (
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  onClick={() => {
                    removeBookingHandler('reports', test);
                  }}
                />
              )}
            </div>
            <div className="results d-flex align-items-center">
              <Dropzone
                getImage={(e: any, _index: number, type?: string) =>
                  getImageHandler(e, test, type)
                }
                files={test.results || {}}
                index={index}
                type="results"
              />

              {test.results.urls.length > 0 && (
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  onClick={() => {
                    removeBookingHandler('results', test);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
