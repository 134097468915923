import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import errorIcon from '../../../assets/img/alert.svg';
import searchIcon from '../../../assets/img/search.svg';
import successIcon from '../../../assets/img/tick.svg';
import uploadingIcon from '../../../assets/img/upload.svg';
import {
  getLabBookingsAction,
  setCurrentLabBookingAction,
} from '../../../store/actions/labBoard.actions';
import { rootState } from '../../../store/reducers';
import { Booking } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { formatDate, isSameDate } from '../../../utils/moment';
import './LabBookings.scss';

const LabBookings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const labBookings = useSelector(
    (state: rootState) => state.labBoard.bookings
  );

  const isRequestFinished = useSelector(
    (state: rootState) => state.labBoard.isRequestFinished
  );
  const files = useSelector((state: rootState) => state.uploader.files);

  const [bookings, setBookings] = useState<Booking[]>([]);

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const currentBooking = useSelector(
    (state: rootState) => state.labBoard.currentBooking
  );

  useEffect(() => {
    if (currentUnitDetails)
      dispatch(getLabBookingsAction(currentUnitDetails.pk));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBookings(labBookings);
    // console.log('labBookings', labBookings);
  }, [labBookings]);

  const searchBookingHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'mobile' && value.length === 11) {
      if (currentUnitDetails)
        dispatch(getLabBookingsAction(currentUnitDetails.pk, value));
    } else if (name === 'mobile' && value.length === 0) {
      if (currentUnitDetails)
        dispatch(getLabBookingsAction(currentUnitDetails.pk));
    } else {
      if (value) {
        // const phonePattern = new RegExp(`${value}`, 'm');
        const phonePattern = new RegExp(
          value.startsWith('+') ? `\\+${value.split('+')[1]}` : value,
          'm'
        );
        const searchedBookings = labBookings.filter((book: Booking) => {
          if (name === 'date') {
            return isSameDate(new Date(value), new Date(book[name]));
          } else if (name === 'patient' && book.patient && book.patient.name) {
            return phonePattern.test(getFullName(book.patient.name));
          } else if (
            name === 'mobile' &&
            book.patient &&
            book.patient.lmobile
          ) {
            return phonePattern.test(book.patient.lmobile);
          } else if (name === 'dr' && book.targetDr && book.targetDr.name) {
            return phonePattern.test(getFullName(book.targetDr.name));
          }
          return '';
        });
        setBookings(searchedBookings);
      } else {
        setBookings(labBookings);
      }
    }
  };

  return (
    <div className="lab-bookings mt-3 mt-sm-0">
      <div>
        <table
          className="table table-responsive table-striped"
          style={{
            height: `${
              isRequestFinished && labBookings.length === 0 ? '100%' : 'unset'
            }`,
          }}
        >
          <thead>
            <tr>
              <th scope="col">
                <div>
                  <h6>{t('labBoard_page.mobile')}</h6>
                  <div className="input-container d-flex flex-row align-items-center ">
                    <img src={searchIcon} alt="" />
                    <input
                      type="number"
                      name="mobile"
                      onChange={searchBookingHandler}
                    />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div>
                  <h6>{t('labBoard_page.patient')}</h6>
                  <div className="input-container d-flex flex-row align-items-center ">
                    <img src={searchIcon} alt="" />
                    <input
                      type="text"
                      name="patient"
                      onChange={searchBookingHandler}
                    />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div>
                  <h6>{t('labBoard_page.physician')}</h6>
                  <div className="input-container d-flex flex-row align-items-center ">
                    <img src={searchIcon} alt="" />
                    <input
                      type="text"
                      name="dr"
                      onChange={searchBookingHandler}
                    />
                  </div>
                </div>
              </th>
              <th colSpan={2} scope="col">
                <div>
                  <h6>{t('labBoard_page.date')}</h6>
                  <div className="input-container d-flex flex-row align-items-center ">
                    <img src={searchIcon} alt="" />
                    <input
                      type="date"
                      name="date"
                      // min={formatCAlDate(new Date())}
                      onChange={searchBookingHandler}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {labBookings && labBookings.length > 0 && isRequestFinished ? (
              <>
                {bookings &&
                  bookings.map((book, index: number) => {
                    const bookingFiles: any[] = files.filter(
                      (file: any) => file.booking === book.sk
                    );

                    const hasUploadedTest = book?.tests?.some((test: any) => {
                      return test.reports?.length > 0;
                    });

                    return (
                      <tr
                        className={`${
                          currentBooking && currentBooking.sk === book.sk
                            ? 'active'
                            : ''
                        }`}
                        key={index}
                        onClick={() => {
                          dispatch(setCurrentLabBookingAction(book));
                        }}
                      >
                        <th className="mobile-num" scope="row">
                          {book.patient.lmobile}
                        </th>
                        <td>
                          {book.patient &&
                            book.patient.name &&
                            getFullName(book.patient.name)}
                        </td>
                        <td>
                          {book.targetDr && book.targetDr.name
                            ? getFullName(book.targetDr.name)
                            : ''}
                        </td>
                        <td className="mobile-num">
                          {formatDate(new Date(book.date))}
                        </td>
                        <td className="upload-status">
                          {bookingFiles.length === 0 ? (
                            hasUploadedTest === true ? (
                              <img src={successIcon} alt="" />
                            ) : null
                          ) : bookingFiles.every(
                              (file: any) => file.status === 'success'
                            ) ? (
                            <img src={successIcon} alt="" />
                          ) : bookingFiles.some(
                              (file: any) => file.status === 'error'
                            ) ? (
                            <img src={errorIcon} alt="" />
                          ) : bookingFiles.some(
                              (file: any) => file.status === 'pending'
                            ) ? (
                            <img src={uploadingIcon} alt="" />
                          ) : hasUploadedTest === true ? (
                            <img src={successIcon} alt="" />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : isRequestFinished && labBookings.length === 0 ? (
              <tr>
                <td className="no-patient-td" colSpan={5}>
                  <div className="no-patient d-flex flex-column justify-content-center align-items-center">
                    <p>{t('clinicBoard_page.no-patient')}</p>
                    <p className="msg">
                      {t('clinicBoard_page.no-patient-msg')}
                    </p>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LabBookings;
