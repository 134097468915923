import React from 'react';
import './Marker.scss';

type Props = {
  color: string;
  name: string;
  lat?: number;
  lng?: number;
};

const Marker: React.FC<Props> = ({ name, color }) => {
  return (
    <div>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={name}
      />
      <div className="pulse" />
    </div>
  );
};

export default Marker;
