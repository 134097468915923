import React, { useState, useEffect, useReducer } from 'react';
import './CompleteProfile.scss';

import { useHistory, useLocation } from 'react-router-dom';
import routes from '../../assets/constants/routes';

import CompleteProfileToken from '../../components/Token/TokenForm';
import CompleteProfileForm from '../../components/CompleteProfile/CompleteProfileForm/CompleteProfileForm';
import CheckProfile from '../../components/CompleteProfile/CheckProfile/CheckProfile';
import apiService from '../../services/api';

import RegisterClinicDetails from '../../components/Layout/RegisterClinicDetails/RegisterClinicDetails';
import tokenTypes from '../../assets/constants/tokenTypes';
import { isAuthenticated, storeData } from '../../utils/storage';
import { useTranslation } from 'react-i18next';

import clinicImg from '../../assets/img/clinic2.png';
import mobile from '../../assets/img/mobile.svg';
import mobileActive from '../../assets/img/mobile-active.svg';
import check from '../../assets/img/check.svg';
import checkActive from '../../assets/img/check-active.svg';
import personal from '../../assets/img/personal.svg';
import personalActive from '../../assets/img/personal-active.svg';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated, getClinicianUnits } from '../../store/actions';
import { nationalIdRegex } from '../../assets/constants/regexValues';
import { errorToast } from '../../utils/toast';
import EventTracker from '../../utils/analytics';

type Action = {
  type: string;
  payload?: any;
};

const segmentsData = {
  token: 'token',
  personal: 'personal_data',
  check: 'check',
};

const initialState = {
  token: '',
  firstName: '',
  lastName: '',
  password: '',
  nationalNumber: '',
  nationalScan: '',
  profileImg: '',
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case segmentsData.token:
      return { ...state, token: action.payload };
    case segmentsData.personal:
      return { ...state, ...action.payload };
    case segmentsData.check:
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return { ...state, ...initialState };
    default:
      return state;
  }
};

const CompleteProfile: React.FC = () => {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const { t, i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [personalData, setPersonalData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  });
  const [selectedSegment, setSelectedSegment] = useState(segmentsData.token);
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };
  const [shouldResendToken, setShouldResendToken] = useState(false);

  const [user, setUser] = useState<any>({});
  const [profileImg, setProfileImg] = useState<any>('');
  const [nationalScan, setNationalScan] = useState<any>('');
  const [nationalNumber, setNationalNumber] = useState<any>('');
  const [profilePower, setProfilePower] = useState<number>(0);
  const [natIdPower, setNatIdPower] = useState<any>(0);
  const [natImgPower, setNatImgPower] = useState<any>(0);
  const [profileImgPower, setProfileImgPower] = useState<any>(0);
  // const [uploading, setUploading] = useState<any>(false);
  const [isUploadProfile, setIsUploadProfile] = useState(false);
  const [isUploadNat, setIsUploadNat] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileLocalImg, setProfileLocalImg] = useState<any>('');
  const [nationalScanLocalImg, setNationalScanLocalImg] = useState<any>('');

  useEffect(() => {
    setProfilePowerHandler(natIdPower + natImgPower + profileImgPower);
  }, [natIdPower, natImgPower, profileImgPower]);

  useEffect(() => {
    // console.log('location', location);
    if (location && location.state && location.state.user) {
      setUser({ ...location.state.user });
    } else {
      history.push(routes.CHECK_USER);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated()) history.push(routes.HOME);
  }, [history]);

  useEffect(() => {
    const changeLangHandler = (lang: string = 'en') => {
      i18n.changeLanguage(lang);
    };
    changeLangHandler('ar');
  }, [i18n]);

  const setProfilePowerHandler = (value: number) => {
    setProfilePower(value);
  };

  const getImage = (file: File, type: string) => {
    // console.log('file', file);
    if (!file || !file.type || !file.type.startsWith('image/')) {
      errorToast(t('register_page.invalid'));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (_e: any) => {
      if (type === 'profile') {
        setProfileLocalImg(reader.result);
        setIsUploadProfile(true);
      } else if (type === 'national') {
        setNationalScanLocalImg(reader.result);
        setIsUploadNat(true);
      }
      uploadFile(file, type);
    };
  };

  const uploadFile = async (file: any, type?: string) => {
    if (!file) return;
    // setUploading(true);
    const imgUrl = await apiService.upload(
      file,
      t('register_page.upload-img'),
      t('register_page.upload-img-done'),
      t('register_page.upload-img-err')
    );
    // setUploading(false);
    // console.log('imgUrl', imgUrl);
    if (!imgUrl) {
      if (type === 'profile') {
        setIsUploadProfile(false);
      } else if (type === 'national') {
        setIsUploadNat(false);
      }
      return;
    }

    if (type === 'profile') {
      setProfileImg(imgUrl || '');
      setProfileImgPower(20);
      setIsUploadProfile(false);
    } else if (type === 'national') {
      setNationalScan(imgUrl || '');
      setNatImgPower(40);
      setIsUploadNat(false);
    }
  };

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.token) {
      dispatch({ type: segmentsData.token, payload: payload.data });
      changeSegmentHandler(segmentsData.personal);
    } else if (payload.type === segmentsData.personal) {
      if (
        !payload.data.firstName ||
        !payload.data.lastName ||
        !payload.data.password ||
        !payload.data.confirmPassword
      )
        return;
      setPersonalData({ ...payload.data });
      dispatch({ type: segmentsData.personal, payload: payload.data });
      changeSegmentHandler(segmentsData.check);
    } else {
      setNationalNumber(payload.data.nationalNumber);
      setNatIdPower(payload.data.power);
    }
  };

  const completeProfileHandler = async () => {
    try {
      const payload = {
        ...state,
        nationalNumber,
        nationalScan,
        profileImg,
        id: user.pk,
        mobile: user.mobile,
      };
      if (
        (payload.nationalNumber && payload.nationalNumber.length !== 14) ||
        (payload.nationalNumber &&
          !nationalIdRegex.test(payload.nationalNumber))
      ) {
        errorToast(t('complete_profile.nat-invalid'));
        setIsSubmitting(false);
        return;
      }
      // console.log('payload', payload);
      setIsSubmitting(true);
      delete payload.confirmPassword;
      const data = await apiService.completeProfile(payload);
      // console.log('data', data.data);
      setIsSubmitting(false);
      let storedData = {
        ...data.data,
      };
      const sMobile = payload.mobile.startsWith('+2')
        ? payload.mobile
        : `+2${payload.mobile}`;
      if (data.data.trustedDeviceToken) {
        storedData = {
          ...storedData,
          [`trustedDeviceToken-${sMobile}`]: data.data.trustedDeviceToken,
        };
      }
      EventTracker.sendEvent('COMPLETE_REGISTRATION', { ...payload });

      delete storedData.trustedDeviceToken;
      storeData({ ...storedData });
      reduxDispatch(setUserAuthenticated());
      reduxDispatch(getClinicianUnits(history));
      history.push(routes.HOME);
    } catch (error) {
      console.error('error[registerPhysician]', error);
      setIsSubmitting(false);
    }
  };

  const checkTokenHandler = async (values: any) => {
    if (!values.token || values.token.length !== 4) return;
    try {
      EventTracker.sendEvent('VERIFY_TOKEN_BUTTON_CLICKED', {
        mobile: user.mobile,
        tokenType: tokenTypes.updateProfile,
      });
      setIsSubmitting(true);
      const tokenPayload = {
        ...values,
        mobile: user.mobile,
        tokenType: tokenTypes.updateProfile,
      };
      const data = await apiService.checkToken(tokenPayload);
      // console.log('data', data.data);
      setIsSubmitting(false);
      nextStepHandler({
        data: data.data.token,
        type: segmentsData.token,
      });
    } catch (error) {
      console.error('error[checkToken]', error);
      setIsSubmitting(false);
    }
  };

  const resendTokenHandler = async () => {
    try {
      EventTracker.sendEvent('RESEND_TOKEN_BUTTON_CLICKED', {
        mobile: user.mobile,
        tokenType: tokenTypes.updateProfile,
      });
      await apiService.requestToken({
        mobile: user.mobile,
        tokenType: tokenTypes.updateProfile,
      });
      setShouldResendToken(true);
    } catch (error) {
      console.error('error[resendToken]', error);
      setShouldResendToken(true);
    }
  };

  const goBackHandler = () => {
    history.push(routes.CHECK_USER);
  };

  const goBackToPreviousSegment = () => {
    if (selectedSegment === segmentsData.personal) {
      // setSelectedSegment(segmentsData.token);
      goBackHandler();
    } else if (selectedSegment === segmentsData.check) {
      setSelectedSegment(segmentsData.personal);
    } else {
      dispatch({ type: 'RESET_STATE' });
    }
  };

  return (
    <div className="completeProfile-page d-flex flex-column">
      <div className="segment mt-3">
        <ul>
          <li
            className={`base token  mobile-icon ${
              selectedSegment === segmentsData.token ? 'selected' : ''
            }`}
            // onClick={() => {
            //   changeSegmentHandler(segmentsData.token);
            // }}
          >
            <img
              className="d-none d-sm-block"
              src={
                selectedSegment === segmentsData.token ? mobileActive : mobile
              }
              alt="mobile"
            />
            {t('complete_profile.mobile')}
          </li>
          <li
            className={`base ${
              selectedSegment === segmentsData.personal ? 'selected' : ''
            }`}
            // onClick={() => {
            //   changeSegmentHandler(segmentsData.personal);
            // }}
          >
            <img
              className="d-none d-sm-block"
              src={
                selectedSegment === segmentsData.personal
                  ? personalActive
                  : personal
              }
              alt="personal"
            />
            {t('complete_profile.personal-data')}
          </li>
          <li
            className={`base ${
              selectedSegment === segmentsData.check ? 'selected' : ''
            }`}
            // onClick={() => {
            //   changeSegmentHandler(segmentsData.check);
            // }}
          >
            <img
              className="d-none d-sm-block"
              src={selectedSegment === segmentsData.check ? checkActive : check}
              alt="check"
            />
            {t('complete_profile.check')}
          </li>
        </ul>
      </div>
      <div className="completeProfile-page__main d-flex flex-column-reverse flex-sm-row  align-items-center ">
        {selectedSegment === segmentsData.token ? (
          <>
            <CompleteProfileToken
              checkTokenhandler={checkTokenHandler}
              resendTokenHandler={resendTokenHandler}
              shouldResend={shouldResendToken}
              mobile={user.mobile}
              isSubmitting={isSubmitting}
              goBack={goBackHandler}
              name={t('pages.completeProfile')}
            />
            <RegisterClinicDetails />
          </>
        ) : selectedSegment === segmentsData.personal ? (
          <>
            <CompleteProfileForm
              personalData={personalData}
              nextStepHandler={nextStepHandler}
              currentSegment={segmentsData.personal}
              goBack={goBackToPreviousSegment}
            />
            <div className="clinicImage-container d-flex">
              <img src={clinicImg} alt="clinic" />
            </div>
          </>
        ) : (
          <>
            <CheckProfile
              getImage={getImage}
              nextStepHandler={nextStepHandler}
              completeProfile={completeProfileHandler}
              setProfilePowerHandler={setProfilePowerHandler}
              currentSegment={segmentsData.check}
              isSubmitting={isSubmitting}
              uploading={isUploadProfile || isUploadNat}
              goBack={goBackToPreviousSegment}
              profileLocalImg={profileLocalImg}
              nationalScanLocalImg={nationalScanLocalImg}
            />
            <div className="clinicImage-container">
              <div className="progress-container d-flex align-items-center justify-content-between">
                <p>{t('complete_profile.profile-power')}</p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${profilePower}%` }}
                    aria-valuenow={profilePower}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="percent">{`${profilePower}%`}</p>
              </div>
              <img src={clinicImg} alt="clinic" className="mt-3" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompleteProfile;
