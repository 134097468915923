import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './dentalServiceModal.scss';
import Switch from './switchButton';
import ApiService from '../../../../services/api';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
  },
};
type Props = {
  isOpen: boolean;
  closeModal: any;
  category: any;
  addService: any;
  isAddMode: boolean;
  selectedService: any;
  editService: any;
};
const DentalServiceModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  category,
  addService,
  isAddMode,
  selectedService,
  editService,
}) => {
  const [numOfSteps, setNumOfSteps] = useState(4);
  const [labOptionIncluded, setLabOptionIncluded] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [price, setPrice] = useState('');
  const [steps, setSteps] = useState(['', '', '', '']);
  const [teethSelection, setTeethSelection] = useState<String>('');
  const [preDefinedServices, setpreDefinedServices] = useState<string[]>([]);
  const [selectedLabService, setSelectedLabService] = useState('');
  useEffect(() => {
    if (isOpen) {
      if (!isAddMode) {
        setPrice(selectedService.price);
        setServiceName(selectedService.name);
        setServiceName(selectedService.name);
        setNumOfSteps(selectedService.steps.length);
        setSteps(selectedService.steps);
        setLabOptionIncluded(selectedService.labIncluded);
        setSelectedLabService(selectedService.labService);
      } else {
        resetBtnHandler();
        setLabOptionIncluded(false);
      }
    }
  }, [isOpen]);
  useEffect(() => {
    async function getPredefinedLabServices() {
      try {
        const { data } = await ApiService.getPredefinedLabServices();
        setpreDefinedServices(data.services || []);
      } catch (error) {
        console.error('error', error);
      }
    }
    getPredefinedLabServices();
  }, []);
  const resetBtnHandler = () => {
    setServiceName('');
    setPrice('');
    setLabOptionIncluded(false);
    setNumOfSteps(4);
    setSteps(['', '', '', '']);
    setTeethSelection('');
    setSelectedLabService('');
  };
  const stepsHandler = (e: any, i: number) => {
    const newSteps = [...steps];
    newSteps[i] = e.target.value;
    setSteps(newSteps);
  };

  const addStep = () => {
    setNumOfSteps((prev) => prev + 1);
    setSteps([...steps, '']);
  };
  const saveService = () => {
    const filteredSteps = steps.filter((s: any) => s);
    if (isAddMode) {
      addService({
        name: serviceName,
        type: serviceName.toUpperCase().replaceAll(' ', '_'),
        isCustom: true,
        price: Number(price),
        selectionOption: teethSelection,
        steps: filteredSteps,
        labIncluded: labOptionIncluded,
        labService: selectedLabService,
      });
    } else {
      editService({
        name: serviceName,
        type: selectedService.type,
        isCustom: selectedService.isCustom ? true : false,
        price: Number(price),
        selectionOption: teethSelection,
        steps,
        labIncluded: labOptionIncluded,
        labService: selectedLabService,
      });
    }
  };
  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => closeModal()}
    >
      <div className="dental-service-modal">
        <div className="modal-header">
          <p>Service</p>
          <i className="fas fa-times" onClick={() => closeModal()}></i>
        </div>
        <div className="dental-modal-content">
          <div className="first-inputs-set">
            <input
              disabled={!isAddMode && !selectedService.isCustom}
              type="text"
              name="serviceName"
              placeholder="Service Name"
              onChange={(e) => setServiceName(e.target.value)}
              value={serviceName}
            />
            <div className="steps-section">
              <div className="add-more-steps">
                <p>Steps</p>
                <div
                  onClick={() =>
                    !isAddMode && !selectedService.isCustom ? null : addStep()
                  }
                >
                  {' '}
                  Add +
                </div>
              </div>
              <div className="step-inputs">
                {[...Array(numOfSteps)].map((_: any, i: any) => {
                  return (
                    <div key={i}>
                      <input
                        type="text"
                        name={`step${i}`}
                        placeholder={i + 1 + ':'}
                        onChange={(e) => stepsHandler(e, i)}
                        value={steps[i]}
                        disabled={!isAddMode && !selectedService.isCustom}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="second-inputs-set">
            <input
              type="text"
              name="price"
              placeholder="Price"
              onChange={(e: any) => {
                if (!isNaN(e.target.value) && Number(e.target.value) >= 0) {
                  setPrice(e.target.value);
                }
              }}
              value={price}
            />
            <p>Chart selection options:</p>
            <div
              className="service-options"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTeethSelection(e.target.value);
              }}
            >
              <input
                className="custom-radio-btn"
                type="radio"
                value="TOOTH"
                name="toothSelection"
                defaultChecked={
                  (!selectedService.selectionOption ||
                    selectedService.selectionOption == 'TOOTH') &&
                  !isAddMode
                }
                disabled={!isAddMode && !selectedService.isCustom}
              />{' '}
              Single Tooth
              <br />
              <input
                type="radio"
                value="MULTIPLE_TEETH"
                name="toothSelection"
                defaultChecked={
                  selectedService.selectionOption == 'MULTIPLE_TEETH' &&
                  !isAddMode
                }
                disabled={!isAddMode && !selectedService.isCustom}
              />{' '}
              Multiple teeth, connected
              <br />
              <input
                type="radio"
                value="MULTIPLE_DISCONNECTED"
                name="toothSelection"
                defaultChecked={
                  selectedService.selectionOption == 'MULTIPLE_DISCONNECTED' &&
                  !isAddMode
                }
                disabled={!isAddMode && !selectedService.isCustom}
              />{' '}
              Multiple teeth, disconnected
              <br />
              <input
                type="radio"
                value="ONE_ARCH"
                name="toothSelection"
                defaultChecked={
                  selectedService.selectionOption == 'ONE_ARCH' && !isAddMode
                }
                disabled={!isAddMode && !selectedService.isCustom}
              />{' '}
              Single arch
              <br />
              <input
                type="radio"
                value="BOTH_ARCH"
                name="toothSelection"
                defaultChecked={
                  selectedService.selectionOption == 'BOTH_ARCH' && !isAddMode
                }
                disabled={!isAddMode && !selectedService.isCustom}
              />{' '}
              Both arches
              <br />
            </div>
            <div className="lab-option-included-toggle">
              <p className="switch-title">Lab option</p>
              <Switch
                isOn={labOptionIncluded}
                handleToggle={() => setLabOptionIncluded(!labOptionIncluded)}
              />
              <p className="switch-title">Included</p>
            </div>
            <div className="select-lab-service">
              <p>Lab service:</p>
              <select
                name="LabService"
                className="lab-service-drop-down"
                defaultValue={selectedLabService}
                id=""
                onChange={(e) => setSelectedLabService(e.target.value)}
              >
                <option value="" disabled defaultValue="">
                  Select your option
                </option>
                {preDefinedServices.map((s: string, i: number) => {
                  return <option key={i}>{s}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="modal-btns">
          <div onClick={() => resetBtnHandler()}>Reset</div>
          <div onClick={() => saveService()}>Save</div>
          <div className="modal-cancel-btn" onClick={() => closeModal()}>
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DentalServiceModal;
