export const getMinutes = (addedMinutes: number = 5) => {
  const minutes = [];
  let startMinute = 0;

  while (startMinute < 60) {
    minutes.push(startMinute < 10 ? `0${startMinute}` : `${startMinute}`);
    startMinute += addedMinutes;
  }
  return minutes;
};

export const getHours = () => {
  const hours = [];
  let sDate = new Date(new Date().setHours(0, 0, 0, 0)).getHours();

  while (sDate <= 23) {
    hours.push(sDate < 10 ? `0${sDate}` : `${sDate}`);
    sDate += 1;
  }
  return hours;
};
