import React from 'react';

import { useTranslation } from 'react-i18next';

import bookingIcon from '../../../assets/img/medical-degree.svg';
import priceIcon from '../../../assets/img/price.svg';
import calenderIcon from '../../../assets/img/calendar.svg';
import clockIcon from '../../../assets/img/clock.svg';

const TableHead = () => {
  const { t } = useTranslation();
  return (
    <tr>
      <th scope="col" className="scan">
        <div className="d-flex">
          <img src={bookingIcon} alt="mobile icon" />
          <h6 className="align-self-end"> {t('booking_page.scan')}</h6>
        </div>
      </th>
      <th scope="col" className="price">
        <div className="d-flex">
          <img src={priceIcon} alt="mobile icon" />
          <h6 className="align-self-end">{t('booking_page.price')}</h6>
        </div>
      </th>
      <th scope="col" className="date">
        <div className="d-flex">
          <img src={calenderIcon} alt="mobile icon" />
          <h6 className="align-self-end">
            {' '}
            {t('addBooking_page.booking-date')}
          </h6>
        </div>
      </th>
      <th scope="col" className="time">
        <div className="d-flex">
          <img src={clockIcon} alt="mobile icon" />
          <h6 className="align-self-end">
            {t('addBooking_page.booking-time')}
          </h6>
        </div>
      </th>
    </tr>
  );
};

export default TableHead;
