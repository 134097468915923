import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionStatus } from '../../Subscription';
import './LabsDetails.scss';
import Button from '../../UI/Form/Button/Button';
import { ClinicData } from '../../../types';
import LabServices from './LabServices/LabServices';
import closeIcon from './../../../assets/img/close-icon.svg';
import backArrow from '../../../assets/img/backArrow.svg';
import ApiService from '../../../services/api';
import { errorToast } from '../../../utils/toast';
type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit?: ClinicData | null;
};

const LabsDetails: React.FC<Props> = ({
  nextStepHandler,
  currentSegment,
  goBackHandler,
  initialState,
  isSettingPage,
  isSubmitting,
  unit,
}) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const [showLabServices, setShowLabServices] = useState(false);
  const [selectedInd, setSelectedInd] = useState(0);
  const [labName, setLabName] = useState('');
  const [labsData, setLabsData] = useState<any>([]);
  const [saveData, setSaveData] = useState(false);
  const [preDefinedServices, setpreDefinedServices] = useState<string[]>([]);
  useEffect(() => {
    async function getPredefinedLabServices() {
      try {
        const { data } = await ApiService.getPredefinedLabServices();
        setpreDefinedServices(data.services || []);
        const kk = [];
        for (const key in initialState) {
          kk.push(initialState[key]);
        }
        setLabsData([...kk]);
      } catch (error) {
        console.error('error', error);
      }
    }
    getPredefinedLabServices();
    setpreDefinedServices([]);
  }, []);

  useEffect(() => {
    if (saveData) {
      nextStepHandler({ type: currentSegment, data: [...labsData] });
      setSaveData(false);
    }
  }, [saveData]);

  const addLabHandler = () => {
    setPopup(true);
  };

  const showService = (index: number) => {
    setSelectedInd(index);
    setShowLabServices(true);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setLabName(name);
  };

  const labNameHandler = () => {
    const labIndex = labsData.findIndex((lab: any) => lab.name === labName);
    if (labIndex >= 0) {
      return errorToast(t('labsDetails_page.lab_found'));
    }
    const lServices: { name: string; price: number }[] = [];
    preDefinedServices.forEach((ser) => {
      lServices.push({ name: ser, price: 0.0 });
    });
    setPopup(false);
    const payload = {
      name: labName,
      services: lServices,
      unitId: unit?.pk,
      status: 'new',
    };

    setLabsData([...labsData, payload]);
    showService(labsData.length);
  };
  const closeHandler = () => {
    setPopup(false);
  };

  const setServiceHandler = async () => {
    const tempLabs = [...labsData];
    let payload;
    labsData.length > 0 &&
      labsData.map(async (lab: any, i: number) => {
        if (lab.status && lab.status === 'new') {
          payload = {
            name: lab.name,
            services: lab.services,
            unitId: lab.unitId,
          };
          const { data } = await ApiService.addDentalLab(payload);
          tempLabs[i] = data.DentalLab;
          setLabsData([...tempLabs]);
          setSaveData(true);
        } else if (lab.status === 'updated') {
          payload = tempLabs[i];
          const { data } = await ApiService.updateDentalLabs(payload);
          tempLabs[i] = data.dentLab;
          setLabsData([...tempLabs]);
          setSaveData(true);
        }
      });
  };

  const deleteLabHandler = (ind: number) => {
    const filterdata = labsData.filter((obj: any) => obj !== labsData[ind]);
    setLabsData(filterdata);
    if (isSettingPage) {
      // nextStepHandler({ type: currentSegment, data: [...filterdata] });
      setLabsData([...filterdata]);
      setSaveData(true);
    }
    if (filterdata.length > 0) {
      showService(0);
    } else {
      setShowLabServices(false);
    }
  };

  const addServiceToLabHandler = (
    services: { name: string; price: number }[]
  ) => {
    const labIndex = labsData.findIndex((lab: any) => lab._id === selectedInd);
    if (labIndex >= 0) {
      const updatedLabs = [...labsData];
      updatedLabs[labIndex].services = services;
      setLabsData(updatedLabs);
    }
  };

  const goBack = () => {
    goBackHandler({ type: currentSegment, data: [...labsData] });
  };

  return (
    <div className="labs-container d-flex flex-column">
      <div className="d-flex justify-content-between">
        <SubscriptionStatus unit={unit} />
      </div>

      <div className="labs-container__main">
        <div className="details ">
          <div className="details-header d-flex mt-4">
            <p className="p-4">{t('labsDetails_page.labs')}</p>
            <div className="button-actions">
              <Button
                type="button"
                name={t('labsDetails_page.add')}
                saveData={addLabHandler}
              />
            </div>
          </div>
          {labsData.length > 0 &&
            labsData.map((ele: any, index: any) => (
              <div
                key={index}
                className="item"
                onClick={() => {
                  showService(index);
                }}
                style={{
                  color: `${index === selectedInd ? 'var(--color-main)' : ''}`,
                }}
              >
                {ele.name}
                {!showLabServices && index === 0 ? showService(0) : ''}
              </div>
            ))}
          {popup ? (
            <div className="labName d-flex align-items-center">
              <input
                autoFocus
                type="text"
                name="labName"
                onChange={(e) => {
                  changeHandler(e);
                }}
              ></input>
              <Button
                type="button"
                name={t('labsDetails_page.save')}
                saveData={labNameHandler}
              />
              <img src={closeIcon} alt="closeIcon" onClick={closeHandler} />
            </div>
          ) : (
            ''
          )}
        </div>
        {showLabServices && (
          <LabServices
            labs={labsData}
            setServiceHandler={setServiceHandler}
            confirmDelete={deleteLabHandler}
            addServiceToLabHandler={addServiceToLabHandler}
            selected={selectedInd}
            isSettingPage={isSettingPage}
          />
        )}
      </div>

      {!isSettingPage && (
        <div className="addClinc-btn-container">
          <Button
            saveData={() => {
              setServiceHandler();
            }}
            name={t('employess_page.finish')}
          />
          <div
            className="back go-back align-self-end"
            onClick={() => {
              goBack();
            }}
          >
            <span>{t('otp_page.back')}</span>
            <img src={backArrow} alt="back" />
          </div>
        </div>
      )}
    </div>
  );
};
export default LabsDetails;
