import * as Yup from 'yup';

const bookingFormValidationSchema = (t: any) => {
  return Yup.object().shape({
    exminationType: Yup.string().required(t('booking_page.required')),
    exminationPrice: Yup.string()
      .required(t('booking_page.required'))
      .matches(/[0-9]/, t('booking_page.valid-number')),
    bookingTime: Yup.string().required(t('booking_page.required')),
    bookingDate: Yup.string().required(t('booking_page.required')),
  });
};

export default bookingFormValidationSchema;
