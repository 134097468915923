import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import bookingStatus from '../../assets/constants/bookingStatus';
import paymentStatus from '../../assets/constants/paymentStatus';
import routes from '../../assets/constants/routes';
import ClinicBookingForm from '../../components/AddBooking/ClinicBookingForm/ClinicBookingForm';
import LabBookingForm from '../../components/AddBooking/LabBookingForm/LabBookingForm';
import PatientDetails from '../../components/AddBooking/PatientDetails/PatientDetails';
import PreviousBooking from '../../components/AddBooking/PreviousBooking/PreviousBooking';
import UpCommingBooking from '../../components/AddBooking/UpCommingBooking/UpCommingBooking';
import apiService from '../../services/api';
import {
  addBooking,
  addPatientOrders,
  getPatientPreviousBooking,
  updateBooking,
} from '../../store/actions';
import {
  PATIENT_PREVIOUS_BOOKINGS,
  SET_CURRENT_PATIENT,
  SET_CURRENT_SLOT,
  SET_PATIENT_ORDERS,
} from '../../store/actions/actionTypes';
import { getPatientOrders } from '../../store/actions/booking.actions';
import { rootState } from '../../store/reducers';
import { Booking } from '../../types';
import unitTypes from '../../utils/unitTypes';
import './AddBooking.scss';

const AddBooking: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const [bookingData, setBooking] = useState<any>(null);
  const [isNewBooking, setIsNewBooking] = useState(false);
  const [isEditBooking, setIsEditBooking] = useState(false);
  const [isUpComingBooking, setIsUpComingBooking] = useState(true);
  const [orders, setOrders] = useState<any>({});
  const [patientData, setPatientData] = useState<any>({});
  const [pUnitBookings, setPUnitBookings] = useState<Booking[]>([]);

  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const previousBookings = useSelector(
    (state: rootState) => state.booking.previousBookings
  );

  const patientOrders = useSelector(
    (state: rootState) => state.booking.patientOrders
  );

  const bookDate = useSelector((state: rootState) => state.booking.bookingDate);

  const currentSlot = useSelector(
    (state: rootState) => state.booking.currentSlot
  );

  // add or edit booking
  useEffect(() => {
    if (!currentPatient || !currentUnitDetails) {
      history.push(routes.BOOKING);
      return;
    }
    setBooking(null);
    setIsEditBooking(false);
    setIsNewBooking(false);
    setIsUpComingBooking(false);
    setPUnitBookings([]);
    // console.log('location.state.booking', location.state);
    if (location && location.state && location.state.booking) {
      const b: Booking = location.state.booking;
      if (currentUnitDetails.type !== unitTypes.clinic) {
        dispatch(getPatientOrders(currentPatient.pk, currentUnitDetails.type));
      }
      setIsEditBooking(true);
      setIsNewBooking(false);
      setIsUpComingBooking(false);
      setBooking(b);
    } else {
      // new booking
      // if (!currentPatient || !currentUnitDetails) return;
      newBookingHandler();
    }
    // eslint-disable-next-line
  }, [location, currentPatient, currentUnitDetails, history, dispatch]);

  // orders
  useEffect(() => {
    // console.log('patientOrderssssssssss', patientOrders);
    if (patientOrders.length > 0) {
      const drOrders: any = {};
      for (const ele of patientOrders) {
        if (ele.tests.length > 0) {
          const drPk = ele.dr.pk;
          if (!drOrders[drPk]) {
            drOrders[drPk] = { ...ele };
          } else {
            drOrders[drPk] = {
              ...drOrders[drPk],
              tests: [...drOrders[drPk].tests, ...ele.tests],
            };
          }
        }
      }

      // console.log('drOrders', drOrders);
      setOrders(drOrders);
    } else {
      setOrders({});
      setPUnitBookings([]);
    }
    // eslint-disable-next-line
  }, [patientOrders]);

  useEffect(() => {
    if (
      currentPatient &&
      bookingData &&
      currentPatient.pk !== bookingData.patient.pk
    ) {
      setIsNewBooking(true);
      setIsEditBooking(false);
      setIsUpComingBooking(false);
    }
  }, [currentPatient, bookingData]);

  // clean
  useEffect(() => {
    return () => {
      dispatch({ type: SET_PATIENT_ORDERS, payload: [] });
      dispatch({ type: PATIENT_PREVIOUS_BOOKINGS, payload: [] });
      dispatch({ type: SET_CURRENT_PATIENT, payload: null });
      dispatch({ type: SET_CURRENT_SLOT, payload: '' });
    };
    // eslint-disable-next-line
  }, []);

  const setPatientDataHandler = (data: any) => {
    if (data) {
      const { dr, clinic, patient, pk, sk } = data;
      setPatientData({ dr, clinic, patient, pk, sk });
    } else {
      setPatientData({});
    }
  };

  const newBookingHandler = async () => {
    if (!currentPatient || !currentUnitDetails) return;

    // get upcomming and previous booking
    const b = await patientUnitBookings(
      currentPatient.pk,
      currentUnitDetails.pk
    );

    // get patient orders
    if (currentUnitDetails.type !== unitTypes.clinic) {
      dispatch(getPatientOrders(currentPatient.pk, currentUnitDetails.type));
    }

    // console.log('b', b);
    if (b) {
      setIsUpComingBooking(true);
      setIsEditBooking(false);
      setIsNewBooking(false);
      setBooking(b);
    } else {
      setIsNewBooking(true);
      setIsEditBooking(false);
      setIsUpComingBooking(false);
      setBooking(null);
    }
  };

  const patientUnitBookings = async (patietId: string, clinicId: string) => {
    if (!currentUnitDetails) return null;
    try {
      const {
        data: { bookings },
      }: {
        data: { bookings: Booking[] };
      } = await apiService.getPatientUnitBookings({
        pId: patietId,
        unitId: clinicId,
      });
      setPUnitBookings(bookings);
      if (currentUnitDetails.type === unitTypes.clinic) {
        const upComing = bookings.findIndex(
          (ele) =>
            +ele.date >= new Date().setHours(0, 0, 0, 0) &&
            [bookingStatus.new].includes(ele.status)
        );

        if (upComing >= 0) {
          return setPreviousAndUpComing(upComing, bookings);
        }
      }
      setPatientPreviousBooking(bookings);
      return null;
    } catch (error) {
      console.error('error[getPatientUnitBookings]', error);
      return null;
    }
  };

  const setPreviousAndUpComing = (index: number, bookings: Booking[]) => {
    const allBookings = [...bookings];
    allBookings.splice(index, 1);
    setPatientPreviousBooking(allBookings);
    return bookings[index];
  };

  const setPatientPreviousBooking = (bookings: Booking[]) => {
    dispatch(getPatientPreviousBooking(bookings));
  };

  const goToEditBookingHandler = () => {
    if (!bookingData) return;
    setIsEditBooking(true);
    setIsUpComingBooking(false);
    setIsNewBooking(false);
  };

  const addClinicBookingHandler = (payload: any) => {
    if (!currentPatient || !currentUnitDetails) return;
    // console.log(payload);
    const {
      bookingTime,
      bookingDate,
      exminationPrice,
      exminationType,
      cost,
      appliedOffer,
    } = payload;
    const time = bookingTime.split(':');
    const date = new Date(bookingDate).setHours(time[0], time[1], 0, 0);
    // patientData
    const patient = {
      name: currentPatient?.name,
      lmobile: currentPatient?.lmobile,
      dob: currentPatient?.dob,
      age: currentPatient?.age,
      gender: currentPatient?.gender,
      nationalNumber: currentPatient?.nationalNumber,
      pk: currentPatient?.pk,
      gov: currentPatient?.gov,
    };
    // doctor data from clinic details
    const dr = {
      name: currentUnitDetails?.owner.name,
      pk: currentUnitDetails?.owner.pk,
      specialty: currentUnitDetails?.owner.specialty,
    };
    // booking payload
    const bookingPayload = {
      type: exminationType,
      cost: +cost,
      price: +exminationPrice,
      date,
      patientId: currentPatient?.pk,
      patient,
      clinic: {
        name: currentUnitDetails?.name,
        pk: currentUnitDetails?.pk,
        org: currentUnitDetails.org,
      },
      dr,
      appliedOffer,
    };

    dispatch(addBooking(bookingPayload, history));
  };

  const editClinicBookingHandler = (payload: any) => {
    // console.log('editBookingHanler', payload);
    const {
      bookingTime,
      bookingDate,
      exminationPrice,
      cost,
      exminationType,
      pk,
      sk,
      date,
    } = payload;
    const time = bookingTime.split(':');
    const NewBookingDate = new Date(bookingDate).setHours(
      time[0],
      time[1],
      0,
      0
    );
    if (
      NewBookingDate === date &&
      cost === bookingData.cost &&
      exminationType === bookingData.type
    )
      return;

    if (NewBookingDate === date) {
      dispatch(
        updateBooking(
          pk,
          sk,
          'examinationType',
          {
            exminationPrice,
            cost: +cost,
            exminationType,
          },
          history
        )
      );
    } else {
      dispatch(
        updateBooking(
          pk,
          sk,
          'reschedule',
          {
            date: NewBookingDate,
            exminationPrice,
            cost: +cost,
            exminationType,
          },
          history
        )
      );
    }
  };

  const addLabBookingHandler = ({ scans, totalCost, paidPrice }: any) => {
    // console.log('scans', scans);
    // console.log('currentUnitDetails', currentUnitDetails);
    if (!currentPatient || !currentUnitDetails || scans.length <= 0) return;

    // current unit dr details
    const dr = {
      name: currentUnitDetails?.owner.name,
      pk: currentUnitDetails?.owner.pk,
      specialty: currentUnitDetails?.owner.specialty,
    };

    // current unit details
    const unitDetails = {
      name: currentUnitDetails?.name,
      pk: currentUnitDetails?.pk,
      org: currentUnitDetails?.org,
    };

    // patient details
    const patient = {
      name: currentPatient?.name,
      lmobile: currentPatient?.lmobile,
      dob: currentPatient?.dob,
      age: currentPatient?.age,
      gender: currentPatient?.gender,
      nationalNumber: currentPatient?.nationalNumber,
      pk: currentPatient?.pk,
      gov: currentPatient?.gov,
    };

    // details of clinic and dr that ask for orders
    const targetClinic = patientData.clinic || null;
    const targetDr = patientData.dr || null;
    const orderId = patientData.sk || null;

    const booking: any = {};
    let lab: any = {};
    const tests: any[] = [];
    const currentTime = new Date().setSeconds(0, 0);
    const labEnc =
      currentUnitDetails?.type === unitTypes.lab
        ? `lab-${currentTime}`
        : `rad-${currentTime}`;

    scans.forEach(
      ({ name, code, price, date, time, class: scanClass, method }: any) => {
        const bookingTime = time.split(':');
        const bookingDate = new Date(date).setHours(
          bookingTime[0],
          bookingTime[1],
          0,
          0
        );
        let test: any = { name, code, class: scanClass, method };
        tests.push(test);
        test = { ...test, price };
        const bookDetails = {
          cost: price,
          clinic: unitDetails,
          dr,
          patient,
          pk: unitDetails.pk,
          sk: bookingDate + '',
          patientId: patient.pk,
          paymentStatus: paymentStatus.not_paid,
          date: bookingDate,
          enc: labEnc,
          targetClinic,
          targetDr,
          orderId,
          paid: 0,
        };
        if (!booking[bookingDate]) {
          booking[bookingDate] = { ...bookDetails, tests: [test] };
        } else {
          booking[bookingDate] = {
            ...bookDetails,
            tests: [...booking[bookingDate].tests, test],
            cost: booking[bookingDate].cost + price,
          };
        }
      }
    );
    lab = {
      ...lab,
      pk: patient.pk,
      sk: labEnc,
      tests,
      bookings: Object.values(booking),
      order: patientData.sk || null,
      clinic: targetClinic,
      dr: targetDr,
      encounter:
        patientData && patientData.dr
          ? `${patientData.dr.pk}-${currentTime}`
          : `${patient.pk}-${currentTime}`,
      patient,
      totalCost,
      paid: paidPrice,
    };
    // console.log('booking', booking);
    // console.log('add lab order', lab);
    dispatch(addPatientOrders(currentPatient!.pk, lab, history));
  };

  const editLabBookingHandler = (payload: any) => {
    // console.log('payload', payload);
    // console.log('patientData', patientData);
    const { sk } = patientData;
    const oldBooking = payload.booking;
    if (!oldBooking) return;
    const booking: any = {};
    payload.scans.forEach(
      ({ name, code, price, date, time, class: scanClass, method }: any) => {
        const bookingTime = time.split(':');
        const bookingDate = new Date(date).setHours(
          bookingTime[0],
          bookingTime[1],
          0,
          0
        );
        let test: any = { name, code, class: scanClass, method };
        test = { ...test, price };
        const order = {
          ...oldBooking,
          cost: price,
          sk: bookingDate + '',
          date: bookingDate,
          paid: payload.paidPrice,
        };
        if (!booking[bookingDate]) {
          booking[bookingDate] = { ...order, tests: [test] };
        } else {
          booking[bookingDate] = {
            ...order,
            tests: [...booking[bookingDate].tests, test],
            cost: booking[bookingDate].cost + price,
          };
        }
      }
    );
    const updatedBookings: Booking[] = Object.values(booking) || [];
    // console.log('updatedBookings', updatedBookings);
    if (updatedBookings.length > 0) {
      dispatch(
        updateBooking(
          oldBooking.pk,
          oldBooking.sk,
          'labBooking',
          {
            bookings: updatedBookings,
            oldBooking,
            order: sk,
          },
          history
        )
      );
    }
  };

  const updateBookingStatusHandler = () => {
    history.goBack();
  };

  const setEditBookingHandler = (b: Booking) => {
    // console.log('b', b);
    setIsEditBooking(true);
    setIsNewBooking(false);
    setIsUpComingBooking(false);
    setBooking(b);
    const allBookings = [...pUnitBookings];
    const bIdx = allBookings.findIndex((ele) => ele.sk === b.sk);
    if (bIdx >= 0) {
      allBookings.splice(bIdx, 1);
    }
    setPatientPreviousBooking(allBookings);
  };

  const getCustomTypeName = (key: string) => {
    let name = key;
    if (i18n.exists('examinationAndPrices_page.' + key)) {
      name = t('examinationAndPrices_page.' + key);
    } else if (currentUnitDetails && currentUnitDetails.examinationTypes) {
      name = currentUnitDetails.examinationTypes[key].name || '';
    }
    // console.log('name', name);
    return name;
  };

  return (
    <div className="add-booking d-flex justify-content-center align-items-center flex-column mt-3 p-5">
      {currentPatient && (
        <div className="d-flex flex-column justify-content-between">
          <div className="add-booking-current d-flex justify-content-center flex-column flex-sm-row ">
            <PatientDetails
              patient={currentPatient}
              lastVisit={
                previousBookings.length > 0 ? previousBookings[0].date : null
              }
            />
            {currentUnitDetails &&
            currentUnitDetails.type === unitTypes.clinic ? (
              <>
                {isNewBooking || isEditBooking ? (
                  <ClinicBookingForm
                    addBookingHandler={addClinicBookingHandler}
                    editBookingHanler={editClinicBookingHandler}
                    clinic={currentUnitDetails}
                    booking={bookingData}
                    bookDate={bookDate}
                    currentSlot={currentSlot}
                    patient={currentPatient}
                  />
                ) : isUpComingBooking ? (
                  <UpCommingBooking
                    goToEditBookingHandler={goToEditBookingHandler}
                    booking={bookingData}
                    updateBookingStatusHandler={updateBookingStatusHandler}
                    getCustomTypeName={getCustomTypeName}
                  />
                ) : null}
              </>
            ) : (
              <LabBookingForm
                addLabBookingHandler={addLabBookingHandler}
                editLabBookingHandler={editLabBookingHandler}
                clinic={currentUnitDetails}
                orders={orders}
                bookingData={bookingData}
                setPatientDataHandler={setPatientDataHandler}
                setPrevoiusAndUpComming={setPreviousAndUpComing}
                setPatientPreviousBooking={setPatientPreviousBooking}
                previousBookings={pUnitBookings}
              />
            )}
          </div>
          {previousBookings.length > 0 && (
            <div className="add-booking-previous mt-5">
              <PreviousBooking
                previousBookings={previousBookings}
                setEditBookingHanlder={setEditBookingHandler}
                clinic={currentUnitDetails}
                getCustomTypeName={getCustomTypeName}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddBooking;
