import React, { useEffect } from 'react';
import './App.scss';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ToastContainer } from 'react-toastify';
import { toastOptions } from './utils/toast';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from './store/reducers';
import { setUserAuthenticated, getClinicianUnits } from './store/actions';
import { socketRemoveAllListener } from './services/socket';
import Header from './components/Layout/Header/Header';
import BoardHeader from './components/Layout/BoardHeader/BoardHeader';
import routes from './assets/constants/routes';
import { appRoutes, publicRoutes } from './MainRoutes';
import NoMatch from './components/NoMatch/NoMatch';
import PrivateRoute from './containers/Layout/PrivateRoute';
import HomePage from './containers/homePage/HomePage';

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authToken } = useSelector((state: rootState) => state.auth);

  useEffect(() => {
    // console.log(isAuthenticated)
    if (authToken) {
      dispatch(setUserAuthenticated());
      dispatch(getClinicianUnits(history));
    }
    return () => socketRemoveAllListener();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-container" dir={i18n.dir()}>
        {authToken ? <BoardHeader /> : <Header />}

        <Switch>
          <Route path={routes.HOME} exact render={() => <HomePage />} />
          {appRoutes.map(({ path, roles, hasUnit, component: Component }) => (
            <PrivateRoute
              key={path}
              path={path}
              token={authToken}
              roles={roles}
              hasUnit={hasUnit}
              component={Component}
            />
          ))}
          {/* public routes */}
          {publicRoutes.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              exact
              render={() => {
                return <Component />;
              }}
            />
          ))}
          <Route path="*" exact={true} component={NoMatch} />
        </Switch>
      </div>
      <ToastContainer {...toastOptions} />
    </>
  );
}

export default App;
