import annotationPlugin from 'chartjs-plugin-annotation';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DailyReportCardDetails from '../../components/finances/DailyReports/DailyReportCardDetails/DailyReportCardDetails';
import FinancesContainer from '../../components/finances/FinancesContainer/FinancesContainer';
import Header from '../../components/finances/Header/Header';
import ReportBotton from '../../components/finances/ReportBotton/ReportBotton';
import { rootState } from '../../store/reducers';
import {
  addMonthToYear,
  getMonthNameFromNumber,
  formatDate,
} from '../../utils/moment';
import getFullName from '../../utils/getFullName';
// import { getDentalMonthlyReport } from '../../services/api/statistics';
import apiService from '../../services/api';
import {
  DentalMonthlyReportType,
  DentalLabReportType,
  LabServiceOrderType,
  ExpensesType,
  IncomeType,
} from '../../types';
import './Finances.scss';
import moment from 'moment';
type MonthlyCardsType = {
  dr: {
    name: { firstName: string; lastName: string };
    pk: string;
  };
  data: { start: string; mid: string; end: string };
  header: { start: string; mid: string; end: string };
  total: string;
};

const Finances: React.FC = () => {
  const { i18n } = useTranslation();

  const [isMonthlyClick, setIsMonthlyClick] = useState(false);
  const [months, setMonths] = useState<string[]>([]);
  const [dentalMonthlyReport, setDentalMonthlyReport] = useState<
    DentalMonthlyReportType
  >();
  const [dentalLabReport, setDentalLabReport] = useState<DentalLabReportType>(
    []
  );
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalLabs, setTotalLabs] = useState(0);
  const [currentMonth, setCurrentMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [incomeByDoctor, setIncomeByDoctor] = useState<MonthlyCardsType[]>([]);
  const [labsCards, setLabsCards] = useState<MonthlyCardsType[]>([]);
  const [incomeReportData, setIncomeReportData] = useState<any[]>([]);
  const [expensesReportData, setExpensesReportData] = useState<any[]>([]);
  const [labReportData, setLabReportData] = useState<any[]>([]);
  const dataPoints = [3500, 3000, 1500, 2000, 2500, 4500, 5000];

  const [totalDailyIncomeForDocs, setTotalDailyIncomeForDocs] = useState<any[]>(
    []
  );
  const [totalDailyIncome, setTotalDailyIncome] = useState(0);
  const [totalDailyExpenses, setTotalDailyExpenses] = useState(0);
  const [revenueChartData, setRevenuChartData] = useState<any>([]);
  const [revenueChartLabels, setRevenuChartLabels] = useState<any>([]);

  const computeAvgofDataPoints = (dataPoints: number[]) => {
    if (dataPoints.length == 0) return [];
    return dataPoints.reduce((prev, curr) => prev + curr) / dataPoints.length;
  };
  const chartData = {
    // labels: ['1', '2', '3', '4', '5', '6', '7'],
    labels: revenueChartLabels,
    datasets: [
      {
        fill: false,
        lineTension: 0.1,
        borderColor: '#0090FF',
        pointBackgroundColor: '#fff',
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        data: revenueChartData,
      },
    ],
  };
  const options: any = {
    plugins: {
      legend: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
            display: false,
          },
          display: true,
          ticks: {
            fontColor: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            fontSize: 16,
            padding: 10,
            fontColor: '#000',
            beginAtZero: true,
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: computeAvgofDataPoints(revenueChartData),
          borderColor: 'grey',
          borderWidth: 1,
          borderDash: [10, 5],
          label: {
            enabled: false,
            content: 'Test label',
          },
        },
      ],
    },
  };
  const currentUnitDetails = useSelector(
    (statee: rootState) => statee.booking.currentUnitDetails
  );
  const data = { start: '', mid: '', end: '' };

  useEffect(() => {
    if (!currentUnitDetails) return;

    const monthsfromUnitCreatingDate: string[] = [];
    let dd = new Date(currentUnitDetails._ct).getTime();
    const myDate = new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ).getTime();
    while (myDate >= dd) {
      const mName = new Date(dd).getMonth() + 1;
      monthsfromUnitCreatingDate.push(`${mName}/${new Date(dd).getFullYear()}`);
      dd = new Date(addMonthToYear(new Date(dd))).getTime();
    }
    setMonths(monthsfromUnitCreatingDate);
    const thisMonth = `${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
    setCurrentMonth(thisMonth);
    const monthName = getMonthNameFromNumber(new Date().getMonth() + 1);
  }, [currentUnitDetails]);

  const getMonthlyReport = async (clinicId: string, month: string) => {
    const data = await apiService.getDentalMonthlyReport(clinicId, month);
    const report = data.data as DentalMonthlyReportType;
    const labData = await apiService.getDentLabReport({ clinicId, month });
    const labReport = labData.data.labReport as DentalLabReportType;
    setDentalMonthlyReport(report);
    setDentalLabReport(labReport);
  };

  const calculateMonthlyReportData = () => {
    if (dentalMonthlyReport) {
      const totalIncome = dentalMonthlyReport.income.reduce((a, b) => {
        return a + b.amount;
      }, 0);

      const totalExpenses = dentalMonthlyReport.expenses.reduce(
        (a, b) => a + b.amount,
        0
      );

      setTotalIncome(totalIncome);
      setTotalExpenses(totalExpenses);
      setIncomeByDoctor(drMonthlyCards(dentalMonthlyReport.income));

      let newArr = [];
      let rObj = {};
      newArr = dentalMonthlyReport.income.map((ele: IncomeType) => {
        const time = +ele.sk.split('-')[1];
        rObj = {
          PatientName: getFullName(ele.patient.name),
          payment: ele.amount,
          drName: getFullName(ele.dr.name),
          date: formatDate(new Date(time)),
        };
        return rObj;
      });
      setIncomeReportData(newArr);

      newArr = dentalMonthlyReport.expenses.map((ele: ExpensesType) => {
        rObj = {
          payiedBy: getFullName(ele.reporter.name),
          type: ele.name,
          value: ele.amount,
          date: formatDate(new Date(ele._ct)),
        };
        return rObj;
      });
      setExpensesReportData(newArr);
    }
  };
  const calculateLabReports = () => {
    if (dentalLabReport) {
      const totalLabCost = dentalLabReport.reduce((a, b) => {
        return a + b.price;
      }, 0);
      setTotalLabs(totalLabCost);

      setLabsCards(drMonthlyCards(dentalLabReport));

      let rObj = {};
      const newArr = dentalLabReport.map((ele: LabServiceOrderType) => {
        rObj = {
          PatientName: getFullName(ele.patient.name),
          lab: ele.lab.name,
          service: ele.serviceName,
          units: ele.units,
          cost: ele.price,
          drName: getFullName(ele.dr.name),
          impresionDate: formatDate(new Date(ele._ct)),
          delivaryDate: formatDate(new Date(ele.delivaryTime)),
        };
        return rObj;
      });
      setLabReportData(newArr);
    }
  };

  const drMonthlyCards = (monthlyData: any[]) => {
    const cards: MonthlyCardsType[] = [];
    monthlyData.forEach((ele) => {
      const elementAmountcheck = ele.amount || ele.amount === 0;
      const index = cards.findIndex((e) =>
        elementAmountcheck ? ele.dr.pk : ele.lab.pk === e.dr.pk
      );
      const visitsCount = dentalMonthlyReport?.visitsCount;
      const headerStart = elementAmountcheck
        ? getFullName(ele.dr.name)
        : ele.lab.name;
      const val = elementAmountcheck ? ele.amount : ele.price;
      if (index === -1) {
        cards.push({
          dr: elementAmountcheck ? ele.dr : ele.lab,
          header: {
            start: headerStart,
            mid: visitsCount + '',
            end: ele.isNew || !elementAmountcheck ? '1' : '0',
          },
          data: {
            start: val.toString(),
            mid: '0',
            end: elementAmountcheck
              ? '100'
              : // : (parseInt(data.start) - parseInt(data.mid)).toString(),
                val,
          },
          total: elementAmountcheck ? val.toString() : '',
        });
      } else {
        cards[index] = {
          ...cards[index],
          header: {
            start: headerStart,
            // mid: (
            //   parseInt(cards[index].header.mid) + (ele.amount > 0 ? 1 : 0)
            // ).toString(),
            mid: visitsCount + '',
            end:
              ele.isNew || !elementAmountcheck
                ? (parseInt(cards[index].header.end) + 1).toString()
                : cards[index].header.end,
          },
          data: {
            start: (parseInt(cards[index].data.start) + val).toString(),
            mid: '0',
            end: elementAmountcheck
              ? '100'
              : (
                  parseInt(cards[index].data.start) +
                  val -
                  parseInt(cards[index].data.mid)
                ).toString(),
          },
          total: (
            (parseInt(cards[index].data.start) + val) *
            (parseInt(cards[index].data.end) / 100)
          ).toString(),
        };
      }
    });
    return cards;
  };

  useEffect(() => {
    if (isMonthlyClick && currentUnitDetails) {
      getMonthlyReport(
        currentUnitDetails.pk,
        currentMonth.split('/')[1] + '-' + currentMonth.split('/')[0]
      );
    }
  }, [isMonthlyClick, currentUnitDetails, currentMonth]);

  useEffect(() => {
    if (dentalMonthlyReport) {
      calculateMonthlyReportData();
    }
    // eslint-disable-next-line
  }, [dentalMonthlyReport]);
  useEffect(() => {
    if (dentalLabReport) {
      calculateLabReports();
    }
    // eslint-disable-next-line
  }, [dentalLabReport]);
  useEffect(() => {
    const changeLangHandler = (lang: string = 'en') => {
      i18n.changeLanguage(lang);
    };
    if (i18n.language === 'ar') changeLangHandler('en');
  }, [i18n]);
  useEffect(() => {
    const dateFormattedMonthly = moment(selectedDate).format('YYYY-MM');
    const dateFormattedDaily = moment(selectedDate).format('YYYY-MM-DD');

    getDailyStats(dateFormattedDaily);
    getMonthlyStats(dateFormattedMonthly);
  }, [selectedDate]);
  const getDailyStats = async (date: string) => {
    try {
      const { data } = await apiService.getUnitDailyStatistics({
        clinicId: currentUnitDetails?.pk,
        day: date,
      });
      setTotalDailyIncome(
        data.income
          .map((i: any) => Number(i.amount))
          .reduce((a: number, b: number) => a + b, 0)
      );
      setTotalDailyExpenses(
        data.expenses
          .map((i: any) => Number(i.amount))
          .reduce((a: number, b: number) => a + b, 0)
      );
      groupIncomeByDoctor(data.income, data.visitsCount);
    } catch (error) {
      console.error('error', error);
    }
  };
  const getMonthlyStats = async (date: string) => {
    try {
      const { data } = await apiService.getDentalMonthlyReport(
        currentUnitDetails?.pk!,
        date
      );
      groupByDay(data.income, data.expenses);
    } catch (error) {
      console.error('error', error);
    }
  };
  const groupIncomeByDoctor = (incomes: IncomeType[], visitsCount: number) => {
    const incomeGroupedByDocs: any[] = [];
    for (const income of incomes) {
      const docs = incomeGroupedByDocs.map((doc: any) => doc.drId);
      const incomeEntry = {
        amount: income.amount,
        isNew: income.isNew,
        pk: income.pk,
        sk: income.sk,
      };
      if (docs.includes(income.dr.pk)) {
        const idx = incomeGroupedByDocs
          .map((doc: any) => doc.drId)
          .indexOf(income.dr.pk);
        incomeGroupedByDocs[idx].income.push(incomeEntry);
      } else {
        incomeGroupedByDocs.push({
          drId: income.dr.pk,
          name: income.dr.name.firstName + ' ' + income.dr.name.lastName,
          income: [incomeEntry],
          visitsCount,
        });
      }
    }
    setTotalDailyIncomeForDocs(incomeGroupedByDocs);
    return incomeGroupedByDocs;
  };
  const groupByDay = (income: any, expenses: any) => {
    const revenue: any = {};
    const incomeFormatted = income.map((i: any) => {
      return {
        date: moment(Number(i.sk.split('-')[1]))
          .startOf('day')
          .valueOf(),
        amount: i.amount,
      };
    });
    const expensesFormatted = expenses.map((i: any) => {
      return {
        date: moment(i._md).startOf('day').valueOf(),
        amount: Number(i.amount),
      };
    });
    for (const i of incomeFormatted) {
      if (!revenue[i.date]) {
        revenue[i.date] = i.amount;
      } else {
        revenue[i.date] += i.amount;
      }
    }
    for (const i of expensesFormatted) {
      if (!revenue[i.date]) {
        revenue[i.date] = -i.amount;
      } else {
        revenue[i.date] -= i.amount;
      }
    }
    const chartData = Object.values(revenue);
    const labelsFormatted = Object.keys(revenue).map((date: any) =>
      moment(Number(date)).format('DD/MM/YYYY')
    );
    setRevenuChartData(chartData);
    setRevenuChartLabels(labelsFormatted);
  };
  return (
    <>
      {currentUnitDetails && (
        <div className="finances-page d-flex flex-column" dir={i18n.dir()}>
          {/* Header */}
          <Header
            setIsMonthlyClick={setIsMonthlyClick}
            months={months}
            isMonthlyClick={isMonthlyClick}
            setCurrentMonth={setCurrentMonth}
            currentMonth={currentMonth}
            setDatePickerOpen={setDatePickerOpen}
            datePickerOpen={datePickerOpen}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          {/* body */}
          {isMonthlyClick && (
            <div className="finances-body d-flex flex-column align-self-center mt-5">
              <div className="report-botton d-flex">
                <ReportBotton
                  name="TOTAL INCOME"
                  amount={totalIncome.toString()}
                  color="var(--color-main)"
                  currentMonth={currentMonth}
                  reportData={dentalMonthlyReport ? incomeReportData : []}
                />
                <ReportBotton
                  name="LAB"
                  amount={totalLabs.toString()}
                  color="var(--color-primary-dark2)"
                  currentMonth={currentMonth}
                  reportData={dentalLabReport ? labReportData : []}
                />
                <ReportBotton
                  name="EXPENCES"
                  amount={totalExpenses.toString()}
                  color="var(--color-danger-light-2)"
                  currentMonth={currentMonth}
                  reportData={dentalMonthlyReport ? expensesReportData : []}
                />
                <ReportBotton
                  name="TOTAL REVENUE"
                  amount={(totalIncome - totalExpenses - totalLabs).toString()}
                  color="var(--color-grey-dark-8)"
                  currentMonth={currentMonth}
                  reportData={[]}
                />
              </div>
              <div className="mt-4">Doctors</div>
              <div className="report-botton d-flex ">
                {incomeByDoctor &&
                  incomeByDoctor.map((drIncome) => (
                    <FinancesContainer
                      key={drIncome.dr.pk}
                      header={drIncome.header}
                      data={drIncome.data}
                      total={drIncome.total}
                    />
                  ))}
              </div>
              <div className="mt-4">Labs</div>

              <div className="report-botton d-flex">
                {labsCards &&
                  labsCards.map((card) => (
                    <FinancesContainer
                      key={card.dr.pk}
                      header={card.header}
                      data={card.data}
                    />
                  ))}
              </div>
            </div>
          )}
          {!isMonthlyClick && (
            <div className="daily-reports-screen-container">
              <div className="daily-reports-total-income">
                <div className="income-and-expenses-cards">
                  <div className="total-income-card">
                    <p>TOTAL INCOME</p>
                    <p className="total-income-value">{totalDailyIncome}</p>
                  </div>
                  <div className="total-expenses-card">
                    <p>EXPENSES</p>
                    <p className="total-income-value">{totalDailyExpenses}</p>
                  </div>
                </div>
                {totalDailyIncomeForDocs.length > 0 && (
                  <div className="doctors-income-cards">
                    {totalDailyIncomeForDocs.map(
                      (docReport: any, idx: number) => {
                        const totalVisits = docReport.visitsCount;
                        const newPatients = docReport.income
                          .map((i: any) => i.isNew)
                          .filter(Boolean).length;
                        const docIncome = docReport.income
                          .map((i: any) => i.amount)
                          .reduce((a: number, b: number) => a + b, 0);
                        const name = docReport.name;
                        return (
                          <div key={idx}>
                            <DailyReportCardDetails
                              docName={name}
                              income={docIncome}
                              totalVisits={totalVisits}
                              newPatients={newPatients}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>

              <div className="daily-reports-graph-section">
                <p>REVENUE GRAPH</p>
                <div className="daily-reports-line-chart">
                  <Line
                    data={chartData}
                    options={options}
                    plugins={[annotationPlugin]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Finances;
