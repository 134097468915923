import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';

import { useTranslation } from 'react-i18next';
import uploadIcon from '../../assets/img/upload-on-cloud.svg';
import uploadingIcon from '../../assets/img/upload.svg';
import errorIcon from '../../assets/img/alert.svg';
import successIcon from '../../assets/img/tick.svg';

type Props = {
  getImage: (e: any, index: number, type?: string) => void;
  index: number;
  files?: any;
  type?: string;
};

const MyDropzone: React.FC<Props> = ({ getImage, index, type, files = {} }) => {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      getImage(acceptedFiles, index, type);
    },
    [getImage, type, index]
  );
  // console.log(files);

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <div
          className="profile upload d-flex align-items-center"
          {...getRootProps()}
        >
          <img
            src={
              files.status === 'uploading'
                ? uploadingIcon
                : files.status === 'error'
                ? errorIcon
                : files.status === 'success'
                ? successIcon
                : uploadIcon
            }
            alt="upload"
          />

          {files.urls.length > 0 ? (
            <p>
              {files.urls.length} {t('labBoard_page.files')}
            </p>
          ) : (
            <p className="d-flex flex-column align-items-center">
              <span>{t('labBoard_page.drag-msg')}</span>
              <span>{t('labBoard_page.browse')}</span>
            </p>
          )}
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
};

export default MyDropzone;
