import governments from '../assets/constants/governments';
import { getAgeFromBD } from './moment';
// import { GenderType } from '../helpers/types';

const getInfoFromNID = (
  nid: string
): {
  dob: Date;
  age: number;
  gov?: string;
  gender: string;
} => {
  const firstDigit = nid.charAt(0);
  const centaury = +firstDigit - 1;

  const year = +nid.slice(1, 3);
  const month = +nid.slice(3, 5);
  const day = +nid.slice(5, 7);

  const birthDateString = `${month}/${day}/${centaury + 18}${year}`;

  const dob = new Date(birthDateString);
  const age = +getAgeFromBD(dob).split(' ')[0];

  const government = nid.slice(7, 9);
  const gov = Object.keys(governments).find(
    (ele) => governments[ele].code === government
  );

  const gender = +nid.charAt(12) % 2 === 0 ? 'female' : 'male';

  return { dob, age, gov, gender };
};

export default getInfoFromNID;
