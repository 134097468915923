export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const CHECK_USER = 'CHECK_USER';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER_AUTHENTICATED = 'SET_USER_AUTHENTICATED';
export const TOKEN_REQUIRED = 'TOKEN_REQUIRED';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_CLINICIAN_UNITS = 'SET_CLINICIAN_UNITS';
export const SET_CURRENT_UNIT_DETAILS = 'SET_CURRENT_UNIT_DETAILS';
export const SET_EMP_CURRENT_UNIT = 'SET_EMP_CURRENT_UNIT';

export const RESET_BOOKING = ' RESET_BOOKING';

export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT';
export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const REMOVE_SEARCHED_PATIENTS = 'REMOVE_SEARCHED_PATIENTS';
export const SHOW_ADD_PATIENTS_BUTTON = 'SHOW_ADD_PATIENTS_BUTTON';
export const SET_PATIENT_ORDERS = 'SET_PATIENT_ORDERS';

export const SET_BOOKING_DATE = 'SET_BOOKING_DATE';
export const SET_BOOKING_END_DATE = 'SET_BOOKING_END_DATE';
export const ADD_BOOKING = 'ADD_BOOKING';
export const ADD_PENDING_BOOKING = 'ADD_PENDING_BOOKING';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const SET_CURRENT_BOOKING = 'SET_CURRENT_BOOKING';
export const SET_CLINIC_BOOKINGS = 'SET_CLINIC_BOOKINGS';
export const SET_CLINIC_BOOKINGS_PENDING = 'SET_CLINIC_BOOKINGS_PENDING';
export const SET_CLINIC_TIME_SLOTS = 'SET_CLINIC_TIME_SLOTS';
export const GET_CLINIC_BOOKINGS = 'GET_CLINIC_BOOKINGS';
export const SET_UNIT_EMPLOYEES = 'SET_UNIT_EMPLOYEES';
export const SET_UNIT_Expenses = 'SET_UNIT_Expenses';
export const PATIENT_PREVIOUS_BOOKINGS = 'PATIENT_PREVIOUS_BOOKINGS';
export const FOCUS_SEARCH_INPUT = 'FOCUS_SEARCH_INPUT';
export const SET_LOADING = 'SET_LOADING';
export const SET_STATISTICS = 'SET_STATISTICS';
export const SET_CURRENT_SLOT = 'SET_CURRENT_SLOT';
export const SET_COLLAPSED_SLOTS = 'SET_COLLAPSED_SLOTS';
export const SET_SHOW_ALL = 'SET_SHOW_ALL';
export const SET_SHOW_SOME_SLOTS = 'SET_SHOW_SOME_SLOTS';
export const SET_ALLBOOKING = 'SET_ALLBOOKING';
export const LOCK_NAV_BAR = 'LOCK_NAV_BAR';
export const SET_BOOKING_CURRENT_FINANCAIL_LOG =
  'SET_BOOKING_CURRENT_FINANCAIL_LOG';
//  board
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const RESET_RESERVATION = 'RESET_RESERVATION';
export const SET_HISTORY = 'SET_HISTORY';
export const ADD_BOARD_BOOKING = 'ADD_BOARD_BOOKING ';
export const SET_BOARD_BOOKINGS = 'SET_BOARD_BOOKINGS';
export const SET_CURRENT_ENCOUNTER = 'SET_CURRENT_ENCOUNTER';
export const SET_RAD_ENCOUNTER = 'SET_RAD_ENCOUNTER';
export const SET_LAB_ENCOUNTER = 'SET_LAB_ENCOUNTER';
export const TOGGLE_BOARD_MENU = 'TOGGLE_BOARD_MENU';
export const START_EXAMINATION = 'START_EXAMINATION';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const RESET_BOARD_BOOKING = 'RESET_BOARD_BOOKING';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_TESTS = 'SET_TESTS';
export const SET_PHYS_COLLAPSED_SLOTS = 'SET_PHYS_COLLAPSED_SLOTS';
export const SET_PHYS_ALLBOOKINGS = 'SET_PHYS_ALLBOOKINGS';
export const SET_HIDE_ALL = 'SET_HIDE_ALL';
export const SET_SOMESLOTS_OPEN = 'SET_SOMESLOTS_OPEN';
export const ADD_ATTACHMENTS_FILE = 'ADD_ATTACHMENTS_FILE';
export const DELETE_ATTACHMENTS_FILE = 'DELETE_ATTACHMENTS_FILE';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const IS_ATTACHMENT_UPLOADING = 'IS_ATTACHMENT_UPLOADING';
export const SET_EXPIRATION_MODAL = 'SET_EXPIRATION_MODAL';
export const RESET_HISTORY = 'RESET_HISTORY';
export const SET_BOARD_CURRENT_BOOKING = 'SET_BOARD_CURRENT_BOOKING';
export const RESET_BOARD = 'RESET_BOARD';
export const SEARCH_DR_RESULT = 'SEARCH_DR_RESULT';
export const SET_REFERAL = 'SET_REFERAL';
export const SET_ENCOUNTER = 'SET_ENCOUNTER';
export const SET_BOARD_CURRENT_FINANCAIL_LOG =
  'SET_BOARD_CURRENT_FINANCAIL_LOG';
export const UPDATE_BOARD_CURRENT_FINANCAIL_LOG =
  'UPDATE_BOARD_CURRENT_FINANCAIL_LOG';
// uploader
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const DELETE_FILE = 'DELETE_FILE';
export const REMOVE_FILES = 'REMOVE_FILES';
export const CLEAR_BOOKING_FILES = 'CLEAR_BOOKING_FILES';
export const SET_FILES_SENT = 'SET_FILES_SENT';
export const RESET_UPLOADER = 'RESET_UPLOADER';

// LAB_BOARD
export const SET_CURRENT_LAB_BOOKING = 'SET_CURRENT_LAB_BOOKING';
export const SET_LAB_BOOKINGS = 'SET_LAB_BOOKINGS';
export const UPDATE_LAB_BOOKING = 'UPDATE_LAB_BOOKING';
export const DELETE_LAB_BOOKING = 'DELETE_LAB_BOOKING';
export const ADD_LAB_BOOKING = 'ADD_LAB_BOOKING';
export const RESET_LAB_BOOKING = 'RESET_LAB_BOOKING';
export const UPDATE_EXIST_LAB_BOOKING = 'UPDATE_EXIST_LAB_BOOKING';
