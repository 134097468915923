import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { searchSymp } from '../../../../services/api/search';
import * as ICD11Service from '../../../../services/icd11';
import { rootState } from '../../../../store/reducers';
import SearchResult from '../SearchResult/SearchResult';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import { SelectedItemTag } from '../SelectedItemTag/SelectedItemTag';

import './SymptomsComponent.scss';

type Props = {
  column: number;
  data: any[];
  onSelect: (data: any) => void;
  onRemove: (data: any) => void;
};
const SymptomsComponent = (props: Props) => {
  const { column, data, onSelect, onRemove } = props;
  const [title] = useState<string>('Symptoms');
  const activeSection = useSelector(
    (state: rootState) => state.board.activeSection
  );
  const [result, setResult] = useState<any>([]);
  const [reset, doReset] = useState(0);
  const [hText, setHText] = useState<any>('');
  const strings = hText.split(' ');
  const onTextChange = async (value: string) => {
    if (!value) setResult([]);
    else {
      const { data: searchData } = await searchSymp({ text: value });
      setResult(ICD11Service.groupByCode(searchData));
      setHText(value);
    }
  };
  const onDone = (value: string) => {
    setResult({});
    setHText('');
    doReset((prev) => prev + 1);
  };

  const onSelectItem = (d: any) => {
    onSelect(d);
    setResult({});
    doReset((prev) => prev + 1);
  };

  useEffect(() => {
    setResult({});
    setHText('');
  }, [activeSection.section]);

  return (
    <div className="component symptoms-component">
      <SectionHeaderComponent
        title={title}
        column={column}
        hasButton={true}
        onTextChange={onTextChange}
        onDone={onDone}
        reset={reset}
      />
      <div className="col-content">
        <SearchResult data={result} strings={strings} onSelect={onSelectItem} />
        <div className="selected-result">
          <div className="tags">
            {data.map((element: any) => (
              <SelectedItemTag
                key={`diag-${element.code}`}
                element={element}
                onRemove={onRemove}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SymptomsComponent;
