import React, { useState } from 'react';
import Modal from 'react-modal';
import './dentalServiceModal.scss';
import Switch from './switchButton';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
  },
};
type Props = {
  isOpen: boolean;
  closeModal: any;
};
const DentalServiceModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const [numOfSteps, setNumOfSteps] = useState(4);
  const [labOptionIncluded, setLabOptionIncluded] = useState(false);
  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => closeModal()}
    >
      <div className="dental-service-modal">
        <div className="modal-header">
          <p>Service</p>
          <i className="fas fa-times" onClick={() => closeModal()}></i>
        </div>
        <div className="dental-modal-content">
          <div className="first-inputs-set">
            <input type="text" name="serviceName" placeholder="Service Name" />
            <div className="steps-section">
              <div className="add-more-steps">
                <p>Steps</p>
                <div onClick={() => setNumOfSteps((prev) => prev + 1)}>
                  {' '}
                  Add +
                </div>
              </div>
              <div className="step-inputs">
                {[...Array(numOfSteps)].map((_: any, i: any) => {
                  return (
                    <div key={i}>
                      <input
                        type="text"
                        name={`step${i}`}
                        placeholder={i + 1 + ':'}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="second-inputs-set">
            <input type="text" name="price" placeholder="Price" />
            <p>Chart selection options:</p>
            <div className="service-options">
              <input type="radio" value="1" name="_" /> Single Tooth
              <br />
              <input type="radio" value="2" name="_" /> Multiple teeth,
              connected
              <br />
              <input type="radio" value="3" name="_" /> Multiple teeth,
              disconnected
              <br />
              <input type="radio" value="4" name="_" /> Single arch
              <br />
              <input type="radio" value="5" name="_" /> Both arches
              <br />
            </div>
            <div className="lab-option-included-toggle">
              <p className="switch-title">Lab option</p>
              <Switch
                isOn={labOptionIncluded}
                handleToggle={() => setLabOptionIncluded(!labOptionIncluded)}
              />
              <p className="switch-title">Included</p>
            </div>
            <div className="select-lab-service">
              <p>Lab Service:</p>
              <select name="" id="">
                <option value=""></option>
              </select>
              <i className="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
        <div className="modal-btns">
          <div>Reset</div>
          <div>Save</div>
          <div className="modal-cancel-btn">Cancel</div>
        </div>
      </div>
    </Modal>
  );
};

export default DentalServiceModal;
