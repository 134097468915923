import React from 'react';
import { NavLink } from 'react-router-dom';
import { SadFaceIcon } from '../../assets/constants/images';
import { UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import { ClinicData } from '../../types';
import './NotActive.scss';

const SubscriptionExpiredEmployee = () => {
  return (
    <div className="emptyPage">
      <div className="pageBody">
        <div className="icon-container error">
          <SadFaceIcon />
        </div>
        <h2>
          للأسف لوحة المتابعة الخاصة بك معطلة الآن
          <br /> برجاء انتظار الطبيب لاعادة تفعيلها
        </h2>
      </div>
    </div>
  );
};
const SubscriptionExpiredPhysician = (unit: ClinicData) => {
  return (
    <div className="emptyPage">
      <div className="pageBody">
        <div className="icon-container error">
          <SadFaceIcon />
        </div>
        <h2 className="text">
          Unfortunately Your Board Is <strong>Disabled</strong> Now
          <br />
          {unit.type === 'clinic'
            ? 'Renew Your Subscription To Activate It'
            : `Recharge your balance to activate it`}
        </h2>

        <NavLink to={routes.PLANS} className="btn btn-primary btn-lg renewBtn">
          {unit.type === 'clinic' ? 'Renew' : 'Recharge'}
        </NavLink>
      </div>
    </div>
  );
};

const SubscriptionExpired = (currentEmpUnit: any, unit: ClinicData) => {
  return currentEmpUnit &&
    [UserRoles.RadioTech, UserRoles.LabTech, UserRoles.Physician].includes(
      currentEmpUnit.role
    )
    ? SubscriptionExpiredPhysician(unit)
    : currentEmpUnit &&
      [UserRoles.Nurse, UserRoles.Receptionist, UserRoles.Assistant].includes(
        currentEmpUnit.role
      )
    ? SubscriptionExpiredEmployee()
    : null;
};
export default SubscriptionExpired;
