import ReactPixel from 'react-facebook-pixel';
import { pixleId } from '../assets/constants/pixel';
class Analytics {
  public static eventNames = {
    CompleteRegistration: 'CompleteRegistration',
    Lead: 'Lead',
    ViewContent: 'ViewContent',
  };

  public static init = () => {
    const options: any = {
      autoConfig: true, // set pixel's autoConfig
      debug: true, // enable logs
    };
    ReactPixel.init(pixleId, options);
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');
  };

  public static sendEvent = (action: string, data: any): void => {
    ReactPixel.track(action, data);
  };
}

export default Analytics;
