import React from 'react';
import Input from '../Input/Input';

type NameInputProps = {
  name: string;
  placeholder: any;
  inputChange?: (value: string) => void;
};

const NameInput: React.FC<NameInputProps> = ({
  name,
  placeholder,
  inputChange,
}) => {
  return (
    <Input
      inputChange={inputChange}
      type="text"
      name={name}
      inputMode="text"
      placeholder={placeholder}
    />
  );
};
export default NameInput;
