import React from 'react';
import WithUnit from '../../hocs/WithUnit';
import Board from './Board';

const BoardPage: React.FC = () => {
  return (
    <WithUnit>
      <Board />
    </WithUnit>
  );
};
export default BoardPage;
