import React, { useState, forwardRef } from 'react';
import './CustomDatePicker.scss';
import DatePicker, { registerLocale } from 'react-datepicker';
import el from 'date-fns/locale/ar-SA';

import arrowIcon from '../../../../assets/img/arrow-down.svg';
import { getDayName, getMonthName } from '../../../../utils/moment';
import days from '../../../../assets/constants/days';
import months from '../../../../assets/constants/months';
import { useTranslation } from 'react-i18next';

registerLocale('el', el); // register it with the name you want

type Props = {
  minDate: Date;
  startDate: Date;
  changeBookingDateHandler: (date: Date) => void;
};

const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => {
  const { i18n } = useTranslation();

  return (
    <div
      className="date-container d-flex justify-content-between align-items-center"
      onClick={onClick}
    >
      <div className="day align-items-center justify-content-center d-flex">
        {days[getDayName(value).toLocaleLowerCase()][i18n.language]}
      </div>
      <div className="select d-flex justify-content-between align-items-center">
        <p>{`${getMonthName(value).split(' ')[1]} ${
          months[getMonthName(value).split(' ')[0].toLocaleLowerCase()][
            i18n.language
          ]
        }`}</p>
        <img src={arrowIcon} alt="arrowIcon" />
      </div>
    </div>
  );
});

const CustomDatePicker: React.FC<Props> = ({
  minDate,
  startDate,
  changeBookingDateHandler,
}) => {
  const { i18n } = useTranslation();
  const [initDate, setInitDate] = useState<any>(startDate);
  const changeDateHandler = (date: Date) => {
    setInitDate(date);
    changeBookingDateHandler(date);
  };

  let dateOptions: any = {
    selected: initDate,
    minDate,
    onChange: changeDateHandler,
  };
  if (i18n.language === 'ar') {
    dateOptions = { ...dateOptions, locale: 'el' };
  }

  return <DatePicker {...dateOptions} customInput={<ExampleCustomInput />} />;
};

export default CustomDatePicker;
