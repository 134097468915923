import React from 'react';
import './EmailInput.scss';
import { useField, useFormikContext } from 'formik';
import parseArabic from '../../../../utils/parseArabic';
import icon from '../../../../assets/img/Mask Group 536.svg';
type InputProps = {
  iconClick?: () => void;
  inputChange?: (value: string) => void;
  name: string;
  placeholder?: string;
  type: string;
  icon?: string;
  isIcon?: boolean;
  className?: string;
  disabled?: boolean;
  onChangeValue?: (value: string, fieldName: string) => void;
};

const EmailInput: React.FC<InputProps> = ({
  disabled,
  inputChange,
  onChangeValue,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const onInputChange = (value: string) => {
    if (inputChange) {
      inputChange(value);
    }
  };
  return (
    <div className="email-input-container">
      <img className="icon" src={icon} alt="icon"></img>

      <input
        {...props}
        {...field}
        onKeyUp={(e: any) => {
          const value = parseArabic(e.target.value);
          // console.log('value', value);
          setFieldValue(field.name, value);
          if (onChangeValue) onChangeValue(value, field.name);
          onInputChange(value);
        }}
        disabled={disabled}
      />

      <div className="error ">
        {meta.touched && meta.error ? (
          <span className="error">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

export default EmailInput;
