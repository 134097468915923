import React, { useState, useEffect } from 'react';
import './LabBookingForm.scss';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '../../UI/Form/Button/Button';

import { searchOrder } from '../../../services/api/search';

import routes from '../../../assets/constants/routes';
import { ClinicData, Booking } from '../../../types';
import getFullName from '../../../utils/getFullName';

import { getTimeSlots } from '../../../services/timeSlots';
import {
  formatCAlDate,
  getBookingTime,
  getDayName,
} from '../../../utils/moment';
import { errorToast, warnToast } from '../../../utils/toast';
import unitTypes from '../../../utils/unitTypes';
import parseArabic from '../../../utils/parseArabic';
import { useSelector } from 'react-redux';
import { rootState } from '../../../store/reducers';

import specialityIcon from '../../../assets/img/speciality.svg';
import addIcon from '../../../assets/img/add.svg';
import arrowIcon from '../../../assets/img/arrow-down.svg';
import SwitchOrdersModal from './SwitchOrdersModal/SwitchOrdersModal';
import TableRow from './TableRow';
import bookingStatus from '../../../assets/constants/bookingStatus';
import TableHead from './TableHead';

type Props = {
  addLabBookingHandler: (payload: any) => void;
  editLabBookingHandler: (payload: any) => void;
  setPatientDataHandler: (data: any) => void;
  setPrevoiusAndUpComming: (index: number, bookings: Booking[]) => Booking;
  setPatientPreviousBooking: (bookings: Booking[]) => void;
  clinic: ClinicData | null;
  orders?: any;
  bookingData: Booking;
  previousBookings: Booking[];
};

const LabBookingForm: React.FC<Props> = ({
  addLabBookingHandler,
  editLabBookingHandler,
  setPatientDataHandler,
  setPrevoiusAndUpComming,
  setPatientPreviousBooking,
  previousBookings,
  clinic,
  orders,
  bookingData,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [booking, setBooking] = useState<Booking | null>(null);
  const [isEditBooking, setIsEditBooking] = useState(false);
  const [isUpcommingBooking, setIsUpcommingBooking] = useState(false);
  const [totalScanPrices, setTotalScanPrices] = useState(0);
  const [customPrices, setCustomPrices] = useState(0);
  const [paidPrice, setPaidPrice] = useState<any>('');

  const [selectedDr, setSelectedDr] = useState('');
  const [tempDr, setTempDr] = useState('');
  const [openDrList, setOpenDrList] = useState(false);

  const [scans, setscans] = useState<any>([]);
  const [editScans, setEditScans] = useState<any>([]);
  const [customScans, setCustomScans] = useState<any[]>([]);
  const [timeSlots, setTimeSlots] = useState<any>({});
  const [result, setResult] = useState<any[]>([]);

  const [isChangingData, setIsChangingData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const bookings = useSelector((state: rootState) => state.booking.bookings);
  const isLoading = useSelector((state: rootState) => state.booking.isLoading);

  // get upcomming booking for patient according to booking dr
  const getPatientUpcommingBooking = (drId: string) => {
    let upComming = -1;
    if (drId) {
      upComming = previousBookings.findIndex(
        (ele) =>
          +ele.date >= new Date().setHours(0, 0, 0, 0) &&
          [bookingStatus.new].includes(ele.status) &&
          ele.targetDr?.pk === drId
      );
    } else {
      upComming = previousBookings.findIndex(
        (ele) =>
          +ele.date >= new Date().setHours(0, 0, 0, 0) &&
          [bookingStatus.new].includes(ele.status) &&
          !ele.targetDr
      );
    }
    // console.log('upComming', upComming);
    if (upComming >= 0) {
      const b = setPrevoiusAndUpComming(upComming, previousBookings);
      // console.log('b', b);
      setIsUpcommingBooking(true);
      setBookingDetails(b);
      setEditOrdersHandler(b);
    } else {
      setBooking(null);
      setBookingDetails(null);
      setIsUpcommingBooking(false);
      setPatientPreviousBooking(previousBookings);
      // orderTestsHandler(tests, null);
    }
  };

  const setOrdersHandler = (drId: string) => {
    if (isEditBooking) return;
    setIsChangingData(false);
    if (drId) {
      if (Object.keys(orders).length <= 0) return;
      const { dr, clinic: clin, patient, pk, sk, tests } = orders[drId];
      setSelectedDr(drId);
      getPatientUpcommingBooking(drId);
      setPatientDataHandler({ dr, clinic: clin, patient, pk, sk });
      orderTestsHandler(tests, null);
    } else {
      setSelectedDr('');
      getPatientUpcommingBooking('');
      setPatientDataHandler(null);
      setscans([]);
      setCustomScans([]);
    }
  };

  const orderTestsHandler = (tests: any[], b: Booking | null) => {
    const pScans: any[] = [];
    tests.forEach((test: any) => {
      if (b && b.tests) {
        // console.log('in b tests');
        const testIdx = b.tests.findIndex((ele) => ele.code === test.code);
        if (testIdx < 0) pScans.push({ ...test, removed: false, price: 0 });
      } else {
        pScans.push({ ...test, removed: false, price: 0 });
      }
    });
    // console.log('pScans', pScans);
    setscans([...pScans]);
    setCustomScans([]);
  };

  const setEditOrdersHandler = (b: Booking) => {
    if (!b.targetDr) return;
    const drId = b.targetDr.pk;
    setSelectedDr(b.targetDr.pk);
    const { dr, clinic: clin, patient, pk, sk, tests } = orders[drId];
    setPatientDataHandler({ dr, clinic: clin, patient, pk, sk });
    orderTestsHandler(tests, b);
  };

  const setBookingDetails = (bookingDetails: Booking | null) => {
    if (bookingDetails) {
      setBooking({ ...bookingDetails });
      const eScans: any[] = [];
      let price = 0;
      if (!bookingDetails.tests) return;
      bookingDetails.tests.forEach((ele) => {
        const date = formatCAlDate(new Date(bookingDetails.date));
        const time = getBookingTime(new Date(bookingDetails.date));
        eScans.push({
          ...ele,
          removed: false,
          price: ele.price,
          date,
          time,
        });
        price += ele.price;
        getScanTimeSlots(new Date(date), time);
      });
      // console.log('eScans', eScans);
      setEditScans([...eScans]);
      setTotalScanPrices(price);
      setPaidPrice(bookingDetails.paid ? bookingDetails.paid : 0);
    } else {
      resetBooking();
    }
  };

  const resetBooking = () => {
    setBooking(null);
    setIsEditBooking(false);
    setEditScans([]);
    setTotalScanPrices(0);
    setPaidPrice(0);
  };

  useEffect(() => {
    if (bookingData) {
      // console.log('bookingData', bookingData);
      setIsEditBooking(true);
      setBookingDetails(bookingData);
    } else {
      resetBooking();
    }
    // eslint-disable-next-line
  }, [bookingData]);

  useEffect(() => {
    if (!orders || Object.keys(orders).length === 0) {
      setscans([]);
      return;
    }
    // console.log('orders', orders);
    if (
      Object.keys(orders) &&
      Object.keys(orders).length > 0 &&
      isEditBooking &&
      booking &&
      booking.targetDr
    ) {
      // console.log('edittttttttt booking');
      setEditOrdersHandler(booking);
    }
    if (
      Object.keys(orders) &&
      Object.keys(orders).length > 0 &&
      !isEditBooking &&
      !isUpcommingBooking
    ) {
      // console.log('newwwwwwwww booking');
      finsNearstUpcommingBooking();
    }
    // eslint-disable-next-line
  }, [orders, isEditBooking]);

  const finsNearstUpcommingBooking = () => {
    let upComming = [];

    upComming = previousBookings.filter(
      (ele) =>
        +ele.date >= new Date().setHours(0, 0, 0, 0) &&
        [bookingStatus.new].includes(ele.status)
    );
    // console.log('upComming', upComming);
    if (upComming.length > 0) {
      // console.log('lastBooking', upComming[upComming.length - 1]);
      const upCommingBooking = upComming[upComming.length - 1];
      const idx = previousBookings.findIndex(
        (ele) => ele.sk === upCommingBooking.sk
      );
      const b = setPrevoiusAndUpComming(idx, previousBookings);
      // console.log('b', b);
      setIsUpcommingBooking(true);
      setBookingDetails(b);
      setEditOrdersHandler(b);
    } else {
      setOrdersHandler(Object.keys(orders)[0]);
    }
  };

  useEffect(() => {
    return () => {
      setTimeSlots({});
    };
  }, []);

  const getScanTimeSlots = (date: Date, time?: string) => {
    // console.log('date', date);
    if (!clinic) return;
    if (timeSlots[date.getTime()]) return;
    const timeSlot = getTimeSlots(date, clinic, bookings, time);
    const newSlots = { ...timeSlots };
    newSlots[date.getTime()] = timeSlot;
    // console.log('newSlots', newSlots);
    setTimeSlots(newSlots);
  };

  const changeScanHandler = (
    index: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.persist();
    if (!isChangingData) setIsChangingData(true);
    const name = e.target.name;
    const value = e.target.value;
    const newScans = [...scans];
    if (name === 'date') {
      const dayName = getDayName(new Date(value)).toLocaleLowerCase();
      if (
        clinic &&
        clinic.workingDays &&
        !Object.keys(clinic.workingDays).includes(dayName)
      ) {
        warnToast(t('addBooking_page.clinic-day'));
      }
      newScans[index].time = '';
      getScanTimeSlots(new Date(value));
    }

    if (name === 'price') {
      const parsedNumber = parseArabic(value);
      const isValid = /^[0-9]*$/.test(parsedNumber);
      // console.log('parsedNumber', parsedNumber);
      newScans[index][name] = isValid ? +parsedNumber : 0;
      let price = 0;
      newScans.map((scan) => (price += +scan.price || 0));
      setTotalScanPrices(price);
    } else {
      newScans[index][name] = value;
    }
    setscans(newScans);
  };

  const changeEditScanHandler = (
    index: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.persist();
    if (!isChangingData) setIsChangingData(true);
    const name = e.target.name;
    const value = e.target.value;
    const newScans = [...editScans];
    if (name === 'date') {
      const dayName = getDayName(new Date(value)).toLocaleLowerCase();
      if (
        clinic &&
        clinic.workingDays &&
        !Object.keys(clinic.workingDays).includes(dayName)
      ) {
        warnToast(t('addBooking_page.clinic-day'));
      }
      newScans[index].time = '';
      getScanTimeSlots(new Date(value));
    }

    if (name === 'price') {
      const parsedNumber = parseArabic(value);
      const isValid = /^[0-9]*$/.test(parsedNumber);
      // console.log('parsedNumber', parsedNumber);
      newScans[index][name] = isValid ? +parsedNumber : 0;
      let price = 0;
      newScans.map((scan) => (price += +scan.price || 0));
      setTotalScanPrices(price);
    } else {
      newScans[index][name] = value;
    }
    setEditScans(newScans);
  };

  const changeCustomScanHandler = async (
    index: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.persist();
    if (!isChangingData) setIsChangingData(true);
    const name = e.target.name;
    const value = e.target.value;
    const newScans = [...customScans];
    if (name === 'date') {
      const dayName = getDayName(new Date(value)).toLocaleLowerCase();
      if (
        clinic &&
        clinic.workingDays &&
        !Object.keys(clinic.workingDays).includes(dayName)
      ) {
        warnToast(t('addBooking_page.clinic-day'));
      }
      newScans[index].time = '';
      getScanTimeSlots(new Date(value));
    }

    if (name === 'price') {
      const parsedNumber = parseArabic(value);
      const isValid = /^[0-9]*$/.test(parsedNumber);
      newScans[index][name] = isValid ? +parsedNumber : 0;
      let price = 0;
      newScans.map((scan) => (price += +scan.price));

      setCustomPrices(() => price);
    } else {
      newScans[index][name] = value;
    }
    // console.log('newScans', newScans);
    setCustomScans(newScans);
  };

  const hideScanHandler = (index: number) => {
    const newScans = [...scans];
    const removedScan = newScans.splice(index, 1)[0];
    removedScan.removed = true;
    setTotalScanPrices((ee) => ee - removedScan.price);
    setscans([...newScans, removedScan]);
  };

  const hideEditScanHandler = (index: number) => {
    const newScans = [...editScans];
    const removedScan = newScans.splice(index, 1)[0];
    removedScan.removed = true;
    setTotalScanPrices((ee) => ee - removedScan.price);
    setEditScans([...newScans, removedScan]);
  };

  const hideCustomScanHandler = (index: number) => {
    const newScans = [...customScans];
    const removedScan = newScans.splice(index, 1)[0];
    setCustomPrices((ee) => ee - removedScan.price);
    setCustomScans([...newScans]);
  };

  const showScanHandler = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      const newScans = [...scans];
      const addedScan = newScans.splice(index, 1)[0];
      addedScan.removed = false;
      setTotalScanPrices((ee) => ee + addedScan.price);
      setscans([addedScan, ...newScans]);
    }
  };

  const showEditScanHandler = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      const newScans = [...editScans];
      const addedScan = newScans.splice(index, 1)[0];
      addedScan.removed = false;
      setTotalScanPrices((ee) => ee + addedScan.price);
      setEditScans([addedScan, ...newScans]);
    }
  };

  const addCustomScanHandler = () => {
    const tableRow = {
      price: 0,
      name: '',
      date: '',
      time: '',
      code: '',
      class: '',
    };
    setCustomScans([tableRow, ...customScans]);
  };

  const searchOrderHandler = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const newScans = [...customScans];
    const { data } = await searchOrder({ text: value });
    if (data.length > 0 && value) {
      if (clinic && clinic.type === unitTypes.lab) {
        const lab: any[] = data.filter((order: any) => order.class !== 'RAD');
        // console.log('lab', lab);
        setResult(lab);
      } else if (clinic && clinic.type === unitTypes.image) {
        const rad: any[] = data.filter((order: any) => order.class === 'RAD');
        // console.log('rad', rad);
        setResult(rad);
      }
      const details = data.find((ele: any) => ele.name === value);
      newScans[index] = {
        ...newScans[index],
        ...details,
      };
    } else {
      setResult([]);
      newScans[index] = {
        ...newScans[index],
        name: '',
        code: '',
        class: '',
      };
    }
    setCustomScans(newScans);
  };

  const getPricesHandler = () => {
    const total = customPrices + totalScanPrices;
    const remaining = total - paidPrice;
    return { total, remaining };
  };

  const saveBookingHandler = () => {
    // console.log('customScans', customScans);
    let payload = [...editScans, ...scans, ...customScans];
    payload = payload.filter((ele) => !ele.removed);
    // console.log('payload', payload);
    const validPayload = payload.every(
      (scan) => !!(scan.date && scan.time && scan.name && scan.price)
    );
    if (!validPayload) {
      errorToast(t('employess_page.fill_choosed'));
      return;
    }
    const data = {
      scans: [...payload],
      totalCost: getPricesHandler().total,
      paidPrice,
    };
    if (isEditBooking || isUpcommingBooking) {
      // console.log('edit[saveBookingHandler] booking');
      editLabBookingHandler({ ...data, booking });
    } else {
      // console.log('add[saveBookingHandler] booking');

      const isValidDate = payload.every(
        (ele) => new Date(ele.date) >= new Date(new Date().setHours(2, 0, 0, 0))
      );
      // console.log('isValidDate', isValidDate);
      if (!isValidDate) {
        errorToast(t('employess_page.date-err'));
        return;
      }
      addLabBookingHandler(data);
    }
  };

  const getScanTimeSlot = (date: string, time: string) => {
    const slots = timeSlots[new Date(date).getTime()] || {};
    if (time && !slots[time]) {
      const hour = +time.split(':')[0];
      const minutes = +time.split(':')[1];
      const timeSlot: any = {
        ar: `${hour <= 12 ? hour : ('0' + (hour - 12)).slice(-2)}:${
          minutes < 10 ? '0' + minutes : minutes
        } ${hour < 12 ? 'ص' : 'م'}`,
        en: `${hour <= 12 ? hour : ('0' + (hour - 12)).slice(-2)}:${
          minutes < 10 ? '0' + minutes : minutes
        } ${hour < 12 ? 'AM' : 'PM'}`,
      };
      slots[time] = timeSlot;
    }
    return slots;
  };

  const switchDr = (dr: string) => {
    setOrdersHandler(dr);
    setOpenDrList(false);
    setIsEditBooking(false);
    setTimeSlots({});
  };

  const selectDrhandler = (dr: string) => {
    // console.log('dr', dr);
    if (dr === selectedDr) return;
    if (isChangingData) {
      setTempDr(dr);
      setIsOpen(true);
    } else {
      switchDr(dr);
    }
  };

  const onModalClose = () => setIsOpen(false);

  const onModalConfirm = (dr: string) => {
    switchDr(dr);
    setIsChangingData(false);
    onModalClose();
  };

  // const isScanInBookingTests = (scan: any) => {
  //   let isOk = true;
  //   if (booking && booking.tests) {
  //     const ff = booking.tests.findIndex((test) => test.code === scan.code);
  //     if (ff >= 0) isOk = false;
  //   }
  //   return isOk;
  // };

  return (
    <div
      className="l-booking-data__form d-flex flex-column mt-5 mt-sm-0"
      onClick={() => {
        if (isEditBooking) return;
        if (openDrList) {
          setOpenDrList(false);
        }
      }}
    >
      {isOpen && (
        <SwitchOrdersModal
          isOpen={isOpen}
          dr={tempDr}
          onModalClose={onModalClose}
          onModalConfirm={onModalConfirm}
        />
      )}
      <div className="l-booking-actions d-flex justify-content-between">
        {/* {isEditBooking && booking && !booking.targetDr ? null : ( */}
        <div className="d-flex align-items-center">
          <div className="d-flex">
            <img src={specialityIcon} alt="specialityIcon" />
            <h5 className="ml-2 mr-2">{t('addBooking_page.dr')}</h5>
          </div>
          <div
            className="select-dr d-flex align-items-center justify-content-between"
            onClick={() => {
              if (isEditBooking) return;
              setOpenDrList(!openDrList);
            }}
          >
            {selectedDr && (
              <span>
                {!isEditBooking
                  ? getFullName(orders[selectedDr]?.dr.name)
                  : booking && booking.targetDr
                  ? getFullName(booking.targetDr.name)
                  : null}
              </span>
            )}
            <img className="arrow" src={arrowIcon} alt="" />

            {openDrList && (
              <ul>
                {isEditBooking ? (
                  <>
                    {booking && booking.targetDr && (
                      <li>{getFullName(booking.targetDr.name)}</li>
                    )}
                  </>
                ) : (
                  <>
                    <li
                      onClick={() => {
                        selectDrhandler('');
                      }}
                    ></li>
                    {Object.keys(orders).map((dr, ind) => (
                      <li
                        key={dr + ind}
                        onClick={() => {
                          selectDrhandler(dr);
                        }}
                      >
                        {getFullName(orders[dr].dr.name)}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            )}
          </div>
        </div>

        <div></div>
        <div className="d-flex align-self-end">
          <Button
            type="button"
            name={t('addBooking_page.back')}
            btnType="back"
            saveData={() => {
              history.push(routes.BOOKING);
            }}
          />
          <div className="add-scan">
            <button onClick={addCustomScanHandler}>
              <img src={addIcon} alt="addIcon" />
              <span>{t('addBooking_page.add')}</span>
            </button>
          </div>
          <Button
            type="submit"
            name={
              isEditBooking
                ? t('booking_page.save-edit')
                : t('addBooking_page.confirm')
            }
            isSubmitting={isLoading}
            saveData={saveBookingHandler}
          />
        </div>
      </div>

      {/* upcomming and edit */}
      {(isEditBooking || isUpcommingBooking) && (
        <div className="mt-5">
          <div className="d-flex">
            <p>{t('addBooking_page.current-order')}</p>
          </div>
          <div className="l-booking-data__form-container flex-grow-1 mt-4 p-4">
            <table className="table table-responsive  table-striped">
              <thead>
                <TableHead />
              </thead>
              <tbody>
                {customScans.length > 0 &&
                  customScans.map((ele, index) => (
                    <TableRow
                      key={`scan-${ele.name}-${ele.time}-${index}`}
                      removeScanHandler={hideCustomScanHandler}
                      changeScanHandler={changeCustomScanHandler}
                      searchOrderHandler={searchOrderHandler}
                      scan={ele}
                      result={result}
                      index={index}
                      timeSlots={getScanTimeSlot(ele.date, ele.time)}
                      isCustomScan={true}
                      className="custom-scan"
                    />
                  ))}
                {editScans &&
                  editScans.length > 0 &&
                  editScans.map((scan: any, index: number) => (
                    <TableRow
                      key={`${scan.code}-${scan.time}-${index}`}
                      removeScanHandler={hideEditScanHandler}
                      changeScanHandler={changeEditScanHandler}
                      searchOrderHandler={searchOrderHandler}
                      showScanHandler={showEditScanHandler}
                      scan={scan}
                      result={result}
                      index={index}
                      timeSlots={getScanTimeSlot(scan.date, scan.time)}
                      isCustomScan={false}
                      className={`custom-scan ${
                        scan.removed ? 'disable-row' : ''
                      }`}
                    />
                  ))}
              </tbody>
            </table>
          </div>{' '}
        </div>
      )}

      {/* orders */}
      {(isEditBooking && booking && !booking.targetDr) ||
      (isUpcommingBooking && scans.length === 0) ||
      (isEditBooking && scans.length === 0) ? null : (
        <div className="mt-4">
          {(isEditBooking || isUpcommingBooking) && (
            <p>{t('addBooking_page.other-orders')}</p>
          )}
          <div className="l-booking-data__form-container flex-grow-1 mt-2 p-4">
            <table className="table table-responsive  table-striped">
              <thead>
                <TableHead />
              </thead>
              <tbody>
                {(!isEditBooking || !isUpcommingBooking) &&
                  customScans.length > 0 &&
                  customScans.map((ele, index) => (
                    <TableRow
                      key={`scan-${ele.name}-${ele.time}-${index}`}
                      removeScanHandler={hideCustomScanHandler}
                      changeScanHandler={changeCustomScanHandler}
                      searchOrderHandler={searchOrderHandler}
                      scan={ele}
                      result={result}
                      index={index}
                      timeSlots={getScanTimeSlot(ele.date, ele.time)}
                      isCustomScan={true}
                      className="custom-scan"
                    />
                  ))}
                {!(booking && !booking.targetDr) &&
                  scans &&
                  scans.length > 0 &&
                  scans.map((scan: any, index: number) => (
                    <TableRow
                      key={`${scan.code}-${scan.time}-${index}`}
                      removeScanHandler={hideScanHandler}
                      changeScanHandler={changeScanHandler}
                      searchOrderHandler={searchOrderHandler}
                      showScanHandler={showScanHandler}
                      scan={scan}
                      result={result}
                      index={index}
                      timeSlots={getScanTimeSlot(scan.date, scan.time)}
                      isCustomScan={false}
                      className={`custom-scan ${
                        scan.removed ? 'disable-row' : ''
                      }`}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default LabBookingForm;
