export const getBookingFilesByType = (
  files: any[],
  booking: any,
  test: any,
  type: string
) => {
  let urls = [];
  let status = null;
  const data = files.filter(
    (f: any) =>
      f.type === type && f.test === test.code && f.booking === booking.sk
  );

  const oldUrls = test[type] || [];
  urls = Array.from(new Set([...oldUrls, ...data.map((ele: any) => ele.url)]));
  status =
    urls.length === 0
      ? null
      : data.every((f: any) => f.status === 'success')
      ? 'success'
      : data.some((f: any) => f.status === 'error')
      ? 'error'
      : data.some((f: any) => f.status === 'pending')
      ? 'uploading'
      : null;

  return {
    urls,
    status,
  };
};

export const getBookingFiles = (files: any[], booking: any, test: any) => {
  let urls = [];
  let status = null;
  const data = files.filter(
    (f: any) => f.test === test.code && f.booking === booking.sk
  );

  urls = data.map((ele: any) => ele.url);
  status =
    urls.length === 0
      ? null
      : data.every((f: any) => f.status === 'success')
      ? 'success'
      : data.some((f: any) => f.status === 'error')
      ? 'error'
      : data.some((f: any) => f.status === 'pending')
      ? 'uploading'
      : null;

  return {
    urls,
    status,
  };
};

export const getFileExtension = (url: string): string => {
  let ext: any = url.split(/[#?]/)[0];
  if (ext) ext = ext.split('.');
  if (ext) ext = ext.pop().trim();
  return ext;
};
