import React, { useState, useEffect } from 'react';
import './DatePicker.scss';

import { useFormikContext, useField } from 'formik';

import DatePickerInput, { registerLocale } from 'react-datepicker';
import el from 'date-fns/locale/ar-SA';
import { useTranslation } from 'react-i18next';

registerLocale('el', el); // register it with the name you want

type Props = {
  name: any;
  minDate?: Date;
  maxDate?: Date;
  startDate: any;
  disabled?: any;
  setDateHandler?: (date: any) => void;
};

const DatePicker: React.FC<Props> = ({
  minDate,
  maxDate,
  setDateHandler,
  startDate,
  disabled,
  ...props
}) => {
  const [initData, setDate] = useState<any>('');
  const { i18n } = useTranslation();

  useEffect(() => {
    // if (startDate) setDate(startDate);
    setDate(startDate);
  }, [startDate]);

  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const changeDateHandler = (value: any) => {
    setDate(value);
    setFieldValue(field.name, new Date(value).toISOString());
    if (setDateHandler) setDateHandler(new Date(value).toISOString());
  };

  let dateOptions: any = {};
  if (i18n.language === 'ar') {
    dateOptions = { ...dateOptions, locale: 'el' };
  }

  return (
    <div className="date-picker">
      <DatePickerInput
        selected={initData}
        onChange={changeDateHandler}
        peekNextMonth={true}
        showMonthDropdown={true}
        showYearDropdown={true}
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        minDate={minDate}
        maxDate={maxDate}
        {...dateOptions}
        onKeyDown={(e) => e.preventDefault()}
        disabled={disabled}
      />
      <div className="error d-flex align-items-center">
        {meta.touched && meta.error ? <span>{meta.error}</span> : null}
      </div>
    </div>
  );
};

export default DatePicker;
