import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dentalCategories from '../../assets/constants/dentalServices';
import { UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import clinicIconActive from '../../assets/img/clinicIcon-active.svg';
import clinicIcon from '../../assets/img/clinicIcon.svg';
import servicesIcon from '../../assets/img/settings-services-not-selected.svg';
import servicesIconActive from '../../assets/img/settings-services-selected.svg';
import specialityIcon from '../../assets/img/speciality.svg';
import specialityActiveIcon from '../../assets/img/specialityActive.svg';
import vitalSignsIcon from '../../assets/img/vitalSigns.svg';
import vitalSignsActiveIcon from '../../assets/img/vitalSignsActive.svg';
import workingDaysIcon from '../../assets/img/workingDays.svg';
import workingDaysActiveIcon from '../../assets/img/workingDaysActive.svg';
import AddEmployeesAndexpenses from '../../components/AddUnit/Employees/AddEmployees/AddEmployeesAndexpenses';
// import VitalSigns from '../../components/AddUnit/VitalSigns/VitalSigns';
import Employees from '../../components/AddUnit/Employees/Employees';
import ExaminationTypes from '../../components/AddUnit/ExaminationTypes/ExaminationTypes';
import LabsDetails from '../../components/AddUnit/LabsDetails/LabsDetails';
import UnitName from '../../components/AddUnit/UnitName/UnitName';
import WorkingDays from '../../components/AddUnit/WorkingDays/WorkingDays';
import DentalService from '../../components/Setting/DentalServices/dentalServices';
import apiService from '../../services/api';
import {
  getClinicUnitsWithUpdateCurrentUnit,
  resetCurrentUnit,
  setCurrentBooking,
  updateUnit,
} from '../../store/actions/booking.actions';
import { rootState } from '../../store/reducers';
import EventTracker from '../../utils/analytics';
import { errorToast } from '../../utils/toast';
import unitTypes from '../../utils/unitTypes';
import './AddClinic.scss';

type Action = {
  type: string;
  payload: any;
};

const segmentsData = {
  workingDays: 'workingDays',
  examinationTypes: 'examinationTypes',
  vitalSigns: 'vitalSigns',
  employees: 'employees',
  clinicName: 'clinicName',
  unitDetails: 'unitDetails',
  expenses: 'expenses',
  labs: 'labs',
  services: 'services',
  clinicDetails: 'clinicDetails',
};

const initialState = {
  [segmentsData.workingDays]: {},
  [segmentsData.examinationTypes]: {},
  networks: [],
  expenses: {},
  [segmentsData.unitDetails]: {
    onlinePrescription: {},
    offers: [],
  },
  [segmentsData.vitalSigns]: {
    temperature: true,
    bloodPressure: true,
    weight: true,
    height: true,
  },
  [segmentsData.employees]: {},
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case segmentsData.clinicName:
      return { ...state, ...action.payload };
    case segmentsData.workingDays:
      return { ...state, workingDays: action.payload };
    case segmentsData.examinationTypes:
      const { networks, ...examination } = action.payload;
      return {
        ...state,
        examinationTypes: examination,
        networks,
      };
    case segmentsData.labs:
      return { ...state, labs: action.payload };
    case segmentsData.unitDetails:
    case segmentsData.clinicDetails:
      // const {
      //   expenses = state.expenses,
      //   employees = state.employees,
      //   unitDetails = state.unitDetails,
      // } = action.payload;
      return {
        ...state,
        // expenses,
        // employees,
        // unitDetails,
        ...action.payload,
      };
    default:
      return state;
  }
};

const AddClinic: React.FC = () => {
  const { t } = useTranslation();
  const reduxDiapatch = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedSegment, setSelectedSegment] = useState(
    segmentsData.workingDays
  );

  const ownerData = useSelector((state: rootState) => state.auth.user);
  const [isDental, setIsDental] = useState(false);

  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const [isClinicNamePage, setIsClinicNamePage] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentUnitDetails = useSelector(
    (statee: rootState) => statee.booking.currentUnitDetails
  );

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.clinicName) {
      dispatch({ type: segmentsData.clinicName, payload: payload.data });
      setIsClinicNamePage(false);
    } else if (payload.type === segmentsData.workingDays) {
      dispatch({ type: segmentsData.workingDays, payload: payload.data });
      changeSegmentHandler(segmentsData.examinationTypes);
    } else if (payload.type === segmentsData.services) {
      reduxDiapatch(
        updateUnit({
          ...payload,
          id: currentUnitDetails && currentUnitDetails.pk,
        })
      );
      changeSegmentHandler(segmentsData.labs);
    } else if (payload.type === segmentsData.labs) {
      reduxDiapatch(
        updateUnit({
          ...payload,
          id: currentUnitDetails && currentUnitDetails.pk,
        })
      );
      history.push(routes.BOARD);
    } else if (payload.type === segmentsData.examinationTypes) {
      dispatch({ type: segmentsData.examinationTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.unitDetails);
    } else if (payload.type === segmentsData.unitDetails) {
      dispatch({
        type: segmentsData.unitDetails,
        payload: payload.data,
      });
      // console.log('Saving unit details to the local reducer ', payload);
    } else if (payload.type === segmentsData.clinicDetails) {
      dispatch({
        type: segmentsData.clinicDetails,
        payload: payload.data,
      });
    } else {
      dispatch({ type: segmentsData.employees, payload: payload.data });
    }
  };

  const goBackHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.employees) {
      dispatch({ type: segmentsData.employees, payload: payload.data });
      changeSegmentHandler(segmentsData.unitDetails);
    } else if (payload.type === segmentsData.unitDetails) {
      dispatch({
        type: segmentsData.unitDetails,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.examinationTypes);
    } else if (payload.type === segmentsData.examinationTypes) {
      dispatch({ type: segmentsData.examinationTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.workingDays);
    } else if (payload.type === segmentsData.services) {
      dispatch({ type: segmentsData.services, payload: payload.data });
      changeSegmentHandler(segmentsData.unitDetails);
    } else if (payload.type === segmentsData.labs) {
      dispatch({ type: segmentsData.labs, payload: payload.data });
      changeSegmentHandler(segmentsData.services);
    } else if (payload.type === segmentsData.clinicDetails) {
      dispatch({
        type: segmentsData.clinicDetails,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.labs);
    }
  };

  const checkEmpsHandler = async (emps: any[]) => {
    try {
      let isValidEmp = true;
      for (const emp of emps) {
        const data = await apiService.checkUser({ mobile: emp.mobile });
        const empData = data.data;
        if (empData.exist && empData.user.role === UserRoles.Physician) {
          errorToast(
            t('addClinic_page.emp-errMsg', {
              mobile: emp.mobile,
              unitType: unitTypes.clinic,
            })
          );
          isValidEmp = false;
          setIsSubmitting(false);
          return isValidEmp;
        }
      }
      return isValidEmp;
    } catch (error) {
      console.error('error[checkEmpsHandler]', error);
      return false;
    }
  };

  const saveClinicData = async () => {
    try {
      // setIsSubmitting(true);
      const sentState = { ...state };
      delete sentState.unitDetails;
      const payload = {
        ...sentState,
        ...state.unitDetails.onlinePrescription,
        offers: state.unitDetails.offers,
        type: unitTypes.clinic,
      };

      const clinicEmp = Object.values(payload.employees);
      const isValidEmps = await checkEmpsHandler(clinicEmp);
      if (isValidEmps) {
        const { data } = await apiService.addUnit(payload);
        const { unit } = data;
        if (unit) {
          EventTracker.sendEvent('LEAD', {
            content_category: unit.type,
            content_name: unit.name,
          });
          EventTracker.sendEvent('SUBMIT_APPLICATION', {});
          EventTracker.sendEvent('START_TRIAL', { unit: unit.pk });
          reduxDiapatch(getClinicUnitsWithUpdateCurrentUnit(unit, history));
        }
        setIsSubmitting(false);
        isDental
          ? changeSegmentHandler(segmentsData.services)
          : history.push(routes.BOARD);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error('error[saveClinicData]', error);
    }
  };
  useEffect(() => {
    reduxDiapatch(setCurrentBooking(null));
    reduxDiapatch(resetCurrentUnit());
    if (ownerData?.specialty === 'dentistry') setIsDental(true);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="addClinic-page d-flex flex-column">
      {isClinicNamePage ? (
        <UnitName
          nextStepHandler={nextStepHandler}
          currentSegment={segmentsData.clinicName}
        />
      ) : (
        <>
          <div className="segment mt-3">
            <ul>
              <li
                className={`base token ${
                  selectedSegment === segmentsData.workingDays ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.workingDays
                      ? workingDaysActiveIcon
                      : workingDaysIcon
                  }
                  alt="mobile"
                />
                {t('addClinic_page.workingDays')}
              </li>
              <li
                className={`base ${
                  selectedSegment === segmentsData.examinationTypes
                    ? 'selected'
                    : ''
                }`}
              >
                {
                  <img
                    className="d-none d-sm-block"
                    src={
                      selectedSegment === segmentsData.examinationTypes
                        ? specialityActiveIcon
                        : specialityIcon
                    }
                    alt="personal"
                  />
                }
                {t('addClinic_page.examinationTypes')}
              </li>
              {!isDental && (
                <li
                  className={`base ${
                    selectedSegment === segmentsData.unitDetails
                      ? 'selected'
                      : ''
                  }`}
                >
                  {
                    <>
                      <img
                        className="d-none d-sm-block"
                        src={
                          selectedSegment === segmentsData.unitDetails
                            ? clinicIconActive
                            : clinicIcon
                        }
                        alt="check"
                      />
                      {t('addClinic_page.unitDetails')}
                    </>
                  }
                </li>
              )}
              {isDental && (
                <li
                  className={`base ${
                    selectedSegment === segmentsData.clinicDetails ||
                    selectedSegment === segmentsData.unitDetails
                      ? 'selected'
                      : ''
                  }`}
                >
                  {
                    <>
                      <img
                        className="d-none d-sm-block"
                        src={
                          selectedSegment === segmentsData.clinicDetails ||
                          selectedSegment === segmentsData.unitDetails
                            ? clinicIconActive
                            : clinicIcon
                        }
                        alt="check"
                      />
                      {t('addClinic_page.clinicDetails')}
                    </>
                  }
                </li>
              )}

              {isDental && (
                <li
                  className={`base ${
                    selectedSegment === segmentsData.services ? 'selected' : ''
                  }`}
                >
                  {
                    <>
                      <img
                        className="d-none d-sm-block"
                        src={
                          selectedSegment === segmentsData.services
                            ? servicesIconActive
                            : servicesIcon
                        }
                        alt="check"
                      />
                      {t('addClinic_page.services')}
                    </>
                  }
                </li>
              )}

              {isDental && (
                <li
                  className={`base ${
                    selectedSegment === segmentsData.labs ? 'selected' : ''
                  }`}
                >
                  {
                    <>
                      <img
                        className="d-none d-sm-block"
                        src={
                          selectedSegment === segmentsData.labs
                            ? vitalSignsActiveIcon
                            : vitalSignsIcon
                        }
                        alt="check"
                      />
                      {t('addClinic_page.lab')}
                    </>
                  }
                </li>
              )}
            </ul>
          </div>
          <div className="addClinic-page__main d-flex flex-column-reverse flex-sm-row mt-3 p-3">
            {selectedSegment === segmentsData.workingDays ? (
              <WorkingDays
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.workingDays}
                initialState={state[segmentsData.workingDays]}
              />
            ) : selectedSegment === segmentsData.examinationTypes ? (
              <ExaminationTypes
                goBackHandler={goBackHandler}
                initialState={state[segmentsData.examinationTypes]}
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.examinationTypes}
                isSettingPage={false}
              />
            ) : selectedSegment === segmentsData.unitDetails ? (
              <AddEmployeesAndexpenses
                isSubmitting={isSubmitting}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.unitDetails}
                saveClinicData={saveClinicData}
                initialState={{
                  employees: state[segmentsData.employees],
                  expenses: state[segmentsData.expenses],
                  unitDetails: {
                    ...state[segmentsData.unitDetails],
                    examinationTypes: {
                      ...state[segmentsData.examinationTypes],
                    },
                  },
                }}
                unitType={unitTypes.clinic}
              />
            ) : selectedSegment === segmentsData.labs ? (
              <LabsDetails
                goBackHandler={goBackHandler}
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.labs}
                initialState={state[segmentsData.labs]}
                unit={null}
                isSettingPage={false}
                isSubmitting={isSubmitting}
              />
            ) : selectedSegment === segmentsData.services ? (
              <DentalService
                isSettingPage={false}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.services}
                initialState={dentalCategories}
              />
            ) : selectedSegment === segmentsData.clinicDetails ? (
              <AddEmployeesAndexpenses
                isSubmitting={isSubmitting}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.clinicDetails}
                saveClinicData={saveClinicData}
                initialState={{
                  employees: state[segmentsData.employees],
                  expenses: state[segmentsData.expenses],
                  unitDetails: {
                    ...state[segmentsData.unitDetails],
                    examinationTypes: {
                      ...state[segmentsData.examinationTypes],
                    },
                  },
                }}
                unitType={unitTypes.clinic}
              />
            ) : (
              <Employees
                isSubmitting={isSubmitting}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.employees}
                saveClinicData={saveClinicData}
                initialState={state[segmentsData.employees]}
                unitType={unitTypes.clinic}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddClinic;
