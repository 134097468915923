import React, { useState } from 'react';
import './TokenForm.scss';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import Button from '../UI/Form/Button/Button';
import backArrow from '../../assets/img/back.svg';
import { useSelector } from 'react-redux';
import { rootState } from '../../store/reducers';
import parseArabic from '../../utils/parseArabic';

type Props = {
  checkTokenhandler: (payload: any) => Promise<void>;
  resendTokenHandler: () => Promise<void>;
  mobile: string;
  shouldResend?: boolean;
  isSubmitting?: boolean;
  name?: string;
  goBack: () => void;
};

const TokenForm: React.FC<Props> = ({
  checkTokenhandler,
  resendTokenHandler,
  mobile,
  shouldResend,
  isSubmitting,
  name,
  goBack,
}) => {
  const { t } = useTranslation();
  const isLoading = useSelector((state: rootState) => state.auth.isLoading);
  const [token, setToken] = useState('');
  const handleOtp = (otp: any) => {
    const value = parseArabic(otp).replace(/\D|-/g, '');
    // console.log('value', value);
    setToken(value);
  };

  const submitHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter' || e.keyCode !== 13 || token.length !== 4) return;
    checkTokenhandler({ token });
  };

  return (
    <div className="otp-page-container d-flex justify-content-center flex-column p-5 ">
      <div className="name d-flex justify-content-between">
        <p>{name ? name : t('pages.login')}</p>
      </div>
      <div className="d-flex flex-column mt-0 mt-sm-5">
        <div className="otp-page-form align-items-center d-flex flex-column p-3 p-sm-4">
          <div className="otp-msg d-flex flex-column text-center">
            <h6>{t('otp_page.msg')}</h6>
            <h6 className="mobile mt-2">{mobile}</h6>
          </div>
          <div className="mt-4 w-100" onKeyDown={submitHandler}>
            <OtpInput
              onChange={handleOtp}
              inputStyle={{
                width: '4rem',
                height: '4rem',
                margin: '0 5px',
                borderRadius: '5px',
                fontFamily: 'inherit',
                border: '1px solid var(--color-main)',
                fontSize: '1.3rem',
              }}
              value={token}
              containerStyle={{
                direction: 'ltr',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              numInputs={4}
              placeholder="4444"
              isInputNum
              shouldAutoFocus={true}
              hasErrored={true}
            />
          </div>
          <div className="mt-5 w-100">
            <Button
              type="submit"
              name={t('login_page.next')}
              isSubmitting={isLoading || isSubmitting}
              saveData={() => {
                checkTokenhandler({ token });
              }}
            />
          </div>
          <div className="text-center mt-4">
            <button
              className="link-button"
              disabled={shouldResend}
              onClick={resendTokenHandler}
            >
              {t('otp_page.resend')}
            </button>
          </div>
        </div>
      </div>
      <div
        className="back align-self-start mt-2"
        onClick={() => {
          goBack();
        }}
      >
        <img src={backArrow} alt="back" />
        <span>{t('otp_page.back')}</span>
      </div>
    </div>
  );
};

export default TokenForm;
