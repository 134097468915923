import React from 'react';
import './CheckBox.scss';
import { useField, useFormikContext } from 'formik';
import parseArabic from '../../../../utils/parseArabic';

type InputProps = {
  iconClick?: () => void;
  inputChange?: (value: string) => void;
  name: string;
  placeholder?: string;
  icon?: string;
  isIcon?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChangeValue?: (value: string, fieldName: string) => void;
  openModal?: () => void;
};

const CheckBox: React.FC<InputProps> = ({
  disabled,
  label,
  inputChange,
  onChangeValue,
  openModal,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const onInputChange = (value: string) => {
    if (inputChange) {
      inputChange(value);
    }
  };
  return (
    <div className="input-container">
      <div className="form-check terms">
        <input
          {...props}
          {...field}
          className="form-check-input"
          type="checkbox"
          onKeyUp={(e: any) => {
            const value = parseArabic(e.target.value);
            // console.log('value', value);
            setFieldValue(field.name, value);
            if (onChangeValue) onChangeValue(value, field.name);
            onInputChange(value);
          }}
          disabled={disabled}
        />

        <label className="form-check-label" onClick={openModal}>
          {label}
        </label>
      </div>

      <div className="error ">
        {meta.touched && meta.error ? (
          <span className="error">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

export default CheckBox;
