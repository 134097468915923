import './ToothSelection.scss';

import React, { useState } from 'react';
import { teethUpper, teethLower } from './teethList';
import { childTeethUpper, childTeethLower } from './childTeethList';
type props = {
  isActive: boolean;
  ageHandler: (age: string) => void;
  teethHandler: (tooth: number) => void;
  ageType: string;
  workingTeeth: number[];
  removeSelectedTeeth: () => void;
  toothSelectionBehavior: string;
  archesSelection: (teeth: number[]) => void;
};
const ToothSelection: React.FC<props> = ({
  isActive,
  ageHandler,
  teethHandler,
  ageType,
  workingTeeth,
  removeSelectedTeeth,
  toothSelectionBehavior,
  archesSelection,
}) => {
  const range = (start: number, last: number) =>
    Array(Math.ceil(last - start))
      .fill(start)
      .map((num, idx) => num + idx);

  const isUpperArch = (teethId: number) => {
    if (ageType == 'adult') {
      if (range(11, 19).includes(teethId) || range(21, 29).includes(teethId))
        return true;
      return false;
    } else {
      if (range(1, 11).includes(teethId)) return true;
      return false;
    }
  };
  const isConnected = (
    teethId: number,
    workingTeeth: number[],
    type: string
  ) => {
    for (const teeth of workingTeeth) {
      if (teeth == teethId + 1 || teeth == teethId - 1) return true;
    }
    if (isUpperArch(teethId)) {
      if (
        (workingTeeth.includes(11) && teethId == 21) ||
        (workingTeeth.includes(21) && teethId == 11)
      )
        return true;
    } else {
      if (
        (workingTeeth.includes(41) && teethId == 31) ||
        (workingTeeth.includes(31) && teethId == 41)
      )
        return true;
    }
    return false;
  };

  /* const canBeRemoved = (teethID: number, workingTeeth: number[]) => {
     let leftSideExists: boolean = false
     let rightSideExists: boolean = false
 
     for (let teeth of workingTeeth) {
       if (teeth > teethID) rightSideExists = true
       if (teeth < teethID) leftSideExists = true
     }
     if (teethID == 11 && workingTeeth.includes(12) && workingTeeth.includes(21)) return false
     if (teethID == 31 && workingTeeth.includes(32) && workingTeeth.includes(41)) return false
     return !(leftSideExists && rightSideExists)
   }
 */
  const canBeRemoved = (
    teethID: number,
    workingTeeth: number[],
    type: string
  ) => {
    let leftSideExists: boolean = false;
    let rightSideExists: boolean = false;
    const indexedTeeth = [...workingTeeth];
    const offset: number = type == 'upper' ? 28 : 48;
    const offset2: number = type == 'upper' ? 2 : 22;
    if (ageType == 'adult') {
      if (type == 'upper') {
        teethID =
          teethID >= 21
            ? teethID - offset + 2 * Math.abs(teethID - offset) + 1
            : teethID - offset2;
        for (const teeth in indexedTeeth) {
          if (indexedTeeth[teeth] >= 21) {
            indexedTeeth[teeth] =
              indexedTeeth[teeth] -
              offset +
              2 * Math.abs(indexedTeeth[teeth] - offset) +
              1;
          } else {
            indexedTeeth[teeth] = indexedTeeth[teeth] - offset2;
          }
        }
      } else {
        teethID =
          teethID <= 48 && teethID >= 41
            ? teethID - offset + 2 * Math.abs(teethID - offset) + 1
            : teethID - offset2;
        for (const teeth in indexedTeeth) {
          if (indexedTeeth[teeth] <= 48 && indexedTeeth[teeth] >= 41) {
            indexedTeeth[teeth] =
              indexedTeeth[teeth] -
              offset +
              2 * Math.abs(indexedTeeth[teeth] - offset) +
              1;
          } else {
            indexedTeeth[teeth] = indexedTeeth[teeth] - offset2;
          }
        }
      }
    }
    for (const teeth of indexedTeeth) {
      if (teeth > teethID) rightSideExists = true;
      if (teeth < teethID) leftSideExists = true;
    }
    return !(leftSideExists && rightSideExists);
  };
  const selectTeethHandler = (teethID: any, type: string) => {
    if (toothSelectionBehavior == 'TOOTH') return teethHandler(Number(teethID));

    if (toothSelectionBehavior == 'MULTIPLE_TEETH') {
      if (workingTeeth.length == 0) return teethHandler(Number(teethID));
      if (workingTeeth.includes(teethID)) {
        if (canBeRemoved(teethID, workingTeeth, type))
          return teethHandler(Number(teethID));
        else return;
      } else {
        if (
          (isUpperArch(workingTeeth[0]) && isUpperArch(teethID)) ||
          (!isUpperArch(workingTeeth[0]) && !isUpperArch(teethID))
        ) {
          if (isConnected(teethID, workingTeeth, type))
            return teethHandler(Number(teethID));
        }
      }
    }

    if (toothSelectionBehavior == 'ONE_ARCH') {
      if (ageType == 'adult')
        return type == 'upper'
          ? archesSelection(teethUpper.map((t) => t.id))
          : archesSelection(teethLower.map((t) => t.id));

      type == 'upper'
        ? archesSelection(childTeethUpper.map((t) => t.id))
        : archesSelection(childTeethLower.map((t) => t.id));
    }
    if (toothSelectionBehavior == 'BOTH_ARCH') {
      if (workingTeeth.length > 0) return archesSelection([]);
      if (ageType == 'adult')
        return archesSelection([
          ...teethUpper.map((t) => t.id),
          ...teethLower.map((t) => t.id),
        ]);
      archesSelection([
        ...childTeethUpper.map((t) => t.id),
        ...childTeethLower.map((t) => t.id),
      ]);
    }
  };
  return (
    <div className="select-teeth-wrapper">
      <h1 className={isActive ? 'active-header' : undefined}>
        Select a teeth to apply
      </h1>
      <div className="age-selection-wrapper">
        <div
          onClick={() => {
            ageHandler('adult');
            removeSelectedTeeth();
          }}
          className={ageType === 'adult' ? 'selected-age' : undefined}
        >
          Adult
        </div>
        <div
          onClick={() => {
            ageHandler('pedo');
            removeSelectedTeeth();
          }}
          className={ageType === 'pedo' ? 'selected-age' : undefined}
        >
          Pedo
        </div>
      </div>
      {ageType == 'adult' && (
        <div>
          <div className="teeth-wrapper">
            <div className="set-wrapper child-teeth-pattern-wrapper">
              {teethUpper.map((teeth: any) => {
                const TeethImg: any = teeth.teethImg;
                return (
                  <div key={teeth.id}>
                    <span>
                      <h1
                        className="teeth-number"
                        style={{
                          position: 'absolute',
                          left: teeth.titlePosition[1],
                          top: teeth.titlePosition[0],
                          color: workingTeeth.includes(teeth.id)
                            ? '#0090ff'
                            : 'grey',
                        }}
                      >
                        {teeth.id}
                      </h1>
                      <TeethImg
                        style={{
                          position: 'absolute',
                          left: teeth.position[1],
                          top: teeth.position[0],
                        }}
                        width={teeth.size[1]}
                        height={teeth.size[0]}
                        onClick={() => selectTeethHandler(teeth.id, 'upper')}
                        className={
                          workingTeeth.includes(teeth.id)
                            ? 'selected-tooth '
                            : 'normal-tooth'
                        }
                      />
                    </span>
                  </div>
                );
              })}
              {teethLower.map((teeth: any) => {
                const TeethImg: any = teeth.teethImg;
                return (
                  <div key={teeth.id}>
                    <span>
                      <h1
                        className="teeth-number"
                        style={{
                          position: 'absolute',
                          left: teeth.titlePosition[1],
                          top: teeth.titlePosition[0],
                          color: workingTeeth.includes(teeth.id)
                            ? '#0090ff'
                            : 'grey',
                        }}
                      >
                        {teeth.id}
                      </h1>
                      <TeethImg
                        style={{
                          position: 'absolute',
                          left: teeth.position[1],
                          top: teeth.position[0],
                        }}
                        width={teeth.size[1]}
                        height={teeth.size[0]}
                        onClick={() => selectTeethHandler(teeth.id, 'lower')}
                        className={
                          workingTeeth.includes(teeth.id)
                            ? 'selected-tooth '
                            : 'normal-tooth'
                        }
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {ageType == 'pedo' && (
        <div>
          <div className="teeth-wrapper">
            <div className="set-wrapper child-teeth-pattern-wrapper">
              {childTeethUpper.map((teeth: any) => {
                const TeethImg: any = teeth.teethImg;
                return (
                  <div key={teeth.id}>
                    <span>
                      <h1
                        className="teeth-number"
                        style={{
                          position: 'absolute',
                          left: teeth.titlePosition[1],
                          top: teeth.titlePosition[0],
                          color: workingTeeth.includes(teeth.id)
                            ? '#0090ff'
                            : 'grey',
                        }}
                      >
                        {teeth.id}
                      </h1>
                      <TeethImg
                        style={{
                          position: 'absolute',
                          left: teeth.position[1],
                          top: teeth.position[0],
                        }}
                        width={teeth.size[1]}
                        height={teeth.size[0]}
                        onClick={() => selectTeethHandler(teeth.id, 'upper')}
                        className={
                          workingTeeth.includes(teeth.id)
                            ? 'selected-tooth '
                            : 'normal-tooth-child'
                        }
                      />
                    </span>
                  </div>
                );
              })}
              {childTeethLower.map((teeth: any) => {
                const TeethImg: any = teeth.teethImg;
                return (
                  <div key={teeth.id}>
                    <span>
                      <h1
                        className="teeth-number"
                        style={{
                          position: 'absolute',
                          left: teeth.titlePosition[1],
                          top: teeth.titlePosition[0],
                          color: workingTeeth.includes(teeth.id)
                            ? '#0090ff'
                            : 'grey',
                        }}
                      >
                        {teeth.id}
                      </h1>
                      <TeethImg
                        style={{
                          position: 'absolute',
                          left: teeth.position[1],
                          top: teeth.position[0],
                        }}
                        width={teeth.size[1]}
                        height={teeth.size[0]}
                        onClick={() => selectTeethHandler(teeth.id, 'lower')}
                        className={
                          workingTeeth.includes(teeth.id)
                            ? 'selected-tooth '
                            : 'normal-tooth-child'
                        }
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToothSelection;
