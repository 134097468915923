const extractDrugUnit = (name: string) => {
  if (name.endsWith('tab.')) return 'bills';
  else if (name.endsWith('tabs.')) return 'bills';
  else if (name.endsWith('tab')) return 'bills';
  else if (name.endsWith('tabs')) return 'bills';
  else if (name.endsWith('sachets.')) return 'bills';
  else if (name.endsWith('sachets')) return 'bills';
  else if (name.endsWith('sachet.')) return 'bills';
  else if (name.endsWith('sachet')) return 'bills';
  else if (name.endsWith('cap')) return 'Caps';
  else if (name.endsWith('caps')) return 'Caps';
  else if (name.endsWith('cap.')) return 'Caps';
  else if (name.endsWith('caps.')) return 'Caps';
  else if (name.includes('syrup.')) return 'Cm';
  else if (name.includes('syrup')) return 'Cm';
  else if (name.includes('syrup.')) return 'Cm';
  else if (name.includes('syrup')) return 'Cm';
  else if (name.includes('cream')) return 'times';
  else if (name.includes('drops')) return 'drops';
  else if (name.includes('vial')) return 'amp';
  else return 'unit';
};

export const getDrugsGroupedBySciName = (hits: any = []) => {
  const groups: any = {};
  hits = hits.sort((h1: any, h2: any) => h2._score - h1._score);
  for (const hit of hits) {
    if (!groups[hit._source.sciName]) groups[hit._source.sciName] = [];
    groups[hit._source.sciName].push({
      tradeName: hit._source.tradeName,
      sciName: hit._source.sciName,
      unit: extractDrugUnit(hit._source.tradeName),
      quantity: 1,
      every: '1 a day',
      duration: 1,
      durationExt: 'weeks',
    });
  }
  return groups;
};
