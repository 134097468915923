import React, { useState, useEffect } from 'react';
import './dentalServices.scss';
import dentalCategories from '../../../assets/constants/dentalServices';
import DentalServiceModal from './dentalServiceModal/dentalServiceModal';
import Button from '../../UI/Form/Button/Button';
import backArrow from '../../../assets/img/backArrow.svg';
import { useTranslation } from 'react-i18next';
import { ClinicData } from '../../../types';
import { SubscriptionStatus } from '../../Subscription';
import { errorToast } from '../../../utils/toast';
type prop = {
  nextStepHandler: (payload: { type: string; data: any }) => Promise<void>;
  goBackHandler: (payload: { type: string; data: any }) => void;
  isSettingPage?: boolean;
  unit?: ClinicData | null;
  isSubmitting?: boolean;
  currentSegment: string;
  initialState: any;
};
const DentalService: React.FC<prop> = ({
  isSettingPage = true,
  nextStepHandler,
  goBackHandler,
  isSubmitting,
  currentSegment,
  unit,
  initialState,
}) => {
  const [categories, setCategories] = useState<any>([]);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [isAddMode, setIsAddMode] = useState<boolean>(true);
  const [selectedService, setSelectedService] = useState<any>({});
  const [priceForPredefinedServices, setPriceForPredefinedServices] = useState(
    0
  );
  // const [selectedSubField, setSelectedSubField] = useState<any>({})
  const { t } = useTranslation();
  useEffect(() => {
    const incomingCategories = [];
    for (const categoryKey in initialState) {
      incomingCategories.push(initialState[categoryKey]);
    }
    setCategories(incomingCategories);
    /*dentalCategories.forEach(
      (_: any, i: number) => (dentalCategories[i].isExpanded = false)
    );
    categoryHandler(dentalCategories[0]);*/
  }, []);
  useEffect(() => {
    if (priceForPredefinedServices >= 0) updatePriceForPredefinedService();
  }, [priceForPredefinedServices]);
  const categoryHandler = (c: any) => {
    if (c.type == selectedCategory.type) {
      // selectedCategory.isExpanded = false;
      setSelectedCategory({});
      //      setSelectedSubField({})
    } else {
      setSelectedCategory(c);
      //      setSelectedSubField({})
      /*categories.map((category: any) => {
        category.type == c.type
          ? (category.isExpanded = true)
          : (category.isExpanded = false);
      });*/
    }
    setCategories([...categories]);
  };

  const goBack = () => {
    goBackHandler({ type: 'services', data: { name: 'somedata' } });
  };
  const addService = (newService: {}) => {
    selectedCategory.services.push(newService);
    setCategories([...categories]);
    setIsServiceModalOpen(false);
  };
  const editService = (editedService: any) => {
    for (const i in selectedCategory.services) {
      if (selectedCategory.services[i].type == editedService.type) {
        selectedCategory.services[i] = editedService;
      }
    }
    setCategories([...categories]);
    setIsServiceModalOpen(false);
  };
  const deleteService = (type: String) => {
    // console.log(selectedCategory.services);
    selectedCategory.services = selectedCategory.services.filter(
      (s: any) => s.type != type
    );
    setCategories([...categories]);
  };
  const saveData = async () => {
    nextStepHandler({ type: currentSegment, data: categories });
  };
  const updatePriceForPredefinedService = () => {
    if (
      priceForPredefinedServices < 0 ||
      isNaN(Number(priceForPredefinedServices))
    ) {
      selectedService.price = Number(0);
      return;
    }
    selectedService.price = Number(priceForPredefinedServices);
    // editService(selectedService);
    setPriceForPredefinedServices(-1);
  };
  return (
    <div className="services-page-container">
      {isSettingPage && (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              isSubmitting={isSubmitting}
              saveData={saveData}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBack}
            />
          </div>
        </div>
      )}
      <div className="dental-services-page-container">
        <DentalServiceModal
          isOpen={isServiceModalOpen}
          closeModal={() => setIsServiceModalOpen(false)}
          category={selectedCategory}
          addService={addService}
          isAddMode={isAddMode}
          selectedService={selectedService}
          editService={editService}
        />

        <div className="dental-services-categories-section">
          <h1>Categories</h1>
          {categories.map((c: any) => (
            <div key={c.type}>
              <p
                style={{
                  color:
                    selectedCategory.type == c.type ? '#0090FF' : '#646464',
                }}
                className="categoryField"
                onClick={() => categoryHandler(c)}
              >
                {c.name}
              </p>
            </div>
          ))}
        </div>

        <div className="dental-services-services-section">
          <table>
            <thead>
              <tr>
                <th>Service Name</th>
                <th className="table-price-column">Price</th>
                <th>Steps</th>
                <th className="action-btns-cell">
                  <div className="table-btn-cell">
                    {Object.keys(selectedCategory).length > 0 && (
                      <div
                        className="add-service-btn"
                        onClick={() => {
                          setIsAddMode(true);
                          setIsServiceModalOpen(true);
                        }}
                      >
                        Add
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(selectedCategory).length > 0 &&
                selectedCategory.services.map((s: any) => (
                  <tr key={s.type}>
                    <td>{s.name}</td>
                    <td
                      className="table-price-column"
                      onClick={() => {
                        setSelectedService(s);
                      }}
                      style={{ cursor: s.isCustom ? 'default' : 'pointer' }}
                    >
                      {s.isCustom || selectedService?.type != s.type ? (
                        s.price.toFixed(1)
                      ) : (
                        <input
                          className="update-price-input"
                          type="text"
                          placeholder={s.price.toFixed(1)}
                          value={
                            priceForPredefinedServices > 0
                              ? priceForPredefinedServices
                              : s.price
                          }
                          onChange={(e: any) => {
                            if (
                              !isNaN(e.target.value) &&
                              Number(e.target.value) >= 0
                            ) {
                              setPriceForPredefinedServices(e.target.value);
                            }
                          }}
                        />
                      )}
                    </td>
                    <td>{s.steps && s.steps.join(',')}</td>
                    <td className="action-btns-cell">
                      <div className="service-section-body-actions">
                        {s.isCustom && (
                          <div
                            className="delete-btn"
                            onClick={() => deleteService(s.type)}
                          >
                            Delete
                          </div>
                        )}
                        {s.isCustom && (
                          <div
                            className="edit-btn"
                            onClick={() => {
                              setSelectedService(s);
                              setIsAddMode(false);
                              // console.log(s)
                              setIsServiceModalOpen(true);
                            }}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {!isSettingPage && (
        <div className="addClinc-btn-container">
          <Button
            saveData={() => {
              saveData();
            }}
            name={t('working_days_page.next')}
          />
          <div
            className="back go-back align-self-end"
            onClick={() => {
              goBack();
            }}
          >
            <span>{t('otp_page.back')}</span>
            <img src={backArrow} alt="back" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DentalService;
