import React from 'react';
import './PreviousBooking.scss';

import { useTranslation } from 'react-i18next';
import { formatTimeAmPm, formatDateWithDay } from '../../../utils/moment';
import { Booking, ClinicData } from '../../../types';

import calenderIcon from '../../../assets/img/calender.svg';
import clockIcon from '../../../assets/img/clock.svg';
import bookingIcon from '../../../assets/img/medical-degree.svg';
import editIcon from '../../../assets/img/edit.svg';
import bookingStatus from '../../../assets/constants/bookingStatus';
import days from '../../../assets/constants/days';

type Props = {
  previousBookings: Booking[];
  setEditBookingHanlder: (booking: Booking) => void;
  getCustomTypeName: (key: string) => string;
  clinic: ClinicData | null;
};
const PreviousBooking: React.FC<Props> = ({
  previousBookings,
  setEditBookingHanlder,
  getCustomTypeName,
  clinic,
}) => {
  const { t, i18n } = useTranslation();

  const filterBookingsHandler = (booking: Booking) => {
    return (
      ![bookingStatus.completed, bookingStatus.canceled].includes(
        booking.status
      ) && +booking.date >= new Date().setHours(0, 0, 0, 0)
    );
  };

  return (
    <div className="previous-booking d-flex flex-column">
      <p> {t('addBooking_page.previous-booking')}</p>
      <div className=" previous-booking-container align-items-start d-flex flex-wrap">
        {previousBookings.map((booking, index) => (
          <div
            key={`booking-${index}`}
            className={`previous-booking-container-details ${
              filterBookingsHandler(booking) ? 'comming' : 'prev'
            }`}
          >
            <div className="previous-booking-item align-items-center d-flex">
              <img src={bookingIcon} alt="mobile icon" />
              <h6 className="align-self-end">{`${booking.cost} ${t(
                'examinationAndPrices_page.egp'
              )}`}</h6>
            </div>
            {booking.type && (
              <div className="previous-booking-item align-items-center d-flex">
                <img src={bookingIcon} alt="mobile icon" />
                <h6 className="align-self-end">
                  {getCustomTypeName(booking.type)}
                </h6>
              </div>
            )}
            <div className="previous-booking-item align-items-center d-flex">
              <img src={calenderIcon} alt="mobile icon" />
              <h6 className="align-self-end">
                {`${
                  days[
                    formatDateWithDay(new Date(booking.date))
                      .split(' ')[1]
                      .toLocaleLowerCase()
                  ][i18n.language]
                } ${formatDateWithDay(new Date(booking.date)).split(' ')[0]}`}
              </h6>
            </div>
            <div className="previous-booking-item d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img src={clockIcon} alt="mobile icon" />
                <h6 className="align-self-end">
                  {`${formatTimeAmPm(new Date(booking.date)).split(' ')[0]} ${t(
                    'booking_page.' +
                      formatTimeAmPm(new Date(booking.date)).split(' ')[1]
                  )}`}
                </h6>
              </div>
              {filterBookingsHandler(booking) && (
                <div
                  className="edit d-flex"
                  onClick={() => {
                    setEditBookingHanlder(booking);
                  }}
                >
                  <img src={editIcon} alt="" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviousBooking;
