import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import closeIcon from '../../assets/img/close2.svg';
import SelectService from './SelectService/SelectService';
import ToothSelection from './ToothSelection/ToothSelection';
import './DentalServiceModal.scss';
import ServiceDetails from './ServiceDetails/ServiceDetails';
import {
  singleArchSelection,
  bothArchesSelection,
  connectedTeethSelection,
} from '../../assets/constants/teethSelection';
import dentalCategories from '../../assets/constants/dentalServices';
import { rootState } from '../../store/reducers';
import { useSelector } from 'react-redux';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '1.5rem',
    transform: 'translate(-50%, -50%)',
    minWidth: '90%',
    maxWidth: '90%',
    height: '90%',
  },
};

type props = {
  isOpen: boolean;
  closeModal: () => void;
  addOperation: any;

  // DO NOT FORGET TO PASS THE ONCLOSE HANDLER HERE !
};
const DentalServiceModal: React.FC<props> = ({
  isOpen,
  closeModal,
  addOperation,
}) => {
  const [currentService, setCurrentService] = useState('');
  const [categories, setCategories] = useState<any>([]);
  const [activeSection, setActiveSection] = useState(1);
  const [ageType, setAgeType] = useState('adult');
  const [price, setPrice] = useState(0);
  const [workingTeeth, setWorkingTeeth] = useState<number[]>([]);
  const [discount, setDiscount] = useState(0);
  const [isDiscountPercentage, setIsDiscountPercentage] = useState(true);
  const [teethSelectionBehavior, setTeethSelectionBehavior] = useState<string>(
    'TOOTH'
  );
  const currentUnitDetails: any = useSelector(
    (statee: rootState) => statee.booking.currentUnitDetails
  );
  useEffect(() => {
    const incomingCategories = [];
    for (const categoryKey in currentUnitDetails?.services) {
      incomingCategories.push(currentUnitDetails?.services[categoryKey]);
    }
    setCategories(incomingCategories);
  }, []);
  const discountHandler = (e: any) => {
    if (isDiscountPercentage) {
      e.target.value >= 0 && e.target.value <= 100
        ? setDiscount(e.target.value)
        : setDiscount(0);
    } else {
      e.target.value >= 0 && e.target.value <= price
        ? setDiscount(e.target.value)
        : setDiscount(0);
    }
  };
  const ageHandler = (age: string) => {
    setAgeType(age);
  };
  const removeSelectedTeeth = () => {
    setWorkingTeeth([]);
  };
  const toggleDiscountOption = () => {
    setIsDiscountPercentage((prev) => !prev);
  };
  const teethHandler = (tooth: number) => {
    if (!currentService) return;
    // console.log(tooth)
    setWorkingTeeth((state) => {
      const index = state.indexOf(tooth);
      if (index === -1) {
        // console.log('adding tooth to selection', tooth, state);
        return [...state, tooth];
      } else {
        // console.log('removing tooth from selection');
        const tempState = state.filter((ele, eleIndex) => {
          return index !== eleIndex;
        });

        return tempState;
      }
    });
  };
  const archesSelection = (teeth: number[]) => {
    if (!currentService) return;
    setWorkingTeeth(teeth);
  };
  const ServiceSetter = (service: string) => {
    // console.log('Current Service is set to be:', service);

    /*if (singleArchSelection.includes(service)) {
      setTeethSelectionBehavior('SINGLE_ARCH');
    } else if (bothArchesSelection.includes(service)) {
      setTeethSelectionBehavior('BOTH_ARCHES');
    } else if (connectedTeethSelection.includes(service)) {
      setTeethSelectionBehavior('CONNECTED_TEETH');
    } else {
      setTeethSelectionBehavior('TOOTH');
    }*/
    //
    for (const category of categories) {
      // console.log(category)
      for (const s of category.services) {
        if (s.type == service) {
          // console.log(s.selectionOption);
          if (!s.selectionOption) setTeethSelectionBehavior('TOOTH');
          else {
            setTeethSelectionBehavior(s.selectionOption);
          }
        }
      }
    }
    removeSelectedTeeth();
    setCurrentService(service);
  };
  const setOperationPrice = (price: number) => {
    setPrice(price);
  };
  const onModalClose = () => {
    // console.log('Modal Will close');
    /** PLEASE READ IMPORTANT NOTE
     * Containr that uses this modal should pass it's open state
     * and onClose Handler which should take all the need state as inpute to save it on the container or redux
     * eg.    onModalClose(price,workingTeeth,ageType,currentService)
     *
     * current service now is just a string you can pass the whole object if need to be done from coponent  './SelectService/SelectService' instead of passing
     *
     * service.type pass service that will send the whole object   and don't forget to change it's type here in line 23 to be an object instead of string
     *
     * ALSO
     *
     * DON"T FORGET TO SET THE OPEN FLAG TO FALSE ON CLOSE !!!!
     *
     */
  };

  const resetState = () => {
    setCurrentService('');
    setActiveSection(1);
    setAgeType('adult');
    setPrice(0);
    setWorkingTeeth([]);
    setDiscount(0);
    setIsDiscountPercentage(true);
    setTeethSelectionBehavior('TOOTH');
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="Select Service"
      style={customStyles}
    >
      <div className="modal-content-wrapper">
        <div
          className="close-img"
          onClick={() => {
            closeModal();
            resetState();
          }}
        >
          <img src={closeIcon} alt="" />
        </div>
        <div
          className="component-wrapper"
          onClick={() => {
            setActiveSection(1);
          }}
        >
          <SelectService
            setCurrentService={ServiceSetter}
            currentService={currentService}
            isActive={activeSection === 1 ? true : false}
            services={categories}
            setOperationPrice={setOperationPrice}
          />
        </div>
        <div
          className="component-wrapper"
          onClick={() => {
            setActiveSection(2);
          }}
        >
          <ToothSelection
            isActive={activeSection === 2 ? true : false}
            ageHandler={ageHandler}
            teethHandler={teethHandler}
            ageType={ageType}
            workingTeeth={workingTeeth}
            removeSelectedTeeth={removeSelectedTeeth}
            toothSelectionBehavior={teethSelectionBehavior}
            archesSelection={archesSelection}
          />
        </div>
        <div
          className="component-wrapper"
          onClick={() => {
            setActiveSection(3);
          }}
        >
          <ServiceDetails
            isActive={activeSection === 3 ? true : false}
            price={price}
            discountHandler={discountHandler}
            isDiscountPercentage={isDiscountPercentage}
            discount={discount}
            toggleDiscountOption={toggleDiscountOption}
          />
        </div>
        <div className="add-operation">
          <div
            onClick={() => {
              addOperation(
                workingTeeth,
                ageType,
                currentService,
                isDiscountPercentage
                  ? price - (price * discount) / 100
                  : price - discount
              );
              resetState();
            }}
          >
            Add
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DentalServiceModal;
