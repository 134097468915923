import * as Yup from 'yup';
import { arabicRegex } from '../../../assets/constants/regexValues';

const bookingFormValidationSchema = (t: any) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(arabicRegex, t('booking_page.name-arabic'))
      .min(2, t('booking_page.name-valid'))
      .required(t('booking_page.required')),
    lastName: Yup.string()
      .trim()
      .matches(arabicRegex, t('booking_page.name-arabic'))
      .min(2, t('booking_page.name-valid'))
      .required(t('booking_page.required')),
    medicalDegree: Yup.string().required(t('booking_page.required')),
    specialty: Yup.string().required(t('booking_page.required')),
    licenseNumber: Yup.string().required(t('booking_page.required')),
  });
};

export default bookingFormValidationSchema;
