import React, { useState, useEffect } from 'react';
import './SelectUnitType.scss';
import SelectUnitTypeForm from '../../components/Setting/SelectUnitType/SelectUnitType';
import Button from '../../components/UI/Form/Button/Button';
import routes from '../../assets/constants/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { errorToast } from '../../utils/toast';
import EventTracker from '../../utils/analytics';

const unitTypes = {
  clinic: 'Clinic',
  laboratory: 'Laboratory',
  imagingCenter: 'ImagingCenter',
};

const SelectUnitType: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [selectedType, setSelectedType] = useState('');
  const createUnitHandler = () => {
    EventTracker.sendEvent('SELECT_UNIT_TYPE_BUTTON_CLICKED', {
      type: selectedType,
    });

    switch (selectedType) {
      case unitTypes.clinic:
        history.push(routes.ADD_CLINIC);
        break;
      case unitTypes.laboratory:
        history.push(routes.ADD_LAB);
        break;
      case unitTypes.imagingCenter:
        history.push(routes.ADD_IMAGING_CENTER);
        break;
      default:
        errorToast(t('select_unit_type_page.choose_one'));
        break;
    }
  };

  useEffect(() => {
    const changeLangHandler = (lang: string = 'en') => {
      i18n.changeLanguage(lang);
    };
    changeLangHandler('en');
  }, [i18n]);

  const selectUnitTypeHandler = (type: string) => {
    setSelectedType(type);
  };

  return (
    <>
      <div className="setting_page p-3 d-flex flex-column align-items-center justify-content-center">
        <div className="setting_page-data">
          <SelectUnitTypeForm selectTypeHandler={selectUnitTypeHandler} />
        </div>
        <div className="setting_page-data w-100 d-flex justify-content-end">
          <Button
            saveData={() => {
              createUnitHandler();
            }}
            name={t('select_unit_type_page.next')}
          />
        </div>
      </div>
    </>
  );
};

export default SelectUnitType;
