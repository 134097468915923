import i18n from '../../services/i18n';

const gender: any = {
  male: { ar: 'ذكر', en: 'Male' },
  female: { ar: 'أنثى', en: 'Female' },
};

export const setGanderByLang = (type: string) => {
  if (!type) return '';
  return i18n.language === 'ar' ? gender[type].ar : gender[type].en;
};

export default gender;
