import api from './api';

export const addEmployee = async (payload: any) => {
  return await api.send('addEmployee', payload);
};

export const getEmployees = async (payload: any) => {
  return await api.send('getEmployees', payload);
};

export const revokeEmployeeAccess = async (payload: any) => {
  return await api.send('revokeEmployeeAccess', payload);
};
