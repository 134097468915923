import React from 'react';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import './HistoryView.scss';

type Props = { currentEncounter: any };
const DrugComponent = ({ drug }: any) => {
  return (
    <tr>
      <td>{drug.tradeName}</td>
      <td>{`${drug.quantity} ${drug.unit}`}</td>
      <td>{`${drug.every}`}</td>
      <td>{`For ${drug.duration} ${drug.durationExt}`}</td>
    </tr>
  );
};
const HistoryView = (props: Props) => {
  const { currentEncounter } = props;
  const {
    symp,
    diag,
    pres,
    lab,
    rad,
    vitals,
    allergy,
    attachments,
    chronic,
  } = currentEncounter;
  // console.log(JSON.stringify(currentEncounter));

  const openInNewTab = (image: { localUrl?: string; url: string }) => {
    // console.log('image', image);
    const url = image.localUrl ? image.localUrl : image.url;
    window.open(url, '_blank');
  };

  return (
    <div className="history-view">
      <div className="col-top">
        <div className="component allergy-component">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <SectionHeaderComponent
                  title={'Allergy'}
                  column={4}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <div className="tags">
                    {allergy &&
                      allergy.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`allergy-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <SectionHeaderComponent
                  title={'Chronic Disease'}
                  column={4}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <div className="tags">
                    {chronic &&
                      chronic.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`chronic-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className={`border-right col-4`}>
              <div className="component">
                <SectionHeaderComponent
                  title={'Vital Signs'}
                  column={1}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content vitals">
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">
                        Blood Pressure:
                      </label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'b1'}
                          readOnly
                          type="number"
                          value={vitals?.b1 || ''}
                        />
                      </div>
                      <div className="col-sm-1 ext">/</div>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'b2'}
                          readOnly
                          value={vitals?.b2 || ''}
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">
                        Temperature:
                      </label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'temp'}
                          type="number"
                          readOnly
                          value={vitals?.temp || ''}
                        />
                      </div>
                      <div className="col-sm-1 ext">C</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">
                        Pulse Rate:
                      </label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'pulseRate'}
                          type="number"
                          readOnly
                          value={vitals?.pulseRate || ''}
                        />
                      </div>
                      <div className="col-sm-1 ext">b/min</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">
                        Fast Blood<br></br> Glucose:
                      </label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'fastBloodGlucose'}
                          type="number"
                          readOnly
                          value={vitals?.fastBloodGlucose || ''}
                        />
                      </div>
                      <div className="col-sm-1 ext">mg/dl</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">
                        Respiratory <br></br>Rate:
                      </label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'respiratoryRate'}
                          type="number"
                          readOnly
                          value={vitals?.respiratoryRate || ''}
                        />
                      </div>
                      <div className="col-sm-1 ext">b/min</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">Weight:</label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'weight'}
                          readOnly
                          value={vitals?.weight || ''}
                          type="number"
                        />
                      </div>
                      <div className="col-sm-1 ext">KG</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">Height:</label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'height'}
                          readOnly
                          value={vitals?.height || ''}
                          type="number"
                        />
                      </div>
                      <div className="col-sm-1 ext">CM</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row vital-item">
                      <label className="col-sm-5 col-form-label">BMI:</label>
                      <div className="col-sm-3">
                        <input
                          className="form-control"
                          name={'bmi'}
                          readOnly
                          value={vitals?.bmi || ''}
                          type="number"
                        />
                      </div>
                      <div className="col-sm-1 ext">b/min</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="component">
                <SectionHeaderComponent
                  title={'Attachments'}
                  column={1}
                  hasButton={false}
                />
                <div className="col-content">
                  <div className="procedures-container d-flex flex-wrap ">
                    {attachments.map((image: any, idx: number) => (
                      <div
                        key={image.url + idx}
                        className="img-container d-flex align-items-start justify-content-between"
                        onClick={() => openInNewTab(image)}
                      >
                        <div className="main-img">
                          <img
                            src={image.localUrl ? image.localUrl : image.url}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={`border-right col-4`}>
              <div className="component">
                <SectionHeaderComponent
                  title={'Symptoms'}
                  column={1}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <div className="tags">
                    {symp &&
                      symp.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`symp-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="component">
                <SectionHeaderComponent
                  title={'Prescription'}
                  column={1}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <table className="table drug">
                    <tbody>
                      {pres &&
                        pres.map((element: any) => {
                          return (
                            <DrugComponent
                              drug={element}
                              key={`drug-${element.tradeName}`}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className={`border-right col-4`}>
              <div className="component">
                <SectionHeaderComponent
                  title={'Diagnoses'}
                  column={1}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <div className="tags">
                    {diag &&
                      diag.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`diag-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="component">
                <SectionHeaderComponent
                  title={'Investigations (Lab/Rad)'}
                  column={1}
                  hasButton={false}
                  // onDone={() => {}}
                />
                <div className="col-content">
                  <div className="tags">
                    {lab &&
                      lab.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`lab-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                    {rad &&
                      rad.map((element: any) => {
                        return (
                          <span
                            className="badge badge-primary"
                            key={`rad-${element.code}`}
                          >
                            <strong>{element.code} : </strong> {element.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryView;
