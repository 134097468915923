import React, { useEffect, useState } from 'react';
import './ForgetPassword.scss';
import ForgetPasswordForm from '../../components/ForgetPassword/ForgetPassword';
import TokenForm from '../../components/Token/TokenForm';

import apiService from '../../services/api';
import RegisterClinicDetails from '../../components/Layout/RegisterClinicDetails/RegisterClinicDetails';
import tokenTypes from '../../assets/constants/tokenTypes';
import routes from '../../assets/constants/routes';
import { errorToast } from '../../utils/toast';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isAuthenticated } from '../../utils/storage';

const ForgetPassword: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isForgetPassword, setIsForgetPassword] = useState(true);
  const [user, setUser] = useState<any>({});

  const [shouldResendToken, setShouldResendToken] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) history.push(routes.HOME);
  }, [history]);

  const forgetPasswordhandler = async (values: any) => {
    try {
      const payload = { ...values };
      // storeData(payload);
      // console.log('payload', payload);
      const data = await apiService.checkUser(payload);
      // console.log('data', data.data);
      if (data.data.exist) {
        setUser(data.data.user);
        await apiService.requestToken({
          mobile: data.data.user.mobile,
          tokenType: tokenTypes.resetPassword,
        });
        setIsForgetPassword(false);
      } else {
        errorToast(data.data.msg);
      }
    } catch (error) {
      console.error('error[checkOtp]', error);
    }
  };

  const checkTokenHandler = async (values: any) => {
    if (!values.token || values.token.length !== 4) return;
    try {
      setIsSubmitting(true);
      const tokenPayload = {
        ...values,
        mobile: user.mobile,
        tokenType: tokenTypes.resetPassword,
      };
      const data = await apiService.checkToken(tokenPayload);
      // console.log('data', data.data);
      setIsSubmitting(false);
      history.push({
        pathname: routes.CHANGE_PASSWORD,
        state: { user, token: data.data.token },
      });
    } catch (error) {
      console.error('error[checkToken]', error);
      setIsSubmitting(false);
    }
  };

  const resendTokenHandler = async () => {
    try {
      await apiService.requestToken({
        mobile: user.mobile,
        tokenType: tokenTypes.resetPassword,
      });
      setShouldResendToken(true);
    } catch (error) {
      console.error('error[resendToken]', error);
      setShouldResendToken(true);
    }
  };

  const goBackHandler = () => {
    setIsForgetPassword(true);
  };

  return (
    <div className="forget-password-page d-flex flex-column">
      <div className="d-flex flex-column-reverse flex-sm-row ">
        {isForgetPassword ? (
          <ForgetPasswordForm forgetPasswordhandler={forgetPasswordhandler} />
        ) : (
          <TokenForm
            checkTokenhandler={checkTokenHandler}
            resendTokenHandler={resendTokenHandler}
            mobile={user.mobile}
            shouldResend={shouldResendToken}
            isSubmitting={isSubmitting}
            goBack={goBackHandler}
            name={t('pages.forgetPassword')}
          />
        )}

        <RegisterClinicDetails />
      </div>
    </div>
  );
};

export default ForgetPassword;
