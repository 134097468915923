import {
  CHECK_USER,
  START_LOADING,
  STOP_LOADING,
  LOGIN,
  CHECK_TOKEN,
  TOKEN_REQUIRED,
  LOGOUT,
  SET_USER_AUTHENTICATED,
  SET_USER_PROFILE,
} from './actionTypes';
import { Dispatch as ReduxDispatch, Action } from 'redux';
import apiService from '../../services/api';
import { errorToast } from '../../utils/toast';
import { storeData } from '../../utils/storage';
import tokenTypes from '../../assets/constants/tokenTypes';
import { UserStatus, UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import initSocket, { socketRemoveAllListener } from '../../services/socket';

type Dispatch = ReduxDispatch<Action<string>>;

export const checkUserExists = (payload: any, history: any) => async (
  dispatch: any
) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await apiService.checkUser(payload);
    // console.log('checkUserExists', data);
    if (data.data.exist) {
      const user = data.data.user;
      dispatch({ type: CHECK_USER, payload: data.data.user });
      if (
        user &&
        user.status === UserStatus.New &&
        [UserRoles.Nurse, UserRoles.Receptionist].includes(user.role)
      ) {
        // request token to complete profile
        dispatch(
          requestUserToken({
            mobile: user.mobile,
            tokenType: tokenTypes.updateProfile,
          })
        );
        history.push({
          pathname: routes.COMPLETE_PROFILE,
          state: { user },
        });
      } else if (user && user.status === UserStatus.Active) {
        // redirect to login page
        history.push({
          pathname: routes.LOGIN,
          state: { user },
        });
      }
    } else {
      errorToast(data.data.msg);
    }
  } catch (error) {
    dispatch({ type: STOP_LOADING });
  }
};

export const requestUserToken = (payload: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: START_LOADING });
    await apiService.requestToken(payload);
    // console.log('requestUserToken', data);
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    dispatch({ type: STOP_LOADING });
  }
};

export const checkUserToken = (payload: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await apiService.checkToken(payload);
    // console.log('checkUserToken', data);
    dispatch({ type: CHECK_TOKEN, payload: data.data.token });
  } catch (error) {
    dispatch({ type: STOP_LOADING });
  }
};

export const loginUser = (payload: any, history: any) => async (
  dispatch: Dispatch
) => {
  try {
    // console.log('loginUser payload', payload);
    dispatch({ type: START_LOADING });
    const data = await apiService.login(payload);
    // console.log('loginUser data', data.data);
    if (data.data.tokenRequired) {
      dispatch({ type: TOKEN_REQUIRED, payload: data.data.tokenRequired });
    } else {
      delete data.data.tokenRequired;
      storeData({ ...data.data });
      dispatch({ type: LOGIN, payload: data.data });
      history.push(routes.BOOKING);
    }
  } catch (error) {
    dispatch({ type: STOP_LOADING });
  }
};

export const logout = () => (dispatch: Dispatch) => {
  socketRemoveAllListener();
  dispatch({ type: LOGOUT });
};

export const setUserAuthenticated = () => (dispatch: any) => {
  initSocket();
  dispatch({ type: SET_USER_AUTHENTICATED });
  dispatch(getUserProfile());
};

export const setUserProfile = (user: any) => (dispatch: Dispatch) => {
  dispatch({ type: SET_USER_PROFILE, payload: user });
};

export const getUserProfile = () => async (dispatch: any) => {
  try {
    const {
      data: { user },
    } = await apiService.getUserProfile({});
    // console.log('user', user);
    dispatch(setUserProfile(user));
  } catch {
    console.error('error[getUserProfile]');
  }
};
