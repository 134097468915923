import api from './api';

export const checkUser = async (payload: any) => {
  return await api.send('checkUser', payload);
};

export const login = async (payload: any) => {
  return await api.send('login', payload);
};

export const requestToken = async (payload: any) => {
  return await api.send('requestToken', payload);
};

export const checkToken = async (payload: any) => {
  return await api.send('checkToken', payload);
};

export const register = async (payload: any) => {
  return await api.send('register', payload);
};

export const changePassword = async (payload: any) => {
  return await api.send('changePasswordUser', payload);
};

export const completeProfile = async (payload: any) => {
  return await api.send('completeProfile', payload);
};

export const getUserProfile = async (payload: any) => {
  return await api.send('getUserProfile', payload);
};

export const updateUserProfile = async (payload: any) => {
  return await api.send('updateUserProfile', payload);
};

export const updateUserPassword = async (payload: any) => {
  return await api.send('updateUserPassword', payload);
};

export const validatePromoCode = async (payload: any) => {
  return await api.send('validatePromoCode', payload);
};

export const searchDrByMobile = async (payload: any): Promise<any> => {
  const {
    data: { user, exist, units },
  } = await api.send('searchDrByMobile', payload);
  return { user, exist, units };
};
