import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addImgIcon from '../../../../assets/img/add-img.svg';
import cameraIcon from '../../../../assets/img/camera.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/delete.svg';
import loadingIcon from '../../../../assets/img/loading.svg';
import { DELETE_ATTACHMENTS_FILE } from '../../../../store/actions/actionTypes';
import { rootState } from '../../../../store/reducers';
import './AttachmentsComponent.scss';
import UploadManager from './UploadManagerProgress/UploadManagerProgress';

const AttachmentsComponent: React.FC<{
  imgSource?: string;
  orderPhotos?: any;
  addAttachs?: (file: any) => void;
  deleteAttachs?: (ind: number) => void;
}> = ({ imgSource, orderPhotos, addAttachs, deleteAttachs }) => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [attaches, setAttaches] = useState<any[]>([]);

  const { attachments, reservation, isAttachmentsUploading } = useSelector(
    (state: rootState) => state.board
  );

  const currentBooking = useSelector(
    (state: rootState) => state.board.currentBooking
  );

  const [uploadingImages, setUploadingImages] = useState<File[]>([]);
  const [uploadingCounter, setUploadingCounter] = useState(0);

  useEffect(() => {
    // console.log('reservation', reservation);
    // console.log('attachments', attachments);
    if (!isAttachmentsUploading) {
      setUploadingCounter(0);
    }
    const bookingId = currentBooking
      ? currentBooking.sk
      : reservation.b?.date
      ? reservation.b.date.toString()
      : null;
    if (
      !bookingId ||
      (attachments.length === 0 && imgSource !== 'dentLabOrder') ||
      (!orderPhotos && imgSource === 'dentLabOrder')
    ) {
      setAttaches([]);
      return;
    }

    const ff =
      imgSource === 'dentLabOrder' && orderPhotos
        ? orderPhotos
        : attachments.filter(
            (ele: any) => !ele.hasOwnProperty('bId') || ele.bId === bookingId
          );
    setAttaches(ff);
  }, [attachments, currentBooking, reservation, orderPhotos]);

  const openFileExplorerHandler = () => {
    const node = ref.current;
    if (node) {
      node.click();
    }
  };

  const selectImagesHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;
    if (!files) return;

    const filteredImages = Array.from(files).filter(
      (file) => file.type || file.type.startsWith('image')
    );
    setUploadingImages(filteredImages);
    setUploadingCounter(filteredImages.length + attaches.length);
  };

  const deleteImage = (idx: number) => {
    if (imgSource === 'dentLabOrder' && deleteAttachs) {
      deleteAttachs(idx);
    } else {
      dispatch({
        type: DELETE_ATTACHMENTS_FILE,
        payload: idx,
      });
      setUploadingCounter(uploadingCounter - 1);
    }
  };

  const openInNewTab = (image: { localUrl?: string; url: string }) => {
    // console.log('image', image);
    const url = image.localUrl ? image.localUrl : image.url;
    window.open(url, '_blank');
  };

  return (
    <>
      {imgSource ? (
        <>
          <div
            className={`${
              imgSource === 'dentalBoard'
                ? 'add-photo-container d-flex'
                : 'attached-imgs'
            }`}
          >
            <UploadManager
              files={uploadingImages}
              bookingId={
                currentBooking
                  ? currentBooking.sk
                  : reservation.b?.date
                  ? reservation.b.date.toString()
                  : null
              }
              imgSource={imgSource}
              addAttachs={addAttachs}
            />
            <input
              type="file"
              ref={ref}
              accept="image/*"
              onChange={selectImagesHandler}
              multiple
            />
            {imgSource === 'dentalBoard' ? (
              <div className="add-photo" onClick={openFileExplorerHandler}>
                <p className="plus text-light">+</p>
                <p className="txt text-light ">Photo</p>
              </div>
            ) : (
              <div>
                <img
                  className="camera-icon"
                  src={cameraIcon}
                  alt=""
                  onClick={openFileExplorerHandler}
                />
              </div>
            )}
            <div className="d-flex overflow-auto">
              {attaches.length > 0 &&
                attaches.map((image: any, idx: number) => (
                  <div key={image.url + idx} className="photo ml-2">
                    <div
                      className="main-img"
                      onClick={() => openInNewTab(image)}
                    >
                      <img
                        src={image.localUrl ? image.localUrl : image.url}
                        alt=""
                      />
                    </div>

                    <div
                      className="delete-icon"
                      onClick={() => {
                        deleteImage(idx);
                      }}
                    >
                      <DeleteIcon fill="var(--color-danger)" />
                    </div>
                  </div>
                ))}

              {imgSource === 'dentalBoard'
                ? attaches.length < uploadingCounter && (
                    <div className="loading-img">
                      <img src={loadingIcon} alt="" />
                    </div>
                  )
                : ''}
            </div>
          </div>
        </>
      ) : (
        <div className="component procedures-component d-flex flex-column align-items-start">
          <div className="d-flex justify-content-between w-100">
            <div className="title align-self-center ">Attachments:</div>
            <div className="">
              <UploadManager
                files={uploadingImages}
                bookingId={
                  currentBooking
                    ? currentBooking.sk
                    : reservation.b?.date
                    ? reservation.b.date.toString()
                    : null
                }
              />
            </div>
          </div>
          <div
            className="add-img d-flex align-items-center my-3"
            onClick={openFileExplorerHandler}
          >
            <input
              type="file"
              ref={ref}
              accept="image/*"
              onChange={selectImagesHandler}
              multiple
            />
            <img src={addImgIcon} alt="" />
            <p>upload Images</p>
          </div>
          <div className="procedures-container d-flex flex-wrap justify-content-between">
            {attaches.length > 0 &&
              attaches.map((image: any, idx: number) => (
                <div
                  key={image.url + idx}
                  className="img-container d-flex align-items-start justify-content-between"
                >
                  <div className="main-img" onClick={() => openInNewTab(image)}>
                    <img
                      src={image.localUrl ? image.localUrl : image.url}
                      alt=""
                    />
                  </div>

                  <div
                    className="delete-icon"
                    onClick={() => {
                      deleteImage(idx);
                    }}
                  >
                    <DeleteIcon fill="var(--color-danger)" />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentsComponent;
