import React, { useState } from 'react';
import './SelectService.scss';
// import services from '../../../assets/constants/dentalServices';
import { Collapse } from 'react-collapse';
type props = {
  setCurrentService: (service: string) => void;
  currentService: string;
  isActive: boolean;
  services: any;
  setOperationPrice: (price: number) => void;
};
const SelectService: React.FC<props> = ({
  setCurrentService,
  currentService,
  isActive,
  services,
  setOperationPrice,
}) => {
  const [currentCat, setCurrentCat] = useState('');

  const currentCatSetter = (catType: string) => {
    if (currentCat === catType) {
      setCurrentCat('');
    } else {
      setCurrentCat(catType);
    }
    setCurrentService('');
  };
  return (
    <div className="select-service-wrapper">
      <h1 className={isActive ? 'active-header' : undefined}>
        Select a service
      </h1>
      <div className="service-cats-wrapper">
        {services.map((cat: any) => {
          return (
            <div key={cat.type} className="cat-wrapper">
              <h1
                onClick={() => {
                  // console.log({ cat });
                  currentCatSetter(cat.type);
                }}
              >
                {cat.name}
              </h1>
              <Collapse isOpened={currentCat === cat.type}>
                {cat.services.map((service: any) => {
                  return (
                    <ul
                      className="service"
                      key={service.type}
                      onClick={() => {
                        setCurrentService(service.type);
                        setOperationPrice(service.price);
                      }}
                    >
                      <li
                        className={
                          service.type === currentService
                            ? 'selected-service'
                            : undefined
                        }
                      >
                        {service.name}
                      </li>
                    </ul>
                  );
                })}
              </Collapse>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectService;
