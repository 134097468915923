import React, { useState, useEffect } from 'react';
import './NoteModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import backIcon from '../../../../../../assets/img/close-icon.svg';
import Button from '../../../../../UI/Form/Button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50rem',
    minHeight: '25rem',
  },
};

type Props = {
  isOpen: boolean;
  note: string;
  onModalClose: () => void;
  index: number;
  addNoteHandler: (index: number, note: string) => void;
};

const MapModal: React.FC<Props> = ({
  isOpen,
  note,
  onModalClose,
  addNoteHandler,
  index,
}) => {
  const [noteValue, setNoteValue] = useState(note ? note : '');

  const { t, i18n } = useTranslation();

  const cancelHandler = () => {
    onModalClose();
  };

  const setInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'note') {
      setNoteValue(value);
    }
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div dir={i18n.dir()} className="noteModel-container d-flex flex-column">
        <div className="title d-flex justify-content-between">
          <div>
            <h5>{!note ? t('clinicBoard_page.add-note') : 'Update Note'}</h5>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={cancelHandler}>
            <img src={backIcon} style={{ height: '15px' }} alt="" />
          </div>
        </div>
        <div className="input d-flex mt-5 justify-content-center">
          <div className="passwordInput">
            <textarea
              style={{ width: '450px', height: '100px' }}
              name="note"
              value={noteValue}
              autoComplete="off"
              placeholder={t('clinicBoard_page.note')}
              onChange={setInputHandler}
            />
          </div>
        </div>
        <div className="confirm d-flex justify-content-center">
          <Button
            saveData={() => {
              addNoteHandler(index, noteValue);
            }}
            name={t('addClinic_page.done')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;
