import React from 'react';
import { useTranslation } from 'react-i18next';
import { setGanderByLang } from '../../../assets/constants/gender';
import { SijilNetworkIcon } from '../../../assets/constants/images';
import calenderIcon from '../../../assets/img/calender.svg';
import genderIcon from '../../../assets/img/gender.svg';
import mobileIcon from '../../../assets/img/mobile.svg';
import nameIcon from '../../../assets/img/personal.svg';
import { BaseUserNetwork, Patient } from '../../../types';
import getFullName from '../../../utils/getFullName';
import { formatDate, getAgeFromBD } from '../../../utils/moment';
import './PatientDetails.scss';

type Props = {
  patient: Patient;
  lastVisit: any;
};

const PatientDetails: React.FC<Props> = ({ patient, lastVisit }) => {
  const { t } = useTranslation();

  const calcAgeHandler = (patientData: any) => {
    let age: any;
    if (patientData && patientData.dob) {
      const patientAge = getAgeFromBD(new Date(patientData.dob));
      age = `${patientAge.split(' ')[0]} ${t(
        'booking_page.' + patientAge.split(' ')[1]
      )}`;
    } else {
      age = `${patientData.age} ${t('booking_page.age')}`;
    }

    return age;
  };

  return (
    <div className="booking-details d-flex flex-column">
      <p> {t('addBooking_page.booking')}</p>
      <div className="booking-data d-flex flex-column justify-content-between">
        <div className="booking-data__patient-data">
          <div className="name-group align-items-center d-flex">
            <img src={nameIcon} alt="mobile icon" />
            <h6 className="ar-font align-self-end">
              {patient && patient.name ? getFullName(patient.name) : ''}
            </h6>
          </div>
          <div className="name-group align-items-center d-flex">
            <img src={genderIcon} alt="mobile icon" />
            <h6 className="align-self-end">
              {patient && patient.gender ? setGanderByLang(patient.gender) : ''}
            </h6>
          </div>
          <div className="name-group align-items-center d-flex">
            <img src={calenderIcon} alt="mobile icon" />
            <h6 className="date align-self-end">
              {patient && patient.dob ? calcAgeHandler(patient) : patient.age}
            </h6>
          </div>
          <div className="name-group mobile-icon  align-items-center d-flex">
            <img src={mobileIcon} alt="mobile icon" />
            <h6 className="date mobileNum align-self-end">
              {patient && patient.mobile ? patient.mobile : patient.lmobile}
            </h6>
          </div>
          <div>
            {patient.networks &&
              patient.networks.map((network: BaseUserNetwork) => {
                if (network.code === 'sijil') return <SijilNetworkIcon />;
                else return <span>{network.name}</span>;
              })}
          </div>
        </div>
        {lastVisit ? (
          <div className="booking-data__patient-lastVisit">
            <span>{t('addBooking_page.last-visit')}</span>
            <span className="date">{formatDate(new Date(lastVisit))}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PatientDetails;
