const randomColors = ['#82C9FF', '#FF8AA9', '#74FFA7', '#FFC28E', '#FFDA6A'];

export const getRandomColor = (index: number) => {
  const val = index % 5;
  return randomColors[+val];
};

export const colors = {
  normal: '#F2F9FF',
  followUp: '#EFF9F2',
  urgent: '#FFECF1',
  hold: '#FFAB3A',
  completed: '#0090ff',
  current: '#0090ff',
  canceled: '#FF1654',
  active: '#0090FF',
  full: '#A7A7A7',
};

export const statisticsColors = {
  normal: '#0090FF',
  followUp: '#31B260',
  urgent: '#FF1654',
};
