const tokenTypes = {
  authToken: 'authToken',
  trustedDeviceToken: 'trustedDeviceToken',
  loginToken: 'loginToken',
  registerToken: 'registerToken',
  updateProfile: 'updateProfile',
  resetPassword: 'resetPassword',
};

export default tokenTypes;
