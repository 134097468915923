import React, { useState } from 'react';
import './CheckProfile.scss';

import { useTranslation } from 'react-i18next';
import MyDropzone from '../../Register/CheckProfile/DropZone';

import Button from '../../UI/Form/Button/Button';
import backArrow from '../../../assets/img/back.svg';
import parseArabic from '../../../utils/parseArabic';
import { nationalIdRegex } from '../../../assets/constants/regexValues';

type Props = {
  getImage: (file: any, type: string) => void;
  completeProfile: (values: any) => Promise<void>;
  nextStepHandler: (payload: { type: string; data: any }) => void;
  setProfilePowerHandler: (value: number) => void;
  currentSegment: string;
  isSubmitting: boolean;
  uploading: boolean;
  goBack: () => void;
  profileLocalImg: string;
  nationalScanLocalImg: string;
};

const CheckProfile: React.FC<Props> = ({
  completeProfile,
  nextStepHandler,
  getImage,
  currentSegment,
  isSubmitting,
  uploading,
  goBack,
  profileLocalImg,
  nationalScanLocalImg,
}) => {
  const { t } = useTranslation();

  const [natError, setNatError] = useState('');

  const changeNationlaNumberHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let natNum = parseArabic(e.target.value);
    natNum = natNum.replace(/\D|-/g, '');
    e.target.value = natNum;
    if (RegExp(nationalIdRegex).test(natNum) && natNum.length === 14) {
      nextStepHandler({
        data: { nationalNumber: natNum, power: 40 },
        type: currentSegment,
      });
      setNatError('');
    } else {
      setNatError(t('booking_page.valid-nat'));
      nextStepHandler({
        data: { nationalNumber: natNum, power: 0 },
        type: currentSegment,
      });
    }
  };

  return (
    <div className="register-profile-check d-flex  flex-column  justify-content-center w-100 mt-3 mt-sm-0">
      <div className="name d-flex justify-content-between mt-3 mt-sm-0">
        <p>{t('pages.register')}</p>
      </div>
      <div className="register-profile-check-container align-items-center d-flex flex-column justify-content-between  mt-4">
        <div className="nat-num-profile d-flex flex-column flex-sm-row justify-content-between  w-100">
          <div className="national-id-img  w-100 p-3">
            <div className=" d-flex justify-content-between">
              <p>
                {t('complete_profile.nat-img')}
                <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span>
              </p>
              <p className="percent  align-self-start">40%</p>
            </div>
            <div className="d-flex justify-content-center align-items-center p-4">
              {/* {nationalScanLocalImg ? (
                <div className="pic">
                  <img src={nationalScanLocalImg} alt="pic" />
                </div>
              ) : ( */}
              <div className="mt-4">
                <MyDropzone
                  imgSrc={nationalScanLocalImg}
                  getImage={getImage}
                  type="national"
                />
              </div>
              {/* )} */}
            </div>
          </div>
          <div className="national-id-img  w-100 p-3">
            <div className=" d-flex justify-content-between">
              <p>
                {t('complete_profile.profile')}
                <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span>
              </p>
              <p className="percent align-self-start">20%</p>
            </div>
            <div className="d-flex justify-content-center align-items-center p-4">
              {/* {profileLocalImg ? (
                <div className="pic">
                  <img src={profileLocalImg} alt="pic" />
                </div>
              ) : ( */}
              <div className="mt-4">
                <MyDropzone
                  imgSrc={profileLocalImg}
                  getImage={getImage}
                  type="profile"
                />
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="nat-asso-scan  d-flex flex-column flex-sm-row justify-content-between w-100 mt-5">
          <div className="national-id mt-5 mt-sm-0">
            <div className=" d-flex justify-content-between">
              <p>
                {t('complete_profile.nat-num')}
                <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span>
              </p>
              <p className="percent align-self-start">40%</p>
            </div>
            <div className="d-flex flex-column nat-num mt-5">
              <input
                type="number"
                placeholder={t('complete_profile.nat-placeholder')}
                onChange={changeNationlaNumberHandler}
                maxLength={14}
              />
              <div className="error">
                {natError ? <span>{natError}</span> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="create-account w-100 my-4">
          <Button
            type="button"
            name={t('complete_profile.create-account')}
            isSubmitting={isSubmitting || uploading}
            saveData={completeProfile}
          />
        </div>
      </div>
      <div
        className="back align-self-start"
        onClick={() => {
          goBack();
        }}
      >
        <img src={backArrow} alt="back" />
        <span>{t('otp_page.back')}</span>
      </div>
    </div>
  );
};

export default CheckProfile;
