export const UserRoles = {
  Physician: 'physician',
  Nurse: 'nurse',
  Receptionist: 'receptionist',
  Patient: 'patient',
  Assistant: 'assistant',
  LabTech: 'labTech',
  RadioTech: 'radioTech',
};

export const paymentRoles = {
  Daily: 'daily',
  Monthly: 'monthly',
};

export const employeePayment: any = {
  daily: { en: 'Daily', ar: 'يوميا' },
  monthly: { en: 'Monthly', ar: 'شهريا' },
};

export const UserStatus = {
  New: 'new',
  Active: 'active',
};

const roles: any = {
  assistant: { en: 'Assistant', ar: 'مساعد' },
  receptionist: { en: 'Receptionist', ar: 'موظف استقبال' },
  nurse: { en: 'Nurse', ar: 'ممرضه' },
  radioTech: { en: 'Radiology technologist', ar: 'فني أشعة' },
  labTech: { en: 'Laboratory technician', ar: 'فني تحاليل' },
};

export default roles;
