import React from 'react';
import { Collapse } from 'react-collapse';
import collapseIcon from '../../../../../assets/img/collapse.svg';
import moreIcon from '../../../../../assets/img/more.svg';
import { getBookingInHour } from '../../../../../services/timeSlots';
import { Booking, ClinicData } from '../../../../../types';
import BookingItem from '../BookingItem/BookingItem';
import { rootState } from '../../../../../store/reducers';
import { useSelector } from 'react-redux';

type Props = {
  index: number;
  slots: any;
  slot: string;
  currentUnitDetails: ClinicData | null;
  showHideCollapsedHandler: (index: number) => void;
  collapsedSlots: any;
  setCurrentBookingHandler: (booking: any) => void;
  currentBooking: Booking | null;
  calcAgeHandler: (patient: any) => string;
};
const BookingTimeSlot = (props: Props): JSX.Element => {
  const {
    index,
    slots,
    slot,
    currentUnitDetails,
    collapsedSlots,
    currentBooking,
    showHideCollapsedHandler,
    setCurrentBookingHandler,
    calcAgeHandler,
  } = props;
  const hideFlag = useSelector((state: rootState) => state.board.hideFlag);
  const someSlotsOpen = useSelector(
    (state: rootState) => state.board.someSlotsOpen
  );
  return (
    <div className="booking-item-container">
      <div
        className="date-time justify-content-between w-100"
        style={{
          backgroundColor: 'var(--color-primary-dark1)',
        }}
        onClick={() => {
          showHideCollapsedHandler(index);
        }}
      >
        <span className="toggle-span">
          {`${slots[slot].length}`}/
          {currentUnitDetails && getBookingInHour(currentUnitDetails)}
        </span>
        <button
          onClick={() => {
            showHideCollapsedHandler(index);
          }}
        >
          <span>{slot}</span>
          <img
            src={
              !collapsedSlots.includes(index) || (hideFlag && someSlotsOpen)
                ? collapseIcon
                : moreIcon
            }
            // src={(collapsedSlots.includes(index)) ? moreIcon : collapseIcon}
            alt="showIcon"
          />
        </button>
      </div>
      <div className="booking-item-container-details">
        <Collapse
          isOpened={
            !collapsedSlots.includes(index) || (hideFlag && someSlotsOpen)
          }
        >
          {slots[slot].map(
            (booking: any, ind: number) =>
              booking &&
              booking.sk && (
                <BookingItem
                  key={`ele-${ind}`}
                  ind={ind}
                  setCurrentBookingHandler={setCurrentBookingHandler}
                  booking={booking}
                  currentBooking={currentBooking}
                  calcAgeHandler={calcAgeHandler}
                  currentUnitDetails={currentUnitDetails}
                />
              )
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default BookingTimeSlot;
