import React from 'react';
import './Subscription.scss';
// import { useTranslation } from 'react-i18next';

// import { useSelector, useDispatch } from 'react-redux';
// import { rootState } from '../../store/reducers';
// import { useHistory } from 'react-router-dom';

const Subscription: React.FC = () => {
  // const { t, i18n } = useTranslation();
  // const history = useHistory();
  // const dispatch = useDispatch();

  // const currentUnitDetails = useSelector(
  //   (statee: rootState) => statee.booking.currentUnitDetails
  // );

  return <div className="container"></div>;
};

export default Subscription;
