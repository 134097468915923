import React from 'react';
import { useSelector } from 'react-redux';
import { NotActive, SubscriptionExpired } from '../../components/Subscription';
import { rootState } from '../../store/reducers';
import { ClinicData } from '../../types';
type Props = {
  currentUnitDetails?: ClinicData | null;
  children: any;
};
const WithSubscription = (props: Props) => {
  const { currentUnitDetails, children } = props;
  const currentEmpUnit = useSelector(
    (state: rootState) => state.booking.currentEmpUnit
  );
  // const user = useSelector((state: rootState) => state.auth.user);
  // useEffect(() => {}, []);

  return currentUnitDetails?.pk ? (
    currentUnitDetails.subscription ? (
      !currentEmpUnit?.owner?.active ? (
        NotActive(currentEmpUnit)
      ) : currentUnitDetails.type === 'clinic' &&
        currentUnitDetails.subscription.expirationDate &&
        new Date(currentUnitDetails.subscription.expirationDate) <
          new Date() ? (
        SubscriptionExpired(currentEmpUnit, currentUnitDetails)
      ) : currentUnitDetails.type !== 'clinic' &&
        currentUnitDetails.subscription.type === 'trial' &&
        currentUnitDetails.subscription.expirationDate &&
        new Date(currentUnitDetails.subscription.expirationDate) <
          new Date() ? (
        SubscriptionExpired(currentEmpUnit, currentUnitDetails)
      ) : currentUnitDetails.type !== 'clinic' &&
        currentUnitDetails.subscription.balance === 0 ? (
        SubscriptionExpired(currentEmpUnit, currentUnitDetails)
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )
    ) : (
      NotActive(currentEmpUnit)
    )
  ) : null;
};
export default WithSubscription;
