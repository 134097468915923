import React, { useState, useEffect } from 'react';
import './MapModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../UI/Form/Button/Button';
import Map from '../../../Map/Map';
import { getPlacelatlng } from '../../../../utils/location';
import ApiService from '../../../../services/api';
import backIcon from '../../../../assets/img/back2.svg';
import searchIcon from '../../../../assets/img/search2.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20rem',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  confirmHandler: (coords: any) => void;
  location: { lat: number; lng: number } | null;
};

const MapModal: React.FC<Props> = ({
  isOpen,
  location,
  confirmHandler,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const [coords, setCoords] = useState({});

  const [locationData, setLocationData] = useState({
    lat: 30.033333,
    lng: 31.233334,
  });

  const [searchValue, setSearchValue] = useState('');
  const [places, setPlaces] = useState([]);
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    if (!location) return;
    setLocationData({ ...location });
  }, [location]);

  const setLocation = (newCoords: {
    lat: number;
    lng: number;
    address: string;
  }) => {
    // console.log('newCoords', newCoords);
    setCoords({ ...newCoords });
  };

  const cancelHandler = () => {
    setCoords({});
    setLocationData({ lat: 30.033333, lng: 31.233334 });
    setSearchValue('');
    setInputFocus(false);
    onModalClose();
  };

  const setPlacesHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      setPlaces([]);
    }
    setSearchValue(value);
    if (value.length < 3) return;
    const { data } = await ApiService.searchPlaces({ value });
    setPlaces(data.results);
  };

  const getPlaceDetails = async (description: string, placeId: string) => {
    setSearchValue(description);
    const { lat, lng, address } = await getPlacelatlng(placeId);
    // console.log('data', { lat, lng, address });
    setCoords({ lat, lng, address });
    setLocationData({ lat, lng });
    blurInputHandler();
  };

  const focusInputHandler = () => {
    setInputFocus(true);
  };

  const blurInputHandler = () => {
    setInputFocus(false);
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div className="mapModal-container d-flex flex-column align-items-center">
        <div className="address-input d-flex align-items-center justify-content-between">
          <div
            className="img align-self-stretch d-flex"
            onClick={cancelHandler}
          >
            <img src={backIcon} alt="" />
          </div>
          <div className="search d-flex flex-fill  align-items-center">
            <img src={searchIcon} alt="" />
            <input
              type="text"
              value={searchValue}
              onChange={setPlacesHandler}
              onFocus={focusInputHandler}
              // onBlur={blurInputHandler}
              placeholder={t('addClinic_page.search')}
            />
            {inputFocus && places.length > 0 && (
              <ul className="places-list">
                {places.map(({ description, place_id }: any) => (
                  <li
                    key={place_id}
                    onClick={() => {
                      getPlaceDetails(description, place_id);
                    }}
                  >
                    {description}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div>
          <Map location={locationData} setLocation={setLocation} />
        </div>
        <div className="confirm align-self-stretch d-flex">
          <Button
            saveData={() => {
              // console.log('coords', coords);
              confirmHandler(coords);
            }}
            name={t('addClinic_page.done')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;
