import React, { useEffect, useState } from 'react';
import { vitalValidations } from '../../../../../assets/constants/vitalValidations';
import { indicator } from '../../../../../utils/indicator';

import '../AllInputComponent.scss';

const VitalInput = (props: any) => {
  const {
    className,
    name,
    value,
    onChange,
    setStatus,
    setBloodPressure,
    bloodPressure,
    age,
  } = props;
  const { min, max }: any = vitalValidations[name];
  const [error, setError] = useState(false);

  const [text, setText] = useState(value);

  useEffect(() => {
    // console.log('value', value);
    setText(value);
  }, [value]);

  const bloodPressureIndicator = (b1: string, b2: string) => {
    setBloodPressure({ b1: Number(b1), b2: Number(b2) });

    if (Number(b1) < 90 && Number(b2) < 60) {
      setStatus('Low');
    } else if (Number(b1) < 120 && Number(b2) < 80) {
      setStatus('Normal');
    } else if (Number(b1) < 129 && Number(b2) < 80) {
      setStatus('High');
    } else if (Number(b1) < 139 && Number(b2) < 90) {
      setStatus('Stage1');
    } else {
      setStatus('Stage2');
    }
  };

  const onChangeInput = (name: string, value: string) => {
    // console.log(name, value);
    if (Number(value) < 0 || Number(value) > max) {
      // console.log('if', value);
      setError(true);
    } else if (Number(value) < min && Number(value) !== 0) {
      setError(true);
      // console.log('else if', Number(value));
      onChange(name, value);
    } else {
      setError(false);
      // console.log('else', value);
      onChange(name, value);
    }
    value = value.replace(/[^0-9.]/g, '');
    if (setStatus) {
      if (name === 'b1') bloodPressureIndicator(value, bloodPressure.b2);
      else if (name === 'b2') bloodPressureIndicator(bloodPressure.b1, value);
      else if (name === 'temp') setStatus(indicator(value, 36.1, 37.2));
      else if (name === 'pulseRate') setStatus(indicator(value, 60, 100));
      else if (name === 'fastBloodGlucose') setStatus(indicator(value, 0, 199));
      else if (name === 'respiratoryRate') {
        if (Number(age) < 1) {
          setStatus(indicator(value, 30, 60));
        } else if (Number(age) < 3) {
          setStatus(indicator(value, 24, 40));
        } else if (Number(age) < 6) {
          setStatus(indicator(value, 22, 34));
        } else if (Number(age) < 12) {
          setStatus(indicator(value, 18, 30));
        } else {
          setStatus(indicator(value, 12, 16));
        }
      }
    }
  };

  useEffect(() => {
    if (text !== value) {
      const debouncer = setTimeout(() => {
        onChangeInput(name, text);
      }, 1000);
      return () => {
        clearTimeout(debouncer);
      };
    }
  }, [text]);

  return (
    <input
      className={`${className} ${error ? 'error' : ''}`}
      name={name}
      value={text}
      type="Number"
      min={min}
      max={max}
      onChange={(e) => {
        setText(e.target.value);
      }}
    />
  );
};

export default VitalInput;
