import React from 'react';
import Highlighter from 'react-highlight-words';
import './SearchResult.scss';
const mm = (s: any, strings: string[]) => {
  return new RegExp(strings.join('|')).test(s);
};
const SearchResult = (props: any) => {
  const { data, strings, onSelect } = props;
  return (
    <div className="result-container">
      {Object.keys(data).map((key: any) => {
        return (
          <div className="" key={`result-${key}`}>
            {data[key].item ? (
              <div
                className=""
                onClick={() => {
                  onSelect(data[key].item);
                }}
              >
                <div className="ele">
                  <strong>{key} </strong>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={strings}
                    autoEscape={true}
                    textToHighlight={`${data[key].item.name}`}
                  />
                </div>
                <div className="sub">
                  {data[key].item.synonym &&
                    data[key].item.synonym
                      .filter((s: string) => mm(s, strings))
                      .map(
                        (s: string, i: number) =>
                          s !== data[key].item.name && (
                            <div
                              key={`highlight-${key}-${s}-${i}`}
                              className="ele"
                            >
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={strings}
                                autoEscape={true}
                                textToHighlight={s}
                              />
                            </div>
                          )
                      )}
                </div>
              </div>
            ) : (
              <div className="">
                <strong>{key} </strong>
              </div>
            )}
            {data[key].items.map((element: any) => {
              return (
                <div
                  key={`result-${element.code}`}
                  className="nested ele"
                  onClick={() => {
                    onSelect(element);
                  }}
                >
                  <strong>{element.code} </strong>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={strings}
                    autoEscape={true}
                    textToHighlight={`${element.name}`}
                  />
                  <div className="sub">
                    {element.synonym.map(
                      (s: string, i: number) =>
                        s !== element.name && (
                          <Highlighter
                            key={`highlight-${element.code}-${s}-${i}`}
                            highlightClassName="highlight"
                            searchWords={strings}
                            autoEscape={true}
                            textToHighlight={s}
                          />
                        )
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default SearchResult;
