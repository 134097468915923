const specialities: any = {
  internalMedicine: {
    en: 'Internal Medicine',
    ar: 'الباطنة',
  },
  hepatology: {
    en: 'Hepatology',
    ar: 'جهاز هضمي و كبد',
  },
  diabetes: {
    en: 'Diabetes and Endocrinology',
    ar: 'سكر وغدد صماء',
  },
  cardiologyAndvascular: {
    en: 'Cardiology and Vascular Disease',
    ar: 'قلب وأوعية دموية',
  },
  obstetricsGynecology: {
    en: 'Obstetrics and Gynecology',
    ar: 'نساء وتوليد',
  },
  chest: {
    en: 'Chest and Respiratory',
    ar: 'أمراض صدرية',
  },
  pediatrics: {
    en: 'Pediatrics and Neonatology',
    ar: 'أطفال وحديثي الولادة',
  },
  generalSurgery: {
    en: 'General Surgery',
    ar: 'جراحة عامة',
  },
  obesityandLaparoscopicSurge: {
    en: 'Obesity and Laparoscopic Surgery',
    ar: 'جراحات السمنة والمناظير',
  },
  orthopedic: {
    en: 'Orthopedics',
    ar: 'عظام',
  },
  ivf: {
    en: 'Ivf and Infertility',
    ar: 'خصوبة و حقن مجهري',
  },
  earAndNose: {
    en: 'Ear & Nose and Throat (ENT)',
    ar: 'أنف وأذن وحنجرة',
  },
  nephrology: {
    en: 'Nephrology',
    ar: 'أمراض كلي',
  },
  urology: {
    en: 'Urology',
    ar: 'مسالك بولية',
  },
  spinalSurgery: {
    en: 'Spinal Surgery',
    ar: 'جراحات العمود الفقري',
  },
  dermatology: {
    en: 'Dermatology',
    ar: 'أمراض جلدية',
  },
  neurology: {
    en: 'Neurology',
    ar: 'أمراض المخ والأعصاب',
  },
  ophthalmology: {
    en: 'Ophthalmology',
    ar: 'أمراض عيون / رمد',
  },
  oncology: {
    en: 'Oncology',
    ar: 'اورام',
  },
  familyMedicine: {
    en: 'Family Medicine',
    ar: 'طب الأسرة',
  },
  generalPractice: {
    en: 'General Practice',
    ar: 'ممارس عام',
  },
  andrology: {
    en: 'Andrology and Male Infertility',
    ar: 'أمراض عقم و ذكورة',
  },
  emergencyMedicine: {
    en: 'Emergency Medicine and ICU',
    ar: 'طب الطوارئ و الحالات الحرجة',
  },
  vascularSurgery: {
    en: 'Vascular surgery & Diabetic Foot',
    ar: 'جراحة أوعية دموية وقدم سكري',
  },
  hematology: {
    en: 'Hematology and Immunology',
    ar: 'أمراض الدم والمناعة',
  },
  rheumatology: {
    en: 'Rheumatology',
    ar: 'روماتيزم و أمراض المفاصل و المناعة',
  },
  allergyImmunology: {
    en: 'Allergy and Immunology',
    ar: 'أمراض الحساسية و المناعة',
  },
  cardiologyAndThoracic: {
    en: 'Cardiology and Thoracic Surgery (Heart & Chest)',
    ar: 'جراحة القلب والصدر',
  },
  dentistry: {
    en: 'Dentistry',
    ar: 'طب أسنان',
  },
  physiotherapyAndSport: {
    en: 'Physiotherapy and Sport Injurie',
    ar: 'علاج طبيعي واصابات ملاعب',
  },
  plasticSurgery: {
    en: 'Plastic Surgery',
    ar: 'جراحة تجميل',
  },
  neurosurgery: {
    en: 'Neurosurgery',
    ar: 'جراحة مخ و أعصاب',
  },
  nutrition: {
    en: 'Dietitian and Nutrition',
    ar: 'تغذية',
  },
  OncologySurgery: {
    en: 'Oncology Surgery',
    ar: 'جراحة الاورام',
  },
  pediatricSurgery: {
    en: 'Pediatric Surgery',
    ar: 'جراحة الأطفال',
  },
  psychology: {
    en: 'Psychology',
    ar: 'أمراض الطب النفسي',
  },
  painManagement: {
    en: 'Pain Management',
    ar: 'علاج الألم',
  },
  audiology: {
    en: 'Audiology',
    ar: 'سمع',
  },
  PhoniatricsSpeech: {
    en: 'Phoniatrics (Speech)',
    ar: 'تخاطب',
  },
  gastroenterology: {
    en: 'Gastroenterology and Endoscology',
    ar: 'جهاز هضمي ومناظير',
  },
};

export default specialities;
