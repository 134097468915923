import React from 'react';
// import { SupportIcon } from '../../assets/constants/images';
import { SadFaceIcon } from '../../assets/constants/images';
import { UserRoles } from '../../assets/constants/roles';
import SupportIcon from '../../assets/img/Mask Group 520.svg';
import './NotActive.scss';
const physicianMessage = () => (
  <div className="emptyPage">
    <div className="pageBody">
      <div className="icon-container">
        {/* <SupportIcon /> */}
        <img src={SupportIcon} alt="" />
      </div>
      <h2 className="text">Thanks for your registration</h2>
      <h2>
        one of our call center will communicate <br /> with you within 24 hours
        to activate your account
      </h2>
    </div>
  </div>
);

const employeeMessage = () => (
  <div className="emptyPage">
    <div className="pageBody">
      <div className="icon-container error">
        <SadFaceIcon />
      </div>
      <h2>
        للأسف لوحة المتابعة الخاصة بك معطلة الآن
        <br /> برجاء انتظار الطبيب لاعادة تفعيلها
      </h2>
    </div>
  </div>
);
const NotActive = (currentEmpUnit: any) =>
  [UserRoles.RadioTech, UserRoles.LabTech, UserRoles.Physician].includes(
    currentEmpUnit.role
  )
    ? physicianMessage()
    : [UserRoles.Nurse, UserRoles.Receptionist, UserRoles.Assistant].includes(
        currentEmpUnit.role
      )
    ? employeeMessage()
    : null;

export default NotActive;
