import React, { useState, useEffect } from 'react';
import './ScanType.scss';
import Toggle from 'react-toggle';
import dropDownArrow from '../../../../assets/img/dropdownArrow.svg';

type Props = {
  shouldToggle: boolean;
  changeScanTypes: (type: string, payload: any) => void;
  type: any;
  initialState: any;
};

const ScanType: React.FC<Props> = ({
  shouldToggle,
  changeScanTypes,
  type,
  initialState,
}) => {
  const [toggle, setSetToggle] = useState(shouldToggle);
  const [droppedDown, setDroppedDown] = useState(false);
  const [arrowClass, setArrowClass] = useState('downArrow');

  useEffect(() => {
    if (
      initialState &&
      initialState.subScans &&
      initialState.checked &&
      initialState.subScans.length > 0
    ) {
      setDroppedDown(true);
      setArrowClass('upArrow');
    }
  }, [initialState]);

  const expandSubScans = () => {
    if (droppedDown === false) {
      setDroppedDown(true);
      setArrowClass('upArrow');
    } else {
      setDroppedDown(false);
      setArrowClass('downArrow');
    }
  };

  const handleSelectSubScan = (e: any) => {
    let newArr = [...initialState.subScans];
    if (e.target.checked === false) {
      newArr = newArr.map((ele: any) => {
        if (ele.name === e.target.name) {
          return { ...ele, checked: false };
        } else {
          return { ...ele };
        }
      });
    } else {
      newArr = newArr.map((ele: any) => {
        if (ele.name === e.target.name) {
          return { ...ele, checked: true };
        } else {
          return { ...ele };
        }
      });
    }
    initialState.subScans = newArr;
    changeScanTypes(type, initialState);
  };

  const handleTofuChange = (e: any) => {
    const checked = e.target.checked;
    expandSubScans();
    initialState.checked = checked;
    setSetToggle(checked);
    let newArr = [...initialState.subScans];
    newArr = newArr.map((ele: any) => {
      return { ...ele, checked: false };
    });
    initialState.subScans = newArr;
    changeScanTypes(type, initialState);
  };

  return (
    <div
      className="scanType-container mb-4"
      style={{
        alignSelf: toggle ? 'inherit' : 'baseline',
      }}
    >
      <div className="scanType-container d-flex justify-content-between ">
        <div onClick={expandSubScans} className="scanType-container-title">
          {initialState.name}{' '}
          {toggle ? (
            <img
              className={`scanType-container-title-${arrowClass}`}
              src={dropDownArrow}
              alt="arrow"
            />
          ) : null}
        </div>
        <div>
          <Toggle
            className="custom-classname"
            defaultChecked={toggle}
            icons={false}
            onChange={handleTofuChange}
          />
        </div>
      </div>
      {toggle && droppedDown ? (
        <div className="scanType-container-scan">
          <div className="scanType-container-scan-subScan">
            <>
              {initialState &&
                initialState.subScans &&
                initialState.subScans.length > 0 &&
                initialState.subScans.map((subScan: any, index: any) => (
                  <div key={index} className="d-flex align-items-center">
                    <input
                      name={subScan.name}
                      type="checkbox"
                      checked={subScan.checked}
                      onChange={handleSelectSubScan}
                    />
                    <div className="name">{subScan.name}</div>
                  </div>
                ))}
            </>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ScanType;
