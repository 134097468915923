export default {
  HOME: '/',
  SEARCH: '/search',
  BOOKING: '/booking',
  ADD_CLINIC: '/addClinic',
  ADD_LAB: '/addLab',
  ADD_IMAGING_CENTER: '/addImagingCenter',
  ADD_PATIENT: '/addPatient',
  ADD_BOOKING: '/addBooking',
  REGISTER: '/register',
  TOKEN: '/token',
  SELECT_UNIT_TYPE: '/selectUnitType',
  CHECK_USER: '/checkUser',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgetPassword',
  CHANGE_PASSWORD: '/changePassword',
  COMPLETE_PROFILE: '/completeProfile',
  BOARD: '/board',
  LAB_BOARD: '/LabBoard',
  SETTINGS: '/settings',
  Finances: '/finances',
  REPORTS: '/reports',
  PROFILE: '/profile',
  UPCOMMING_BOOKING: '/upCommingBooking',
  PATIENT: '/:link',
  SUBSCRIPTION: '/subscription',
  PLANS: '/plans',
  EMP_PROFILE: '/empProfile',
  DENTAL_SERVICES: '/dentalServices',
};
