import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getCurruntDay } from '../../../assets/constants/currentDay';
import { GiftIcon } from '../../../assets/constants/images';
import { UserRoles } from '../../../assets/constants/roles';
import routes from '../../../assets/constants/routes';
import unitConfig from '../../../assets/constants/unitConfig';
import avatarIcon from '../../../assets/img/avatar.png';
import logoutIcon from '../../../assets/img/logout.svg';
import apiService from '../../../services/api';
import {
  logout,
  resetBoardBooking,
  resetBooking,
  resetLabBooking,
  resetUploader,
  resetBoard,
} from '../../../store/actions';
import { redeemUnitPoints } from '../../../store/actions/booking.actions';
import { rootState } from '../../../store/reducers';
import getFullName from '../../../utils/getFullName';
import {
  isAuthenticatedEmployee,
  isAuthenticatedPhysician,
  removeAllFromStorage,
} from '../../../utils/storage';
import { errorToast } from '../../../utils/toast';
import unitTypes from '../../../utils/unitTypes';
import ExpModal from '../../AddUnit/UnitDetails/ExpModal/ExpModal';
import Button from '../../UI/Form/Button/Button';
import './BoardHeader.scss';
import ClinicianUnits from './ClinicianUnits/ClinicianUnits';
import Menu from './Menu/Menu';
import PhysicianPatientSearch from './PhysicianPatientSearch/PhysicianPatientSearch';
import SearchPatient from './SearchPatient/SearchPatient';
import UploadManager from './UploadManager/UploadManager';

const BoardHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [giftOpen, setGiftOpen] = useState<boolean>(false);
  const [openModalExp, setOpenModalExp] = useState(false);
  const units = useSelector((state: rootState) => state.booking.units);
  const userProfile = useSelector((state: rootState) => state.auth.user);
  const { currentEmpUnit } = useSelector((state: rootState) => state.booking);
  const ref = useRef<any>(null);
  const changeLangHandler = () => {
    i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
  };

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const isAddUnitPageHandler = () => {
    return currentEmpUnit // check new registered doctor
      ? [routes.ADD_CLINIC, routes.ADD_LAB, routes.ADD_IMAGING_CENTER].includes(
          location.pathname
        )
      : true;
  };

  const isClinicianBoardHandler = () => {
    return !isAddUnitPageHandler();
    // return !isAddUnitPageHandler() && units && units.length > 0;
  };

  const resetStore = () => {
    dispatch(resetBooking());
    dispatch(resetBoard());
    dispatch(resetBoardBooking());
    dispatch(resetLabBooking());
    dispatch(resetUploader());
  };

  const onRedeem = () => {
    if (currentUnitDetails) dispatch(redeemUnitPoints(currentUnitDetails.pk));
  };

  const popOver = () => {
    return giftOpen && currentUnitDetails ? (
      <div ref={ref} className="popOver">
        <h3>
          {currentUnitDetails.points || 0}
          <small>points</small>
        </h3>
        <button
          onClick={onRedeem}
          disabled={
            !currentUnitDetails.points ||
            currentUnitDetails.points < unitConfig.redeemPoints
          }
          className="btn btn-primary btn-block"
        >
          Redeem
        </button>
      </div>
    ) : null;
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setGiftOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const logoutHandler = () => {
    dispatch(logout());
    resetStore();
    removeAllFromStorage();
    history.push(routes.HOME);
  };
  const hasActiveSubscription = () => {
    return currentUnitDetails && !currentUnitDetails.subscription
      ? true
      : currentUnitDetails?.subscription?.name === 'payAsYouGo'
      ? true
      : currentUnitDetails?.owner?.active &&
        new Date(currentUnitDetails?.subscription?.expirationDate || '') >
          new Date()
      ? true
      : false;
  };

  const openCloseModalExpHandler = () => {
    setOpenModalExp(!openModalExp);
  };

  const addExpHandler = async (name: string, amount: string) => {
    const addedEex = {
      name,
      amount,
      _md: getCurruntDay(),
      _ct: getCurruntDay(),
    };
    let isValid: boolean = false;
    isValid = !!addedEex.name && !!addedEex.amount;
    if (!isValid) {
      errorToast(t('board-header_page.expenses-errMsg'));
      return;
    }
    const payload = {
      expenses: {
        addedExpenses: [addedEex],
      },
      pk: currentUnitDetails?.pk,
    };
    await apiService.addExpenses(payload);
    openCloseModalExpHandler();
  };

  return (
    <div
      id="main-header"
      className="main-boardHeader-container align-items-sm-center align-items-start d-flex flex-sm-row  p-sm-4"
    >
      {openModalExp && (
        <ExpModal
          isOpen={openModalExp}
          onModalClose={openCloseModalExpHandler}
          addExpHandler={addExpHandler}
        />
      )}
      <div className="site-name">
        <h1>{t('board-header_page.sijil')}</h1>
      </div>
      <div className="clinic-data d-flex align-items-center">
        {isAddUnitPageHandler()}
        {isClinicianBoardHandler() && (
          <ClinicianUnits resetStore={resetStore} units={units} />
        )}

        {currentEmpUnit &&
          [
            UserRoles.Physician,
            UserRoles.LabTech,
            UserRoles.RadioTech,
          ].includes(currentEmpUnit.role) &&
          isClinicianBoardHandler() && <Menu />}

        {currentEmpUnit &&
          currentEmpUnit.type !== unitTypes.clinic &&
          [
            UserRoles.Physician,
            UserRoles.LabTech,
            UserRoles.RadioTech,
          ].includes(currentEmpUnit.role) &&
          isClinicianBoardHandler() && <UploadManager />}

        {currentEmpUnit &&
          ([
            UserRoles.Assistant,
            UserRoles.Nurse,
            UserRoles.Receptionist,
          ].includes(currentEmpUnit.role) ||
            ([UserRoles.Physician].includes(currentEmpUnit.role) &&
              [routes.BOOKING, routes.ADD_BOOKING, routes.ADD_PATIENT].includes(
                location.pathname
              ))) &&
          hasActiveSubscription() && (
            <div className={'d-flex row'}>
              <SearchPatient />
              {[
                UserRoles.Assistant,
                UserRoles.Nurse,
                UserRoles.Receptionist,
              ].includes(currentEmpUnit.role) &&
                currentUnitDetails?.type === unitTypes.clinic &&
                currentUnitDetails?.owner.specialty === 'dentistry' && (
                  <Button
                    name={t('employess_page.expenses')}
                    type="button"
                    isDisabled={false}
                    className="mx-3 expensesButton"
                    saveData={openCloseModalExpHandler}
                  />
                )}
            </div>
          )}

        {currentUnitDetails &&
          currentUnitDetails.type === unitTypes.clinic &&
          currentEmpUnit &&
          [
            UserRoles.Physician,
            UserRoles.LabTech,
            UserRoles.RadioTech,
          ].includes(currentEmpUnit.role) &&
          ![routes.BOOKING, routes.ADD_BOOKING, routes.ADD_PATIENT].includes(
            location.pathname
          ) &&
          isClinicianBoardHandler() && <PhysicianPatientSearch />}
      </div>
      <div className="lang d-flex align-items-center">
        <div className="info-container d-flex align-items-center">
          <img
            className="avatar"
            onClick={() => {
              if (
                isAuthenticatedPhysician() &&
                [routes.BOARD, routes.SETTINGS, routes.REPORTS].includes(
                  location.pathname
                )
              )
                history.push(routes.PROFILE);
              else if (isAuthenticatedEmployee()) {
                history.push(routes.EMP_PROFILE);
              }
            }}
            src={userProfile?.profileImg || avatarIcon}
            alt="personal Icon"
          />
          <p>{getFullName(userProfile?.name || '')}</p>
        </div>
        {currentEmpUnit &&
        ![UserRoles.Physician, UserRoles.LabTech, UserRoles.RadioTech].includes(
          currentEmpUnit.role
        ) ? (
          <div className="language d-flex align-items-center">
            <div>
              {i18n.language === 'ar' ? (
                <span onClick={changeLangHandler}>
                  {t('board-header_page.en')}
                </span>
              ) : (
                <span onClick={changeLangHandler}>
                  {t('board-header_page.ar')}
                </span>
              )}
            </div>
          </div>
        ) : null}
        {currentEmpUnit &&
          [UserRoles.Physician].includes(currentEmpUnit.role) &&
          currentUnitDetails &&
          currentUnitDetails.type === 'clinic' &&
          currentUnitDetails.subscription &&
          currentUnitDetails.subscription.type === 'basic' && (
            <button
              className="btn btn-default gift-btn"
              onClick={() => {
                setGiftOpen(!giftOpen);
              }}
            >
              {currentUnitDetails?.points >= unitConfig.redeemPoints && (
                <span className="notification"></span>
              )}
              <GiftIcon />
              {popOver()}
            </button>
          )}
        <img
          src={logoutIcon}
          onClick={logoutHandler}
          alt="personal Icon"
          className=" logout"
        />
      </div>
    </div>
  );
};

export default BoardHeader;
