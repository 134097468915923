const bookingStatus = {
  new: 'new',
  active: 'active',
  canceled: 'canceled',
  completed: 'completed',
  hold: 'hold',
  deleted: 'deleted',
  pending: 'pending',
};

export default bookingStatus;
