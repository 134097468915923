const parseArabic = (str: any) => {
  return str
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d: string) => {
      return d.charCodeAt(0) - 1632; // Convert Arabic numbers
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d: string) => {
      return d.charCodeAt(0) - 1776; // Convert Persian numbers
    })
    .replace(/[أإآ]/g, (d: string) => {
      return 'ا';
    });
};

export default parseArabic;
