import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import medicalDegrees from '../../../assets/constants/medicalDegrees';
import specialities from '../../../assets/constants/specialities';
import backArrow from '../../../assets/img/back.svg';
import medicalDegreeIcon from '../../../assets/img/medical-degree.svg';
import nameIcon from '../../../assets/img/personal.svg';
import specialityIcon from '../../../assets/img/speciality.svg';
import EventTracker from '../../../utils/analytics';
import TermsModal from '../../TermsModal/TermsModal';
import Button from '../../UI/Form/Button/Button';
import NameInput from '../../UI/Form/Name/NameInput';
import NumberInput from '../../UI/Form/NumberInput/NumberInput';
import Select from '../../UI/Form/Select/Select';
import formValidationSchema from './FormValidation';
import './RegisterForm.scss';
import MyDropzone from '../../Register/CheckProfile/DropZone';

type Props = {
  getImage: (file: any, type: string) => void;
  associationScanImg: string;
  isUploadAssossiation: boolean;
  nextStepHandler: (payload: { type: string; data: any }) => void;
  registerHandler: (values: any) => Promise<void>;
  currentSegment: string;
  goBack: () => void;
  personalData: any;
  isSubmitting: boolean;
};

const RegisterProfileForm: React.FC<Props> = ({
  getImage,
  associationScanImg,
  isUploadAssossiation,
  nextStepHandler,
  registerHandler,
  currentSegment,
  goBack,
  personalData,
  isSubmitting,
}) => {
  const { t, i18n } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const openModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const changeTermshandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('checked', e.target.checked);
    EventTracker.sendEvent('CONDITIONA_AND_TERMS_CHECKED', {});
    setIsTermsChecked(e.target.checked);
  };

  // const getImageHelper = (file: any, type: string) => {
  //   getImage(file, type);
  //   console.log('getImageHelprWorks');
  // };

  return (
    <div className="register-form d-flex flex-column w-100 p-0 p-sm-5 mt-4 mt-sm-0">
      {isOpenModal && (
        <TermsModal isOpen={isOpenModal} onModalClose={openModal} />
      )}
      <div className="name d-flex justify-content-between">
        <p>{t('pages.register')}</p>
      </div>
      <div className="register-form-container align-items-center d-flex flex-column mt-4">
        <Formik
          initialValues={personalData}
          validationSchema={formValidationSchema(t)}
          enableReinitialize
          onSubmit={async (values) => {
            // console.log('values', values);
            // console.log('Imagesource', associationScanImg);
            const vv = formValidationSchema(t).cast(values);
            EventTracker.sendEvent('SET_PERSONAL_DATA_BUTTON_CLICKED', {
              ...vv,
            });
            nextStepHandler({
              data: vv,
              type: currentSegment,
            });
            await registerHandler(vv);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }: FormikProps<any>) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div
                dir="rtl"
                className="d-flex flex-column flex-sm-row mt-2 mt-sm-0 name-container password-container"
              >
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-2">
                    <img src={nameIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('register_page.title')}
                    </h6>
                  </div>
                  <NameInput
                    name="firstName"
                    placeholder={t('register_page.fName')}
                  />
                </div>
                <div className="d-flex flex-column align-self-stretch align-self-sm-end">
                  <NameInput
                    name="lastName"
                    placeholder={t('register_page.lName')}
                  />
                </div>
              </div>
              <div className="password-container d-flex flex-column  mt-2">
                <div className="d-flex flex-column specialtyContainer">
                  <div className="name-group d-flex align-self-start mb-2">
                    <img src={specialityIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('register_page.specialty')}
                    </h6>
                  </div>
                  <Select
                    label={t('register_page.specialty')}
                    name="specialty"
                    options={Object.keys(specialities).map((key) => ({
                      label:
                        i18n.language === 'ar'
                          ? specialities[key].ar
                          : specialities[key].en,
                      value: key,
                    }))}
                  />
                </div>
              </div>
              <div className="password-container d-flex flex-column flex-sm-row mt-2">
                <div className="d-flex flex-column medicalDegContainer">
                  <div className="name-group d-flex align-self-start mb-2">
                    <img src={medicalDegreeIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('register_page.med-degree')}
                    </h6>
                  </div>
                  <Select
                    label={t('register_page.med-degree')}
                    name="medicalDegree"
                    options={Object.keys(medicalDegrees).map((key) => ({
                      label:
                        i18n.language === 'ar'
                          ? medicalDegrees[key].ar
                          : medicalDegrees[key].en,
                      value: key,
                    }))}
                  />
                </div>

                {/**<div className="d-flex flex-column license">
                  <div className="name-group d-flex mb-2">
                    <img src={medicalDegreeIcon} alt="mobile icon" />
                    <h6 className="align-self-end">
                      {t('register_page.license-degree')}
                    </h6>
                  </div>
                  <NumberInput
                    className="title"
                    type="text"
                    name="licenseNumber"
                    placeholder={t('register_page.license-degree-placeholder')}
                  />
                  </div>**/}
              </div>

              <div className="password-container d-flex flex-column flex-sm-row mt-2">
                <div className="national-id-img  w-100 p-3">
                  <div className="name-attr d-flex justify-content-between">
                    <p>
                      {t('complete_profile.asso-img')}
                      {/* <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span> */}
                    </p>
                    <div className="error ">
                      {errors.associationScan && touched.associationScan ? (
                        <span className="error">{errors.associationScan}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="">
                      <MyDropzone
                        imgSrc={associationScanImg}
                        getImage={(file, type) => {
                          getImage(file, type);
                          setFieldValue('associationScan', file);
                        }}
                        type="association"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="create-account d-flex flex-column align-items-center my-2">
                <div className="check d-flex align-items-center mb-5">
                  <input
                    className="check-input"
                    type="checkbox"
                    onChange={changeTermshandler}
                  />
                  <label className="check-label" onClick={openModal}>
                    {t('subscription.terms')}
                  </label>
                </div>
                <Button
                  type="submit"
                  isSubmitting={isSubmitting || isUploadAssossiation}
                  isDisabled={!isTermsChecked}
                  name={t('complete_profile.create-account')}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div
        className="back align-self-start mt-2"
        onClick={() => {
          goBack();
        }}
      >
        <img src={backArrow} alt="back" />
        <span>{t('otp_page.back')}</span>
      </div>
    </div>
  );
};

export default RegisterProfileForm;
