const dentalCategories: any = [
  {
    type: 'RESTORATIVE_DENTISTRY',
    name: 'Restorative Dentistry',
    services: [
      {
        type: 'AMALGAM',
        name: 'Amalgam',
        steps: ['Cavity Prep', 'Filling'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'DIRECT_COMPOSITE_ANTERIOR',
        name: 'Direct Composite anterior',
        steps: ['Cavity Prep', 'Filling'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'DIRECT_COMPOSITE_POSTERIOR',
        name: 'Direct Composite Posterior',
        steps: ['Cavity Prep', 'Filling'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'INDIRECT_COMPOSITE_ANTERIOR',
        name: 'Indirect Composite - anterior',
        steps: ['Cavity Prep', 'Impression', 'Cementation'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'INDIRECT_COMPOSITE_POSTERIOR',
        name: 'Indirect Composite - Posterior',
        steps: ['Cavity Prep', 'Impression', 'Cementation'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'INLAY_ONLAY_PORCELAIN',
        name: 'Inlay/onlay porcelain',
        steps: ['Cavity Prep', 'Impression', 'Try in', 'Cementation'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'INLAY_ONLAY_ZIRCON',
        name: 'Inlay/onlay Zircon',
        steps: ['Cavity Prep', 'Impression', 'Try in', 'Cementation'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'INLAY_ONLAY_EMAX',
        name: 'Inlay/onlay Emax',
        steps: ['Cavity Prep', 'Impression', 'Try in', 'Cementation'],
        price: 0.0,
        labIncluded: true,
      },
    ],
  },

  {
    type: 'ENDODONTICS',
    name: 'Endodontics',
    services: [
      {
        type: 'PULPOTOMY',
        name: 'Pulpotomy',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'PULPECTOMY',
        name: 'Pulpectomy',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ROOT_CANAL_TREATMENT_ANTERIOR',
        name: 'Root canal treatment -anterior',
        steps: [
          'Access Prep. & Pulp extirpation',
          'Working length determination',
          'Mechanical Preparation',
          'Obturation',
        ],
        workingLengthDetermination: true,
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ROOT_CANAL_TREATMENT_BICUSPID',
        name: 'Root canal treatment -bicuspid',
        steps: [
          'Access Prep. & Pulp extirpation',
          'Working length determination',
          'Mechanical Preparation',
          'Obturation',
        ],
        workingLengthDetermination: true,
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ROOT_CANAL_TREATMENT_FIRST_AND_SECOND_MOLAR',
        name: 'Root canal treatment - First and second molar',
        steps: [
          'Access Prep. & Pulp extirpation',
          'Working length determination',
          'Mechanical Preparation',
          'Obturation',
        ],
        workingLengthDetermination: true,
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ROOT_CANAL_TREATMENT_THIRD_MOLAR',
        name: 'Root canal treatment - third molar',
        steps: [
          'Access Prep. & Pulp extirpation',
          'Working length determination',
          'Mechanical Preparation',
          'Obturation',
        ],
        workingLengthDetermination: true,
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'INCOMPLETE_ENDODONTIC_THERAPY_INOPERABLE_OR_FRACTURED_TOOTH',
        name: 'Incomplete endodontic therapy; inoperable or fractured tooth',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'INTERNAL_ROOT_REPAIR_OF_PERFORATION_DEFECTS',
        name: 'Internal root repair of perforation defects',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'RETREATMENT_OF_PREVIOUS_ROOT_CANAL_THERAPY_ANTERIOR',
        name: 'Retreatment of previous root canal therapy - anterior',
        steps: [
          'Removal of old restoration',
          'Disinfection',
          'Working length determination',
          'Mechanical preparation',
          'Re-obturation',
        ],
        price: 0.0,
        workingLengthDetermination: true,
        labIncluded: false,
      },
      {
        type: 'RETREATMENT_OF_PREVIOUS_ROOT_CANAL_THERAPY_BICUSPID',
        name: 'Retreatment of previous root canal therapy - bicuspid',
        steps: [
          'Removal of old restoration',
          'Disinfection',
          'Working length determination',
          'Mechanical preparation',
          'Re-obturation',
        ],
        price: 0.0,
        workingLengthDetermination: true,
        labIncluded: false,
      },
      {
        type: 'RETREATMENT_OF_PREVIOUS_ROOT_CANAL_THERAPY_MOLAR',
        name: 'Retreatment of previous root canal therapy - molar',
        steps: [
          'Removal of old restoration',
          'Disinfection',
          'Working length determination',
          'Mechanical preparation',
          'Re-obturation',
        ],
        price: 0.0,
        workingLengthDetermination: true,
        labIncluded: false,
      },
      {
        type: 'APEXIFICATION_RECALCIFICATION',
        name: 'Apexification/recalcification',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'APICOECTOMY_PERIRADICULAR_SURGERY_ANTERIOR',
        name: 'Apicoectomy/periradicular surgery - anterior',
        steps: ['Surgery', 'Stetch removal'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'APICOECTOMY_PERIRADICULAR_SURGERY_BICUPSID',
        name: 'Apicoectomy/periradicular surgery - bicuspid',
        steps: ['Surgery', 'Stetch removal'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'APICOECTOMY_PERIRADICULAR_SURGERY_MOLAR',
        name: 'Apicoectomy/periradicular surgery - molar',
        steps: ['Surgery', 'Stetch removal'],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'RETROGRADE_FILLING_PER_ROOT',
        name: 'Retrograde filling - per root',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ROOT_AMPUTATION_PER_ROOT',
        name: 'Root amputation - per root',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'FIXED_PROSTHODONTICS',
    name: 'Fixed Prosthodontics',
    services: [
      {
        type: 'RESIN_TEMPORARY_CROWN',
        name: 'Resin/Temporary crown',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'FULL_METAL_CROWN',
        name: 'Full metal crown',
        steps: ['Tooth preparation', 'Impression', 'Try in', 'Delivery'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'ALL_PORCELAIN_CROWN',
        name: 'All Porcelain crown',
        steps: [
          'Tooth preparation',
          'Impression',
          'Shade selection',
          'Try in',
          'Delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'PFM_CROWN',
        name: 'PFM (Porcelain fused to metal) crown',
        steps: [
          'Tooth preparation',
          'Impression',
          'Shade selection',
          'Try in',
          'Delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'ZIRCON_CROWN',
        name: 'Zircon crown',
        steps: [
          'Tooth preparation',
          'Impression',
          'Shade selection',
          'Try in',
          'Delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'EMAX_CROWN',
        name: 'Emax crown',
        steps: [
          'Tooth preparation',
          'Impression',
          'Shade selection',
          'Try in',
          'Delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'RESIN_TEMPORARY_BRIDGE',
        name: 'Resin/Temporary bridge',
        selectionOption: 'MULTIPLE_TEETH',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'FULL_METAL_BRIDGE',
        name: 'Full metal bridge',
        selectionOption: 'MULTIPLE_TEETH',
        steps: ['Teeth preparation', 'Impression', 'Try in', 'Delivery'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'ALL_PORCELAIN_BRIDGES',
        name: 'All Porcelain bridges',
        selectionOption: 'MULTIPLE_TEETH',
        steps: [
          'Teeth preparation',
          'Impression',
          'Shade selection',
          'Try in',
          'Delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'PORCELAIN_FUSED_TO_METAL_BRIDGE',
        name: 'Porcelain fused to metal bridge',
        selectionOption: 'MULTIPLE_TEETH',
        steps: ['Teeth preparation', 'Impression', 'Try in', 'Delivery'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'ZIRCON_BRIDGE',
        name: 'Zircon bridge',
        selectionOption: 'MULTIPLE_TEETH',
        steps: ['Teeth preparation', 'Impression', 'Try in', 'Delivery'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'EMAX_BRIDGE',
        name: 'Emax bridge',
        selectionOption: 'MULTIPLE_TEETH',
        steps: ['Teeth preparation', 'Impression', 'Try in', 'Delivery'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'PREFABRICATED_STAINLESS_STEEL_CROWN_PRIMARY_TOOTH',
        name: 'Prefabricated stainless steel crown - primary tooth',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'PREFABRICATED_STAINLESS_STEEL_CROWN_PERMANENT_TOOTH',
        name: 'Prefabricated stainless steel crown - permanent tooth',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'SCREW_POST_AND_CORE_BUILD_UP',
        name: 'Screw post and core build up',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'FIBER_POST_AND_CORE_BUILD_UP',
        name: 'Fiber post and core build up',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'CASTED_POST_AND_CORE',
        name: 'Casted post and core',
        steps: ['Root preparation', 'Impression', 'Cementation'],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'REMOVABLE_PROSTHODONTICS',
    name: 'Removable Prosthodontics',
    services: [
      {
        type: 'DENTURE_ONE_ARCH_RESIN_BASE',
        name: 'denture - one arch- resin base',
        selectionOption: 'ONE_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'DENTURE_ONE_ARCH_FLEXIBLE',
        name: 'denture - one arch- flexible',
        selectionOption: 'ONE_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'COMPLETE_DENTURE_BOTH_ARCHES_RESIN_BASE',
        name: 'Complete denture - both arches- resin base',
        selectionOption: 'BOTH_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'COMPLETE_DENTURE_BOTH_ARCHES_FLEXIBLE',
        name: 'Complete denture - both arches- flexible',
        selectionOption: 'BOTH_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'IMMEDIATE_DENTURE_ONE_ARCH',
        name: 'Immediate denture - one arch',
        selectionOption: 'ONE_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
      },
      {
        type: 'IMMEDIATE_DENTURE_BOTH_ARCHES',
        name: 'Immediate denture - both arches',
        selectionOption: 'BOTH_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'PARTIAL_DENTURE_RESIN_BASE',
        name: 'partial denture - resin base',
        selectionOption: 'MULTIPLE_TEETH_NOT_CONNECTED',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'PARTIAL_DENTURE_FLEXIBLE',
        name: 'partial denture - flexible',
        selectionOption: 'MULTIPLE_TEETH_NOT_CONNECTED',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'PARTIAL_DENTURE_CASE_METAL_FRAMEWORK_WITH_RESIN_BASES',
        name: 'partial denture - cast metal framework with resin bases',
        selectionOption: 'MULTIPLE_TEETH_NOT_CONNECTED',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'OVERDENTURE_ONE_ARCH',
        name: 'overdenture - one arch',
        selectionOption: 'ONE_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'OVERDENTURE_BOTH_ARCHES',
        name: 'overdenture - both arches',
        selectionOption: 'BOTH_ARCH',
        steps: [
          'primary impression',
          'secondary impression',
          'bite registration',
          'shade selection',
          'try in',
          'delivery',
        ],
        price: 0.0,
        labIncluded: true,
      },
    ],
  },
  {
    type: 'EXTRACTION',
    name: 'Extraction',
    services: [
      {
        type: 'SIMPLE_EXTRACTION',
        name: 'Simple extraction',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'REMAINING_ROOT_EXTRACTION',
        name: 'Remaining root extraction',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'SURGICAL_REMOVAL_OF_IMPACTED_THOTH_SOFT_TISSUE',
        name: 'Surgical removal of impacted tooth (soft tissue)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'SURGICAL_REMOVAL_OF_IMPACTED_TOOTH_PARTIALLY_BONY',
        name: 'Surgical removal of impacted tooth (partially bony)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'SURIGCAL_REMOVAL_OF_IMPACTED_TOOTH_COMPLETELY_BONY',
        name: 'Surgical removal of impacted tooth (completely bony)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'REMOVAL_OF_IMPACTED_TOOH_COMPLETELY_BONY_COMPLICATIONS',
        name:
          'Removal of impacted tooth (completely bony, with unusual surgical complications)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'SURGICAL_REMOVAL_OF_RESIDUAL_TOOTH_ROOTS_CUTTING_PROCEDURE',
        name: 'Surgical removal of residual tooth roots (cutting procedure).',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'DECIDUOUS_EXTRACTION',
        name: 'Deciduous extraction',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'PERIODONTICS',
    name: 'Periodontics',
    services: [
      {
        type: 'GINGIVECTOMY',
        name: 'Gingivectomy',
        selectionOption: 'MULTIPLE_TEETH',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ROOT_PLANNING',
        name: 'root planning',
        selectionOption: 'MULTIPLE_TEETH',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'GINGIVAL_PERIODONTAL_SCALING',
        name: 'gingival/Periodontal scaling',
        selectionOption: 'MULTIPLE_TEETH_ONE_ARCH_BOTH_ARCH',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'PREVENTIVE_PROCEDURES',
    name: 'Preventive Procedures',
    services: [
      {
        type: 'TOPICAL_FLUORIDE_AND_PROPHYLAXIS',
        name: 'Topical fluoride and prophylaxis.',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'FISSURE_SEALANT',
        name: 'Fissure Sealant',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type:
          'APPLICATION_OF_DESENSITIZING_RESIN_FOR_CERVICAL_AND_OR_ROOT_SURFACE',
        name:
          'Application of desensitizing resin for cervical and/or root surface',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'SPACE_MAINTAINERS',
    name: 'Space Maintainers',
    services: [
      {
        type: 'FIXED_SPACE_MAINAINER',
        name: 'Fixed space maintainer',
        steps: ['Impression', 'delivery'],
        price: 0.0,
        labIncluded: true,
      },
      {
        type: 'REMOVABLE_SPACE_MAINTAINER',
        name: 'Removable space maintainer',
        steps: ['Impression', 'delivery'],
        price: 0.0,
        labIncluded: true,
      },
    ],
  },
  {
    type: 'RADIOGRAPHS',
    name: 'Radiographs ',
    services: [
      {
        type: 'PERIAPICAL_RADIOGRAPH',
        name: 'Periapical radiograph',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'PANORAMIC_RADIGRAPH',
        name: 'Panoramic radiograph',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'OCCLUSAL_FILM',
        name: 'occlusal film',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'BITEWING',
        name: 'Bitewing',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'CT_CONVENTIONAL',
        name: 'CT (computed tomography)- conventional',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'CBCT',
        name: 'CBCT (cone beam CT)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'PATHOLOGY',
    name: 'Pathology',
    services: [
      {
        type: 'BIOPSY_OF_ORAL_TISSUE_HARD',
        name: 'Biopsy of oral tissue - hard (bone, tooth).',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'BIOPSY_OF_ORAL_TISSUE_SOFT',
        name: 'Biopsy of oral tissue - soft (all others)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'RECEMENTATION',
    name: 'Recementation',
    services: [
      {
        type: 'INLAY_RECEMENTATION',
        name: 'Inlay recementation',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'CROWN_RECEMENTATION',
        name: 'Crown recementation',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'BRIDGE_RECEMENTATION',
        name: 'bridge recementation',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'RECEMENTATION_OF_SPACE_MAINTAINER',
        name: 'Recementation of space maintainer',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'FULL_AND_PARTIAL_DENTURE_REPAIRS_ACRYLIC',
    name: 'Full and Partial Denture Repairs, Acrylic',
    services: [
      {
        type: 'REPAIR_OF_COMPLETE_DENTURES',
        name: 'Repair of Complete Dentures.',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'REPAIR_OF_PARTIAL_DENTURES',
        name: 'Repair of Partial Dentures.',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'RELINE_COMPLETE_DENTURE',
        name: 'Reline complete denture',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'RELINE_PARTIAL_DENTURE',
        name: 'Reline partial denture',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'ALVEOLAR_ORGINGIVAL_RECONSTRUCTION',
    name: 'Alveolar or Gingival Reconstruction',
    services: [
      {
        type: 'ALVEOPLASTY_WITHOUT_EXTRACTION',
        name: 'Alveoplasty (without extractions)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'ALVEOPLASTY_WITH_EXTRACTION',
        name: 'Alveoplasty (with extractions)',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'VESTIBULOPLASTY_RIDGE_EXTENSION',
        name: 'Vestibuloplasty - ridge extension',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
  {
    type: 'ANESTHESIA',
    name: 'Anesthesia',
    services: [
      {
        type: 'DEEP_SEDATION_GENERAL_ANESTHESIA',
        name: 'Deep sedation/general anesthesia',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
      {
        type: 'INTERVENOUS_CONSCIOUS_SEDATION_ANALGESIA',
        name: 'Intravenous conscious sedation/analgesia',
        steps: [],
        price: 0.0,
        labIncluded: false,
      },
    ],
  },
];

export default dentalCategories;
