import React, { useState } from 'react';
import './Employee.scss';
import NoEmployees from './NoEmployees/NoEmployees';
import AddEmployees from './AddEmployees/AddEmployees';
import { useTranslation } from 'react-i18next';

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  saveClinicData: () => Promise<void>;
  currentSegment: string;
  isSubmitting: boolean;
  initialState: {};
  unitType: string;
};
const Employees: React.FC<Props> = ({
  currentSegment,
  nextStepHandler,
  goBackHandler,
  saveClinicData,
  initialState,
  isSubmitting,
  unitType,
}) => {
  const [showNoEmployeePage, setShowNoEmployeePage] = useState(false);
  const showNoEmployeePageHandler = () => {
    setShowNoEmployeePage(false);
  };
  const { t } = useTranslation();
  return (
    <div className="employees_page d-flex flex-column">
      <div className="description d-flex justify-content-between ">
        <p className="descriptionText p-4 mb-4">
          {t('employess_page.description')}
        </p>
      </div>
      {showNoEmployeePage ? (
        <NoEmployees showNoEmployeePageHandler={showNoEmployeePageHandler} />
      ) : (
        <AddEmployees
          nextStepHandler={nextStepHandler}
          goBackHandler={goBackHandler}
          saveClinicData={saveClinicData}
          currentSegment={currentSegment}
          initialState={initialState}
          isSubmitting={isSubmitting}
          unitType={unitType}
        />
      )}
    </div>
  );
};

export default Employees;
