import React, { useState, useEffect } from 'react';
import './Reports.scss';

import Header from '../../components/Reports/Header/Header';
import Charts from '../../components/Reports/Charts/Charts';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../store/reducers';
import { getUnitStatistics } from '../../store/actions';
import { formatCAlDate } from '../../utils/moment';
// import WithSubscription from '../../components/Subscription/WithSubscription';

const Reports: React.FC = () => {
  const dispatch = useDispatch();

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const statistics = useSelector(
    (state: rootState) => state.booking.statistics
  );

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [days, setDays] = useState<{ [key: number]: number }>([]);

  const [dayTotalCost, setDayTotalCost] = useState<number>(0);

  const [monthCosts, setMonthCosts] = useState<{ [key: number]: number }>({});

  const [emps, setemps] = useState<any>({});

  const [bookingsTypes, setBookingsTypes] = useState<any>({});

  const [shifts, setShifts] = useState<any>({});

  const [currentDate, setCurrentDate] = useState(0);

  const [totalBookings, setTotalBookings] = useState(0);

  useEffect(() => {
    if (!currentUnitDetails || !startDate) return;
    setMonthCosts({});
    dispatch(getUnitStatistics(currentUnitDetails.pk, startDate, endDate));
  }, [currentUnitDetails, startDate, endDate, dispatch]);

  useEffect(() => {
    // console.log('statistics', statistics);
    const costs: { [key: number]: number } = {};
    statistics.forEach((ele) => {
      const day = new Date(ele.date).getDate();
      costs[day] = ele.total;
    });
    // console.log('costs', costs);

    setMonthCosts({ ...days, ...costs });
    // setMonthCosts(costs);
  }, [days, statistics]);

  useEffect(() => {
    if (!currentDate) return;

    const eDate = new Date(
      new Date(new Date(currentDate)).setHours(23, 59, 0, 0)
    ).getTime();

    const filteredStatistics = statistics.find(
      (ele) => ele.date >= currentDate && ele.date <= eDate
    );
    if (filteredStatistics) {
      // console.log('filteredStatistics', filteredStatistics);
      setDayTotalCost(filteredStatistics.total);
      const {
        emps: empsData,
        bookings,
        shifts: shiftsData,
        bookingsIds,
      } = filteredStatistics;
      // console.log('empsData', empsData);
      setemps(empsData);
      setBookingsTypes(bookings);
      setShifts(shiftsData);
      setTotalBookings(bookingsIds?.length || 0);
    } else {
      setDayTotalCost(0);
      setemps({});
      setBookingsTypes({});
      setShifts({});
      setTotalBookings(0);
    }
  }, [currentDate, statistics]);

  const getUnitStatisticsData = (
    sDate: number,
    eDate: number,
    daysData: number[] = []
  ) => {
    const monthStartDate = formatCAlDate(new Date(sDate));
    const monthEndtDate = formatCAlDate(new Date(eDate));
    // console.log(monthStartDate, monthEndtDate);
    setStartDate(monthStartDate);
    setEndDate(monthEndtDate);
    const dd: any = {};
    daysData.forEach((day) => {
      return (dd[day] = 0);
    });
    // console.log('daysData', dd);
    setDays(dd);
  };

  const getDayStatistics = (date: number) => {
    setCurrentDate(date);
  };

  return (
    <>
      <div className="d-flex flex-column p-5">
        <Header
          getUnitStatisticsData={getUnitStatisticsData}
          getDayStatistics={getDayStatistics}
          dayTotalCost={dayTotalCost}
          currentUnitDetails={currentUnitDetails}
        />
        <Charts
          costs={monthCosts}
          emps={emps}
          shifts={shifts}
          bookingsTypes={bookingsTypes}
          clinic={currentUnitDetails}
          totalBookings={totalBookings}
        />
      </div>
    </>
  );
};

export default Reports;
