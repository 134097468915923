import React from 'react';
import './RegisterClinicDetails.scss';

import clinicImg from '../../../assets/img/clinic.png';

const ClinicDetails = () => {
  return (
    <div className="clinicDetails-container d-flex">
      <img src={clinicImg} alt="clinic" />
    </div>
  );
};

export default ClinicDetails;
