import React, { useEffect, useState } from 'react';
import './TreatmentComponent.scss';
import Modal from 'react-modal';
import OperationComponent from './OperationComponent/OperationComponent';
import ComplainComponent from './ComplainComponent/ComplainComponent';
import DentalServiceModal from '../../../DentalServiceModal/DentalServiceModal';
import dentalServices from '../../../../assets/constants/dentalServices';
import {
  updateBoardCurrentFinancailLog,
  updateReservation,
} from '../../../../store/actions/board.actions';
import { rootState } from '../../../../store/reducers';

import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentBooking } from '../../../../store/actions/board.actions';
import { fLog } from '../../../../types';
import {
  getCurruntDay,
  getDayFormat,
} from '../../../../assets/constants/currentDay';
type Props = {
  updateObject: (update: any) => void;
};
const TreatmentComponent: React.FC<Props> = ({ updateObject }) => {
  const numOfCanals = 6;
  const [categories, setCategories] = useState<any>([]);
  const dispatch = useDispatch();
  const [tab, setTab] = useState('complain');
  const [isOperationModalOpen, setIsOperationModalOpen] = useState(false);
  //  const [operations, setOperations] = useState<any>([]);
  const [updatePriceModal, setUpdatePriceModal] = useState<boolean>(false);
  const [priceToUpdate, setPriceToUpdate] = useState(0);
  const [operationIdxToUpdate, setOperationIdxToUpdate] = useState(0);
  const [isCancellingOperation, setIsCancellingOperation] = useState<boolean>(
    true
  );
  const currentBooking = useSelector(
    (state: rootState) => state.board.currentBooking
  );
  const setOperations = (operations: any) => {
    dispatch(updateReservation({ operations, lastChange: new Date() }));
  };

  const changeTabHandler = (tab: string) => {
    setTab(tab);
  };
  const currentUnitDetails: any = useSelector(
    (statee: rootState) => statee.booking.currentUnitDetails
  );
  useEffect(() => {
    const incomingCategories = [];
    for (const categoryKey in currentUnitDetails?.services) {
      incomingCategories.push(currentUnitDetails?.services[categoryKey]);
    }
    setCategories(incomingCategories);
  }, []);
  const reservation = useSelector(
    (state: rootState) => state.board.reservation
  );
  const boardCurrentFinancailLog = useSelector(
    (state: rootState) => state.board.currentFinancailLog
  );
  const changeOperationModalStatus = () => {
    setIsOperationModalOpen((prev) => !prev);
  };
  const addOperation = (
    workingTeeth: number[],
    ageType: string,
    service: string,
    price: number
  ) => {
    if (workingTeeth.length === 0 || !service) return;
    if (!service || workingTeeth.length === 0) return;
    let currentService;
    let currentCategory;
    for (const category of categories) {
      for (const s of category.services) {
        if (s.type === service) {
          currentCategory = category.name;
          currentService = s;
        }
      }
    }
    const operation = {
      mlog: [{ md: currentBooking.date, operationStatus: 'Planned' }],
      operation: currentService.name,
      category: currentCategory,
      ageType,
      workingTeeth,
      operationSteps: currentService.steps.map((s: any) => {
        return {
          step: s,
          stepCompleted: false,
          mlog: [{ md: currentBooking.date, stepCompleted: false }],
        };
      }),
      operationStatus: 'Planned',
      price,
      labIncluded: currentService.labIncluded,
      workingLengthDetermination:
        currentService.workingLengthDetermination ?? false,
      canalsMeasurements: currentService.workingLengthDetermination
        ? {
            canals: Array(numOfCanals).fill(''),
            lengths: Array(numOfCanals).fill(''),
            MFSize: Array(numOfCanals).fill(''),
          }
        : null,
    };

    if (boardCurrentFinancailLog?.fLog) {
      const financalLog: fLog = {
        date: getCurruntDay(),
        record: 'Serviceadded',
        value: price,
        balance: boardCurrentFinancailLog.fLog.length
          ? boardCurrentFinancailLog.fLog[
              boardCurrentFinancailLog.fLog.length - 1
            ].balance + price
          : price,
        notes: currentService.name,
      };
      dispatch(
        updateBoardCurrentFinancailLog([
          ...boardCurrentFinancailLog.fLog,
          financalLog,
        ])
      );
    }
    const updatedBooking = { ...currentBooking };
    dispatch(updateCurrentBooking(updatedBooking));
    setOperations([...reservation.operations, operation]);

    changeOperationModalStatus();
  };
  const updateOperationStatusLog = (status: string, opIdx: number) => {
    const idx = reservation.operations[opIdx].mlog.findIndex(
      (log: any) => log.md === currentBooking.date
    );
    if (idx > -1) {
      reservation.operations[opIdx].mlog[idx].operationStatus = status;
    } else {
      reservation.operations[opIdx].mlog.push({
        md: currentBooking.date,
        operationStatus: status,
      });
    }
  };

  const checkOperationStep = (index: number, step: string) => {
    let allChecked: boolean = true;
    let noneChecked: boolean = true;
    for (const currentStep of reservation.operations[index].operationSteps) {
      if (currentStep.step === step) {
        currentStep.stepCompleted = !currentStep.stepCompleted;
        const idx = currentStep.mlog.findIndex(
          (log: any) => log.md === currentBooking.date
        );
        if (idx === -1) {
          currentStep.mlog.push({
            md: currentBooking.date,
            stepCompleted: currentStep.stepCompleted,
          });
        } else {
          currentStep.mlog[idx].stepCompleted = currentStep.stepCompleted;
        }
      }
      if (!currentStep.stepCompleted) allChecked = false;
      if (currentStep.stepCompleted) noneChecked = false;
    }
    if (allChecked) {
      reservation.operations[index].operationStatus = 'Completed';
      updateOperationStatusLog('Completed', index);
    }
    if (noneChecked) {
      reservation.operations[index].operationStatus = 'Planned';
      updateOperationStatusLog('Planned', index);
    }
    if (!noneChecked && !allChecked) {
      reservation.operations[index].operationStatus = 'In progress';
      updateOperationStatusLog('In progress', index);
    }
    setOperations([...reservation.operations]);
  };
  const cancelOperation = (idx: number) => {
    setIsCancellingOperation(true);

    if (boardCurrentFinancailLog?.fLog) {
      const financalLog: fLog = {
        date: getCurruntDay(),
        record: 'ServiceCanceled',
        value: -reservation.operations[idx].price,
        balance: boardCurrentFinancailLog.fLog.length
          ? boardCurrentFinancailLog.fLog[
              boardCurrentFinancailLog.fLog.length - 1
            ].balance - reservation.operations[idx].price
          : -reservation.operations[idx].price,
        notes: reservation.operations[idx].operation,
      };
      dispatch(
        updateBoardCurrentFinancailLog([
          ...boardCurrentFinancailLog.fLog,
          financalLog,
        ])
      );
    }
    if (reservation.operations[idx].operationStatus === 'Planned') {
      const updatedBooking = { ...currentBooking };
      dispatch(updateCurrentBooking(updatedBooking));
      reservation.operations.splice(idx, 1);
    } else {
      reservation.operations[idx].operationStatus = 'Canceled';
      updateOperationStatusLog('Canceled', idx);
      if (reservation.operations[idx].price > 0) setUpdatePriceModal(true);
      setOperationIdxToUpdate(idx);
    }
    setOperations(reservation.operations);
  };

  const addNoteOperation = (index: number, note: string) => {
    reservation.operations[index].note = note;
    setOperations([...reservation.operations]);
  };

  const updateOperationPrice = () => {
    if (isNaN(priceToUpdate)) return;
    if (isCancellingOperation) {
      if (reservation.operations[operationIdxToUpdate].price >= priceToUpdate) {
        reservation.operations[operationIdxToUpdate].price -= Number(
          priceToUpdate
        );
        setUpdatePriceModal(false);
      }
    } else {
      setUpdatePriceModal(false);
      reservation.operations[operationIdxToUpdate].price += Number(
        priceToUpdate
      );
      checkOperationStep(operationIdxToUpdate, '');
    }
  };
  const activateOperation = (idx: number) => {
    setIsCancellingOperation(false);
    setOperationIdxToUpdate(idx);
    setUpdatePriceModal(true);
  };
  const startTreatmentPlan = () => {
    updateObject({});
    changeTabHandler('operation');
    setIsOperationModalOpen(true);
  };
  const handleOperationCanalsMeasurements = (
    operationIdx: number,
    fieldIdx: number,
    value: string,
    field: string
  ) => {
    if (field === 'CANALS') {
      reservation.operations[operationIdx].canalsMeasurements.canals[
        fieldIdx
      ] = value;
    }
    if (field === 'LENGTH') {
      reservation.operations[operationIdx].canalsMeasurements.lengths[
        fieldIdx
      ] = value;
    }
    if (field === 'MFSIZE') {
      reservation.operations[operationIdx].canalsMeasurements.MFSize[
        fieldIdx
      ] = value;
    }
    setOperations([...reservation.operations]);
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '1.5rem',
      transform: 'translate(-50%, -50%)',
      height: '130px',
      width: '250px',
    },
  };
  return (
    <div className="component-wrapper">
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={updatePriceModal}
        style={customStyles}
      >
        <div className="update-price-modal">
          <input
            type="text"
            placeholder="Price"
            onChange={(e: any) => setPriceToUpdate(e.target.value)}
          />
          <div
            className="submit-price-update"
            onClick={() => updateOperationPrice()}
          >
            <div>Submit</div>
          </div>
        </div>
      </Modal>
      <div className="header w-100 d-flex ">
        <ul className="d-flex">
          <li
            className={tab === 'complain' ? 'selected-li' : ''}
            onClick={() => {
              changeTabHandler('complain');
            }}
          >
            Complaint & Diagnosis
          </li>
          <li
            className={tab === 'operation' ? 'selected-li' : ''}
            onClick={() => {
              changeTabHandler('operation');
            }}
          >
            Operation
          </li>
          {tab === 'operation' && (
            <li
              onClick={() => changeOperationModalStatus()}
              className="add-opertaion-btn"
            >
              New Operation
            </li>
          )}
        </ul>
      </div>
      <div className="grad-line"></div>
      <div className="main-content">
        {tab === 'operation' ? (
          <OperationComponent
            operations={reservation.operations ? reservation.operations : []}
            checkOperationStep={checkOperationStep}
            cancelOperation={cancelOperation}
            activateOperation={activateOperation}
            addNote={addNoteOperation}
            handleOperationCanalsMeasurements={
              handleOperationCanalsMeasurements
            }
            isHistory={false}
          />
        ) : (
          <ComplainComponent startTreatmentPlan={startTreatmentPlan} />
        )}
      </div>
      <DentalServiceModal
        isOpen={isOperationModalOpen}
        closeModal={changeOperationModalStatus}
        addOperation={addOperation}
      />
    </div>
  );
};

export default TreatmentComponent;
