import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Button from '../../UI/Form/Button/Button';
import './endVisitSelectBookingModal.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '40rem',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  onModalConfirm: (selection: string) => void;
};

const EndVisitSelectBookingModal: React.FC<Props> = ({
  onModalConfirm,
  isOpen,
  onModalClose,
  // bookingStatus,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isOpen}
      contentLabel="Confirmation"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onModalClose}
    >
      <div className="booking-actions">
        <p>{t('clinicBoard_page.selectionConfirmation')}</p>
        {
          <div className="d-flex justify-content-between">
            <div className="modal-btn-group">
              <Button
                type="button"
                name={t('clinicBoard_page.next')}
                saveData={() => {
                  onModalConfirm('next');
                }}
              />
              <Button
                type="button"
                btnType="hold"
                name={t('clinicBoard_page.current')}
                saveData={() => {
                  onModalConfirm('current');
                }}
              />
            </div>
            <div className="modal-btn-group"></div>
          </div>
        }
      </div>
    </Modal>
  );
};

export default EndVisitSelectBookingModal;
