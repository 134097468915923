import { getDayFormatEN } from '../assets/constants/currentDay';
import getFullName from '../utils/getFullName';

const createPrescriptionTemplate = (patient: any, drugs: any[]) => {
  let css = ` @page { size: A5; margin: 0; }`;
  css += `@media print {`;
  css += `@page {size: A5;margin: 20mm 10mm;}`;
  css += `}`;

  let html = `<!DOCTYPE html><html><head><style>${css}</style></head><body style="padding-top:100px;">`;
  html += '<table style="width:100%; margin-bottom:10px;">';
  html += `<thead>`;
  html += `<tr>`;
  html += `<th colspan=2><h4 style="text-align:left;">Patient</h4></th>`;
  html += `</tr>`;
  html += `</thead>`;
  html += `<tbody>`;
  html += `<tr style="background:#f2f9ff">`;
  html += `<td style="padding:4px;font-size:12px;"><strong>Name   :</strong> ${getFullName(
    patient?.name || null
  )}</td> `;
  html += `<td style="padding:4px;font-size:12px;"><strong>Mobile :</strong>${
    patient?.lmobile || null
  }</td > `;
  html += `</tr > `;
  html += '</tbody></table>';

  html += '<table style="width:100%">';
  html += `<thead>`;
  html += `<tr>`;
  html += `<th colspan=3> <h4 style="text-align:left;">Prescription</h4> </th> `;
  html += `</tr > `;
  html += `</thead > `;
  html += `<tbody>`;
  for (const drug of drugs) {
    html += `<tr style = " background:#f2f9ff" > `;
    html += `<td colspan=3 style="font-weight:700;padding:4px;font-size:14px;" > ${drug.tradeName}</td > `;
    html += `</tr > `;
    html += `<tr> `;
    html += `<td style="padding:4px;font-size:12px;" > ${drug.quantity} ${drug.unit}</td > `;
    html += `<td style="padding:4px;font-size:12px;" > ${drug.every}</td > `;
    html += `<td style="padding:4px;font-size:12px;" > For ${drug.duration} ${drug.durationExt}</td > `;
    html += `</tr > `;
  }
  html += '</tbody></table>';
  html += '</body></html>';
  return html;
};

const createOrderTemplate = (patient: any, orders: any[]) => {
  let css = ` @page { size: A5; margin: 0; }`;
  css += `@media print {`;
  css += `@page {size: A5;margin: 20mm 10mm;}`;
  css += `}`;

  let html = `<!DOCTYPE html><html><head><style>${css}</style></head><body style="padding-top:100px;">`;
  html += '<table style="width:100%; margin-bottom:10px;">';
  html += `<thead>`;
  html += `<tr>`;
  html += `<th colspan=2><h4 style="text-align:left;">Patient</h4></th>`;
  html += `</tr>`;
  html += `</thead>`;
  html += `<tbody>`;
  html += `<tr style="background:#f2f9ff">`;
  html += `<td style="padding:4px;font-size:12px;"><strong>Name   :</strong> ${getFullName(
    patient?.name || null
  )}</td> `;
  html += `<td style="padding:4px;font-size:12px;"><strong>Mobile :</strong>${
    patient?.lmobile || null
  }</td > `;
  html += `</tr > `;
  html += '</tbody></table>';

  html += '<table style="width:100%">';
  html += `<thead>`;
  html += `<tr>`;
  html += `<th colspan=3> <h4 style="text-align:left;">Investigations (Lab/Rad)</h4> </th> `;
  html += `</tr > `;
  html += `</thead > `;
  html += `<tbody>`;
  for (const [i, order] of orders.entries()) {
    html += `<tr style = "background:${
      i % 2 === 0 ? '#f2f9ff' : '#ffffff'
    }" > `;
    html += `<td colspan=3 style="font-weight:700;padding:4px;font-size:14px;" > ${order.name}</td > `;
    html += `</tr > `;
  }
  html += '</tbody></table>';
  html += '</body></html>';
  return html;
};

const createReportTemplate = (
  month: string,
  data: any[],
  header: any[],
  type?: string
) => {
  let css = ` @page { size: A5; margin: 0; }`;
  css += `@media print {`;
  css += `@page {size: A5;margin: 20mm 10mm;}`;
  css += `}`;

  let html = `<!DOCTYPE html><html><head><style>${css}</style></head><body style="padding-top:100px;">`;
  html += `<h4 style="text-align:left;">${month}  ${
    type ? type : 'Financal'
  } report</h4>`;

  html += '<table style="width:100%">';
  html += `<thead>`;
  html += `<tr style = " background:#f2f9ff" > `;
  for (const title of header) {
    html += `<th style="text-align:center;font-size:12px"> ${title}</th > `;
  }
  html += `</tr > `;
  html += `</thead > `;
  html += `<tbody>`;

  for (const ele of data) {
    html += `<tr style = " background:#f2f9ff" >`;
    Object.entries(ele).map(
      ([key]) =>
        (html += `<td  style="padding:4px;text-align:center;font-size:12px;"> ${ele[key]}</td>`)
    );
    html += `</tr > `;
  }
  html += '</tbody></table>';
  html += '</body></html>';
  return html;
};
const createFinancialLogReportTemplate = (
  name: string,
  mobil: string,
  data: any[],
  header: any[]
) => {
  let css = ` @page { size: A5; margin: 0; }`;
  css += `@media print {`;
  css += `@page {size: A5;margin: 10mm 10mm;}`;
  css += `}`;

  let html = `<!DOCTYPE html><html><head><style>${css}</style></head><body style="padding-top:100px;">`;
  html += `<h4 style="text-align:left;">Financal report</h4>`;
  html += `<div style="font-size: 12px; margin-bottom: 10px;">
              <div>Name: ${name}</div>
              <div>Mobile: ${mobil}</div>
           </div>`;

  html += '<table style="width:100%">';
  html += `<thead>`;
  html += `<tr style = " background:#f2f9ff" > `;
  for (const title of header) {
    html += `<th style="text-align:center;font-size:12px"> ${title}</th > `;
  }
  html += `</tr > `;
  html += `</thead > `;
  html += `<tbody>`;

  for (const ele of data) {
    html += `<tr style = " background:#f2f9ff" >`;
    html += `<td  style="padding:4px;text-align:center;font-size:12px;"> ${getDayFormatEN(
      ele.date
    )}</td>`;
    html += `<td  style="padding:4px;text-align:center;font-size:12px;"> ${ele.record}</td>`;
    html += `<td  style="padding:4px;text-align:center;font-size:12px;"> ${ele.value}</td>`;
    html += `<td  style="padding:4px;text-align:center;font-size:12px;"> ${ele.balance}</td>`;
    html += `<td  style="padding:4px;text-align:center;font-size:12px;"> ${ele.notes}</td>`;
    html += `</tr > `;
  }
  html += '</tbody></table>';
  html += '</body></html>';
  return html;
};

export const printPrescription = (patient: any, drugs: any[]) => {
  const template = createPrescriptionTemplate(patient, drugs);
  doPrint(template);
};

export const printOrder = (patient: any, orders: any[]) => {
  const template = createOrderTemplate(patient, orders);
  doPrint(template);
};

export const printMonthReport = (
  month: string,
  type: string,
  data: any[],
  header: any[]
) => {
  const template = createReportTemplate(month, data, header, type);
  doPrint(template);
};

export const printFinancialReport = (
  patient: string,
  mobile: string,
  data: any[],
  header: any[]
) => {
  const template = createFinancialLogReportTemplate(
    patient,
    mobile,
    data,
    header
  );
  doPrint(template);
};

const doPrint = (template: any) => {
  const iframe: any = document.createElement('IFRAME');
  let doc: any = null;
  // iframe.setAttribute('id', 'printTest');
  // tslint:disable-next-line: max-line-length
  // Add specific style solutions according to rendering needs---- visibility: hidden: print content appears on the parent page when iframe is called
  iframe.setAttribute('style', 'textAlign:center;display: none;');
  document.body.appendChild(iframe);
  doc = iframe.contentWindow.document;
  // Introduce the proprietary CSS style for printing, modify it according to the actual situation

  doc.write(template);

  doc.close();
  // Get the focus of the iframe and start printing from the iframe
  iframe.contentWindow.focus();
  iframe.contentWindow.print();
  setTimeout(() => {
    document.body.removeChild(iframe);
  }, 50);
};
