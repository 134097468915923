import React, { useState, useEffect } from 'react';

import Marker from './Marker/Marker';
import GoogleMapReact from 'google-map-react';
import { getlocationDetails, googleApiKey } from '../../utils/location';

type Props = {
  location: {
    lat: number;
    lng: number;
  } | null;
  setLocation: ({
    lat,
    lng,
    address,
  }: {
    lat: number;
    lng: number;
    address: string;
  }) => void;
};

const Map: React.FC<Props> = ({ location, setLocation }) => {
  const [center, setCenter] = useState<any>(null);
  const [address, setAddress] = useState('');
  const [draggable, setDraggable] = useState(false);

  useEffect(() => {
    // console.log('location', location);
    if (!location || !location.lat || !location.lng) return;
    getLocationHandler(location.lat, location.lng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getLocationHandler = async (lat: number, lng: number) => {
    setCenter({ lat, lng });
    const addrData = await getlocationDetails(lat, lng);
    setLocation({ lat, lng, address: addrData });
    setAddress(addrData);
  };

  const onChildMouseMove = (childKey: any, childProps: any, mouse: any) => {
    if (childKey !== 'drag-pin') return;
    setDraggable(false);
    setCenter({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  const onChildMouseUp = async (childKey: any, childProps: any, mouse: any) => {
    if (childKey !== 'drag-pin') return;
    setDraggable(true);
    // setCenter({
    //   lat: mouse.lat,
    //   lng: mouse.lng,
    // });
    // console.log('onChildMouseUp', mouse.lat, mouse.lng);
    await getLocationHandler(mouse.lat, mouse.lng);
  };

  return (
    <div style={{ width: 800, height: 500 }}>
      {center && center.lat > 0 && center.lng > 0 && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: googleApiKey,
            libraries: 'places',
          }}
          center={center}
          defaultZoom={11}
          onClick={async ({ lat: latitude, lng: longitude }) => {
            // console.log('onClick', latitude, longitude);
            await getLocationHandler(latitude, longitude);
          }}
          options={{
            fullscreenControl: false,
          }}
          draggable={draggable}
          onChildMouseMove={onChildMouseMove}
          onChildMouseDown={onChildMouseMove}
          onChildMouseUp={onChildMouseUp}
        >
          <Marker
            lat={center.lat}
            lng={center.lng}
            name={address}
            key="drag-pin"
            color="blue"
          />
        </GoogleMapReact>
      )}
    </div>
  );
};

export default Map;
