import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../store/reducers';
import bookingStatus from '../../assets/constants/bookingStatus';
import GirlIcon from '../../assets/img/GirlIcon.svg';
import userIcon from '../../assets/img/Mask Group 343.svg';
import calenderIcon from '../../assets/img/Mask Group 358.svg';
import { Booking, Patient } from '../../types';
import getFullName from '../../utils/getFullName';
import { formatDate, getAgeFromBD } from '../../utils/moment';
import EndVisitSelectBookingModal from './endVisitSelectBookingModal/endVisitSelectBookingModal';

import { resetReservation } from '../../store/actions';
import { setBoardCurrentBooking } from '../../store/actions/board.actions';
import { setCurrentPatient } from '../../store/actions/booking.actions';
type BoardPatientInfoProps = {
  currentPatient: Patient;
  currentBooking: Booking | null;
  reservation: any;
  onSave: (status: string, partialUpdate?: boolean) => void;
  onSelect: () => void;
  isDental?: boolean;
};

const BoardPatientInfo: React.FC<BoardPatientInfoProps> = ({
  currentPatient,
  currentBooking,
  reservation,
  onSave,
  onSelect,
  isDental = false,
}) => {
  const dispatch = useDispatch();
  const isAttachmentsUploading = useSelector(
    (state: rootState) => state.board.isAttachmentsUploading
  );
  const { bookings } = useSelector((state: rootState) => state.board);

  const { t } = useTranslation();
  const calcAgeHandler = (patient: any) => {
    if (!patient) return '';
    return getAgeFromBD(new Date(patient.dob));
  };
  const [selectionModal, setSelectionModal] = useState(false);

  const toggleSelectionModal = () => {
    setSelectionModal(!selectionModal);
  };
  const totalBookingCost = () => {
    const totalOperationCost = reservation.operations
      .map((op: any) => {
        return op.price;
      })
      .reduce((a: number, b: number) => a + b, 0);
    const totalCost = totalOperationCost + currentBooking?.cost;
    return totalCost;
  };
  const doChangeBooking = (booking: any) => {
    dispatch(resetReservation());
    if (booking) {
      setTimeout(() => {
        dispatch(setBoardCurrentBooking(booking));
      }, 100);
    } else {
      setTimeout(() => {
        dispatch(setBoardCurrentBooking(null));
        dispatch(setCurrentPatient(null));
        onSelect();
      }, 100);
    }
  };
  const getNextActiveBooking = () => {
    const firstActiveBooking = bookings.find(
      (booking: any) => booking.status === 'active'
    );
    if (!firstActiveBooking) return null;
    // console.log('Return With Next Active booking ', firstActiveBooking);
    return firstActiveBooking;
  };

  const onModalConfirm = (selection: string) => {
    toggleSelectionModal();
    if (selection === 'current') return;
    // console.log('Confriming selection with:', selection);
    const nextActiveBooking = getNextActiveBooking();
    doChangeBooking(nextActiveBooking);
  };

  // TODO : We need to add paid price !
  return (
    <div className="patient-info">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-fill flex-row bd-highlight">
          <img
            src={currentPatient?.gender === 'male' ? userIcon : GirlIcon}
            alt=""
          />
          <div className="name">
            {getFullName(currentPatient?.name || null)}
          </div>
        </div>
        <div className="d-flex flex-fill flex-column bd-highlight">
          {isDental ? (
            <div className="d-flex flex-fill flex-row bd-highlight justify-content-around">
              <img src={calenderIcon} alt="" />
              <div className="age">{calcAgeHandler(currentPatient)}</div>
            </div>
          ) : (
            ''
          )}
          {/* {isDental && (
            <div className="d-flex  flex-row  justify-content-around">
              <div className="price">0/ {totalBookingCost()}</div>
              <div className="money-sign">$</div>
            </div>
          )} */}
        </div>
      </div>
      {(currentBooking && currentBooking.status === bookingStatus.completed) ||
      (!currentBooking &&
        reservation &&
        reservation.status === bookingStatus.completed) ? (
        <div className="btns">
          <button
            type="button"
            className="btn btn-primary btn-lg btn-block"
            onClick={() => {
              onSave('completed', true);
            }}
          >
            {t('clinicBoard_page.save')}
          </button>
        </div>
      ) : (currentBooking && currentBooking.status !== 'completed') ||
        (!currentBooking &&
          reservation &&
          reservation.status !== 'completed') ? (
        <div className="btns">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            disabled={isAttachmentsUploading}
            onClick={() => {
              onSave('completed');
              toggleSelectionModal();
            }}
          >
            {t('clinicBoard_page.end-visit')}
          </button>
          <button
            type="button"
            disabled={isAttachmentsUploading}
            className="btn btn-secondary btn-lg"
            onClick={() => {
              onSave('hold');
            }}
          >
            {t('clinicBoard_page.hold')}
          </button>
        </div>
      ) : null}
      <div>
        {!currentBooking && reservation && reservation.b
          ? `Date: ${formatDate(reservation.b.date)}`
          : null}
      </div>
      {selectionModal && (
        <EndVisitSelectBookingModal
          isOpen={selectionModal}
          onModalClose={() => {
            return;
          }}
          onModalConfirm={onModalConfirm}
        />
      )}
    </div>
  );
};

export default BoardPatientInfo;
