import {
  changePassword,
  checkToken,
  checkUser,
  completeProfile,
  login,
  register,
  requestToken,
  getUserProfile,
  updateUserProfile,
  validatePromoCode,
  updateUserPassword,
  searchDrByMobile,
} from './clinicians';
import { addEmployee, getEmployees, revokeEmployeeAccess } from './employee';
import { upload, getUploadAccess } from './media';
import {
  addUnit,
  getUnitDetails,
  getClinicianUnits,
  getUnitTestsTypes,
  getUnitEmployees,
  getUnitExpenses,
  updateUnit,
  searchPlaces,
  subscribeUnit,
  redeemPoints,
  getSubscriptionPlans,
  getNetworks,
  getPredefinedLabServices,
  addDentalLab,
  getDentalLabs,
  updateDentalLabs,
} from './unit';
import {
  addBooking,
  updateBooking,
  getClinicBookings,
  getClinicBookingsPending,
  getPatientPreviousBookings,
  getPatientUpCommingBooking,
  // searchReservationBy,
  // updateReservation,
  getLabBookings,
  markComplete,
  getPatientUnitBookings,
  updateBookingTest,
  deleteBookingTest,
  addDentOrder,
} from './booking';

import {
  getUnitMonthStatistics,
  getDentalMonthlyReport,
  getUnitDailyStatistics,
  getDentLabReport,
  addExpenses,
} from './statistics';

import {
  updatePatient,
  addPatient,
  findPatientBy,
  findPatientWithUnitBookingBy,
  getPatientOrders,
  getPatientLabEncounters,
  getPatientRadEncounters,
  addPatientOrders,
  addPatientLabEncounters,
  addPatientRadEncounters,
  addPatientReferedData,
  getPatientData,
} from './patient';

export default {
  // clinician
  changePassword,
  checkToken,
  checkUser,
  completeProfile,
  login,
  register,
  requestToken,
  getUserProfile,
  updateUserProfile,
  validatePromoCode,
  updateUserPassword,
  searchDrByMobile,
  // employee
  addEmployee,
  getEmployees,
  revokeEmployeeAccess,
  // unit
  addUnit,
  getUnitDetails,
  getClinicianUnits,
  getUnitTestsTypes,
  getUnitEmployees,
  getUnitExpenses,
  updateUnit,
  searchPlaces,
  subscribeUnit,
  redeemPoints,
  getSubscriptionPlans,
  // booking
  addBooking,
  updateBooking,
  getClinicBookings,
  getClinicBookingsPending,
  getPatientPreviousBookings,
  getPatientUpCommingBooking,
  getPatientUnitBookings,
  updateBookingTest,
  // searchReservationBy,
  // updateReservation,
  updatePatient,
  getLabBookings,
  markComplete,
  deleteBookingTest,
  // patient
  addPatient,
  findPatientBy,
  findPatientWithUnitBookingBy,
  getPatientOrders,
  getPatientLabEncounters,
  getPatientRadEncounters,
  addPatientOrders,
  addPatientLabEncounters,
  addPatientRadEncounters,
  addPatientReferedData,
  getPatientData,
  // media
  upload,
  getUploadAccess,
  // statistics
  getUnitMonthStatistics,
  addExpenses,
  getUnitDailyStatistics,
  getNetworks,
  getDentalMonthlyReport,
  getDentLabReport,
  // denatal labs
  getPredefinedLabServices,
  addDentalLab,
  getDentalLabs,
  updateDentalLabs,
  addDentOrder,
};
