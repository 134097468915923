import React from 'react';
import './LabBoard.scss';

import { useSelector } from 'react-redux';

import LabBookings from '../../../components/LabBoard/Bookings/LabBookings';
import LabDetails from '../../../components/LabBoard/LabDetails/LabDetails';
import ImageDetails from '../../../components/LabBoard/ImageDetails/ImageDetails';
import { rootState } from '../../../store/reducers';
import unitTypes from '../../../utils/unitTypes';

const LabBoard: React.FC = () => {
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  return (
    <div className="lab-board-container p-0 p-sm-5 d-flex flex-column flex-sm-row">
      <LabBookings />
      {currentUnitDetails && currentUnitDetails.type === unitTypes.lab ? (
        <LabDetails />
      ) : (
        <ImageDetails />
      )}
    </div>
  );
};

export default LabBoard;
