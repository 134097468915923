import React from 'react';
import AddNewExperience from '../AddNewExperience';
import { useTranslation } from 'react-i18next';
import './Expences.scss';

type Props = {
  openCloseModalExpHandler: () => void;
  removeAddedExpHandler: (index: number) => void;
  editExp: (index: number) => void;
  addeddexpenses: any[];
  isSetting?: boolean;
};

const Expences: React.FC<Props> = ({
  addeddexpenses,
  openCloseModalExpHandler,
  removeAddedExpHandler,
  editExp,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column p-3" style={{ flex: '1' }}>
      <h5>{t('employess_page.monthly-expenses')}</h5>
      <div className="expense mt-3">
        <div className="d-flex p-3" style={{ height: '50px' }}>
          <div className="d-flex col-3 align-items-center">
            <h6>{t('setting_page.title')}</h6>
          </div>
          <div className="d-flex col-3 justify-content-center align-items-center">
            <h6>{t('setting_page.price')}</h6>
          </div>
          <div className="d-flex col-4 justify-content-center align-items-center">
            <h6>Date</h6>
          </div>
        </div>
        {addeddexpenses &&
          addeddexpenses.length > 0 &&
          addeddexpenses.map((ele, eleIndex) => (
            <AddNewExperience
              editExp={editExp}
              removeAddedExpHandler={removeAddedExpHandler}
              key={`addedEmp-${eleIndex}`}
              even={eleIndex % 2 === 0}
              title={addeddexpenses[eleIndex].name}
              price={addeddexpenses[eleIndex].amount}
              date={addeddexpenses[eleIndex]._md}
              index={eleIndex}
            />
          ))}
        <p className="add mt-3">
          <span onClick={openCloseModalExpHandler}>
            {t('setting_page.add-exp')}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Expences;
