import { ReactComponent as I11 } from '../../../assets/img/teeth/I11.svg';
import { ReactComponent as I12 } from '../../../assets/img/teeth/I12.svg';
import { ReactComponent as C13 } from '../../../assets/img/teeth/C13.svg';
import { ReactComponent as P14 } from '../../../assets/img/teeth/P14.svg';
import { ReactComponent as P15 } from '../../../assets/img/teeth/P15.svg';
import { ReactComponent as M16 } from '../../../assets/img/teeth/M16.svg';
import { ReactComponent as M17 } from '../../../assets/img/teeth/M17.svg';
import { ReactComponent as M18 } from '../../../assets/img/teeth/M18.svg';
import { ReactComponent as M26 } from '../../../assets/img/teeth/M26.svg';
import { ReactComponent as M27 } from '../../../assets/img/teeth/M27.svg';
import { ReactComponent as M28 } from '../../../assets/img/teeth/M28.svg';
import { ReactComponent as C23 } from '../../../assets/img/teeth/C23.svg';
import { ReactComponent as P24 } from '../../../assets/img/teeth/P24.svg';
import { ReactComponent as P25 } from '../../../assets/img/teeth/P25.svg';
import { ReactComponent as I21 } from '../../../assets/img/teeth/I21.svg';
import { ReactComponent as I22 } from '../../../assets/img/teeth/I22.svg';
import { ReactComponent as M38 } from '../../../assets/img/teeth/M38.svg';
import { ReactComponent as M37 } from '../../../assets/img/teeth/M37.svg';
import { ReactComponent as M36 } from '../../../assets/img/teeth/M36.svg';
import { ReactComponent as P35 } from '../../../assets/img/teeth/P35.svg';
import { ReactComponent as P34 } from '../../../assets/img/teeth/P34.svg';
import { ReactComponent as C33 } from '../../../assets/img/teeth/C33.svg';
import { ReactComponent as I32 } from '../../../assets/img/teeth/I32.svg';
import { ReactComponent as I31 } from '../../../assets/img/teeth/I31.svg';
import { ReactComponent as I41 } from '../../../assets/img/teeth/I41.svg';
import { ReactComponent as I42 } from '../../../assets/img/teeth/I42.svg';
import { ReactComponent as C43 } from '../../../assets/img/teeth/C43.svg';
import { ReactComponent as P44 } from '../../../assets/img/teeth/P44.svg';
import { ReactComponent as P45 } from '../../../assets/img/teeth/P45.svg';
import { ReactComponent as M46 } from '../../../assets/img/teeth/M46.svg';
import { ReactComponent as M47 } from '../../../assets/img/teeth/M47.svg';
import { ReactComponent as M48 } from '../../../assets/img/teeth/M48.svg';
const teethUpper = [
  {
    id: 28,
    teethImg: M28,
    position: [130, 160],
    titlePosition: [140, 200],
    size: [35, 35],
  },
  {
    id: 27,
    teethImg: M27,
    position: [102, 155],
    titlePosition: [110, 200],
    size: [38, 38],
  },
  {
    id: 26,
    teethImg: M26,
    position: [72, 150],
    titlePosition: [80, 195],
    size: [38, 38],
  },
  {
    id: 25,
    teethImg: P25,
    position: [50, 145],
    titlePosition: [50, 180],
    size: [30, 30],
  },
  {
    id: 24,
    teethImg: P24,
    position: [30, 135],
    titlePosition: [25, 170],
    size: [30, 30],
  },
  {
    id: 23,
    teethImg: C23,
    position: [8, 115],
    titlePosition: [0, 145],
    size: [30, 30],
  },
  {
    id: 22,
    teethImg: I22,
    position: [-8, 97],
    titlePosition: [-20, 120],
    size: [25, 25],
  },
  {
    id: 21,
    teethImg: I21,
    position: [-15, 75],
    titlePosition: [-30, 80],
    size: [25, 25],
  },
  {
    id: 11,
    teethImg: I11,
    position: [-15, 50],
    titlePosition: [-30, 50],
    size: [25, 25],
  },
  {
    id: 12,
    teethImg: I12,
    position: [-10, 25],
    titlePosition: [-25, 25],
    size: [25, 25],
  },
  {
    id: 13,
    teethImg: C13,
    position: [5, 5],
    titlePosition: [-10, 0],
    size: [30, 30],
  },
  {
    id: 14,
    teethImg: P14,
    position: [25, -15],
    titlePosition: [10, -20],
    size: [30, 30],
  },
  {
    id: 15,
    teethImg: P15,
    position: [45, -30],
    titlePosition: [35, -40],
    size: [35, 35],
  },
  {
    id: 16,
    teethImg: M16,
    position: [70, -45],
    titlePosition: [65, -60],
    size: [40, 40],
  },
  {
    id: 17,
    teethImg: M17,
    position: [100, -55],
    titlePosition: [100, -70],
    size: [40, 40],
  },
  {
    id: 18,
    teethImg: M18,
    position: [130, -57],
    titlePosition: [130, -75],
    size: [35, 35],
  },
];
const teethLower = [
  {
    id: 48,
    teethImg: M48,
    position: [175, -60],
    titlePosition: [185, -80],
    size: [35, 35],
  },
  {
    id: 47,
    teethImg: M47,
    position: [207, -53],
    titlePosition: [225, -75],
    size: [35, 35],
  },
  {
    id: 46,
    teethImg: M46,
    position: [237, -45],
    titlePosition: [255, -60],
    size: [40, 40],
  },
  {
    id: 45,
    teethImg: P45,
    position: [268, -25],
    titlePosition: [285, -45],
    size: [30, 30],
  },
  {
    id: 44,
    teethImg: P44,
    position: [292, -10],
    titlePosition: [320, -25],
    size: [25, 25],
  },
  {
    id: 43,
    teethImg: C43,
    position: [305, 8],
    titlePosition: [340, 5],
    size: [32, 32],
  },
  {
    id: 42,
    teethImg: I42,
    position: [320, 32],
    titlePosition: [350, 32],
    size: [25, 25],
  },
  {
    id: 41,
    teethImg: I41,
    position: [325, 55],
    titlePosition: [355, 60],
    size: [25, 25],
  },
  {
    id: 31,
    teethImg: I31,
    position: [325, 75],
    titlePosition: [355, 85],

    size: [25, 25],
  },
  {
    id: 32,
    teethImg: I32,
    position: [315, 95],
    titlePosition: [345, 110],
    size: [25, 25],
  },
  {
    id: 33,
    teethImg: C33,
    position: [300, 110],
    titlePosition: [330, 140],
    size: [32, 32],
  },
  {
    id: 34,
    teethImg: P34,
    position: [283, 130],
    titlePosition: [305, 160],

    size: [28, 28],
  },
  {
    id: 35,
    teethImg: P35,
    position: [260, 140],
    titlePosition: [280, 175],
    size: [28, 28],
  },
  {
    id: 36,
    teethImg: M36,
    position: [232, 145],
    titlePosition: [250, 185],

    size: [35, 35],
  },
  {
    id: 37,
    teethImg: M37,
    position: [203, 155],
    titlePosition: [220, 193],

    size: [35, 35],
  },
  {
    id: 38,
    teethImg: M38,
    position: [172, 160],
    titlePosition: [185, 200],

    size: [35, 35],
  },
];
export { teethUpper, teethLower };
