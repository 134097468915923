export const getCurruntDay = () => {
  const today = new Date();
  const date = today.getTime();
  return date;
};

export const getDayFormat = (num: number) => {
  const today = new Date(num);

  const date =
    today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
  return date;
};

export const getDayFormatEN = (num: number) => {
  const today = new Date(num);

  const date =
    today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  return date;
};
