import axios from 'axios';

export const googleApiKey = 'AIzaSyDJ7jqLmOtgTHtfctCutcGX-WzlZZCEmfE';

export const getlocationDetails = async (
  latitude: number,
  longitude: number
) => {
  // console.log(latitude, longitude);
  const url = 'https://maps.googleapis.com/maps/api/geocode/json';
  return axios({
    method: 'GET',
    url,
    params: {
      latlng: `${latitude},${longitude}`,
      key: googleApiKey,
      language: 'ar',
      location_type: 'GEOMETRIC_CENTER',
    },
  })
    .then((res) => {
      // console.log('res', res.data);
      let address = '';
      if (res.data.results[0] && res.data.results[0].address_components[0]) {
        address = res.data.results[0].formatted_address;
      } else {
        address = res.data.plus_code.compound_code
          .split(' ')
          .slice(1)
          .join(' ');
      }
      // console.log('getlocationDetails', address);
      return address;
    })
    .catch((err) => {
      console.error('error[getlocationDetails]', err);
      return '';
    });
};

export const getPlacelatlng = async (place: string) => {
  const url = 'https://maps.googleapis.com/maps/api/geocode/json';
  let address = { lat: 0, lng: 0, address: '' };
  return axios({
    method: 'GET',
    url,
    params: {
      place_id: place,
      key: googleApiKey,
      language: 'ar',
    },
  })
    .then((res) => {
      // console.log('res', res.data);
      if (res.data.results[0] && res.data.results[0].geometry) {
        address = {
          ...res.data.results[0].geometry.location,
          address: res.data.results[0].formatted_address,
        };
      }
      // console.log('getPlacelatlng', address);
      return address;
    })
    .catch((err) => {
      console.error('error[getPlacelatlng]', err);
      return address;
    });
};
