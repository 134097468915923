import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import eyeIcon from '../../../assets/img/Group 1156.svg';
import invisibleIcon from '../../../assets/img/Group 1157.svg';
import reportIcon from '../../../assets/img/reports.svg';
import testsIcon from '../../../assets/img/tests.svg';
import xrayIcon from '../../../assets/img/x-ray.svg';
import { rootState } from '../../../store/reducers';
import { formatDate } from '../../../utils/moment';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import './RadiologyResults.scss';

type Element = { type: string; index: number };

const flatEncountersList = (encounters: any[]) => {
  // console.log('encounters', encounters);
  let tests: any[] = [];
  for (const element of encounters) {
    tests = [...tests, element];
  }
  const sortedTests = tests.sort((a, b) => {
    const c = new Date(a.date);
    const d = new Date(b.date);
    return c < d ? 1 : -1;
  });
  return sortedTests;
};

const RadiologyResults = () => {
  const [files, setFiles] = useState<any>([]);
  const [list, setList] = useState<any[]>([]);
  const [activeElement, setActiveElement] = useState<Element>();
  const encounters = useSelector(
    (state: rootState) => state.board.radEncounters
  );

  useEffect(() => {
    if (encounters.length <= 0) return;
    // console.log('encounters', encounters);
    const listData = flatEncountersList(encounters);
    // console.log('listData', listData);
    setList(listData);
    if (listData[0].reports.length > 0) {
      setActiveElement({ type: 'reports', index: 0 });
      setFiles(listData[0].reports);
    }
  }, [encounters]);
  // console.log(list);

  return (
    <div className="radiology-view">
      <div className="main-view d-flex">
        <div className="data">
          <div className="container-result">
            <table className="table">
              <thead>
                <tr>
                  <th className="cell-first">
                    <div className="cell-content d-flex align-items-center">
                      <img src={testsIcon} alt="" />
                      <div className="item-title">Tests</div>
                    </div>
                  </th>
                  <th className="cell-last">
                    <div className="cell-content d-flex align-items-center">
                      <img src={reportIcon} alt="" />
                      <div className="item-title">Reports</div>
                    </div>
                  </th>
                  <th className="cell-last">
                    <div className="cell-content d-flex align-items-center">
                      <img src={xrayIcon} alt="" />
                      <div className="item-title">Radiology</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list
                  .filter(
                    (d: any) => d.reports.length !== 0 || d.results.length !== 0
                  )
                  .map((r: any, i: number) => {
                    return (
                      <tr key={`result-${i}`}>
                        <td className="cell-first">
                          <div className="item-title">{r.name}</div>
                          <div className="item-date">{formatDate(r.date)}</div>
                        </td>
                        <td className="cell-last">
                          {/*eslint-disable-next-line  */}
                          <a
                            className={`align-self-center ${
                              activeElement &&
                              activeElement.index === i &&
                              activeElement.type === 'reports'
                                ? 'active'
                                : 'inactive'
                            }`}
                            onClick={() => {
                              setActiveElement({ type: 'reports', index: i });
                              setFiles(r.reports);
                            }}
                          >
                            <img
                              src={
                                activeElement &&
                                activeElement.index === i &&
                                activeElement.type === 'reports'
                                  ? eyeIcon
                                  : invisibleIcon
                              }
                              alt=""
                            />
                          </a>
                        </td>
                        <td className="cell-last">
                          {/*eslint-disable-next-line  */}
                          <a
                            className={`align-self-center ${
                              activeElement &&
                              activeElement.index === i &&
                              activeElement.type === 'results'
                                ? 'active'
                                : 'inactive'
                            }`}
                            onClick={() => {
                              setActiveElement({ type: 'results', index: i });
                              setFiles(r.results);
                            }}
                          >
                            <img
                              src={
                                activeElement &&
                                activeElement.index === i &&
                                activeElement.type === 'results'
                                  ? eyeIcon
                                  : invisibleIcon
                              }
                              alt=""
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="viewer">
          <DocumentViewer files={files} />
        </div>
      </div>
    </div>
  );
};

export default RadiologyResults;
