import React, { useState, useEffect } from 'react';
import './TestType.scss';
import Toggle from 'react-toggle';
import dropDownArrow from '../../../../assets/img/dropdownArrow.svg';

type Props = {
  shouldToggle: boolean;
  changeTestTypes: (type: string, payload: any) => void;
  type: any;
  initialState: any;
};

const TestType: React.FC<Props> = ({
  shouldToggle,
  changeTestTypes,
  type,
  initialState,
}) => {
  const [toggle, setSetToggle] = useState(shouldToggle);
  const [droppedDown, setDroppedDown] = useState(false);
  const [arrowClass, setArrowClass] = useState('downArrow');

  useEffect(() => {
    if (
      initialState &&
      initialState.checked &&
      initialState.subTests &&
      initialState.subTests.length > 0
    ) {
      setDroppedDown(true);
      setArrowClass('upArrow');
    }
  }, [initialState]);

  const expandSubTests = () => {
    if (droppedDown === false) {
      setDroppedDown(true);
      setArrowClass('upArrow');
    } else {
      setDroppedDown(false);
      setArrowClass('downArrow');
    }
  };

  const handleSelectSubTest = (e: any) => {
    let newArr = [...initialState.subTests];
    if (e.target.checked === false) {
      newArr = newArr.map((ele: any) => {
        if (ele.name === e.target.name) {
          return { ...ele, checked: false };
        } else {
          return { ...ele };
        }
      });
    } else {
      newArr = newArr.map((ele: any) => {
        if (ele.name === e.target.name) {
          return { ...ele, checked: true };
        } else {
          return { ...ele };
        }
      });
    }
    initialState.subTests = newArr;
    changeTestTypes(type, initialState);
  };

  const handleTofuChange = (e: any) => {
    const checked = e.target.checked;
    expandSubTests();
    initialState.checked = checked;
    setSetToggle(checked);
    let newArr = [...initialState.subTests];
    newArr = newArr.map((ele: any) => {
      return { ...ele, checked: false };
    });
    initialState.subTests = newArr;
    changeTestTypes(type, initialState);
  };

  return (
    <div
      className="testType-container mb-4"
      style={{
        alignSelf: toggle ? 'inherit' : 'baseline',
      }}
    >
      <div className="testType-container d-flex justify-content-between ">
        <div onClick={expandSubTests} className="testType-container-title">
          {initialState.name}{' '}
          {toggle ? (
            <img
              className={`testType-container-title-${arrowClass}`}
              src={dropDownArrow}
              alt="arrow"
            />
          ) : null}
        </div>
        <div>
          <Toggle
            className="custom-classname"
            defaultChecked={toggle}
            icons={false}
            onChange={handleTofuChange}
          />
        </div>
      </div>
      {toggle && droppedDown ? (
        <div className="testType-container-test">
          <div className="testType-container-test-subTest">
            <>
              {initialState &&
                initialState.subTests &&
                initialState.subTests.length > 0 &&
                initialState.subTests.map((subTest: any, index: any) => (
                  <div key={index} className="d-flex  align-items-center">
                    <input
                      name={subTest.name}
                      type="checkbox"
                      checked={subTest.checked}
                      onChange={handleSelectSubTest}
                    />
                    <div className="name">{subTest.name}</div>
                  </div>
                ))}
            </>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TestType;
