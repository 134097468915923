import React, { useRef, useState, useEffect } from 'react';
import './ProfileImages.scss';
import { useTranslation } from 'react-i18next';
import MyDropzone from '../../Register/CheckProfile/DropZone';
import cameraIcon from '../../../assets/img/camera.svg';
import parseArabic from '../../../utils/parseArabic';
import { nationalIdRegex } from '../../../assets/constants/regexValues';

type Props = {
  getImage: (file: any, type: string) => void;
  onNatNumChange: (payload: { type: string; data: any }) => void;
  uploading: boolean;
  profileLocalImg: string;
  nationalScanLocalImg: string;
  nationalNumber: string;
};

const ProfileImages: React.FC<Props> = ({
  onNatNumChange,
  getImage,
  profileLocalImg,
  nationalScanLocalImg,
  nationalNumber,
}) => {
  const { t } = useTranslation();
  const uploadProfileRef = useRef<any>();
  const [natError, setNatError] = useState('');
  const [natNumber, setNatNumber] = useState(nationalNumber);

  const openProfileFileExplorerHandler = () => {
    uploadProfileRef.current.click();
  };

  useEffect(() => {
    setNatNumber(nationalNumber);
  }, [nationalNumber]);

  const changeNationlaNumberHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let natNum = parseArabic(e.target.value);
    natNum = natNum.replace(/\D|-/g, '');
    setNatNumber(natNum);
    if (
      !natNum ||
      (natNum && natNum.length === 14 && RegExp(nationalIdRegex).test(natNum))
    ) {
      onNatNumChange({
        data: { nationalNumber: natNum, power: 30 },
        type: 'nationalNumber',
      });
      setNatError('');
    } else {
      setNatError(t('complete_profile.nat-invalid'));
      onNatNumChange({
        data: { nationalNumber: natNum, power: 0 },
        type: 'nationalNumber',
      });
    }
  };

  return (
    <div className="emp-profile-images d-flex  flex-column  justify-content-center ">
      <div className="emp-profile-images-container align-items-center d-flex flex-column justify-content-between p-4">
        <div className="nat-num-profile d-flex flex-column flex-sm-row justify-content-between  w-100">
          <div className="profile-img">
            <div className="name-attr d-flex justify-content-between">
              <p>
                {t('complete_profile.profile')}
                <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span>
              </p>
            </div>
            <div className=" d-flex justify-content-center align-items-center mt-3">
              {profileLocalImg ? (
                <div
                  className="pic p-0"
                  onClick={openProfileFileExplorerHandler}
                >
                  <img src={profileLocalImg} alt="pic" />
                </div>
              ) : (
                <div
                  className="profile  mt-5"
                  onClick={openProfileFileExplorerHandler}
                >
                  <img src={cameraIcon} alt="profile" />
                </div>
              )}

              <input
                type="file"
                ref={uploadProfileRef}
                accept="image/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files?.length > 0) {
                    getImage(e.target.files[0], 'profile');
                  }
                }}
              />
            </div>
          </div>

          <div className="national-id-img  w-100">
            <div className="name-attr d-flex justify-content-between">
              <p>
                {t('complete_profile.nat-img')}
                <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span>
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 ">
              <div className="">
                <MyDropzone
                  imgSrc={nationalScanLocalImg}
                  getImage={getImage}
                  type="national"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row w-100 ">
          <div className="national-id mt-5 mt-sm-5 ">
            <div className="name-attr d-flex justify-content-between">
              <p>
                {t('complete_profile.nat-num')}
                <span className="m-2">{`(${t(
                  'complete_profile.optional'
                )})`}</span>
              </p>
            </div>
            <div className="d-flex flex-column nat-num mt-1">
              <input
                type="text"
                placeholder={t('complete_profile.nat-placeholder')}
                value={natNumber}
                onChange={changeNationlaNumberHandler}
                maxLength={14}
              />
              <div className="error">
                {natError ? <span>{natError}</span> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImages;
