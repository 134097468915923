import React from 'react';
import { WithUnit } from '../../hocs';
import Setting from './Setting';

const SettingPage: React.FC = () => {
  return (
    <WithUnit>
      <Setting />
    </WithUnit>
  );
};
export default SettingPage;
