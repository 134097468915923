import React, { useState, useEffect } from 'react';
import './ExaminationTypes.scss';
import Toggle from 'react-toggle';
import { useTranslation } from 'react-i18next';
import parseArabic from '../../../../utils/parseArabic';
import checkIcon from '../../../../assets/img/Group 3420.svg';
import close2Icon from '../../../../assets/img/close.svg';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../store/reducers';
import { getNetworks } from '../../../../services/api/unit';

type Props = {
  shouldToggle: boolean;
  isCustom: boolean;
  setInputHandler: (
    name: string,
    color: string,
    keyName: string,
    value: string | null,
    isFirstToggle?: boolean
  ) => void;
  type: string;
  color: string;
  name: string;
  price?: string;
  value?: string;
  time?: string;
  period?: string;
  isFollowUp: boolean;
  setNetworks: (s: any) => void;
  networks: any;
  // setSelecedNetworks: (s: any) => void;
  // allSelecedNetworks: any;
  // setUnSelecedNetworks: (s: any) => void;
  // allUnSelecedNetworks: any;
  isSettingPage?: boolean;
};

const ExaminationType: React.FC<Props> = ({
  type,
  shouldToggle,
  isCustom,
  color,
  name,
  price,
  time,
  period,
  value,
  setInputHandler,
  isFollowUp,
  setNetworks,
  networks,
  // setSelecedNetworks,
  // allSelecedNetworks,
  // setUnSelecedNetworks,
  // allUnSelecedNetworks,
  isSettingPage,
}) => {
  const { t } = useTranslation();
  const [toggle, setSetToggle] = useState(shouldToggle);
  const [allSelecedNetworks, setSelecedNetworks] = useState<any[]>([]);
  const [allUnSelecedNetworks, setUnSelecedNetworks] = useState<any[]>([]);
  const [allNet, setAllNet] = useState<any[]>([]);

  const [condation, setCondation] = useState<string>('');
  const [codeDel, setCodeDel] = useState<string>('');

  const [priceValue, setPrice] = useState(
    typeof price !== undefined || typeof price !== 'undefined' ? price : ''
  );
  const [timeValue, setTime] = useState(time ? time : '');
  const [periodValue, setPeriod] = useState(period ? period : '');
  const [networkValue, setNetwork] = useState<any>();
  const [discountValue, setDiscount] = useState<string>('');

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const getAllNetworks = async () => {
    const allNetworks = await getNetworks();
    isSettingPage
      ? setAllNet(allNetworks.data.networks)
      : setUnSelecedNetworks(allNetworks.data.networks);
  };
  const handleTofuChange = (e: any) => {
    setSetToggle(e.target.checked);
    if (!e.target.checked) {
      setInputHandler(name, color, e.target.name, null);
      // setPrice('');
      // setTime('');
      // setPeriod('');
    } else {
      setInputHandler(name, color, e.target.name, null, e.target.checked);
    }
  };

  useEffect(() => {
    if (isSettingPage) {
      if (!currentUnitDetails) return;
      let netSelected: any = [];
      let netUnSelected: any = [];
      let off: any = [];
      let exist: boolean = false;

      if (currentUnitDetails?.networks?.length) {
        for (const key in allNet) {
          for (const network in currentUnitDetails.networks) {
            if (
              allNet[key].code === currentUnitDetails.networks[network].code
            ) {
              if (
                currentUnitDetails.networks[network].offers.find(
                  (off) => off.service === name
                )
              ) {
                exist = true;
                off = currentUnitDetails.networks[network].offers;
              }
            }
          }
          if (exist) {
            const net: any = netSelected;
            netSelected = [
              {
                name: allNet[key].name,
                code: allNet[key].code,
                offers: off,
                pk: allNet[key].pk,
                sk: allNet[key].sk,
              },
              ...net,
            ];
          } else {
            const net: any = netUnSelected;
            netUnSelected = [
              {
                name: allNet[key].name,
                code: allNet[key].code,
                offers: [],
                pk: allNet[key].pk,
                sk: allNet[key].sk,
              },
              ...net,
            ];
          }
          exist = false;
        }
      } else {
        for (const key in allNet) {
          const net: any = netUnSelected;
          netUnSelected = [
            {
              name: allNet[key].name,
              code: allNet[key].code,
              offers: [],
              pk: allNet[key].pk,
              sk: allNet[key].sk,
            },
            ...net,
          ];
        }
        setUnSelecedNetworks(netUnSelected);
        return;
      }
      setCondation('');
      setSelecedNetworks(netSelected);
      setUnSelecedNetworks(netUnSelected);
    }
  }, [allNet, currentUnitDetails]);

  // useEffect(() => {
  //   if (isSettingPage) {
  //     if (!currentUnitDetails) return;

  //     if (currentUnitDetails?.networks?.length) {
  //       for (const network in currentUnitDetails.networks) {
  //         if (
  //           currentUnitDetails.networks[network].offers.find(
  //             (off) => off.service === name
  //           )
  //         ) {
  //           setSelecedNetworks(currentUnitDetails.networks);
  //         } else {
  //           setUnSelecedNetworks(currentUnitDetails.networks);
  //         }
  //       }
  //     }
  //   }
  // }, [currentUnitDetails]);

  useEffect(() => {
    if (condation === 'add') {
      if (networks.length <= 0) {
        for (const key in allSelecedNetworks) {
          if (allSelecedNetworks.hasOwnProperty(key)) {
            setNetworks([
              {
                code: allSelecedNetworks[key].code,
                name: allSelecedNetworks[key].name,
                offers: allSelecedNetworks[key].offers,
              },
            ]);
          }
        }
      } else {
        for (const key in allSelecedNetworks) {
          let f: boolean = true;
          let exist: boolean = false;
          for (const i in networks) {
            if (allSelecedNetworks[key].code === networks[i].code) {
              exist = true;
            }
          }
          if (exist) {
            for (const i in networks) {
              if (allSelecedNetworks[key].code === networks[i].code) {
                if (allSelecedNetworks[key].offers.length > 0) {
                  for (const off in networks[i].offers) {
                    for (const k in allSelecedNetworks[key].offers) {
                      if (
                        networks[i].offers[off].service ===
                        allSelecedNetworks[key].offers[k].service
                      ) {
                        f = false;
                      }
                    }
                  }
                  if (f) {
                    const net = networks;
                    net[i].offers.push(allSelecedNetworks[key].offers[0]);
                    net[i] = { ...net[i] };
                    setNetworks(net);
                    f = true;
                  }
                }
              }
            }
            exist = false;
          } else {
            setNetworks([
              ...networks,
              {
                code: allSelecedNetworks[key].code,
                name: allSelecedNetworks[key].name,
                offers: allSelecedNetworks[key].offers,
              },
            ]);
          }
        }
      }
    } else if (condation === 'delete') {
      for (const key in allSelecedNetworks) {
        for (const i in networks) {
          if (codeDel === networks[i].code) {
            const net = networks;
            const afterdel = net[i].offers.filter((off: any) => {
              return off.service !== name;
            });
            net[i] = { ...net[i], offers: afterdel };
            setNetworks(net);
          }
        }
      }
    }
  }, [allSelecedNetworks, condation]);

  useEffect(() => {
    getAllNetworks();
    return () => {
      setPrice('');
      setTime('');
      setPeriod('');
    };
  }, []);

  const setInputNetworkHandlerr = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const attrName = e.target.name;
    const attrValue = e.target.value;

    if (attrName === 'network') {
      setNetwork(attrValue);
    }
    if (attrName === 'discount') {
      setDiscount(attrValue);
    }
  };

  const addNetworks = () => {
    if (isSettingPage) {
      if (!currentUnitDetails) return;

      if (networkValue && discountValue !== '') {
        let f = false;
        const net: any = allUnSelecedNetworks.filter(
          (net: any, ind: number) => {
            return net.code !== allUnSelecedNetworks[networkValue].code;
          }
        );
        const netnew: any = allUnSelecedNetworks.filter(
          (net: any, ind: number) => {
            return net.code === allUnSelecedNetworks[networkValue].code;
          }
        );
        const oldnet: any = allSelecedNetworks;
        netnew[0].offers.push({
          service: name,
          discountRatio: discountValue ? discountValue : '',
        });
        for (const key in allSelecedNetworks) {
          if (netnew[0].code === allSelecedNetworks[key].code) {
            f = true;
          }
        }

        setCondation('add');
        f
          ? setSelecedNetworks(oldnet)
          : setSelecedNetworks([...allSelecedNetworks, ...netnew]);
        setUnSelecedNetworks(net);
        setDiscount('');
        setNetwork('');
      }
    } else {
      if (networkValue && discountValue !== '') {
        const net: any = allUnSelecedNetworks.filter(
          (net: any, ind: number) => {
            return net.code !== allUnSelecedNetworks[networkValue].code;
          }
        );
        setUnSelecedNetworks(net);
        const netnew: any = allUnSelecedNetworks.filter(
          (net: any, ind: number) => {
            return net.code === allUnSelecedNetworks[networkValue].code;
          }
        );
        netnew[0] = { ...netnew[0], offers: [] };
        netnew[0].offers.push({
          service: name,
          discountRatio: discountValue ? discountValue : '',
        });
        setCondation('add');
        setSelecedNetworks([...allSelecedNetworks, ...netnew]);
        setDiscount('');
        setNetwork('');
      }
    }
  };
  const updateNetworks = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    index: any
  ) => {
    const attrName = e.target.name;
    const attrValue = e.target.value;

    if (attrName === 'networkDiscount') {
      const data = allSelecedNetworks;
      const offers = data[index].offers;
      for (const off in offers) {
        if (offers[off].service === name) {
          offers[off].discountRatio = attrValue;
        }
      }
      data[index].offers = offers;
      setCondation('update');
      setSelecedNetworks([...data]);
    }
  };
  const deletNetworks = (index: any) => {
    if (isSettingPage) {
      if (!currentUnitDetails) return;

      const net: any = allSelecedNetworks.filter((n: any, ind: number) => {
        return ind === index;
      });
      net[0].offers = net[0].offers.filter((off: any) => {
        return off.service !== name;
      });
      setUnSelecedNetworks([...allUnSelecedNetworks, ...net]);
      const data = allSelecedNetworks;
      const offers = data[index].offers;
      for (const off in offers) {
        if (offers[off].service === name) {
          offers.splice(off, 1);
        }
      }
      data[index].offers = offers;
      setCodeDel(net[0].code);
      setCondation('delete');
      setSelecedNetworks([...data]);
    } else {
      const net: any = allSelecedNetworks.filter((n: any, ind: number) => {
        return ind === index;
      });
      net[0].offers = net[0].offers.filter((off: any) => {
        return off.service !== name;
      });
      setUnSelecedNetworks([...allUnSelecedNetworks, ...net]);
      const data = allSelecedNetworks;
      const offers = data[index].offers;
      for (const off in offers) {
        if (offers[off].service === name) {
          offers.splice(off, 1);
        }
      }
      data[index].offers = offers;
      setCondation('delete');
      setSelecedNetworks([...data]);
    }
  };

  const setInputHandlerr = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const attrName = e.target.name;
    const attrValue: any = e.target.value;

    if (attrName === 'name') {
      setInputHandler(name, color, e.target.name, attrValue);
    }
    let parsedNumber = parseArabic(attrValue);
    const isValid = /^[0-9]*$/.test(parsedNumber);
    if (isValid) {
      parsedNumber = +parsedNumber;
      if (attrName === 'price') {
        setPrice(parsedNumber);
      } else if (attrName === 'time') {
        setTime(parsedNumber);
      } else if (attrName === 'period') {
        setPeriod(parsedNumber);
      }
      setInputHandler(name, color, e.target.name, parsedNumber);
    }
  };

  return (
    <div
      className="examinationTypes-container_examine p-4"
      style={{
        backgroundColor: color,
        alignSelf: toggle ? 'inherit' : 'baseline',
      }}
    >
      {/* {console.log(
        'name',
        name,
        'allUnSelecedNetworks',
        allUnSelecedNetworks,
        'allSelecedNetworks',
        allSelecedNetworks,
        'networks',
        networks,
        'allNet',
        allNet
      )} */}
      <div className="examinationTypes-container_examine-toggle  d-flex justify-content-between ">
        <div>
          {!isCustom ? (
            <p>{t('examinationAndPrices_page.' + type)}</p>
          ) : (
            <input
              className="custom-name input"
              placeholder={t('examinationAndPrices_page.custom')}
              defaultValue={value}
              type="text"
              name="name"
              onChange={setInputHandlerr}
            />
          )}
        </div>
        <div>
          <Toggle
            className="custom-classname"
            defaultChecked={toggle}
            icons={false}
            onChange={handleTofuChange}
          />
        </div>
      </div>
      {toggle ? (
        <div>
          <div className="examinationTypes-container_examine-inputs mt-3 d-flex justify-content-between">
            <div className="price">
              <p>{t('examinationAndPrices_page.price')}</p>
              <div className="d-flex">
                <input
                  className="input"
                  type="number"
                  name="price"
                  value={priceValue == '0' ? '' : priceValue}
                  onChange={setInputHandlerr}
                />
                <span className="align-self-end ml-2 mr-2">
                  {t('examinationAndPrices_page.egp')}
                </span>
              </div>
            </div>
            {isFollowUp && (
              <div className="period">
                <p>{t('examinationAndPrices_page.period')}</p>
                <div className="d-flex">
                  <input
                    className="input"
                    type="number"
                    name="period"
                    value={periodValue == '0' ? '' : periodValue}
                    onChange={setInputHandlerr}
                  />
                  <span className="align-self-end ml-2 mr-2">
                    {t('examinationAndPrices_page.days')}
                  </span>
                </div>
              </div>
            )}

            <div className="time">
              <p>{t('examinationAndPrices_page.time')}</p>
              <div className="d-flex">
                <input
                  className="input"
                  type="number"
                  name="time"
                  value={timeValue == '0' ? '' : timeValue}
                  onChange={setInputHandlerr}
                />
                <span className="align-self-end ml-2 mr-2">
                  {t('examinationAndPrices_page.minutes')}
                </span>
              </div>
            </div>
          </div>
          <div>
            {
              <hr
                style={{
                  border: `1px dashed ${
                    isCustom
                      ? '#F9C784'
                      : name === 'urgent'
                      ? '#FF1654'
                      : '#0090FF'
                  }`,
                }}
              ></hr>
            }
            <div>
              <div>
                <p>{t('examinationAndPrices_page.discounts')}</p>
              </div>
            </div>
            {allSelecedNetworks && Object.keys(allSelecedNetworks).length > 0
              ? Object.keys(allSelecedNetworks).map(
                  (net, index) =>
                    allSelecedNetworks[index].offers &&
                    Object.keys(allSelecedNetworks[index].offers).map(
                      (off, i) =>
                        allSelecedNetworks[index].offers[i].service ===
                          name && (
                          <div
                            key={`${net}`}
                            className="network network-selected  d-flex justify-content-between pt-2"
                          >
                            <div className="d-flex col-5">
                              <input
                                name="networkName"
                                type="text"
                                value={allSelecedNetworks[index].name}
                                readOnly
                              />
                            </div>

                            <div className="d-flex col-5">
                              <input
                                name="networkDiscount"
                                type="number"
                                min={0}
                                max={100}
                                value={
                                  allSelecedNetworks[index].offers[i]
                                    .discountRatio
                                }
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value) < 0 ||
                                    Number(e.target.value) > 100
                                  ) {
                                  } else {
                                    updateNetworks(e, index);
                                  }
                                }}
                              />
                              <span className="align-self-end ml-2 mr-2">
                                {'%'}
                              </span>
                            </div>
                            <div className="d-flex align-items-center col-1">
                              <img
                                src={close2Icon}
                                alt="type name"
                                onClick={() => {
                                  deletNetworks(index);
                                }}
                                className="offer-action-button"
                              ></img>
                            </div>
                          </div>
                        )
                    )
                )
              : null}
            <div className="network d-flex justify-content-between pt-2">
              <div className="d-flex col-5">
                <select
                  name="network"
                  value={networkValue}
                  onChange={setInputNetworkHandlerr}
                >
                  <option value="choose-network" hidden>
                    choose network
                  </option>
                  {allUnSelecedNetworks && allUnSelecedNetworks.length ? (
                    Object.keys(allUnSelecedNetworks).map(
                      (key, ind) =>
                        allUnSelecedNetworks && (
                          <option key={key} value={ind}>
                            {allUnSelecedNetworks[ind].name}
                          </option>
                        )
                    )
                  ) : (
                    <option value="choose-network">No Network</option>
                  )}
                </select>
              </div>
              <div className="d-flex col-5">
                <input
                  name="discount"
                  type="number"
                  value={discountValue}
                  placeholder={t('examinationAndPrices_page.type-discount')}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) < 0 ||
                      Number(e.target.value) > 100
                    ) {
                    } else {
                      setInputNetworkHandlerr(e);
                    }
                  }}
                />
                <span className="align-self-end ml-2 mr-2">{'%'}</span>
              </div>
              <div className="lock-icon d-flex align-items-center col-1">
                <img
                  src={checkIcon}
                  alt="type name"
                  onClick={() => {
                    addNetworks();
                  }}
                  className="offer-action-button"
                ></img>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExaminationType;
