import React, { useReducer } from 'react';
import './ScansTypes.scss';

import { useTranslation } from 'react-i18next';

import ScanType from './ScanType/ScanType';
import Button from '../../UI/Form/Button/Button';
// import { errorToast } from '../../../utils/toast';
import backArrow from '../../../assets/img/backArrow.svg';
import { errorToast } from '../../../utils/toast';
import { ClinicData } from '../../../types';
import { SubscriptionStatus } from '../../Subscription';

const scans = {};

type Action = {
  type: string;
  payload: any;
};

const reducer = (state: any, action: Action) => {
  return {
    ...state,
    [action.type]: { ...state[action.type], ...state.payload },
  };
};
type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit?: ClinicData | null;
};

const ScansTypes: React.FC<Props> = ({
  nextStepHandler,
  goBackHandler,
  currentSegment,
  initialState,
  isSettingPage,
  isSubmitting,
  unit,
}) => {
  const { t } = useTranslation();
  if (initialState && Object.keys(initialState).length === 0) {
    initialState = scans;
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const changeScanTypes = (type: string, payload: any) => {
    dispatch({ type, payload });
  };

  const goBack = () => {
    goBackHandler({ type: currentSegment, data: initialState });
  };

  const saveScansTypesHandler = async () => {
    // console.log('state', state);
    const isTestsChecked = Object.values(state).some((ele: any) => ele.checked);
    if (!isTestsChecked) {
      errorToast(t('employess_page.choose_one'));
      return;
    }
    let isSubScansChecked = false;
    for (const test of Object.keys(state)) {
      const subScans = state[test].subScans;
      isSubScansChecked = Object.values(subScans).some(
        (sub: any) => sub.checked
      );
      if (state[test].checked && !isSubScansChecked) {
        errorToast(t('employess_page.test_err'));
        return;
      }
    }
    nextStepHandler({ type: currentSegment, data: state });
  };
  return (
    // scrollHide flex-column flex-sm-row p-0 p-sm-4 pt-5 pt-sm-0
    <div className="scansTypes-container d-flex flex-column ">
      {isSettingPage && (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              saveData={saveScansTypesHandler}
              isSubmitting={isSubmitting}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBackHandler}
            />
          </div>
        </div>
      )}
      <div className="scansTypes-container__main ">
        {Object.keys(initialState).length > 0 &&
          Object.keys(initialState).map((scan: any, index: any) => (
            <ScanType
              changeScanTypes={changeScanTypes}
              key={index}
              shouldToggle={state[scan].checked}
              type={scan}
              initialState={state[scan]}
            />
          ))}
      </div>
      {!isSettingPage && (
        <>
          <Button
            name={t('working_days_page.next')}
            saveData={saveScansTypesHandler}
          />
          <div
            className="back go-back align-self-end"
            onClick={() => {
              goBack();
            }}
          >
            <span>{t('otp_page.back')}</span>
            <img src={backArrow} alt="back" />
          </div>
        </>
      )}
    </div>
  );
};

export default ScansTypes;
