import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import { rootState } from '../../store/reducers';

const HomePage = () => {
  const { authToken } = useSelector((state: rootState) => state.auth);
  const { currentEmpUnit } = useSelector((state: rootState) => state.booking);

  return authToken ? (
    currentEmpUnit &&
    [UserRoles.Physician, UserRoles.RadioTech, UserRoles.LabTech].includes(
      currentEmpUnit.role
    ) ? (
      <Redirect to={routes.BOARD} />
    ) : currentEmpUnit &&
      ![UserRoles.Physician, UserRoles.RadioTech, UserRoles.LabTech].includes(
        currentEmpUnit.role
      ) ? (
      <Redirect to={routes.BOOKING} />
    ) : null
  ) : (
    <Redirect to={routes.CHECK_USER} />
  );
};
export default HomePage;
