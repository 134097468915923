import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { searchOrder } from '../../../../services/api/search';
import { printOrder } from '../../../../services/print';
import { rootState } from '../../../../store/reducers';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import { SelectedItemTag } from '../SelectedItemTag/SelectedItemTag';

import './OrdersComponent.scss';
type Props = {
  column: number;
  data: any[];
  onSelect: (data: any) => void;
  onRemove: (data: any) => void;
};
const OrdersComponent = (props: Props) => {
  const { column, data, onSelect, onRemove } = props;
  const [title] = useState<string>('Investigations (Lab/Rad)');
  const [result, setResult] = useState<any>([]);
  const [hText, setHText] = useState<any>('');
  const [reset, doReset] = useState(0);
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );
  const activeSection = useSelector(
    (state: rootState) => state.board.activeSection
  );
  const strings = hText.split(' ');
  const handlePrint = () => {
    printOrder(currentPatient, data);
  };
  const onTextChange = async (value: string) => {
    if (!value) setResult([]);
    else {
      const { data: searchData } = await searchOrder({ text: value });
      setHText(value);
      setResult(searchData);
    }
  };
  const onDone = (value: string) => {
    setResult([]);
    setHText('');
    doReset((prev) => prev + 1);
  };
  const onSelectItem = (element: any) => {
    onSelect(element);
    setResult([]);
    doReset((prev) => prev + 1);
  };

  useEffect(() => {
    setResult([]);
    setHText('');
    doReset((prev) => prev + 1);
  }, [activeSection.section]);

  return (
    <div className="component orders-component">
      <SectionHeaderComponent
        title={title}
        column={column}
        hasButton={true}
        canPrint={data.length > 0}
        handlePrint={handlePrint}
        onTextChange={onTextChange}
        onDone={onDone}
        reset={reset}
      />
      <div className="col-content">
        <div className="result-container">
          {result.map((element: any) => {
            const exist = data.find((s: any) => s.code === element.code);
            if (exist) return null;
            return (
              <div
                key={`order-result-${element.code}`}
                className="item result"
                onClick={() => {
                  onSelectItem(element);
                }}
              >
                <div className="ele">
                  <strong>{element.code} </strong>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={strings}
                    autoEscape={true}
                    textToHighlight={`${element.name}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="selected-result">
          <div className="tags">
            {data.map((element: any) => (
              <SelectedItemTag
                key={`diag-${element.code}`}
                element={element}
                onRemove={onRemove}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersComponent;
