import React, { useEffect, useState } from 'react';
import labIcon from '../../assets/img/Mask Group 327.svg';
import historyIcon from '../../assets/img/Mask Group 330.svg';
import radiologyIcon from '../../assets/img/Mask Group 46.svg';
import clinicalExamIcon from '../../assets/img/Mask Group 53.svg';
import { setCurrentBooking } from '../../store/actions';
import { formatCAlDate } from '../../utils/moment';

import getFullName from '../../utils/getFullName';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../store/reducers';
import { setEncounter } from '../../store/actions/board.actions';
import { Booking, fLog, Patient } from '../../types';
import FinancialReport from './FinancialReport/FinancialReport';
import paymentTypes from '../../assets/constants/paymentTypes';

type BoardMenuProps = {
  currentPatient?: Patient;
  currentView: any;
  setCurrentView: (view: string) => void;
  filteredHistory: any;
  currentEncounter: any;

  setRefereModal: (open: boolean) => void;
  isDental?: boolean;
  currentBooking?: Booking | null;
};

const ReportHeader = ['Date', 'Record', 'Value', 'Balance', 'Notes'];

const BoardMenu: React.FC<BoardMenuProps> = ({
  currentPatient,
  currentView,
  setCurrentView,
  filteredHistory,
  setRefereModal,
  isDental = false,
  currentBooking,
}) => {
  // TODO :  add Dynamic Price calculation
  const [openReport, setOnenReport] = useState(false);
  const [dataBody, setDataBody] = useState<fLog[]>([]);
  const [expandedHistory, setExpandedHistory] = useState<any[]>([]);
  const [totalCost, setTotalCost] = useState<{ paid: number; total: number }>({
    paid: 0,
    total: 0,
  });
  const referal = useSelector(
    (state: rootState) => state.board.referredEncounters
  );
  const currentFinancailLog = useSelector(
    (state: rootState) => state.booking.currentFinancailLog
  );
  const currentEncounter = useSelector(
    (state: rootState) => state.board.currentEncounter
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      currentFinancailLog &&
      currentFinancailLog.fLog &&
      currentBooking?.dr.specialty === 'dentistry'
    ) {
      setDataBody(currentFinancailLog.fLog);
      let total = 0;
      let paid = 0;
      currentFinancailLog.fLog.forEach((log) => {
        if (log.record === paymentTypes.pay) paid += log.value;
        else if (log.record === paymentTypes.retrieve) paid -= log.value;
        else total += log.value;
      });
      setTotalCost({ paid, total });
    }
  }, [currentFinancailLog, currentBooking]);

  useEffect(() => {
    if (isDental) {
      const tempHistory = filteredHistory.map((enc: any) => {
        if (enc.visits?.length > 0) {
          const tempEnc: any = {};
          Object.assign(tempEnc, enc);
          const expendedHistory = enc.visits.map((visit: any) => {
            const expandedEnc = { ...tempEnc, b: visit.booking };
            return expandedEnc;
          });
          return expendedHistory.reverse();
        }
        return enc;
      });

      setExpandedHistory(tempHistory.flat());
    } else {
      setExpandedHistory(filteredHistory);
    }
  }, [filteredHistory, isDental]);

  useEffect(() => {}, [currentBooking]);

  const openModel = () => {
    setOnenReport(!openReport);
  };

  return (
    <div className="list">
      {openReport && (
        <FinancialReport
          header={ReportHeader}
          data={dataBody}
          onModalClose={openModel}
          title={`${currentPatient?.name.firstName} ${currentPatient?.name.lastName}`}
          mobile={currentPatient?.lmobile || ''}
        />
      )}
      <div className="list-group">
        {isDental && (
          <div className="d-flex w-100 flex-row justify-content-around financial-container">
            <div className="money-sign">$</div>
            <div className="financial-text">Financial report</div>
            <div onClick={openModel} className="price">
              {totalCost.paid}/{totalCost.total}
            </div>
          </div>
        )}
        <div
          className={`list-group-item list-group-item-action ${
            currentView === 'clinicalExam' ? 'active' : ''
          }`}
          onClick={() => {
            setCurrentView('clinicalExam');
          }}
        >
          <div className="d-flex w-100 justify-content-start">
            <img src={clinicalExamIcon} alt="" />
            <div className="item-title">Clinical Exam</div>
          </div>
        </div>

        <div
          className={`list-group-item list-group-item-action history ${
            currentView === 'historyView' ? 'active' : ''
          }`}
          onClick={() => {
            if (expandedHistory.length > 0) {
              setCurrentView('historyView');
              // setCurrentEncounter(expandedHistory[0]);
              dispatch(setEncounter(expandedHistory[0]));
            }
          }}
        >
          <ul className="list-group history-date-list">
            <li
              className={`list-group-item head d-flex w-100 justify-content-start`}
            >
              <img src={historyIcon} alt="" />
              <div className="item-title">History</div>
            </li>
            {currentView === 'historyView'
              ? expandedHistory.map((enc: any) => {
                  return (
                    <li
                      key={enc.b.date}
                      className={`list-group-item ${
                        enc.b.date === currentEncounter.b.date ? 'selected' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setEncounter(enc));
                        // setCurrentEncounter(enc);
                      }}
                    >
                      {/* {formatCAlDate(enc._ct)} */}
                      {formatCAlDate(enc.b.date)}
                    </li>
                  );
                })
              : expandedHistory &&
                expandedHistory.length > 0 && (
                  <li className="list-group-item">
                    {formatCAlDate(expandedHistory[0]._ct)}
                  </li>
                )}
            {referal.map((enc: any) => {
              return (
                <li
                  key={enc._ct}
                  className={`list-group-item ${
                    enc.sk === currentEncounter.sk ? 'selected' : ''
                  }`}
                  onClick={(e) => {
                    // e.stopPropagation();
                    // setCurrentEncounter(enc);
                  }}
                >
                  {formatCAlDate(enc._ct)}
                  <h6 className="p2">
                    {' '}
                    Dr. {getFullName(enc.dr?.name || '')} (Referral)
                  </h6>
                </li>
              );
            })}
          </ul>
        </div>

        <div
          className={`list-group-item list-group-item-action ${
            currentView === 'radiologyView' ? 'active' : ''
          }`}
          onClick={() => {
            setCurrentView('radiologyView');
          }}
        >
          <div className="d-flex w-100 justify-content-start">
            <img src={radiologyIcon} alt="" />
            <div className="item-title">Radiology</div>
          </div>
        </div>

        <div
          className={`list-group-item list-group-item-action ${
            currentView === 'labView' ? 'active' : ''
          }`}
          onClick={() => {
            setCurrentView('labView');
          }}
        >
          <div className="d-flex w-100 justify-content-start">
            <img src={labIcon} alt="" />
            <div className="item-title">Lab Results</div>
          </div>
        </div>
        {isDental && (
          <div className="d-flex w-100 pt-4 justify-content-center">
            <button
              type="button"
              className="refer-button btn btn-primary btn-lg"
              onClick={() => {
                setRefereModal(true);
              }}
            >
              <h5>Refer Patient</h5>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardMenu;
