import api from './api';

export const addBooking = async (payload: any): Promise<any> => {
  return await api.send('addBooking', payload);
};

export const updateBooking = async (payload: any): Promise<any> => {
  return await api.send('updateBooking', payload);
};

export const getClinicBookings = async (payload: any): Promise<any> => {
  return await api.send('clinicBookings', payload);
};

export const getClinicBookingsPending = async (payload: any): Promise<any> => {
  return await api.send('clinicBookingsPending', payload);
};

export const getPatientPreviousBookings = async (
  payload: any
): Promise<any> => {
  return await api.send('patientPreviousBooking', payload);
};

export const getPatientUpCommingBooking = async (
  payload: any
): Promise<any> => {
  return await api.send('patientUpCommingBooking', payload);
};

export const getPatientUnitBookings = async (payload: {
  pId: string;
  unitId: string;
  type?: string;
}): Promise<any> => {
  return await api.send('patientUnitBookings', payload);
};

export const getLabBookings = async (payload: any): Promise<any> => {
  return await api.send('getLabBookings', payload);
};

export const markComplete = async (payload: any): Promise<any> => {
  return await api.send('markComplete', payload);
};

export const updateBookingTest = async (payload: any): Promise<any> => {
  return await api.send('updateBookingTest', payload);
};
export const deleteBookingTest = async (payload: any): Promise<any> => {
  return await api.send('deleteBookingTest', payload);
};

export const addDentOrder = async (payload: any): Promise<any> => {
  return await api.send('addDentOrder', payload);
};
