import React, { useEffect, useState } from 'react';
import ChangePasswordForm from '../../components/ChangePassword/ChangePasswordForm';
import { useHistory, useLocation } from 'react-router-dom';
import apiService from '../../services/api';
import RegisterClinicDetails from '../../components/Layout/RegisterClinicDetails/RegisterClinicDetails';
import routes from '../../assets/constants/routes';
import { isAuthenticated } from '../../utils/storage';

const ChangePassword: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();

  const [user, setUser] = useState<any>({});
  const [token, setToken] = useState('');

  useEffect(() => {
    // console.log('user from Login', location.state);
    if (
      location &&
      location.state &&
      location.state.user &&
      location.state.token
    ) {
      setUser({ ...location.state.user });
      setToken(location.state.token);
    } else {
      history.push(routes.CHECK_USER);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated()) history.push(routes.HOME);
  }, [history]);

  const changePasswordhandler = async (values: any) => {
    try {
      const payload = { ...values, mobile: user.mobile, token, id: user.pk };
      // console.log('payload', payload);
      await apiService.changePassword(payload);
      // console.log('data', data.data);
      history.push(routes.LOGIN);
    } catch (error) {
      console.error('error[checkOtp]', error);
    }
  };

  return (
    <div className="d-flex flex-column-reverse flex-sm-row">
      <ChangePasswordForm changePasswordhandler={changePasswordhandler} />
      <RegisterClinicDetails />
    </div>
  );
};

export default ChangePassword;
