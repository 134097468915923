import api from './api';

export const searchSymp = async (payload: any): Promise<any> => {
  return await api.send('searchSymp', payload);
};
export const searchDiag = async (payload: any): Promise<any> => {
  return await api.send('searchDiag', payload);
};
export const searchAllergy = async (payload: any): Promise<any> => {
  return await api.send('searchAllergy', payload);
};
export const searchChronic = async (payload: any): Promise<any> => {
  return await api.send('searchChronic', payload);
};
export const searchOrder = async (payload: any): Promise<any> => {
  return await api.send('searchOrder', payload);
};
export const searchPresc = async (payload: any): Promise<any> => {
  return await api.send('searchPresc', payload);
};
