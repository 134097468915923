import React from 'react';
import './SelectedItemTag.scss';
type Props = {
  element: any;
  onRemove: (data: any) => void;
};
export const SelectedItemTag = (props: Props) => {
  const { element, onRemove } = props;
  return (
    <span className="selected-tag-item badge badge-primary">
      <div className="d-name">
        <strong>{element.code}: </strong> {element.name}
      </div>
      <button
        type="button"
        className="btn remove btn-sm"
        onClick={() => {
          onRemove(element);
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </span>
  );
};
