import { UserRoles } from './assets/constants/roles';
import routes from './assets/constants/routes';
import AddBookingPage from './containers/AddBooking';
import AddClinic from './containers/AddClinic/AddClinic';
import AddImagingCenter from './containers/AddImagingCenter/AddImagingCenter';
import AddLab from './containers/AddLab/AddLab';
import AddPatientPage from './containers/AddPatient';
import BoardPage from './containers/Board';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import CheckUser from './containers/CheckUser/CheckUser';
import CompleteProfile from './containers/CompleteProfile/CompleteProfile';
import EmpProfile from './containers/EmpProfile/Profile';
import ForgetPassword from './containers/ForgetPassword/ForgetPassword';
import Login from './containers/Login/Login';
import PlansPage from './containers/Plans';
import Profile from './containers/Profile/Profile';
import Register from './containers/Register/Register';
import ReportsPage from './containers/Reports';
import Search from './containers/search/Search';
import SelectUnitType from './containers/SelectUnitType/SelectUnitType';
import SettingPage from './containers/Setting';
import FinancesPage from './containers/Finances';
import SubscriptionPage from './containers/Subscription';
import UnitBoardPage from './containers/UnitBoard';
import DentalService from './components/DentalServices/dentalServices';
export const publicRoutes = [
  {
    path: routes.CHECK_USER,
    component: CheckUser,
  },
  {
    path: routes.REGISTER,
    component: Register,
  },
  {
    path: routes.LOGIN,
    component: Login,
  },
  {
    path: routes.FORGET_PASSWORD,
    component: ForgetPassword,
  },
  {
    path: routes.CHANGE_PASSWORD,
    component: ChangePassword,
  },
  {
    path: routes.COMPLETE_PROFILE,
    component: CompleteProfile,
  },
  {
    path: routes.DENTAL_SERVICES,
    component: DentalService,
  },
];

export const appRoutes = [
  {
    path: routes.BOARD,
    component: BoardPage,
    roles: [UserRoles.Physician, UserRoles.LabTech, UserRoles.RadioTech],
    hasUnit: true,
  },
  {
    path: routes.REPORTS,
    component: ReportsPage,
    roles: [UserRoles.Physician],
    hasUnit: true,
  },
  {
    path: routes.SETTINGS,
    component: SettingPage,
    roles: [UserRoles.Physician],
    hasUnit: true,
  },
  {
    path: routes.Finances,
    component: FinancesPage,
    roles: [UserRoles.Physician],
    hasUnit: true,
  },
  {
    path: routes.PROFILE,
    component: Profile,
    roles: [UserRoles.Physician],
    hasUnit: false,
  },
  {
    path: routes.SELECT_UNIT_TYPE,
    component: SelectUnitType,
    roles: [UserRoles.Physician],
    hasUnit: false,
  },
  {
    path: routes.ADD_CLINIC,
    component: AddClinic,
    roles: [UserRoles.Physician],
    hasUnit: false,
  },
  {
    path: routes.ADD_LAB,
    component: AddLab,
    roles: [UserRoles.Physician],
    hasUnit: false,
  },
  {
    path: routes.ADD_IMAGING_CENTER,
    component: AddImagingCenter,
    roles: [UserRoles.Physician],
    hasUnit: false,
  },
  {
    path: routes.SEARCH,
    component: Search,
    roles: [UserRoles.Physician],
    hasUnit: false,
  },
  {
    path: routes.PLANS,
    component: PlansPage,
    roles: [UserRoles.Physician],
    hasUnit: true,
  },
  {
    path: routes.SUBSCRIPTION,
    component: SubscriptionPage,
    roles: [UserRoles.Physician],
    hasUnit: true,
  },
  {
    path: routes.BOOKING,
    component: UnitBoardPage,
    roles: [
      UserRoles.Assistant,
      UserRoles.Receptionist,
      UserRoles.Nurse,
      UserRoles.Physician,
    ],
    hasUnit: true,
  },
  {
    path: routes.ADD_BOOKING,
    component: AddBookingPage,
    roles: [
      UserRoles.Assistant,
      UserRoles.Receptionist,
      UserRoles.Nurse,
      UserRoles.Physician,
    ],
    hasUnit: true,
  },
  {
    path: routes.ADD_PATIENT,
    component: AddPatientPage,
    roles: [
      UserRoles.Assistant,
      UserRoles.Receptionist,
      UserRoles.Nurse,
      UserRoles.Physician,
    ],
    hasUnit: true,
  },
  {
    path: routes.EMP_PROFILE,
    component: EmpProfile,
    roles: [UserRoles.Assistant, UserRoles.Receptionist, UserRoles.Nurse],
    hasUnit: false,
  },
];
