import React from 'react';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/img/close-icon.svg';
import { formatCAlDate } from '../../../utils/moment';

type Props = {
  removeScanHandler: (index: number) => void;
  searchOrderHandler: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  showScanHandler?: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  changeScanHandler: (
    index: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  index: number;
  scan: {
    name: string;
    code: string;
    price: string | number;
    time: string;
    date: string;
    removed: boolean;
  };
  result: any;
  timeSlots: any;
  className: string;
  isCustomScan: boolean;
};

const TableRow: React.FC<Props> = ({
  removeScanHandler,
  changeScanHandler,
  searchOrderHandler,
  showScanHandler,
  index,
  scan,
  result,
  timeSlots,
  className,
  isCustomScan,
}) => {
  const { i18n } = useTranslation();
  return (
    <tr className={className}>
      <td>
        {isCustomScan ? (
          <div className="d-flex align-items-center">
            <img
              className="close"
              src={closeIcon}
              alt="closeIcon"
              onClick={() => {
                removeScanHandler(index);
              }}
            />
            <input
              type="text"
              name="name"
              list="lab-orders"
              defaultValue={scan.name}
              autoComplete="off"
              onChange={(e) => {
                searchOrderHandler(index, e);
              }}
            />
            <datalist id="lab-orders">
              {result &&
                result.length > 0 &&
                result.map((order: any) => (
                  <option key={order.name} value={order.name} />
                ))}
            </datalist>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            {scan.removed ? (
              <input
                type="checkbox"
                onChange={(e) => {
                  if (showScanHandler) showScanHandler(index, e);
                }}
              />
            ) : (
              <img
                className="close"
                src={closeIcon}
                alt="closeIcon"
                onClick={() => {
                  removeScanHandler(index);
                }}
              />
            )}

            <h6>{scan.name}</h6>
          </div>
        )}
      </td>
      <td>
        <div className="d-flex align-items-center">
          <input
            type="number"
            name="price"
            disabled={scan.removed}
            className="price"
            value={scan.price == '0' ? '' : scan.price}
            onChange={(e) => {
              changeScanHandler(index, e);
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <input
            type="date"
            name="date"
            min={formatCAlDate(new Date())}
            disabled={scan.removed}
            defaultValue={scan.date}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              changeScanHandler(index, e);
            }}
            date-format="YYYY MM DD"
            // [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
          />
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <select
            name="time"
            disabled={scan.removed}
            defaultValue={scan.time}
            onChange={(e) => {
              changeScanHandler(index, e);
            }}
          >
            <option value=""></option>
            {timeSlots &&
              Object.keys(timeSlots)
                .sort()
                .map((timeslot: any) => (
                  <option key={timeslot} value={timeslot}>
                    {timeSlots[timeslot][i18n.language]}
                  </option>
                ))}
          </select>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
