import React from 'react';
import WithUnit from '../../hocs/WithUnit';
import Subscription from './Subscription';

const SubscriptionPage: React.FC = () => {
  return (
    <WithUnit>
      <Subscription />{' '}
    </WithUnit>
  );
};
export default SubscriptionPage;
