import React, { useEffect, useState } from 'react';
import './UnitPanel.scss';
import Toggle from 'react-toggle';

import { useTranslation } from 'react-i18next';
import PanelBookings from './UnitPanelBookings/UnitPanelBookings';
import CustomDatePicker from '../../UI/Form/CustomDatePicker/CustomDatePicker';
import Button from '../../UI/Form/Button/Button';
import { shouldSearchInputFocus } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

import { rootState } from '../../../store/reducers';
import showIcon from '../../../assets/img/show.svg';
import hideIcon from '../../../assets/img/hide.svg';
import {
  setShowAll,
  setSomeSlotsShown,
  setAllBooking,
} from '../../../store/actions';

type Props = {
  changeBookingDateHandler: (date: Date) => void;
  updateBookingStatusHandler: (status: string) => void;
  startDate: Date;
};

const UnitPanel: React.FC<Props> = ({
  changeBookingDateHandler,
  updateBookingStatusHandler,
  startDate,
}) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  // const [checkedToggle, setCheckedToggle] = useState(true);

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const isAllSlotsShown = true;
  // const [isAllBookings, setIsAllBookings] = useState(false);
  const showAll = useSelector((state: rootState) => state.booking.showAll);
  const allBookingsPending = useSelector(
    (state: rootState) => state.booking.bookingsPending
  );
  const allbooking = useSelector(
    (state: rootState) => state.booking.allbooking
  );
  const showHideBookingsHandler = () => {
    dispatch(setShowAll(!showAll));
    dispatch(setSomeSlotsShown(false));
  };

  const handleToggleChange = (e: any) => {
    dispatch(setAllBooking(e.target.checked));
    dispatch(setShowAll(!allbooking));
    // dispatch(setShowAll(true));
    // dispatch(setSomeSlotsShown(false));
  };

  return (
    <div className="d-flex flex-column flex-sm-row mt-3">
      <div
        className={`box ${
          i18n.language === 'en' ? 'en box-en' : 'ar box-ar'
        } sb ${isActive ? 'sb2' : 'sb1'}`}
      >
        <div>
          <Button
            type="button"
            name={t('booking_page.reservations')}
            saveData={() => {
              setIsActive(true);
            }}
            btnType={`button ${
              i18n.language === 'en' ? 'button-top-en' : 'button-top-ar'
            } ${isActive ? '' : 'button-not-active'}`}
          />
        </div>
        <div className={`btn-container`}>
          <button
            type="button"
            className={`button ${
              i18n.language === 'en' ? 'button-bottom-en' : 'button-bottom-ar'
            } ${isActive ? 'button-not-active' : ''}`}
            onClick={() => {
              setIsActive(false);
            }}
          >
            <div className="d-flex">
              <div className="p-2 "> {t('booking_page.requests')}</div>
              <div className="ml-auto p-2">
                <span
                  className={`span-bottom ${
                    isActive ? '' : 'span-bottom-active'
                  }`}
                >
                  {allBookingsPending.length}
                </span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="panel-container col-10 d-flex flex-column align-items-start">
        {isActive ? (
          <div className="panel-action d-flex align-items-center">
            <h6>{t('booking_page.bookings')}</h6>
            <CustomDatePicker
              minDate={new Date()}
              startDate={startDate}
              changeBookingDateHandler={changeBookingDateHandler}
            />
            <Button
              type="button"
              name={t('booking_page.add-booking')}
              saveData={() => {
                dispatch(shouldSearchInputFocus(true));
              }}
            />
          </div>
        ) : (
          <div className="panel-action d-flex align-items-center"></div>
        )}
        <div className="booking-action w-100">
          <button onClick={showHideBookingsHandler}>
            <img src={showAll ? hideIcon : showIcon} alt="showIcon" />
            <span className="mx-2">
              {t(
                showAll
                  ? 'booking_page.hide-booking'
                  : 'booking_page.show-booking'
              )}
            </span>
          </button>
          <span className="toggle-span">
            <Toggle
              className="custom-classname"
              defaultChecked={allbooking}
              icons={false}
              onChange={handleToggleChange}
            />
            <span className="mx-2">{t('booking_page.all-bookings')}</span>
          </span>
        </div>
        <div className="panel-bookings w-100 mt-4">
          <PanelBookings
            updateBookingStatusHandler={updateBookingStatusHandler}
            isReservationsActive={isActive}
            isAllSlotsShown={showAll}
            isAllBookings={allbooking}
          />
        </div>
      </div>
    </div>
  );
};

export default UnitPanel;
