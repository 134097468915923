import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorToast } from '../../../../utils/toast';

import Button from '../../../UI/Form/Button/Button';
import AddLabServiceModal from '../AddLabServiceModal/AddLabServiceModal';
import DeleteLabModal from '../DeleteLabModal/DeleteLabModal';
import './LabServices.scss';

type Lab = {
  _id: number;
  name: string;
  services: any[];
  status: string;
};
type Props = {
  labs: Lab[];
  setServiceHandler: () => void;
  confirmDelete: (id: number) => void;
  addServiceToLabHandler: (services: { name: string; price: number }[]) => void;
  selected: number;
  isSettingPage?: boolean;
};

const LabServices: React.FC<Props> = ({
  labs,
  setServiceHandler,
  confirmDelete,
  addServiceToLabHandler,
  selected,
  isSettingPage,
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [services, setServices] = useState<any[]>([]);

  const openModalHandler = () => {
    setOpenModal(!openModal);
  };

  const setServiceModalHandler = () => {
    setServiceModal(!serviceModal);
  };

  const saveHandler = () => {
    setServiceHandler();
  };
  const priceHandler = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = +e.target.value;
    if (value < 0) {
      value = value * -1;
    }
    const servicesTemp = [...services];
    servicesTemp[index].price = value;
    setServices(servicesTemp);
    if (labs[selected].status != 'new') {
      labs[selected].status = 'updated';
    }
  };
  const handlePriceFocus = (event: any) => event.target.select();

  useEffect(() => {
    if (labs && selected >= 0) {
      const lab = labs[selected];
      if (lab) {
        setServices(lab.services);
      }
    }
  }, [labs, selected]);

  const confirmAddService = (service: { name: string; price: number }) => {
    const serIndex = services.findIndex((ser) => ser.name === service.name);
    if (serIndex >= 0) {
      errorToast(t('labsDetails_page.service_found'));
    } else {
      const newServices = [...services, service];
      setServices(newServices);
      addServiceToLabHandler(newServices);
    }
  };

  return (
    <div className="div-table">
      <DeleteLabModal
        isOpen={openModal}
        onModalClose={openModalHandler}
        confirmDeleteHandler={() => {
          confirmDelete(selected);
          openModalHandler();
        }}
      />
      <AddLabServiceModal
        isOpen={serviceModal}
        onModalClose={setServiceModalHandler}
        confirmAddHandler={(ser: { name: string; price: number }) => {
          setServiceModalHandler();
          confirmAddService(ser);
        }}
      />
      <table className="table table-responsive table-striped">
        <thead>
          <tr>
            <th scope="col">
              <div>
                <h6>{t('labsDetails_page.service_name')}</h6>
              </div>
            </th>
            <th scope="col">
              <div>
                <h6>{t('labsDetails_page.service_price')}</h6>
              </div>
            </th>
            <th scope="col">
              <div className="button-actions d-flex ">
                {isSettingPage && (
                  <Button
                    type="button"
                    name={t('labsDetails_page.save')}
                    saveData={saveHandler}
                  />
                )}
                <Button
                  type="button"
                  name={t('labsDetails_page.delete')}
                  btnType="warning"
                  saveData={openModalHandler}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td></td>
            <td></td>
            <td>
              <button className="link-button" onClick={setServiceModalHandler}>
                {t('labsDetails_page.add_service')}
              </button>
            </td>
          </tr> */}
          {services.map((ele: any, index) => (
            <tr key={index}>
              <td>{ele.name}</td>
              <td>
                <div className="input-container d-flex  align-items-center ">
                  <input
                    type="number"
                    name="price"
                    onChange={(e) => {
                      priceHandler(index, e);
                    }}
                    onFocus={handlePriceFocus}
                    value={ele.price}
                  />
                </div>
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default LabServices;
