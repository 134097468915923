import React from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { AlertOutlineIcon } from '../../assets/constants/images';
import { ClinicData } from '../../types';
import Button from '../UI/Form/Button/Button';
import './ExpirationAlert.scss';
const customStyles = {
  overlay: {
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    transform: 'translateY(0)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '0',
    left: '0',
    right: '0',
    width: '100%',
    borderRadius: '0px',
    padding: '4rem 0',
    border: 'none',
  },
};

const dateFromToday = (date: Date) => {
  const date1 = new Date().getTime();
  const date2 = new Date(date).getTime();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
const ExpirationAlert = ({
  unit,
  open,
  closeModal,
}: {
  unit: ClinicData;
  open: boolean;
  closeModal: () => void;
}) => {
  const history = useHistory();

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={open}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={() => {
        closeModal();
      }}
      className="expiration-alert"
    >
      <div className="container">
        <div className="expiration-modal-container d-flex flex-row align-items-start">
          <span className="alert-icon">
            <AlertOutlineIcon />
          </span>
          {['lab', 'image'].includes(unit.type) && (
            <h1>
              Your Balance is low and you have only
              <span>{` ${unit.subscription.balance || 0} EGP`}</span>
            </h1>
          )}
          {unit.type === 'clinic' && (
            <h1>
              Your subscription is about to end and you have
              <span>
                {` ${dateFromToday(
                  unit.subscription?.expirationDate || new Date()
                )} Days `}
              </span>
              to renew it
            </h1>
          )}
        </div>
        <div className="footer align-self-stretch d-flex">
          <Button
            saveData={() => {
              history.push('/plans');
            }}
            name={
              ['clinic'].includes(unit.type)
                ? 'Renew'
                : ['lab', 'image'].includes(unit.type)
                ? 'Recharge'
                : ''
            }
          />
          <Button
            className="danger"
            saveData={() => {
              closeModal();
            }}
            name={'Later'}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ExpirationAlert;
