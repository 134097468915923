import React from 'react';
import { Patient } from '../../types';
import TreatmentView from './TreatmentView/TreatmentView';
import ReferralModal from '../../components/Board/ClinicExam/ReferralComponent/ReferralModal/ReferralModal';
import RadiologyResults from '../Board/RadiologyResults/RadiologyResults';
import LabResults from '../Board/LabResults/LabResults';
import { useSelector } from 'react-redux';
import { rootState } from '../../store/reducers';
import DentalHistoryView from '../Board/ClinicExam/HistoryView/DentalHistoryView';

type Props = {
  currentView: string;
  updateObject: (update: any) => void;
  reservation: any;
  currentEncounter: any;
  currentPatient: Patient | null;
  openReferModal: boolean;
  closeReferModalHandler: () => void;
};

const DentalBoardContent: React.FC<Props> = ({
  currentView,
  updateObject,
  reservation,
  currentEncounter,
  currentPatient,
  openReferModal,
  closeReferModalHandler,
}) => {
  if (!currentPatient) return null;
  return (
    <>
      {/* <ReferralModal
        isOpen={openReferModal}
        data={reservation.pres}
        onModalClose={closeReferModalHandler}
      />
      <div>
      <TreatmentView updateObject={updateObject} data={{ ...reservation }} />
      </div> */}

      {currentView === 'clinicalExam' ? (
        <>
          <ReferralModal
            isOpen={openReferModal}
            data={reservation.pres}
            onModalClose={closeReferModalHandler}
          />
          <div>
            <TreatmentView
              updateObject={updateObject}
              data={{ ...reservation }}
            />
          </div>
        </>
      ) : currentView === 'historyView' ? (
        currentEncounter && (
          <DentalHistoryView
            currentEncounter={{
              ...currentEncounter,
              // allergy: currentPatient?.allergy || [],
              // chronic: currentPatient?.chronic || [],
              // attachments: currentEncounter.attachments || [],
            }}
          />
        )
      ) : currentView === 'radiologyView' ? (
        <RadiologyResults />
      ) : currentView === 'labView' ? (
        <LabResults />
      ) : null}
    </>
  );
};

export default DentalBoardContent;
