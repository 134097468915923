import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import bookingStatus from '../../../assets/constants/bookingStatus';
import { CancelIcon } from '../../../assets/constants/images';
import menuIcon from '../../../assets/img/menu.png';
import LottiePlayer from '../../../components/Animation/Animation';
import BoardContent from '../../../components/Board/BoardConent';
import BoardMenu from '../../../components/Board/BoardMenu';
import BoardPatientInfo from '../../../components/Board/BoardPatientInfo';
import BookingList from '../../../components/Board/ClinicExam/BookingList/BookingList';
import { updateReservation } from '../../../store/actions';
import {
  endBookingAction,
  getClinicBookingsAction,
  getCurrentPatientProfileAction,
  getPatientBookingHistoryAction,
  setCurrentViewAction,
  StartExaminationAction,
  toggleMenuAction,
} from '../../../store/actions/board.actions';
import {
  setCurrentBooking,
  updateBooking,
  updateBookingWithOutMsg,
} from '../../../store/actions/booking.actions';
import { rootState } from '../../../store/reducers';
import './ClinicBoard.scss';

const ClinicBoard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);

  const [startDate] = useState(moment().startOf('day').toDate().getTime());
  const [endDate] = useState(moment().endOf('day').toDate().getTime());
  const [filteredHistory, setFilterHistory] = useState<any>([]);
  const [openRefereModal, setOpenRefereModal] = useState(false);
  const currentEncounter = useSelector(
    (state: rootState) => state.board.currentEncounter
  );
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const currentBooking = useSelector(
    (state: rootState) => state.board.currentBooking
  );
  const currBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );
  const attachments = useSelector(
    (state: rootState) => state.board.attachments
  );
  const menuOpen = useSelector((state: rootState) => state.board.menuOpen);
  const startExam = useSelector((state: rootState) => state.board.startExam);
  const history = useSelector((state: rootState) => state.board.history);
  const referal = useSelector(
    (state: rootState) => state.board.referredEncounters
  );
  const currentView = useSelector(
    (state: rootState) => state.board.currentView
  );
  const bookings = useSelector((state: rootState) => state.board.bookings);
  const isRequestFinished = useSelector(
    (state: rootState) => state.board.isRequestFinished
  );
  const reservation = useSelector(
    (state: rootState) => state.board.reservation
  );

  const setMenuOpened = () => {
    dispatch(toggleMenuAction(!menuOpen));
  };

  const setCurrentView = (view: string) => {
    dispatch(setCurrentViewAction(view));
  };

  const setRefereModal = (open: boolean) => {
    setOpenRefereModal(open);
  };
  const closeReferModalHandler = () => {
    setOpenRefereModal(false);
  };
  const updateObject = (updated: any) => {
    if (currentBooking?.status === 'new') {
      dispatch(
        updateBookingWithOutMsg(
          currentBooking.pk,
          currentBooking.sk,
          'status',
          {
            status: 'active',
          },
          true
        )
      );
    }
    dispatch(
      updateReservation({ ...reservation, ...updated, lastChange: new Date() })
    );
  };

  const onSave = (status: string, partialUpdate: boolean = false) => {
    if ((!currentBooking && !reservation.pk) || !currentUnitDetails) return;
    let removeLocalUrls: { url: string }[] = [];
    if (attachments && attachments.length > 0) {
      removeLocalUrls = attachments.map(({ url }: any) => {
        const splittedUrl = url.split('?')[0];
        return { url: splittedUrl };
      });
    }
    if (currentBooking) {
      const payload = {
        ...reservation,
        attachments: removeLocalUrls,
        unit: currentUnitDetails.pk,
        id: currentBooking.patient.pk,
        patient: {
          name: currentBooking.patient.name,
          lmobile: currentBooking.patient.lmobile,
        },
        b: {
          type: currentBooking.type,
          cost: currentBooking.cost,
          sk: currentBooking.sk,
          date: currentBooking.date,
          linkId: currentBooking.linkId,
        },
        clinic: currentBooking.clinic,
        unitType: currentUnitDetails.type,
        dr: currentBooking.dr,
        status,
        partialUpdate,
      };
      // console.log('payload', payload);
      dispatch(endBookingAction(payload));
    } else if (reservation.pk) {
      const payload = {
        ...reservation,
        attachments: removeLocalUrls,
        unit: currentUnitDetails.pk,
        unitType: currentUnitDetails.type,
        status,
        partialUpdate,
        id: reservation.pk,
      };
      // console.log('payload with reservation', payload);
      dispatch(endBookingAction(payload));
    }
  };

  useOnClickOutside(ref, (e: any) => {
    if (e && e.path) {
      const modal = e.path.find(
        (el: any) => el.className === 'ReactModalPortal'
      );
      if (!modal && startExam) {
        setMenuOpened();
      }
    }
  });

  useEffect(() => {
    if (currentUnitDetails?.pk) {
      dispatch(
        getClinicBookingsAction(currentUnitDetails.pk, startDate, endDate)
      );
    }
    // eslint-disable-next-line
  }, [currentUnitDetails, startDate]);

  useEffect(() => {
    const { lastChange } = reservation;
    if (lastChange && currentPatient) return;
    if (currentBooking && currentUnitDetails) {
      dispatch(
        getCurrentPatientProfileAction(
          currentBooking
            ? currentBooking.patient.pk
            : currentBooking.patient.pk,
          currentUnitDetails.pk
        )
      );
      dispatch(
        getPatientBookingHistoryAction(
          currentBooking
            ? currentBooking.patient.pk
            : currentBooking.patient.pk,
          currentUnitDetails.pk
        )
      );
    }
    const patientInClinic = bookings.some(
      (ele: any) =>
        ele.status ===
        (bookingStatus.active || bookingStatus.hold || bookingStatus.completed)
    );
    if (patientInClinic) {
      dispatch(StartExaminationAction(true));
    }
    // eslint-disable-next-line
  }, [currentBooking, currentUnitDetails]);

  useEffect(() => {
    if (bookings) {
      if (!currentBooking) {
        const activeBookingIndex = bookings.findIndex(
          (booking: any) => booking.status === 'active'
        );
        if (activeBookingIndex >= 0)
          dispatch(setCurrentBooking(bookings[activeBookingIndex]));
        else dispatch(setCurrentBooking(null));
      }
      const patientInClinic = bookings.some(
        (ele: any) => ele.status === bookingStatus.active // ||
        // bookingStatus.hold ||
        // bookingStatus.active)
      );
      if (patientInClinic) {
        dispatch(StartExaminationAction(true));
      }
    }
    // eslint-disable-next-line
  }, [bookings]);

  useEffect(() => {
    setFilterHistory(
      currentBooking
        ? history.filter((enc: any) => enc.sk !== `dr-${currentBooking.sk}`)
        : history.filter((enc: any) => enc.sk !== reservation.sk)
    );
    // eslint-disable-next-line
  }, [history, currentBooking]);

  return (
    <div className="page">
      <div className="left-side">
        <div className="col-top">
          {/*eslint-disable-next-line */}
          <a
            className="menu-btn"
            onClick={() => {
              setMenuOpened();
            }}
          >
            <img src={menuIcon} alt="" />
          </a>
          {currentPatient && (
            <BoardPatientInfo
              currentPatient={currentPatient}
              currentBooking={currentBooking}
              reservation={reservation}
              onSave={onSave}
              onSelect={() => {
                setMenuOpened();
              }}
            />
          )}
        </div>
        <div className="col-bottom">
          {currentPatient && (
            <BoardMenu
              currentView={currentView}
              setCurrentView={setCurrentView}
              filteredHistory={filteredHistory}
              currentEncounter={currentEncounter}
              setRefereModal={setRefereModal}
            />
          )}
        </div>

        {menuOpen && (
          <div className="reservation-list" id="overlay" ref={ref}>
            <div className="reservation-list-header d-flex justify-content-between">
              <span>Reservations</span>
              <button
                type="button"
                className="btn close"
                onClick={() => {
                  setMenuOpened();
                }}
              >
                <CancelIcon />
              </button>
            </div>

            <div className="reservation-list-body">
              {bookings && bookings.length > 0 && isRequestFinished ? (
                <BookingList
                  onSave={onSave}
                  onSelect={() => {
                    setMenuOpened();
                  }}
                />
              ) : isRequestFinished && bookings.length === 0 ? (
                <div className="no-patient d-flex flex-column justify-content-center align-items-center">
                  <p>{t('clinicBoard_page.no-patient')}</p>
                  <p className="msg">{t('clinicBoard_page.no-patient-msg')}</p>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        {((bookings && bookings.length > 0 && startExam) || currentPatient) &&
        isRequestFinished ? (
          <>
            {(currentBooking || reservation.pk) && (
              <BoardContent
                currentView={currentView}
                updateObject={updateObject}
                reservation={reservation}
                currentEncounter={currentEncounter}
                currentPatient={currentPatient}
              />
            )}
          </>
        ) : bookings &&
          bookings.some(
            (ele: any) =>
              ele.status === bookingStatus.active ||
              ele.status === bookingStatus.new ||
              ele.status === bookingStatus.hold
          ) &&
          !startExam &&
          isRequestFinished ? (
          <div className="no-patient d-flex flex-column justify-content-center align-items-center">
            <h4>You have waiting patients on the queue</h4>
            <LottiePlayer />
          </div>
        ) : isRequestFinished ? (
          <div className="no-patient d-flex justify-content-center align-items-center">
            <LottiePlayer />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ClinicBoard;

// workflow flag
