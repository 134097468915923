import React, { useState, useEffect } from 'react';
import './AddEmployees.scss';
import Button from '../../../UI/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import AddSingleEmployee from './AddSingleEmployee';
import { errorToast } from '../../../../utils/toast';
import backArrow from '../../../../assets/img/backArrow.svg';
import {
  mobileRegex,
  specialCharsWithNumbers,
} from '../../../../assets/constants/regexValues';
import roles, {
  UserRoles,
  paymentRoles,
} from '../../../../assets/constants/roles';
import EventTracker from '../../../../utils/analytics';
import AddNewExperience from '../../UnitDetails/AddNewExperience';
import ExpModal from '../../UnitDetails/ExpModal/ExpModal';
import parseArabic from '../../../../utils/parseArabic';
import MapModal from '../../UnitDetails/MapModal/MapModal';
import questionIcon from '../../../../assets/img/question.svg';
import question2Icon from '../../../../assets/img/question2.svg';
import locationIcon2 from '../../../../assets/img/location-3.svg';
import locationIcon from '../../../../assets/img/location.svg';
import mobileIcon from '../../../../assets/img/mobile.svg';
import personalIcon from '../../../../assets/img/personal.svg';
import phoneIcon from '../../../../assets/img/phone.svg';
import Expences from '../../UnitDetails/Expences/Expences';
import { getCurruntDay } from '../../../../assets/constants/currentDay';

const data: any = {
  emp1: {
    name: { firstName: '', lastName: '' },
    mobile: '',
    role: UserRoles.Assistant,
    payment: paymentRoles.Monthly,
    salary: '',
  },
};

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  saveClinicData: () => Promise<void>;
  currentSegment: string;
  initialState: any;
  isSubmitting: boolean;
  unitType: string;
};

const AddEmployeesAndexpenses: React.FC<Props> = ({
  nextStepHandler,
  goBackHandler,
  saveClinicData,
  currentSegment,
  initialState,
  isSubmitting,
  unitType,
}) => {
  const { t } = useTranslation();
  const [openModalExp, setOpenModalExp] = useState(false);
  const [addeddExpenses, setAddeddExpenses] = useState<any[]>([]);
  const [allEmp, setAllEmp] = useState<any>(data);
  const [drTitle, setDrTitle] = useState('');
  const [drSubTitle, setDrSubTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [updatedExpense, setUpdatedExpense] = useState<{}>({});
  const [isUpdatedExpense, setIsUpdatedExpense] = useState(false);
  const [updatedExpenseIndex, setupdatedExpenseIndex] = useState(0);
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [address, setAddress] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [offers, setOffers] = useState<any[]>([]);

  useEffect(() => {
    if (initialState && Object.keys(initialState.employees).length > 0) {
      const { employees } = initialState;
      setAllEmp(employees);
    }
    if (initialState && Object.keys(initialState.expenses).length > 0) {
      const { expenses } = initialState;
      setAddeddExpenses(expenses.addedExpenses);
    }
  }, [initialState]);

  const setInputHandler = (
    name: string,
    keyName: string,
    value: string | null
  ) => {
    const data: any = allEmp;
    if (keyName === 'firstName' || keyName === 'lastName') {
      const nameData = data[name]?.name ? data[name].name : {};
      const firstORLastName = { ...nameData, [keyName]: value };
      data[name] = {
        ...data[name],
        name: { ...firstORLastName },
      };
    } else {
      data[name] = {
        ...data[name],
        [keyName]: value,
      };
    }
    setAllEmp(data);
    nextStepHandler({ type: currentSegment, data: { employees: allEmp } });
  };

  const removeEmployeeHandler = (index: number) => {
    EventTracker.sendEvent('REMOVE_EMPLOYEE', { number: index + 1 });
    const newEmps = [...addEmployeesList];
    newEmps.splice(index, 1);
    const delEmp: any = { ...allEmp };
    delete delEmp[`emp${index + 1}`];
    setAllEmp(delEmp);
    setAddEmployeesList([...newEmps]);
    nextStepHandler({ type: currentSegment, data: { employees: allEmp } });
  };

  const goBack = () => {
    const payload: {
      onlinePrescription: {
        drTitle: string;
        drSubTitle: string;
        phone: any;
        mobile: any;
        location: any;
      };
      offers: any[];
    } = {
      onlinePrescription: {
        drTitle,
        drSubTitle,
        phone,
        mobile,
        location: {},
      },
      offers,
    };
    if (location && location.lat && location.lng) {
      payload.onlinePrescription.location = { ...location };
    }
    payload.onlinePrescription.location = {
      address,
      ...payload.onlinePrescription.location,
    };
    if (goBackHandler)
      goBackHandler({
        type: currentSegment,
        data: {
          employees: allEmp,
          expenses: { addedExpenses: addeddExpenses },
          unitDetails: payload,
        },
      });
  };

  const saveEmpHandler = async () => {
    if (Object.keys(allEmp).length <= 0) {
      errorToast(t('employess_page.fill_choosed'));
      return;
    }
    let isValid: boolean = false;
    let isValidMobile: boolean = false;
    for (const key of Object.keys(allEmp)) {
      const obj = allEmp[key];
      if (!obj.payment) {
        obj.payment = paymentRoles.Monthly;
      }
      if (!obj.role) {
        obj.role = UserRoles.Assistant;
      }
      isValid =
        !!obj.mobile &&
        !!obj.role &&
        !!obj.name.firstName &&
        !!obj.name.lastName &&
        !!obj.salary &&
        !!obj.payment;
      isValidMobile = mobileRegex.test(obj.mobile);
    }
    if (!isValid) {
      errorToast(t('employess_page.emp-errMsg'));
      return;
    }
    if (!isValidMobile) {
      errorToast(t('booking_page.valid-mobile'));
      return;
    }
    saveOnlinePrescriptionHandler();
    await saveClinicData();
  };

  const mapInitialEmployees = () => {
    if (initialState && Object.keys(initialState.employees).length > 0) {
      return Object.values(initialState.employees).map(
        (initEmp: any, index) => {
          return (
            <AddSingleEmployee
              key={`emp${index + 1}`}
              name={`emp${index + 1}`}
              index={index}
              setInputHandler={setInputHandler}
              removeEmployeeHandler={removeEmployeeHandler}
              initEmp={initEmp}
              unitType={unitType}
            />
          );
        }
      );
    } else {
      return [
        <AddSingleEmployee
          key={`emp${1}`}
          name={`emp${1}`}
          index={0}
          setInputHandler={setInputHandler}
          removeEmployeeHandler={removeEmployeeHandler}
          initEmp={null}
          unitType={unitType}
        />,
      ];
    }
  };

  const [addEmployeesList, setAddEmployeesList] = useState<any>(() => {
    const initialEmployees = mapInitialEmployees();
    return initialEmployees;
  });

  const addEmployeeHandler = () => {
    EventTracker.sendEvent('ADD_EMPLOYEE_BUTTON_CLICKED', {
      number: addEmployeesList.length + 1,
    });

    const data = {
      ...allEmp,
      [`emp${addEmployeesList.length + 1}`]: {
        name: { firstName: '', lastName: '' },
        mobile: '',
        role: UserRoles.Assistant,
        payment: paymentRoles.Monthly,
        salary: '',
      },
    };
    setAllEmp(data);

    setAddEmployeesList([
      ...addEmployeesList,
      <AddSingleEmployee
        key={`emp${addEmployeesList.length + 1}`}
        name={`emp${addEmployeesList.length + 1}`}
        index={addEmployeesList.length}
        setInputHandler={setInputHandler}
        removeEmployeeHandler={removeEmployeeHandler}
        initEmp={null}
        unitType={unitType}
      />,
    ]);
  };

  ////////////////////////////////// experinces/////////////

  const openCloseModalExpHandler = () => {
    setIsUpdatedExpense(false);
    setOpenModalExp(!openModalExp);
  };

  const addExpHandler = (name: string, amount: string) => {
    const addedEex = {
      name,
      amount,
      _md: getCurruntDay(),
      _ct: getCurruntDay(),
    };
    let isValid: boolean = false;
    isValid = !!addedEex.name && !!addedEex.amount;
    if (!isValid) {
      errorToast(t('employess_page.emp-errMsg'));
      return;
    }
    setAddeddExpenses([...addeddExpenses, addedEex]);
    nextStepHandler({
      type: currentSegment,
      data: { expenses: { addedExpenses: [...addeddExpenses, addedEex] } },
    });
    openCloseModalExpHandler();
  };

  const editExpHandler = (name: string, amount: string) => {
    const addedEex = {
      name,
      amount,
      _md: getCurruntDay(),
      _ct: getCurruntDay(),
    };
    let isValid: boolean = false;
    isValid = !!addedEex.name && !!addedEex.amount;
    if (!isValid) {
      errorToast(t('employess_page.emp-errMsg'));
      return;
    }
    const allExpenses = [...addeddExpenses];
    allExpenses[updatedExpenseIndex] = addedEex;
    setAddeddExpenses(allExpenses);
    nextStepHandler({
      type: currentSegment,
      data: { expenses: { addedExpenses: allExpenses } },
    });
    openCloseModalExpHandler();
  };

  const removeAddedExpHandler = (index: number) => {
    const exp = [...addeddExpenses];
    exp.splice(index, 1);
    setAddeddExpenses([...exp]);
    nextStepHandler({
      type: currentSegment,
      data: { expenses: { addedExpenses: [...exp] } },
    });
  };

  const editExp = (index: number) => {
    const exp = addeddExpenses[index];
    setupdatedExpenseIndex(index);
    setUpdatedExpense(exp);
    setIsUpdatedExpense(true);
    setOpenModalExp(!openModalExp);
  };
  ////////////////////////////////// onlinePrescription///////////////////

  useEffect(() => {
    const { unitDetails } = initialState;

    setOffers(unitDetails.offers || []);
    if (unitDetails.onlinePrescription.phone)
      setPhone(unitDetails.onlinePrescription.phone || '');
    if (unitDetails.onlinePrescription.mobile)
      setMobile(unitDetails.onlinePrescription.mobile || '');
    if (unitDetails.onlinePrescription.drTitle)
      setDrTitle(unitDetails.onlinePrescription.drTitle || '');
    if (unitDetails.onlinePrescription.drSubTitle)
      setDrSubTitle(unitDetails.onlinePrescription.drSubTitle || '');
    if (
      unitDetails.onlinePrescription.location &&
      unitDetails.onlinePrescription.location.lng &&
      unitDetails.onlinePrescription.location.lat
    )
      setLocation({
        lng: unitDetails.onlinePrescription.location.lng,
        lat: unitDetails.onlinePrescription.location.lat,
      });
    if (
      unitDetails.onlinePrescription.location &&
      unitDetails.onlinePrescription.location.address
    )
      setAddress(unitDetails.onlinePrescription.location.address);
    //  dispatch(setCurrentEmpUnit(unit));
  }, []);
  const saveOnlinePrescriptionHandler = () => {
    const payload: {
      onlinePrescription: {
        drTitle: string;
        drSubTitle: string;
        phone: any;
        mobile: any;
        location: any;
      };
      offers: any[];
    } = {
      onlinePrescription: {
        drTitle,
        drSubTitle,
        phone,
        mobile,
        location: {},
      },
      offers,
    };
    if (location && location.lat && location.lng) {
      payload.onlinePrescription.location = { ...location };
    }
    payload.onlinePrescription.location = {
      address,
      ...payload.onlinePrescription.location,
    };
    EventTracker.sendEvent('SET_ONLINE_PRESCRIPTION', {});
    nextStepHandler({ type: currentSegment, data: { unitDetails: payload } });
  };

  const showHideNotice = () => {
    setShowNotice(!showNotice);
  };

  const changeClinicDetails = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const attrName = e.target.name;
    const attrValue = e.target.value;
    // console.log(attrName, attrValue);
    if (attrName === 'unitPhone') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setPhone(num);
    } else if (attrName === 'unitMobile') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setMobile(num);
    } else if (attrName === 'drTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumbers, '');
      setDrTitle(val);
    } else if (attrName === 'drSubTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumbers, '');
      setDrSubTitle(val);
    }
    saveOnlinePrescriptionHandler();
  };

  const checkLocationHandler = () => {
    // check unit location details
    if (location && location.lat && location.lng) {
      openMapModalHandler();
      // request browser location
    } else if (window.navigator.geolocation) {
      // Geolocation available
      window.navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const { latitude, longitude } = position.coords;
          // console.log('position', position);
          setLocationAndOpenModal(latitude, longitude);
        },
        (err) => {
          // console.error('err', err);
          // errorToast(err.message);
          //   setLocationFromGov();
        }
      );
    }
  };

  const openCloseModalHandler = () => {
    setOpenModal(!openModal);
  };

  const openMapModalHandler = () => {
    openCloseModalHandler();
  };
  const setLocationAndOpenModal = (lat: number, lng: number) => {
    setLocation({ lat, lng });
    openMapModalHandler();
  };

  const changeAddressHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // console.log('e.target.value', e.target.value);
    setAddress(e.target.value);
  };

  return (
    <div className="addEmployeesAndexpenses-container d-flex flex-column">
      {openModalExp && (
        <ExpModal
          isEdit={isUpdatedExpense}
          updatedExpense={updatedExpense}
          isOpen={openModalExp}
          onModalClose={openCloseModalExpHandler}
          addExpHandler={addExpHandler}
          editExpHandler={editExpHandler}
        />
      )}
      <div className="expAndonline-container d-flex p-3 mb-5">
        <div className="onlinePrescription-container d-flex flex-column">
          {openModal && (
            <MapModal
              isOpen={openModal}
              onModalClose={openCloseModalHandler}
              confirmHandler={({ lat, lng, address: newAddress }) => {
                // console.log('[unit details component]', lat, lng, newAddress);
                if (lat && lng) setLocation({ lat, lng });
                if (newAddress) setAddress(newAddress);
                if (!newAddress) setAddress('');
                saveOnlinePrescriptionHandler();
                openCloseModalHandler();
              }}
              location={location}
            />
          )}
          <div className="onlinePrescription-container__main d-flex pl-3 justify-content-between">
            <div className="pres-online">
              <div className="notice d-flex align-items-center ">
                <h5>{t('addClinic_page.onlineDetails')} </h5>

                <img
                  src={showNotice ? questionIcon : question2Icon}
                  alt=""
                  onClick={showHideNotice}
                  onBlur={() => {
                    setShowNotice(false);
                  }}
                  tabIndex={1}
                />
                {showNotice && <p>{t('addClinic_page.notice')}</p>}
              </div>
              <div className="pres-online-data mt-3 d-flex justify-content-between">
                <div className="unit-data d-flex flex-column p-3">
                  {/* first part */}
                  {/* <div> */}
                  <div className="name-title d-flex flex-column">
                    {/* dr title */}
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={personalIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.dr-title')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          className="shared"
                          name="drTitle"
                          type="text"
                          value={drTitle}
                          placeholder={t('addClinic_page.dr-title-holder')}
                          onChange={changeClinicDetails}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    {/* dr sub title */}
                    <div className="mt-3">
                      <div className="lock-icon d-flex align-items-center">
                        <img src={personalIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.dr-subTitle')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <textarea
                          className="shared right"
                          name="drSubTitle"
                          value={drSubTitle}
                          rows={3}
                          placeholder={t('addClinic_page.dr-subtitle-holder')}
                          onChange={changeClinicDetails}
                          maxLength={130}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tel d-flex flex-column mt-4">
                    {/* unit phone */}
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={phoneIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.tel')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          name="unitPhone"
                          type="number"
                          value={phone}
                          placeholder={t('addClinic_page.mobile-holder')}
                          onChange={changeClinicDetails}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    {/* unit mobile */}
                    <div className="mt-3">
                      <div className="lock-icon mobile-icon d-flex align-items-center">
                        <img src={mobileIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.mobile')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          name="unitMobile"
                          type="number"
                          value={mobile}
                          placeholder={t('addClinic_page.mobile-holder')}
                          onChange={changeClinicDetails}
                          maxLength={11}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* second part */}
                <div className="map-container p-3">
                  {location && location.lat && location.lng ? (
                    <>
                      <div
                        className="flex-fill"
                        style={{
                          overflow: 'hidden',
                          height: '17.7rem',
                        }}
                      >
                        <iframe
                          title="map"
                          width="100%"
                          height="400"
                          frameBorder="0"
                          style={{
                            border: 0,
                            borderRadius: '1rem',
                            marginTop: '-100px',
                            marginBottom: '-145px',
                            pointerEvents: 'none',
                          }}
                          src={`https://maps.google.com/maps?q= + ${location.lat} + , + ${location.lng} + &t=&z=15&ie=UTF8&iwloc=&output=embed`}
                        ></iframe>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <div className=" btn-container">
                          <button
                            className="d-flex justify-content-center align-items-center"
                            onClick={checkLocationHandler}
                          >
                            <img src={locationIcon2} alt="" />
                            <span>
                              {location && location.lat && location.lng
                                ? t('addClinic_page.edit-location')
                                : t('addClinic_page.add-location')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className="no-map d-flex flex-column justify-content-center align-items-center"
                      onClick={checkLocationHandler}
                    >
                      <img src={locationIcon} alt="" />
                      <p>{t('addClinic_page.pick')}</p>
                    </div>
                  )}

                  {/* unit address */}
                  <div className="mt-3">
                    <div className="lock-icon d-flex align-items-center">
                      <img src={locationIcon} alt="type name"></img>
                      <h6>{t('addClinic_page.address')}</h6>
                    </div>
                    <div className="passwordInput mt-3">
                      <textarea
                        name="address"
                        className="shared address"
                        value={address}
                        rows={2}
                        placeholder={t('addClinic_page.address-holder')}
                        onChange={changeAddressHandler}
                        maxLength={97}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Expences
          editExp={editExp}
          removeAddedExpHandler={removeAddedExpHandler}
          openCloseModalExpHandler={openCloseModalExpHandler}
          addeddexpenses={addeddExpenses}
        />
      </div>
      <div className="addEmployees-container  d-flex flex-column">
        <div className="addEmployees-container__main">
          {addEmployeesList.map((emp: any) => emp)}
        </div>
        <button className="add-emp link-button" onClick={addEmployeeHandler}>
          {t('employess_page.add')}
        </button>
        <Button
          name={t('employess_page.finish')}
          isSubmitting={isSubmitting}
          saveData={saveEmpHandler}
        />
        <div
          className="back go-back align-self-end"
          onClick={() => {
            goBack();
          }}
        >
          <span>{t('otp_page.back')}</span>
          <img src={backArrow} alt="back" />
        </div>
      </div>
    </div>
  );
};

export default AddEmployeesAndexpenses;
