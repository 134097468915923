import React, { useState, useEffect } from 'react';
import './VitalSigns.scss';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import Button from '../../UI/Form/Button/Button';

import bloodPressurIcon from '../../../assets/img/bloodPressure.svg';
import temperatureIcon from '../../../assets/img/temperature.svg';
import weightIcon from '../../../assets/img/weight.svg';
import heightIcon from '../../../assets/img/height.svg';
import backArrow from '../../../assets/img/backArrow.svg';
import bmiIcon from '../../../assets/img/bmi.svg';
import respiratoryRateIcon from '../../../assets/img/respiratoryRate.svg';
import fastBloodGlucoseIcon from '../../../assets/img/fastBloodGlucose.svg';
import pulseRateIcon from '../../../assets/img/pulseRate.svg';
import { errorToast } from '../../../utils/toast';
import { ClinicData } from '../../../types';
import { SubscriptionStatus } from '../../Subscription';
import EventTracker from '../../../utils/analytics';

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  goBackHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit?: ClinicData | null;
};

const data: any = {
  temperature: false,
  bloodPressure: false,
  weight: false,
  height: false,
  bmi: false,
  respiratoryRatr: false,
  fastBloodGlucose: false,
  pulseRate: false,
};

const VitalSignsData = [
  { label: 'temp', name: 'temperature', icon: temperatureIcon },
  { label: 'blood', name: 'bloodPressure', icon: bloodPressurIcon },
  { label: 'weight', name: 'weight', icon: weightIcon },
  { label: 'height', name: 'height', icon: heightIcon },
  { label: 'bmi', name: 'bmi', icon: bmiIcon },
  { label: 'respiratory', name: 'respiratoryRate', icon: respiratoryRateIcon },
  { label: 'fast', name: 'fastBloodGlucose', icon: fastBloodGlucoseIcon },
  { label: 'pulse', name: 'pulseRate', icon: pulseRateIcon },
];

const VitalSigns: React.FC<Props> = ({
  currentSegment,
  initialState,
  nextStepHandler,
  goBackHandler,
  isSettingPage,
  isSubmitting,
  unit,
}) => {
  const { t } = useTranslation();
  const [dataChanged, setDataChanged] = useState(initialState);

  if (initialState && Object.keys(initialState).length === 0) {
    initialState = data;
  }

  // useEffect(() => {
  //   if(dataChanged.weight && dataChanged.height){
  //     initialState = {
  //       ...dataChanged,
  //       bmi: true,
  //     };
  //     setDataChanged(initialState)
  //   } else{
  //     initialState = {
  //       ...dataChanged,
  //       bmi: false,
  //     };
  //     setDataChanged(initialState)
  //   }
  //   console.log(dataChanged)
  // }, [dataChanged.height,dataChanged.weight]);

  useEffect(() => {
    if (dataChanged.bmi) {
      initialState = {
        ...dataChanged,
        height: true,
        weight: true,
      };
      setDataChanged(initialState);
    }
    // if(dataChanged.weight && dataChanged.height){
    //   initialState = {
    //     ...dataChanged,
    //     bmi: true,
    //   };
    //   setDataChanged(initialState)
    // }
    // console.log(dataChanged)
  }, [dataChanged.bmi, dataChanged.height, dataChanged.weight]);

  const saveVitalSignshandler = () => {
    // console.log('saveVitalSignshandler', initialState);
    // if (Object.keys(dataChanged).length <= 0) {
    //   errorToast(t('employess_page.choose_one'));
    //   return;
    // }
    EventTracker.sendEvent('SET_VITAL_SIGNS_TYPES', {});
    nextStepHandler({ type: currentSegment, data: dataChanged });
  };

  const goBack = () => {
    // if (Object.keys(initialState).length <= 0) {
    //   errorToast(t('employess_page.choose_one'));
    //   return;
    // }
    goBackHandler({ type: currentSegment, data: initialState });
  };

  const handleTofuChange = (e: any) => {
    initialState = {
      ...dataChanged,
      [e.target.name]: e.target.checked,
    };
    setDataChanged(initialState);
  };
  return (
    <div className="vitalSigns-container d-flex flex-column ">
      {isSettingPage ? (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              saveData={saveVitalSignshandler}
              isSubmitting={isSubmitting}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBackHandler}
            />
          </div>
        </div>
      ) : (
        <p>{t('vitalSigns_page.choose')}</p>
      )}
      <div className="vitalSigns-container__main d-flex flex-column align-items-center flex-sm-row flex-wrap">
        {VitalSignsData.map((vital, index) => (
          <div
            key={index}
            className="vitalSigns-container__main-data p-4 m-4 d-flex flex-column align-self-stretch align-items-center"
          >
            <div className="img">
              <img src={vital.icon} alt={vital.name} />
            </div>
            <div className="check d-flex align-items-center  justify-content-between justify-content-center mt-4 w-100">
              <p>{t('vitalSigns_page.' + vital.label)}</p>
              <Toggle
                className="custom-classname"
                checked={dataChanged[vital.name]}
                icons={false}
                name={vital.name}
                onChange={handleTofuChange}
              />
            </div>
          </div>
        ))}
      </div>
      {!isSettingPage && (
        <>
          <Button
            saveData={() => {
              saveVitalSignshandler();
            }}
            name={t('working_days_page.next')}
          />
          <div
            className="back go-back align-self-end"
            onClick={() => {
              goBack();
            }}
          >
            <span>{t('otp_page.back')}</span>
            <img src={backArrow} alt="back" />
          </div>
        </>
      )}
    </div>
  );
};

export default VitalSigns;
