import React from 'react';
import './ConfirmBookingModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../UI/Form/Button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20rem',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  confirmCancelHandler: () => void;
};

const CancelBookingModal: React.FC<Props> = ({
  confirmCancelHandler,
  isOpen,
  onModalClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div className="booking-actions">
        <p>{t('booking_page.confirm-msg')}</p>
        <div className="d-flex justify-content-between">
          <Button
            type="button"
            name={t('booking_page.confirm')}
            btnType="warning"
            saveData={confirmCancelHandler}
          />
          <Button
            type="button"
            name={t('booking_page.close')}
            saveData={onModalClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CancelBookingModal;
