import api from './api';

export const addUnit = async (payload: any) => {
  return await api.send('addUnit', payload);
};

export const getClinicianUnits = async () => {
  return await api.send('getClinicianUnits', {});
};

export const getUnitDetails = async (payload: any) => {
  return await api.send('getUnitDetails', payload);
};

export const getUnitTestsTypes = async (payload: any) => {
  return await api.send('getUnitTestsTypes', payload);
};

export const getUnitEmployees = async (payload: any) => {
  return await api.send('getUnitEmployees', payload);
};
export const getUnitExpenses = async (payload: any) => {
  return await api.send('getUnitExpenses', payload);
};

export const updateUnit = async (payload: any) => {
  return await api.send('updateUnit', payload);
};

export const searchPlaces = async (payload: any) => {
  return await api.send('searchPlaces', payload);
};
export const subscribeUnit = async (payload: any) => {
  return await api.send('subscribeUnit', payload);
};
export const redeemPoints = async (payload: any) => {
  return await api.send('redeemPoints', payload);
};

export const getSubscriptionPlans = async (payload: any) => {
  return await api.send('getSubscriptionPlans', payload);
};

export const getNetworks = async () => {
  return await api.send('getNetworks', {});
};

// Dental labs
export const getPredefinedLabServices = async () => {
  return await api.send('getPredefinedLabServices', {});
};

export const addDentalLab = async (payload: any) => {
  return await api.send('addDentalLab', payload);
};

export const getDentalLabs = async (payload: any) => {
  return await api.send('getDentalLabs', payload);
};

export const updateDentalLabs = async (payload: any) => {
  return await api.send('updateDentalLabs', payload);
};
