import { combineReducers } from 'redux';
import booking from './booking.reducer';
import auth from './auth.reducer';
import board from './board.reducer';
import labBoard from './labBoard.reduce';
import uploader from './uploader.reducer';

const rootReducer = combineReducers({
  booking,
  auth,
  board,
  uploader,
  labBoard,
});

export default rootReducer;

export type rootState = ReturnType<typeof rootReducer>;
