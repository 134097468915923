import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LabPlan } from '../../components/Plans';
import ClinicPlan from '../../components/Plans/ClinicPlan';
import api from '../../services/api';
import { rootState } from '../../store/reducers';
import { ClinicData } from '../../types';
import unitTypes from '../../utils/unitTypes';
declare const FawryPay: any;

const Plans: React.FC = () => {
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    if (currentUnitDetails) loadPlans(currentUnitDetails);
  }, [currentUnitDetails]);
  const loadPlans = async (unit: ClinicData) => {
    try {
      setPlans([]);
      const { data } = await api.getSubscriptionPlans({ type: unit.type });
      // console.log(data);
      setPlans(data.plans ? data.plans : []);
    } catch (error) {
      console.error(error);
    }
  };
  const onSubscribe = async (
    email: string,
    price: string,
    planType: string,
    planName: string
  ) => {
    try {
      const { data } = await api.subscribeUnit({
        id: currentUnitDetails?.pk,
        email,
        price,
        planName,
        planType,
      });
      if (data) {
        const script = document.createElement('script');
        script.src = data.link;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = () => {
          FawryPay.checkout(
            data.chargeRequest,
            data.successPageUrl,
            data.failerPageUrl
          );
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="card-deck">
        {currentUnitDetails?.type === unitTypes.clinic ? (
          <ClinicPlan plans={plans} onSubscribe={onSubscribe} />
        ) : currentUnitDetails &&
          [unitTypes.image, unitTypes.lab].includes(
            currentUnitDetails?.type
          ) ? (
          <LabPlan plans={plans} onSubscribe={onSubscribe} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Plans;
