import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../../../store/reducers';
import { useHistory } from 'react-router-dom';
import routes from '../../../../../assets/constants/routes';
import './OperationComponent.scss';
import ProgressBar from '@ramonak/react-progress-bar';
import DentLabOrderModal from './DentLabOrderModal';
import ChangeBookingConfirmationModal from '../../../../Board/changeBookingConfirmationModal/changeBookingConfirmationModal';
import NoteModal from './NoteModal/NoteModal';
import { isNewDate } from '../../../../../utils/moment';
import { useTranslation } from 'react-i18next';
import { updateReservation } from '../../../../../store/actions/board.actions';

type props = {
  operations: any;
  checkOperationStep: any;
  cancelOperation: any;
  activateOperation: any;
  addNote: (index: number, note: string) => void;
  handleOperationCanalsMeasurements: any;
  isHistory: boolean;
  currentDate?: number;
};
const OperationComponent: React.FC<props> = ({
  operations,
  checkOperationStep,
  cancelOperation,
  activateOperation,
  addNote,
  handleOperationCanalsMeasurements,
  isHistory,
  currentDate = 0,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const { currentBooking } = useSelector((state: rootState) => state.board);
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );

  const [openModalExp, setOpenModalExp] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentNote, setCurrentNote] = useState('');
  const [dropDown, setDropDown] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [operationIndx, setOperationIndx] = useState(-1);

  const wrapperRef = useRef(null);
  function useOutsideClickingListener(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(null);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const canalMeasurementsHandler = (
    opIdx: number,
    e: any,
    idx: number,
    field: string
  ) => {
    handleOperationCanalsMeasurements(opIdx, idx, e.target.value, field);
  };
  useOutsideClickingListener(wrapperRef);
  const calcPercentageOfCompletedSteps = (index: number) => {
    let completedSteps = 0;
    for (const step of operations[index].operationSteps) {
      if (step.stepCompleted) completedSteps = completedSteps + 1;
    }
    return (
      (completedSteps / operations[index].operationSteps.length) *
      100
    ).toFixed(1);
  };

  const operationDropDown = (idx: number) => {
    setDropDown(idx);
  };

  const addOrderObj = (index: number, payload: any) => {
    const {
      lab,
      delivaryTime,
      color,
      notes,
      price,
      serviceName,
      serviceType,
      units,
      vendor,
      attachs,
      status,
      labOrderStatus,
    } = payload;
    const dr = {
      name: currentUnitDetails?.owner.name,
      pk: currentUnitDetails?.owner.pk,
    };

    const patient = {
      name: currentPatient?.name,
      lmobile: currentPatient?.lmobile,
      pk: currentPatient?.pk,
    };

    const orderPayload = {
      lab: { name: lab.name, pk: lab.pk },
      color,
      notes,
      price,
      serviceName,
      serviceType,
      units,
      vendor,
      attachs,
      delivaryTime: +delivaryTime,
      date: new Date().getTime(),
      patient,
      clinic: { name: currentUnitDetails?.name, pk: currentUnitDetails?.pk },
      dr,
      status,
      labOrderStatus,
    };

    operations[index].orderData =
      status === 'updated'
        ? { ...orderPayload, pk: payload.pk, sk: payload.sk }
        : orderPayload;
  };
  const openOrderModelHandler = (index: number) => {
    if (
      !currentUnitDetails?.labs ||
      Object.getOwnPropertyNames(currentUnitDetails?.labs).length === 0
    ) {
      openConfirmModalHandler();
    } else {
      setOpenModal(true);
      setOperationIndx(index);
    }
  };

  const openConfirmModalHandler = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const onConfirmModal = (status: string, partialUpdate: boolean = false) => {
    history.push(routes.SETTINGS, ['complete_labs']);
    // onSave(status, partialUpdate);
    // setTimeout(() => {
    //   doChangeBooking(tempBooking);
    //   openModalHandler();
    // }, 50);
  };

  const onDismissModal = () => {
    // setTempBooking(null);
    // doChangeBooking(tempBooking);
    openConfirmModalHandler();
  };

  const openModalNoteHandler = (index: number, note: string) => {
    setCurrentIndex(index);
    setCurrentNote(note);
    setOpenModalExp(!openModalExp);
  };
  const CloseModalNoteHandler = () => {
    setOpenModalExp(!openModalExp);
  };

  const addNoteOperation = (index: number, note: string) => {
    addNote(index, note);
    CloseModalNoteHandler();
  };

  const selectOperationHistory = (mlog: any) => {
    // this function is used in history mode to return the  latest  state
    let finalLog = mlog[0];
    mlog.forEach((log: any, index: number) => {
      if (isNewDate(currentDate, log.md)) {
        finalLog = mlog[index];
      }
    });
    return finalLog.stepCompleted;
  };

  const selectOperationStatus = (mlog: any) => {
    // this function is used in history mode to return the  latest  state
    let finalLog = mlog[0];
    mlog.forEach((log: any, index: number) => {
      if (isNewDate(currentDate, log.md)) {
        finalLog = mlog[index];
      }
    });
    return finalLog.operationStatus;
  };
  const setOperations = (operations: any) => {
    dispatch(updateReservation({ operations, lastChange: new Date() }));
  };
  return (
    <div className="operations-wrapper">
      {openModalExp && (
        <NoteModal
          isOpen={openModalExp}
          index={currentIndex}
          note={currentNote}
          onModalClose={CloseModalNoteHandler}
          addNoteHandler={addNoteOperation}
        />
      )}
      {operations.map((operation: any, index: number) => {
        const workingTeeth =
          'Teeth: ' +
          operation.workingTeeth.join(',').replace(/,([^,]*)$/, ' &' + '$1');
        if (isHistory) {
          operation.operationStatus = selectOperationStatus(operation.mlog);
        }
        return (
          <div key={index} className="operation-item">
            <div className="operation-header">
              <h1 className="operation-title">
                {operation.category + ': ' + operation.operation}
              </h1>
              <div className="operation-actions">
                <h1
                  style={{
                    color:
                      operation.operationStatus === 'In progress'
                        ? '#00C527'
                        : '#00A3C5',
                  }}
                >
                  {operation.operationStatus}
                </h1>
                <div
                  className="more-icon"
                  onClick={() => operationDropDown(index)}
                >
                  {!isHistory ? <i className="fas fa-ellipsis-h"></i> : null}
                </div>
                <div
                  onClick={() => {
                    !isHistory && openModalNoteHandler(index, operation.note);
                  }}
                  className="action-btn"
                >
                  <i className="fa fa-book"></i>
                </div>
                {operation.labIncluded ? (
                  <>
                    <div className="headerDivider"></div>
                    <div
                      className="action-btn"
                      onClick={() => openOrderModelHandler(index)}
                    >
                      Dent Lab Order
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            {dropDown === index && (
              <div ref={wrapperRef} className="operation-drop-down">
                <div
                  onClick={() => {
                    operation.operationStatus === 'Canceled'
                      ? activateOperation(index)
                      : cancelOperation(index);
                    setDropDown(null);
                  }}
                >
                  {operation.operationStatus === 'Canceled'
                    ? 'Activate'
                    : 'Cancel'}
                </div>
                <div>Discontinue</div>
              </div>
            )}
            <div className="operation-actions">
              <div>
                {' '}
                <h1 style={{ color: '#777777' }}>{workingTeeth}</h1>
                <div className="operation-progress-bar">
                  <ProgressBar
                    completed={calcPercentageOfCompletedSteps(index)}
                    bgColor="#0090FF"
                    width="200px"
                    height="8px"
                    isLabelVisible={false}
                    baseBgColor="white"
                  />
                </div>
                {operation.operationSteps.length > 0 && (
                  <h1 className="progress-bar-title">
                    {calcPercentageOfCompletedSteps(index) + '% Complete'}
                  </h1>
                )}
                {operation.operationSteps.map((s: any, i: number) => {
                  return (
                    <div className="operation-step" key={i}>
                      <label>
                        <input
                          disabled={
                            operation.operationStatus === 'Canceled' ||
                            isHistory === true
                          }
                          type="checkbox"
                          placeholder={s.step}
                          // value={
                          //   isHistory
                          //     ? selectOperationHistory(s.mlog)
                          //     : s.stepCompleted

                          // }
                          // defaultChecked={
                          //   isHistory
                          //     ? selectOperationHistory(s.mlog)
                          //     : s.stepCompleted
                          // }
                          checked={
                            isHistory
                              ? selectOperationHistory(s.mlog)
                              : s.stepCompleted
                          }
                          onChange={() => {
                            checkOperationStep(index, s.step);
                          }}
                        />
                        {s.step}
                      </label>
                      {s.step === 'Try in' && operation.orderData?.lab?.name && (
                        <div className="order-action">
                          <button
                            type="button"
                            className="btn btn-primary approve"
                            disabled={
                              operation.orderData?.labOrderStatus == 'Approve'
                            }
                            onClick={() => {
                              operation.orderData = {
                                ...operation.orderData,
                                labOrderStatus: 'Approve',
                              };
                              setOperationIndx(index);
                              setOperations([...operations]);
                            }}
                          >
                            {t('clinicBoard_page.approve')}
                          </button>
                          <button
                            type="button"
                            className="btn remake"
                            disabled={
                              operation.orderData?.labOrderStatus == 'Approve'
                            }
                            onClick={() => {
                              // onSave('completed', true);
                              operation.orderData = {
                                ...operation.orderData,
                                labOrderStatus: 'Remake',
                              };
                              setOperationIndx(index);
                              setOperations([...operations]);
                            }}
                          >
                            {t('clinicBoard_page.remake')}
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {operation.workingLengthDetermination && (
                <div className="WL-MF-fields">
                  <div className="WL-MF-canals">
                    <h1>Canals:</h1>
                    {operation.canalsMeasurements.canals.map(
                      (canal: string, idx: number) => {
                        return (
                          <input
                            key={idx}
                            placeholder="Name"
                            disabled={
                              operation.operationStatus === 'Canceled' ||
                              isHistory
                            }
                            value={operation.canalsMeasurements.canals[idx]}
                            onChange={(e) =>
                              canalMeasurementsHandler(index, e, idx, 'CANALS')
                            }
                          />
                        );
                      }
                    )}
                  </div>
                  <div className="WL-MF-length">
                    <h1 style={{ marginRight: '15px' }}>Length:</h1>
                    {operation.canalsMeasurements.lengths.map(
                      (length: string, idx: number) => {
                        return (
                          <input
                            key={idx}
                            placeholder=""
                            type="number"
                            value={operation.canalsMeasurements.lengths[idx]}
                            disabled={
                              operation.operationStatus === 'Canceled' ||
                              isHistory
                            }
                            onChange={(e) =>
                              canalMeasurementsHandler(index, e, idx, 'LENGTH')
                            }
                          />
                        );
                      }
                    )}
                  </div>
                  <div className="WL-MF-size">
                    <h1>MF Size:</h1>
                    {operation.canalsMeasurements.MFSize.map(
                      (size: string, idx: number) => {
                        return (
                          <input
                            key={idx}
                            placeholder=""
                            type="number"
                            value={operation.canalsMeasurements.MFSize[idx]}
                            disabled={
                              operation.operationStatus === 'Canceled' ||
                              isHistory
                            }
                            onChange={(e) =>
                              canalMeasurementsHandler(index, e, idx, 'MFSIZE')
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="grad-line"></div>
            <DentLabOrderModal
              isOpen={openModal}
              closeModal={() => setOpenModal(false)}
              operation={{ ...operations[operationIndx] }}
              orderDataObj={addOrderObj}
              ind={operationIndx}
            />
            {openConfirmModal && (
              <ChangeBookingConfirmationModal
                isOpen={openConfirmModal}
                bookingStatus={currentBooking ? currentBooking.status : ''}
                onModalClose={onDismissModal}
                onModalConfirm={onConfirmModal}
                closeModelWithoutAction={openConfirmModalHandler}
                addDentLab={true}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OperationComponent;
