import * as Yup from 'yup';
import { mobileRegex } from '../../assets/constants/regexValues';

const formValidationSchema = (t: any) => {
  return Yup.object().shape({
    mobile: Yup.string()
      .matches(mobileRegex, t('booking_page.valid-mobile'))
      .required(t('booking_page.required'))
      .length(11, t('booking_page.valid-11')),
  });
};

export default formValidationSchema;
