import React, { useEffect, useState } from 'react';
import './Plans.scss';
import { CheckedIcon, SijilBasicIcon } from '../../assets/constants/images';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import formValidationSchema from './ClinicPlanFormValidation';
import CheckBox from '../UI/Form/CheckBox/CheckBox';
import EmailInput from '../UI/Form/EmailInput/EmailInput';
import Button from '../UI/Form/Button/Button';
import { useSelector } from 'react-redux';
import { rootState } from '../../store/reducers';
import TermsModal from '../TermsModal/TermsModal';

const ClinicPlan = ({
  onSubscribe,
  plans,
}: {
  onSubscribe: any;
  plans: any[];
}) => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPlan, setPlan] = useState<any>();
  const [unitPlans, setUnitPlans] = useState<any>([]);
  const [name, setName] = useState('yearly');
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const handleSelectPlan = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
    setUnitPlans(
      plans.filter(
        (p: any) =>
          p.unitType.includes(currentUnitDetails?.type) && p.name === value
      )
    );
  };

  const handleSubmitt = async (email: string) => {
    onSubscribe(
      email,
      selectedPlan.price,
      selectedPlan.type,
      selectedPlan.name
    );
  };

  useEffect(() => {
    if (unitPlans.length > 0) setPlan(unitPlans[0]);
  }, [unitPlans]);

  useEffect(() => {
    setUnitPlans(plans.filter((p: any) => p.name === name));
    // eslint-disable-next-line
  }, [plans]);

  const isBeforeNow = (date: any) => {
    return new Date(date) < new Date(new Date().toDateString());
  };

  const openModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className="plan-form">
      {isOpenModal && (
        <TermsModal isOpen={isOpenModal} onModalClose={openModal} />
      )}
      <div className="plan-form-title">
        {currentUnitDetails &&
        currentUnitDetails.subscription &&
        currentUnitDetails.subscription.expirationDate &&
        isBeforeNow(currentUnitDetails.subscription.expirationDate) ? (
          <h1>Your Trial Subscription Ended</h1>
        ) : null}

        <h3>Choose Plan</h3>
      </div>
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <label
          className={`btn btn-light ${name === 'monthly' ? 'active' : ''}`}
        >
          <input
            type="radio"
            name="name"
            value="monthly"
            onChange={handleSelectPlan}
          />
          Monthly
        </label>
        <label className={`btn btn-light ${name === 'yearly' ? 'active' : ''}`}>
          <input
            type="radio"
            name="name"
            value="yearly"
            onChange={handleSelectPlan}
          />
          Yearly
        </label>
      </div>

      <div className="plan-form-body">
        {unitPlans.map((p: any, i: number) => (
          <PlanCard key={`${currentUnitDetails?.type}-plan-${i}`} p={p} />
        ))}
      </div>
      <div className="plan-form-footer">
        <Formik
          initialValues={{
            email: '',
            terms: false,
          }}
          validationSchema={formValidationSchema(t)}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            // console.log(values);
            handleSubmitt(values.email);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="controller controller-terms">
                <div className="termsInput">
                  <CheckBox
                    name="terms"
                    label={t('subscription.terms')}
                    className="form-check-input"
                    openModal={openModal}
                  />
                </div>
              </div>

              <div className="controller controller-email">
                <div className="emailInput">
                  <EmailInput
                    type={'text'}
                    name={'email'}
                    placeholder={'write your email to receive contract'}
                  />
                </div>
              </div>

              <Button
                type="submit"
                name={t('subscription.subscribe')}
                isSubmitting={isSubmitting}
                className="submit-btn"
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ClinicPlan;

const PlanCard = ({ p }: { p: any }) => {
  return (
    <div className="plan-body">
      {p.note && <div className="plan-note">{p.note}</div>}
      <SijilBasicIcon className="plan-logo" />
      <p className="form-check-label">
        Pay <strong>{p.price} EGP</strong>
        <small> per {p.durationName}</small>
      </p>
      <ul className="plan-data">
        {p.options.map((op: string, i: number) => (
          <li key={`plan-opt-${i}`}>
            <CheckedIcon /> {op}
          </li>
        ))}
      </ul>
    </div>
  );
};
