import React, { useState } from 'react';
import './SelectUnitType.scss';
import { useTranslation } from 'react-i18next';
import clinicIcon from '../../../assets/img/clinic.svg';
import clinicIconActive from '../../../assets/img/hospital.svg';
import labIcon from '../../../assets/img/labIcon.svg';
import labIconActive from '../../../assets/img/labIcon-active.svg';
import imagingCenterIcon from '../../../assets/img/imagingCenterIcon.svg';
import imagingCenterIconActive from '../../../assets/img/imagingCenterIcon-active.svg';
import EventTracker from '../../../utils/analytics';

type Props = {
  selectTypeHandler: (type: string) => void;
};

const unitTypes = {
  clinic: 'Clinic',
  laboratory: 'Laboratory',
  imagingCenter: 'ImagingCenter',
};

const SelectUnitType: React.FC<Props> = ({ selectTypeHandler }) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState('');
  const selectUnitTypeHandler = (value: string) => {
    EventTracker.sendEvent('UNIT_TYPE_CHANGED', { type: value });
    setSelectedType(value);
    selectTypeHandler(value);
  };

  return (
    <div className="selectUnitType-container d-flex flex-column p-5 ">
      <p className="title">{t('select_unit_type_page.choose_account')}</p>
      <div className="selectUnitType-container__main d-flex flex-column align-items-center flex-sm-row justify-content-between">
        <div
          onClick={() => selectUnitTypeHandler(unitTypes.clinic)}
          className={`selectUnitType-container__main-${
            selectedType === unitTypes.clinic ? 'dataActive' : 'data'
          } py-4 px-5 m-4 d-flex flex-column align-self-stretch align-items-center`}
        >
          <div className="img">
            <img
              src={
                selectedType === unitTypes.clinic
                  ? clinicIconActive
                  : clinicIcon
              }
              alt={unitTypes.clinic}
            />
          </div>
          <div className="unit-type mt-4">
            <p>{t('select_unit_type_page.clinic')}</p>
          </div>
        </div>
        <div
          onClick={() => selectUnitTypeHandler(unitTypes.laboratory)}
          className={`selectUnitType-container__main-${
            selectedType === unitTypes.laboratory ? 'dataActive' : 'data'
          } py-4 px-5 m-4 d-flex flex-column align-self-stretch align-items-center`}
        >
          <div className="img">
            <img
              src={
                selectedType === unitTypes.laboratory ? labIconActive : labIcon
              }
              alt={unitTypes.laboratory}
            />
          </div>
          <div className="unit-type mt-4">
            <p>{t('select_unit_type_page.lab')}</p>
          </div>
        </div>
        <div
          onClick={() => selectUnitTypeHandler(unitTypes.imagingCenter)}
          className={`selectUnitType-container__main-${
            selectedType === unitTypes.imagingCenter ? 'dataActive' : 'data'
          } py-4 px-5 m-4 d-flex flex-column align-self-stretch align-items-center`}
        >
          <div className="img">
            <img
              src={
                selectedType === unitTypes.imagingCenter
                  ? imagingCenterIconActive
                  : imagingCenterIcon
              }
              alt={unitTypes.imagingCenter}
            />
          </div>
          <div className="unit-type mt-4">
            <p>{t('select_unit_type_page.imaging_center')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUnitType;
