import React from 'react';
import { Patient } from '../../types';
import ClinicExam from './ClinicExam/ClinicExam';
import HistoryView from './ClinicExam/HistoryView/HistoryView';
import LabResults from './LabResults/LabResults';
import RadiologyResults from './RadiologyResults/RadiologyResults';

type BoardProps = {
  currentView: string;
  updateObject: (updated: any) => void;
  reservation: any;
  currentEncounter: any;
  currentPatient: Patient | null;
  // openRefereModal: boolean;
  // closeReferModalHandler: () => void;
};
const BoardContent: React.FC<BoardProps> = ({
  currentView,
  updateObject,
  reservation,
  currentEncounter,
  currentPatient,
  // openRefereModal,
  // closeReferModalHandler,
}) => {
  if (!currentPatient) return null;
  return (
    <>
      {/* <ReferralModal
        isOpen={openRefereModal}
        data={reservation.pres}
        onModalClose={closeReferModalHandler}
      /> */}
      {currentView === 'clinicalExam' ? (
        <ClinicExam updateObject={updateObject} data={{ ...reservation }} />
      ) : currentView === 'historyView' ? (
        currentEncounter && (
          <HistoryView
            currentEncounter={{
              ...currentEncounter,
              allergy: currentPatient?.allergy || [],
              chronic: currentPatient?.chronic || [],
              attachments: currentEncounter.attachments || [],
            }}
          />
        )
      ) : currentView === 'radiologyView' ? (
        <RadiologyResults />
      ) : currentView === 'labView' ? (
        <LabResults />
      ) : null}
    </>
  );
};

export default BoardContent;
