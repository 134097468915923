export const nationalIdRegex = /^([1-2][0-9][0-9])(([1][0-2])|([0][1-9]))(([0-2][0-9])|([3][0-1]))(01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/;
//    /^(2|3)[0-9][1-9][0-1][1-9][0-3]
// [1-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/;

export const mobileRegex = /^(\+2)?01[0125]{1}\d{8}$/;
// export const mobileRegex = /^((\\+[1-9]{1,4}[ \\-]*)|
// (\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const arabicRegex = /^([\u0621-\u064A ]+$|([\u0621-\u064A ]+[\s]+[\u0621-\u064A ]+$))/;
// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const specialCharsWithNumbers = /\d|[$&+,:;=?@#|'<>.-^*()%!]/g;
