import React from 'react';
import './CheckUser.scss';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import mobileIcon from '../../assets/img/mobile.svg';
import routes from '../../assets/constants/routes';
import Button from '../UI/Form/Button/Button';
import formValidationSchema from './FormValidation';
import MobileNumberInput from '../UI/Form/MobileNum/MobileNumInput';

import backArrow from '../../assets/img/back.svg';
import EventTracker from '../../utils/analytics';

type Props = {
  checkUserHandler: (payload: any) => Promise<any>;
  name?: string;
  isregisterPage?: boolean;
};

const LoginForm: React.FC<Props> = ({
  checkUserHandler,
  name,
  isregisterPage,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="login-page-container d-flex align-items-center justify-content-center flex-column p-3 ">
      <div className="align-self-start">
        <p>{name ? name : t('pages.login')}</p>
      </div>
      <div className=" align-self-stretch d-flex flex-column mt-0 mt-sm-5">
        <div className="login-page-form  align-items-center d-flex flex-column p-5">
          <div className="mobile-icon d-flex align-self-start">
            <img src={mobileIcon} alt="mobile icon" />
            <h6>{t('login_page.mobile')}</h6>
          </div>
          <Formik
            initialValues={{
              mobile: '',
            }}
            validationSchema={formValidationSchema(t)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await checkUserHandler(values);
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="mt-3">
                <div className="mt-3">
                  <MobileNumberInput />
                </div>
                <div className="mt-3">
                  <Button
                    type="submit"
                    name={t('login_page.next')}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
          {!isregisterPage && (
            <div className="new-register text-center mt-5">
              <p>
                {t('login_page.new-user')}{' '}
                {/* <Link to={routes.REGISTER}>
                  <span>{t('login_page.register-now')}</span>
                </Link> */}
              </p>
              <div className="mt-3">
                <Button
                  type="button"
                  name={t('login_page.register-now')}
                  saveData={() => {
                    EventTracker.sendEvent('REGISTER_BUTTON_CLICKED', {});
                    history.push(routes.REGISTER);
                  }}
                  btnType="newRegister"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isregisterPage && (
        <div
          className="back align-self-start mt-2"
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={backArrow} alt="back" />
          <span>{t('otp_page.back')}</span>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
