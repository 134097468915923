import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Search.scss';
// import { useTranslation } from 'react-i18next';
import SearchForm from '../../components/Search/SearchForm';
import DrugList from '../../components/Search/DrugList';
const { REACT_APP_ES_URL } = process.env;

const getQuery = (data: any) => {
  const { text, specialty, doctor, symptoms } = data;

  const payload: any = {
    query: {
      bool: {
        should: [],
      },
    },
    size: 100,
    aggs: {
      attrs: {
        terms: {
          field: 'tradeName.keyword',
          order: {
            'sum_score.value': 'desc',
          },
        },
        aggs: {
          max_score: {
            max: {
              script: {
                lang: 'painless',
                inline: '_score',
              },
            },
          },
          sum_score: {
            sum: {
              script: {
                lang: 'painless',
                inline: '_score',
              },
            },
          },
        },
      },
    },
  };
  if (text) {
    payload.query.bool.must = [];
    const textQuery: any = {
      multi_match: {
        query: text,
        fields: ['tradeName', 'sciName'],
      },
    };

    payload.query.bool.must.push(textQuery);
  }
  if (specialty) {
    payload.query.bool.should.push({
      match: {
        specialty,
      },
    });
  }
  if (doctor) {
    payload.query.bool.should.push({
      match: {
        doctor,
      },
    });
  }
  if (symptoms) {
    payload.query.bool.should.push({
      match: {
        symptoms,
      },
    });
  }
  return payload;
};

// const combineDrugs = (buckets: any[], hits: any[]) => {
//   const drugs = [];
//   for (let index = 0; index < buckets.length; index++) {
//     const bucket = buckets[index];
//     for (let i = 0; i < hits.length; i++) {
//       const hit = hits[i];
//       if (hit._source.tradeName === bucket.key) {
//         drugs.push(hit._source);
//         console.log(i);
//         break;
//       }
//     }
//   }
//   return drugs;
// };
const url = REACT_APP_ES_URL || 'http://18.215.248.72:9200/drugs/';

const Search: React.FC = (props) => {
  // const { t } = useTranslation();
  const [drugs, setDrugs] = useState<any>([]);
  const [selectedDrugs, selectDrug] = useState<any>([]);
  const [symptoms, setSymptom] = useState<any>([]);

  useEffect(() => {
    SearchHandler();
    // eslint-disable-next-line
  }, []);

  const displayDrugs = (data: any) => {
    const { aggregations } = data;
    // const values = combineDrugs(aggregations.attrs.buckets, hits.hits);
    const names = aggregations.attrs.buckets.map((b: any) => b.key);
    return names;
  };
  const SearchHandler = async (payload: any = {}) => {
    const user = localStorage.getItem('user');
    if (user) {
      payload.doctor = JSON.parse(user)._id;
      payload.specialty = JSON.parse(user).specialty;
    }

    setSymptom(payload.symptoms);
    const query = getQuery(payload);
    try {
      // console.log(JSON.stringify(query));

      const { data } = await axios.post(url + '_search', query);
      const values = displayDrugs(data);
      // console.log(values);

      setDrugs(values);
    } catch (error) {
      // console.log(error);
    }
  };
  const onSelectDrug = (drug: any) => {
    selectDrug((selectedDrugss: any[]) => [...selectedDrugss, drug]);
    insert(drug);
  };

  const onRemoveDrug = (drug: any) => {
    selectDrug((selectedDrugss: any[]) =>
      selectedDrugss.filter((d: any) => d !== drug)
    );
  };
  const insert = async (tradeName: any) => {
    const payload = {
      tradeName,
      specialty: '',
      doctor: '',
      symptoms,
      selectedDrugs,
    };
    const user = localStorage.getItem('user');
    if (user) {
      payload.doctor = JSON.parse(user)._id;
      payload.specialty = JSON.parse(user).specialty;
    }
    // console.log(payload);
    try {
      await axios.post(url + 'drug', payload);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {/* <SideBar /> */}
      <div className="search_page">
        <div className="container">
          <SearchForm search={SearchHandler} />
          <DrugList
            drugs={drugs}
            selectedDrugs={selectedDrugs}
            selectDrug={onSelectDrug}
            removeDrug={onRemoveDrug}
          />
        </div>
      </div>
    </>
  );
};

export default Search;
