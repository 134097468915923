import React, { useState, useReducer, useEffect } from 'react';
import './AddLab.scss';
import { useTranslation } from 'react-i18next';

import WorkingDays from '../../components/AddUnit/WorkingDays/WorkingDays';
import TestsTypes from '../../components/AddUnit/TestsTypes/TestsTypes';
import Employees from '../../components/AddUnit/Employees/Employees';

import workingDaysIcon from '../../assets/img/workingDays.svg';
import workingDaysActiveIcon from '../../assets/img/workingDaysActive.svg';
import UnitName from '../../components/AddUnit/UnitName/UnitName';
import employeesIcon from '../../assets/img/employees.svg';
import employeesActiveIcon from '../../assets/img/employeesActive.svg';
import labIcon from '../../assets/img/labIcon.svg';
import labIconActive from '../../assets/img/labIcon-active.svg';
import apiService from '../../services/api';
import { useHistory } from 'react-router-dom';
import routes from '../../assets/constants/routes';
import unitTypes from '../../utils/unitTypes';
import { useDispatch } from 'react-redux';
import { errorToast } from '../../utils/toast';
import { UserRoles } from '../../assets/constants/roles';
import {
  getClinicUnitsWithUpdateCurrentUnit,
  resetCurrentUnit,
  setCurrentBooking,
} from '../../store/actions/booking.actions';
import EventTracker from '../../utils/analytics';
import OnlinePrescription from '../../components/AddUnit/OnlinePrescription/OnlinePrescription';

type Action = {
  type: string;
  payload: any;
};

const segmentsData = {
  workingDays: 'workingDays',
  testsTypes: 'testsTypes',
  employees: 'employees',
  labName: 'labName',
  onlinePrescription: 'onlinePrescription',
};

const initialState = {
  [segmentsData.workingDays]: {},
  [segmentsData.testsTypes]: {},
  [segmentsData.employees]: {},
  [segmentsData.onlinePrescription]: {},
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case segmentsData.labName:
      return { ...state, ...action.payload };
    case segmentsData.workingDays:
      return { ...state, workingDays: action.payload };
    case segmentsData.testsTypes:
      return { ...state, testsTypes: action.payload };
    case segmentsData.onlinePrescription:
      return { ...state, onlinePrescription: action.payload };
    case segmentsData.employees:
      return { ...state, employees: action.payload };
    default:
      return state;
  }
};

const AddLab: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const reduxDiapatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedSegment, setSelectedSegment] = useState(
    segmentsData.workingDays
  );

  useEffect(() => {
    getTestTypes();
  }, []);

  const getTestTypes = async () => {
    const typs = await apiService.getUnitTestsTypes({ type: 'test' });
    dispatch({
      type: segmentsData.testsTypes,
      payload: typs.data.testsTypesData,
    });
  };

  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };
  const [isClinicNamePage, setIsClinicNamePage] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.labName) {
      dispatch({ type: segmentsData.labName, payload: payload.data });
      setIsClinicNamePage(false);
    } else if (payload.type === segmentsData.workingDays) {
      dispatch({ type: segmentsData.workingDays, payload: payload.data });
      changeSegmentHandler(segmentsData.onlinePrescription);
    } else if (payload.type === segmentsData.onlinePrescription) {
      dispatch({
        type: segmentsData.onlinePrescription,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.employees);
    } else if (payload.type === segmentsData.testsTypes) {
      dispatch({ type: segmentsData.testsTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.employees);
    } else {
      dispatch({ type: segmentsData.employees, payload: payload.data });
    }
  };

  const goBackHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.employees) {
      dispatch({ type: segmentsData.employees, payload: payload.data });
      changeSegmentHandler(segmentsData.onlinePrescription);
    } else if (payload.type === segmentsData.onlinePrescription) {
      dispatch({
        type: segmentsData.onlinePrescription,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.workingDays);
    } else if (payload.type === segmentsData.testsTypes) {
      dispatch({ type: segmentsData.testsTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.workingDays);
    }
  };

  const checkEmpsHandler = async (emps: any[]) => {
    try {
      let isValidEmp = true;
      for (const emp of emps) {
        const data = await apiService.checkUser({ mobile: emp.mobile });
        const empData = data.data;
        if (empData.exist && empData.user.role === UserRoles.Physician) {
          errorToast(
            t('addClinic_page.emp-errMsg', {
              mobile: emp.mobile,
              unitType: unitTypes.lab,
            })
          );
          isValidEmp = false;
          setIsSubmitting(false);
          return isValidEmp;
        }
      }
      return isValidEmp;
    } catch (error) {
      console.error('error[checkEmpsHandler]', error);
      return false;
    }
  };

  const saveLabData = async () => {
    try {
      setIsSubmitting(true);
      const sentState = { ...state };
      delete sentState.onlinePrescription;
      const payload = {
        ...sentState,
        ...state.onlinePrescription,
        type: unitTypes.lab,
      };
      // console.log('payload', payload);
      const clinicEmp = Object.values(payload.employees);
      const isValidEmps = await checkEmpsHandler(clinicEmp);
      if (isValidEmps) {
        const { data } = await apiService.addUnit(payload);
        const { unit } = data;
        if (unit) {
          EventTracker.sendEvent('LEAD', {
            content_category: unit.type,
            content_name: unit.name,
          });
          EventTracker.sendEvent('SUBMIT_APPLICATION', {});
          EventTracker.sendEvent('START_TRIAL', { unit: unit.pk });
          reduxDiapatch(getClinicUnitsWithUpdateCurrentUnit(unit, history));
        }
        setIsSubmitting(false);
        history.push(routes.BOARD);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error('error[saveLabData]', error);
    }
  };

  useEffect(() => {
    reduxDiapatch(setCurrentBooking(null));
    reduxDiapatch(resetCurrentUnit());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="addLab-page d-flex flex-column">
      {isClinicNamePage ? (
        <UnitName
          nextStepHandler={nextStepHandler}
          currentSegment={segmentsData.labName}
        />
      ) : (
        <>
          <div className="segment mt-3">
            <ul>
              <li
                className={`base token ${
                  selectedSegment === segmentsData.workingDays ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.workingDays
                      ? workingDaysActiveIcon
                      : workingDaysIcon
                  }
                  alt="mobile"
                />
                {t('addLab_page.workingDays')}
              </li>
              {/* <li
                className={`base ${
                  selectedSegment === segmentsData.testsTypes ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.testsTypes
                      ? tetsIconActive
                      : tetsIcon
                  }
                  alt="personal"
                />
                {t('addLab_page.testsTypes')}
              </li> */}
              <li
                className={`base ${
                  selectedSegment === segmentsData.onlinePrescription
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.onlinePrescription
                      ? labIconActive
                      : labIcon
                  }
                  alt="check"
                />
                {t('addLab_page.unitDetails')}
              </li>
              <li
                className={`base ${
                  selectedSegment === segmentsData.employees ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.employees
                      ? employeesActiveIcon
                      : employeesIcon
                  }
                  alt="check"
                />
                {t('addLab_page.employees')}
              </li>
            </ul>
          </div>
          <div className="addLab-page__main d-flex flex-column-reverse flex-sm-row mt-3 p-3">
            {selectedSegment === segmentsData.workingDays ? (
              <WorkingDays
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.workingDays}
                initialState={state[segmentsData.workingDays]}
              />
            ) : selectedSegment === segmentsData.testsTypes ? (
              <TestsTypes
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.testsTypes}
                initialState={state[segmentsData.testsTypes]}
              />
            ) : selectedSegment === segmentsData.onlinePrescription ? (
              <OnlinePrescription
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.onlinePrescription}
                goBackHandler={goBackHandler}
                initialState={state[segmentsData.onlinePrescription]}
              />
            ) : (
              <Employees
                isSubmitting={isSubmitting}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.employees}
                saveClinicData={saveLabData}
                unitType={unitTypes.lab}
                initialState={state[segmentsData.employees]}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddLab;
