import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckedIcon, SijilBasicIcon } from '../../assets/constants/images';
import { rootState } from '../../store/reducers';
import TermsModal from '../TermsModal/TermsModal';
import Button from '../UI/Form/Button/Button';
import CheckBox from '../UI/Form/CheckBox/CheckBox';
import EmailInput from '../UI/Form/EmailInput/EmailInput';
import Input from '../UI/Form/Input/Input';
import formValidationSchema from './LabPlanFormValidation';
import './Plans.scss';
const LabPlan = ({
  onSubscribe,
  plans,
}: {
  onSubscribe: any;
  plans: any[];
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPlan, setPlan] = useState<any>();
  const [unitPlans, setUnitPlans] = useState<any>([]);
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const handleSubmitt = async (email: string, price: any) => {
    // console.log(selectedPlan);
    onSubscribe(email, price, selectedPlan.type, selectedPlan.name);
  };

  useEffect(() => {
    if (unitPlans.length > 0) setPlan(unitPlans[0]);
  }, [unitPlans]);

  useEffect(() => {
    setUnitPlans(plans);
  }, [plans]);
  const isBeforeNow = (date: any) => {
    return new Date(date) < new Date(new Date().toDateString());
  };

  const openModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className="plan-form">
      {isOpenModal && (
        <TermsModal isOpen={isOpenModal} onModalClose={openModal} />
      )}
      <div className="plan-form-title">
        {currentUnitDetails?.subscription?.expirationDate &&
        isBeforeNow(currentUnitDetails.subscription.expirationDate) ? (
          <h1>Your Trial Subscription Ended</h1>
        ) : null}
      </div>

      <div className="plan-form-body">
        {unitPlans.map((p: any, i: number) => (
          <PlanCard key={`${currentUnitDetails?.type}-plan-${i}`} p={p} />
        ))}
      </div>
      <div className="plan-form-footer">
        <Formik
          initialValues={{
            price: '',
            email: '',
            terms: false,
          }}
          validationSchema={formValidationSchema(t)}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            // console.log(values);
            handleSubmitt(values.email, values.price);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <h1 className="footer-header">
                Recharge Your Balance with Minimum <strong>100 EGP</strong>
              </h1>
              <h3 className="footer-sub-header">Enter Recharge Amount</h3>
              <div className="controller controller-price">
                <div className="passwordInput">
                  <Input className="price-input" type={'number'} name="price" />
                </div>
              </div>

              <div className="controller controller-terms">
                <div className="passwordInput">
                  <CheckBox
                    name="terms"
                    label={t('subscription.terms')}
                    className="form-check-input"
                    openModal={openModal}
                  />
                </div>
              </div>

              <div className="controller controller-email">
                <div className="passwordInput">
                  <EmailInput
                    type={'text'}
                    name={'email'}
                    placeholder={'write your email to receive contract'}
                  />
                </div>
              </div>

              <Button
                type="submit"
                name={t('subscription.subscribe')}
                isSubmitting={isSubmitting}
                className="submit-btn"
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default LabPlan;

function PlanCard({ p }: { p: any }) {
  return (
    <div className="plan-body">
      <SijilBasicIcon className="plan-logo" />
      <p className="form-check-label"> Pay As You go </p>

      <ul className="plan-data">
        {p.options.map((op: string, i: number) => (
          <li key={`plan-opt-${i}`}>
            <CheckedIcon /> {op}
          </li>
        ))}
      </ul>
    </div>
  );
}
