import React, { useEffect, useReducer, useState } from 'react';
import './UploadManagerProgress.scss';

import uploadIcon from '../../../../../assets/img/upload.svg';
import arrowIcon from '../../../../../assets/img/arrow-down.svg';

import ImageUploader from './ImageUploader';
import {
  ADD_ATTACHMENTS_FILE,
  // UPDATE_RESERVATION,
} from '../../../../../store/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../../../store/reducers';
import { isAttachmentUploading } from '../../../../../store/actions';

type Props = {
  files: File[];
  bookingId: string | null;
  imgSource?: string;
  addAttachs?: (file: any) => void;
};

const UUID = () => Math.random().toString(36).slice(2);

const getNumberOfUploaded = (files: any[]) => {
  return files.filter((f: any) => f.success).length;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_FILE':
      return [...state, action.payload];
    case 'DELETE_FILE':
      const allImages = [...state];
      allImages.splice(action.payload, 1);
      return [...allImages];
    case 'UPDATE_FILE':
      state[action.payload.index] = {
        ...state[action.payload.index],
        ...action.payload.file,
      };
      return [...state];
    case 'RESET':
      return [];
    case 'ADD_ALLFILES':
      return [...action.payload];
    default:
      return state;
  }
};

const UploadManagerProgress: React.FC<Props> = ({
  files,
  bookingId,
  imgSource,
  addAttachs,
}) => {
  const {
    attachments,
    isAttachmentsReset,
    deletedAttachments,
    isAttachmentsUploading,
  } = useSelector((state: rootState) => state.board);

  const [opened, setMenuOpen] = useState(false);
  const reduxDispatch = useDispatch();

  const [allFiles, dispatch] = useReducer(reducer, []);
  useEffect(() => {
    // console.log('files', files);
    if (files.length === 0 || !bookingId) return;
    for (const file of files) {
      dispatch({
        type: 'ADD_FILE',
        payload: {
          id: UUID(),
          success: false,
          url: '',
          localUrl: URL.createObjectURL(file),
          file,
          bId: bookingId,
        },
      });
    }
    reduxDispatch(isAttachmentUploading(true));
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    // console.log('isAttachmentsReset', isAttachmentsReset);
    if (isAttachmentsReset) {
      dispatch({
        type: 'RESET',
      });
    }
  }, [isAttachmentsReset, files]);

  useEffect(() => {
    // console.log('deletedAttachments', deletedAttachments);
    if (deletedAttachments && deletedAttachments.id) {
      const attIdex = allFiles.findIndex(
        (ele: any) => ele.id === deletedAttachments.id
      );
      if (attIdex >= 0) {
        dispatch({
          type: 'DELETE_FILE',
          payload: attIdex,
        });
      }
    }
    // eslint-disable-next-line
  }, [deletedAttachments]);

  useEffect(() => {
    if (allFiles.length === 0) {
      if (attachments.length > 0) {
        dispatch({
          type: 'ADD_ALLFILES',
          payload: attachments,
        });
      }
      return;
    } else if (allFiles.length < attachments.length) {
      const remainFiles = attachments.filter(
        (item: any) => !allFiles.includes(item)
      );
      for (const file of remainFiles) {
        dispatch({
          type: 'ADD_FILE',
          payload: file,
        });
      }
      return;
    }
    const successFiles = allFiles.filter((f: any) => f.success || f.url != '')
      .length;
    if (allFiles.length === successFiles && isAttachmentsUploading) {
      // console.log('successFiles', successFiles);
      reduxDispatch(isAttachmentUploading(false));
    }
    // eslint-disable-next-line
  }, [allFiles, attachments, files]);

  const sendUrl = (url: string, id: number) => {
    const attIdex = allFiles.findIndex((ele: any) => ele.id === id);
    if (attIdex >= 0) {
      const file = { ...allFiles[attIdex], success: true, url };
      // console.log('file', file);
      if (imgSource === 'dentLabOrder') {
        if (addAttachs) {
          addAttachs(file);
        }
      } else {
        reduxDispatch({
          type: ADD_ATTACHMENTS_FILE,
          payload: file,
        });
      }
      dispatch({
        type: 'UPDATE_FILE',
        payload: {
          index: attIdex,
          file,
        },
      });
    }
  };

  return (
    <div className={`attachments-upload-container`}>
      {!imgSource ? (
        <div
          className="upload-item d-flex justify-content-between align-items-center "
          onClick={() => {
            setMenuOpen(!opened);
          }}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex align-items-center">
                <img className="upload" src={uploadIcon} alt="avatarIcon" />
                <p>{`${
                  allFiles.length > 0 && isAttachmentsUploading == true
                    ? files.length
                    : 0
                } Image uploading`}</p>
              </div>
              <div className="progress" style={{ height: '2px' }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `
                  ${
                    allFiles.length > 0
                      ? (getNumberOfUploaded(allFiles) / allFiles.length) * 100
                      : 0
                  }%`,
                    // width: '10%',
                  }}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          </div>
          <div className="mx-2">
            <img src={arrowIcon} alt="" />
          </div>
        </div>
      ) : (
        ''
      )}
      <div
        className={`uploader-container ${
          opened && allFiles.length > 0 ? 'open' : ''
        }`}
      >
        {allFiles.map((file: any, idx: number) => (
          <ImageUploader
            key={idx}
            file={file}
            index={file.id}
            sendUrl={sendUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default UploadManagerProgress;
