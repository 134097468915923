import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import printIcon from '../../../../assets/img/Mask Group 360.svg';
import { setActiveSectionAction } from '../../../../store/actions/board.actions';
import { rootState } from '../../../../store/reducers';
import './SectionHeaderComponent.scss';
type Props = {
  column: any;
  title: any;
  hasButton?: boolean;
  canPrint?: boolean;
  handleAdd?: () => void;
  handlePrint?: () => void;
  onTextChange?: (value: string) => void;
  onDone?: (value: string) => void;
  reset?: any;
};
const SectionHeaderComponent = (props: Props) => {
  const {
    column,
    title,
    hasButton,
    onDone,
    onTextChange,
    canPrint,
    handlePrint,
    reset,
    handleAdd,
  } = props;

  const [text, setText] = useState<string>('');
  const dispatch = useDispatch();
  const activeSection = useSelector(
    (state: rootState) => state.board.activeSection
  );

  useEffect(() => {
    setText('');
  }, [reset]);
  useEffect(() => {
    const debouncer = setTimeout(() => {
      if (onTextChange) onTextChange(text);
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
    // eslint-disable-next-line
  }, [text]);

  return (
    <div className="section-header">
      <div className="d-flex justify-content-between col-header">
        <div className="p-2 title align-self-center ">
          {title}
          {title != '' ? ':' : ''}
        </div>
        {title === activeSection.section ? (
          <>
            <div className="p-2 flex-fill ">
              <input
                autoFocus
                className="form-control align-self-center"
                name="text"
                autoComplete="off"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
            <div className="p-2 add">
              <button
                className="btn btn-primary btn-lg align-self-center"
                onClick={() => {
                  setTimeout(() => {
                    setText('');
                    dispatch(setActiveSectionAction(-1, ''));
                    if (onDone) onDone('');
                  }, 100);
                }}
              >
                Done
              </button>
            </div>
          </>
        ) : (
          <>
            {hasButton && (
              <div className="p-2 add">
                {canPrint && (
                  <button
                    className="btn btn-primary btn-lg align-self-center "
                    onClick={handlePrint}
                  >
                    <img src={printIcon} alt="" />
                  </button>
                )}
                <button
                  className="btn btn-primary btn-lg  align-self-center"
                  onClick={() => {
                    if (handleAdd) {
                      dispatch(setActiveSectionAction(column, title));
                      handleAdd();
                    } else {
                      dispatch(setActiveSectionAction(column, title));
                    }
                  }}
                >
                  +Add
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SectionHeaderComponent;
