import React, { useState, useEffect } from 'react';
import './CostModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../UI/Form/Button/Button';
import backIcon from '../../../../assets/img/close-icon.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50rem',
    minHeight: '22rem',
  },
};

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  handlerCurrentPaid: (amount: string) => void;
};

const CostModel: React.FC<Props> = ({
  isOpen,
  onModalClose,
  handlerCurrentPaid,
}) => {
  const [priceValue, setPriceValue] = useState('');

  const { t, i18n } = useTranslation();

  const cancelHandler = () => {
    onModalClose();
  };

  const setInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'cost') {
      setPriceValue(value);
    }
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div dir={i18n.dir()} className="costModel-container d-flex flex-column">
        <div className="title d-flex justify-content-between">
          <div>
            <h5>{t('booking_page.cost')}</h5>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={cancelHandler}>
            <img src={backIcon} style={{ height: '15px' }} alt="" />
          </div>
        </div>
        <div className="input d-flex mt-5 justify-content-center">
          <div className="passwordInput">
            <input
              autoFocus
              name="cost"
              type="number"
              value={priceValue}
              autoComplete="off"
              placeholder={t('setting_page.price')}
              onChange={setInputHandler}
            />
          </div>
        </div>
        <div className="confirm mt-5 align-self-stretch d-flex justify-content-center">
          <Button
            saveData={() => {
              handlerCurrentPaid(priceValue);
            }}
            name={t('addClinic_page.done')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CostModel;
