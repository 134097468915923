import React from 'react';
import './PrescriptionComponent.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { printPrescription } from '../../../../services/print';
import { updateBooking, updateReservation } from '../../../../store/actions';
import { rootState } from '../../../../store/reducers';
import SectionHeaderComponent from '../SectionHeaderComponent/SectionHeaderComponent';
import PrescriptionModal from './PrescriptionModal/PrescriptionModal';
import { setActiveSectionAction } from '../../../../store/actions/board.actions';

type Props = {
  column: number;
  data: any;
  isHistory: boolean;
};

const DrugComponent = ({ drug }: any) => {
  return (
    <tr>
      <td>{drug.tradeName}</td>
      <td>{`${drug.quantity} ${drug.unit}`}</td>
      <td>{`${drug.every}`}</td>
      <td>{`For ${drug.duration} ${drug.durationExt}`}</td>
    </tr>
  );
};
const PrescriptionComponent = (props: Props) => {
  const dispatch = useDispatch();
  const [title] = useState<string>('Prescription');
  const { column, data, isHistory } = props;
  const [modalIsOpen, setIsOpen] = useState(false);

  const reservation = useSelector(
    (state: rootState) => state.board.reservation
  );
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );
  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );
  const updateObject = (updated: any) => {
    if (currentBooking?.status === 'new') {
      dispatch(
        updateBooking(currentBooking.pk, currentBooking.sk, 'status', {
          status: 'active',
        })
      );
    }

    dispatch(
      updateReservation({ ...reservation, ...updated, lastChange: new Date() })
    );
  };
  const handlePrint = () => {
    printPrescription(currentPatient, data);
  };
  const handleAdd = () => {
    setIsOpen(!modalIsOpen);
  };
  return (
    <div className="component prescription-component">
      <SectionHeaderComponent
        title={title}
        column={column}
        hasButton={!isHistory ? true : false}
        canPrint={!isHistory || data.length > 0}
        handlePrint={handlePrint}
        handleAdd={handleAdd}
      />
      <div className="col-content">
        <div className="result-container">
          <table className="table drug">
            <tbody>
              {data.map((element: any, ind: number) => {
                return (
                  <DrugComponent
                    drug={element}
                    key={`presc-${ind}-${element.code}`}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <PrescriptionModal
        isOpen={modalIsOpen}
        data={reservation.pres}
        symp={reservation.symp}
        onRequestClose={(pres: any = []) => {
          setIsOpen(false);
          updateObject({ pres });
          dispatch(setActiveSectionAction(-1, ''));
        }}
      />
    </div>
  );
};

export default PrescriptionComponent;
