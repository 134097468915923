import React, { useEffect, useState } from 'react';
import './ComplainComponent.scss';
import { updateReservation } from '../../../../../store/actions/board.actions';
import { rootState } from '../../../../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';

type props = {
  startTreatmentPlan: () => void;
};
const ComplainComponent: React.FC<props> = ({ startTreatmentPlan }) => {
  const [cText, setCText] = useState('');
  const [dText, setDText] = useState('');
  const reservation = useSelector(
    (state: rootState) => state.board.reservation
  );
  useEffect(() => {
    setCText(reservation?.complaintText);
    setDText(reservation?.diagnosisText);
  }, [reservation]);

  useEffect(() => {
    const timeOutId = setTimeout(() => complaintHandler(cText), 300);
    return () => clearTimeout(timeOutId);
  }, [cText]);

  useEffect(() => {
    const timeOutId = setTimeout(() => diagnosisHandler(dText), 300);
    return () => clearTimeout(timeOutId);
  }, [dText]);

  const dispatch = useDispatch();
  const complaintHandler = (text: string) => {
    if (reservation.complaintText === text) return;
    dispatch(
      updateReservation({ complaintText: text, lastChange: new Date() })
    );
  };

  const diagnosisHandler = (text: string) => {
    if (reservation.diagnosisText === text) return;
    dispatch(
      updateReservation({ diagnosisText: text, lastChange: new Date() })
    );
  };
  return (
    <div className="complain-wrapper d-flex flex-column">
      <div className="complain-section section d-flex flex-column">
        Complaint:
        <textarea
          className=""
          value={cText}
          onChange={(e) => {
            setCText(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="Diag-section section d-flex flex-column ">
        Diagnosis:
        <textarea
          className=""
          value={dText}
          onChange={(e) => {
            setDText(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary btn-lg"
          onClick={() => startTreatmentPlan()}
        >
          Start a Treatment plan
        </button>
      </div>
    </div>
  );
};

export default ComplainComponent;
