import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import routes from '../../assets/constants/routes';
import NotAuthorized from '../../components/NotAuthorized/NotAuthorized';
import { rootState } from '../../store/reducers';
interface PrivateRouteProps {
  token: string | null;
  path: string;
  hasUnit: boolean;
  roles: string[];
  component: any;
}
const PrivateRoute = (props: PrivateRouteProps) => {
  const { token, path, hasUnit, roles, component: Component } = props;
  const { currentEmpUnit } = useSelector((state: rootState) => state.booking);
  const history = useHistory();
  const [hasAccess, setHasAccess] = useState(true);
  useEffect(() => {
    if (!token) {
      history.push(routes.CHECK_USER);
    } else if (hasUnit && currentEmpUnit) {
      if (!roles.includes(currentEmpUnit.role)) setHasAccess(false);
      else setHasAccess(true); // history.push(routes.CHECK_USER);
    }
    // eslint-disable-next-line
  }, [currentEmpUnit]);

  return (
    <Route
      path={path}
      exact
      render={() => (hasAccess ? <Component /> : <NotAuthorized />)}
    />
  );
};
export default PrivateRoute;
