import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Button from '../../../UI/Form/Button/Button';
import './SwitchOrdersModal.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: '40rem',
  },
};

type Props = {
  isOpen: boolean;
  dr: string;
  onModalClose: () => void;
  onModalConfirm: (dr: string) => void;
};

const SwitchOrdersModal: React.FC<Props> = ({
  onModalConfirm,
  isOpen,
  dr,
  onModalClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isOpen}
      contentLabel="Confirmation"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onModalClose}
    >
      <div className="booking-actions">
        <p>{t('addBooking_page.switchBookingConfirmation')}</p>
        <div className="d-flex justify-content-between">
          <div className="modal-btn-group">
            <Button
              type="button"
              name={t('addBooking_page.continue')}
              saveData={() => {
                onModalConfirm(dr);
              }}
            />
          </div>
          <div className="modal-btn-group">
            <Button
              type="button"
              btnType="warning"
              name={t('addBooking_page.dismiss')}
              saveData={onModalClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchOrdersModal;
