import api from './api';
// import { Patient } from '../../types';

export const addPatient = async (payload: any): Promise<any> => {
  return await api.send('addPatient', payload);
};

export const findPatientBy = async (payload: any): Promise<any> => {
  return await api.send('searchPatient', payload);
};

export const findPatientWithUnitBookingBy = async (
  payload: any
): Promise<any> => {
  return await api.send('searchPatientWithUnitBooking', payload);
};

export const findPatientById = async (payload: any): Promise<any> => {
  return await api.send('findPatientById', payload);
};

export const updatePatient = async (payload: any): Promise<any> => {
  return await api.send('updatePatient', payload);
};

export const getPatientOrders = async (payload: any) => {
  return await api.send('getPatientOrders', payload);
};

export const addPatientOrders = async (payload: any) => {
  return await api.send('addPatientOrders', payload);
};

export const endPatientBooking = async (payload: any) => {
  return await api.send('endPatientBooking', payload);
};

export const getPatientLabEncounters = async (payload: any) => {
  return await api.send('getPatientLabEncounters', payload);
};

export const getPatientRadEncounters = async (payload: any) => {
  return await api.send('getPatientRadEncounters', payload);
};

export const addPatientLabEncounters = async (payload: any) => {
  return await api.send('addPatientLabEncounters', payload);
};
export const addPatientRadEncounters = async (payload: any) => {
  return await api.send('addPatientRadEncounters', payload);
};

export const addPatientReferedData = async (payload: any) => {
  return await api.send('addPatientReferedData', payload);
};

export const getPatientBookingHistory = async (payload: any) => {
  return await api.send('getPatientBookingHistory', payload);
};

export const getPatientOpenFinancailLog = async (payload: any) => {
  return await api.send('getPatientOpenFinancailLog', payload);
};

export const updatePatientFinancailLog = async (payload: any) => {
  return await api.send('updatePatientFinancailLog', payload);
};

export const getPatientData = async (payload: { link: string }) => {
  return await api.send('getPatientData', payload);
};
