import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { rootState } from '../../../../../store/reducers';
import closeIcon from '../../../../../assets/img/close-icon.svg';
import AttachmentsComponent from '../../../../Board/ClinicExam/AttachmentsComponent/AttachmentsComponent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './DentLabOrderModal.scss';

const customStyles = {
  overlay: {
    background: 'none',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '1.5rem',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
    maxWidth: '50%',
    height: '70%',
  },
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  orderDataObj: (index: number, data: any) => void;
  ind: number;
  operation: any;
};
const DentLabOrderModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  orderDataObj,
  operation,
  ind,
}) => {
  const { orderData } = operation;

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );
  const { isAttachmentsUploading } = useSelector(
    (state: rootState) => state.board
  );

  const [date, setDate] = useState(new Date());
  const [labs, setLabs] = useState<any[]>([]);
  const [required, setRequired] = useState(false);
  const [status, setStatus] = useState('');
  const labData = {
    lab: { name: '', pk: '' },
    vendor: 'vendor',
    color: 'color',
    serviceType: 'tryIn',
    delivaryTime: new Date(date).getTime().toString(),
    notes: '',
    attachs: [''],
    units: '',
    serviceName: '',
    price: 0,
    status: 'new',
    labOrderStatus: 'Pending',
  };
  const [labOrderData, setLabOrderData] = useState({
    ...labData,
  });
  const workingTeeth =
    'Teeth: ' +
    operation.workingTeeth?.join(',').replace(/,([^,]*)$/, ' &' + '$1');
  useEffect(() => {
    if (currentUnitDetails?.labs) {
      const unitLabs = [];
      for (const key in currentUnitDetails?.labs) {
        unitLabs.push(currentUnitDetails?.labs[key]);
      }
      setLabs([...unitLabs]);
    }
    if (orderData) {
      setDate(
        orderData.delivaryTime ? new Date(+orderData.delivaryTime) : new Date()
      );
      setLabOrderData({ ...orderData });
    } else {
      setLabOrderData({
        ...labData,
        serviceName: operation.operation,
        units: operation.workingTeeth ? operation.workingTeeth.length : 0,
        attachs: [],
      });
    }
  }, [ind]);
  /**
   *
   * @param e
   * @description to handle input change if it dropdown lists
   */
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value !== '') {
      !labOrderData.status ? setStatus('updated') : setStatus('new');
    }
    if (name === 'labs') {
      if (value === '') {
        setLabOrderData({ ...labOrderData, lab: { name: '', pk: '' } });
      } else {
        const selectedLab = labs.filter((ele) => {
          return ele.name === value;
        });
        const service = selectedLab[0].services.filter((ser: any) => {
          return ser.name === labOrderData.serviceName;
        })[0];
        const oredrCost = service.price * +labOrderData.units;
        setLabOrderData({
          ...labOrderData,
          lab: { name: selectedLab[0].name, pk: selectedLab[0].pk },
          price: oredrCost,
        });
      }
    } else if (name === 'vendor') {
      setLabOrderData({ ...labOrderData, vendor: value });
    } else if (name === 'color') {
      setLabOrderData({ ...labOrderData, color: value });
    } else if (name === 'service') {
      setLabOrderData({ ...labOrderData, serviceType: value });
    } else if (name === 'notes') {
      setLabOrderData({ ...labOrderData, notes: value });
    }
  };

  const confirmHandler = () => {
    if (labOrderData.lab.pk === '') {
      setRequired(true);
    } else {
      setRequired(false);
      if (status !== '') {
        setLabOrderData({ ...labOrderData, status });
        orderDataObj(ind, { ...labOrderData, status });
      }
      closeModal();
    }
  };
  const addAttachs = (file: any) => {
    const photos = labOrderData.attachs;
    photos.push(file);
    setLabOrderData({ ...labOrderData, attachs: photos });
  };

  const deleteAttachs = (idx: number) => {
    if (labOrderData.attachs) {
      const allPhotos = labOrderData.attachs;
      const deletedAttachments = allPhotos.splice(idx, 1);
      setLabOrderData({ ...labOrderData, attachs: allPhotos });
    }
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      //  ariaHideApp={false}
      closeTimeoutMS={500}
      onRequestClose={() => {
        closeModal();
      }}
      style={customStyles}
    >
      <div className="modal-container">
        <div>
          <img
            className="close-img"
            src={closeIcon}
            alt=""
            onClick={() => {
              closeModal();
            }}
          />
        </div>
        <div className="component">
          <div>
            <h1>{operation.operation}</h1>
            <h1 className="teeth">{workingTeeth}</h1>
          </div>

          <div className="details mt-3">
            <div className="row">
              <div className="col">
                <h1>Lab</h1>

                <div>
                  <select
                    name="labs"
                    defaultValue={labOrderData?.lab?.name}
                    onChange={handleChange}
                  >
                    <option value="">--choose lab--</option>
                    {Object.keys(labs).map((key) => (
                      <option value={labs[+key].name} key={`option-${key}`}>
                        {labs[+key].name}
                      </option>
                    ))}
                  </select>

                  {required ? <span className="error">required</span> : null}
                </div>

                <h1>Shade</h1>
                <select name="vendor" onChange={handleChange}>
                  <option value="vedor">Vendor</option>
                </select>
                <select name="color" className="ml-4" onChange={handleChange}>
                  <option value="color">Color</option>
                </select>
                <div className="d-flex">
                  <div>
                    <h1>Service</h1>
                    <select
                      name="service"
                      value={labOrderData.serviceType}
                      onChange={handleChange}
                    >
                      <option value="tryIn">Try in</option>
                      <option value="delivery">Delivery</option>
                    </select>
                  </div>
                  <div className="ml-4">
                    <h1>Required by</h1>
                    <DatePicker
                      name="date"
                      className="datepicker"
                      selected={date}
                      onChange={(date) => {
                        date && setDate(date);
                        date &&
                          setLabOrderData({
                            ...labOrderData,
                            delivaryTime: new Date(date).getTime().toString(),
                          });
                        !labOrderData.status
                          ? setStatus('updated')
                          : setStatus('new');
                      }}
                      dateFormat="dd/MM/yyyy"
                      startDate={new Date(labOrderData.delivaryTime)}
                      minDate={new Date()}
                      popperPlacement="bottom-end"
                    />
                  </div>
                </div>
                <h1>Photos</h1>
                <div className="photos">
                  <AttachmentsComponent
                    imgSource={'dentLabOrder'}
                    orderPhotos={
                      labOrderData.attachs[0] !== '' ? labOrderData.attachs : []
                    }
                    addAttachs={addAttachs}
                    deleteAttachs={deleteAttachs}
                  />
                </div>
              </div>
              <div className="col">
                <h1>Notes</h1>
                <textarea
                  name="notes"
                  rows={10}
                  onChange={handleChange}
                  value={labOrderData.notes}
                ></textarea>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary confirm-btn  "
                    onClick={() => {
                      confirmHandler();
                    }}
                    disabled={isAttachmentsUploading}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DentLabOrderModal;
