import axios from 'axios';
import {
  deleteBookingTest,
  updateBookingTest,
} from '../../services/api/booking';
import { upload } from '../../services/api/media';
import { getFileExtension } from '../../utils/checkFileType';
import { errorToast } from '../../utils/toast';
import {
  REMOVE_FILES,
  RESET_UPLOADER,
  SET_FILES_SENT,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
} from './actionTypes';
import { updateExistBookingAction } from './labBoard.actions';
const UUID = () => Math.random().toString(36).slice(2);

type Doc = {
  id: string;
  url: string;
  status: string;
  type: string;
  patient: string;
  test: string;
  booking: string;
  file: any;
  cancelToken?: any;
};

export const uploadFileAction = (doc: Doc) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch({ type: UPLOAD_FILE, payload: { ...doc, status: 'pending' } });
    const url = await upload(doc.file, '', '', '', doc.cancelToken);
    // console.log(url);
    if (url) {
      const {
        booking: { currentEmpUnit },
      } = getState();
      const { data } = await updateBookingTest({
        id: currentEmpUnit.sk,
        sk: doc.booking,
        test: doc.test,
        url,
        type: doc.type,
      });
      dispatch(updateExistBookingAction(data));
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: { ...doc, url, status: 'success', cancelToken: null },
      });
    } else
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: { ...doc, url: null, status: 'error', cancelToken: null },
      });
  } catch (error) {
    dispatch({
      type: UPLOAD_FILE_SUCCESS,
      payload: { ...doc, url: null, status: 'error', cancelToken: null },
    });
  }
};

export const uploadMultiFiles = (
  files: any[],
  type: string,
  booking: any,
  test: any
) => async (dispatch: any) => {
  // console.log(booking);
  // console.log(files);
  const validFiles: any[] = [];
  files.forEach((file) => {
    const ext = getFileExtension(file.name);
    if (!ext) {
      errorToast(`${file.name} isn’t allowed to upload`);
    } else {
      validFiles.push(file);
    }
  });

  const docs: Doc[] = validFiles.map((file: any) => {
    const cancelToken = axios.CancelToken.source();

    return {
      file,
      status: 'new',
      id: UUID(),
      url: '',
      type,
      patient: `${booking.patient.name.firstName} ${booking.patient.name.lastName}`,
      booking: booking.sk,
      test: test.code,
      cancelToken,
    };
  });
  // console.log('docs', docs);
  docs.forEach((doc: Doc) => {
    dispatch(uploadFileAction(doc));
  });
};

export const removeTestImages = (
  type: string,
  booking: any,
  test: any
) => async (dispatch: any, getState: any) => {
  const { data } = await deleteBookingTest({
    id: booking.pk,
    sk: booking.sk,
    test: test.code,
    type,
  });
  dispatch(updateExistBookingAction(data));
  dispatch({
    type: REMOVE_FILES,
    payload: { type, test: test.code, booking: booking.sk },
  });
};

export const setBookingFilesSend = (booking: string) => async (
  dispatch: any
) => {
  dispatch({
    type: SET_FILES_SENT,
    payload: { booking },
  });
};

export const resetUploader = () => async (dispatch: any) => {
  dispatch({ type: RESET_UPLOADER });
};
