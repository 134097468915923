import { UserRoles } from '../assets/constants/roles';
export const storeData = (payload: any) => {
  Object.keys(payload).map((key) =>
    localStorage.setItem(key, JSON.stringify(payload[key]))
  );
};

export const getStoredData = (key: string) => {
  const data = localStorage.getItem(key);
  return data && data !== 'undefined' ? JSON.parse(data) : null;
};

export const removeStoredData = (key: string) => {
  localStorage.removeItem(key);
};

export const removeAllFromStorage = () => {
  const local = localStorage;
  Object.keys(local).map((key) =>
    key === 'i18nextLng' || key.startsWith('trustedDeviceToken-')
      ? null
      : removeStoredData(key)
  );
};

export const isAuthenticated = () => {
  const token = getStoredData('authToken');
  const user = getStoredData('user');
  // console.log(token);
  // console.log(user);
  if (
    token &&
    [
      UserRoles.Nurse,
      UserRoles.Receptionist,
      UserRoles.Assistant,
      UserRoles.Physician,
      UserRoles.RadioTech,
      UserRoles.LabTech,
    ].includes(user.role)
  )
    return true;
  return false;
};

export const isAuthenticatedEmployee = () => {
  const token = getStoredData('authToken');
  const user = getStoredData('user');
  if (
    token &&
    [UserRoles.Nurse, UserRoles.Receptionist, UserRoles.Assistant].includes(
      user.role
    )
  )
    return true;
  return false;
};
// export const isAuthenticatedTechnician = () => { }
export const isAuthenticatedPhysician = () => {
  const token = getStoredData('authToken');
  const user = getStoredData('user');
  if (
    token &&
    [UserRoles.Physician, UserRoles.RadioTech, UserRoles.LabTech].includes(
      user.role
    )
  )
    return true;
  return false;
};

export const isAuthenticatedPatient = () => {
  const token = getStoredData('authToken');
  const user = getStoredData('user');
  if (token && [UserRoles.Patient].includes(user.role)) return true;
  return false;
};
