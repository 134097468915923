import React from 'react';

import Loader from 'react-loader-spinner';

type Props = {
  color?: string;
};

const Spinner: React.FC<Props> = ({ color }) => {
  return (
    <Loader type="Oval" color={color ? color : '#fff'} height={25} width={25} />
  );
};

export default Spinner;
