import api from './api';
import axios from 'axios';
import { errorToast, successToast } from '../../utils/toast';
import imageCompression from 'browser-image-compression';
export const getUploadAccess = async (
  fileType: string,
  isThumbNail?: boolean
) => {
  try {
    const uploadedData = await api.send('getUploadAccess', {
      fileType,
      isThumbNail,
    });
    // console.log('uploadedData', uploadedData.data);
    return !isThumbNail ? uploadedData.data.url : uploadedData.data;
  } catch (error) {
    console.error('error getUploadAccess', error);
  }
};

const uploadImage = async (
  url: string,
  payload: any,
  msg: string,
  type: string,
  cancelToken?: any
) => {
  // console.log('UploadImage Called With ', payload, url, type);
  try {
    const config: any = {
      headers: {
        'Content-Type': type,
      },
    };
    if (cancelToken) config.cancelToken = cancelToken.token;

    const image = await axios.put(url, payload, config);
    // console.log('image', image);
    if (msg) successToast(msg);
    return image;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log('Request canceled', error.message);
      return null;
    } else {
      console.error('error uploadImage', error);
      throw new Error('');
    }
  }
};

export const upload = async (
  file: any,
  msg: string,
  successMsg: string,
  errMsg: string,
  isThumbNail?: boolean,
  cancelToken?: any
) => {
  if (!file) return;

  try {
    // console.log(file);
    const options = {
      // maxSizeMB: 0.25,
      maxWidthOrHeight: 100,
    };
    const thumbNailFile = isThumbNail
      ? await imageCompression(file, options)
      : null;
    const uploadAcessRes = await getUploadAccess(file.type, isThumbNail);

    const uploadUrl = !isThumbNail ? uploadAcessRes : uploadAcessRes.url;
    const uploadThumbUrl: any = isThumbNail
      ? uploadAcessRes.thumbUrl
      : undefined;

    // console.log('uploadUrl', uploadUrl);
    if (!uploadUrl || (isThumbNail && !uploadThumbUrl)) return null;
    // infoToast(msg);
    const image = await uploadImage(
      uploadUrl,
      file,
      successMsg,
      file.type,
      cancelToken
    );
    const thumbNail = isThumbNail
      ? await uploadImage(
          uploadThumbUrl,
          thumbNailFile,
          successMsg,
          file.type,
          cancelToken
        )
      : null;
    // thumbNail
    //   ? console.log(uploadThumbUrl.split('?')[0])
    //   : console.log('ThumbNail Fail');
    if (image) {
      const imgUrl = uploadUrl.split('?')[0];
      return imgUrl;
    } else {
      return null;
    }
  } catch (error) {
    console.error('error uploading image', error);

    errorToast(errMsg);
    return null;
  }
};

export const signUrl = async (url: string) => {
  try {
    const res = await api.send('signUrl', { url });
    return res.data.url;
  } catch (error) {
    return null;
  }
};
