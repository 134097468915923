import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserRoles } from '../../assets/constants/roles';
import routes from '../../assets/constants/routes';
import employeesIcon from '../../assets/img/employees.svg';
import employeesActiveIcon from '../../assets/img/employeesActive.svg';
import workingDaysIcon from '../../assets/img/workingDays.svg';
import workingDaysActiveIcon from '../../assets/img/workingDaysActive.svg';
import imageCenterIcon from '../../assets/img/labIcon.svg';
import imageCenterIconActive from '../../assets/img/labIcon-active.svg';
import Employees from '../../components/AddUnit/Employees/Employees';
import ScansTypes from '../../components/AddUnit/ScansTypes/ScansTypes';
import UnitName from '../../components/AddUnit/UnitName/UnitName';
import WorkingDays from '../../components/AddUnit/WorkingDays/WorkingDays';
import apiService from '../../services/api';
import {
  getClinicUnitsWithUpdateCurrentUnit,
  resetCurrentUnit,
  setCurrentBooking,
} from '../../store/actions/booking.actions';
import { errorToast } from '../../utils/toast';
import unitTypes from '../../utils/unitTypes';
import './AddImagingCenter.scss';
import OnlinePrescription from '../../components/AddUnit/OnlinePrescription/OnlinePrescription';

type Action = {
  type: string;
  payload: any;
};

const segmentsData = {
  workingDays: 'workingDays',
  scansTypes: 'scansTypes',
  employees: 'employees',
  imagingCenterName: 'imagingCenterName',
  onlinePrescription: 'onlinePrescription',
};

const initialState = {
  [segmentsData.workingDays]: {},
  [segmentsData.scansTypes]: {},
  [segmentsData.employees]: {},
  [segmentsData.onlinePrescription]: {},
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case segmentsData.imagingCenterName:
      return { ...state, ...action.payload };
    case segmentsData.workingDays:
      return { ...state, workingDays: action.payload };
    case segmentsData.onlinePrescription:
      return { ...state, onlinePrescription: action.payload };
    case segmentsData.scansTypes:
      return { ...state, scansTypes: action.payload };
    case segmentsData.employees:
      return { ...state, employees: action.payload };
    default:
      return state;
  }
};

const AddImagingCenter: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const reduxDiapatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedSegment, setSelectedSegment] = useState(
    segmentsData.workingDays
  );
  useEffect(() => {
    getScansTypes();
  }, []);

  const getScansTypes = async () => {
    const types = await apiService.getUnitTestsTypes({ type: 'scan' });
    dispatch({
      type: segmentsData.scansTypes,
      payload: types.data.scansTypesData,
    });
  };
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const [isUnitNamePage, setIsUnitNamePage] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.imagingCenterName) {
      dispatch({ type: segmentsData.imagingCenterName, payload: payload.data });
      setIsUnitNamePage(false);
    } else if (payload.type === segmentsData.workingDays) {
      dispatch({ type: segmentsData.workingDays, payload: payload.data });
      changeSegmentHandler(segmentsData.onlinePrescription);
    } else if (payload.type === segmentsData.onlinePrescription) {
      dispatch({
        type: segmentsData.onlinePrescription,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.employees);
    } else if (payload.type === segmentsData.scansTypes) {
      dispatch({ type: segmentsData.scansTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.employees);
    } else {
      dispatch({ type: segmentsData.employees, payload: payload.data });
    }
  };

  const goBackHandler = async (payload: { type: string; data: any }) => {
    if (payload.type === segmentsData.employees) {
      dispatch({ type: segmentsData.employees, payload: payload.data });
      changeSegmentHandler(segmentsData.onlinePrescription);
    } else if (payload.type === segmentsData.onlinePrescription) {
      dispatch({
        type: segmentsData.onlinePrescription,
        payload: payload.data,
      });
      changeSegmentHandler(segmentsData.workingDays);
    } else if (payload.type === segmentsData.scansTypes) {
      dispatch({ type: segmentsData.scansTypes, payload: payload.data });
      changeSegmentHandler(segmentsData.workingDays);
    }
  };

  const checkEmpsHandler = async (emps: any[]) => {
    try {
      let isValidEmp = true;
      for (const emp of emps) {
        const data = await apiService.checkUser({ mobile: emp.mobile });
        const empData = data.data;
        if (empData.exist && empData.user.role === UserRoles.Physician) {
          errorToast(
            t('addClinic_page.emp-errMsg', {
              mobile: emp.mobile,
              unitType: unitTypes.image,
            })
          );
          isValidEmp = false;
          setIsSubmitting(false);
          return isValidEmp;
        }
      }
      return isValidEmp;
    } catch (error) {
      console.error('error[checkEmpsHandler]', error);
      return false;
    }
  };

  const saveImagingCenterData = async () => {
    try {
      setIsSubmitting(true);
      const sentState = { ...state };
      delete sentState.onlinePrescription;
      const payload = {
        ...sentState,
        ...state.onlinePrescription,
        type: unitTypes.image,
      };
      // console.log('payload', payload);
      const clinicEmp = Object.values(payload.employees);
      const isValidEmps = await checkEmpsHandler(clinicEmp);
      if (isValidEmps) {
        const { data } = await apiService.addUnit(payload);
        const { unit } = data;
        if (unit)
          reduxDiapatch(getClinicUnitsWithUpdateCurrentUnit(unit, history));
        setIsSubmitting(false);
        history.push(routes.BOARD);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error('error[saveImagingCenterData]', error);
    }
  };

  useEffect(() => {
    reduxDiapatch(setCurrentBooking(null));
    reduxDiapatch(resetCurrentUnit());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="addImagingCenter-page d-flex flex-column">
      {isUnitNamePage ? (
        <UnitName
          nextStepHandler={nextStepHandler}
          currentSegment={segmentsData.imagingCenterName}
        />
      ) : (
        <>
          <div className="segment mt-3">
            <ul>
              <li
                className={`base token  ${
                  selectedSegment === segmentsData.workingDays ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.workingDays
                      ? workingDaysActiveIcon
                      : workingDaysIcon
                  }
                  alt="mobile"
                />
                {t('addImagingCenter_page.workingDays')}
              </li>
              <li
                className={`base token ${
                  selectedSegment === segmentsData.onlinePrescription
                    ? 'selected'
                    : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.onlinePrescription
                      ? imageCenterIconActive
                      : imageCenterIcon
                  }
                  alt="mobile"
                />
                {t('addImagingCenter_page.unitDetails')}
              </li>
              {/* <li
                className={`base ${
                  selectedSegment === segmentsData.scansTypes ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.scansTypes
                      ? scanIconActive
                      : scanIcon
                  }
                  alt="personal"
                />
                {t('addImagingCenter_page.scansTypes')}
              </li> */}
              <li
                className={`base ${
                  selectedSegment === segmentsData.employees ? 'selected' : ''
                }`}
              >
                <img
                  className="d-none d-sm-block"
                  src={
                    selectedSegment === segmentsData.employees
                      ? employeesActiveIcon
                      : employeesIcon
                  }
                  alt="check"
                />
                {t('addImagingCenter_page.employees')}
              </li>
            </ul>
          </div>
          <div className="addImagingCenter-page__main d-flex flex-column-reverse flex-sm-row mt-3 p-3">
            {selectedSegment === segmentsData.workingDays ? (
              <WorkingDays
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.workingDays}
                initialState={state[segmentsData.workingDays]}
              />
            ) : selectedSegment === segmentsData.scansTypes ? (
              <ScansTypes
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.scansTypes}
                initialState={state[segmentsData.scansTypes]}
              />
            ) : selectedSegment === segmentsData.onlinePrescription ? (
              <OnlinePrescription
                nextStepHandler={nextStepHandler}
                currentSegment={segmentsData.onlinePrescription}
                goBackHandler={goBackHandler}
                initialState={state[segmentsData.onlinePrescription]}
              />
            ) : (
              <Employees
                isSubmitting={isSubmitting}
                nextStepHandler={nextStepHandler}
                goBackHandler={goBackHandler}
                currentSegment={segmentsData.employees}
                saveClinicData={saveImagingCenterData}
                initialState={state[segmentsData.employees]}
                unitType={unitTypes.image}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddImagingCenter;
