import React, { useState } from 'react';
import './PasswordModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import formValidationSchema from './FormValidation';
import Button from '../../../UI/Form/Button/Button';
import PasswordInput from '../../../UI/Form/Password/PasswordInput';

import closeIcon from '../../../../assets/img/close2.svg';
import lockIcon from '../../../../assets/img/lock.svg';
import eyeIcon from '../../../../assets/img/eye.svg';
import visibilityIcon from '../../../../assets/img/visibility.svg';

const customStyles: { content: React.CSSProperties } = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30rem',
    borderRadius: '1rem',
    padding: 0,
  },
};

type Props = {
  isOpen: boolean;
  isChangePassword: boolean;
  onModalClose: () => void;
  confirmHandler: (values: any) => Promise<void>;
};

const PasswordModal: React.FC<Props> = ({
  isOpen,
  isChangePassword,
  confirmHandler,
  onModalClose,
}) => {
  const { t, i18n } = useTranslation();

  const [shouldShowOldPassword, setShouldShowOldPassword] = useState(false);
  const [shouldShowNewPassword, setShouldShowNewPassword] = useState(false);
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] = useState(
    false
  );

  const toggleOldPassword = () => {
    setShouldShowOldPassword(!shouldShowOldPassword);
  };

  const toggleNewPassword = () => {
    setShouldShowNewPassword(!shouldShowNewPassword);
  };

  const toggleConfirmPassword = () => {
    setShouldShowConfirmPassword(!shouldShowConfirmPassword);
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div
        className="passwordModal-container d-flex flex-column"
        dir={i18n.dir()}
      >
        <div className="header d-flex justify-content-between align-items-center p-2">
          <div className="flex-fill text-center">
            <h3>{t('register_page.changePassword')}</h3>
          </div>
          <img src={closeIcon} alt="" onClick={onModalClose} />
        </div>
        <div className="form-container p-3 mt-3">
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={formValidationSchema(t)}
            onSubmit={async (values) => {
              await confirmHandler(values);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="password">
                  <div className="lock-icon d-flex align-self-start">
                    <img src={lockIcon} alt="lockIcon"></img>
                    <h6>{t('changePassword_page.old_password')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <PasswordInput
                      type={shouldShowOldPassword ? 'text' : 'password'}
                      name="oldPassword"
                      placeholder={t('changePassword_page.enter_password')}
                      icon={shouldShowOldPassword ? eyeIcon : visibilityIcon}
                      isIcon={true}
                      iconClick={toggleOldPassword}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="password">
                  <div className="lock-icon d-flex align-self-start">
                    <img src={lockIcon} alt="lockIcon"></img>
                    <h6>{t('changePassword_page.set-password')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <PasswordInput
                      type={shouldShowNewPassword ? 'text' : 'password'}
                      name="newPassword"
                      placeholder={t('changePassword_page.enter_password')}
                      icon={shouldShowNewPassword ? eyeIcon : visibilityIcon}
                      isIcon={true}
                      iconClick={toggleNewPassword}
                    />
                  </div>
                </div>
                <div className="password">
                  <div className="lock-icon d-flex align-self-start">
                    <img src={lockIcon} alt="lockIcon"></img>
                    <h6>{t('changePassword_page.repeat-password')}</h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <PasswordInput
                      type={shouldShowConfirmPassword ? 'text' : 'password'}
                      name={'confirmPassword'}
                      placeholder={t('changePassword_page.enter_password')}
                      icon={
                        shouldShowConfirmPassword ? eyeIcon : visibilityIcon
                      }
                      isIcon={true}
                      iconClick={toggleConfirmPassword}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    type="submit"
                    name={t('changePassword_page.confirm')}
                    isSubmitting={isChangePassword}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordModal;
