import React from 'react';
import WithUnit from '../../hocs/WithUnit';
import UnitBoard from './UnitBoard';

const UnitBoardPage: React.FC = () => {
  return (
    <WithUnit>
      <UnitBoard />
    </WithUnit>
  );
};
export default UnitBoardPage;
