import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../store/reducers';
import AllergyComponent from './AllergyComponent/AllergyComponent';
import ChronicDiseaseComponent from './ChronicDiseaseComponent/ChronicDiseaseComponent';
import './ClinicExam.scss';
import DiagnosesComponent from './DiagnosesComponent/DiagnosesComponent';
import OrdersComponent from './OrdersComponent/OrdersComponent';
import PrescriptionComponent from './PrescriptionComponent/PrescriptionComponent';
import AttachmentsComponent from './AttachmentsComponent/AttachmentsComponent';
import SymptomsComponent from './SymptomsComponent/SymptomsComponent';
import VitalSignsComponent from './VitalSignsComponent/VitalSignsComponent';

type Props = {
  updateObject: (data: any) => void;
  data: any;
};
type Order = {
  name: string;
  code: string;
  class: string;
};
const ClinicExam = (props: Props) => {
  const { updateObject, data } = props;
  const { vitals, symp, diag, orders, allergy, chronic, pres } = data;

  const activeSection = useSelector(
    (state: rootState) => state.board.activeSection
  );
  const isExist = (array: any[], item: any) => {
    return array.find((d: any) => d.code === item.code);
  };
  const onSelectSymptom = ({ name, code }: any) => {
    if (isExist(symp, { name, code })) return;
    updateObject({ symp: [...symp, { name, code }] });
  };

  const onRemoveSymptom = ({ code }: any) => {
    updateObject({
      symp: symp.filter((obj: any) => obj.code !== code),
    });
  };

  const onSelectDiagnosis = ({ name, code }: any) => {
    if (isExist(diag, { name, code })) return;
    updateObject({ diag: [...diag, { name, code }] });
  };

  const onRemoveDiagnosis = ({ code }: any) => {
    updateObject({
      diag: diag.filter((obj: any) => obj.code !== code),
    });
  };

  const onSelectDisease = ({ name, code }: any) => {
    if (isExist(chronic, { name, code })) return;
    updateObject({ chronic: [...chronic, { name, code }] });
  };

  const onRemoveDisease = ({ code }: any) => {
    updateObject({
      chronic: chronic.filter((obj: any) => obj.code !== code),
    });
  };

  const onSelectOrders = (order: Order) => {
    if (isExist(orders, order)) return;
    updateObject({ orders: [...orders, order] });
  };

  const onRemoveOrders = ({ code }: any) => {
    updateObject({
      orders: orders.filter((obj: any) => obj.code !== code),
    });
  };

  const onSelectAllergy = ({ name, code }: any) => {
    if (isExist(allergy, { name, code })) return;
    updateObject({ allergy: [...allergy, { name, code }] });
  };

  const onRemoveAllergy = ({ code }: any) => {
    updateObject({
      allergy: allergy.filter((obj: any) => obj.code !== code),
    });
  };
  const onVitalChange = (d: any) => {
    updateObject({ vitals: d });
  };

  return (
    <div className="clinic-exam-view">
      <div
        className={`col-top ${
          activeSection.column === 0
            ? 'expand'
            : activeSection.column > 0
            ? 'collapsed'
            : ''
        }`}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <AllergyComponent
                onSelect={onSelectAllergy}
                onRemove={onRemoveAllergy}
                data={allergy || []}
                column={0}
              />
            </div>
            <div className="col-6">
              <ChronicDiseaseComponent
                onSelect={onSelectDisease}
                onRemove={onRemoveDisease}
                data={chronic || []}
                column={0}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`col-bottom ${
          activeSection.column > 0
            ? 'expand'
            : activeSection.column === 0
            ? 'collapsed'
            : ''
        }`}
      >
        <div className="d-flex">
          <div className={`border-right column column-fixed`}>
            <VitalSignsComponent vitalSigns={vitals} onChange={onVitalChange} />
            <AttachmentsComponent />
          </div>
          <div
            className={`border-right column ${
              activeSection.column === 2
                ? 'column-expanded'
                : activeSection.column === 1
                ? 'column-collapsed'
                : activeSection.column === 3
                ? 'column-collapsed'
                : 'column-default'
            }`}
          >
            <SymptomsComponent
              data={symp || []}
              onSelect={onSelectSymptom}
              onRemove={onRemoveSymptom}
              column={2}
            />
            <PrescriptionComponent
              isHistory={false}
              data={pres || []}
              column={2}
            />
          </div>
          <div
            className={`border-right column ${
              activeSection.column === 3
                ? 'column-expanded'
                : activeSection.column === 1
                ? 'column-collapsed'
                : activeSection.column === 2
                ? 'column-collapsed'
                : 'column-default'
            }`}
          >
            <DiagnosesComponent
              onSelect={onSelectDiagnosis}
              onRemove={onRemoveDiagnosis}
              data={diag || []}
              column={3}
            />
            <OrdersComponent
              onSelect={onSelectOrders}
              onRemove={onRemoveOrders}
              data={orders || []}
              column={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClinicExam;
