import React, { useState, useEffect } from 'react';
import './Charts.scss';

import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import getFullName from '../../../utils/getFullName';
import { ClinicData } from '../../../types';
import { getSatisticsColor } from '../../../utils/booking';
import unitTypes from '../../../utils/unitTypes';

type Props = {
  // days: number[];
  costs: { [key: number]: number };
  emps: {
    [key: string]: {
      name: {
        firstName: string;
        lastName: string;
      };
      total: number;
    };
  };
  bookingsTypes: {
    [key: string]: number;
  };
  shifts: {
    [key: string]: {
      [key: string]: number;
    };
  };
  clinic: ClinicData | null;
  totalBookings: number;
};

const data: any = {
  labels: [],
  datasets: [
    {
      label: '',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: '#0090ff',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#0090ff',
      pointBackgroundColor: '#0090ff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#0090ff',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 3,
      pointRadius: 3,
      borderWidth: 1,
      pointHitRadius: 10,
      data: [],
    },
  ],
};

const Chart: React.FC<Props> = ({
  costs,
  emps,
  bookingsTypes,
  shifts,
  clinic,
  totalBookings,
}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any>({ ...data });
  const [shiftsData, setShiftsData] = useState<any>({});

  useEffect(() => {
    if (costs && Object.keys(costs).length <= 0) {
      setChartData({
        ...data,
        labels: [],
        datasets: [{ ...data.datasets[0], data: [] }],
      });
      return;
    } else {
      const dd = Object.keys(costs);
      const cc = Object.values(costs);
      const datasets = [...data.datasets];
      datasets[0].data = cc;
      const ddd = {
        ...data,
        labels: dd,
        datasets,
      };
      setChartData(ddd);
    }
    // eslint-disable-next-line
  }, [costs]);

  useEffect(() => {
    // console.log('bookingsTypes', bookingsTypes);
    // console.log('shifts', shifts);
    if (
      shifts &&
      Object.keys(shifts).length > 0 &&
      bookingsTypes &&
      Object.keys(bookingsTypes).length > 0
    ) {
      const ff = { ...shifts };
      const newFF: any = {};
      for (const type of Object.keys(bookingsTypes)) {
        for (const shift of Object.keys(ff)) {
          const tt = {
            [type]: ff[shift][type] || 0,
          };
          // console.log('tt', tt);

          newFF[shift] = { ...(newFF[shift] || {}), ...tt };
        }
      }
      // console.log('newFF', newFF);
      setShiftsData({ ...newFF });
    } else {
      setShiftsData({});
    }
  }, [shifts, bookingsTypes]);

  const getCustomTypeName = (key: string) => {
    return clinic && clinic?.examinationTypes[key].name
      ? clinic?.examinationTypes[key].name
      : key;
  };

  return (
    <div className="chart-container d-flex flex-grow  mt-5 p-2">
      <div className="chart-container__chart">
        <Line
          data={chartData}
          // height={350}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              ],
            },
          }}
        />
      </div>
      {((shiftsData && Object.keys(shiftsData).length > 0) ||
        (emps && Object.keys(emps).length > 0)) && (
        <div className="chart-container__card p-4">
          <div className="chart-container__card-emps">
            {emps &&
              Object.keys(emps).length > 0 &&
              Object.keys(emps).map((emp, ind) => (
                <div
                  key={`${emps[emp].total}-${ind}`}
                  className="emp d-flex flex-cloumn flex-fill justify-content-between p-2"
                >
                  <p>{getFullName(emps[emp].name)}</p>
                  <p className="price">
                    {emps[emp].total} {t('examinationAndPrices_page.le')}
                  </p>
                </div>
              ))}
            {totalBookings >= 0 && clinic && clinic.type !== unitTypes.clinic && (
              <div className="emp d-flex flex-cloumn flex-fill justify-content-between p-2">
                <p>{t('examinationAndPrices_page.total')}</p>
                <p className="price">{totalBookings}</p>
              </div>
            )}
          </div>
          {shiftsData && Object.keys(shiftsData).length > 0 && (
            <>
              <hr />
              <div className="chart-container__card-shifts">
                <table className="table table-borderless table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">{t('booking_page.shift')}</th>
                      {bookingsTypes &&
                        Object.keys(bookingsTypes).map((type) => (
                          <th
                            key={'type-' + type}
                            scope="col"
                            style={{
                              color: getSatisticsColor({ type }, clinic),
                            }}
                          >
                            {getCustomTypeName(type)}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {shiftsData &&
                      Object.keys(shiftsData)
                        .sort()
                        .map((shift) => (
                          <tr key={'shift' + shift}>
                            <th scope="row">{shift + ''}</th>
                            {Object.keys(shiftsData[shift]).map(
                              (ele: any, ind) => (
                                <td
                                  key={'ele-' + ele + '-' + ind}
                                  style={{
                                    color: getSatisticsColor(
                                      { type: ele },
                                      clinic
                                    ),
                                  }}
                                >
                                  {shiftsData[shift][ele]}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                    <tr className="total">
                      <th scope="row">{t('booking_page.total')}</th>
                      {bookingsTypes &&
                        Object.keys(bookingsTypes).length > 0 &&
                        Object.keys(bookingsTypes).map((type) => (
                          <th
                            key={'total-' + type}
                            scope="row"
                            style={{
                              color: getSatisticsColor({ type }, clinic),
                            }}
                          >
                            {bookingsTypes[type]}
                          </th>
                        ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Chart;
