import React from 'react';
import {
  getDayFormat,
  getDayFormatEN,
} from '../../../assets/constants/currentDay';
import closeIcon from '../../../assets/img/close.svg';
import editIcon from '../../../assets/img/edit.png';
import i18n from '../../../services/i18n';

type Props = {
  title: string;
  price: string;
  date: string;
  even: boolean;
  index: number;
  removeAddedExpHandler: (index: number) => void;
  editExp: (index: number) => void;
};

const AddNewExperience: React.FC<Props> = ({
  title,
  price,
  date,
  even,
  index,
  removeAddedExpHandler,
  editExp,
}) => {
  const lang = i18n.language;
  const day =
    lang === 'en'
      ? getDayFormatEN(parseInt(date))
      : getDayFormat(parseInt(date));

  return (
    <div
      className="d-flex justify-content-between pl-4"
      style={{ backgroundColor: even ? 'white' : '#F2F9FF' }}
    >
      <div className="d-flex col-3">
        <p style={{ textTransform: 'capitalize' }}>{title}</p>
      </div>
      <div className="d-flex col-3 justify-content-center">
        <p>{price}</p>
      </div>
      <div className="d-flex col-4 justify-content-center">
        <p>{day}</p>
      </div>
      <div className="d-flex col-2 pr-0 justify-content-end">
        <div className="mr-2">
          <img
            height="17px"
            src={editIcon}
            alt="edit icon"
            onClick={() => editExp(index)}
          />
        </div>
        <div>
          <img
            height="17px"
            src={closeIcon}
            alt="close icon"
            onClick={() => removeAddedExpHandler(index)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewExperience;
