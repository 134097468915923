import React from 'react';
import { WithUnit } from '../../hocs';
import Finances from './Finances';

const FinancesPage: React.FC = () => {
  return (
    <WithUnit>
      <Finances />
    </WithUnit>
  );
};
export default FinancesPage;
