import bookingStatus from '../../assets/constants/bookingStatus';
import { Booking } from '../../types';
import {
  ADD_LAB_BOOKING,
  SET_CURRENT_LAB_BOOKING,
  SET_LAB_BOOKINGS,
  UPDATE_LAB_BOOKING,
  RESET_LAB_BOOKING,
  UPDATE_EXIST_LAB_BOOKING,
} from '../actions/actionTypes';

const initialState: any = {
  bookings: [],
  currentBooking: null,
  isRequestFinished: false,
};

const addBooking = (state: any, action: any) => {
  let bookings = [...state.bookings];
  let currentBooking = state.currentBooking;
  const newBooking: Booking = action.payload;

  // if (new Date(newBooking.date).getDate() !== new Date().getDate())
  //   return state;

  if (
    newBooking &&
    [
      bookingStatus.completed,
      bookingStatus.canceled,
      bookingStatus.deleted,
    ].includes(newBooking.status)
  ) {
    bookings = bookings.filter((ele) => ele.date !== newBooking.date);
    if (
      currentBooking &&
      currentBooking.sk === newBooking.date + '' &&
      currentBooking.pk === newBooking.pk
    )
      currentBooking = null;
  } else {
    const filteredBooking = bookings.findIndex(
      (ele) => ele.sk === newBooking.sk
    );
    if (filteredBooking >= 0) {
      bookings[filteredBooking] = newBooking;
    } else {
      bookings.push(newBooking);
    }
  }

  // console.log('bookings', bookings[0]);
  if (!currentBooking) currentBooking = bookings.length > 0 && bookings[0];
  // console.log('currentBooking', currentBooking);
  bookings.sort((a, b) => a.date - b.date);
  return { ...state, bookings, currentBooking };
};

const resetBooking = (state: any) => {
  return {
    ...state,
    bookings: [],
    currentBooking: null,
    isRequestFinished: false,
  };
};

export default function LabBoard(state = initialState, action: any): any {
  switch (action.type) {
    case SET_LAB_BOOKINGS:
      // console.log(action.payload);

      return { ...state, bookings: action.payload, isRequestFinished: true };
    case SET_CURRENT_LAB_BOOKING:
      return {
        ...state,
        currentBooking: action.payload,
      };

    case UPDATE_LAB_BOOKING:
      const i = state.bookings.findIndex(
        (b: any) => b.sk === action.payload.sk
      );
      if (i < 0) return { ...state };
      const data = [...state.bookings];
      data[i] = action.payload;
      // const currentBooking = i;
      return {
        ...state,
        bookings: data,
        // currentBooking,
      };
    case ADD_LAB_BOOKING:
      return addBooking(state, action);
    case RESET_LAB_BOOKING:
      return resetBooking(state);
    case UPDATE_EXIST_LAB_BOOKING:
      const bookings = [...state.bookings];
      let currentBooking = state.currentBooking;
      const index = bookings.findIndex((b: any) => b.sk === action.payload.sk);
      if (index >= 0) {
        bookings[index] = action.payload;
        if (currentBooking?.sk === action.payload.sk)
          currentBooking = action.payload;
      }
      return { ...state, bookings, currentBooking };
    default:
      return state;
  }
}
