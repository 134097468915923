import React from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import Spinner from '../../UI/Spinner/Spinner';
import './Header.scss';

type Props = {
  setIsMonthlyClick: (isMonthly: boolean) => void;
  isMonthlyClick: boolean;
  months: string[];
  setCurrentMonth: (Month: string) => void;
  currentMonth: string;
  setDatePickerOpen: any;
  datePickerOpen: boolean;
  selectedDate: Date;
  setSelectedDate: (arg: Date) => void;
};

const Header: React.FC<Props> = ({
  setIsMonthlyClick,
  months,
  isMonthlyClick,
  setCurrentMonth,
  currentMonth,
  setDatePickerOpen,
  datePickerOpen,
  selectedDate,
  setSelectedDate,
}) => {
  const { t, i18n } = useTranslation();
  const getDateName = (dateStr: any) => {
    const locale = i18n.language == 'en' ? 'us-EN' : 'ar-EG';
    const date = new Date(dateStr);
    return [
      date.toLocaleDateString(locale, { weekday: 'long' }),
      date.toLocaleDateString(locale, { month: 'long' }),
      date.toLocaleDateString(locale, { day: 'numeric' }),
    ];
  };

  return (
    <div className="finances-header d-flex align-self-center">
      {/* Swith Monthly & Day */}
      <div className="switch-container d-flex justify-content-between align-items-center">
        <div
          className={`day ${
            isMonthlyClick ? '' : 'select'
          } d-flex align-items-center justify-content-center`}
          onClick={() => {
            setIsMonthlyClick(false);
          }}
        >
          Daily
        </div>
        <div
          className={`day ${
            isMonthlyClick ? 'select' : ''
          } d-flex justify-content-center align-items-center`}
          onClick={() => {
            setIsMonthlyClick(true);
          }}
        >
          Monthly
        </div>
      </div>
      {/* Month/year */}
      {isMonthlyClick && (
        <div className="monthly-container d-flex justify-content-between align-items-center">
          {months &&
            months.map((month) => (
              <div
                key={month}
                onClick={() => {
                  setCurrentMonth(month);
                }}
                className={`monthly ${
                  month === currentMonth ? 'select' : ''
                } d-flex justify-content-center align-items-center`}
              >
                {month}
              </div>
            ))}
        </div>
      )}
      {!isMonthlyClick && (
        <div>
          {
            <div className="daily-reports-date-picker-section">
              <div className="daily-reports-date-picker">
                <i
                  onClick={() => setDatePickerOpen((prev: boolean) => !prev)}
                  className="fas fa-chevron-down"
                >
                  <div className="date-picker-card">
                    <DatePicker
                      open={datePickerOpen}
                      onChange={(date: Date) => setSelectedDate(date)}
                      selected={selectedDate}
                      disabledKeyboardNavigation
                    />
                  </div>
                </i>

                <p>
                  {getDateName(selectedDate)[2] +
                    ' ' +
                    getDateName(selectedDate)[1].toString()}
                </p>
              </div>
              <div className="daily-reports-day-picked">
                <p>{getDateName(selectedDate)[0]}</p>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default Header;
