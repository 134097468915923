import React from 'react';
import './Menu.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../../../../assets/constants/routes';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../store/reducers';
import { UserRoles } from '../../../../assets/constants/roles';
import { errorToast } from '../../../../utils/toast';
const Menu: React.FC = () => {
  const { t } = useTranslation();
  const { currentEmpUnit } = useSelector((state: rootState) => state.booking);
  const { navBarLocked } = useSelector((state: rootState) => state.booking);
  const userProfile = useSelector((state: rootState) => state.auth.user);

  const checkNavIsLocked = (e: any) => {
    if (navBarLocked) {
      e.preventDefault();
      errorToast(t('board-header_page.serviceIsEmpty'));
    }
  };
  const isDentist = () => {
    return userProfile?.specialty === 'dentistry';
  };
  return (
    <div className="menu-container">
      <ul className="d-flex justify-content-between">
        <li>
          <NavLink
            to={routes.BOARD}
            activeClassName="active"
            onClick={checkNavIsLocked}
          >
            {t('board-header_page.board')}
          </NavLink>
        </li>
        {currentEmpUnit && [UserRoles.Physician].includes(currentEmpUnit.role) && (
          <li>
            <NavLink
              to={routes.BOOKING}
              activeClassName="active"
              onClick={checkNavIsLocked}
            >
              {t('board-header_page.bookings')}
            </NavLink>
          </li>
        )}
        {currentEmpUnit && [UserRoles.Physician].includes(currentEmpUnit.role) && (
          <li>
            <NavLink
              to={routes.SETTINGS}
              activeClassName="active"
              onClick={checkNavIsLocked}
            >
              {t('board-header_page.setting')}
            </NavLink>
          </li>
        )}
        {currentEmpUnit && [UserRoles.Physician].includes(currentEmpUnit.role) && (
          <li>
            <NavLink
              to={routes.REPORTS}
              activeClassName="active"
              onClick={checkNavIsLocked}
            >
              {t('board-header_page.reports')}
            </NavLink>
          </li>
        )}
        {currentEmpUnit &&
          currentEmpUnit.type === 'clinic' &&
          [UserRoles.Physician].includes(currentEmpUnit.role) &&
          isDentist() && (
            <li>
              <NavLink
                to={routes.Finances}
                activeClassName="active"
                onClick={checkNavIsLocked}
              >
                {t('board-header_page.finances')}
              </NavLink>
            </li>
          )}
      </ul>
    </div>
  );
};

export default Menu;
