import React from 'react';
import './Header.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import routes from '../../../assets/constants/routes';

const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const changeLangHandler = () => {
    i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
  };

  return (
    <div
      id="main-header"
      className="main-header-container d-flex justify-content-between align-items-center"
      dir={i18n.dir()}
    >
      <div className="site-name align-self-start">
        <h1>{t('board-header_page.sijil')}</h1>
        <h3>{t('login_page.medical-care')}</h3>
      </div>
      {[
        routes.CHECK_USER,
        routes.LOGIN,
        routes.FORGET_PASSWORD,
        routes.CHANGE_PASSWORD,
      ].includes(location.pathname) && (
        <div className="langg d-flex align-items-center">
          <div className="img-container">
            {i18n.language === 'ar' ? (
              <span onClick={changeLangHandler}>
                {t('board-header_page.en')}
              </span>
            ) : (
              <span onClick={changeLangHandler}>
                {t('board-header_page.ar')}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
