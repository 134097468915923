import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import areas from '../../../assets/constants/areas';
import governments from '../../../assets/constants/governments';
import {
  mobileRegex,
  specialCharsWithNumbers,
} from '../../../assets/constants/regexValues';
import roles, {
  UserRoles,
  employeePayment,
  paymentRoles,
} from '../../../assets/constants/roles';
import arrowIcon from '../../../assets/img/arrow-down.svg';
import clinicIcon from '../../../assets/img/clinic.svg';
import closeIcon from '../../../assets/img/close.svg';
import locationIcon2 from '../../../assets/img/location-3.svg';
import locationIcon from '../../../assets/img/location.svg';
import mobileIcon from '../../../assets/img/mobile.svg';
import userIcon from '../../../assets/img/personal.svg';
import personalIcon from '../../../assets/img/personal.svg';
import phoneIcon from '../../../assets/img/phone.svg';
import questionIcon from '../../../assets/img/question.svg';
import question2Icon from '../../../assets/img/question2.svg';
import roleIcon from '../../../assets/img/role.svg';
import ApiService from '../../../services/api';
import { rootState } from '../../../store/reducers';
import { ClinicData } from '../../../types';
import { getPlacelatlng } from '../../../utils/location';
import parseArabic from '../../../utils/parseArabic';
import { errorToast } from '../../../utils/toast';
import { SubscriptionStatus } from '../../Subscription';
import Button from '../../UI/Form/Button/Button';
import AddNewEmployess from './AddNewEmployess';
import ExpModal from './ExpModal/ExpModal';
import MapModal from './MapModal/MapModal';
import OfferItem from './OfferItem';
import backArrow from '../../../assets/img/backArrow.svg';

import './UnitDetails.scss';
import { getCurruntDay } from '../../../assets/constants/currentDay';
import Expences from './Expences/Expences';
import { boolean, string } from 'yup';

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => Promise<void>;
  goBackHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  // initialState: any;
  isSettingPage?: boolean;
  isSubmitting?: boolean;
  unit: ClinicData | null;
};

const UnitDetails: React.FC<Props> = ({
  nextStepHandler,
  currentSegment,
  // initialState,
  isSettingPage,
  isSubmitting,
  goBackHandler,
  unit,
}) => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState('');
  const [oldname, setoldName] = useState('');
  const [gov, setGov] = useState('');
  const [area, setArea] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [drTitle, setDrTitle] = useState('');
  const [drSubTitle, setDrSubTitle] = useState('');
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [address, setAddress] = useState('');
  const [employees, setEmployees] = useState<any[]>([]);
  const [offers, setOffers] = useState<any[]>([]);
  const [removedEmployees, setRemovedEmployees] = useState<any[]>([]);
  const [addeddEmployees, setAddeddEmployees] = useState<any[]>([]);

  const [expences, setExpences] = useState<any[]>([]);
  const [addedExpenses, setaddedExpenses] = useState<any[]>([]);
  const [updatedExpences, setUpdatedExpences] = useState<any[]>([]);
  const [deletedExpenses, setdeletedExpenses] = useState<any[]>([]);
  const [currentUpdatedExpense, setCurrentUpdatedExpense] = useState<{}>({});
  const [isUpdatedExpense, setIsUpdatedExpense] = useState(false);
  const [updatedExpenseIndex, setupdatedExpenseIndex] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [openModalExp, setOpenModalExp] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const unitEmployees = useSelector(
    (state: rootState) => state.booking.unitEmployees
  );

  const unitExpenses = useSelector(
    (state: rootState) => state.booking.unitExpenses
  );

  useEffect(() => {
    if (!unit) return;

    // console.log('unit', unit);
    if (unit.name) setName(unit.name || '');
    setoldName(unit.name || '');

    if (unit.gov) setGov(unit.gov || '');
    if (unit.area) setArea(unit.area || '');
    if (unit.phone) setPhone(unit.phone || '');
    if (unit.mobile) setMobile(unit.mobile || '');
    if (unit.drTitle) setDrTitle(unit.drTitle || '');
    if (unit.drSubTitle) setDrSubTitle(unit.drSubTitle || '');
    if (unit.location) setLocation(unit.location);
    if (unit.location && unit.location.address)
      setAddress(unit.location.address);
    //  dispatch(setCurrentEmpUnit(unit));
    if (unit.networks?.length) {
      setOffers(unit.networks[0].offers);
      // console.log(unit.networks[0].offers);
    }
  }, [unit]);

  useEffect(() => {
    setEmployees(unitEmployees);
    setAddeddEmployees([]);
  }, [unitEmployees]);

  useEffect(() => {
    setExpences(unitExpenses);
    setaddedExpenses([]);
    setUpdatedExpences([]);
    setdeletedExpenses([]);
  }, [unitExpenses]);

  const saveClinicDetails = async () => {
    if (mobile) {
      const valid = mobileRegex.test(mobile);
      // console.log('valid', valid);
      if (!valid) {
        errorToast(
          t('addClinic_page.emp-errMsg', {
            mobile,
            unitType: unit?.type,
          })
        );
        return;
      }
    }
    let clinicData: any = {
      name,
      gov,
      area,
      phone,
      mobile,
      drTitle,
      drSubTitle,
      location: { ...location, address },
      offers,
      employees,
    };

    if (removedEmployees.length > 0) {
      clinicData = { ...clinicData, removedEmployees };
    }
    if (addeddEmployees.length > 0) {
      let isValid: boolean = false;
      let isValidMobile: boolean = false;
      for (const key of addeddEmployees) {
        isValid =
          !!key.mobile &&
          !!key.role &&
          !!key.firstName &&
          !!key.lastName &&
          !!key.payment &&
          !!key.salary;
        isValidMobile = mobileRegex.test(key.mobile);
      }
      if (!isValid) {
        errorToast(t('employess_page.emp-errMsg'));
        return;
      }
      if (!isValidMobile) {
        errorToast(t('booking_page.valid-11'));
        return;
      }
      clinicData = { ...clinicData, addeddEmployees };
    }
    if (expences.length > 0) {
      let isValid: boolean = false;
      for (const key of expences) {
        isValid = !!key.name && !!key.amount;
      }
      if (!isValid) {
        errorToast(t('employess_page.exp-errMsg'));
        return;
      }
      clinicData = {
        ...clinicData,
        expenses: {
          addedExpenses,
          deletedExpenses,
          editedExpenses: updatedExpences,
        },
      };
    }
    if (oldname !== name) clinicData = { ...clinicData, oldname };
    // console.log('clinic details', clinicData);
    // console.log(offers);
    if (offers.length) {
      const empty = offers.some(
        (o: any) => o.service === '' || o.discountRatio === ''
      );
      if (empty) {
        errorToast(t('setting_page.valid-offer'));
        return;
      }
    }
    for (const currentEmployee of employees) {
      if (!currentEmployee.salary) {
        errorToast(t('employess_page.emp-errMsg'));
        return;
      }
    }
    await nextStepHandler({ type: currentSegment, data: clinicData });

    // setAddeddEmployees([]);
    // history.push(routes.SETTINGS);
  };

  const addEmployeeHandler = () => {
    const addedEmp = {
      firstName: '',
      lastName: '',
      mobile: '',
      role: UserRoles.Assistant,
      payment: paymentRoles.Monthly,
      salary: '',
    };
    setAddeddEmployees([...addeddEmployees, addedEmp]);
  };

  const addExpHandler = (name: string, amount: string) => {
    const addedEex = {
      name,
      amount,
      _md: getCurruntDay(),
      _ct: getCurruntDay(),
    };
    let isValid: boolean = false;
    isValid = !!addedEex.name && !!addedEex.amount;
    if (!isValid) {
      errorToast(t('employess_page.emp-errMsg'));
      return;
    }
    setExpences([...expences, addedEex]);
    setaddedExpenses([...addedExpenses, addedEex]);
    openCloseModalExpHandler();
  };

  const editExpHandler = (name: string, amount: string) => {
    let isOld = false;
    const allExpenses = [...expences];

    const updateddEex = allExpenses[updatedExpenseIndex];
    if (updateddEex.pk || updateddEex.sk) isOld = true;

    updateddEex.name = name;
    updateddEex.amount = amount;
    updateddEex._md = getCurruntDay();

    let isValid: boolean = false;
    isValid = !!updateddEex.name && !!updateddEex.amount;
    if (!isValid) {
      errorToast(t('employess_page.emp-errMsg'));
      return;
    }

    allExpenses[updatedExpenseIndex] = updateddEex;
    if (isOld) setUpdatedExpences([...updatedExpences, updateddEex]);
    setExpences(allExpenses);
    openCloseModalExpHandler();
  };

  const setInputHandler = (
    index: number,
    keyName: string,
    value: string | null
  ) => {
    addeddEmployees[index] = {
      ...addeddEmployees[index],
      [keyName]: value,
    };
  };

  const removeEmployeeHandler = (index: number) => {
    const epms = [...employees];
    const removedEmp = epms.splice(index, 1)[0];
    setEmployees([...epms]);
    setRemovedEmployees([...removedEmployees, removedEmp]);
  };

  const setUpdateInputHandler = (
    index: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    const epms = [...employees];
    epms[index] = { ...epms[index], [name]: value };
    setEmployees([...epms]);
  };

  const removeAddedEmployeeHandler = (
    mobileNum: string,
    role: string,
    index: number
  ) => {
    let emps = [...addeddEmployees];
    if (mobileNum) {
      emps = emps.filter(
        (emp) => emp.mobile !== mobileNum && emp.role !== role
      );
    } else {
      emps.splice(index, 1);
    }
    setAddeddEmployees([...emps]);
  };

  const removeAddedExpHandler = (index: number) => {
    let isOld = false;
    const exp = [...expences];

    const deleteddEex = exp[index];
    if (deleteddEex.pk || deleteddEex.sk) isOld = true;

    if (isOld) {
      setdeletedExpenses([...deletedExpenses, deleteddEex]);
    } else {
      const newAddedExp = addedExpenses.filter(
        (e) => e._ct !== deleteddEex._ct
      );
      setaddedExpenses(newAddedExp);
    }

    exp.splice(index, 1);
    setExpences([...exp]);
  };

  const editExp = (index: number) => {
    const exp = expences[index];
    setupdatedExpenseIndex(index);
    setCurrentUpdatedExpense(exp);
    setIsUpdatedExpense(true);
    setOpenModalExp(!openModalExp);
  };

  const changeClinicDetails = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const attrName = e.target.name;
    const attrValue = e.target.value;
    // console.log(attrName, attrValue);
    if (attrName === 'name') {
      setName(attrValue);
    } else if (attrName === 'gov') {
      setGov(attrValue);
    } else if (attrName === 'area') {
      setArea(attrValue);
    } else if (attrName === 'unitPhone') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setPhone(num);
    } else if (attrName === 'unitMobile') {
      const num = parseArabic(attrValue).replace(/[^0-9]/g, '');
      setMobile(num);
    } else if (attrName === 'drTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumbers, '');
      setDrTitle(val);
    } else if (attrName === 'drSubTitle') {
      const val = parseArabic(attrValue).replace(specialCharsWithNumbers, '');
      setDrSubTitle(val);
    }
  };

  const openCloseModalHandler = () => {
    setOpenModal(!openModal);
  };
  const openCloseModalExpHandler = () => {
    setIsUpdatedExpense(false);
    setOpenModalExp(!openModalExp);
  };

  const openMapModalHandler = () => {
    openCloseModalHandler();
  };

  const setLocationAndOpenModal = (lat: number, lng: number) => {
    setLocation({ lat, lng });
    openMapModalHandler();
  };

  const setLocationFromGov = async () => {
    const { data } = await ApiService.searchPlaces({ value: gov });
    const firstPlace = data.results[0].place_id;
    // console.log('data.results[0]', data.results[0]);
    if (!firstPlace) return;
    const { lat, lng, address: addr } = await getPlacelatlng(firstPlace);
    // console.log('lat, lng, addr', lat, lng, addr);
    setAddress(addr);
    setLocationAndOpenModal(lat, lng);
  };

  const checkLocationHandler = () => {
    // check unit location details
    if (location && location.lat && location.lng) {
      openMapModalHandler();
      // request browser location
    } else if (window.navigator.geolocation) {
      // Geolocation available
      window.navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const { latitude, longitude } = position.coords;
          // console.log('position', position);
          setLocationAndOpenModal(latitude, longitude);
        },
        (err) => {
          // console.error('err', err);
          // errorToast(err.message);
          setLocationFromGov();
        }
      );
    }
  };

  const changeAddressHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // console.log('e.target.value', e.target.value);
    setAddress(e.target.value);
  };

  const showHideNotice = () => {
    setShowNotice(!showNotice);
  };

  const goBack = () => {
    goBackHandler({ type: currentSegment, data: { name: 'someData' } });
  };

  return (
    <div className="unit-details-container d-flex flex-column">
      {openModal && (
        <MapModal
          isOpen={openModal}
          onModalClose={openCloseModalHandler}
          confirmHandler={({ lat, lng, address: newAddress }) => {
            // console.log('[unit details component]', lat, lng, newAddress);
            if (lat && lng) setLocation({ lat, lng });
            if (newAddress) setAddress(newAddress);
            openCloseModalHandler();
          }}
          location={location}
        />
      )}
      {openModalExp && (
        <ExpModal
          isEdit={isUpdatedExpense}
          updatedExpense={currentUpdatedExpense}
          isOpen={openModalExp}
          onModalClose={openCloseModalExpHandler}
          addExpHandler={addExpHandler}
          editExpHandler={editExpHandler}
        />
      )}
      {isSettingPage && (
        <div className="d-flex justify-content-between">
          <SubscriptionStatus unit={unit} />
          <div className="button-actions d-flex align-self-end mb-4">
            <Button
              type="button"
              name={t('setting_page.save')}
              saveData={saveClinicDetails}
              isSubmitting={isSubmitting}
            />
            <Button
              type="button"
              name={t('addBooking_page.back')}
              btnType="back"
              saveData={goBackHandler}
            />
          </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <div className="unit-details-container__main d-flex flex-column">
          {/* unit details */}
          <div>
            {/*  name and location */}
            <div className="d-flex pl-3 justify-content-between">
              {isSettingPage ? (
                <div
                  style={{ flex: '1' }}
                  className="details d-flex flex-column align-self-start p-3"
                >
                  <h5>{t('addClinic_page.unitDetails')}</h5>
                  <div className="name-gov d-flex justify-content-between p-3 mt-3">
                    {/* first part */}
                    {/* <div> */}
                    {/* name */}
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={clinicIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.name')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <input
                          name="name"
                          type="text"
                          value={name}
                          placeholder={t('addClinic_page.name')}
                          onChange={changeClinicDetails}
                        />
                      </div>
                    </div>
                    {/* unit city */}
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={locationIcon} alt="lockIcon"></img>
                        <h6>{t('addClinic_page.city')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <select
                          name="gov"
                          value={gov}
                          onChange={changeClinicDetails}
                        >
                          <option value=""></option>
                          {Object.keys(governments).map((key) => (
                            <option key={key} value={key}>
                              {governments[key][i18n.language]}
                            </option>
                          ))}
                        </select>
                        <img
                          className="arrow"
                          src={arrowIcon}
                          alt="arrowIcon"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="lock-icon d-flex align-items-center">
                        <img src={locationIcon} alt="lockIcon"></img>
                        <h6>{t('addClinic_page.area')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <select
                          name="area"
                          value={area}
                          onChange={changeClinicDetails}
                        >
                          <option value=""></option>
                          {areas[gov] ? (
                            Object.keys(areas[gov]).map((key) => (
                              <option key={key} value={key}>
                                {areas[gov][key][i18n.language]}
                              </option>
                            ))
                          ) : (
                            <option></option>
                          )}
                        </select>
                        <img
                          className="arrow"
                          src={arrowIcon}
                          alt="arrowIcon"
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* unit expenses */}
              <Expences
                removeAddedExpHandler={removeAddedExpHandler}
                editExp={editExp}
                openCloseModalExpHandler={openCloseModalExpHandler}
                addeddexpenses={expences}
              />
            </div>
            {/* dr title and unit telephone numbers */}
            <div className="d-flex pl-3 justify-content-between">
              <div style={{ flex: '.5' }} className="d-flex flex-column pl-3">
                <div className="notice d-flex align-items-center ">
                  <h5>{t('addClinic_page.onlineDetails')} </h5>
                  <img
                    src={showNotice ? questionIcon : question2Icon}
                    alt=""
                    onClick={showHideNotice}
                    onBlur={() => {
                      setShowNotice(false);
                    }}
                    tabIndex={1}
                  />
                  {showNotice && <p>{t('addClinic_page.notice')}</p>}
                </div>
                <div className="pres-online d-flex justify-content-between p-3 mt-3">
                  {/* first part */}
                  <div>
                    <div className="name-gov dr d-flex flex-column">
                      {/* dr title */}
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <img src={personalIcon} alt="type name"></img>
                          <h6>{t('addClinic_page.dr-title')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            className="shared"
                            name="drTitle"
                            type="text"
                            value={drTitle}
                            placeholder={t('addClinic_page.dr-title-holder')}
                            onChange={changeClinicDetails}
                            maxLength={30}
                          />
                        </div>
                      </div>
                      {/* dr sub title */}
                      <div className="mt-3">
                        <div className="lock-icon d-flex align-items-center">
                          <img src={personalIcon} alt="type name"></img>
                          <h6>{t('addClinic_page.dr-subTitle')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <textarea
                            className="shared right"
                            name="drSubTitle"
                            value={drSubTitle}
                            rows={3}
                            placeholder={t('addClinic_page.dr-subtitle-holder')}
                            onChange={changeClinicDetails}
                            maxLength={130}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tel d-flex flex-column mt-4">
                      {/* unit phone */}
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <img src={phoneIcon} alt="type name"></img>
                          <h6>{t('addClinic_page.tel')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            name="unitPhone"
                            type="number"
                            value={phone}
                            placeholder={t('addClinic_page.mobile-holder')}
                            onChange={changeClinicDetails}
                            maxLength={10}
                          />
                        </div>
                      </div>
                      {/* unit mobile */}
                      <div className="mt-3">
                        <div className="lock-icon mobile-icon d-flex align-items-center">
                          <img src={mobileIcon} alt="type name"></img>
                          <h6>{t('addClinic_page.mobile')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            name="unitMobile"
                            type="number"
                            value={mobile}
                            placeholder={t('addClinic_page.mobile-holder')}
                            onChange={changeClinicDetails}
                            maxLength={11}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* second part */}
                  <div className="map-container ">
                    {location && location.lat && location.lng ? (
                      <>
                        <div
                          className="flex-fill"
                          style={{
                            overflow: 'hidden',
                            height: '17.7rem',
                          }}
                        >
                          <iframe
                            title="map"
                            width="100%"
                            height="400"
                            frameBorder="0"
                            style={{
                              border: 0,
                              borderRadius: '1rem',
                              marginTop: '-100px',
                              marginBottom: '-145px',
                              pointerEvents: 'none',
                            }}
                            src={`https://maps.google.com/maps?q= + ${location.lat} + , + ${location.lng} + &t=&z=15&ie=UTF8&iwloc=&output=embed`}
                          ></iframe>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className=" btn-container">
                            <button
                              className="d-flex justify-content-center align-items-center"
                              onClick={checkLocationHandler}
                            >
                              <img src={locationIcon2} alt="" />
                              <span>
                                {location && location.lat && location.lng
                                  ? t('addClinic_page.edit-location')
                                  : t('addClinic_page.add-location')}
                              </span>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className="no-map d-flex flex-column justify-content-center align-items-center"
                        onClick={checkLocationHandler}
                      >
                        <img src={locationIcon} alt="" />
                        <p>{t('addClinic_page.pick')}</p>
                      </div>
                    )}

                    {/* unit address */}
                    <div className="mt-3">
                      <div className="lock-icon d-flex align-items-center">
                        <img src={locationIcon} alt="type name"></img>
                        <h6>{t('addClinic_page.address')}</h6>
                      </div>
                      <div className="passwordInput mt-3">
                        <textarea
                          name="address"
                          className="shared address"
                          value={address}
                          rows={2}
                          placeholder={t('addClinic_page.address-holder')}
                          onChange={changeAddressHandler}
                          maxLength={97}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* unit employees */}
            <div className="employees-container d-flex flex-column align-self-start mt-3 p-3">
              <h5>{t('addClinic_page.empDetails')}</h5>
              <div className="mt-3">
                {employees &&
                  employees.length > 0 &&
                  employees.map((emp, index) => (
                    <div
                      key={`clinEmp-${index}-${emp.mobile}`}
                      className="employees d-flex p-3 justify-content-between"
                    >
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <img src={userIcon} alt="mobile icon" />
                          <h6>{t('setting_page.firstName')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            name="firstName"
                            type="text"
                            defaultValue={emp.name?.firstName}
                            placeholder={t('setting_page.writeFirstName')}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <img src={userIcon} alt="mobile icon" />
                          <h6>{t('setting_page.lastName')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            name="lastName"
                            type="text"
                            defaultValue={emp.name?.lastName}
                            placeholder={t('setting_page.writeLastName')}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <img src={roleIcon} alt="lockIcon"></img>
                          <h6>{t('employess_page.emp-role')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <select name="role" defaultValue={emp.role} disabled>
                            <option value="">
                              {t('register_page.location')}
                            </option>
                            {Object.keys(roles).map((key) => (
                              <option key={key} value={key}>
                                {roles[key][i18n.language]}
                              </option>
                            ))}
                          </select>
                          <img
                            className="arrow"
                            src={arrowIcon}
                            alt="arrowIcon"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="lock-icon mobile-icon d-flex align-items-center">
                          <img src={mobileIcon} alt="mobile icon" />
                          <h6>{t('setting_page.mobile')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            name="mobile"
                            type="number"
                            defaultValue={emp.mobile}
                            placeholder={t('setting_page.mobile')}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <h6>{t('employess_page.emp-payment')}</h6>
                        </div>
                        <div className="passwordInput mt-3">
                          <select
                            name="payment"
                            defaultValue={emp.payment}
                            onChange={(e) => {
                              setUpdateInputHandler(index, e);
                            }}
                          >
                            <option value="" hidden>
                              {t('employess_page.emp-payment')}
                            </option>
                            {Object.keys(employeePayment).map((key) => (
                              <option key={key} value={key}>
                                {employeePayment[key][i18n.language]}
                              </option>
                            ))}
                          </select>
                          <img
                            className="arrow"
                            src={arrowIcon}
                            alt="arrowIcon"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="lock-icon d-flex align-items-center">
                          <h6>{t('employess_page.emp-salary')}</h6>
                          <div className="close-iconn">
                            <img
                              src={closeIcon}
                              alt="close icon"
                              onClick={() => {
                                removeEmployeeHandler(index);
                              }}
                            />
                          </div>
                        </div>
                        <div className="passwordInput mt-3">
                          <input
                            name="salary"
                            type="number"
                            defaultValue={emp.salary}
                            placeholder={t('employess_page.emp-salary')}
                            onChange={(e) => {
                              setUpdateInputHandler(index, e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                {unit &&
                  addeddEmployees &&
                  addeddEmployees.length > 0 &&
                  addeddEmployees.map((ele, eleIndex) => (
                    <AddNewEmployess
                      key={`addedEmp-${eleIndex}`}
                      index={eleIndex}
                      unitType={unit?.type}
                      removeAddedEmployeeHandler={removeAddedEmployeeHandler}
                      addEmpHandler={setInputHandler}
                    />
                  ))}
                <p className="add mt-3 p-3">
                  <span onClick={addEmployeeHandler}>
                    {t('setting_page.add-emp')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isSettingPage && (
        <div className="addClinc-btn-container">
          <Button saveData={() => {}} name={t('working_days_page.next')} />
          <div
            className="back go-back align-self-end"
            onClick={() => {
              goBack();
            }}
          >
            <span>{t('otp_page.back')}</span>
            <img src={backArrow} alt="back" />
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitDetails;
