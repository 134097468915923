import React from 'react';
import './FinancesContainer.scss';

type Props = {
  header: {
    start: string;
    mid?: string;
    end: string;
  };
  data: {
    start: string;
    mid: string;
    end: string;
  };
  total?: string;
};

const FinancesContainer: React.FC<Props> = ({ header, data, total }) => {
  return (
    <div
      className={`finances-container d-flex flex-column mt-4 ${
        total ? '' : 'pb-5'
      }`}
    >
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: 'bold' }}>{header.start}</div>
        {total && <div>{header.mid} Visit</div>}
        <div>
          {header.end} {total ? 'New' : 'Order'}
        </div>
      </div>

      <div
        style={{ textAlign: 'end' }}
        className="d-flex justify-content-around  mt-2"
      >
        <div className="d-flex flex-column">
          <div className="mb-1"> {total ? 'Income' : 'Total'}</div>
          <div className="mb-1"> {total ? 'Lab' : 'Deposits'}</div>
          <div className="mb-1"> {total ? 'Percentage' : 'Remaing'}</div>
        </div>
        <div className="d-flex flex-column align-self-center">
          <div
            className="mb-1"
            style={{ textAlign: 'center', color: '#0090FF' }}
          >
            {data.start}
          </div>
          <div
            className="mb-1"
            style={{ textAlign: 'center', color: '#F31D73' }}
          >
            {data.mid}
          </div>
          <div
            className="mb-1"
            style={{ textAlign: 'center', color: '#707070' }}
          >
            {data.end}
          </div>
          {total && (
            <hr
              style={{ width: '70px', borderTop: '1px solid', margin: '0px' }}
            ></hr>
          )}
          {total && (
            <div
              style={{
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {total}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancesContainer;
