import React, { useState, useEffect } from 'react';
import './Header.scss';

import {
  daysInMonth,
  // monthsInYear,
  formatTodayDate,
  getMonthNumber,
  getMonthNameFromNumber,
  addMonthToYear,
} from '../../../utils/moment';

import arrowIcon from '../../../assets/img/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { ClinicData } from '../../../types';

type Props = {
  getUnitStatisticsData: (
    startDate: number,
    endDate: number,
    months: number[]
  ) => void;
  getDayStatistics: (sDate: number) => void;
  dayTotalCost: number;
  currentUnitDetails: ClinicData | null;
};

const Header: React.FC<Props> = ({
  getUnitStatisticsData,
  getDayStatistics,
  dayTotalCost,
  currentUnitDetails,
}) => {
  const { t } = useTranslation();
  const [openDropDown, setOpenDropDown] = useState(false);

  const [monthDate, setMonthDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState('');
  const [months, setMonths] = useState<string[]>([]);

  const [currentDay, setCurrentDay] = useState(0);
  const [days, setDays] = useState<number[]>([]);

  // const [year, setYear] = useState<number>(0);

  const [today, setToday] = useState('');

  useEffect(() => {
    if (!currentUnitDetails) return;

    const monthsfromUnitCreatingDate: string[] = [];
    let dd = new Date(currentUnitDetails._ct).getTime();
    // let dd = new Date(new Date().setFullYear(2019)).getTime();
    const myDate = new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ).getTime();
    while (myDate >= dd) {
      const mName = getMonthNameFromNumber(new Date(dd).getMonth() + 1);
      monthsfromUnitCreatingDate.push(`${mName} ${new Date(dd).getFullYear()}`);
      dd = new Date(addMonthToYear(new Date(dd))).getTime();
    }

    setMonths(monthsfromUnitCreatingDate.reverse());

    const monthName = getMonthNameFromNumber(new Date().getMonth() + 1);
    setCurrentMonthHandler(`${monthName} ${new Date().getFullYear()}`);

    // eslint-disable-next-line
  }, [currentUnitDetails]);

  const setCurrentMonthHandler = (month: string) => {
    if (month === currentMonth) return;
    setCurrentMonth(month);
    getMonthDaysHandler(month);
  };

  const getMonthDaysHandler = (month: string) => {
    // console.log('getMonthDaysHandler', month);
    const [mm, yy] = month.split(' ');
    const monthNumber = getMonthNumber(mm);
    const mDate = new Date(
      new Date(new Date().setFullYear(+yy)).setMonth(monthNumber)
    );
    // console.log('mDate', mDate);
    setMonthDate(mDate);
    const monthDays = daysInMonth(mDate);

    setDays(monthDays);

    getUnitsStatisticsHandler(
      mDate,
      monthDays[0],
      monthDays[monthDays.length - 1],
      monthDays
    );

    changeDayHandler(mDate, mDate.getDate());
  };

  const getUnitsStatisticsHandler = (
    date: Date,
    sDate: number,
    eDate: number,
    monthsData: number[]
  ) => {
    const startDate = new Date(
      new Date(new Date(date).setDate(sDate)).setHours(2, 0, 0)
    ).getTime();
    const endDate = new Date(
      new Date(new Date(date).setDate(eDate)).setHours(23, 0, 0)
    ).getTime();

    getUnitStatisticsData(startDate, endDate, monthsData);
  };

  const changeDayHandler = (date: Date, day: number) => {
    if (day === currentDay) return;
    setCurrentDay(day);
    const todayDate = new Date(new Date(date).setDate(day)).setHours(
      2,
      0,
      0,
      0
    );
    setToday(formatTodayDate(todayDate));
    getDayStatistics(todayDate);
    // console.log('changeDayHandler', todayDate);
  };

  const openDropDownHandlerOnBlur = () => {
    setOpenDropDown(false);
  };

  const openDropDownHandler = () => {
    setOpenDropDown(!openDropDown);
  };

  return (
    <div className="report-header-container">
      <div className="report-header-container--months d-flex">
        <div
          tabIndex={1}
          className="report-header-container--months__select d-flex align-self-center justify-content-between align-items-center"
          onClick={openDropDownHandler}
          onBlur={openDropDownHandlerOnBlur}
        >
          <div className="current-month d-flex align-items-center">
            {currentMonth && <p>{currentMonth}</p>}
          </div>

          <img src={arrowIcon} alt="arrowIcon" />
          {openDropDown && (
            <div className="month-list">
              {months &&
                months.map((month) => (
                  <div
                    key={month}
                    className="month-item d-flex align-items-center"
                    onClick={() => {
                      setCurrentMonthHandler(month);
                    }}
                  >
                    <p>{month}</p>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="report-header-container--months__price">
          <p className="report-header-container--months__price-day">
            {t('booking_page.day')}: {today}
          </p>
          <p className="report-header-container--months__price-price">
            {dayTotalCost} {t('examinationAndPrices_page.le')}
          </p>
        </div>
      </div>

      <div className="report-header-container--days d-flex flex-column mt-5">
        <div className="report-header-container--days__month d-flex align-items-center">
          <p className="name">{t('booking_page.days')}</p>
          <div className="day d-flex">
            {days.map((day) => (
              <div
                key={day}
                className={`d-flex align-items-center justify-content-center ${
                  currentDay === day ? 'selected' : ''
                }`}
                onClick={() => {
                  changeDayHandler(monthDate, day);
                }}
              >
                <p
                  className={`${
                    new Date().getDate() === day &&
                    new Date(monthDate).getMonth() === new Date().getMonth()
                      ? 'today'
                      : ''
                  }`}
                >
                  {day}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
