import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { rootState } from '../../../store/reducers';
import './DentalBoard.scss';
import menuIcon from '../../../assets/img/menu.png';
import { CancelIcon } from '../../../assets/constants/images';
import bookingStatus from '../../../assets/constants/bookingStatus';
import LottiePlayer from '../../../components/Animation/Animation';
import BoardPatientInfo from '../../../components/Board/BoardPatientInfo';
import BoardMenu from '../../../components/Board/BoardMenu';
import BookingList from '../../../components/Board/ClinicExam/BookingList/BookingList';
import { updateReservation } from '../../../store/actions';
import ApiService from '../../../services/api';
import {
  setCurrentBooking,
  setCurrentFinancailLog,
  updateBooking,
  updateCurrentFinancailLog,
} from '../../../store/actions/booking.actions';
import DentalBoardContent from '../../../components/DentalBoard/DentalBoardContent';
import {
  toggleMenuAction,
  getClinicBookingsAction,
  StartExaminationAction,
  getCurrentPatientProfileAction,
  getPatientBookingHistoryAction,
  setCurrentViewAction,
  endBookingAction,
  setFinancalLog,
  setBoardCurrentFinancailLog,
} from '../../../store/actions/board.actions';
const DentalBoard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);
  const isRequestFinished = true;
  const currentView = useSelector(
    (state: rootState) => state.board.currentView
  );
  const currentPatient = useSelector(
    (state: rootState) => state.booking.currentPatient
  );

  const currentBooking = useSelector(
    (state: rootState) => state.board.currentBooking
  );

  const boardCurrentFinancailLog = useSelector(
    (state: rootState) => state.board.currentFinancailLog
  );
  const reservation = useSelector(
    (state: rootState) => state.board.reservation
  );

  const attachments = useSelector(
    (state: rootState) => state.board.attachments
  );

  const [startDate] = useState(moment().startOf('day').toDate().getTime());
  const [endDate] = useState(moment().endOf('day').toDate().getTime());

  const [filteredHistory, setFilterHistory] = useState<any>([]);

  const [openReferModal, setOpenReferModal] = useState(false);
  const bookings = useSelector((state: rootState) => state.board.bookings);
  const history = useSelector((state: rootState) => state.board.history);
  const menuOpen = useSelector((state: rootState) => state.board.menuOpen);
  const currentEncounter = useSelector(
    (state: rootState) => state.board.currentEncounter
  );
  // this function check if the encounter is DONE
  const isReservationOpen = () => {
    const operationsNotFinshed = reservation?.operations.some(
      (op: any) =>
        op.operationStatus !== 'Completed' && op.operationStatus !== 'Canceled'
    );

    const bookingIsPaid =
      boardCurrentFinancailLog?.fLog[boardCurrentFinancailLog?.fLog.length - 1]
        .balance === 0;

    // NAND , return  false "not open" only when paid and operation are doone
    return !(!operationsNotFinshed && bookingIsPaid);
  };

  // this function calculate the visits object

  const accumlateVisits = () => {
    // add first booking
    if (!reservation.visits || !reservation.visits[0].booking) {
      // console.log('First Visit');
      return [
        {
          booking: {
            type: currentBooking.type,
            cost: currentBooking.cost,
            sk: currentBooking.sk,
            date: currentBooking.date,
            linkId: currentBooking.linkId,
          },
        },
      ];
    }

    if (
      reservation.visits.some(
        (ele: any) => ele.booking.sk === currentBooking.sk
      )
    ) {
      // console.log('Booking already exist and the same ');
      return [...reservation.visits];
    }
    // console.log('New booking to be accumlated ');
    return [
      ...reservation.visits,
      {
        booking: {
          type: currentBooking.type,
          cost: currentBooking.cost,
          sk: currentBooking.sk,
          date: currentBooking.date,
          linkId: currentBooking.linkId,
        },
      },
    ];
  };

  const onSave = (status: string, partialUpdate: boolean = false) => {
    if ((!currentBooking && !reservation.pk) || !currentUnitDetails) return;
    let removeLocalUrls,
      removeOrdersLocalUrls: { url: string }[] = [];
    if (attachments && attachments.length > 0) {
      removeLocalUrls = attachments.map(({ url }: any) => {
        const splittedUrl = url.split('?')[0];
        return { url: splittedUrl };
      });
    }
    let operations: [] = [];
    if (reservation && reservation.operations.length > 0) {
      operations = reservation.operations.map((oper: any) => {
        if (
          oper.orderData &&
          oper.orderData.attachs &&
          oper.orderData.attachs[0] != ''
        )
          removeOrdersLocalUrls = oper.orderData.attachs.map(({ url }: any) => {
            const splittedUrl = url.split('?')[0];
            return { url: splittedUrl };
          });
        return {
          ...oper,
          orderData: { ...oper.orderData, attachs: removeOrdersLocalUrls },
        };
      });
    }

    if (currentBooking) {
      const payload = {
        ...reservation,
        attachments: removeLocalUrls,
        operations,
        unit: currentUnitDetails.pk,
        id: currentBooking.patient.pk,
        patient: {
          name: currentBooking.patient.name,
          lmobile: currentBooking.patient.lmobile,
        },
        b: {
          type: currentBooking.type,
          cost: currentBooking.cost,
          sk: currentBooking.sk,
          date: currentBooking.date,
          linkId: currentBooking.linkId,
        },
        clinic: currentBooking.clinic,
        unitType: currentUnitDetails.type,
        dr: currentBooking.dr,
        status,
        partialUpdate,
        isOpen: isReservationOpen(),
        visits: accumlateVisits(),
      };
      delete payload.vitals;

      delete payload.diag;
      // console.log('payload', payload);
      dispatch(endBookingAction(payload));
    } else if (reservation.pk) {
      const payload = {
        ...reservation,
        attachments: removeLocalUrls,
        operations,
        unit: currentUnitDetails.pk,
        unitType: currentUnitDetails.type,
        status,
        partialUpdate,
        id: reservation.pk,
        isOpen: isReservationOpen(),
      };
      delete payload.vitals;

      delete payload.diag;
      // console.log('payload with reservation', payload);
      dispatch(endBookingAction(payload));
    }
    dispatch(updateCurrentFinancailLog(boardCurrentFinancailLog.fLog));
  };

  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  const setMenuOpened = () => {
    dispatch(toggleMenuAction(!menuOpen));
  };

  const setCurrentView = (view: string) => {
    // console.log('set current view handler called ');
    dispatch(setCurrentViewAction(view));
  };

  const setRefereModal = (open: boolean) => {
    setOpenReferModal(open);
  };

  const closeReferModalHandler = () => {
    setOpenReferModal(false);
  };

  // this useEffect set the current view for the holde board , logic which decide which component to be rendered should be here
  useEffect(() => {
    if (currentPatient || currentBooking) {
      // setCurrentView('clinicalExam');
    } else if (
      bookings &&
      bookings.some(
        (ele: any) =>
          ele.status === bookingStatus.active ||
          ele.status === bookingStatus.new ||
          ele.status === bookingStatus.hold
      )
    ) {
      setCurrentView('patient-waiting');
    } else {
      setCurrentView('no-patient');
    }
  }, [currentPatient, bookings]);

  const isExamView = (view: string) => {
    if (
      view === 'clinicalExam' ||
      view === 'historyView' ||
      view === 'radiologyView' ||
      view === 'labView'
    )
      return true;

    return false;
  };

  const updateObject = (updated: any) => {
    if (currentBooking?.status === 'new') {
      dispatch(
        updateBooking(currentBooking.pk, currentBooking.sk, 'status', {
          status: 'active',
        })
      );
    }
    dispatch(
      updateReservation({ ...reservation, ...updated, lastChange: new Date() })
    );
  };

  useEffect(() => {
    if (currentUnitDetails?.pk) {
      dispatch(
        getClinicBookingsAction(currentUnitDetails.pk, startDate, endDate)
      );
      if (currentPatient?.pk) {
        dispatch(
          setCurrentFinancailLog(currentUnitDetails.pk, currentPatient.pk)
        );
      }
    }
    // eslint-disable-next-line
  }, [currentUnitDetails, startDate, currentPatient]);

  useEffect(() => {
    if (boardCurrentFinancailLog) {
      dispatch(setFinancalLog(boardCurrentFinancailLog));
    }
    // eslint-disable-next-line
  }, [boardCurrentFinancailLog]);

  useEffect(() => {
    const { lastChange } = reservation;
    if (lastChange && currentPatient) return;
    if (currentBooking && currentUnitDetails) {
      dispatch(
        getCurrentPatientProfileAction(
          currentBooking
            ? currentBooking.patient.pk
            : currentBooking.patient.pk,
          currentUnitDetails.pk
        )
      );
      dispatch(
        setBoardCurrentFinancailLog(
          currentUnitDetails.pk,
          currentBooking.patient.pk
        )
      );

      dispatch(
        getPatientBookingHistoryAction(
          currentBooking
            ? currentBooking.patient.pk
            : currentBooking.patient.pk,
          currentUnitDetails.pk
        )
      );
    }
    const patientInClinic = bookings.some(
      (ele: any) =>
        ele.status ===
        (bookingStatus.active || bookingStatus.hold || bookingStatus.completed)
    );
    if (patientInClinic) {
      dispatch(StartExaminationAction(true));
    }
    // eslint-disable-next-line
  }, [currentBooking, currentUnitDetails]);

  useEffect(() => {
    if (bookings) {
      if (!currentBooking) {
        const activeBookingIndex = bookings.findIndex(
          (booking: any) => booking.status === 'active'
        );
        if (activeBookingIndex >= 0)
          dispatch(setCurrentBooking(bookings[activeBookingIndex]));
        else dispatch(setCurrentBooking(null));
      }
      const patientInClinic = bookings.some(
        (ele: any) => ele.status === bookingStatus.active // ||
        // bookingStatus.hold ||
        // bookingStatus.active)
      );
      if (patientInClinic) {
        dispatch(StartExaminationAction(true));
      }
    }

    // eslint-disable-next-line
  }, [bookings]);

  useEffect(() => {
    setFilterHistory(
      currentBooking
        ? history.filter((enc: any) => enc.sk !== `dr-${currentBooking.sk}`)
        : history.filter((enc: any) => enc.sk !== reservation.sk)
    );
    // eslint-disable-next-line
  }, [history, currentBooking]);
  return (
    <div className="dental-page">
      <div className="left-side">
        <div className="col-top">
          {/*eslint-disable-next-line */}
          <a
            className="menu-btn"
            onClick={() => {
              setMenuOpened();
            }}
          >
            <img src={menuIcon} alt="" />
          </a>
          {currentPatient && (
            <BoardPatientInfo
              currentPatient={currentPatient}
              currentBooking={currentBooking}
              reservation={reservation}
              onSave={onSave}
              onSelect={() => {
                setMenuOpened();
              }}
              isDental={true}
            />
          )}
        </div>
        <div className="col-bottom">
          {currentPatient && (
            <BoardMenu
              currentPatient={currentPatient}
              currentView={currentView}
              setCurrentView={setCurrentView}
              filteredHistory={filteredHistory}
              currentEncounter={currentEncounter}
              isDental={true}
              currentBooking={currentBooking}
              setRefereModal={setRefereModal}
            />
          )}
        </div>

        {menuOpen && (
          <div className="reservation-list" id="overlay" ref={ref}>
            <div className="reservation-list-header d-flex justify-content-between">
              <span>Reservations</span>
              <button
                type="button"
                className="btn close"
                onClick={() => {
                  setMenuOpened();
                }}
              >
                <CancelIcon />
              </button>
            </div>

            <div className="reservation-list-body">
              {bookings && bookings.length > 0 && isRequestFinished ? (
                <BookingList
                  onSave={onSave}
                  onSelect={() => {
                    setMenuOpened();
                  }}
                />
              ) : isRequestFinished && bookings.length === 0 ? (
                <div className="no-patient d-flex flex-column justify-content-center align-items-center">
                  <p>{t('clinicBoard_page.no-patient')}</p>
                  <p className="msg">{t('clinicBoard_page.no-patient-msg')}</p>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <div className="page-content">
        {isExamView(currentView) ? (
          <DentalBoardContent
            currentView={currentView}
            updateObject={updateObject}
            reservation={reservation}
            currentEncounter={currentEncounter}
            currentPatient={currentPatient}
            openReferModal={openReferModal}
            closeReferModalHandler={closeReferModalHandler}
          />
        ) : currentView === 'patient-waiting' ? (
          <div className="no-patient d-flex flex-column justify-content-center align-items-center">
            <h4>You have waiting patients on the queue</h4>
            <LottiePlayer />
          </div>
        ) : currentView === 'no-patient' ? (
          <div className="no-patient d-flex justify-content-center align-items-center">
            <LottiePlayer />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DentalBoard;
