import React, { useState, useEffect } from 'react';
import './ReportBotton.scss';
import { useTranslation } from 'react-i18next';
import { getMonthNameFromNumber } from '../../../utils/moment';
import Report from './Report/Report';

type Props = {
  name: string;
  amount: string;
  color: string;
  currentMonth: string;
  reportData: any[];
};

const ReportBotton: React.FC<Props> = ({
  name,
  amount,
  color,
  currentMonth,
  reportData,
}) => {
  const { t } = useTranslation();
  // const [reportData, setReportData] = useState<any[]>([]);
  const [reportType, setReportType] = useState('');
  const month = getMonthNameFromNumber(+currentMonth.split('/')[0]);
  const onModalClose = () => {
    setReportType('');
  };
  return (
    <div className="month-report-container d-flex align-self-center">
      <div
        className="month-report d-flex justify-content-between"
        style={{ backgroundColor: `${color}` }}
        onClick={() => {
          if (name === 'LAB') {
            setReportType('Lab');
          } else if (name === 'TOTAL INCOME') {
            setReportType('Income');
          } else if (name === 'EXPENCES') {
            setReportType('Expences');
          }
        }}
      >
        <div className="align-self-center" style={{ fontSize: '12px' }}>
          {name}
        </div>
        <div style={{ fontSize: '25px' }}>{amount}</div>
      </div>
      {reportType != '' && (
        <Report
          isOpen={true}
          currentMonth={month}
          reportType={reportType}
          data={reportData}
          onModalClose={onModalClose}
        />
      )}
    </div>
  );
};

export default ReportBotton;
