import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import medicalDegrees from '../../../assets/constants/medicalDegrees';
import specialities from '../../../assets/constants/specialities';
import medicalDegreeIcon from '../../../assets/img/medical-degree.svg';
import nameIcon from '../../../assets/img/personal.svg';
import specialityIcon from '../../../assets/img/speciality.svg';
import apiService from '../../../services/api';
import Button from '../../UI/Form/Button/Button';
import NameInput from '../../UI/Form/Name/NameInput';
import NumberInput from '../../UI/Form/NumberInput/NumberInput';
import Select from '../../UI/Form/Select/Select';
import formValidationSchema from './FormValidation';
import PasswordModal from './PasswordModal/PasswordModal';
import './ProfileForm.scss';

type Props = {
  onDataChange: (payload: { type: string; data: any }) => void;
  personalData: any;
};

const ProfileForm: React.FC<Props> = ({ onDataChange, personalData }) => {
  const { t, i18n } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const onchangeDataHandler = (value: string, name: string) => {
    const payload = { type: name, data: value };
    onDataChange(payload);
  };

  const changePassword = async (payload: any) => {
    try {
      // console.log('payload', payload);
      setIsChangePassword(true);
      await apiService.updateUserPassword(payload);
      setIsChangePassword(false);
      closeModal();
    } catch {
      setIsChangePassword(false);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="profile-form d-flex flex-column">
      {openModal && (
        <PasswordModal
          isOpen={openModal}
          isChangePassword={isChangePassword}
          confirmHandler={async (data: any) => {
            // console.log('confirmHandler', data);
            await changePassword(data);
          }}
          onModalClose={closeModal}
        />
      )}
      <div className="profile-form-container align-items-center d-flex flex-column">
        <Formik
          initialValues={personalData}
          validationSchema={formValidationSchema(t)}
          enableReinitialize
          onSubmit={(values) => {
            // console.log('values', values);
            const vv = formValidationSchema(t).cast(values);
            onDataChange({
              data: vv,
              type: '',
            });
          }}
        >
          {({ handleSubmit }: FormikProps<any>) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="p-4">
              <div className="d-flex flex-column mt-3 mt-sm-0 password-container">
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-3">
                    <img src={nameIcon} alt="mobile icon" />
                    <h6>{t('complete_profile.name')}</h6>
                  </div>
                  <NameInput
                    name="firstName"
                    placeholder={t('complete_profile.firstName')}
                    inputChange={(value) =>
                      onchangeDataHandler(value, 'firstName')
                    }
                  />
                </div>
                <div className="d-flex flex-column align-self-stretch">
                  <NameInput
                    name="lastName"
                    placeholder={t('complete_profile.lastName')}
                    inputChange={(e) => onchangeDataHandler(e, 'lastName')}
                  />
                </div>
              </div>
              <div className="password-container d-flex flex-column mt-3">
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-3">
                    <img src={specialityIcon} alt="mobile icon" />
                    <h6>{t('register_page.specialty')}</h6>
                  </div>
                  <Select
                    label={t('register_page.specialty')}
                    name="specialty"
                    options={Object.keys(specialities).map((key) => ({
                      label:
                        i18n.language === 'ar'
                          ? specialities[key].ar
                          : specialities[key].en,
                      value: key,
                    }))}
                    onchangeData={(e) => onchangeDataHandler(e, 'specialty')}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-3">
                    <img src={medicalDegreeIcon} alt="mobile icon" />
                    <h6>{t('register_page.med-degree')}</h6>
                  </div>
                  <Select
                    label={t('register_page.med-degree')}
                    name="medicalDegree"
                    options={Object.keys(medicalDegrees).map((key) => ({
                      label:
                        i18n.language === 'ar'
                          ? medicalDegrees[key].ar
                          : medicalDegrees[key].en,
                      value: key,
                    }))}
                    onchangeData={(e) =>
                      onchangeDataHandler(e, 'medicalDegree')
                    }
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-3">
                    <img src={medicalDegreeIcon} alt="mobile icon" />
                    <h6>{t('register_page.license-degree')}</h6>
                  </div>
                  <NumberInput
                    type="text"
                    placeholder={t('register_page.license-degree-placeholder')}
                    name="licenseNumber"
                    inputChange={(value) =>
                      onchangeDataHandler(value, 'licenseNumber')
                    }
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
        <Button
          name={t('register_page.changePassword')}
          saveData={() => {
            setOpenModal(!openModal);
          }}
        />
      </div>
    </div>
  );
};

export default ProfileForm;
