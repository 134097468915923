import React, { useState, useEffect } from 'react';
import './dentalServices.scss';
import dentalCategories from '../../assets/constants/dentalServices';
import DentalServiceModal from './dentalServiceModal/dentalServiceModal';
const DentalService = () => {
  const [categories, setCategories] = useState<any>(dentalCategories);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedSubField, setSelectedSubField] = useState<any>({});
  useEffect(() => {
    dentalCategories.forEach(
      (_: any, i: number) => (dentalCategories[i].isExpanded = false)
    );
  }, []);
  const categoryHandler = (c: any) => {
    setSelectedCategory(c);
    setSelectedSubField({});
    categories.map((category: any) =>
      category.type == c.type
        ? (category.isExpanded = true)
        : (category.isExpanded = false)
    );
    setCategories([...categories]);
  };
  return (
    <div className="dental-services-page-container">
      <DentalServiceModal
        isOpen={isServiceModalOpen}
        closeModal={() => setIsServiceModalOpen(false)}
      />

      <div className="dental-services-categories-section">
        <h1>Categories</h1>
        {categories.map((c: any) => (
          <div key={c.type}>
            <p
              style={{
                color: selectedCategory.type == c.type ? '#0090FF' : '#646464',
              }}
              className="categoryField"
              onClick={() => categoryHandler(c)}
            >
              {c.name}
            </p>
            {c.isExpanded &&
              c.services.map((s: any) => (
                <div
                  key={s.type}
                  onClick={() => {
                    setSelectedSubField(s);
                  }}
                >
                  <p
                    className="subfield"
                    style={{
                      color:
                        selectedSubField.type == s.type ? '#0090FF' : '#646464',
                    }}
                  >
                    {s.name}
                  </p>
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="dental-services-services-section">
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Price</th>
              <th>Steps</th>
              <th>
                <div className="table-btn-cell">
                  <div
                    className="add-service-btn"
                    onClick={() => setIsServiceModalOpen(true)}
                  >
                    Add
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(selectedSubField).length > 0 && (
              <tr>
                <td>{selectedSubField.name}</td>
                <td>120</td>
                <td>
                  {selectedSubField.steps && selectedSubField.steps.join(',')}
                </td>
                <td>
                  {' '}
                  <div className="service-section-body-actions">
                    <div className="delete-btn">Delete</div>
                    <div className="edit-btn">Edit</div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DentalService;
