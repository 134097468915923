import React from 'react';
import './Select.scss';
import { useField } from 'formik';
import arrowIcon from '../../../../assets/img/arrow-down.svg';

type InputProps = {
  name: string;
  options: any;
  disabled?: any;
  label?: string;
  onchangeData?: (event: any, name?: string) => void;
};

const Select: React.FC<InputProps> = ({
  options,
  label,
  disabled,
  onchangeData,
  ...props
}) => {
  const [field, meta] = useField(props);
  let selectData = { ...field };
  if (onchangeData) {
    selectData = {
      ...selectData,
      onChange: (e: any) => {
        onchangeData(e.target.value, field.name);
      },
    };
  }
  return (
    <div className="select-container mt-3 mt-sm-0">
      <select {...selectData} disabled={disabled}>
        <option value=""></option>
        {options.map((option: any) => (
          <option value={option.value} key={`option-${option.value}`}>
            {option.label}
          </option>
        ))}
      </select>
      <img className="arrow" src={arrowIcon} alt="arrowIcon" />
      <div className="error d-flex align-items-center">
        {meta.touched && meta.error ? (
          <span className="error">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

export default Select;
