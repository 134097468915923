import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  DELETE_FILE,
  REMOVE_FILES,
  CLEAR_BOOKING_FILES,
  SET_FILES_SENT,
  RESET_UPLOADER,
} from '../actions/actionTypes';

const initialState: any = {
  files: [],
};

export default function uploader(state = initialState, action: any): any {
  let files;
  switch (action.type) {
    case UPLOAD_FILE:
      files = [...state.files, action.payload];
      return { ...state, files };

    case UPLOAD_FILE_SUCCESS:
      const index = state.files.findIndex(
        (f: any) => f.id === action.payload.id
      );
      files = [...state.files];
      files[index] = action.payload;
      // console.log(files);

      return { ...state, files };

    case REMOVE_FILES:
      // eslint-disable-next-line
      const data = state.files.filter((f: any) => {
        if (
          !(
            f.type === action.payload.type &&
            f.test === action.payload.test &&
            f.booking === action.payload.booking
          )
        )
          return f;
        else {
          if (f.status === 'pending') {
            if (typeof f.cancelToken !== typeof undefined) {
              f.cancelToken.cancel('Operation canceled due to new request.');
            }
          }
        }
      });

      return { ...state, files: data };

    case DELETE_FILE:
      return { ...state };

    case CLEAR_BOOKING_FILES:
      files = state.files.filter((f: any) => !(f.booking === action.payload));
      return { ...state, files };
    case SET_FILES_SENT:
      files = state.files.map((file: any) => {
        if (file.booking === action.payload.booking)
          return { ...file, sent: true };
        else return file;
      });
      return { ...state, files };
    case RESET_UPLOADER:
      return { ...state, files: [] };
    default:
      return state;
  }
}
