import * as Yup from 'yup';

const formValidationSchema = (t: any) => {
  return Yup.object().shape({
    password: Yup.string()
      .required(t('booking_page.required'))
      .min(8, t('booking_page.valid-8')),
  });
};

export default formValidationSchema;
