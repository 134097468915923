import React, { useState, useEffect } from 'react';
import './ExpModal.scss';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../UI/Form/Button/Button';
import backIcon from '../../../../assets/img/close-icon.svg';
import arrowIcon from '../../../../assets/img/arrow-down.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50rem',
    minHeight: '35rem',
  },
};

type Props = {
  isOpen: boolean;
  isEdit?: boolean;
  updatedExpense?: any;
  onModalClose: () => void;
  addExpHandler: (name: string, amount: string) => void;
  editExpHandler?: (name: string, amount: string) => void;
};

const MapModal: React.FC<Props> = ({
  isOpen,
  onModalClose,
  addExpHandler,
  editExpHandler,
  isEdit,
  updatedExpense,
}) => {
  const [typeValue, setTypeValue] = useState('');
  const [typeOtherValue, setTypeOtherValue] = useState('');
  const [priceValue, setPriceValue] = useState('');
  const [other, setOther] = useState(false);

  const { t, i18n } = useTranslation();

  const employeePayment: any = {
    rent: { en: 'Rent', ar: 'تأجير' },
    electricity: { en: 'Electricity', ar: 'كهرباء' },
    salaries: { en: 'Salaries', ar: 'رواتب' },
    maintenance: { en: 'Maintenance', ar: 'اعمال صيانة' },
    installments: { en: 'Installments', ar: 'أقساط' },
  };

  useEffect(() => {
    if (isEdit) {
      setTypeValue(updatedExpense?.name);
      setPriceValue(updatedExpense?.amount);
    }
  }, [isEdit]);

  const cancelHandler = () => {
    onModalClose();
  };

  const setInputHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'type') {
      setTypeValue(value);
      if (value === 'other') {
        setOther(true);
      } else {
        setOther(false);
      }
    } else if (name === 'price') {
      if (Math.sign(parseInt(value)) >= 0 || value === '') {
        setPriceValue(value);
      }
    } else if (name === 'other') {
      setTypeOtherValue(value);
    }
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      contentLabel="modal"
      style={customStyles}
      onRequestClose={onModalClose}
    >
      <div dir={i18n.dir()} className="expModal-container d-flex flex-column">
        <div className="title d-flex justify-content-between">
          <div>
            <h5>{t('employess_page.expenses')}</h5>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={cancelHandler}>
            <img src={backIcon} style={{ height: '15px' }} alt="" />
          </div>
        </div>
        <div className="input d-flex mt-5 justify-content-between">
          <div className="passwordInput" style={{ width: '50%' }}>
            <select name="type" value={typeValue} onChange={setInputHandler}>
              <option value="" hidden>
                {t('employess_page.type')}
              </option>
              {Object.keys(employeePayment).map((key) => (
                <option key={key} value={key}>
                  {employeePayment[key][i18n.language]}
                </option>
              ))}
              <option value="other">Other...</option>
            </select>
            <img
              className="arrow"
              style={
                i18n.dir() === 'ltr'
                  ? { top: '30%', right: '10%' }
                  : { top: '30%', left: '10%' }
              }
              src={arrowIcon}
              alt="arrowIcon"
            />
          </div>
          <div className="passwordInput" style={{ width: '40%' }}>
            <input
              name="price"
              type="text"
              value={priceValue}
              autoComplete="off"
              placeholder={t('setting_page.price')}
              onChange={setInputHandler}
            />
          </div>
        </div>
        {other && (
          <div className="input mt-2 d-flex justify-content-between">
            <div className="passwordInput">
              <input
                name="other"
                type="text"
                value={typeOtherValue}
                autoComplete="off"
                placeholder={t('setting_page.title')}
                onChange={setInputHandler}
                style={{ width: '161px' }}
              />
            </div>
          </div>
        )}
        <div className="confirm align-self-stretch d-flex">
          <Button
            saveData={() => {
              isEdit && editExpHandler
                ? editExpHandler(other ? typeOtherValue : typeValue, priceValue)
                : addExpHandler(other ? typeOtherValue : typeValue, priceValue);
            }}
            name={isEdit ? 'Edit' : t('addClinic_page.done')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;
