import { ReactComponent as T1 } from '../../../assets/img/childTeeth/T1.svg';
import { ReactComponent as T2 } from '../../../assets/img/childTeeth/T2.svg';
import { ReactComponent as T3 } from '../../../assets/img/childTeeth/T3.svg';
import { ReactComponent as T4 } from '../../../assets/img/childTeeth/T4.svg';
import { ReactComponent as T5 } from '../../../assets/img/childTeeth/T5.svg';
import { ReactComponent as T6 } from '../../../assets/img/childTeeth/T6.svg';
import { ReactComponent as T7 } from '../../../assets/img/childTeeth/T7.svg';
import { ReactComponent as T8 } from '../../../assets/img/childTeeth/T8.svg';
import { ReactComponent as T9 } from '../../../assets/img/childTeeth/T9.svg';
import { ReactComponent as T10 } from '../../../assets/img/childTeeth/T10.svg';
import { ReactComponent as T11 } from '../../../assets/img/childTeeth/T11.svg';
import { ReactComponent as T12 } from '../../../assets/img/childTeeth/T12.svg';
import { ReactComponent as T13 } from '../../../assets/img/childTeeth/T13.svg';
import { ReactComponent as T14 } from '../../../assets/img/childTeeth/T14.svg';
import { ReactComponent as T15 } from '../../../assets/img/childTeeth/T15.svg';
import { ReactComponent as T16 } from '../../../assets/img/childTeeth/T16.svg';
import { ReactComponent as T17 } from '../../../assets/img/childTeeth/T17.svg';
import { ReactComponent as T18 } from '../../../assets/img/childTeeth/T18.svg';
import { ReactComponent as T19 } from '../../../assets/img/childTeeth/T19.svg';
import { ReactComponent as T20 } from '../../../assets/img/childTeeth/T20.svg';
const childTeethUpper = [
  {
    id: 1,
    teethImg: T1,
    position: [120, 120],
    titlePosition: [130, 170],
    size: [50, 50],
  },
  {
    id: 2,
    teethImg: T2,
    position: [85, 120],
    titlePosition: [95, 165],
    size: [40, 40],
  },
  {
    id: 3,
    teethImg: T3,
    position: [50, 110],
    titlePosition: [50, 155],
    size: [40, 40],
  },
  {
    id: 4,
    teethImg: T4,
    position: [25, 95],
    titlePosition: [10, 120],
    size: [35, 35],
  },
  {
    id: 5,
    teethImg: T5,
    position: [10, 60],
    titlePosition: [-5, 75],
    size: [40, 40],
  },
  {
    id: 6,
    teethImg: T6,
    position: [10, 20],
    titlePosition: [-5, 30],
    size: [40, 40],
  },
  {
    id: 7,
    teethImg: T7,
    position: [25, -10],
    titlePosition: [15, -10],
    size: [35, 35],
  },
  {
    id: 8,
    teethImg: T8,
    position: [50, -30],
    titlePosition: [55, -45],
    size: [40, 40],
  },
  {
    id: 9,
    teethImg: T9,
    position: [85, -45],

    titlePosition: [95, -60],
    size: [40, 40],
  },
  {
    id: 10,
    teethImg: T10,
    position: [120, -60],
    titlePosition: [130, -75],
    size: [50, 50],
  },
];
const childTeethLower = [
  {
    id: 11,
    teethImg: T11,
    position: [180, -60],
    titlePosition: [210, -75],
    size: [50, 50],
  },
  {
    id: 12,
    teethImg: T12,
    position: [225, -45],
    titlePosition: [240, -65],
    size: [40, 40],
  },
  {
    id: 13,
    teethImg: T13,
    position: [260, -30],
    titlePosition: [280, -50],
    size: [40, 40],
  },
  {
    id: 14,
    teethImg: T14,
    position: [290, -10],
    titlePosition: [320, -15],
    size: [35, 35],
  },
  {
    id: 15,
    teethImg: T15,
    position: [295, 20],
    titlePosition: [340, 30],
    size: [40, 40],
  },
  {
    id: 16,
    teethImg: T16,
    position: [295, 60],
    titlePosition: [340, 80],
    size: [40, 40],
  },
  {
    id: 17,
    teethImg: T17,
    position: [280, 95],
    titlePosition: [320, 120],
    size: [40, 40],
  },
  {
    id: 18,
    teethImg: T18,
    position: [250, 110],
    titlePosition: [270, 155],
    size: [40, 40],
  },
  {
    id: 19,
    teethImg: T19,
    position: [215, 120],
    titlePosition: [230, 165],
    size: [40, 40],
  },
  {
    id: 20,
    teethImg: T20,
    position: [170, 120],
    titlePosition: [190, 170],

    size: [50, 50],
  },
];
export { childTeethUpper, childTeethLower };
