export const groupByCode = (data: any = []) => {
  const cc: any = {};
  data.forEach((element: any) => {
    const c: any = element.code.split('&')[0].split('.')[0];
    // console.log(c[0]);
    if (!cc[c]) cc[c] = { items: [] };
    if (element.code === c) cc[c].item = element;
    else cc[c].items.push(element);
  });
  return cc;
};
