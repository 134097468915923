import { fLog } from './../../types';
import { addDayToDate } from '../../utils/moment';
import { getStoredData } from '../../utils/storage';
import {
  SET_CLINICIAN_UNITS,
  SET_CURRENT_UNIT_DETAILS,
  SET_EMP_CURRENT_UNIT,
  RESET_BOOKING,
  SEARCH_PATIENTS,
  REMOVE_SEARCHED_PATIENTS,
  SHOW_ADD_PATIENTS_BUTTON,
  SET_CURRENT_PATIENT,
  PATIENT_PREVIOUS_BOOKINGS,
  SET_CLINIC_BOOKINGS,
  SET_CLINIC_BOOKINGS_PENDING,
  SET_CLINIC_TIME_SLOTS,
  SET_CURRENT_BOOKING,
  FOCUS_SEARCH_INPUT,
  SET_PATIENT_ORDERS,
  ADD_BOOKING,
  ADD_PENDING_BOOKING,
  CANCEL_BOOKING,
  SET_UNIT_EMPLOYEES,
  SET_UNIT_Expenses,
  SET_BOOKING_DATE,
  SET_BOOKING_END_DATE,
  SET_LOADING,
  SET_STATISTICS,
  SET_CURRENT_SLOT,
  SET_COLLAPSED_SLOTS,
  SET_SHOW_ALL,
  SET_SHOW_SOME_SLOTS,
  SET_ALLBOOKING,
  LOCK_NAV_BAR,
  SET_BOOKING_CURRENT_FINANCAIL_LOG,
} from '../actions/actionTypes';
import { bookingStateType } from '../actions/types';
// import { TimeSlotGroupType } from '../../types';

// import { getBookingTime } from '../../utils/moment';

// const startDate = new Date(new Date().toJSON().slice(0, 10)).getTime();
const startDate = new Date(
  new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toJSON()
    .slice(0, 10)
).getTime();
const endDate = new Date(addDayToDate(new Date(), 7)).setHours(2, 0, 0, 0);

const initialState: bookingStateType = {
  units: [],
  currentEmpUnit: getStoredData('currentUnit'),
  currentUnitDetails: null,
  searchedPatients: [],
  previousBookings: [],
  bookings: [],
  bookingsPending: [],
  bookingDate: startDate,
  endDate,
  patientOrders: [],
  unitEmployees: [],
  unitExpenses: [],
  timeSlots: null,
  currentBooking: null,
  currentFinancailLog: null,
  currentPatient: null,
  showPatientButton: false,
  isFocused: false,
  isLoading: false,
  statistics: [],
  currentSlot: '',
  collapsedSlots: [],
  showAll: true,
  someSlotsShown: false,
  allbooking: false,
  navBarLocked: false,
};

const addNewBooking = (state: bookingStateType, action: any) => {
  // const bookingDate = state.bookingDate;
  const bookings = [...state.bookings];
  // const groupedTimeSlots: TimeSlotGroupType = { ...state.timeSlots };

  const newBooking = action.payload;

  // if (new Date(newBooking.date).getDate() !== new Date(bookingDate).getDate())
  //   return state;

  const filteredBooking = bookings.findIndex((ele) => ele.sk === newBooking.sk);
  if (filteredBooking >= 0) {
    bookings[filteredBooking] = newBooking;
  } else {
    bookings.push(newBooking);
  }

  bookings.sort((a, b) => a.date - b.date);

  return { ...state, bookings };
};
const addNewPendingBooking = (state: bookingStateType, action: any) => {
  const bookings = [...state.bookingsPending];
  const newBooking = action.payload;
  if (bookings.length <= 0) {
    return { ...state, bookingsPending: [action.payload] };
  }
  bookings.push(newBooking);
  bookings.sort((a, b) => a.date - b.date);
  return { ...state, bookings };
};

const cancelBooking = (state: bookingStateType, action: any) => {
  const bookingDate = state.bookingDate;
  let bookings = [...state.bookings];
  // const groupedTimeSlots: TimeSlotGroupType = { ...state.timeSlots };

  const canceledBooking = action.payload.booking;
  const oldSk = action.payload.oldSk;

  if (
    new Date(canceledBooking.date).getDate() !== new Date(bookingDate).getDate()
  ) {
    return state;
  }

  bookings = bookings.filter((ele) => ele.sk !== oldSk + '');

  bookings.sort((a, b) => a.date - b.date);
  // currentBooking = bookings[0];

  return { ...state, bookings };
};

const resetBooking = (state: bookingStateType) => {
  return {
    ...state,
    units: [],
    currentEmpUnit: null,
    currentUnitDetails: null,
    currentPatient: null,
    searchedPatients: [],
    previousBookings: [],
    bookings: [],
    bookingDate: startDate,
    endDate,
    patientOrders: [],
    timeSlots: null,
    unitEmployees: [],
    currentBooking: null,
    currentFinancailLog: null,
    showPatientButton: false,
    isFocused: false,
    isLoading: false,
    statistics: [],
    currentSlot: '',
  };
};

export default function booking(
  state = initialState,
  action: any
): bookingStateType {
  switch (action.type) {
    case SET_CLINICIAN_UNITS:
      return { ...state, units: action.payload };
    case SET_EMP_CURRENT_UNIT:
      return { ...state, currentEmpUnit: action.payload };
    case SET_CURRENT_UNIT_DETAILS:
      return { ...state, currentUnitDetails: action.payload };
    case SEARCH_PATIENTS:
      return {
        ...state,
        searchedPatients: action.payload,
        showPatientButton: false,
      };
    case SET_CURRENT_PATIENT:
      return { ...state, currentPatient: action.payload };
    case REMOVE_SEARCHED_PATIENTS:
      return { ...state, searchedPatients: [], showPatientButton: false };
    case SHOW_ADD_PATIENTS_BUTTON:
      return { ...state, showPatientButton: true };
    case SET_PATIENT_ORDERS:
      return { ...state, searchedPatients: [], patientOrders: action.payload };
    case SET_CLINIC_TIME_SLOTS:
      return { ...state, timeSlots: action.payload };
    case SET_CLINIC_BOOKINGS:
      return { ...state, bookings: action.payload };
    case SET_CLINIC_BOOKINGS_PENDING:
      return { ...state, bookingsPending: action.payload };
    case SET_UNIT_EMPLOYEES:
      return { ...state, unitEmployees: action.payload };
    case SET_UNIT_Expenses:
      return { ...state, unitExpenses: action.payload };
    case SET_BOOKING_DATE:
      return { ...state, bookingDate: action.payload };
    case SET_BOOKING_END_DATE:
      return { ...state, endDate: action.payload };
    case ADD_BOOKING:
      return addNewBooking(state, action);
    case ADD_PENDING_BOOKING:
      return addNewPendingBooking(state, action);
    case CANCEL_BOOKING:
      return cancelBooking(state, action);
    case PATIENT_PREVIOUS_BOOKINGS:
      return { ...state, previousBookings: action.payload };
    case SET_CURRENT_BOOKING:
      return { ...state, currentBooking: action.payload };
    case SET_BOOKING_CURRENT_FINANCAIL_LOG:
      return {
        ...state,
        currentFinancailLog: {
          ...state.currentFinancailLog,
          ...action.payload,
        },
      };
    case FOCUS_SEARCH_INPUT:
      return { ...state, isFocused: action.payload };
    case SET_STATISTICS:
      return { ...state, statistics: action.payload };
    case RESET_BOOKING:
      return resetBooking(state);
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_CURRENT_SLOT:
      return { ...state, currentSlot: action.payload };
    case SET_COLLAPSED_SLOTS:
      return { ...state, collapsedSlots: action.payload };
    case SET_SHOW_ALL:
      return { ...state, showAll: action.payload };
    case SET_SHOW_SOME_SLOTS:
      return { ...state, someSlotsShown: action.payload };
    case SET_ALLBOOKING:
      return { ...state, allbooking: action.payload };
    case LOCK_NAV_BAR:
      return { ...state, navBarLocked: action.payload };
    default:
      return state;
  }
}
