import React from 'react';
import WithUnit from '../../hocs/WithUnit';
import AddBooking from './AddBooking';

const AddBookingPage: React.FC = () => {
  return (
    <WithUnit>
      <AddBooking />{' '}
    </WithUnit>
  );
};
export default AddBookingPage;
