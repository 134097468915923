import React, { useState } from 'react';
import './dentalServiceModal.scss';
type Props = {
  isOn: boolean;
  handleToggle: any;
};
const Switch: React.FC<Props> = ({ isOn, handleToggle }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label className="react-switch-label" htmlFor={`react-switch-new`}>
        <span
          className={`react-switch-button`}
          style={{ background: isOn ? '#0090FF' : '#fff' }}
        />
      </label>
    </>
  );
};
export default Switch;
