import * as Yup from 'yup';

const formValidationSchema = (t: any) => {
  return Yup.object().shape({
    password: Yup.string()
      .required(t('booking_page.required'))
      .min(8, t('changePassword_page.password-validation-8')),
    confirmPassword: Yup.string()
      .required(t('booking_page.required'))
      .oneOf(
        [Yup.ref('password'), null],
        t('changePassword_page.password-match')
      ),
  });
};

export default formValidationSchema;
