import { ClinicData } from '../types';
import { colors, statisticsColors } from './getRandomColor';
import unitTypes from './unitTypes';

export const getBookingColor = (
  { type }: any,
  currentUnitDetails?: ClinicData | null
) => {
  // console.log('type', type);
  if (!currentUnitDetails || !type) return 'fff';
  else if (type === 'normal') return colors.normal;
  else if (type === 'followUp') return colors.followUp;
  else if (type === 'urgent') return colors.urgent;
  else if (
    !['normal', 'followUp', 'urgent'].includes(type) &&
    currentUnitDetails.type === unitTypes.clinic
  )
    return currentUnitDetails.examinationTypes[type].color || '#ffffff';
  else return '#ffffff';
};
export const getBookingStatusColor = (status: string) => {
  if (status === 'completed') return colors.completed;
  else if (status === 'hold') return colors.hold;
  else if (status === 'active') return colors.active;
  else if (status === 'canceled') return colors.canceled;
  else return '#ffffff';
};

export const getBookingGroupedBySlot = (
  bookings: any = [],
  all: boolean = true
) => {
  const slots: any = {};
  bookings.forEach((element: any) => {
    if (!all && element.status === 'completed') return;
    const date = new Date(element.date);
    const [, time, p] = date.toLocaleString().split(' ');
    const hour = time.split(':')[0] + ':00 ' + p;
    if (!slots[hour]) slots[hour] = [];
    slots[hour].push(element);
  });

  return slots;
};

export const isAllFilesSent = (booking: any, files: any[]) => {
  const bookingFiles = files.filter((file: any) => file.booking === booking.sk);
  return (
    booking &&
    booking.tests.some((test: any) => {
      return (
        (test.reports.status && test.reports.status === 'error') ||
        (test.results?.status && test.results?.status === 'error')
      );
    }) &&
    bookingFiles.every((file: any) => file.sent)
  );
};

export const getSatisticsColor = (
  { type }: any,
  currentUnitDetails?: ClinicData | null
) => {
  // console.log('type', type);
  if (!currentUnitDetails || !type) return 'fff';
  else if (type === 'normal') return statisticsColors.normal;
  else if (type === 'followUp') return statisticsColors.followUp;
  else if (type === 'urgent') return statisticsColors.urgent;
  else if (
    !['normal', 'followUp', 'urgent'].includes(type) &&
    currentUnitDetails.type === unitTypes.clinic
  )
    return currentUnitDetails.examinationTypes[type].color || '#ffffff';
  else return '#ffffff';
};
