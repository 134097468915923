import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';

type PasswordInputProps = {
  iconClick?: () => void;
  name: string;
  type?: string;
  placeholder: any;
  icon?: string;
  isIcon?: boolean;
  autoComplete?: string;
};

const PasswordInput: React.FC<PasswordInputProps> = ({
  iconClick,
  name,
  placeholder,
  icon,
  isIcon,
  type,
  autoComplete,
}) => {
  const { i18n } = useTranslation();
  return (
    <Input
      type={type || 'password'}
      name={name}
      placeholder={placeholder}
      icon={icon}
      className={
        i18n.language === 'ar'
          ? ['placeholder-right'].join(' ')
          : ['num-left', 'placeholder-right'].join(' ')
      }
      isIcon={isIcon}
      iconClick={iconClick}
      autoComplete={autoComplete}
    />
  );
};
export default PasswordInput;
