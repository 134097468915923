const parseArabicNumber = (str: any) => {
  return str
    .replace(/[\u0621-\u064Aa-zA-Z\s$&+,:;=?@#|'<>.^*()%!-]$/g, (d: string) => {
      return '';
    })
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d: string) => {
      return d.charCodeAt(0) - 1632; // Convert Arabic numbers
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d: string) => {
      return d.charCodeAt(0) - 1776; // Convert Persian numbers
    });
};

export default parseArabicNumber;
