import React from 'react';
import WithUnit from '../../hocs/WithUnit';
import AddPatient from './AddPatient';

const AddPatientPage: React.FC = () => {
  return (
    <WithUnit>
      <AddPatient />
    </WithUnit>
  );
};
export default AddPatientPage;
