import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import roles, {
  UserRoles,
  paymentRoles,
  employeePayment,
} from '../../../../assets/constants/roles';

import userIcon from '../../../../assets/img/personal.svg';
import mobileIcon from '../../../../assets/img/mobile.svg';
import roleIcon from '../../../../assets/img/role.svg';
import closeIcon from '../../../../assets/img/close.svg';
import { mobileRegex } from '../../../../assets/constants/regexValues';
import parseArabic from '../../../../utils/parseArabic';
import unitTypes from '../../../../utils/unitTypes';

type Props = {
  name: string;
  index: number;
  initEmp: any;
  setInputHandler: (
    name: string,
    keyName: string,
    value: string | null
  ) => void;
  removeEmployeeHandler: (name: number) => void;
  unitType: string;
};

const AddSingleEmployee: React.FC<Props> = ({
  name,
  index,
  initEmp,
  setInputHandler,
  removeEmployeeHandler,
  unitType,
}) => {
  const { t, i18n } = useTranslation();
  const [mobileError, setMobileError] = useState('');
  const [employeeRoles, setEmployeeRoles] = useState<any>({});
  const [selectValue, setSelectValue] = useState(() => {
    const initialRole =
      initEmp && initEmp.role ? initEmp.role : UserRoles.Assistant;
    return initialRole;
  });
  const [selectPaymentValue, setSelectPaymentValue] = useState(() => {
    const initialpayment =
      initEmp && initEmp.payment ? initEmp.payment : paymentRoles.Monthly;
    return initialpayment;
  });

  const [mobileValue, setMobileValue] = useState(
    initEmp && initEmp.mobile ? initEmp.mobile : ''
  );
  const [firstNameValue, setFirstNameValue] = useState(
    initEmp && initEmp.name.firstName ? initEmp.name.firstName : ''
  );
  const [lastNameValue, setLastNameValue] = useState(
    initEmp && initEmp.name.lastName ? initEmp.name.lastName : ''
  );
  const [salaryValue, setSalaryValue] = useState(
    initEmp && initEmp.salary ? initEmp.salary : ''
  );

  // const inputValueProps =
  //   initEmp && initEmp.mobile ? { value: initEmp.mobile } : {};
  // let mobilNu = initEmp && initEmp.mobile ? initEmp.mobile : '';

  const setInputHandlerr = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const nameInput = e.target.name;
    const value = e.target.value;
    const mobilNu = parseArabic(value);
    if (nameInput === 'firstName') {
      setFirstNameValue(value);
      setInputHandler(name, 'firstName', value);
    } else if (nameInput === 'lastName') {
      setLastNameValue(value);
      setInputHandler(name, 'lastName', value);
    } else if (nameInput === 'payment') {
      setSelectPaymentValue(value);
      setInputHandler(name, 'payment', value);
    } else if (nameInput === 'salary') {
      setSalaryValue(value);
      setInputHandler(name, 'salary', value);
    } else if (nameInput === 'role') {
      setSelectValue(value);
      setInputHandler(name, 'role', value);
    } else {
      setMobileValue(mobilNu);
      if (mobileRegex.test(mobilNu)) {
        setMobileError('');
        setInputHandler(name, 'mobile', mobilNu);
        setInputHandler(name, 'role', selectValue);
      } else {
        setMobileError(t('booking_page.valid-mobile'));
        setInputHandler(name, 'mobile', mobilNu);
        setSelectValue(UserRoles.Assistant);
      }
    }
  };

  useEffect(() => {
    let values = {};
    if (unitType === unitTypes.clinic) {
      const { assistant, receptionist, nurse } = roles;
      values = { assistant, receptionist, nurse };
    } else if (unitType === unitTypes.lab) {
      const { assistant, receptionist, nurse, labTech } = roles;
      values = { assistant, receptionist, nurse, labTech };
    } else if (unitType === unitTypes.image) {
      const { assistant, receptionist, nurse, radioTech } = roles;
      values = { assistant, receptionist, nurse, radioTech };
    }
    setEmployeeRoles(values);
  }, [unitType]);

  return (
    <div className="form d-flex flex-column flex-sm-row p-3">
      <div className="mobile d-flex flex-column">
        <div className="name-group d-flex align-items-center mb-2">
          <img src={userIcon} className="" alt="mobile icon" />
          <h6>{t('setting_page.firstName')}</h6>
        </div>
        <div className="inputs">
          <input
            autoComplete="off"
            type="text"
            name="firstName"
            onChange={setInputHandlerr}
            value={firstNameValue}
            // {...inputValueProps}
          />
        </div>
      </div>
      <div className="mobile d-flex flex-column">
        <div className="name-group d-flex align-items-center mb-2">
          <img src={userIcon} className="" alt="mobile icon" />
          <h6>{t('setting_page.lastName')}</h6>
        </div>
        <div className="inputs">
          <input
            autoComplete="off"
            type="text"
            name="lastName"
            onChange={setInputHandlerr}
            value={lastNameValue}
            // {...inputValueProps}
          />
        </div>
      </div>
      <div className="role d-flex flex-column">
        <div className="name-group d-flex align-items-center mb-2">
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <img src={roleIcon} alt="role icon" />
            <h6>{t('employess_page.emp-role')}</h6>
          </div>
        </div>
        <div className="inputs">
          <select name="role" value={selectValue} onChange={setInputHandlerr}>
            {Object.keys(employeeRoles).map((key) => (
              <option value={key} key={`option-${key}`}>
                {employeeRoles[key][i18n.language]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mobile d-flex flex-column">
        <div className="name-group mobile-icon d-flex align-items-center mb-2">
          <img src={mobileIcon} className="" alt="mobile icon" />
          <h6>{t('setting_page.mobile')}</h6>
        </div>
        <div className="inputs">
          <input
            autoComplete="off"
            type="number"
            name="mobile"
            onChange={setInputHandlerr}
            value={mobileValue}
            // {...inputValueProps}
          />
          <div className="error">
            {mobileError ? <span className="error">{mobileError}</span> : null}
          </div>
        </div>
      </div>
      <div className="role d-flex flex-column">
        <div className="name-group d-flex align-items-center mb-2">
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <h6>{t('employess_page.emp-payment')}</h6>
          </div>
        </div>
        <div className="inputs">
          <select
            name="payment"
            value={selectPaymentValue}
            onChange={setInputHandlerr}
          >
            {Object.keys(employeePayment).map((key) => (
              <option value={key} key={`option-${key}`}>
                {employeePayment[key][i18n.language]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mobile d-flex flex-column">
        <div className="name-group d-flex align-items-center mb-2">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ flex: 1 }}
          >
            <h6>{t('employess_page.emp-salary')}</h6>
            {index > 0 && (
              <div className="close-icon">
                <img
                  src={closeIcon}
                  alt="close icon"
                  onClick={() => {
                    removeEmployeeHandler(index);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="inputs">
          <input
            autoComplete="off"
            type="number"
            name="salary"
            onChange={setInputHandlerr}
            value={salaryValue}
            // {...inputValueProps}
          />
        </div>
      </div>
    </div>
  );
};

export default AddSingleEmployee;
