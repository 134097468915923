import React, { useEffect, useState } from 'react';
import VitalInput from '../VitalInputComponent/VitalInputComponent';

import '../AllInputComponent.scss';
import { indicator } from '../../../../../utils/indicator';

const FastBloodGlucoseInput = (props: any) => {
  const { onChange, values } = props;
  const [status, setStatus] = useState('Normal');

  useEffect(() => {
    setStatus(indicator(values.fastBloodGlucose, 0, 199));
  }, [values]);

  return (
    <div className="col-md-12">
      <div className="form-group row vital-item">
        <label className="col-sm-3 col-form-label">
          Fast Blood <br></br>Glucose:
        </label>
        <div className="col-sm-2">
          <VitalInput
            className="form-control"
            name={'fastBloodGlucose'}
            value={values.fastBloodGlucose || ''}
            type="number"
            setStatus={(s: string) => {
              setStatus(s);
            }}
            onChange={(name: string, value: string) => {
              onChange(name, value);
            }}
          />
        </div>
        <div className="col-sm-1 ext mr-auto">
          <span className="span-bottom">mg/dl</span>
        </div>
        {values.fastBloodGlucose && (
          <div className="col-sm-1">
            <i className={`${status} fas fa-flag`}></i>
          </div>
        )}
        {values.fastBloodGlucose && (
          <div className={`${status} col-sm-1`}>
            {status === 'High' ? 'diabetes' : status}
          </div>
        )}
      </div>
    </div>
  );
};

export default FastBloodGlucoseInput;
