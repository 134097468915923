const months: any = {
  january: { ar: 'يناير', en: 'January' },
  february: { ar: 'فبراير', en: 'February' },
  march: { ar: 'مارس', en: 'March' },
  april: { ar: 'أبريل', en: 'April' },
  may: { ar: 'مايو', en: 'May' },
  june: { ar: 'يونيو', en: 'June' },
  july: { ar: 'يوليو', en: 'July' },
  august: { ar: 'أغسطس', en: 'August' },
  september: { ar: 'سبتمبر', en: 'September' },
  october: { ar: 'أكتوبر', en: 'October' },
  november: { ar: 'نوفمبر', en: 'November' },
  december: { ar: 'ديسمبر', en: 'December' },
};

export default months;
