import React, { useState } from 'react';
import './CompleteProfileForm.scss';

import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import formValidationSchema from './FormValidation';
import Button from '../../UI/Form/Button/Button';
import NameInput from '../../UI/Form/Name/NameInput';
import PasswordInput from '../../UI/Form/Password/PasswordInput';
import nameIcon from '../../../assets/img/personal.svg';
import passwordIcon from '../../../assets/img/lock.svg';
import eyeIcon from '../../../assets/img/eye.svg';
import backArrow from '../../../assets/img/back.svg';
import visibilityIcon from '../../../assets/img/visibility.svg';
import EventTracker from '../../../utils/analytics';

type Props = {
  nextStepHandler: (payload: { type: string; data: any }) => void;
  currentSegment: string;
  personalData: any;
  goBack: () => void;
};

const CompleteProfileForm: React.FC<Props> = ({
  nextStepHandler,
  currentSegment,
  personalData,
  goBack,
}) => {
  const { t } = useTranslation();
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] = useState(
    false
  );

  const iconClickHandler = (name: string) => {
    if (name === 'password') {
      setShouldShowPassword((showPassword) => !showPassword);
    } else {
      setShouldShowConfirmPassword((showPassword) => !showPassword);
    }
  };

  return (
    <div className="complete-profile-form d-flex flex-column w-100 p-0 p-sm-5 mt-4 mt-sm-0">
      <div className="name d-flex justify-content-between">
        <p>{t('pages.register')}</p>
      </div>
      <div className="complete-profile-form-container align-items-center d-flex flex-column mt-4">
        <Formik
          initialValues={personalData}
          validationSchema={formValidationSchema(t)}
          enableReinitialize
          onSubmit={async (values) => {
            const vv = formValidationSchema(t).cast(values);
            EventTracker.sendEvent('SET_PERSONAL_DATA_BUTTON_CLICKED', {
              ...vv,
            });
            nextStepHandler({
              data: vv,
              type: currentSegment,
            });
          }}
        >
          {({ handleSubmit }: FormikProps<any>) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="d-flex flex-column flex-sm-row mt-3 mt-sm-0 password-container">
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-2">
                    <img src={nameIcon} alt="mobile icon" />
                    <h6>{t('complete_profile.name')}</h6>
                  </div>
                  <NameInput
                    name="firstName"
                    placeholder={t('complete_profile.firstName')}
                  />
                </div>
                <div className="d-flex flex-column align-self-stretch align-self-sm-end">
                  <NameInput
                    name="lastName"
                    placeholder={t('complete_profile.lastName')}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row mt-3 password-container">
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-2">
                    <img src={passwordIcon} alt="mobile icon" />
                    <h6>{t('complete_profile.password-name')}</h6>
                  </div>
                  <PasswordInput
                    type={shouldShowPassword ? 'text' : 'password'}
                    name="password"
                    placeholder={t('complete_profile.password')}
                    icon={shouldShowPassword ? eyeIcon : visibilityIcon}
                    isIcon={true}
                    iconClick={() => iconClickHandler('password')}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="name-group d-flex align-self-start mb-2">
                    <img src={passwordIcon} alt="mobile icon" />
                    <h6>{t('complete_profile.rewrite-password')}</h6>
                  </div>
                  <PasswordInput
                    type={shouldShowConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder={t('complete_profile.confirm-password')}
                    icon={shouldShowConfirmPassword ? eyeIcon : visibilityIcon}
                    isIcon={true}
                    iconClick={() => iconClickHandler('confirmPassword')}
                  />
                </div>
              </div>
              <div className="mt-3">
                <Button type="submit" name={t('complete_profile.next')} />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div
        className="back align-self-start"
        onClick={() => {
          goBack();
        }}
      >
        <img src={backArrow} alt="back" />
        <span>{t('otp_page.back')}</span>
      </div>
    </div>
  );
};

export default CompleteProfileForm;
