import React from 'react';
import { WithUnit } from '../../hocs';
import Reports from './Reports';

const ReportsPage: React.FC = () => {
  return (
    <WithUnit>
      <Reports />{' '}
    </WithUnit>
  );
};
export default ReportsPage;
