import React, { useState, useEffect, useReducer } from 'react';
import './Register.scss';

import { useHistory } from 'react-router-dom';
import routes from '../../assets/constants/routes';

import CheckUserForm from '../../components/CheckUser/CheckUser';
import RegisterToken from '../../components/Token/TokenForm';
import RegisterForm from '../../components/Register/RegisterForm/RegisterForm';
// import CheckProfile from '../../components/Register/CheckProfile/CheckProfile';
import ChangePasswordForm from '../../components/ChangePassword/ChangePasswordForm';
import apiService from '../../services/api';

import RegisterClinicDetails from '../../components/Layout/RegisterClinicDetails/RegisterClinicDetails';
import { isAuthenticated, storeData } from '../../utils/storage';
import tokenTypes from '../../assets/constants/tokenTypes';
import { useTranslation } from 'react-i18next';

import clinicImg from '../../assets/img/clinic2.png';
import naqabaImg from '../../assets/img/NeqabaID.png';
import mobile from '../../assets/img/mobile.svg';
import mobileActive from '../../assets/img/mobile-active.svg';
// import check from '../../assets/img/check.svg';
// import checkActive from '../../assets/img/check-active.svg';
import personal from '../../assets/img/personal.svg';
import personalActive from '../../assets/img/personal-active.svg';
import { errorToast } from '../../utils/toast';
import { setUserAuthenticated, getClinicianUnits } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { UserRoles } from '../../assets/constants/roles';
import { nationalIdRegex } from '../../assets/constants/regexValues';
import EventTracker from '../../utils/analytics';

type Action = {
  type: string;
  payload?: any;
};

const segmentsData = {
  mobile: 'mobile',
  token: 'token',
  password: 'password',
  personal: 'personal',
};

const initialState = {
  token: '',
  firstName: '',
  lastName: '',
  password: '',
  nationalNumber: '',
  nationalScan: '',
  profileImg: '',
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case segmentsData.token:
      return { ...state, token: action.payload };
    case segmentsData.mobile:
      return { ...state, mobile: action.payload };
    case segmentsData.personal:
      return { ...state, ...action.payload };
    case segmentsData.password:
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return { ...state, ...initialState };
    default:
      return state;
  }
};

const RegisterProfile: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [personalData, setPersonalDatae] = useState({
    firstName: '',
    lastName: '',
    medicalDegree: '',
    specialty: '',
    // licenseNumber: '',
    associationScan: '',
  });

  useEffect(() => {
    const changeLangHandler = (lang: string = 'en') => {
      i18n.changeLanguage(lang);
    };
    changeLangHandler('en');
    return () => changeLangHandler('ar');
  }, [i18n]);

  useEffect(() => {
    if (isAuthenticated()) history.push(routes.HOME);
  }, [history]);

  const [selectedSegment, setSelectedSegment] = useState(segmentsData.token);
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };
  // const [profileImg, setProfileImg] = useState<any>('');
  // const [nationalScan, setNationalScan] = useState<any>('');
  // const [nationalNumber, setNationalNumber] = useState<any>('');
  const [associationScan, setAssociationScan] = useState<any>('');
  // const [profilePower, setProfilePower] = useState<number>(0);
  // const [natIdPower, setNatIdPower] = useState<any>(0);
  // const [natImgPower, setNatImgPower] = useState<any>(0);
  // const [profileImgPower, setProfileImgPower] = useState<any>(0);
  // const [associationImgPower, setAssociationImgPower] = useState<any>(0);
  // const [uploading, setUploading] = useState<any>(false);
  const [shouldResendToken, setShouldResendToken] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isUploadProfile, setIsUploadProfile] = useState(false);
  // const [isUploadNat, setIsUploadNat] = useState(false);
  const [isUploadAssossiation, setIsUploadAssossiation] = useState(false);
  const [isCheckUser, setIsCheckUser] = useState(true);
  const [isPassword, setIsPassword] = useState(true);
  const [user, setUser] = useState({ mobile: '' });
  // const [profileLocalImg, setProfileLocalImg] = useState<any>('');
  // const [nationalScanLocalImg, setNationalScanLocalImg] = useState<any>('');
  const [associationScanImg, setAssociationScanImg] = useState<any>('');

  // useEffect(() => {
  //   setProfilePowerHandler(
  //     natIdPower + natImgPower + profileImgPower + associationImgPower
  //   );
  // }, [natIdPower, natImgPower, profileImgPower, associationImgPower]);

  // const setProfilePowerHandler = (value: number) => {
  //   setProfilePower(value);
  // };

  const getImage = (file: File, type: string) => {
    // console.log('file', file);
    if (!file || !file.type || !file.type.startsWith('image/')) {
      errorToast(t('register_page.invalid'));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (_e: any) => {
      if (type === 'association') {
        setAssociationScanImg(reader.result);
        setIsUploadAssossiation(true);
      }
      uploadFile(file, type);
    };
  };

  const uploadFile = async (file: any, type?: string) => {
    if (!file) return;
    // setUploading(true);
    const imgUrl = await apiService.upload(
      file,
      t('register_page.upload-img'),
      t('register_page.upload-img-done'),
      t('register_page.upload-img-err')
    );
    // setUploading(false);
    // console.log('imgUrl', imgUrl);
    if (!imgUrl) {
      if (type === 'association') {
        setIsUploadAssossiation(false);
      }
      return;
    }
    if (type === 'association') {
      setAssociationScan(imgUrl);
      setIsUploadAssossiation(false);
    }
  };

  const nextStepHandler = async (payload: { type: string; data: any }) => {
    // console.log('payload', payload);
    if (payload.type === segmentsData.token) {
      dispatch({ type: segmentsData.token, payload: payload.data });
      changeSegmentHandler(segmentsData.personal);
    } else if (payload.type === segmentsData.personal) {
      if (!payload.data.firstName || !payload.data.lastName) return;
      setPersonalDatae({ ...payload.data });
      dispatch({ type: segmentsData.personal, payload: payload.data });
    }
    // else {
    //   // setNationalNumber(payload.data.nationalNumber);
    //   // setNatIdPower(payload.data.power);
    // }
  };

  const registerHandler = async (values: any) => {
    try {
      const payload = {
        ...state,
        ...values,
        // nationalNumber,
        // nationalScan,
        // profileImg,
        associationScan,
      };
      if (
        (payload.nationalNumber && payload.nationalNumber.length !== 14) ||
        (payload.nationalNumber &&
          !nationalIdRegex.test(payload.nationalNumber))
      ) {
        errorToast(t('complete_profile.nat-invalid'));
        setIsSubmitting(false);
        return;
      }

      delete payload.confirmPassword;
      // console.log('payload', payload);
      EventTracker.sendEvent('COMPLETE_REGISTRATION', { ...payload });
      setIsSubmitting(true);
      const data = await apiService.register(payload);
      // console.log('data', data.data);
      setIsSubmitting(false);
      let storedData = {
        ...data.data,
      };
      const sMobile = payload.mobile.startsWith('+2')
        ? payload.mobile
        : `+2${payload.mobile}`;
      if (data.data.trustedDeviceToken) {
        storedData = {
          ...storedData,
          [`trustedDeviceToken-${sMobile}`]: data.data.trustedDeviceToken,
        };
      }
      delete storedData.trustedDeviceToken;
      storeData({ ...storedData });
      reduxDispatch(setUserAuthenticated());
      reduxDispatch(getClinicianUnits());
      if (data.data.user.role === UserRoles.Physician) {
        history.push(routes.SELECT_UNIT_TYPE);
      } else {
        history.push(routes.BOOKING);
      }
    } catch (error) {
      console.error('error[registerPhysician]', error);
      setIsSubmitting(false);
    }
  };

  const checkTokenHandler = async (values: any) => {
    if (!values.token || values.token.length !== 4) return;
    try {
      EventTracker.sendEvent('VERIFY_TOKEN_BUTTON_CLICKED', {
        mobile: user.mobile,
        tokenType: tokenTypes.registerToken,
      });

      setIsSubmitting(true);
      const tokenPayload = {
        ...values,
        mobile: user.mobile,
        tokenType: tokenTypes.registerToken,
      };
      const data = await apiService.checkToken(tokenPayload);
      // console.log('data', data.data);
      setIsSubmitting(false);
      nextStepHandler({
        data: data.data.token,
        type: segmentsData.token,
      });
    } catch (error) {
      console.error('error[checkToken]', error);
      setIsSubmitting(false);
    }
  };

  const resendTokenHandler = async () => {
    try {
      await apiService.requestToken({
        mobile: user.mobile,
        tokenType: tokenTypes.registerToken,
      });
      setShouldResendToken(true);
    } catch (error) {
      console.error('error[resendToken]', error);
      setShouldResendToken(true);
    }
  };

  const checkUserHandler = async (values: any) => {
    try {
      EventTracker.sendEvent('CHECK_USER_BUTTON_CLICKED', {
        mobile: values.mobile,
      });
      const payload = { ...values };
      const data = await apiService.checkUser(payload);
      // console.log('checkUserHandler', data.data);
      if (data.data.exist) {
        errorToast(data.data.msg);
      } else {
        await apiService.requestToken({
          mobile: payload.mobile,
          tokenType: tokenTypes.registerToken,
        });
        dispatch({ type: segmentsData.mobile, payload: values.mobile });
        setUser(values);
        setIsCheckUser(false);
      }
    } catch (error) {
      console.error('error[login]', error);
    }
  };

  const goBackHandler = () => {
    setIsCheckUser(true);
  };

  const goBackToPreviousSegment = () => {
    if (selectedSegment === segmentsData.personal && isPassword) {
      setSelectedSegment(segmentsData.token);
      setIsCheckUser(true);
    } else if (selectedSegment === segmentsData.personal && !isPassword) {
      setIsPassword(true);
    } else if (selectedSegment === segmentsData.token && !isCheckUser) {
      setIsCheckUser(true);
    } else {
      dispatch({ type: 'RESET_STATE' });
    }
  };

  const changePasswordhandler = async (payload: any) => {
    EventTracker.sendEvent('SET_PASSWORD_BUTTON_CLICKED', {
      mobile: user.mobile,
    });

    dispatch({ type: segmentsData.password, payload });
    setIsPassword(false);
  };

  return (
    <div className="register-page d-flex flex-column" dir={i18n.dir()}>
      <div className="segment mt-3">
        <ul>
          <li
            className={`base token mobile-icon ${
              selectedSegment === segmentsData.token ? 'selected' : ''
            }`}
          >
            <img
              className="d-none d-sm-block"
              src={
                selectedSegment === segmentsData.token ? mobileActive : mobile
              }
              alt="mobile"
            />
            {t('complete_profile.mobile')}
          </li>
          <li
            className={`base ${
              selectedSegment === segmentsData.personal ? 'selected' : ''
            }`}
          >
            <img
              className="d-none d-sm-block"
              src={
                selectedSegment === segmentsData.personal
                  ? personalActive
                  : personal
              }
              alt="personal"
            />
            {t('complete_profile.personal-data')}
          </li>
        </ul>
      </div>
      <div className="register-page__main d-flex flex-column-reverse flex-sm-row align-items-center mt-2">
        {selectedSegment === segmentsData.token ? (
          <>
            {isCheckUser ? (
              <CheckUserForm
                checkUserHandler={checkUserHandler}
                name={t('pages.register')}
                isregisterPage={true}
              />
            ) : (
              <RegisterToken
                checkTokenhandler={checkTokenHandler}
                resendTokenHandler={resendTokenHandler}
                shouldResend={shouldResendToken}
                mobile={user.mobile}
                isSubmitting={isSubmitting}
                name={t('pages.register')}
                goBack={goBackHandler}
              />
            )}

            <RegisterClinicDetails />
          </>
        ) : (
          <>
            {isPassword ? (
              <>
                <ChangePasswordForm
                  changePasswordhandler={changePasswordhandler}
                  isRegisterPage={true}
                  goBack={goBackToPreviousSegment}
                />
                <div className="clinicImage-container d-flex">
                  <img src={clinicImg} alt="clinic" />
                </div>
              </>
            ) : (
              <>
                <RegisterForm
                  getImage={getImage}
                  isUploadAssossiation={isUploadAssossiation}
                  associationScanImg={associationScanImg}
                  personalData={personalData}
                  nextStepHandler={nextStepHandler}
                  registerHandler={registerHandler}
                  isSubmitting={isSubmitting}
                  currentSegment={segmentsData.personal}
                  goBack={goBackToPreviousSegment}
                />
                <div className="clinicImage-container d-flex">
                  <img className="neqabaImg" src={naqabaImg} alt="naqaba" />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RegisterProfile;
