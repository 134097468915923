import { User } from '../../types';
import {
  CHECK_USER,
  START_LOADING,
  STOP_LOADING,
  LOGIN,
  CHECK_TOKEN,
  TOKEN_REQUIRED,
  LOGOUT,
  SET_USER_AUTHENTICATED,
  SET_USER_PROFILE,
} from '../actions/actionTypes';

export type AuthStateType = {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  tokenRequired: boolean;
  authToken: string | null;
  token: string | null;
  trustedDeviceToken: string;
};

const initialState: AuthStateType = {
  user: null,
  isLoading: false,
  tokenRequired: false,
  token: null,
  authToken: localStorage.getItem('authToken'),
  trustedDeviceToken: '',
  isAuthenticated: false,
};

export default function auth(state = initialState, action: any): AuthStateType {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case CHECK_USER:
      return { ...state, user: action.payload, isLoading: false };
    case LOGIN:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        token: '',
      };
    case CHECK_TOKEN:
      return { ...state, token: action.payload, isLoading: false };
    case SET_USER_PROFILE:
      return { ...state, user: { ...state.user, ...action.payload } };
    case TOKEN_REQUIRED:
      return { ...state, tokenRequired: action.payload, isLoading: false };
    case SET_USER_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        authToken: localStorage.getItem('authToken'),
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isLoading: false,
        tokenRequired: false,
        token: '',
        authToken: '',
        trustedDeviceToken: '',
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
