import React from 'react';
import Lottie, { Options } from 'react-lottie';
import animationFile from '../../assets/animation/empty-board.json';

type Props = {
  lotti?: string;
  width?: string;
  height?: string;
};
const LottiePlayer: React.FC<Props> = ({ lotti, width, height }) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: lotti ? lotti : animationFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height || '50rem'}
        width={width || '50rem'}
      />
    </div>
  );
};

export default LottiePlayer;
