import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bookingStatus from '../../../../assets/constants/bookingStatus';
import { groupTimeSlot } from '../../../../services/timeSlots';
import {
  setCollapsedSlots,
  setCurrentBooking,
  setCurrentSlot,
  setSomeSlotsShown,
  shouldSearchInputFocus,
} from '../../../../store/actions';
import { rootState } from '../../../../store/reducers';
import { Booking } from '../../../../types';
import { formatDateWithDay } from '../../../../utils/moment';
import TimeSlots from './TimeSlotsComponent/TimeSlotsComponent';
import './UnitPanelBookings.scss';

type Props = {
  isAllSlotsShown: boolean;
  isAllBookings: boolean;
  isReservationsActive: boolean;
  updateBookingStatusHandler: (status: string) => void;
};

const UnitPanelBookings: React.FC<Props> = ({
  isAllBookings,
  isReservationsActive,
  updateBookingStatusHandler,
}) => {
  const dispatch = useDispatch();
  const [timeSlots, setTimeSlots] = useState<any>({});
  const [timeSlotsPending, setTimeSlotsPending] = useState<any>({});
  const [empty, setEmpty] = useState<any>({});

  const collapsedSlots = useSelector(
    (state: rootState) => state.booking.collapsedSlots
  );

  const showAll = useSelector((state: rootState) => state.booking.showAll);
  const showSomeSlots = useSelector(
    (state: rootState) => state.booking.someSlotsShown
  );
  const currentBooking = useSelector(
    (state: rootState) => state.booking.currentBooking
  );

  const allBookings = useSelector((state: rootState) => state.booking.bookings);
  const allBookingsPending = useSelector(
    (state: rootState) => state.booking.bookingsPending
  );
  const bookingDate = useSelector(
    (state: rootState) => state.booking.bookingDate
  );
  const currentUnitDetails = useSelector(
    (state: rootState) => state.booking.currentUnitDetails
  );

  useEffect(() => {
    setTimeSlots(null);
    // dispatch(setcurrentCollapsedIndex(''));
    if (!currentUnitDetails) return;

    const sDate = new Date(bookingDate).setHours(0, 0, 0, 0);
    const eDate = new Date(bookingDate).setHours(23, 59, 0, 0);

    const filteredBookings = allBookings.filter(
      (ele) => ele.date >= sDate && ele.date <= eDate
    );
    if (!isAllBookings) {
      const nonCompletedBookings = filteredBookings.filter(
        (booking) => booking.status !== bookingStatus.completed
      );
      updateBookingSlots(nonCompletedBookings);
      return;
    }
    updateBookingSlots(filteredBookings);
  }, [allBookings, bookingDate, currentUnitDetails, isAllBookings]);

  // show/hide all slots
  useEffect(() => {
    const collapsedData = { ...collapsedSlots };
    Object.keys(collapsedSlots).forEach((slot) => {
      if (showAll && !showSomeSlots) {
        collapsedData[slot] = true;
      } else if (!showAll && !showSomeSlots) {
        collapsedData[slot] = false;
      } else if ((showAll && showSomeSlots) || (!showAll && showSomeSlots)) {
        if (collapsedSlots[slot] === false) collapsedData[slot] = false;
        else collapsedData[slot] = true;
      }
    });
    dispatch(setCollapsedSlots({ ...collapsedData }));
    // eslint-disable-next-line
  }, [showAll]);

  useEffect(() => {
    handlePendingBookings();
  }, [allBookingsPending]);

  const handlePendingBookings = () => {
    setTimeSlotsPending({});

    if (!currentUnitDetails) return;
    let daysSlots = { ...empty };
    let timeSlots = { ...empty };

    const sortedBookings = allBookingsPending.sort((a, b) => a.date - b.date);
    sortedBookings.forEach((booking) => {
      const startDate = new Date(
        new Date(booking.date).toJSON().slice(0, 10)
      ).getTime();
      if (daysSlots[startDate]) {
        const obj = {
          [startDate]: [...daysSlots[startDate], booking],
        };
        daysSlots = { ...daysSlots, ...obj };
      } else {
        const obj = {
          [startDate]: [booking],
        };
        daysSlots = { ...daysSlots, ...obj };
      }
    });
    Object.keys(daysSlots).forEach((k: any) => {
      for (const key in daysSlots[k]) {
        if (Object.prototype.hasOwnProperty.call(daysSlots[k], key)) {
          const groupedBookingsInDay = groupTimeSlot(
            daysSlots[k],
            currentUnitDetails,
            new Date(key)
          );
          const obj = {
            [k]: groupedBookingsInDay,
          };
          timeSlots = { ...timeSlots, ...obj };
        }
      }
    });
    setTimeSlotsPending(timeSlots);
  };

  const updateBookingSlots = (bookingArr: Booking[]) => {
    setTimeSlots(null);
    if (!currentUnitDetails) return;
    const slots = groupTimeSlot(
      bookingArr,
      currentUnitDetails,
      new Date(bookingDate)
    );
    let collapsedData = { ...collapsedSlots };
    if (Object.keys(collapsedSlots).length === 0 && bookingArr.length > 0) {
      Object.keys(slots).forEach((slot) => {
        collapsedData[slot] = true;
      });
    } else if (isAllBookings && showAll) {
      Object.keys(slots).forEach((slot) => {
        if (!Object.keys(collapsedSlots).includes(slot)) {
          const obj = {
            [slot]: true,
          };
          collapsedData = { ...collapsedData, ...obj };
        }
      });
    } else if (isAllBookings && !showAll) {
      Object.keys(slots).forEach((slot) => {
        if (!Object.keys(collapsedSlots).includes(slot)) {
          const obj = {
            [slot]: false,
          };
          collapsedData = { ...collapsedData, ...obj };
        } else {
          // collapsedData[slot] = false;
        }
      });
    }
    dispatch(setCollapsedSlots({ ...collapsedData }));

    setTimeSlots(slots);
    if (
      bookingArr.length > 0 &&
      (!currentBooking ||
        (currentBooking &&
          currentBooking.status === bookingStatus.completed &&
          !isAllBookings))
    ) {
      const activeBookingIndex = bookingArr.findIndex(
        (booking: any) => booking.status === bookingStatus.active
      );

      // set current displaying booking to the active booking if found
      // otherwise set it to the first booking in array
      const activeBooking =
        activeBookingIndex >= 0
          ? bookingArr[activeBookingIndex]
          : bookingArr[0];
      // showCollapsedHandler(slots);
      dispatch(setCurrentBooking(activeBooking));
      // openBookingSlot(activeBooking);
    } else {
      // if (currentBooking) openBookingSlot(currentBooking);
    }
  };

  const showHideCollapsedHandler = (slot: string) => {
    const collapsedData = { ...collapsedSlots };
    collapsedData[slot] = !collapsedData[slot];
    dispatch(setCollapsedSlots({ ...collapsedData }));
    dispatch(setSomeSlotsShown(true));
  };

  const setCurrentBookingHandler = (booking: Booking | null) => {
    if (!booking || currentBooking?.sk === booking.sk) return;
    if (booking) dispatch(setCurrentBooking(booking));
  };

  const addBookingHandler = (slot: string) => {
    dispatch(shouldSearchInputFocus(true));
    if (!slot) return;
    dispatch(setCurrentSlot(slot));
  };

  return (
    <>
      {isReservationsActive ? (
        <TimeSlots
          timeSlots={timeSlots}
          showHideCollapsedHandler={(timeSlots: any) => {
            showHideCollapsedHandler(timeSlots);
          }}
          setCurrentBookingHandler={(timeSlots: any) => {
            setCurrentBookingHandler(timeSlots);
          }}
          addBookingHandler={(timeSlots: any) => {
            addBookingHandler(timeSlots);
          }}
          updateBookingStatusHandler={updateBookingStatusHandler}
          reservation={isReservationsActive}
        />
      ) : (
        Object.keys(timeSlotsPending).map((timeSlotPending) => (
          <div key={timeSlotPending}>
            <div className="booking-item-container booking-item-date flex-column flex-sm-row">
              <div className="line line1 line2">
                {formatDateWithDay(parseInt(timeSlotPending))}
              </div>
            </div>
            <TimeSlots
              timeSlots={timeSlotsPending[timeSlotPending]}
              showHideCollapsedHandler={(timeSlots: any) => {
                showHideCollapsedHandler(timeSlots);
              }}
              setCurrentBookingHandler={(timeSlots: any) => {
                setCurrentBookingHandler(timeSlots);
              }}
              addBookingHandler={(timeSlots: any) => {
                addBookingHandler(timeSlots);
              }}
              updateBookingStatusHandler={updateBookingStatusHandler}
              reservation={isReservationsActive}
            />
          </div>
        ))
      )}
    </>
  );
};

export default UnitPanelBookings;
