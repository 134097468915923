import React, { useState } from 'react';
import './ChangePasswordForm.scss';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Form/Button/Button';
import formValidationSchema from './FormValidation';
import PasswordInput from '../UI/Form/Password/PasswordInput';
import lockIcon from '../../assets/img/lock.svg';
import eyeIcon from '../../assets/img/eye.svg';
import visibilityIcon from '../../assets/img/visibility.svg';
import backArrow from '../../assets/img/back.svg';

type Props = {
  changePasswordhandler: (payload: any) => Promise<void>;
  isRegisterPage?: boolean;
  goBack?: () => void;
};

const ChangePasswordForm: React.FC<Props> = ({
  changePasswordhandler,
  isRegisterPage,
  goBack,
}) => {
  const { t } = useTranslation();

  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] = useState(
    false
  );

  const togglePassword = () => {
    setShouldShowPassword((showPassword) => !showPassword);
  };
  const toggleConfirmPassword = () => {
    setShouldShowConfirmPassword((showPassword) => !showPassword);
  };

  return (
    <div className="change-password-page-container d-flex align-items-center flex-column justify-content-center w-100 p-0 p-sm-5 mt-4 mt-sm-0">
      <div className="align-self-start">
        <p>
          {isRegisterPage ? t('pages.register') : t('pages.changePassword')}
        </p>
      </div>
      <div className="change-password-page-form align-items-center align-self-stretch d-flex mt-5">
        <div className="align-items-center d-flex flex-column p-5">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={formValidationSchema(t)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await changePasswordhandler(values);
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="first-password">
                  <div className="lock-icon d-flex align-self-start">
                    <img src={lockIcon} alt="lockIcon"></img>
                    <h6>
                      {isRegisterPage
                        ? t('complete_profile.password-name')
                        : t('changePassword_page.set-password')}
                    </h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <PasswordInput
                      type={shouldShowPassword ? 'text' : 'password'}
                      name={'password'}
                      placeholder={t('changePassword_page.enter_password')}
                      icon={shouldShowPassword ? eyeIcon : visibilityIcon}
                      isIcon={true}
                      iconClick={togglePassword}
                    />
                  </div>
                </div>
                <div className="confirm-password mt-3">
                  <div className="lock-icon d-flex align-self-start">
                    <img src={lockIcon} alt="lockIcon"></img>
                    <h6>
                      {isRegisterPage
                        ? t('complete_profile.rewrite-password')
                        : t('changePassword_page.repeat-password')}
                    </h6>
                  </div>
                  <div className="passwordInput mt-3">
                    <PasswordInput
                      type={shouldShowConfirmPassword ? 'text' : 'password'}
                      name={'confirmPassword'}
                      placeholder={t('changePassword_page.enter_password')}
                      icon={
                        shouldShowConfirmPassword ? eyeIcon : visibilityIcon
                      }
                      isIcon={true}
                      iconClick={toggleConfirmPassword}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    type="submit"
                    name={t('changePassword_page.next')}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {isRegisterPage && (
        <div
          className="back align-self-start mt-2"
          onClick={() => {
            if (goBack) goBack();
          }}
        >
          <img src={backArrow} alt="back" />
          <span>{t('otp_page.back')}</span>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordForm;
